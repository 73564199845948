import { Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import { excelCouterAPI } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import CustomTooltip from "../../components/ant/CustomTooltip";
import Movetowatchlist from "../../components/Movetowatchlist";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";
import useGetFetch from "../../hooks/useGetFetch";
import { authStore } from "../../redux/reducers/authSlice";
import CompanyCapCalc from "../../utilityFn/CompanyCapCalc";
import { exportToExcel } from "../../utilityFn/excel/exportToExcel";
import { getFormatDate } from "../../utilityFn/getFormatdate";
import { marketHourStatus } from "../../utilityFn/marketHourStatus";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import { urlEncode } from "../../utilityFn/urlEncode";

function StockTopCard({ stockData, metricsData, lightMode, excelData }) {
  const { userData } = useSelector(authStore);
  const [premiumModal, setpremiumModal] = useState(false);
  const {
    isLoading,
    apiData: priceChangeData,
    serverError,
  } = useGetFetch(
    StockEndpoints.priceChange(
      stockData.type,
      stockData.symbol,
      stockData.companyType
    )
  );
  const excelPremiumValidation = async () => {
    if (userData?.user?.is_premium) {
      await excelCouterAPI({ file: "stock-details" }).then((resp) => {
        if (resp.data.count_expired === false) {
          exportToExcel(
            excelData,
            `${metricsData?.companyName}`,
            `https://portal.tradebrains.in/stock/${stockData.symbol}/${stockData.companyType}`,
            stockData.companyType
          ).then((url) => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute(
              "download",
              `${metricsData?.companyName}(${
                stockData.companyType
              })-${getFormatDate(new Date())}.xlsx`
            );
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          });
        } else {
          setpremiumModal(true);
        }
      });
    } else {
      setpremiumModal(true);
    }
  };

  return (
    <>
      <div
        className={`table-shadow  p-15 d-felx d-flex-col mb-10 ${
          lightMode ? "bg-gray" : "bg-dark-gray"
        }`}
      >
        <div className="  d-felx d-flex-row justify-content-between">
          <div className="shareshow-card-sd align-items-center d-felx d-flex-row ">
            <h1 className="fs-24-16  fw-500 mb-0">
              {metricsData?.companyName}
            </h1>
            <div
              style={{ padding: "0 10px", margin: "0px 0px 0px 10px" }}
              className={`p-5 ${
                lightMode ? "bg-white" : "bg-dark"
              } br-5 label-cap-pc fs-14-10`}
            >
              {CompanyCapCalc(metricsData?.mcap)}
            </div>
          </div>
          <div style={{ gap: ".7rem" }} className="d-flex align-items-center">
            <Tooltip
              className="square-18-14"
              title="Prices are delayed by 15 Mins"
            >
              <div style={{ display: marketHourStatus() ? "block" : "none" }}>
                {svgSheet.clockIcon}
              </div>
            </Tooltip>
            {/* {userData?.user?.is_premium && ( */}
            <CustomTooltip text="Export To Excel">
              <p
                style={{ width: "22px", height: "22px" }}
                onClick={excelPremiumValidation}
                className="pointer mb-0 "
              >
                {svgSheet.downloadIcon}
              </p>
            </CustomTooltip>
            {/* )} */}
            {/* <p className="pointer mb-0 square-18-14">{svgSheet.SaveForLater}</p> */}
            <Movetowatchlist lightMode={lightMode} symbol={[stockData?.symbol]}>
              <div className="pointer square-18-14">
                {svgSheet.SaveForLaterFilled}
              </div>
            </Movetowatchlist>
          </div>
        </div>
        <div className="w-100 d-felx d-flex-row align-items-center main-card-bottom-sec ">
          <div className="flex w-100 d-flex-row  align-items-center justify-content-between">
            <div
              className={`p-5  ${
                lightMode ? "bg-white" : "bg-dark"
              } label-cap-tab br-5 fs-14-10`}
            >
              {CompanyCapCalc(metricsData?.mcap)}
            </div>
            <Link
              to={`/stock/industry/${urlEncode(metricsData?.industry)}`}
              className={`my-10  ${
                lightMode ? "bg-white" : "bg-dark"
              }  d-felx d-flex-row   align-items-center  pointer br-5`}
            >
              <span
                style={{ padding: "5px 10px" }}
                className="btn-bg-hover d-flex h-100 w-100"
              >
                <p className="mb-0 fs-14-10">Sector: </p>
                <p className=" mb-0 fs-14-10" style={{ margin: "0px 5px" }}>
                  {" "}
                  {metricsData?.industry}
                </p>
              </span>
            </Link>
          </div>
        </div>
        <div className="d-flex w-100 justify-content-end mt-10">
          <div className="flex-col-row sub-bottom-div-card justify-content-around w-100-75 ">
            <div className="grid-col-2 w-100-50">
              <div className="align-items-end-start d-flex-col">
                <p className="mb-0 fs-20-13 fw-400 ff-lato">
                  ₹{" "}
                  {metricsData?.mcap > 10000
                    ? numberWithCommas(Math.trunc(metricsData?.mcap))
                    : numberWithCommas(metricsData?.mcap)}{" "}
                  Cr
                </p>
                <p className="fs-s-12 mb-0 ">Market Cap</p>
              </div>
              <div className=" d-flex d-flex-col align-items-end-start">
                <p className="mb-0 fs-20-13 ff-lato">
                  {metricsData?.ttm_pe?.toFixed(2) ?? 0}
                </p>
                <p className="fs-s-12 mb-0  ">PE (TTM)</p>
              </div>
            </div>
            <div className=" grid-col-2 w-100-50">
              <div className=" d-flex d-flex-col align-items-end-start  ">
                <p className="mb-0 fs-20-13 ff-lato">
                  {numberWithCommas(metricsData?.roe)}%
                </p>
                <p className="fs-s-12 mb-0  ">ROE</p>
              </div>
              <div className=" align-items-end-start ">
                <div
                  style={{
                    color:
                      priceChangeData &&
                      redGreenColorPicker(
                        priceChangeData[0]?.percent,
                        lightMode
                      ),
                  }}
                  className="d-flex align-items-end-start d-flex-col"
                >
                  <h4
                    style={{ color: "inherit" }}
                    className={`mb-0 fs-20-13 fw-600 ff-lato`}
                  >
                    ₹ {numberWithCommas(metricsData?.price)}
                  </h4>
                  <p className={`fs-s-12 mb-0 ff-lato fw-600`}>
                    {priceChangeData && priceChangeData[0]?.change > 0
                      ? "+"
                      : ""}
                    {priceChangeData && priceChangeData[0]?.change} (
                    {priceChangeData && priceChangeData[0]?.percent}%)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PremiumRedirectModal
        visible={premiumModal}
        setVisible={setpremiumModal}
        modalPlan="excel"
      />
    </>
  );
}

export default StockTopCard;
