import React from 'react'
// import GoogleLogin from 'react-google-login'
import { useDispatch } from 'react-redux';
import { postGoogleAuth } from '../../api/fetchClient';
import googleIcon from '../../assets/images/logo/googleIcon.png'
import { setAuth } from '../../redux/reducers/authSlice';

function GoogleAuth({title,onCloseHandler}) {
    const dispatch=useDispatch()
    const onSuccess=async(response)=>{
        try{
            await postGoogleAuth({access_token:response.accessToken}).then((resp)=>{
                dispatch(setAuth(resp.data))
                onCloseHandler("NONE")
            })
        }
        catch(error){
            console.log(error);
        }
    }
  return (
    <button style={{margin:'6px 0px'}}   className='pl-10px center w-60-80 py-10 br-5 bg-white b-gray pointer'>
    <img src={googleIcon} alt="icon" className='mx-10px h-20 w-20'/>
     <span>{title}</span>
      </button>
  //         <GoogleLogin
  //   clientId="1065016260363-bt80d75995gpcisjjq345bmfj8qjq49h.apps.googleusercontent.com"
  //   render={renderProps => (
  //       <button style={{margin:'6px 0px'}} onClick={renderProps.onClick} disabled={ renderProps.disabled} className='pl-10px center w-60-80 py-10 br-5 bg-white b-gray pointer'>
  //       <img src={googleIcon} alt="icon" className='mx-10px h-20 w-20'/>
  //        <span>{title}</span>
  //         </button>
  //       // <button  className='otherlogin' onClick={renderProps.onClick} onSubmit={failureGoogle} disabled={ renderProps.disabled}> 
  //       // <img className="googleimg" src={Google}/><a className='ml-2'>Google</a>
  //       // </button>
  //     )}
  //   buttonText="Login"
  //   onSuccess={onSuccess}
  //   onFailure={(error) => console.log(error)}
  //   cookiePolicy={'single_host_origin'}
   
  // />
  )
}

export default GoogleAuth