import { Modal } from "antd";
import React from "react";

function FormConfirmPopup({ visible, setVisible, lightMode,onSubmit }) {
  return (
    <div>
      <Modal
        centered
        // closable={true}
        width="400px"
        bodyStyle={{ padding: "0px", minHeight: "250px"}}
        visible={visible === "CONFIRM"}
        title={
          <p
            className={`${
              lightMode ? "text-dark" : "text-white"
            }  fs-20-16 fw-600`}
          >
            Confirm Submission
          </p>
        }
        footer={null}
        onCancel={() => setVisible("NONE")}
        className="relative "
        wrapClassName={"filter-modal premium-mod"}
      >
        <div style={{ paddingInline: "24px" }} >
          <p className="fs-18-14 fw-600">
            By submiting the bank details, you are acknowledged that all the
            details and documents attached are correct.
          </p>
          <p className="fs-16-14 fw-500">
            For anyother further updation of bank details,kindly contact us
            via email.
          </p>
          <button onClick={onSubmit}
                  type="submit"
                  style={{ margin: "6px 0px" }}
                  className="w-100 h-45 btn-bg-primary fw-500 py-10 br-5  h-42 text-white pointer"
                >
                  Submit
                </button>
        </div>
      </Modal>
    </div>
  );
}

export default FormConfirmPopup;
