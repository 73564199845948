import React from "react";
import getAppDark from '../../../assets/images/home/get app -dark theme.png'
import getAppLight from '../../../assets/images/home/get app -light theme.png'

function GetMobileAppSection({ lightMode }) {
  return (
    <section className="max-w flex flex-col-row align-items-center  mx-auto px-15 my-100 w-100">
      <div className="w-100-50">
        <h3 className="fs-40-32 fw-700 mb-30 text-md-center ">
          Get Total Control <br />
          By Downloading Our App
        </h3>
        <p className="fs-16-14 my-40 text-md-center">
          Browse through the financial and stock data of your favourite <br/> Indian
          publically listed companies on BSE and NSE!
        </p>
        <div className="w-100 flex justify-content-between">
          <div >
            <h3 className="fs-40-28 fw-700 lh-40">100K +</h3>
            <p className="fs-16-14 text-gray">Downloads</p>
          </div>
          <div>
            <h3 className="fs-40-28 fw-700 lh-40">4.6 +</h3>
            <p className="fs-16-14 text-gray">App Rating</p>
          </div>
          <div>
            <h3 className="fs-40-28 fw-700 lh-40">150K +</h3>
            <p className="fs-16-14 text-gray">Registered Users</p>
          </div>
        </div>
      </div>
      <div className="w-100-50 ">
        <a href="https://bit.ly/tradebrainsportal" target="_blank">
      <img style={{height:'86%'}}
              className="mx-auto"
            src={lightMode?getAppLight:getAppDark}
            alt="img"
          />
          </a>
      </div>
    </section>
  );
}

export default GetMobileAppSection;
