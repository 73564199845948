import React, { useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import FestivalBanner from "../PlanPage/FestivalBanner";
import FeatureCompanies from "./components/FeatureCompanies";
import GetMobileAppSection from "./components/GetMobileAppSection";
import GetStartedSection from "./components/GetStartedSection";
import HomeFaq from "./components/HomeFaq";
import LoserGainerSection from "./components/LoserGainerSection";
import RedirectAllStocks from "./components/RedirectAllStocks";
import ViewPlanSection from "./components/ViewPlanSection";
import KeyFeatures from "./key features/KeyFeatures";
import RegisterSteps from "./register steps/RegisterSteps";
import ToolSection from "./Tools/ToolSection";
import UserFeedbackSection from "./user feedback/UserFeedbackSection";

function HomeIndex({ setisLoginPage }) {
  const { lightMode } = useSelector(getThemeMode);
  const [searchParams] = useSearchParams();
  console.log(searchParams.get("refer"));
  useEffect(() => {
    setisLoginPage(false);
  }, []);
  useEffect(() => {
    if (searchParams.get("refer")) {
      sessionStorage.setItem("referID", searchParams.get("refer"));
    }
  }, []);

  return (
    <>
      <ReactHelmet
        title="Trade Brains Portal - Financial Data & Stock Quotes"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      <div className="ff-poppins    w-100">
        {/* <FestivalBanner/> */}
        <GetStartedSection lightMode={lightMode} />
        <FeatureCompanies lightMode={lightMode} />
        <LoserGainerSection lightMode={lightMode} />
        <RedirectAllStocks lightMode={lightMode} />
        <KeyFeatures lightMode={lightMode} />
        <ToolSection lightMode={lightMode} />
        <ViewPlanSection lightMode={lightMode} />
        <RegisterSteps lightMode={lightMode} />
        <UserFeedbackSection lightMode={lightMode} />
        <GetMobileAppSection lightMode={lightMode} />
        <HomeFaq lightMode={lightMode} />
      </div>
      <Outlet />
    </>
  );
}

export default HomeIndex;
