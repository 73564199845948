import React from 'react'
import affiliateSpace from '../../../../../../assets/images/affiliateSpace.png'
function PromotionCard({lightMode,img,size,kbsize}) {
  return (
    <div  className={`${lightMode ? " card-drop-light-shadow" : "card-drop-dark-shadow"} hover-trasation  p-20   br-5`}>
        <div >
         <img height={"250px"} className="w-100" src={img}></img>
         </div>
         <div className='d-flex justify-content-between'>
             <p>Image Dimension</p>
             <p>{size}</p>
         </div>
         <div className='d-flex justify-content-between'>
             <p>Image Size</p>
             <p>{kbsize} Kb</p>
         </div>
         <div className='d-flex align-items-center text-white   justify-content-center'>
         <a href={img} type="image/png" download  className={`  text-white  p-5 px-20 w-200px fw-500 fs-16-14 text-align-center br-5 btn-bg-primary`  }>Download</a>
         </div>
    </div>
  )
}

export default PromotionCard