import React, { useEffect, useMemo, useState } from 'react'
import { getShareHoldingCompareStocks } from '../../api/fetchClient'
import SelectOptions from '../../components/ant/SelectOptions'
import CustomTable from '../../components/CustomTable'
import convertTableStructure from './convertTableStructure'
import { ShareHoldingComapreData } from './TableType'

function CompareShareholding({lightMode,removedEmptyData,symbolsandnames,columns,CompanyType}) {
    const [selectd, setselectd] = useState(202209)
    const [shareHolding, setShareHolding] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const values =[{"value":"202209","label":"SEP 2022"},{"value":"202206","label":"JUN 2022"},{"value":"202203","label":"MAR 2022"},{"value":"202112","label":"DEC 2021"},{"value":"202109","label":"SEP 2021"}]
    const currentYear = '202209'
    useEffect(() => {
        const getData = async () => {
          setIsLoading(true)
          getShareHoldingCompareStocks(removedEmptyData,selectd,CompanyType).then(async (res) => {
            setIsLoading(false)
            setShareHolding(res.data);
          });
        };
        getData();
      }, [selectd,CompanyType]);


      const returnsTableData = useMemo(() => {
        if (shareHolding?.stocks === undefined) {
          return;
        } else {
          const { tableBodyData, columns } = convertTableStructure(
            shareHolding?.stocks,
            ShareHoldingComapreData,
            removedEmptyData,
            lightMode,
            symbolsandnames 
          );
          return { tableBodyData, columns };
        }
      }, [shareHolding,selectd,lightMode]);
  return (
    <div id="Shareholding Pattern">
    <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h2 className="main-header-c ">Shareholding</h2>
          </div>
          <div className="d-flex flex-col align-items-end">
           <SelectOptions lightMode={lightMode}  data={values} setselectd={setselectd} defaultSelect={"JUN 2022"} width={116}/>
          </div>
        </div>
                <div  
                  className={`table-shadow   ${
                    lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
                  }`}
                >
                  <CustomTable columns={returnsTableData?.columns} scrollable={true} loading={isLoading} data={returnsTableData?.tableBodyData} />
                </div>
              </div>
  )
}

export default CompareShareholding