function convertToGraphData(symbols,apiData,keyValue) {
    const datagrap1 =  symbols?.map((items,index)=>{
        const graphdata1 = []
      //  apiData?.stocks[items] &&  items !== "NESTLEIND" && 
       apiData?.stocks[items]?.map((values,i)=>{
          graphdata1.push(values[keyValue])
        })
        return graphdata1
      })
      return datagrap1
    
}

export default convertToGraphData