import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import { Button, Form, Input, Select, Upload } from "antd";
import AntFormInput from "../../../../../components/ant/AntFormInput";
// import { Option } from "antd/lib/mentions";
import StockEndpoints from "../../../../../api/endPoints";
import { get } from "../../../../../api/MainClient";
import { UploadOutlined } from "@ant-design/icons";
import FormConfirmPopup from "./FormConfirmPopup";
import FormSubmitPopup from "./FormSubmitPopup";
import { getAffiliateBank, postAffiliateBank } from "../../../../../api/fetchClient";
function AffiliateUpdatebank() {
  const { lightMode } = useSelector(getThemeMode);
  const [popupState, setpopupState] = useState("NONE");
  const [bankStatus, setbankStatus] = useState('No_ACC')
  const [userDataForm] = Form.useForm();
  const [userBankData] = Form.useForm();
  const [userData, setuserData] = useState({});
  const { Option } = Select;
console.log("userBankData",userDataForm);
  const getData = async () => {
    await getAffiliateBank().then((resp) => {
      setbankStatus(resp.data);
      if(resp.data.id){
        userBankData.setFieldsValue({...resp.data,confirm_account_number:resp.data.account_number})
      }
    }).catch((err)=>{
      userBankData.setFieldsValue({gst:'',gst_image:null})
    })
  };
  useEffect(() => {
    getData();
  }, []);
 
  useEffect(() => {
    const getData = async () => {
      const response = await get(StockEndpoints.postAffiliate)
        .then((resp) => {
          const promotionValue = promotionOptions?.map((data) => data.value);
          const keyArr = Object.keys(resp.data);
          const commonEle = keyArr?.filter(
            (ele) => promotionValue.includes(ele) && resp.data[ele]
          );
          userDataForm.setFieldsValue({
            ...resp.data,
            phone: resp.data.phone.slice(3),
            promotion: commonEle,
          });
          setuserData({
            ...resp.data,
            phone: resp.data.phone.slice(3),
            promotion: commonEle,
          });
          setpromotionAr(commonEle);
        })
        .catch((err) => console.log(err));
    };
    getData();
  }, []);

  const [promotionAr, setpromotionAr] = useState([]);
  const onSubmit = async (values) => {
    console.log(values);
  };
  const onBankSubmit = async (values) => {
    console.log(values);
    setpopupState("CONFIRM");
  };
  const onFormSubmit = async () => {
    const formValues = userBankData.getFieldsValue();
    console.log("formValues",formValues);
    const bankFormdata = new FormData();
    console.log("bankFormdata12",bankFormdata);
    Object.entries(formValues).forEach((item) => {
    console.log("formValues2",item);

      bankFormdata.append(item[0], item[1]);
      
    });

    await postAffiliateBank(bankFormdata).then((resp) => {
      setpopupState("SUBMIT");
    });
  };
  const promotionOptions = [
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Youtube",
      value: "youtube",
    },
    {
      label: "Faceook",
      value: "facebook",
    },
    {
      label: "Blog",
      value: "blog",
    },
    {
      label: "Twitter",
      value: "twitter",
    },
    {
      label: "Email maketing",
      value: "email_promotion",
    },
    {
      label: "Instagram",
      value: "instgram",
    },
    {
      label: "Others",
      value: "others",
    },
  ];
  const subCount = [
    {
      label: "Below 10k",
      value: "Below 10k",
    },
    {
      label: "10k - 100k",
      value: "10k - 100k",
    },
    {
      label: "Above 100k",
      value: "Above 100k",
    },
  ];
  const accType = [
    {
      label: "Savings",
      value: "savings",
    },
    {
      label: "Current",
      value: "current",
    },
  ];
  
  const props = {
    headers: {
      authorization: "authorization-text",
    },
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    name: "file",
  };

  return (
    <section className="max-w mx-auto px-15  w-100">
      <div className="my-body">
        <h1 className="fs-24-16 fw-700 mb-30">Profile Details</h1>
        <div>
          <Form
            disabled
            autoComplete="off"
            //   className="w-60-80"
            form={userDataForm}
            name="userDataForm"
            onFinish={onSubmit}
            scrollToFirstError
            className="d-flex flex-col-row"
            style={{ gap: "5rem" }}
            layout="vertical"
          >
            <div className="w-100-50">
              <AntFormInput
                disabled={true}
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Name",
                  },
                ]}
                placeholder="Type your name"
              />

              <AntFormInput
                disabled={true}
                label="Email ID"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please Enter your Email ID",
                  },
                ]}
                placeholder="Type Your Email Id"
              />

              <AntFormInput
                label="Mobile"
                name="phone"
                addonBefore="+91"
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Mobile Number",
                  },
                  {
                    pattern: /^[\d]{10,10}$/,
                    message: "Enter valid Mobile Number",
                  },
                ]}
                placeholder="Type Your Mobile Number"
              />

              <Form.Item
                label="Tell us about yourself"
                style={{ margin: "0px 0px 10px 0px" }}
                name="about"
                className={` ${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
              >
                <Input.TextArea
                  showCount
                  maxLength={1000}
                  type="text"
                  rows={8}
                  style={{
                    height: "150px",
                    width: "100%",
                    paddingInline: "0px",
                  }}
                  className={`w-100  auth-form-input  ${
                    lightMode
                      ? "bg-gray ant-textarea-light "
                      : "bg-dark-gray  ant-textarea-dark"
                  }`}
                  placeholder="Type Here"
                />
              </Form.Item>
            </div>
            {/* rightn section  */}
            <div className="w-100-50">
              {/* <Form.Item
                label="Promotional Method"
                valuePropName="checked"
                style={{ margin: "0px 0px 10px 0px" }}
                name="promotion"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Select Promotional Method",
                  },
                ]}
              >
                <Checkbox.Group
                  style={{ margin: "6px 0px" }}
                  className={`check-grp flex flex-wrap ${
                    lightMode ? "checkbox-light" : "checkbox-dark"
                  }`}
                  options={promotionOptions}
                  defaultValue={userData?.promotion}
                  onChange={(checkedValues) => {
                    userDataForm.setFieldsValue({ promotion: checkedValues });
                    setpromotionAr(checkedValues);
                  }}
                />
              </Form.Item> */}
              {promotionAr?.map((arr, i) => (
                <AntFormInput
                  key={i}
                  label={`${arr} URL`}
                  name={arr}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter your ${arr} URL`,
                    },
                  ]}
                  placeholder="Enter URL"
                />
              ))}
              <Form.Item
                label="Total Subscribers/followers Range"
                tooltip="Aggregate all of your subscribers & followers from all your social media platforms and select the appropriate range."
                style={{ margin: "0px 0px 10px 0px" }}
                name="total_subscribers"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please select Total Subscribers/followers Range",
                  },
                ]}
              >
                <Select
                  className={
                    lightMode
                      ? "br-5 contact-select-light bg-gray border1px-light-mode"
                      : "br-5  contact-select-dark bg-dark-gray border1px-dark-mode"
                  }
                  // defaultValue={defaultSelect}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  // onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "35px",
                    margin: "auto",
                  }}
                  placeholder="Subscribers/followers Range"
                >
                  {subCount.map((items, i) => (
                    <Option key={i} value={items.value}>
                      {items.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <div className="flex flex-col-row mt-40 align-lg-center justify-lg-between">
                <button
                  type="submit"
                  style={{ margin: "6px 0px" }}
                  className="w-200px h-45 btn-bg-primary fw-500 py-10 br-5  h-42 text-white pointer"
                >
                  submit
                </button>
              </div> */}
            </div>
            {/* rightn section  */}
          </Form>
          {/* bank section  */}
          <div className="mt-40">
            <h1 className="fs-24-16 fw-700 mb-10">Bank Account Details</h1>
            <p>Fill in the Requied Details & Upload the Required Documents </p>
          </div>
          <Form
            autoComplete="off"
            //   className="w-60-80"
            disabled={bankStatus!=='No_ACC'}
            form={userBankData}
            name="userBankData"
            onFinish={onBankSubmit}
            scrollToFirstError
            className="d-flex flex-col-row"
            style={{ gap: "5rem" }}
            layout="vertical"
          >
            <div className="w-100-50">
              <AntFormInput
                label="Account Holder Name"
                name="account_holder_name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Account Holder Name",
                  },
                  // {
                  //   pattern: /^[a-zA-Z]$/,
                  //   message:'Enter Valid Account Holder Name'
                  // }
                ]}
                placeholder="Enter Acc Holder Name"
              />
              <Form.Item
                label="Account Type"
                style={{ margin: "0px 0px 10px 0px" }}
                name="account_type"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please select Account Type",
                  },
                ]}
              >
                <Select
                  className={
                    lightMode
                      ? "br-5 contact-select-light bg-gray border1px-light-mode"
                      : "br-5  contact-select-dark bg-dark-gray border1px-dark-mode"
                  }
                  // defaultValue={defaultSelect}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  // onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "35px",
                    margin: "auto",
                  }}
                  // getPopupContainer={trigger => trigger.parentNode}
                  placeholder="Select Account Type"
                >
                  {accType.map((items, i) => (
                    <Option key={i} value={items.value}>
                      {items.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <AntFormInput
                label="Acount No"
                name="account_number"
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Acount No",
                  },
                ]}
                placeholder="Enter Account No"
              />
              <AntFormInput
                label="Confirm Account No"
                name="confirm_account_number"
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Please Confirm Acount No",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("account_number") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The entered Acount Number does not match!"
                        )
                      );
                    },
                  }),
                ]}
                placeholder="Confirm Account No"
              />
              <AntFormInput
                label="Bank Name"
                name="bank_name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Bank Name",
                  },
                ]}
                placeholder="Enter Bank Name"
              />
              <AntFormInput
                label="IFSC Code"
                name="ifsc"
                isUpperCase={true}
                rules={[
                  {
                    required: true,
                    message: "Please Enter IFSC Code",
                  },
                  {
                    pattern: /^[A-Z0-9]{11,11}$/,
                    message: "Enter Valid IFSC Number",
                  },
                ]}
                placeholder="Enter IFSC Code"
              />
            </div>
            <div className="w-100-50">
              <AntFormInput
                label="PAN Card"
                name="pan_card"
                isUpperCase={true}
                rules={[
                  {
                    required: true,
                    message: "Please Enter PAN Card",
                  },
                  {
                    pattern: /^[a-zA-Z0-9]{10,10}$/,
                    message: "Enter Valid PAN Number",
                  },
                ]}
                placeholder="Enter your 10 digit pan number"
              />
              <Form.Item
                label=""
                style={{ margin: "0px 0px 10px 0px" }}
                name="pan_card_image"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Upload PAN Card Image",
                  },
                ]}
              >
                <Upload
                  {...props}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={() => false}
                  className={!lightMode && "ant-upload-dark"}
                  // style={{backgroundColor:'#3B3F4F'}}
                  listType="picture"
                  defaultFileList={bankStatus?.pan_card_image?[{url:bankStatus?.pan_card_image}]:[] }
                  // fileList={bankStatus?.pan_card_image?[{url:bankStatus?.pan_card_image}]:[] }
                  onChange={(event) => {
                    userBankData.setFields([
                      { name: "pan_card_image", value: event.file },
                    ]);
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              {
                bankStatus?.pan_card_image&&
                <img style={{height:'50px',width:'50px'}} src={bankStatus?.pan_card_image} alt="doc_img"/>
              }
              <AntFormInput
                label="Aadhar Number"
                type="number"
                name="aadhar_number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Aadhar Number",
                  },
                  {
                    pattern: /^[0-9]{12,12}$/,
                    message: "Enter Valid aadhar Number",
                  },
                ]}
                placeholder="Enter your 12 digit aadhar number"
              />
              <Form.Item
                label=""
                style={{ margin: "0px 0px 10px 0px" }}
                name="aadhar_image"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Upload Aadhar Number Image",
                  },
                ]}
              >
                <Upload
                  {...props}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={() => false}
                  className={!lightMode && "ant-upload-dark"}
                  // style={{backgroundColor:'#3B3F4F'}}
                  listType="picture"
                  defaultFileList={bankStatus?.aadhar_image?[{thumbUrl:bankStatus?.aadhar_image}]:[] }
                  // fileList={bankStatus?.aadhar_image?[{url:bankStatus?.aadhar_image}]:[] }
                  onChange={(event) => {
                    userBankData.setFields([
                      { name: "aadhar_image", value: event.file },
                    ]);
                  }}
                  onRemove={(event) => {
                    console.log("removed");
                    // userBankData.setFieldsValue("aadhar_image",null)
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              {
                bankStatus?.aadhar_image&&
                <img style={{height:'50px',width:'50px'}} src={bankStatus?.aadhar_image} alt="doc_img"/>
              }
              <Form.Item
                label="Cancelled Check / Passbook Front-page"
                style={{ margin: "0px 0px 10px 0px" }}
                name="blank_check"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Upload Cancelled Check Image",
                  },
                ]}
              >
                <Upload
                  {...props}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={() => false}
                  className={!lightMode && "ant-upload-dark"}
                  // style={{backgroundColor:'#3B3F4F'}}
                  listType="picture"
                  defaultFileList={bankStatus?.blank_check?[{thumbUrl:bankStatus?.blank_check}]:[] }
                  // fileList={bankStatus?.blank_check?[{url:bankStatus?.blank_check}]:[] }
                  onChange={(event) => {
                    userBankData.setFields([
                      { name: "blank_check", value: event.file },
                    ]);
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              {
                bankStatus?.blank_check&&
                <img style={{height:'50px',width:'50px'}} src={bankStatus?.blank_check} alt="doc_img"/>
              }
              <AntFormInput
                label="GST IN"
                name="gst"
                isUpperCase={true}
                rules={[
                  {
                    required: false,
                    // message: "Please Enter GST Number",
                  },
                  {
                    pattern: /^[a-zA-Z0-9]{16,16}$/,
                    message: "Enter Valid GST Number",
                  },
                ]}
                placeholder="Enter your 16 digit GST number"
              />
              <Form.Item
                label=""
                style={{ margin: "0px 0px 10px 0px" }}
                name="gst_image"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: false,
                    // message: "Please Upload GST Image",
                  },
                ]}
              >
                <Upload
                  {...props}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={() => false}
                  className={!lightMode && "ant-upload-dark"}
                  listType="picture"
                  defaultFileList={bankStatus?.gst_image?[{url:bankStatus?.gst_image}]:[] }
                  // fileList={bankStatus?.gst_image?[{url:bankStatus?.gst_image}]:[] }
                  onChange={(event) => {
                    userBankData.setFields([
                      { name: "gst_image", value: event.file },
                    ]);
                  }}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Form.Item>
              {
                bankStatus?.gst_image&&
                <img style={{height:'50px',width:'50px'}} src={bankStatus?.gst_image} alt="doc_img"/>
              }
              {/* submit */}
              <div className="flex flex-col-row mt-40 align-lg-center justify-lg-end">
                {bankStatus==='No_ACC'&&<button
                  type="submit"
                  style={{ margin: "6px 0px" }}
                  className="w-200px h-45 btn-bg-primary fw-500 py-10 br-5  h-42 text-white pointer"
                >
                  Submit
                </button>}
              </div>
            </div>
          </Form>
          {/* bank section  */}
        </div>
      </div>
      <FormConfirmPopup
        visible={popupState}
        setVisible={setpopupState}
        lightMode={lightMode}
        onSubmit={onFormSubmit}
      />
      <FormSubmitPopup
        visible={popupState}
        setVisible={setpopupState}
        lightMode={lightMode}
      />
    </section>
  );
}

export default AffiliateUpdatebank;
