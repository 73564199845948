import {
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { DatePicker, Modal, Popover } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AddStockPortfolioAnalysis,
  getCMP,
  getEditPertiicullarTrasation,
  getPortfolioHolding,
  getSearchData,
  getStockTransationPortfolio,
  removeStockFromportfolio,
} from "../../api/fetchClient";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import { dateFrmater } from "./DateFormaterCustom";

function HoldingsPortfolio({
  lightMode,
  portfolioid,
  setPortfoliostocksdata,
  Portfoliostocksdata,
  addStocks,
  setaddStocks,
  isPopoverisopen,
  settrigger,
  trigger,
  currectPortplioName,
  IsOverFlowHidden
}) {
  const [inputReturns, setInputReturns] = useState();
  const [date, setdate] = useState();
  const [Boughtprice, setBoughtprice] = useState();
  const [InputDataisOnChange, setInputDataIsOnChange] = useState();
  const [Inputdatais, setInputdatais] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isLoading1, setisLoading1] = useState(false);
  const [transationPopup, settransationPopup] = useState(false);
  const [Visible, setVisible] = useState(isPopoverisopen);
  const [Qty, setQty] = useState();
  const [addMoreStocks, setaddMoreStocks] = useState(false);
  const [deleteModel, setdeleteModel] = useState(false);
  const [Allltransations, setAllltransations] = useState(false);
  const [tempstock, settempstock] = useState({});
  const [updateAndDelete, setupdateAndDelete] = useState();
  const [trasationstockid, settrasationstockid] = useState();
  const [trasationEditModel, settrasationEditModel] = useState(false);
  const [allTransationsTable, setallTransationsTable] = useState([]);
  const [perticularTrasationid, setperticularTrasationid] = useState();
  const [perticularShares, setperticularShares] = useState();
  const [perticularSharePrice, setperticularSharePrice] = useState();
  const [Datedata, setDatedata] = useState();
  const [fetchError, setfetchError] = useState({ cmp: "", saleError: "" });
  const [typeData, settypeData] = useState();
  const [currentMarketPrice, setcurrentMarketPrice] = useState();
  function reverseString(str) {
    const date = new Date(str);
    const newDate = (date.getDate(), date.getMonth() + 1, date.getFullYear());
    return newDate;
  }
  
  
  const Portfoliostocksdata1 = Portfoliostocksdata?.sort((a, b) => {
    return a.id - b.id;
  });

  const dateFormat1 = ["YYYY-MM-DD", "YYYY-MM-DD"];

  const onChange = (date, dateString) => {
    const date1 = new Date(dateString);
    setdate(dateString);
    const NewFormatOfDate = dateFrmater(dateString);

    NewFormatOfDate &&
      getCMP({ Inputdatais, NewFormatOfDate })
        .then((res) => {
          setBoughtprice(res?.data?.cmp);
          setfetchError("");
        })
        .catch((error) => {
          setfetchError({ cmp: error?.response?.data?.error });
        });
  };
  const dateFormat = ["DD-MM-YYYY", "DD-MM-YY"];

  useEffect(() => {
    setisLoading(true);
    getPortfolioHolding(portfolioid).then((res) => {
      setisLoading(false);
      setPortfoliostocksdata(res?.data);
    });
  }, [portfolioid])
  
  const getAllStocks = () => {
    setisLoading(true);
    getPortfolioHolding(portfolioid).then((res) => {
      setisLoading(false);
      setPortfoliostocksdata(res?.data);
    });
  };

  const addStockToPortfolio = ({ tempstock }, type) => {
    setisLoading(true);

    const data = {
      transcation_type: type,
      portfolio: portfolioid,
      stock: tempstock?.symbol
        ? String(tempstock?.symbol)
        : String(Inputdatais),
      quantity: Number(Qty),
      price: Number(Boughtprice),
      date: dateFrmater(date),
    };
    
    AddStockPortfolioAnalysis({ data })
      .then((res) => {
        setBoughtprice("");
        setQty("");
        setInputdatais("");
        setInputDataIsOnChange("");
        setdate("");
        setInputReturns("");
        setaddStocks(false);
        setaddMoreStocks(false);
        setisLoading(true);
        getAllStocks();
        settrigger(!trigger);
      })
      .catch((error) => {
        setfetchError({ saleError: error?.response?.data?.error });
      });
  };
  const getAllStockTransation = () => {
    if (trasationstockid) {
      setisLoading1(true);
      getStockTransationPortfolio(trasationstockid).then((res) => {
        setallTransationsTable(res?.data);
        setisLoading1(false);
      });
    }
  };

  const editPerticularTrasationdata = () => {
    const data = {
      quantity: perticularShares,
      price: currentMarketPrice,
    };
    getEditPertiicullarTrasation({ perticularTrasationid, data }).then(
      (res) => {
        setperticularShares("");
        setperticularSharePrice("");
        settrasationEditModel(false);
        getAllStockTransation();
        getAllStocks();
      }
    );
  };

  useEffect(() => {
    setisLoading1(true);

    getStockTransationPortfolio(trasationstockid).then((res) => {
      setallTransationsTable(res?.data);
      setisLoading1(false);
    });
  }, [trasationstockid]);

  const delStockFormPortfolio = ({ updateAndDelete }) => {
    removeStockFromportfolio(updateAndDelete).then((res) => {
      setdeleteModel(false);
      getAllStocks();
    });
  };

  useEffect(() => {
    getPortfolioHolding( portfolioid ).then((res) => {
      setPortfoliostocksdata(res?.data);
    });
  }, [portfolioid]);
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const dropdowndata = [];

  {
    inputReturns &&
      inputReturns.map((items) => {
        dropdowndata.push({
          // value: isNaN(items.symbol) ? items.symbol : items.symbol,
          value: items.symbol,
          label: items.company,
        });
      });
  }

  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
      dataIndex: "name",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated`}>
              <span
                className="link-hover-underline"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            </Link>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">CMP</p>,
      dataIndex: "cmp",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Qty </p>,
      width: "50px",
      dataIndex: "quantity",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Average Price</p>,
      dataIndex: "avg_price",
      width: "50px",

      render(text, record) {
        const toFixedValue = text.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(Number(toFixedValue))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Holding %</p>,
      dataIndex: "holding_percent",
      width: "50px",

      render(text, record) {
        const toFixedValue = text?.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text ?  numberWithCommas(Number(toFixedValue))+'%':0}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Investment Value</p>,
      dataIndex: "total_value",
      width: "50px",

      render(text, record) {
        const toFixedValue = text.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(Number(toFixedValue))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Current Value</p>,
      dataIndex: "total_value",
      width: "50px",

      render(text, record) {
        const toFixedValue = (record?.cmp * record?.quantity).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(Number(toFixedValue))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">P/L %</p>,
      dataIndex: "profit_and_loss",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text >= 0 && "+" + " "} {numberWithCommas(text) + " " + "%"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-12 fw-600 mb-0"></p>,
      dataIndex: "prev_close",
      width: "15px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato d-flex align-items-center dsds pointer"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Popover
                //  key={key}
                color={lightMode ? "white" : "#3B3F4F"}
                className="nameis"
                openClassName=""
                overlayClassName="Nopadding-pover"
                placement="right"
                style={{ width: "150px" }}
                content={
                  <div className="">
                    <div
                      className={`${
                        lightMode ? "text-dark-gray" : "text-white"
                      }`}
                    >
                      <div
                        className={`"d-flex p-3-16   align-items-center  " ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p
                          onClick={() => {
                            setaddMoreStocks(true);
                            setVisible(false);
                            setfetchError("");
                            setInputdatais(record?.symbol);
                            settempstock({
                              name: record?.name,
                              symbol: record?.symbol,
                              type: "buy",
                            });
                            // setupdateAndDelete(items?.id);
                          }}
                          className="ml-5 fs-s-12 fw-600 mb-0 pointer"
                        >
                          Add More
                        </p>
                      </div>
                      <div
                        className={`"d-flex  p-3-16 align-items-center " ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p
                          onClick={() => {
                            setaddMoreStocks(true);
                            setInputdatais(record?.symbol);
                            setVisible(false);
                            setfetchError("");
                            settempstock({
                              name: record?.name,
                              symbol: record?.symbol,
                              type: "sell",
                            });
                            // setupdateAndDelete(items?.id);
                          }}
                          className="ml-5 fs-s-12  fw-600 mb-0 pointer"
                        >
                          Sell
                        </p>
                      </div>

                      <div
                        className={`"d-flex p-3-16   align-items-center" ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p
                          onClick={() => {
                            settrasationstockid(record?.id);
                            setAllltransations(true);
                            getAllStockTransation();
                            setVisible(false);
                            settempstock({
                              name: record?.name,
                              symbol: record?.symbol,
                              type: "buy",
                            });
                          }}
                          className="ml-5 fs-s-12  fw-600 mb-0 pointer"
                        >
                          View Transactions
                        </p>
                      </div>
                      <div
                        className={`"d-flex align-items-center p-3-16   " ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p
                          onClick={() => {
                            setupdateAndDelete(record?.id);
                            setdeleteModel(true);
                            setVisible(false);
                            setfetchError("");
                            // setupdateAndDelete(items?.id);
                            // setVisible(false);
                          }}
                          className="ml-5 fs-s-12  fw-600 mb-0 pointer"
                        >
                          Remove Stock
                        </p>
                      </div>
                    </div>
                  </div>
                }
                trigger="click"
                visible={
                  String(Inputdatais) === String(record?.symbol) && Visible
                }
                onVisibleChange={handleVisibleChange}
              >
                <p
                  onClick={() => {
                    setVisible(true);
                    setInputdatais(record?.symbol);
                  }}
                  className="mb-0"
                >
                  <MoreOutlined />
                </p>
              </Popover>
            </span>
          ),
        };
      },
    },
  ];
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Date</p>,
      dataIndex: "date",
      fixed: "left",
      width: "30px",
      render(text, record) {
        const dateformate = text
          ? moment(text).format(dateFormat[0])
          : undefined;
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {dateformate}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Type</p>,
      dataIndex: "transcation_type",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text === "buy" ? 1 : -10, lightMode),
              }}
            >
              {text === "buy" ? "Buy" : "Sell"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">No.of.shares </p>,
      width: "50px",
      dataIndex: "quantity",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Per Share Price</p>,
      dataIndex: "price",
      width: "50px",

      render(text, record) {
        const toFixedValue = text.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(Number(toFixedValue))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Total Value</p>,
      dataIndex: "total_value",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {"₹"}
              {numberWithCommas(Number(text))}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-12 fw-600 mb-0"></p>,
      dataIndex: "prev_close",
      width: "15px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato d-flex align-items-center pointer"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Popover
                //  key={key}
                color={lightMode ? "white" : "#3B3F4F"}
                className="nameis"
                placement="right"
                overlayClassName="Nopadding-pover"
                content={
                  <div className="">
                    <div
                      className={`${
                        lightMode ? "text-dark-gray" : "text-white"
                      }`}
                    >
                      <div
                        className={` p-3-16 ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p
                          onClick={() => {
                            setperticularTrasationid(record?.id);
                            settrasationEditModel(true);
                            setDatedata(
                              record?.date
                                ? moment(record?.date).format(dateFormat[0])
                                : undefined
                            );
                            settransationPopup(false);
                            settypeData(
                              record?.transcation_type === "buy"
                                ? "Buy"
                                : "Sell"
                            );
                            setcurrentMarketPrice(record?.price);
                            // getAllStockTransation()
                          }}
                          className="  fs-s-12  fw-600 mb-0 pointer"
                        >
                          Edit
                        </p>
                      </div>
                      {/* <div
                        className={` p-3-16 ${
                          lightMode
                            ? "popOver-child-light"
                            : "popOver-child-dark"
                        }`}
                      >
                        <p className=" fs-s-12  fw-600 mb-3 pointer">Delete</p>
                      </div> */}
                    </div>
                  </div>
                }
                trigger="click"
                visible={
                  String(perticularTrasationid) === String(record?.id) &&
                  transationPopup
                }
                onVisibleChange={(visible) => settransationPopup(visible)}
              >
                <p
                  onClick={() => {
                    setperticularTrasationid(record?.id);
                    settransationPopup(true);
                  }}
                  className="mb-0"
                >
                  <MoreOutlined />
                </p>
              </Popover>
            </span>
          ),
        };
      },
    },
  ];

  const getResults = async (e) => {
    // setinputData(e.target.value);
    const response = await getSearchData(e)
      .then((resp) => {
        setInputReturns(resp.data);
      })
      .catch((err) => console.log(err));
  };


  return (
    <div className="d-flex flex-col mt-30">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="main-header">Holdings</h2>

      {!IsOverFlowHidden &&  <button
          onClick={() => setaddStocks(true)}
          className="p-10 btn-bg-primary br-3 pointer d-flex align-items-center text-white"
          style={{ height: "30px" }}
        >
          Add stocks
        </button>}
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={columns}
          data={Portfoliostocksdata1?.reverse()}
          scrollable={true}
          loading={isLoading}
        />
            
      </div>
        {console.log("currectPortplioName",currectPortplioName)}
      <div className="d-flex d-flex-row-col justify-content-between w-100">
        <span className="fs-s-12 text-gray">
          *The values shown here are based on yesterday's closing prices and will be updated at the end of the day.
        </span>
        <div className="d-flex justify-content-center">
        <Link
          to={`alltraderecords`}
          state={{ id: portfolioid, name: currectPortplioName }}
        >
        { !IsOverFlowHidden && <p
            style={{ color: "#9A9DA3" }}
            className={`fs-s-16 mb-0  mt-10 link-hover-underline pointer  `}
          >
            View all Trade Records
          </p>}
        </Link>
        </div>

      </div>
      {/* <Modal
        title={
          <p
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Add stocks
          </p>
        }
        visible={addStocks}
        centered
        // onOk={handleOk}
        wrapClassName={!lightMode && "modelClassname"}
        onCancel={() => setaddStocks(false)}
        footer={[
          <button
            key="submit"
            type="primary"
            className={` w-150px br-5 fs-s-16 fw-500 pointer btn-bg-primary text-white ${
              lightMode ? "  h-45" : "h-45 "
            }`}
            onClick={() => addStockToPortfolio(" ", "buy")}
          >
            Add
          </button>,
        ]}
      >
        <div
          // style={{ height: "240px" 
          className="w-100 d-flex flex-col justify-content-between"
        >

          <AutoComplete
            className={
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode antd-Selete-Custom-dark mb-10"
            }
            style={{
              width: "100%",
            }}
            notFoundContent
            defaultValue={""}
            onFocus={() => getResults("")}
            allowClear={true}
            placeholder=" Select Stock"
            fieldNames={"firstOne"}
            options={dropdowndata}
            dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
            value={InputDataisOnChange}
            onChange={(e) => {
              getResults(e);
              setInputDataIsOnChange(e);
            }}
            defaultActiveFirstOption={true}
            onSelect={(e, options) => {
              setInputdatais(e);

              setInputDataIsOnChange(options.label);
            }}
          />
          <DatePicker
            className={!lightMode && "DatePicker-Antd-Dark"}
            dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
            value={date ? moment(date, dateFormat) : undefined}
            onChange={onChange}
            // value={date}
          />
          
          <input
            width={"100%"}
            // value={NewWatchList}
            style={{ width: "100%" }}
            placeholder="Enter Bought Price"
            value={Boughtprice}
            onChange={(e) => setBoughtprice(e.target.value)}
            type="text"
            className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          {fetchError.cmp ? (
            <span
              className="mb-0"
              style={{ color: lightMode ? "#FF0000" : "#F82E2E" }}
            >
              {fetchError.cmp}
            </span>
          ) : (
            ""
          )}

          <input
            width={"100%"}
            // value={NewWatchList}
            style={{ width: "100%" }}
            placeholder="Enter Quantity"
            onChange={(e) => setQty(e.target.value)}
            type="text"
            value={Qty}
            className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
        </div>
      </Modal> */}

      <Modal
        title={
          <p
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            {tempstock?.name}
          </p>
        }
        visible={addMoreStocks}
        centered
        // onOk={handleOk}
        wrapClassName={!lightMode && "modelClassname"}
        onCancel={() => {
          setaddMoreStocks(false);
          setdate("");
          setBoughtprice("");
          setQty("");
        }}
        footer={[
          <button
            key="submit"
            type="primary"
            className={` w-150px br-5 fs-s-16 fw-500 pointer btn-bg-primary text-white ${
              lightMode ? "  h-45" : "h-45 "
            }`}
            onClick={() => Qty && addStockToPortfolio({ tempstock }, tempstock?.type)}
          >
            {tempstock?.type === "sell" ? "Sell" : "Add"}
          </button>,
        ]}
      >
        <div
          // style={{ height: "240px" }}
          className="w-100 d-flex flex-col justify-content-between"
        >
          <p className="fs-s-12 mb-10">Date</p>

          <DatePicker
            className={!lightMode && "DatePicker-Antd-Dark"}
            // defaultValue={moment('2015/01/01', dateFormat)}
            disabledDate={disabledDate}
            format={dateFormat}
            dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
            value={date ? moment(date, dateFormat) : undefined}
            onChange={onChange}
            inputReadOnly={true}
          />
          <p className="fs-s-12 my-10">Per Share Price</p>
          <div className="d-flex   w-100">
            <input
              value={"₹"}
              className={`${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              } ff-lato`}
              style={{
                width: "5%",
                background: lightMode ? "white" : "#3B3F4F",
              }}
              width={"5%"}
              disabled
            ></input>
            <input
              width={"95%"}
              addonBefore="http://"
              // value={NewWatchList}
              style={{ width: "95%" }}
              placeholder="Enter Bought Price"
              value={Boughtprice}
              onChange={(e) => setBoughtprice(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
          </div>
          {fetchError.cmp ? (
            <span
              className="mb-0"
              style={{ color: lightMode ? "#FF0000" : "#F82E2E" }}
            >
              {fetchError.cmp}
            </span>
          ) : (
            ""
          )}
          <p className="fs-s-12 my-10">
            
          </p>

          <input
            width={"100%"}
            // value={NewWatchList}
            style={{ width: "100%" }}
            placeholder="Enter Quantity"
            value={Qty}
            onChange={(e) => setQty(e.target.value)}
            type="text"
            className={`w-100 h-45 br-3 p-3  portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          {fetchError.saleError ? (
            <span style={{ color: lightMode ? "#FF0000" : "#F82E2E" }}>
              {fetchError.saleError}
            </span>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <Modal
        title={
          <p
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Remove Stock
          </p>
        }
        visible={deleteModel}
        centered
        wrapClassName={!lightMode && "modelClassname"}
        // onOk={}
        onCancel={() => setdeleteModel(false)}
        footer={[
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 ml-10 fs-s-12">
              <InfoCircleFilled style={{ marginRight: "5px" }} />
              Once deleted, Data wont be retrive back
            </p>
            <button
              key="submit"
              style={{ background: "#AA1919" }}
              type="primary"
              className={` w-150px br-5 pointer fs-s-16 fw-600 ${
                lightMode ? " text-white h-45" : " h-45 "
              }`}
              onClick={() => delStockFormPortfolio({ updateAndDelete })}
            >
              Confirm
            </button>
          </div>,
        ]}
      >
        <div className="w-100   ">
          <p className="mb-0 ">Are you sure you want to remove the stock</p>
        </div>
      </Modal>
      <Modal
        title={
          <div>
            <p
              style={{ marginBottom: "5px" }}
              className={`${lightMode ? "text-dark" : "text-white"} "fs-s-12 "`}
            >
              All Transactions
            </p>
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              {tempstock?.name} - {"  "} {allTransationsTable?.no_of_shares} Shares
            </p>
          </div>
        }
        visible={Allltransations}
        centered
        width={"80%"}
        wrapClassName={!lightMode && "modelClassname"}
        // onOk={}
        onCancel={() => setAllltransations(false)}
        footer={
          [
            // <button
            //   key="submit"
            //   style={{ background: "#AA1919" }}
            //   type="primary"
            //   className={` w-150px br-5 pointer fs-s-16 fw-600 ${
            //     lightMode ? " text-white h-45" : " h-45 "
            //   }`}
            //   onClick={()=>delStockFormPortfolio({updateAndDelete})}
            // >
            //   Confirm
            // </button>,
          ]
        }
      >
        <div className="w-100">
          <div
            className={`table-shadow   ${
              lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
            }`}
          >
            <CustomTable
              columns={columns1}
              data={allTransationsTable?.transcations}
              scrollable={true}
              loading={isLoading}
            />
          </div>
        </div>
      </Modal>

      {/* trasation edit */}

      <Modal
        title={
          <p
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            {tempstock?.name}
          </p>
        }
        visible={trasationEditModel}
        centered
        // onOk={handleOk}
        wrapClassName={!lightMode && "modelClassname"}
        onCancel={() => {
          settrasationEditModel(false);
          setperticularShares("");
        }}
        footer={[
          <button
            key="submit"
            type="primary"
            className={` w-150px br-5 fs-s-16 fw-500 pointer btn-bg-primary text-white ${
              lightMode ? "  h-45" : "h-45 "
            }`}
            onClick={() => editPerticularTrasationdata()}
          >
            Save
          </button>,
        ]}
      >
        <div
          // style={{ height: "240px" }}
          className="w-100 d-flex flex-col justify-content-between"
        >
          <p className="fs-s-12 my-10">Date</p>
          <input
            width={"100%"}
            style={{ width: "100%", color: "gray" }}
            // placeholder="No.of.shares"
            value={Datedata}
            // onChange={(e) => setperticularShares(e.target.value)}
            disabled
            type="text"
            className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode "
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          <p className="fs-s-12 my-10">Type</p>

          <input
            width={"100%"}
            // value={NewWatchList}
            style={{ width: "100%", color: "gray" }}
            placeholder="Per Share Price"
            value={typeData}
            disabled
            // onChange={(e) => setperticularSharePrice(e.target.value)}
            type="text"
            className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>

          <p className="fs-s-12 my-10"> No.Of.shares</p>

          <input
            width={"100%"}
            // value={NewWatchList}
            style={{ width: "100%" }}
            placeholder="Enter Quantity"
            value={perticularShares}
            onChange={(e) => setperticularShares(e.target.value)}
            type="number"
            className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          <p className="fs-s-12 my-10"> Per Share Price</p>
          <div className="d-flex w-100">
            <input
              value={"₹"}
              className={`${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              } ff-lato`}
              style={{
                width: "5%",
                background: lightMode ? "white" : "#3B3F4F",
              }}
              width={"5%"}
              disabled
            ></input>
            <input
              width={"95%"}
              // value={NewWatchList}
              style={{ width: "95%" }}
              placeholder="Per Share Price"
              value={currentMarketPrice}
              onChange={(e) => setcurrentMarketPrice(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
          </div>

          <p className="fs-s-12 my-10">Total Value</p>
          <div className="d-flex w-100">
            <input
              value={"₹"}
              className={`${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              } ff-lato`}
              style={{
                width: "5%",
                background: lightMode ? "white" : "#3B3F4F",
              }}
              width={"5%"}
              disabled
            ></input>
            <input
              width={"95%"}
              // value={NewWatchList}
              style={{ width: "95%" }}
              placeholder="Total Value"
              disabled
              value={
                perticularShares &&
                currentMarketPrice &&
                perticularShares * currentMarketPrice
              }
              onChange={(e) => setcurrentMarketPrice(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HoldingsPortfolio;
