import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Image, message } from "antd";
import { putUserRoleUpdate } from "../../../../api/fetchClient";

const UserAboutSection = ({ userData, lightMode }) => {
  console.log(userData, "userData");
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };
  const dropdownRoles = [
    {
      label: "Administrator",
      value: "admin",
    },
    {
      label: "User",
      value: "user",
    },
  ];
  const { Option } = Select;

  const handleChange = async (value) => {
    const payload = {
      email: userData?.email,
      type: value,
    };
    const res = await putUserRoleUpdate(payload);
    console.log(res, "api res");
    if (res?.success) {
      success(res?.success);
    }
  };
  const onFinish = (values) => {};

  return (
    <>
       {contextHolder}
      {userData !== undefined && (
        <>
          <h1 className="fs-27-16 fw-600  mb-10">About the User</h1>
          <div className="flex mb-20">
            <Image
              width={100}
              height={100}
              src="https://portal.tradebrains.in/_next/image?url=https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAAcHTteQ5kmWFlQXXuE_m4TZv3aEUo-nAk5I9--7RSRyHPH7%3Ds96-c&w=48&q=75"
            />
            <div>
              <h2>{userData?.username}</h2>
              <p>{userData?.email}</p>
              {/* <p>Active</p>
              <p>
              Created on 21 June 2023
              </p> */}
              {console.log(userData, "userData")}
            </div>
          </div>
          <Form
            form={form}
            initialValues={userData}
            onFinish={onFinish}
            labelCol={{ span: 6 }}>
            <Form.Item
              className={`w-40  form-label ${
                lightMode ? "light-input-login" : "dark-input-login "
              }`}
              name="username"
              label="Username*">
              <Input
                disabled={true}
                className={`w-100 h-40px auth-form-input disabled-text ${
                  lightMode
                    ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                    : "bg-dark-gray email-input-login-page"
                }`}
              />
            </Form.Item>
            <Form.Item
              className={`w-40  form-label ${
                lightMode ? "light-input-login " : "dark-input-login "
              }`}
              name="email"
              label="Email*">
              <Input
                disabled={true}
                className={`w-100 h-40px auth-form-input disabled-text ${
                  lightMode
                    ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                    : "bg-dark-gray email-input-login-page"
                }`}
              />
            </Form.Item>
            <Form.Item
              className={`w-40  form-label ${
                lightMode ? "light-input-login " : "dark-input-login "
              }`}
              name="name"
              label="Display Name">
              <Input
                disabled={true}
                className={`w-100 h-40px auth-form-input disabled-text ${
                  lightMode
                    ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                    : "bg-dark-gray email-input-login-page"
                }`}
              />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role"
              className={`w-40  form-label ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}>
              <Select
                // disabled={addCoupon ? false : true}
                className={
                  lightMode
                    ? "br-5 contact-select-light bg-gray border1px-light-mode"
                    : "br-5  contact-select-dark bg-d ark-gray border1px-dark-mode"
                }
                dropdownClassName={`${
                  !lightMode && "drop-down-stock invert-text"
                }`}
                value={"Asdasd"}
                onChange={(e) => handleChange(e)}
                style={{
                  width: "100%",
                  height: "40px",
                  margin: "6px 0px",
                }}>
                {dropdownRoles.map((items, i) => (
                  <Option key={i} value={items.value}>
                    {items.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              label="Biographical Info"
              name=""
              className={`w-40 form-label ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
            >
              <Input.TextArea
                type="text"
                defaultValue={""}
                className={`w-100  auth-form-input   ${
                  lightMode
                    ? "bg-gray ant-textarea-light "
                    : "bg-dark-gray  ant-textarea-dark                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "
                }`}
                style={{
                  width: "100%",
                  height: "140px",
                  background: lightMode ? "white" : "#3B3F4F",
                  color: lightMode ? "black" : "white",
                }}
                placeholder="type here"
              />
            </Form.Item> */}
            {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
          </Form>
        </>
      )}
    </>
  );
};

export default UserAboutSection;
