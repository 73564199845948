import { Skeleton } from "antd";
import React from "react";
import StockEndpoints from "../../../api/endPoints";
import NoData from "../../../components/NoData";
import useGetFetch from "../../../hooks/useGetFetch";
import {
  getFormatDateAgo,
  getFormatDate,
} from "../../../utilityFn/getFormatdate";

function BSEAnnouncements({ lightMode, symbol }) {
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.stockAnnouncement(symbol)
  );

  if (isLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <div>
      {
        apiData?.length===0&&isLoading===false?
        (
          <div style={{minHeight:'400px'}} className="d-flex div-items-center">
          <NoData errorText={'No Data'}/>
          </div>
        )
        :
      <>
      {apiData?.map((item, i) => (
        <a href={item.attachmenturl} target="_blank" key={i} className="mt-15">
          <div className="new data d-flex mt-20">
            <div
              className={`d-flex flex-col ${
                lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
              } ml-10 justify-content-between w-100`}
            >
              <div className="px-10px">
                <p className="fs-s-14">{item.caption}</p>
              </div>
              <div className="d-flex mb-3">
                <p className="fs-s-12 m-0 brb-1 mr-2 px-10px ">
                  {getFormatDate(item.datetime)}
                </p>
                <p className="fs-s-12 m-0 px-10px">
                  {getFormatDateAgo(item.datetime)}
                </p>
              </div>
            </div>
          </div>
        </a>
      ))}
      </>
    }
    </div>
  );
}

export default BSEAnnouncements;
