import svgSheet from "../../assets/svg/svgSheet";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { getMonth } from "../../utilityFn/getMonth";
import CustomTooltip from "../../components/ant/CustomTooltip";

export const createQuarterTable = (apiData, tableType, lightMode) => {
  // hedaer values
  const headerIndexes = [];
  const FirstNestedRows = [];
  const highlightRows = [];
  // find header indexes
  const indexes = tableType?.map((item, i) => {
    if (item.isHeader) headerIndexes.push(i);
  });
  //  find first nested data
  const nested = tableType?.map((item, i) => {
    if (item.isNested || item.isNested2x) FirstNestedRows.push(i);
  });
  // find highlighted rows
  const bgRows = tableType?.map((item, i) => {
    if (item.highlight) highlightRows.push(i);
  });

  // find header indexes
  let YearHeaders = Object.keys(apiData)?.map((key, i) => {
    const year = key.substring(0, key.length - 2);
    const month = key.slice(4);
    const combineDate = `${getMonth(month)} ${year}`;
    return {
      title: <p className="fw-600 mb-0 fs-s-16">{combineDate}</p>,
      dataIndex: Number(key),
      key: Number(key),
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? highlightRows.includes(index.key - 1)
                ? {
                    background: "#E4F0FA",
                    borderBottom: "3px solid #D3E8FB",
                    borderRight: "1px solid #C9D7DE",
                  }
                : {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
              : highlightRows.includes(index.key - 1)
              ? {
                  background: "#494F65",
                  borderRight: "1px solid #545E78",
                  borderBottom: "2px solid #545E78",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {tableType[index.key - 1]?.unit === "₹"
                ? tableType[index.key - 1]?.unit
                : ""}{" "}
              {numberWithCommas(text)}{" "}
              {tableType[index.key - 1]?.unit === "%"
                ? tableType[index.key - 1]?.unit
                : ""}
            </span>
          ),
        };
      },
    };
  });
  YearHeaders = [
    {
      title: <p className="fw-600 mb-0 fs-s-16">PARTICULARS</p>,
      dataIndex: "PARTICULARS",
      fixed: "left",
      width: "70px",
      render: (text, index) => {
        return {
          // borderColor:'#C9D7DE'
          props: {
            style: lightMode
              ? highlightRows.includes(index.key - 1)
                ? {
                    background: "#E4F0FA",
                    borderBottom: "3px solid #D3E8FB",
                    borderRight: "1px solid #C9D7DE",
                    borderLeft: "1px solid #C9D7DE",
                    fontSize: "14px",
                  }
                : {
                    background: "rgba(242, 246, 248, 1)",
                    fontSize: "14px",
                    borderRight: "1px solid #C9D7DE",
                    borderLeft: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
              : highlightRows.includes(index.key - 1)
              ? {
                  background: "#494F65",
                  borderRight: "1px solid #545E78",
                  borderBottom: "2px solid #545E78",
                  borderLeft: "1px solid #545E78",
                  borderTop: "1px solid #545E78",
                  fontSize: "14px",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  fontSize: "14px",
                },
          },
          children: (
            <div style={{ color: lightMode ? "black" : "white" }}>
              {headerIndexes.includes(index.key - 1) ? (
                <div
                  className="d-flex  justify-content-between align-items-center"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  <p className="mb-0">{text}</p>
                  {index.tooltipText && (
                    <CustomTooltip text={index?.tooltipText}>
                      {svgSheet.infoTooltop}
                    </CustomTooltip>
                  )}
                </div>
              ) : FirstNestedRows.includes(index.key - 1) ? (
                <div
                  style={{ fontWeight: "400", paddingLeft: "15px" }}
                  className="fs-s-14 d-flex  justify-content-between align-items-center"
                >
                  <p className="mb-0">{text}</p>
                  {index.tooltipText && (
                    <CustomTooltip text={index?.tooltipText}>
                      {svgSheet.infoTooltop}
                    </CustomTooltip>
                  )}
                </div>
              ) : (
                <div
                  className="d-flex  justify-content-between align-items-center"
                  style={{ fontWeight: "400" }}
                >
                  <p className="mb-0">{text}</p>
                  {index.tooltipText && (
                    <CustomTooltip text={index?.tooltipText}>
                      {svgSheet.infoTooltop}
                    </CustomTooltip>
                  )}
                </div>
              )}
            </div>
          ),
        };
      },
    },
    ...YearHeaders,
  ];
  // total years array
  const years = Object.keys(apiData);
  // body data array
  const tableBodyData = tableType?.map((item, index) => {
    let YearArryaData = {};
    if (item.isHeader) {
      return {
        key: index + 1,
        PARTICULARS: item.name,
        tooltipText:item.tooltipText
      };
    } else {
      const yearData = Object.values(apiData).map((value, i) => {
        YearArryaData = { ...YearArryaData, [years[i]]: value[item.key] ?? 0 };
      });
      return {
        key: index + 1,
        PARTICULARS: item.name,
        tooltipText:item.tooltipText,
        ...YearArryaData,
      };
    }
  });
  return { YearHeaders, tableBodyData };
};
