import React from "react";
import { Link } from "react-router-dom";
import NoData from "../../components/NoData";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import loadergif from "../../assets/images/loadergif/Free-Content.gif";
import PremiumBlurLayout from "../../components/PremiumBlurLayout";
import svgSheet from "../../assets/svg/svgSheet";
import PremiumCard from "../../components/PremiumCard";

function UserProductCard({ lightMode, head, tableContent, bottomNav,isPremium=false,tabletype }) {
  
  return (
    <div
      className={`card-shadow relative h-100 w-100 p-10md-20lg br-3 ${
        lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <h3 className="fs-20-16 fw-700 mb-20">{head}</h3>
      {isPremium
      ?
    <PremiumCard headerText='Unlock Portfolio Analysis'/>
      :
      <div
        style={{ height: "245px", overflow: "auto",gap:'10px' }}
        className="d-flex scroll flex-col"
      >
        {tableContent[0] !== "EMPTY"&&tableContent[0] !== "LOADING" ? (
          tableContent?.map((content, i) => (
            <div key={i} className="flex justify-content-between">
              <p style={{maxWidth:'65%'}}  className="fs-16-14 fw-500 mb-0">{content.label}</p>
              {
                tabletype==="watchlist"?(
                  content.value
                )
                :
                <p
                style={{
                  color:
                    content.unit === "%"
                      ? redGreenColorPicker(content.value, lightMode)
                      : "currentcolor",
                }}
                className="fs-16-14 fw-500 ff-lato"
              >
                {content.unit === "₹" && content.unit}{" "}
                {numberWithCommas(content.value)}
                {content.unit === "%" && content.unit}
              </p>
              }
             
            </div>
          ))
        ) : 
        tableContent[0] === "LOADING"?
        <div
          className="d-flex div-items-center w-100 h-100"
        >
          <img className="h-50px w-50px" src={loadergif} alt="load" />
        </div>
        :
        (
          <NoData errorText="Add Stocks"/>
        )}
      </div>}
      {!isPremium&&<Link style={{position:'absolute',bottom:'20px',left:'50%',transform:'translateX(-50%)'}}
        className="d-flex justify-content-center underline mt-20"
        to={bottomNav.link}
      >
        {bottomNav.text}
      </Link>}
    </div>
  );
}

export default UserProductCard;
