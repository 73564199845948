import React from "react";
import ScreenCard from "./components/ScreenCard";
import bluechipIcon from "../../assets/images/popular sceens/bluechipIcon.svg";
import debtFreeIcon from "../../assets/images/popular sceens/debtFreeIcon.svg";
import dividentIcon from "../../assets/images/popular sceens/dividentIcon.svg";
import pennyIcon from "../../assets/images/popular sceens/pennyIcon.svg";
import screenerFormula from "./components/screenerFormula";
import { useDispatch } from "react-redux";
import { postScreenerData } from "../../redux/reducers/screenerSlice";
import { animateScroll as scroll } from "react-scroll";
function PopularScreens({ lightMode, setfilterTypeArr,mobView }) {
  const dispatch = useDispatch();
  const cardContents = [
    {
      icon: bluechipIcon,
      head: "Blue Chip Stocks",
      content:
        "List of the well established firms with a history of consistent performance.",
      screenerData: screenerFormula.bluechipScreen,
    },
    {
      icon: debtFreeIcon,
      head: "Debt Free Stocks ",
      content:
        "Indian Stocks that are virtually debt free and safer investment options.",
      screenerData: screenerFormula.debtFreeScreen,
    },
    {
      icon: pennyIcon,
      head: "Penny Stocks",
      content: "List of penny stocks in India with Strong Fundamentals.",
      screenerData: screenerFormula.pennyScreen,
    },
    {
      icon: dividentIcon,
      head: "Dividend Superstars",
      content: "Companies that pay High Dividends.",
      screenerData: screenerFormula.dividentScreen,
    },
  ];
  const applyFilter = (data) => {
    setfilterTypeArr(data);
    dispatch(
      postScreenerData({
        applyFilters: data,
        industries: [],
        indices: [],
      })
    );
    scroll.scrollTo(0, 0);
  };
  return (
    <div className={mobView? "home-screen-grid":"bucket-card-wrap"}>
      {cardContents?.map((card, i) => (
        <ScreenCard
          key={i}
          lightMode={lightMode}
          icon={card.icon}
          head={card.head}
          content={card.content}
          onClickFn={() => applyFilter(card.screenerData)}
        />
      ))}
    </div>
  );
}

export default PopularScreens;
