import { Form, message } from "antd";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { putStarPortfolio } from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import PorrtfolioForm from "./PorrtfolioForm";

function Editportfolio() {
  const params = useParams();
  const { state: urlState } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const keywords = urlState.data.keywords?.split("|")?.map((item) => {
    return { keyword: item };
  });
  const [keywordArr, setkeywordArr] = useState(keywords);
  const onSubmit = async () => {
    const values = form.getFieldsValue();
    const convertKeyword = keywordArr?.map((item) => item.keyword)?.join("|");
    const convertData = { ...values, keywords: convertKeyword };
    const postData = new FormData();
    Object.entries(convertData).map((res) => {
      postData.append(res[0], res[1]);
    });
    await putStarPortfolio(postData, params.id)
      .then((response) => {
        if (response.status === 200) {
          message.success("Portfolio Updated successfully");
          navigate("/admin/star_portfolio");
        }
      })
      .catch((err) => console.log(err));
  };
  const breadCrumbData = [
    { label: "Superstar Portfolio", link: `/admin/star_portfolio` },
  ];
  const formInitValues = useMemo(() => {
    const newValue = { ...urlState?.data };
    delete newValue.premium;
    delete newValue.id;
    delete newValue.keywords;
    return newValue;
  }, [urlState.data]);

  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb linkData={breadCrumbData} current={urlState?.data?.name} />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">{urlState?.data?.name}</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => onSubmit()}
          className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
        >
          Update
        </button>
      </div>
      <PorrtfolioForm
        // formSubmit={onSubmit}
        form={form}
        keywordArr={keywordArr}
        setkeywordArr={setkeywordArr}
        initValues={formInitValues}
      />
    </div>
  );
}

export default Editportfolio;
