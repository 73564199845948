import React from "react";
import noviceImgDark from "../../../assets/images/home/noviceImgDark.png";
import noviceImgLight from "../../../assets/images/home/noviceImgLight.png";
import Stepcard from "./Stepcard";
import svgSheet from "../../../assets/svg/svgSheet";
import noviceBg from "../../../assets/images/bg/noviceBg.png";
import { Link } from "react-router-dom";
function RegisterSteps({ lightMode }) {
  const cardContent = [
    {
      icon: svgSheet.stepOneIcon,
      head: "Step 1",
      content: (
        <Link
          to={"/signup"}
          style={{ maxWidth: "320px" }}
          className="fs-s-14 fw-600 text-blue-grad underline z-10"
        >
          Register Yourself
        </Link>
      ),
    },
    {
      icon: svgSheet.stepTwoIcon,
      head: "Step 2",
      content: (
        <p style={{ maxWidth: "320px" }} className="fs-s14">
          Search for your favourite listed companies
        </p>
      ),
    },
    {
      icon: svgSheet.stepThreeIcon,
      head: "Step 3",
      content: (
        <p style={{ maxWidth: "320px" }} className="fs-s14">
          Browse through easy-to-understand and visually appealing data.
        </p>
      ),
    },
  ];
  return (
    <section className="my-100 home-ring-bg">
      <img
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          // opacity: ".6",
          objectFit: "fill",
        }}
        src={noviceBg}
        alt="bg"
      />
      <div className="max-w flex flex-col-row  mx-auto px-15  w-100">
        <div className="w-100-50">
          <h3 className="fs-40-32 fw-700 mb-30 text-md-center ">
            Novice Investor ?<br />
            Start with our simple steps
          </h3>
          <img
            //   className="mx-auto"
            src={lightMode ? noviceImgLight : noviceImgDark}
            alt="img"
          />
        </div>
        <div className="w-100-50 d-flex flex-col align-md-center">
          <p className="fs-16-14 mt-10 mb-30 text-md-center">
            Don’t know where to begin? Browse through Portal’s
            easy-to-understand and illustrative interface to get a headstart on
            your investing journey.
          </p>
          <div
            style={{ margin: "auto 0", zIndex: 10 }}
            className="flex flex-col"
          >
            {cardContent?.map((card, i) => (
              <Stepcard lightMode={lightMode} data={card} key={i} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterSteps;
