import { MoreOutlined } from "@ant-design/icons";
import { AutoComplete, DatePicker, Modal, Popover } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AddStockPortfolioAnalysis,
  CreatePortfolioAnalysis,
  DeletePortfolioAnalysis,
  getCMP,
  getDividentApi,
  getFundamentalPorfolioAnalysis,
  getMonthlyWiseAnalysedData,
  getPortfolioAnalysis,
  getPortfolioHolding,
  getSearchData,
  UpadatePortfolioAnalysis,
} from "../../api/fetchClient";
// import WatchlistEmpty from "../../assets/images/WatchlistEmpty.png";
import WatchlistEmpty from "../../assets/images/WatchlistEmpty.png";
import svgSheet from "../../assets/svg/svgSheet";
import NoData from "../../components/NoData";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import TimeLineButtons from "../../components/TimeLineButtons";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import { plans } from "../../utilityFn/planData";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import NewsTabStockDetails from "../stock details/Corp Announce/NewsTabStockDetails";
import { dateFrmater } from "./DateFormaterCustom";
import DividendsPortfolioAnalysis from "./DividendsPortfolioAnalysis";
import FundamentalTable from "./FundamentalTable";
import HoldingsPortfolio from "./HoldingsPortfolio";
import KeyPortfolioMetrics from "./KeyPortfolioMetrics";
import MultiGraphPortFolioAnalysis from "./MultiGraphPortFolioAnalysis";
import PortFolioAnalysisList from "./PortFolioAnalysisList";
import PortfolioMainCards from "./PortfolioMainCards";
import PortfolioMobileSlider from "./PortfolioMobileSlider";
import SizeIndustryAnalysisGraph from "./SizeIndustryAnalysisGraph";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import MonthlyWise from "./MonthlyWise";
import ProfileTrackerPortfolioAnalysis from "./ProfileTrackerPortfolioAnalysis";
import portalLogo from "../../assets/images/portalFavICon.png";
import logoWhite from "../../assets/images/logo/logoWhite.png";
import logo from "../../assets/images/PortalLogo.png";
import EventTrackerPortfolioAnalysis from "./EventTrackerPortfolioAnalysis";

function PortfolioAnalysis() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [CreateModel, setCreateModel] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [portfolioName, setportfolioName] = useState();
  const [Portfolios, setPortfolios] = useState([]);
  const [updateAndDeleteid, setupdateAndDelete] = useState();
  const [ChangePortfolio, setChangePortfolio] = useState();
  const [Portfoliostocksdata, setPortfoliostocksdata] = useState([]);
  const [addStocks, setaddStocks] = useState(false);
  const [inputReturns, setInputReturns] = useState();
  const [date, setdate] = useState();
  const [Boughtprice, setBoughtprice] = useState();
  const [InputDataisOnChange, setInputDataIsOnChange] = useState();
  const [Inputdatais, setInputdatais] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isPopoverisopen, setisPopoverisopen] = useState(false);
  const [validation, setvalidation] = useState(false);
  const [trigger, settrigger] = useState(false);
  const dateFormat = ["DD-MM-YYYY", "DD-MM-YYYY"];
  const [divident, setdivident] = useState();
  const [Qty, setQty] = useState();
  const [addMoreStocks, setaddMoreStocks] = useState(false);
  const [inValidInput, setinValidInput] = useState(false);
  const [fetchError, setfetchError] = useState({
    AlreadyCreated: "",
    saleError: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector(authStore);
  const [currectPortplioName, setcurrectPortplioName] = useState();
  const [PlanName, setPlanName] = useState();
  const [portfolioid, setportfolioid] = useState();
  const [IsPremiumModel, setIsPremiumModel] = useState(false);
  const [Selected, setSelected] = useState("quantity");
  const [IsOverFlowHidden, setIsOverFlowHidden] = useState(false);
  const [sortState, setsortState] = useState({
    head: "mcap",
    sortOrder: false,
  });

  //monthly wise data state
  const [MonthlyWise1, setMonthlyWise1] = useState([]);
  const [LoaderMonth, setLoaderMonth] = useState(false);
  const mapRef = document.getElementById("reportTemplateRef");
  const max = { height: 400, width: 200 };
  const handleGeneratePdf = async () => {
    await setIsOverFlowHidden(true);
    const doc = new jsPDF("p", "mm", "a0");
    let array = [];
    const im = new Image();
    // const data = await arrayofimages.map((items, i) => {

    const ImagesPdf = htmlToImage.toPng(mapRef).then(async (dataUrl) => {
      await setIsOverFlowHidden(false);

      // doc.addPage();
      im.src = dataUrl;
      const imwidth = mapRef.clientWidth;
      const imheight = mapRef.clientHeight;
      console.log(im.height, "height");
      im.onload = () => {
        // create a canvas that will present the output image
        const outputImage = document.createElement("canvas");
        // set it to the same size as the image
        outputImage.width = mapRef.clientWidth;
        outputImage.height = mapRef.clientHeight;
      };
      let value = 0;

      // doc.text('Portal Trade Brains', (doc.internal.pageSize.getWidth()/2)-20, 40); //string, x-position, y-position
      for (
        let i = 0;
        i <
        (imheight * doc.internal.pageSize.getWidth()) /
          imwidth /
          doc.internal.pageSize.getHeight();
        i++
      ) {
        await doc.addImage(
          dataUrl,
          "JPEG",
          0,
          -i * doc.internal.pageSize.getHeight(),
          doc.internal.pageSize.getWidth(),
          (imheight * doc.internal.pageSize.getWidth()) / imwidth
        );
        doc.setFillColor(204, 204, 204, 0);
        console.log(
          "this is the i value",
          i,
          (imheight * doc.internal.pageSize.getWidth()) /
            imwidth /
            doc.internal.pageSize.getHeight()
        );
        i <
          Math.floor(
            (imheight * doc.internal.pageSize.getWidth()) /
              imwidth /
              doc.internal.pageSize.getHeight()
          ) && doc.addPage();
      }

      doc.save("TradeBrainsPortal.pdf");
    });
  };

  useEffect(() => {
    setPlanName(auth?.userData?.user?.plan?.planId);
    setportfolioid(Number(location?.pathname?.split("/")[2]));
    currectPortplioName === undefined &&
      setcurrectPortplioName(location?.state);
  }, []);
  const [Fundamental, setFundamental] = useState();
  const [visible, setVisible] = useState(isPopoverisopen);
  // const disabledDate = (current) => {
  //   return {current && current > moment().endOf("day");

  // }
  // };

  function disabledDate(current) {
    // Can not select sundays and predfined days
    return (
      moment(current).day() === 0 ||
      moment(current).day() === 6 ||
      (current && current > moment().endOf("day"))
    );
  }
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   if(Portfolios[0]?.id !== undefined){
  //     if(location?.pathname?.split('/').length <= 2){
  //       Portfolios[0]?.id !== undefined &&  navigate(`/portFolio/${ Portfolios[0]?.id}`)

  //     }
  //     else{
  //       console.log("dadadadafadfdfjsdkf");
  //     }
  // }

  // }, []);
  useEffect(() => {
    setisLoading(true);
    getFundamentalPorfolioAnalysis(
      sortState,
      Number(location?.pathname?.split("/")[2])
    ).then((res) => {
      setFundamental(res?.data);
      setisLoading(false);
    });
  }, [sortState]);

  const onChange = (date, dateString) => {
    const NewFormatOfDate = dateFrmater(dateString);
    setdate(dateString);
    if (NewFormatOfDate === undefined) {
      return undefined;
    } else {
      getCMP({ Inputdatais, NewFormatOfDate }).then((res) => {
        setBoughtprice(res?.data?.cmp);
      });
    }
  };

  useEffect(() => {
    setportfolioid(Number(location?.pathname?.split("/")[2]));
    if (location?.pathname?.split("/")[2] === undefined) {
      Portfolios[0]?.id &&
        navigate(`/portFolio/${Portfolios[0]?.id}`, { replace: true });
      Portfolios[0]?.name && setcurrectPortplioName(Portfolios[0]?.name);
    }
  }, [Portfolios[0]?.id]);

  const [statusdata, setstatusdata] = useState();
  //reset all state
  const resetAddStockModel = () => {
    setQty("");
    setInputdatais("");
    setInputDataIsOnChange("");
    setdate("");
    setBoughtprice("");
    setInputReturns("");
  };
  //get the current market price of stock
  const getcmpdata = (e) => {
    const NewFormatOfDate = dateFrmater(date);
    const Inputdatais = e;
    getCMP({ Inputdatais, NewFormatOfDate }).then((res) => {
      setBoughtprice(res?.data?.cmp);
    });
  };
  //sort function based on the stock profit -> loss
  const datavalue = Portfoliostocksdata?.sort((a, b) => {
    return a.profit_and_loss - b.profit_and_loss;
  });
  const arrayofGainers = [];
  const arrayoflosers = [];
  datavalue?.filter((items, i) => {
    if (items?.profit_and_loss < 0) {
      arrayoflosers.push(items);
    } else {
      arrayofGainers.push(items);
    }
  });
  //reverse the order of arrayofGainers
  const fistthreedata = arrayofGainers.reverse().slice(0, 3);
  const lastthreedata = arrayoflosers.reverse().slice(-3);
  console.log("arrayoflosers", arrayoflosers);
  //used for model open and close state
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    setisLoading(true);
    if (Number(location?.pathname?.split("/")[2])) {
      getPortfolioHolding(Number(location?.pathname?.split("/")[2])).then(
        (res) => {
          setisLoading(false);
          setPortfoliostocksdata(res?.data);
        }
      );
      getDividentApi(Number(location?.pathname?.split("/")[2])).then(
        (items) => {
          setdivident(items?.data);
        }
      );
      getFundamentalPorfolioAnalysis(
        sortState,
        Number(location?.pathname?.split("/")[2])
      ).then((res) => {
        setFundamental(res?.data);
        setisLoading(false);
      });
      setLoaderMonth(true);
      getMonthlyWiseAnalysedData(
        Number(location?.pathname?.split("/")[2]),
        Selected
      ).then((res) => {
        setLoaderMonth(false);
        setMonthlyWise1(res?.data);
      });
    }
  }, [Number(location?.pathname?.split("/")[2])]);

  useEffect(() => {
    setLoaderMonth(true);
    getMonthlyWiseAnalysedData(
      Number(location?.pathname?.split("/")[2]),
      Selected
    ).then((res) => {
      setLoaderMonth(false);
      setMonthlyWise1(res?.data);
    });
  }, [trigger, Selected]);

  useEffect(() => {
    setisLoading(true);
    if (Number(location?.pathname?.split("/")[2])) {
      getDividentApi(Number(location?.pathname?.split("/")[2])).then(
        (items) => {
          setdivident(items?.data);
        }
      );
      setLoaderMonth(true);
      getMonthlyWiseAnalysedData(
        Number(location?.pathname?.split("/")[2]),
        Selected
      ).then((res) => {
        setLoaderMonth(false);
        setMonthlyWise1(res?.data);
      });
      getFundamentalPorfolioAnalysis(
        sortState,
        Number(location?.pathname?.split("/")[2])
      ).then((res) => {
        setFundamental(res?.data);
        setisLoading(false);
      });
    }
  }, [Portfoliostocksdata?.length, Number(location?.pathname?.split("/")[2])]);

  const getAllStocks = () => {
    setisLoading(true);
    if (Number(location?.pathname?.split("/")[2])) {
      getPortfolioHolding(Number(location?.pathname?.split("/")[2])).then(
        (res) => {
          setisLoading(false);
          setPortfoliostocksdata(res?.data);
        }
      );
    }
  };
  const addStockToPortfolio = ({ tempstock }, type) => {
    setisLoading(true);

    const data = {
      transcation_type: type,
      portfolio: Number(location?.pathname?.split("/")[2]),
      stock: tempstock?.symbol
        ? String(tempstock?.symbol)
        : String(Inputdatais),
      quantity: Number(Qty),
      price: Number(Boughtprice),
      date: dateFrmater(date),
    };

    AddStockPortfolioAnalysis({ data }).then((res) => {
      getAllStocks();
      setQty("");
      setInputdatais("");
      setInputDataIsOnChange("");
      setdate("");
      setBoughtprice("");
      setInputReturns("");
      setaddMoreStocks(false);
      setaddStocks(false);
    });
  };

  const dropdowndata = [];

  {
    inputReturns &&
      inputReturns.map((items) => {
        items.type === "stock" &&
          dropdowndata.push({
            // value: isNaN(items.symbol) ? items.symbol : items.symbol,
            value: items.symbol,
            label: items.company,
          });
      });
  }
  const getPortfolioAnalysisdata = () => {
    getPortfolioAnalysis().then((res) => {
      setPortfolios(res.data);
    });
  };
  const { lightMode } = useSelector(getThemeMode);
  const [firstTimeValidation, setfirstTimeValidation] = useState(true);

  const [daysDuration, setdaysDuration] = useState("3m");
  useEffect(() => {
    getPortfolioAnalysis().then((res) => {
      setPortfolios(res.data);
      if (res.data?.length === 0 && firstTimeValidation) {
        const data = { name: "My Portfolio" };
        CreatePortfolioAnalysis({ data })
          .then((res) => {
            setfirstTimeValidation(false);
            getPortfolioAnalysisdata();
            setportfolioName("");
            setportfolioid(res?.data?.id);
            setCreateModel(false);
          })
          .catch((error) => {
            setfetchError({ AlreadyCreated: error?.response?.data?.error });
          });
      }
    });
  }, []);

  const createPortfolioAnalysis = () => {
    if (portfolioName.startsWith(" ") || portfolioName.length === 0) {
      setvalidation(true);
    } else {
      const data = { name: portfolioName };
      CreatePortfolioAnalysis({ data })
        .then((res) => {
          sessionStorage.removeItem(23);
          getPortfolioAnalysisdata();
          setportfolioName("");
          console.log("res", res?.data);
          navigate(`/portfolio/${res?.data?.id}`);
          setcurrectPortplioName(res?.data?.name);
          sessionStorage.setItem(23, [res?.data?.id, res?.data?.name]);
          // setportfolioid(res?.data?.id)
          setCreateModel(false);
        })
        .catch((error) => {
          console.log("ada", error);
          setfetchError({ AlreadyCreated: error?.response?.data?.error });
        });
    }
  };
  const updatePortfolio = () => {
    const data = { name: ChangePortfolio };
    ChangePortfolio.length > 0
      ? UpadatePortfolioAnalysis({ updateAndDeleteid, data }).then((res) => {
          getPortfolioAnalysisdata();
          setIsModalVisible(false);
          setinValidInput(false);
        })
      : setinValidInput(true);
  };
  const deletePortfolioAnalysis = () => {
    DeletePortfolioAnalysis({ updateAndDeleteid }).then((res) => {
      getPortfolioAnalysisdata();
      setIsModalVisible3(false);
      sessionStorage.removeItem(23);
      sessionStorage.setItem(23, [Portfolios[0]?.id, Portfolios[0]?.name]);
      setcurrectPortplioName(Portfolios[1]?.name);
      if (updateAndDeleteid === Portfolios[1]?.id) {
        navigate(`/portfolio/${Portfolios[0]?.id}`);
        setcurrectPortplioName(Portfolios[0]?.name);
      } else {
        navigate(`/portfolio/${Portfolios[1]?.id}`);
        setcurrectPortplioName(Portfolios[1]?.name);
      }

      location = null;
    });
  };

  const getResults = async (e) => {
    // setinputData(e.target.value);
    const response = await getSearchData(e)
      .then((resp) => {
        console.log("input", resp.data);
        setInputReturns(resp.data);
      })
      .catch((err) => console.log(err));
  };

  const timeLineData = [
    // { label: "1M", value: "1m" },
    { label: "3M", value: "3m" },
    { label: "6M", value: "6m" },
    { label: "1Y", value: "1y" },
    { label: "All", value: "all" },
  ];

  return (
    <div>
      <ReactHelmet
        title="Portfolio Analysis | Stock Portfolio Analysis | Equity Portfolio Analysis | Portfolio Analysis tool | Portfolio Analysis Tool India - Track & Evaluate your Portfolio with Ease"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      <div className="my-body">
        <div
          className={` ${
            lightMode ? "" : "bg-dark-black"
          } d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative`}
        >
          <div className="only-mobile-view">
            <div className="d-flex align-items-center mb-20">
              <p className="mr-10 w-m-28 mt-5 mb-0">{svgSheet.portFolioLogo}</p>
              <h1
                className="mb-0 fs-36-30-20 fw-700"
                onClick={handleGeneratePdf}
              >
                Portfolio Analysis
              </h1>
            </div>
            <div className="d-flex align-items-center mb-10">
              <p className="fs-s-14 mb-0 fw-500 d-flex mr-10 justify-content-center Textcapitalize">
                {currectPortplioName}
              </p>
              <Popover
                //  key={key}
                color={lightMode ? "white" : "#3B3F4F"}
                className="nameis"
                getPopupContainer={(trigger) => trigger.parentElement}
                placement="right"
                content={
                  <div style={{ paddingRight: "20px" }} className="">
                    <div
                      className={`${
                        lightMode ? "text-dark-gray" : "text-white"
                      }`}
                    >
                      <div className="d-flex  align-items-center mt-5 ">
                        <p
                          onClick={() => {
                            setIsModalVisible(true);
                            setVisible(false);
                            setupdateAndDelete(portfolioid);
                            // setChangePortfolio(items?.name);
                          }}
                          className="ml-5 fs-s-12 fw-600 mb-3 pointer"
                        >
                          Rename
                        </p>
                      </div>
                      {Portfolios?.length !== 1 && (
                        <div className="d-flex align-items-center mt-5">
                          <p
                            onClick={() => {
                              setIsModalVisible3(true);
                              setupdateAndDelete(portfolioid);
                              setVisible(false);
                            }}
                            className="ml-5 fs-s-12  fw-600 mb-3 pointer"
                          >
                            Delete
                          </p>
                        </div>
                      )}
                      {/* <div className="d-flex  align-items-center mt-5 ">
                        <p
                          onClick={() => {
                            handleGeneratePdf() 
                            
                            setVisible(false);
                            
                            // setChangePortfolio(items?.name);
                          }}
                          className="ml-5 fs-s-12 fw-600 mb-3 pointer"
                        >
                          Export
                        </p>
                      </div> */}
                    </div>
                  </div>
                }
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
              >
                <p onClick={() => setVisible(true)} className="mb-0">
                  <MoreOutlined />
                </p>
              </Popover>
            </div>
          </div>

          <div className="d-flex w-100 justify-content-between ">
            <div className="w-25 only-PC-view">
              <div className="d-flex align-items-center mb-20">
                <p className="mr-10 w-m-28 mt-5 mb-0">
                  {svgSheet.portFolioLogo}
                </p>
                <h1
                  className="mb-0 fs-36-30-20 fw-700"
                  onClick={handleGeneratePdf}
                >
                  Portfolio Analysis
                </h1>
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "sticky",
                  top: `${126 + bannerOffsetCalc(auth)}px`,
                }}
                className={`${
                  lightMode ? "bg-gray" : "bg-dark-gray"
                } d-flex flex-col p-15 mb-10 watchlist-items br-3    `}
              >
                {Portfolios?.map((items, i) => (
                  <PortFolioAnalysisList
                    items={items}
                    i={i}
                    Portfolios={Portfolios}
                    lightMode={lightMode}
                    setCreateModel={setCreateModel}
                    setIsModalVisible3={setIsModalVisible3}
                    setIsModalVisible={setIsModalVisible}
                    setupdateAndDelete={setupdateAndDelete}
                    setportfolioid={setportfolioid}
                    isPopoverisopen={isPopoverisopen}
                    portfolioid={portfolioid}
                    setChangePortfolio={setChangePortfolio}
                    setcurrectPortplioName={setcurrectPortplioName}
                    location={location}
                    handleGeneratePdf={handleGeneratePdf}
                  />
                ))}

                {Portfolios?.length !== 5 && (
                  <button
                    onClick={() => {
                      Portfolios?.length >= plans[PlanName]?.portfolioAnalsis
                        ? setIsPremiumModel(true)
                        : setCreateModel(true);
                      setfetchError("");
                    }}
                    className={`fs-s-16  fw-600 pointer ${
                      lightMode
                        ? "bg-gray text-white"
                        : "bg-dark-gray text-white"
                    } p-10 br-5 fs-s-18 btn-bg-primary mt-10 w-100`}
                  >
                    + Add Portfolio
                  </button>
                )}
              </div>
            </div>
            {Portfoliostocksdata?.length !== 0 ? (
              <div className="w-74-100 ">
                <div
                  // style={{top:"-70px"}}
                  id="reportTemplateRef"
                  className={` ${
                    lightMode ? "" : "bg-dark-black relative"
                  } d-flex-col p-10 `}
                >
                  <div
                    className={`${
                      IsOverFlowHidden ? "" : "visible-hidden"
                    } only-PC-view`}
                  >
                    <div className="mb-20 w-100 d-flex justify-content-between align-items-center">
                      <img
                        src={lightMode ? logo : logoWhite}
                        className="header-logo"
                        alt="logo"
                      />
                      <p className="fs-s-16 fw-700 mb-0">
                        Portfolio:{currectPortplioName}
                      </p>
                    </div>
                  </div>
                  <div className={`${lightMode ? "" : "bg-dark-black"}`}>
                    <PortfolioMainCards
                      lightMode={lightMode}
                      portfolioid={Number(location?.pathname?.split("/")[2])}
                      portfolioLength={Portfoliostocksdata}
                      setstatusdata={setstatusdata}
                      statusdata={statusdata}
                    />
                    <div
                      className="mt-20 "
                      style={{ width: "100%", height: "400px" }}
                    >
                      <div className="d-flex justify-content-end w-100 mb-10">
                        <div className="d-flex  d-flex-row align-items-center">
                          <TimeLineButtons
                            data={timeLineData}
                            value={daysDuration}
                            setValue={setdaysDuration}
                            lightMode={lightMode}
                          />
                        </div>
                      </div>
                      <MultiGraphPortFolioAnalysis
                        portfolioid={Number(location?.pathname?.split("/")[2])}
                        trigger={trigger}
                        lightMode={lightMode}
                        Portfolios={Portfolios}
                        Portfoliostocksdata={Portfoliostocksdata}
                        daysDuration={daysDuration}
                        setdaysDuration={setdaysDuration}
                        setstatusdata={setstatusdata}
                        statusdata={setstatusdata}
                      />
                    </div>

                    <HoldingsPortfolio
                      lightMode={lightMode}
                      portfolioid={Number(location?.pathname?.split("/")[2])}
                      setPortfoliostocksdata={setPortfoliostocksdata}
                      Portfoliostocksdata={Portfoliostocksdata}
                      setaddStocks={setaddStocks}
                      addStocks={addStocks}
                      isPopoverisopen={isPopoverisopen}
                      settrigger={settrigger}
                      trigger={trigger}
                      currectPortplioName={currectPortplioName}
                      IsOverFlowHidden={IsOverFlowHidden}
                    />
                  </div>
                  <div
                    id="reportTemplateRef1"
                    className={`${lightMode ? "" : "bg-dark-black"}`}
                  >
                    <h2 style={{ marginTop: "0px" }} className="main-header">
                      Top Performers
                    </h2>

                    <div className="w-100  d-flex d-flex-row-col justify-content-between">
                      <div
                        style={{ height: "250px" }}
                        className={`${
                          lightMode ? "bg-gray" : "bg-dark-gray"
                        } w-49-100 mt-10 p-20 `}
                      >
                        <p className="fs-s-20 fw-600 mb-30">
                          Top Best Performers
                        </p>

                        {fistthreedata?.length > 0 ? (
                          fistthreedata?.map((items) => (
                            <div className="d-flex justify-content-between mt-20">
                              <Link to={`/stock/${items?.symbol}/consolidated`}>
                                <p className="fs-s-16 link-hover-underline fw-500">
                                  {items?.name}
                                </p>
                              </Link>
                              <p
                                style={{
                                  color: redGreenColorPicker(
                                    items?.profit_and_loss,
                                    lightMode
                                  ),
                                }}
                                className="fs-s-16 ff-lato fw-500"
                              >
                                {"+" + items?.profit_and_loss + "%"}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              paddingBottom: "60px",
                              paddingTop: "20px",
                            }}
                          >
                            {" "}
                            {<NoData />}
                          </div>
                        )}
                      </div>
                      <div
                        style={{ height: "250px" }}
                        className={`${
                          lightMode ? "bg-gray" : "bg-dark-gray"
                        } w-49-100 mt-10 p-20`}
                      >
                        <p className="fs-s-20  fw-600 mb-30">
                          Top Worst Performers
                        </p>

                        {lastthreedata?.length > 0 ? (
                          lastthreedata.reverse()?.map((items) => (
                            <div className="d-flex justify-content-between mt-20">
                              <Link to={`/stock/${items?.symbol}/consolidated`}>
                                <p className="fs-s-16 link-hover-underline fw-500">
                                  {items?.name}
                                </p>
                              </Link>
                              <p
                                style={{
                                  color: redGreenColorPicker(
                                    items?.profit_and_loss,
                                    lightMode
                                  ),
                                }}
                                className="fs-s-16 ff-lato fw-500"
                              >
                                {items?.profit_and_loss + "%"}
                              </p>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              paddingBottom: "60px",
                              paddingTop: "20px",
                            }}
                          >
                            {" "}
                            {<NoData />}
                          </div>
                        )}
                      </div>
                    </div>

                    <SizeIndustryAnalysisGraph
                      portfolioid={Number(location?.pathname?.split("/")[2])}
                      lightMode={lightMode}
                      Portfoliostocksdata={Portfoliostocksdata?.length}
                      trigger={trigger}
                      IsOverFlowHidden={IsOverFlowHidden}
                    />
                  </div>
                  <div
                    id="reportTemplateRef3"
                    className={`${lightMode ? "" : "bg-dark-black"}`}
                  >
                    <DividendsPortfolioAnalysis
                      lightMode={lightMode}
                      divident={divident}
                      IsOverFlowHidden={IsOverFlowHidden}
                    />
                    <KeyPortfolioMetrics
                      divident={divident}
                      lightMode={lightMode}
                    />
                    <div className="">
                      <h2 className="main-header">Fundamentals</h2>
                      {/* <div className="sub-table-news-data w-100 d-flex flex-wrap mb-30"> */}
                      <FundamentalTable
                        isLoading={isLoading}
                        Fundamental={Fundamental}
                        sortState={sortState}
                        setsortState={setsortState}
                        lightMode={lightMode}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="">
                    <ProfileTrackerPortfolioAnalysis
                      portfolioid={Number(location?.pathname?.split("/")[2])}
                      Portfoliostocksdata={Portfoliostocksdata?.length}
                      lightMode={lightMode}
                      IsOverFlowHidden={IsOverFlowHidden}
                    />
                  </div>
                  <div className="">
                    <EventTrackerPortfolioAnalysis
                      portfolioid={Number(location?.pathname?.split("/")[2])}
                      Portfoliostocksdata={Portfoliostocksdata?.length}
                      lightMode={lightMode}
                    />
                  </div>

                  {/* <div>
                    <MonthlyWise
                      setSelected={setSelected}
                      lightMode={lightMode}
                      LoaderMonth={LoaderMonth}
                      MonthlyWise={MonthlyWise1?.length > 0 ? MonthlyWise1 : []}
                    />
                  </div> */}
                </div>
                <div className=" ">
                  <h2 className="main-header  mb-0 mt-40" id="News">
                    News
                  </h2>
                  <div className="sub-table-news-data w-100 d-flex flex-wrap mb-30">
                    <NewsTabStockDetails symbol={"R"} />
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ marginLeft: "5px" }}
                className="d-flex flex-col watch-list-content"
              >
                <div className="d-flex flex-col align-items-center w-100">
                  <img src={WatchlistEmpty} width={"60%"} alt=""></img>
                  <div className="d-flex flex-col justify-content-center align-items-center">
                    <p className="fs-20-13">Start adding stocks to Portfolio</p>
                    <button
                      onClick={() => setaddStocks(true)}
                      className={`p-10 pointer btn-bg-primary text-white w-250px text-white br-3 fw-500 fs-16-14 `}
                    >
                      Add Stocks
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          title={
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Rename Portfolio
            </p>
          }
          visible={isModalVisible}
          centered
          wrapClassName={!lightMode && "modelClassname"}
          onCancel={() => {
            setIsModalVisible(false);
            setinValidInput(false);
          }}
          footer={[
            <button
              key="submit"
              type="primary"
              className={` w-150px br-5 fs-s-16 pointer fw-500 btn-bg-primary text-white ${
                lightMode ? "  h-45" : "h-45 "
              }`}
              onClick={() => updatePortfolio()}
            >
              Rename
            </button>,
          ]}
        >
          <div className="w-100">
            <input
              width={"100%"}
              value={ChangePortfolio}
              maxLength={20}
              style={{ width: "100%" }}
              onChange={(e) => setChangePortfolio(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3 p-3  ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
            {inValidInput && (
              <p style={{ color: redGreenColorPicker(-20, lightMode) }}>
                Invalid Value
              </p>
            )}
          </div>
        </Modal>
        <Modal
          title={
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Create Portfolio
            </p>
          }
          visible={CreateModel}
          centered
          // onOk={handleOk}
          wrapClassName={!lightMode && "modelClassname"}
          onCancel={() => setCreateModel(false)}
          footer={[
            <button
              key="submit"
              type="primary"
              className={` w-150px br-5 fs-s-16 pointer fw-500 btn-bg-primary text-white ${
                lightMode ? "  h-45" : "h-45 "
              }`}
              onClick={() => {
                console.log("dafafafa");
                createPortfolioAnalysis();

                portfolioName.startsWith(" ")
                  ? setvalidation(true)
                  : setvalidation(false);
              }}
            >
              Create
            </button>,
          ]}
        >
          <div className="w-100">
            <input
              width={"100%"}
              value={portfolioName}
              style={{ width: "100%" }}
              onChange={(e) => {
                setportfolioName(e.target.value);
              }}
              type="text"
              maxLength={20}
              className={`w-100 h-45 br-3 p-3 ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
            {validation && (
              <span style={{ color: lightMode ? "#FF0000" : "#F82E2E" }}>
                invalid input
              </span>
            )}
            {fetchError?.AlreadyCreated && (
              <span style={{ color: lightMode ? "#FF0000" : "#F82E2E" }}>
                {fetchError?.AlreadyCreated}
              </span>
            )}
          </div>
        </Modal>
        <Modal
          title={
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Delete Portfolio
            </p>
          }
          visible={isModalVisible3}
          centered
          wrapClassName={!lightMode && "modelClassname"}
          // onOk={}
          onCancel={() => setIsModalVisible3(false)}
          footer={[
            <button
              key="submit"
              style={{ background: "#AA1919" }}
              type="primary"
              className={` w-150px br-5 pointer fs-s-16 fw-600 ${
                lightMode ? " text-white h-45" : " h-45 "
              }`}
              onClick={() => deletePortfolioAnalysis()}
            >
              Confirm
            </button>,
          ]}
        >
          <div className="w-100   ">
            <p className="mb-0">Do you want to Delete your Portfolio</p>
          </div>
        </Modal>
        <Modal
          title={
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Add stocks
            </p>
          }
          visible={addStocks}
          centered
          // onOk={handleOk}
          wrapClassName={!lightMode && "modelClassname"}
          onCancel={() => {
            setaddStocks(false);
            resetAddStockModel();
          }}
          footer={[
            //   <button
            //   key="submit"
            //   type="primary"
            //   style={{width:"120px"}}
            //   className={`  br-5 mb-10 fs-s-16 fw-500 pointer btn-blue-border h-45 mr-10 text-white ${
            //     lightMode ? " text-btn-light " : "bg-dark-gray text-btn-dark"
            //   }`}
            //   onClick={() => resetAddStockModel()}
            // >
            //   Reset
            // </button>,
            <button
              key="submit"
              type="primary"
              style={{ width: "120px" }}
              className={` br-5 mb-10 fs-s-16 fw-500 pointer btn-bg-primary text-white ${
                lightMode ? "  h-45" : "h-45 "
              }`}
              onClick={() => addStockToPortfolio(" ", "buy")}
            >
              Add
            </button>,
          ]}
        >
          <div
            // style={{ height: "240px" }}
            className="w-100 d-flex flex-col justify-content-between"
          >
            <div>
              <p className="fs-s-12 mb-10">Company Name</p>
              <AutoComplete
                className={
                  lightMode
                    ? "border1px-light-mode "
                    : "border1px-dark-mode antd-Selete-Custom-dark mb-10"
                }
                style={{
                  width: "100%",
                  // height: "40px",
                }}
                notFoundContent
                defaultValue={""}
                onFocus={() => getResults("")}
                allowClear={true}
                placeholder=" Select Stock"
                fieldNames={"firstOne"}
                options={dropdowndata}
                dropdownClassName={`${
                  !lightMode && "drop-down-stock invert-text"
                }`}
                value={InputDataisOnChange}
                defaultPickerValue={moment("2022-08-02")}
                onChange={(e) => {
                  getResults(e);
                  setInputDataIsOnChange(e);
                }}
                defaultActiveFirstOption={true}
                onSelect={(e, options) => {
                  setInputdatais(e);

                  setInputDataIsOnChange(options.label);
                  console.log("date", date);

                  {
                    date && getcmpdata(e);
                  }
                }}
              />
            </div>
            <div className="w-100">
              <p className="fs-s-12 mb-10">Date</p>
              <DatePicker
                style={{ width: "100%", height: "35px" }}
                format={dateFormat}
                disabledDate={disabledDate}
                inputReadOnly={true}
                className={!lightMode && "DatePicker-Antd-Dark"}
                value={date ? moment(date, dateFormat) : undefined}
                dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
                onChange={onChange}
              />
            </div>
            <p className="fs-s-12 my-10">Per Share Price</p>
            <div className="d-flex w-100">
              <input
                value={"₹"}
                className={`${
                  lightMode
                    ? "border1px-light-mode"
                    : "border1px-dark-mode bg-dark-gray"
                } ff-lato`}
                style={{
                  width: "5%",
                  background: lightMode ? "white" : "#3B3F4F",
                }}
                width={"5%"}
                disabled
              ></input>
              <input
                width={"95%"}
                // value={NewWatchList}
                style={{ width: "95%", height: "35px" }}
                placeholder="Enter Bought Price"
                value={Boughtprice}
                onChange={(e) => setBoughtprice(e.target.value)}
                type="number"
                className={`w-100 br-3 p-3  portFolio-Modal-inputFeild ${
                  lightMode
                    ? "border1px-light-mode"
                    : "border1px-dark-mode bg-dark-gray"
                }`}
              ></input>
            </div>

            <p className="fs-s-12 my-10">No.of.shares</p>

            <input
              width={"100%"}
              // value={NewWatchList}
              style={{ width: "100%" }}
              placeholder="Enter Quantity"
              onChange={(e) => setQty(e.target.value)}
              type="number"
              value={Qty}
              className={`w-100 h-45 br-3 p-3 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></input>
          </div>
        </Modal>
      </div>
      <PremiumRedirectModal
        visible={IsPremiumModel}
        modalPlan="portFolio"
        setVisible={setIsPremiumModel}
      />
      <PortfolioMobileSlider
        lightMode={lightMode}
        Portfolios={Portfolios}
        setCreateModel={setCreateModel}
        portfolioid={Number(location?.pathname?.split("/")[2])}
        setportfolioid={setportfolioid}
        setcurrectPortplioName={setcurrectPortplioName}
        location={location?.state}
        planNumber={plans[PlanName]?.portfolioAnalsis}
        navigate={navigate}
        setIsPremiumModel={setIsPremiumModel}
      />
    </div>
  );
}

export default PortfolioAnalysis;
