import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function OneTimeValueCalc() {
  const navigate = useNavigate();

  const [SliderValues, setSliderValues] = useState({
    sumAmount: 2000,
    expt: 12,
    durt: 10,
  });
  const [Result, setResult] = useState({
    Result: "",
  });
  const chart = Number(Result.Result) - Number(SliderValues.sumAmount);

  const [ChartResult, setChartResult] = useState({
    investAmt: chart,
    return: SliderValues.sumAmount,
  });
  console.log("ChartResult", ChartResult);
  console.log(
    "chartData",
    chart,
    Number(Result.Result),
    Number(SliderValues.sumAmount)
  );
  useEffect(() => {
    let value =
      Number(SliderValues.sumAmount) *
      (1 + Number(SliderValues.expt) / 100) ** SliderValues.durt;
    setResult({ ...Result, [Object.keys(Result)[0]]: value ? value : 0 });
    console.log("value", value);
  }, [SliderValues.sumAmount, SliderValues.expt, SliderValues.durt]);
  const sliderList = [
    { name: "Monthly SIP (in Rupees)", limit: 100000 },
    { name: "Expected CAGR (%)", limit: 30 },
    { name: "Duration (in Years)", limit: 20 },
  ];

  const ResultHeder = [
    {
      "Expected Amount": (Number(Result.Result)).toFixed(2),
    },
  ];
  const bottomData = [{
    header:"What is One Time Value Calculator?",
    body:"This is a basic Compound Interest Calculator. It will give the future value of one time lump-sum investment. Choose a mutual fund scheme that you want to invest For example, if you invest Rs 3,000 per month for 30 years at a expected return rate of 15%, your final amount will be Rs 21,029,824 (Rs 2.10 Cr)."
  },]
  const { lightMode } = useSelector(getThemeMode);
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {},
      },
    },

    maintainAspectRatio: false,
  };
  

  return (
    <div>
      <div>
        <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
          <div className="my-30">
            <p className="pointer mb-0" onClick={() => navigate(-1)}>
              Calculators {">"} One-Time value Calculator
            </p>
            <p className="mb-0 fs-30-20 fw-700">One-Time value Calculator</p>
            <p className="w-60-80">
              This is an essential compound interest calculator. It will give
              the future value of one time lump-sum investment.
            </p>
          </div>
          <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
            <div>
              <CalcInputFeilds
                SliderValues={SliderValues}
                setSliderValues={setSliderValues}
                sliderList={sliderList}
                lightMode={lightMode}
              />
            </div>
            <div>
              <ResultContainerCalc
                ResultHeder={ResultHeder}
                DonutChartResult={{
                  investAmt: SliderValues.sumAmount,
                  return: Number(chart).toFixed(2),
                }}
                donutChart={true}
                lightMode={lightMode}
              />
            </div>
          </div>
          <div>
            <BottomDataSection  bottomData={bottomData}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneTimeValueCalc;
