
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThemeMode } from '../../../redux/reducers/ThemeSlice';
import BottomDataSection from '../BottomDataSection'
import CalcInputFeilds from '../CalcInputFeilds'
import ResultContainerCalc from '../ResultContainerCalc'

function PriceEarningsCalc() {
const { lightMode } = useSelector(getThemeMode);
const [GetRsult, setGetRsult] = useState([]);
const [eps, setEps] = useState();
  const [median, setMedian] = useState();
  const [egr, setEgr] = useState();
  const [margin, setMargin] = useState();
  const [gd, setGd] = useState();
  const [dr, setDr] = useState();
  const [fiveval, setFiveval] = useState(0);
  const [val, setVal] = useState(0);
  const [arr, setArr] = useState([]);
  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);
const options = {
    scales: {
      y: {
        beginAtZero: true
      }
      
    },
    plugins:{
        legend:{
            position:"bottom",
            labels:{

            }
        },
    },

    maintainAspectRatio: false
  }
console.log("GetRsult",GetRsult);
useEffect(() => {
  let list = [];
  let GetPeConservativeGR = Number(GetRsult["egr"]) * (1 - Number(GetRsult["margin"]) / 100);
  console.log(GetPeConservativeGR, "valvalavl");
  let GetpeEps1 = Number(GetRsult["eps"]) * (1 + Number(GetPeConservativeGR) / 100);
  console.log(GetpeEps1);
  list.push(Number(GetpeEps1));
  console.log(list);

  for (let i = 0, j = 1; j < 5; i++, j++) {
    let vals =
      list[i] *
      (1 +
        (Number(GetPeConservativeGR) / 100) *
          (1 - Number(GetRsult["gd"]) / 100) ** (j - 1));
    list.push(vals);
  }
  console.log(list);
  setArr(list);
  let FiveYears = list[list.length - 1] * Number(GetRsult["median"]);
  let InstrinsicValue = FiveYears / (1 + Number(GetRsult["dr"]) / 100) ** 5;
  setFiveval(FiveYears);
  console.log("InstrinsicValue",InstrinsicValue);
  setVal(InstrinsicValue);
  setShow(true);
}, [GetRsult])
const valueData = [
  { key:"Value in 5 Years ", value:fiveval && fiveval !== Infinity
  ? fiveval?.toFixed(2).toLocaleString("en-US")
  : 0 },
];

  var data = {
    labels: arr.map((items,i)=>{return i+1}),
    datasets: [
        {
            label: "Blue",
            backgroundColor: "#6DB8FD",
            data: arr,
            
  
        },
       
     
    ]
  };
  const formlist = [
    {name:"eps",value:"EPS (last four quaters)(in Rs)"},
    {name:"margin",value:"Margin of Safety (%)"},
    {name:"median",value:"Median Historial P/E (5 Yrs)"},
    {name:"gd",value:"Growth Decline (%)"},
    {name:"egr",value:"Expected Growth Rate (%)"},
    {name:"dr",value:"Discount Rate (%)"},
  ];
  const ResultHeder =[{
    "Intrinsic Value ":Math.floor(
      val && val !== Infinity && val !== -Infinity
        ? val
        : 0
    ).toLocaleString("en-US")
  }]
  const bottomData = [{
    header:"Price to Earnings Ratio (P/E):",
    body:"The Price to Earnings ratio is one of the most widely used financial ratio analyses among the investors for a very long time. A high P/E ratio generally shows that the investor is paying more for the share. As a thumb rule, a low P/E ratio is preferred while buying a stock, but the definition of 'low' varies from industry to industry. So, different sectors (Ex Automobile, Banks etc) have different P/E ratios for the companies in their sector, and comparing the P/E ratio of the company of one sector with the P/E ratio of the company of another sector will be insignificant. However, you can use P/E ratio to compare the companies in the same sector, preferring one with low P/E. The P/E ratio is calculated using this formula: Price Earnings Ratio = (Price Per Share)/(Earnings Per Share)"
  },
  {
    header:"Earnings Per Share (EPS):",
    body:"Earnings per share (EPS) is calculated as a company's profit divided by the outstanding shares of its common stock. The resulting number serves as an indicator of a company's profitability. It is common for a company to report EPS that is adjusted for extraordinary items and potential share dilution. The higher a company's EPS, the more profitable it is considered."
  },{
    header:"Median Historical P/E:",
    body:"The Median Historical P/E is a rounded average of the middle values of a range of the annual price/earnings ratios over the past ten years. The statistic is designed to give investors an easy-to-understand 'typical' P/E of a stock over the past 10 years. By comparing the current P/E Ratio with the Median P/E, the investor can quickly see how the current P/E compares with the stock's historical P/ E and can tell if the current P/E is higher, lower or about the same as the average P/E in the past. Investors often look at these two P/Es to try to determine if a stock is currently overpriced or underpriced on an historical basis."
  },{
    header:"Growth Rate:",
    body:"Growth Rate refers to the percentage change of a specific variable within a specific time period and given a certain context. For investors, growth rates typically represent the compounded annualised rate of growth of a company's revenues, earnings, dividends or even macro concepts, such as gross domestic product (GDP) and retail sales. Expected forward-looking or trailing growth rates are two common kinds of growth rates used for analysis."
  },{
    header:"Margin of Safety:",
    body:"Margin Of Safety is a principle of investing in which an investor only purchases securities when their market price is significantly below their intrinsic value. In other words, when the market price of a security is significantly below your estimation of its intrinsic value, the difference is the margin of safety. Because investors may set a margin of safety in accordance with their own risk preferences, buying securities when this difference is present allows an investment to be made with minimal downside risk."
  },{
    header:"Growth Decline Rate:",
    body:"When a company releases an earnings report, a fundamental reaction is often the most common. As such, good earnings that miss expectations can result in a downgrade of value. If a firm issues an earnings report that does not meet Street expectations, the stock's price will usually drop."
  }]
  const navigate = useNavigate()
  const resetValues = () =>{
    setVal(0)
    setArr([])
    setFiveval(0)
  }
  return (
    <div>
         <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
      <p className='pointer' onClick={()=>navigate(-1)}>Calculators / Price Earning Valuation</p>
        <p className="mb-0 fs-30-20 fw-700">Price Earning Valuation</p>
        <p className='w-60-80'>This is a very commonly used valuation technique for evaluating the valuation of stocks.
</p>
      </div>
      <div  className="mx-50-0 p-45-10 br-0 custom-input-dark">
      <div>
          <CalcInputFeilds  resetValues={resetValues} setGetRsult={setGetRsult} formlist={formlist} lightMode={lightMode}/>
      </div>
      <div>
          <ResultContainerCalc resultLise={valueData}  ResultHeder={ResultHeder} chartData={data} chartOptions={options} lightMode={lightMode}/>
      </div>
      </div>  
      <div>
          <BottomDataSection  bottomData={bottomData}/>
      </div>  
    </div>
    </div>
  )
}

export default PriceEarningsCalc