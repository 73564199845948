import React, { useCallback } from "react";

function TimeLineButtons({ data, value, setValue, lightMode }) {
  const btnClolor = useCallback(
    (item) => {
      if (lightMode) {
        if (value === item) {
          // return { color: "white", backgorund: "#3B3F4F" };
          return { color: "white", backgorund: "linear-gradient(90.15deg, #6DB8FD 0.17%, #1774FF 104.9%)" };
        } else {
          // return { color: "black", backgorund: "#C9D7DE" };
          return { color: "black", backgorund: "#F2F6F8" };
        }
      } else {
        if (value === item) {
          return { color: "white", backgorund: "linear-gradient(90.15deg, #6DB8FD 0.17%, #1774FF 104.9%)" };
        } else {
          return { color: "white", backgorund: "#3B3F4F" };
        }
      }
    },
    [value, lightMode]
  );
  return (
    <div className="pointer d-flex" >
      {data.map((item, i) => {
        const { color, backgorund } = btnClolor(item.value);
        return (
          <h4
            key={i}
            onClick={() => setValue(item.value)}
            style={{
              marginRight: "3px",
              color: color,
              marginTop: "5px",
              transition: "background .5s",
              background: backgorund,
            }}
            className={`px-5px  br-5`}
          >
            {item.label}
          </h4>
        );
      })}
    </div>
  );
}

export default TimeLineButtons;
