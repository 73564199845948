export const bankProfitAndLoss = [
  {
    key: "total_income",
    name: "Total Income -",
    highlight: true,
    unit: "₹",
  },
  {
    key: "interest_earned",
    name: "Interest Earned",
    isNested: true,
    unit: "₹",
    tooltipText:
      "Revenue earned by the bank from its core lending activity over a financial year",
  },
  {
    key: "other_income",
    name: "Other Income",
    isNested: true,
    unit: "₹",
    tooltipText:
      "The income that a company generates from sources other than its core business functions. Ex: interest received on deposits, profits made on the sale of assets.",
  },
  {
    key: "interest",
    name: "Interest Expended",
    unit: "₹",
    tooltipText:
      "it is the amount paid on the deposits accepted by the bank over a financial year",
  },
  {
    key: "operating_expenditure",
    name: "Operating Expenses",
    unit: "₹",
    tooltipText:
      "Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.",
  },
  {
    key: "total_provisions",
    name: "Total Provisions",
    unit: "₹",
    tooltipText:
      "it includes provisions for unrecoverable assets over a financial year",
  },
  {
    key: "profit_before_tax",
    name: "Profit Before Tax",
    highlight: true,
    unit: "₹",
    tooltipText:
      "Profits made by the company before they have to pay corporate income tax.",
  },
  {
    key: "tax",
    name: "Tax",
    unit: "₹",
    tooltipText: "Taxes are compulsory contributions paid to the government.",
  },
  {
    key: "net_profit",
    name: "Net Profit",
    highlight: true,
    unit: "₹",
    tooltipText:
      "The actual profit after working expenses, interest and taxes.",
  },
  {
    key: "adj_eps",
    name: "Adj. EPS (Rs)",
    unit: "₹",
    tooltipText:
      "Adjusted EPS is arrived at after adding or removing components of net income which are non recurring. ",
  },
  {
    key: "net_profit_margin",
    name: "Net Profit Margin",
    unit: "%",
    tooltipText:'The profit margin shows what percentage of sales are profits after all the expenses have been deducted.'
  },
];
export const bankbalanceSheet = [
  {
    key: "total_assets",
    name: "Assets",
    highlight: true,
    unit: "₹",
    tooltipText:
      "An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.",
  },
  {
    key: "cash_balance_with_rbi",
    name: "Cash Balance with RBI",
    isNested: true,
    unit: "₹",
    tooltipText:
      "It is the short term asset for a bank in terms of cash kept with RBI as per mandates.",
  },
  {
    key: "balance_with_bank",
    name: "Balance with bank",
    isNested: true,
    unit: "₹",
  },
  {
    key: "investments",
    name: "Investments",
    isNested: true,
    unit: "₹",
    tooltipText:
      "All investments made by the bank. These mainy include govt seurities, treasuries and bonds",
  },
  {
    key: "advances",
    name: "Advances",
    isNested: true,
    unit: "₹",
    tooltipText:
      "The total of advance of a specified sum of money to an individual or business by a bank",
  },
  {
    key: "net_block",
    name: "Net Block",
    isNested: true,
    unit: "₹",
    tooltipText:
      "All the non-current assets undergo wear and tear over time which is called depreciation. Net block is the value of non-current assets minus depreciation.",
  },
  {
    key: "other_assets",
    name: "Other Assets",
    isNested: true,
    unit: "₹",
    tooltipText: "the sum of other assets apart from the loans and investments",
  },
  {
    key: "total_liabilities",
    name: "Liabilities & Equity -",
    unit: "₹",
    highlight: true,
    tooltipText:
      "This section tells the source of funds used by the company. Liabilities refer to outside money such as bank loans and creditors. Equity refers to the funds supplied by the owners of the company and the past profits which have been saved.",
  },
  {
    key: "deposits",
    name: "Deposits",
    isNested: true,
    unit: "₹",
    tooltipText:
      "it includes sum of all money deposited into savings, fixed deposits, term deposits, etc",
  },
  {
    key: "borrowings",
    name: "Borrowings",
    isNested: true,
    unit: "₹",
    tooltipText:
      "it includes money taken from central bank or interbank bank loans",
  },
  {
    key: "other_liabilities",
    name: "Other Liabilities",
    isNested: true,
    unit: "₹",
    tooltipText:
      "it is the amount that is kept aside in your accounts to cover a future liability. they are all micellaneous obligations that a company lumps together on financial statements",
  },
  {
    key: "shareholders_funds",
    name: "Shareholder's Funds-",
    isNested: true,
    unit: "₹",
    tooltipText:
      "Reserves and promoter capital together make up Shareholders Funds. Reserves are the past profits of the company which it has not distributed. Promoter capital is the amount invested by the shareholders in the company.",
  },
  {
    key: "share_capital",
    name: "Share Capital",
    isNested2x: true,
    unit: "₹",
    tooltipText:
      "Share capital is the amount invested by the shareholders in the company. It is of two types: equity and preference. The preference share capital has more specifications than equity capital with respect to voting power, profit distribution, liquidation, etc.",
  },
  {
    key: "reserve",
    name: "Reserves",
    isNested2x: true,
    unit: "₹",
    tooltipText:
      "Reserves are the past profits a company has either reinvested or kept as cash with itself. Reserves are classified into different types as per their use: general, statutory, debenture redemption, etc.",
  },
];
export const bankCashFlowTable = [

  {
    key: "cash_from_operation",
    name: "Cash From Operating Activities",
    unit: "₹",
    tooltipText:
      "Cash flow from operating activities refers to the inflow or outflow of cash from the regular  activities of a company. It includes cash flow from buying and selling its products and services, employees remuneration, administrative and maintainance activities. It is usually the first section of the cash flow statement.",
  },
  {
    key: "cash_from_investment",
    name: "Cash From Investing Activities",
    unit: "₹",
    tooltipText:
      "Cash flow from investing activities includes the inflow or outflow of cash from buying fixed assets, investments in securities, income from investments (dividends, interest, etc.), and the sale of securities or assets.",
  },
  
  {
    key: "cash_from_financing",
    name: "Cash From Financial Activities",
    unit: "₹",
    tooltipText:
      "Cash flows from financial activities give an insight into the companys financial strength and indicates how well the capital structure is managed. It includes cash flows from issuing equity or debt, dividends paid, and repurchase of equity and debt.",
  },
  {
    key: "free_cash_flow",
    name: "Free Cash Flow",
    unit: "₹",
    tooltipText:'Free cash flow (FCF) is the cash available with the company to repay creditors or pay dividends and interest to investors.'
  },
  {
    key: "net_cash_flow",
    name: "Net Cash Flow",
    unit: "₹",
    tooltipText:
      "Net cash flow is the difference between the total inflows and total outflows during a specific period.",
  },
];
export const bankQuarterlyFinancial = [
  {
    key: "total_income",
    name: "Total Income",
    highlight: true,
    unit: "₹",
  },
  {
    key: "interest",
    name: "Interest Expended",
    unit: "₹",
    tooltipText: "Interest paid on borrowings to various creditors.",
  },
  {
    key: "operating_expenses",
    name: "Operating Expenses	",
    unit: "₹",
    tooltipText:
      "Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.",
  },
  {
    key: "profit_before_tax",
    name: "Profit Before Tax	",
    highlight: true,
    unit: "₹",
    tooltipText:
      "Profits made by the company before they have to pay corporate income tax.",
  },
  {
    key: "tax",
    name: "Tax",
    unit: "₹",
    tooltipText: "Taxes are compulsory contributions paid to the government.",
  },
  {
    key: "net_profit",
    name: "Net Profit	",
    highlight: true,
    unit: "₹",
    tooltipText:
      "The actual profit after working expenses, interest and taxes.",
  },
  {
    key: "adj_eps",
    name: "Adj EPS (Rs)",
    unit: "₹",
    tooltipText:
      "Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.",
  },
  {
    key: "net_profit_margin",
    name: "Net Profit Margin",
    unit: "%",
    tooltipText:'The quarterly profit margin shows what percentage of sales are profits after all the expenses have been deducted.'
  },
];
export const bankShareHoldingtable = [
  {
    key: "promoters",
    name: "Promoters",
    unit: "%",
    tooltipText:
      "Promoters refers to founders, director or controlling shareholders of a company. They have control over the affairs of th company, either directly or indirectly. The percentage of equity that they hold in a company is called as promoters shareholding.",
  },
  {
    key: "share_holding_pledge",
    name: "Shareholding Pledge",
    unit: "%",
    tooltipText:
      "Promoters can avail loans by pledging their shares. However, they retain their ownership. The percentage of shares out of promoters holdings that are pledged are called shareholding pledge.",
  },
  {
    key: "public",
    name: "Public",
    unit: "₹",
    tooltipText:
      "Collection of all individual non-professional investors who have inveted in the security.",
  },
  {
    key: "fii",
    name: "FII",
    unit: "%",
    tooltipText:
      "A foreign institutional investor (FII) is an investor or investment fund investing in a country outside of the one in which it is registered or headquartered. The shares held by these foreign investors in Indian companies is called FII holding.",
  },
  {
    key: "total_dii",
    name: "Total DII",
    unit: "%",
  },
  {
    key: "mf",
    name: "MF",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "insurances",
    name: "Insurances",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "fin_inst",
    name: "Fin inst & other DII's",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "others",
    name: "Others",
    unit: "%",
  },
];
export const bankFiveYearTableData = [
  {
    key: "",
    name: "Operational Ratios",
    isHeader: true,
  },
  {
    key: "gross_npa",
    name: "Gross NPA",
    isNested: true,
    // unit: "₹",
    tooltipText:
      "This denotes the total of all the Banks loan assets that havent been repaid by its borrowers.",
  },
  {
    key: "net_npa",
    name: "Net NPA",
    isNested: true,
    // unit: "₹",
    tooltipText:
      "It shows proportion of loan or advances that are in default or arrears after adjusting for provisions",
  },
  {
    key: "casa",
    name: "Casa Ratio",
    isNested: true,
    // unit: "₹",
    tooltipText:
      "This helps us find the banks profitability. It indicates how much of a bank’s total deposits are in both current and savings accounts.",
  },
  {
    key: "advance_growth",
    name: "Advance Growth",
    isNested: true,
    // unit: "₹",
    tooltipText:
      "It shows the YoY Growth in advances given by a bank. Advances here refer to the loans they give to the public",
  },
  {
    key: "",
    name: "Profitability Ratios",
    isHeader: true,
  },
  {
    key: "net_margin",
    name: "Net Profit Margin",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">Net Profit ⁄ Total revenue x 100</p>
        <p className="mb-0">
          The ratio calculates the percentage of profit a company produces from
          its total revenue after accounting for all its expenses.
        </p>
      </>
    ),
  },
  {
    key: "net_interest_margin",
    name: "Net Interest Margin",
    isNested: true,
    unit: "%",
    tooltipText:
      "It is a measure of difference between interest income generated by banks and amount paid to their lenders. It is similar to the gross margin of non-financial companies.",
  },
  {
    key: "credit_deposit",
    name: "Credit Deposit Ratio",
    isNested: true,
    // unit: "₹",
    tooltipText:
      "It shows how much a bank lends out of the deposits it has mobilized.",
  },
  {
    key: "roe",
    name: "Return On Equity",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">ROE = Net Income / Shareholders’ Equity</p>
        <p className="mb-0">
          ROE is the return on your money invested into the company, which is
          calculated per share.
        </p>
      </>
    ),
  },
  {
    key: "roa",
    name: "Return On Assets",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">Net Income / Total Assets</p>
        <p className="mb-0">
          It measure how well the company is able to use its assets to generate
          profits.
        </p>
      </>
    ),
  },
  {
    key: "eps",
    name: "EPS",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
          (Net Income - Preference dividend ) / Total shares out standing
        </p>
        <p className="mb-0">
          It measures how much profit the company makes for each of its
          shareholders.
        </p>
      </>
    ),
  },
  {
    key: "adj_eps",
    name: "Adj. EPS",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
          (Net Income - Extraordinary gains / losses) / Total shares out
          standing
        </p>
        <p className="mb-0">
          Adjusted EPS removes any non-recurring extraordinary gains or losses
          from the net income and then calculates the EPS.
        </p>
      </>
    ),
  },
  {
    key: "",
    name: "Valuation Ratios:",
    isHeader: true,
  },
  {
    key: "adj_pe",
    name: "PE Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">Current market price / Earnings per share</p>
        <p className="mb-0">
          It provides an indication whether a stock at its current market price
          is expensive or cheap realtive to its earnings
        </p>
      </>
    ),
  },
  {
    key: "price_to_book_value",
    name: "Price To Book Value",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
          Market price per share / Book Value per share
        </p>
        <p className="mb-0">
          P/B Value is the ratio of the current market value of the company
          shares to the book value of equity. It is used to estimate if the
          company is over or undervalued.
        </p>
      </>
    ),
  },
  {
    key: "yield",
    name: "Dividend Yield",
    isNested: true,
    unit: "%",
  },
  {
    key: "",
    name: "Dividend & Other Metrics",
    isHeader: true,
  },
  {
    key: "ev",
    name: "Enterprise Value",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
          Market Capitalization + Market Value of Debt – Cash and Equivalents.
        </p>
        <p className="mb-0">
          Enterprise Value is the measure of a company's total value, including
          equity and short term and long term debt.
        </p>
      </>
    ),
  },
  {
    key: "dividend_per_share",
    name: "Dividend Per Share",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
          (Total Dividend paid - Special Dividend) / Shares outstanding
        </p>
        <p className="mb-0">
          It is the sum of declared dividends issued by a company for every
          ordinary share outstanding
        </p>
      </>
    ),
  },
  {
    key: "adj_dividend_per_share",
    name: "Adj Dividend Per Share",
    isNested: true,
    unit: "₹",
  },
  {
    key: "dividend_payout_ratio",
    name: "Dividend Payout Ratio",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">Total Dividend paid / Net Income</p>
        <p className="mb-0">
          It is the percentage of net earnings paid to shareholders in the form
          of dividends
        </p>
      </>
    ),
  },
];

export const bankRevenueTable = [
  {
    key: "net_sales",
    name: "Net Sales",
  },
];
