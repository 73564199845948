import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StockEndpoints from "../../../../../api/endPoints";
import ReactHelmet from "../../../../../components/react-helmet/ReactHelmet";
import useGetFetch from "../../../../../hooks/useGetFetch";
import { authStore } from "../../../../../redux/reducers/authSlice";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import AffiliateFaq from "./AffiliateFaq";
import AffiliateJoinSteps from "./AffiliateJoinSteps";
import HighlightSec from "./HighlightSec";
import SignupAffiliate from "./SignupAffiliate";

function AffiliateHome() {
  const navigate=useNavigate()
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const {
    isLoading,
    apiData,
    serverError,
  } = useGetFetch(StockEndpoints.postAffiliate);
const regFormRedirect=()=>{
  if(UserAuth.userData.access_token&&apiData===null){
    navigate('/affiliate-register')
  }
  else if(UserAuth.userData.access_token&&apiData){
    navigate('/affiliate-status')
  }
  else{
    navigate('/login',{state:'/become-affiliate'})
  }
}
  return (
    <>
     <ReactHelmet
        title='Affiliate Program by Trade Brains Portal'
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
   
    <div className="ff-poppins    w-100">
      <SignupAffiliate userRedirect={regFormRedirect}/>
      <AffiliateJoinSteps lightMode={lightMode}/>
      <HighlightSec lightMode={lightMode} userRedirect={regFormRedirect}/>
      <AffiliateFaq lightMode={lightMode}/>
    </div>
    </>
  );
}

export default AffiliateHome;
