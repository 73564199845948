import { LogoutOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import svgSheet from "../../../assets/svg/svgSheet";
import { authStore, setAuth } from "../../../redux/reducers/authSlice";
import {
  getThemeMode,
  toggleThemeMode,
} from "../../../redux/reducers/ThemeSlice";
import { resetWatchlistArr } from "../../../redux/reducers/watchListSlice";
import GoogleLogout from "../../../components/popups/GoogleLogout";

function AffiliateNav({ showDrawer, open, value }) {
  const dispatch = useDispatch();
  const { lightMode } = useSelector(getThemeMode);
  const navigate = useNavigate();
  const auth = useSelector(authStore);

  const userLogout = () => {
    dispatch(setAuth({}));
    dispatch(resetWatchlistArr());
    navigate("/");
  };

  //  icon dropdown after login
  const menu = (
    <Menu
      className={`ff-poppins ${
        lightMode ? "menu-DropDwon-light" : "menu-DropDwon-dark bg-dark-black "
      } `}
      style={{ width: "250px", height: "200px" }}
      items={[
        {
          label: (
            <Link to="/affiliate/user-data" className="flex align-items-center">
              {auth?.userData?.user?.profile_pic ? (
                <img
                  src={auth?.userData?.user?.profile_pic}
                  style={{ height: "30px", width: "30px" }}
                  className=" br-50 d-flex pointer"
                />
              ) : (
                <li
                  style={{ height: "30px", width: "30px" }}
                  className="avatar-img  br-50 d-flex pointer"
                ></li>
              )}
              <div>
                <h5 className="fs-s-14 fw-700">
                  {auth?.userData?.user?.first_name}
                </h5>
                <h5 className="fs-12">{auth?.userData?.user?.email}</h5>
              </div>
            </Link>
          ),
          key: "1",
        },
        {
          label: (
            <Link to="/" className="flex align-items-center">
              <div style={{ width: "24px", height: "24px" }}>
                {svgSheet.homeIcon}
              </div>
              <h5 className="fs-s-14 fw-700">Home</h5>
            </Link>
          ),
          key: "7",
        },
        {
          label: (
            <Link to="/affiliate/terms&conditions" className="flex align-items-center">
              <div style={{ width: "24px", height: "24px" }}>
                {svgSheet.termsIcon}
              </div>
              <h5 className="fs-s-14 fw-700">Terms & Conditions</h5>
            </Link>
          ),
          key: "4",
        },

        {
          label: (
            <Link to="/affiliate/helpcenter" className="flex align-items-center">
            <div style={{ width: "24px", height: "24px" }}>
              {svgSheet.contactUsIcon}
            </div>
            <h5 className="fs-s-14 fw-700">Help Center</h5>
          </Link>
          ),
          key: "2",
        },
        {
          label: (
            <div className="fs-s-14 fw-500 flex" onClick={userLogout}>
              {/* <div style={{width:'24px',height:'24px'}}>{svgSheet.logoutIcon}</div> */}
              <LogoutOutlined className="mx-10" style={{ fontSize: "23px" }} />
              {/* <span>Logout</span> */}
              <GoogleLogout />
            </div>
          ),
          key: "5 ",
        },
      ]}
    />
  );

  return (
    <div
      style={{ height: "92px", background: lightMode ? "white" : "#292E3F" }}
      className={`${open && "pl-300"} px-15  justify-content-between d-flex ${
        lightMode ? " bg-white" : "bg-dark  "
      } card-shadow-black d-flex  bg-dark align-items-center`}
    >
      <div className="d-flex">
        <p onClick={showDrawer} className="pointer mt-10-0 mb-0">
          {svgSheet?.burgerIcon}
        </p>
        <p className="mb-0 fs-24-16 fw-700 ml-20">Affiliate {value}</p>
      </div>
      <div className="flex pointer">
        {lightMode ? (
          <span
            style={{ marginTop: "7px" }}
            onClick={() => dispatch(toggleThemeMode(false))}
          >
            {svgSheet.LightModeMoon}
          </span>
        ) : (
          <span
            style={{ marginTop: "7px" }}
            onClick={() => dispatch(toggleThemeMode(true))}
          >
            {svgSheet.DarkModeSun}
          </span>
        )}
        {/* <div className="br-50 mx-15 bg-gray w-45 h-45"></div> */}
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          {auth?.userData?.user?.profile_pic ? (
            <img
              src={auth.userData.user.profile_pic}
              className=" h-45 w-45 br-50 d-flex pointer"
            />
          ) : (
            <li className="avatar-img h-45 w-45 br-50 d-flex pointer">
              {/* {(auth.userData.user.first_name).charAt(0)} */}
            </li>
          )}
        </Dropdown>
      </div>
    </div>
  );
}

export default AffiliateNav;
