import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function StockAverageCalc() {
  const { lightMode } = useSelector(getThemeMode);

  const result = [
    {
      comp_name: "demo data",
      Free_cash: "100",
      Present_Value: "411",
    },
    {
      comp_name: "demo data",
      Free_cash: "100",
      Present_Value: "411",
    },
    {
      comp_name: "demo data",
      Free_cash: "100",
      Present_Value: "411",
    },
    {
      comp_name: "demo data",
      Free_cash: "100",
      Present_Value: "411",
    },
  ];
  const bottomData = [{
    header:"What is the Stock Average Calculator?",
    body:"This is a simple calculator that tells you the average price of the stock that you have bought, regardless of the number of shares or the price paid per share in every transaction."
  },
  {
    header:"How does this calculator work?",
    body:"Under this calculator, the average price of each stock bought is to be calculated and that is done on the simple rule of weighted average. Here you have to multiply each price by the number of shares bought at that price and then we sum it up. And the resultant value is divided by the total number of shares bought to arrive at the final price of each share of that particular stock in your portfolio.The Stock Average calculator is especially useful if you are a trader or investor who is always on the lookout for opportunities to add to your existing positions as and when the opportunity comes. This calculator further gives you the ability to understand the actual profit or loss on the stock as you are aware of the average buy price."
  },
  
 ]
  const [tot, setTot] = useState(0.0);
  const [avg, setAvg] = useState(0.0);
  const ResultHeder =[{
    "Total Shares Bought  ":Math.floor(
      tot && tot !== Infinity && tot !== -Infinity
        ? tot
        : 0
    ).toLocaleString("en-US")
  },
  {
    "Average Purchase Price   ":Math.floor(
      avg && avg !== Infinity && avg !== -Infinity
        ? avg
        : 0
    ).toLocaleString("en-US")
  }]
  

  const navigate = useNavigate();
  const formlist = [
    { name: "Free-Cash", value: "Free Cash Flow (in Mn/Cr)" },
    { name: "Growth-Rate", value: "Growth Rate (1-5 Years)(%)" },
    { name: "Total-Cash", value: "Total Cash (in Mn/Cr)" },
    { name: "Growth-Rate", value: "Growth Rate (6-10 Years)(%)" },
    { name: "Total-Debt", value: "Total Debt ( in Mn/Cr)" },
    { name: "Terminal-Growth", value: "Terminal Growth Rate (%)" },
    { name: "Shares-Outstanding", value: "Shares Outstanding (in Mn/Cr)" },
    { name: "Discount-Rate", value: "Discount Rate (%) " },
  ];
  const Averagecalc = [{
      name:"Share Bought",
      list:['1','2','3','4','5']
  },{
    name:"Purchase Price",
    list:['1','2','3','4','5']
}]
  const valueData = [
    { key: "Terminal Year CF", value: "₹ 1,515.97" },
    { key: "Terminal Value", value: "₹ 1,515.97" },
    { key: "Total PV", value: "₹ 1,515.97" },
    { key: "Total Cash", value: "₹ 1,515.97" },
    { key: "Total Debt", value: "₹ 1,515.97" },
    { key: "Compact Value", value: "₹ 1,515.97" },
  ];
  const [SharePrice, setSharePrice] = useState({
      sharePrice1:"",
      sharePrice2:"",
      sharePrice3:"",
      sharePrice4:"",
  })
  const [PurchasePrice, setPurchasePrice] = useState({
    PurchasePrice1:"",
    PurchasePrice2:"",
    PurchasePrice3:"",
    PurchasePrice4:"",
  })



  console.log("SharePrice",SharePrice);
  return (
    <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
        <p className="pointer" onClick={() => navigate(-1)}>
          Calculators / Stock Average Calculator
        </p>
        <p className="mb-0 fs-30-20 fw-700">Stock Average Calculator</p>
        <p className="w-60-80">
          This calculator is useful to find the average price of the stock
          bought in multiple tranches over a period of time.
        </p>
      </div>
      <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
        <div>
          <CalcInputFeilds setAvg={setAvg} setTot={setTot} PurchasePrice={PurchasePrice} SharePrice={SharePrice} setPurchasePrice={setPurchasePrice} setSharePrice={setSharePrice} lightMode={lightMode} Averagecalc={Averagecalc} />
        </div>
        <div>
          <ResultContainerCalc ResultHeder={ResultHeder} lightMode={lightMode} />
        </div>
      </div>
      <div>
        <BottomDataSection bottomData={bottomData} />
      </div>
    </div>
  );
}

export default StockAverageCalc;
