import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Input, Upload } from "antd";
import React, { useState } from "react";
import AntFormInput from "../../../../components/ant/AntFormInput";

function PorrtfolioFormNew({
  lightMode,
  form,
  formSubmit,
  initValues,
  newStock,
  investorName,
  stockHoldings,
  occupation,
  Dob,
  education,
  college,
  file,
  description,
}) {
  const field1 = [
    {
      uid: "-1",
      name: "yyy.png",
      status: "done",
      url: initValues?.image,
      thumbUrl: initValues?.image,
    },
  ];



  return (
    // <div style={{ gap: "20px" }} className="d-flex d-flex-row-col w-100">
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={formSubmit}
        className="flex flex-col-row"
        name="admin_portfolio"
        initialValues={initValues}
      >
        <div className="w-100-50">
          <AntFormInput
            label="Name"
            value={investorName}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          
          <Form.Item
            label={
              <div>
                <p className="mb-0">Thumbnail Image</p>
                <p className="mb-0">Image Dimension 300*300px less than 50KB</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please select  Image",
              },
            ]}
            style={{ margin: "30px 0px" }}
            value={file}
            initialValue={field1}
            getValueFromEvent={(e) => e.file}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              defaultFileList={[...field1]}
              maxCount={1}
              className="mt-10 darkmode-antdUploader"
            >
              <button
                className="btn-bg-primary p-10 mt-10 fw-500 br-5 text-white"
                icon={<UploadOutlined />}
              >
                Click to upload
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Bio"
            rules={[
              {
                required: true,
              },
            ]}
            //   style={{ margin: "30px 0px" }}
            name="desc"
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
          </Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>Net worth :</p>
            <AntFormInput
            // label="Name"
            defaultValue={newStock}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>No. of Stocks :</p>
            <AntFormInput
            // label="Name"
            value={stockHoldings}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>Born :</p>
            <AntFormInput
            // label="Name"
            value={Dob}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>Education :</p>
            <AntFormInput
            // label="Name"
            value={education}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>College :</p>
            <AntFormInput
            // label="Name"
            value={college}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{color: "white"}}>Occupation  :</p>
            <AntFormInput
            // label="Name"
            value={occupation}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          </div>
          <Form.Item
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter Description",
              },
            ]}
            //   style={{ margin: "30px 0px" }}
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Input.TextArea
              showCount
              // maxLength={1000}
              type="text"
              rows={8}
              value={description}
              style={{
                height: "150px",
                width: "100%",
                paddingInline: "0px",
              }}
              className={`w-100  auth-form-input  ${
                lightMode
                  ? "bg-gray ant-textarea-light "
                  : "bg-dark-gray  ant-textarea-dark"
              }`}
              placeholder="Type Here"
            />
          </Form.Item>
          
        </div>
       
      </Form>
     
    </>
  );
}

export default PorrtfolioFormNew;
