import React from "react";
import BarChart from "../../components/Charts/BarChart";
import DoughnutWithLabels from "../../components/Charts/DoughnutWithLabels";
import CustomTable from "../../components/CustomTable";
import CalculatorSlider from "./CalculatorSlider";

function ResultContainerCalc({
  ResultHeder,
  lightMode,
  DonutChartResult,
  chartData,
  setGetRsult,
  chartOptions,
  donutChart,
  resultLise,
  tableResult,
  tabelColumn,
  
}) {
  const data = [{}];
 console.log("chartData",chartData);
  return (
    <div>
      <p className="fs-s-20 fw-600">Result</p>
      <div
        className={`p-40 br-20 mt-20 ${
          lightMode ? "card-drop-light-shadow" : "bg-dark-gray"
        }`}
      >
        <div>
          {/* Expected Amount : 5,808,476 */}
          {ResultHeder !== undefined && (
            <div>
            <p className="fs-22-18  fw-600 gradient-text-blue mb-0">
              {" "}
              {Object.keys(ResultHeder[0])} :{" "}
              {Object.values(ResultHeder[0])}
            </p>
            {ResultHeder[1] && <p className="fs-22-18  fw-600 gradient-text-blue mb-0">
              {" "}
              {Object.keys(ResultHeder[1])} :{" "}
              {Number(Object.values(ResultHeder[1])).toFixed(2)}
            </p>}
            </div>
          )}
        </div>
        {resultLise?.map((items) => (
          <div className="w-100 d-flex my-15 justify-content-between">
            <div className="w-50">
            <p className="mb-0  fw-600 fs-20-16">{items?.key}</p>
            </div>
            <div className="w-50">
            <p className="mb-0 fw-6 00 fs-20-16 ff-lato ">: {items?.value}</p>
            </div>
          </div>
        ))}
      </div>
      {chartData?.labels?.length > 0 && (
        <div className="mt-40" style={{ width: "100%", height: "370px" }}>
          <BarChart options={chartOptions} data={chartData} />
        </div>
      )}
      {/* <DoughnutWithLabel
          isIndustry={true}
          divID="industry"
          labelArray={['10','dasda','dasdas','dasdas','dasdas']}
          lightMode={lightMode}
          totalValue={100}
          dataArray={
            [20,10,20,20,10,20]
          }
          IsOverFlowHidden={IsOverFlowHidden}
        /> */}
      {donutChart && (
        <DoughnutWithLabels
          isIndustry={true}
          divID="industry"
          labelArray={["Invested Value", "Est.Return"]}
          lightMode={lightMode}
          inRuppee={true}
          totalValue={30}
          dataArray={[
            Number(Number(DonutChartResult.investAmt).toFixed(2)),
            Number(Number(DonutChartResult.return).toFixed(2)),
          ]}
          IsOverFlowHidden={true}
        />
      )}
      {tableResult && (
        <div
          className={`table-shadow mt-30  ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            data={tableResult}
            scrollable={true}
            scrollLimit={500}
            columns={tabelColumn}
          />
        </div>
      )}
    </div>
  );
}

export default ResultContainerCalc;
