import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { checkFingradAccess, getPlans, paymentAPI } from "../api/fetchClient";
import svgSheet from "../assets/svg/svgSheet";
import { authStore, setAuth } from "../redux/reducers/authSlice";
import { getThemeMode } from "../redux/reducers/ThemeSlice";
import successImg from "../assets/images/confetti portal.png";
import fingradLogo from "../assets/images/logo/fingrad-logo.png";
import {getFormatDate} from '../utilityFn/getFormatdate'
function PaymentOfferStatus(finURL) {
  const { lightMode } = useSelector(getThemeMode);
  const dispatch = useDispatch();
  const { userData } = useSelector(authStore);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [planData, setplanData] = useState({ status: true, plan: {} });
  const [fingradAccessStatus, setfingradAccessStatus] = useState({
    url: false,
  })
  // get plan api
  useEffect(() => {
    const getPlanAPI = async () => {
      await getPlans()
        .then((res) => {
          setplanData({
            status:
              searchParams?.get("razorpay_payment_link_status") === "paid",
            plan: res?.data?.find(
              (item) => item.plan_id === searchParams.get("plan")
            ),
          });
        })
        .catch((err) => console.log(err));
      await paymentAPI(location.search)
        .then((resp) => {
          getFinCourseAccess()
          dispatch(
            setAuth({
              access_token: userData.access_token,
              refresh_token: userData.refresh_token,
              user: resp.data,
            })
          );  
        })
        .then((err) => console.log(err));
    };
    getPlanAPI();
  }, []);
  const getFinCourseAccess = async () => {
    await checkFingradAccess(userData?.user?.email).then((resp) => {
      setfingradAccessStatus(resp.data);
    });
  };
  // useEffect(() => {
  //   getFinCourseAccess()
  // }, [])
  return (
    <>
      {planData?.status ? (
        <div className="my-body flex justify-content-center">
          <div
            className={`w-70-100 p-50-16 mx-15 flex flex-col align-items-center br-5 ${
              lightMode
                ? "bg-gray card-drop-light-shadow"
                : "card-drop-dark-shadow"
            }`}
          >
            <div>
              <div className="d-flex flex-col-row align-md-center gap-4r">
                <img
                  style={{ width: "125px", height: "125px" }}
                  src={successImg}
                  alt="img"
                />
                <div className="d-flex flex-col align-md-center mb-30">
                  <h1 className="fs-40-28 fw-700 lh-40 mb-10">
                    Congratulations!!!
                  </h1>
                  <h1 className="fs-40-28 fw-700 lh-40 mb-30 text-md-center">
                    Your Whales Plan is Active on Portal
                  </h1>
                  <p className="fs-18-16 fw-500 text-md-center">
                  Plan expires on {" "}
                    <span className="underline"> {getFormatDate(userData?.user?.premiumExpiry)}</span>
                  </p>
                </div>
              </div>
              <div>
                <p className="fs-20-16 fw-600 text-blue-grad mb-10">
                Get your Fingrad Access
                </p>
                <div
                  style={{
                    border: "1px dashed #2B8BC1",
                    background: lightMode
                      ? "#F2F6F8"
                      : "rgba(16, 17, 17, 0.25)",
                  }}
                  className="flex flex-col-row p-15 div-items-center"
                >
                  <img
                    src={fingradLogo}
                    className="mx-auto"
                    style={{ width: "240px", height: "80px" }}
                  />
                  <div>
                    <p>
                      {" "}
                      To activate{" "}
                      <span className="fw-600">Fingrad Subscription:</span>
                    </p>
                    <p>
                      1. Kindly Register to our Fingrad website using this{" "}
                      <a  href={fingradAccessStatus?.url&&fingradAccessStatus?.url} className="fw-600 underline">link</a> or button
                      given below.
                    </p>
                    <p>
                      2. Please note that you must use the same Email ID as registered here.
                    </p>
                    <p>
                      3. In fingrad, make the purchse with zero payment to enable your free subscription of 3 months.
                    </p>
                    <p>
                      4. On Completing the purchase, your Basic Plan  of 3 months access on fingrad  will be done.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <a href={fingradAccessStatus?.url&&fingradAccessStatus?.url} target="_blank">
                  <button
                    style={{ width: "200px", height: "50px" }}
                    className="my-20 btn-bg-primary fw-600 text-white fs-16-14 br-5"
                  >
                    Go To Fingrad
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="my-body flex justify-content-center"
          style={{ height: "50vh" }}
        >
          <div
            style={{ maxWidth: "600px", maxHeight: "470px" }}
            className={`w-49-100 flex flex-col align-items-center br-5 ${
              lightMode
                ? "bg-gray card-drop-light-shadow"
                : "card-drop-dark-shadow"
            }`}
          >
            <div className="mt-30">
              {planData?.status ? svgSheet.paySuccess : svgSheet.payReject}
            </div>

            <h1 className="fs-40-28 fw-700">
              {planData?.status ? "Payment Successful" : "Payment Failed"}
            </h1>
            {planData?.status ? (
              <div className="text-center">
                <p className="fs-18-14 fw-500 mb-10">
                  You have Successfully Subscribed to{" "}
                </p>
                <p className="fs-20-16 text-blue-grad fw-700">
                  "{planData?.plan?.plan_sname}"
                </p>
              </div>
            ) : (
              <div className="text-center">
                <p className="fs-18-14 fw-500 mb-10">
                  For further Queries & clarifications
                </p>
                <p className="fs-18-14  fw-600">Please Email us</p>
              </div>
            )}
            <Link to={"/"}>
              <button
                style={{ width: "200px", height: "50px" }}
                className="mb-30 btn-bg-primary fw-600 text-white fs-16-14 br-5"
              >
                Back to Dashboard
              </button>
            </Link>
            <p className="fw-500">
              Write to us{" "}
              <a
                href="mailto:payment@tradebrains.in"
                className="text-blue-grad"
              >
                payment@tradebrains.in
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentOfferStatus;
