import React, { useEffect, useRef } from "react";
import BarChart from "../../components/Charts/BarChart";
import { getGradient } from "../../components/Charts/getGradients";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";

function PandLGraphTable({ PandLtableData, PandLData, lightMode,notBankStock }) {
  let yearsHeader, netSales, netProfit, positiveNums;
  const salesRef = useRef(null);
  const PandLRef = useRef(null);
  const pandlColorRef = useRef(null);
  const npmRef = useRef(null);
  if (PandLtableData) {
    yearsHeader = PandLtableData?.YearHeaders?.slice(1).map((year, i) => {
      return year.title.props.children;
    });
    netSales = Object.values(PandLData).map((data) =>notBankStock!=="INSURANCE"?data.net_sales:data?.revenue_from_operations??0);
    netProfit = Object.values(PandLData).map((data) => data.net_profit);

    positiveNums = Object.values(PandLData).map((data) =>
      Math.abs(data.net_profit)
    );
  }

  useEffect(() => {
    if (netSales && netProfit) {
      // setLegendHover()
      salesRef.current.innerText = `Net Sales: ₹ ${numberWithCommas(
        graphData.datasets[0].data.at(-1)
      )} Cr`;
      PandLRef.current.innerText = `${
        netProfit.at(-1) > 0 ? "Net Profit : ₹" : "Net Loss : -₹"
      } ${numberWithCommas(graphData.datasets[1].data.at(-1))} Cr`;

      pandlColorRef.current.style.background =
        netProfit.at(-1) > 0
          ? "linear-gradient(180deg, #38EF7D 0%, #11998E 100%)"
          : "linear-gradient(180deg, #ED213A 0%, #93291E 100%)";
      const npmValue = (netProfit.at(-1) / netSales.at(-1)) * 100;
      npmRef.current.innerText = `Net Profit Margin: ${npmValue.toFixed(2)}%`;
    }
  }, [netSales, netProfit]);

  // bar gradient colours
  const gradientColors = [
    [
      { xValue: 0, color: "#00B4DB" },
      { xValue: 1, color: "#0083B0" },
    ],
    [
      { xValue: 0, color: "#38EF7D" },
      { xValue: 1, color: "#11998E" },
    ],
    [
      { xValue: 0, color: "#ED213A" },
      { xValue: 1, color: "#93291E" },
    ],
  ];
 
  const graphData = {
    //Bring in data
    labels: yearsHeader,
    datasets: [
      {
        barPercentage: 0.9,
        barThickness: 30,
        label: "Net Sales",
        borderColor: "rgb(8, 133, 128)",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[0]);
        },
        borderWidth: 0,
        fill: true,
        data: netSales,
      },
      {
        barPercentage: 0.9,
        barThickness: 30,
        fill: true,
        label: "Net Profit",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          // const position = { x: context.element?.x, y: context.element?.y };
          if (!chartArea) {
            return null;
          } else if (netProfit && netProfit[context.dataIndex] > 0) {
            return getGradient(ctx, chartArea, gradientColors[1]);
          } else {
            return getGradient(ctx, chartArea, gradientColors[2]);
          }
        },
        data: positiveNums,
        borderColor: "rgb(240, 180, 26)",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    //Customize chart options
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 2,
          borderDash: [7, 10],
          // borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
      },
      x: {
        ticks: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          font: {
            size: 14,
          },
          padding: 5,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 1,
          borderDash: [5, 15],
          borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        // position: "right",
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "center",
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },
        displayColors: false,
        callbacks: {
          titleAlign: "center",
          yAlign: "center",
          titleSpacing: 5,
          backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",

          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            if (context.datasetIndex === 0) {
              return "#50B1D7";
            } else {
              if (netProfit[context.dataIndex] > 0) {
                return "#41CE57";
              } else {
                return "#DA3A41";
              }
            }
          },
          label: (context) => {
            salesRef.current.innerText = `Net Sales: ₹ ${numberWithCommas(
              graphData.datasets[0].data[context.dataIndex]
            )} Cr`;
            PandLRef.current.innerText = `${
              netProfit[context.dataIndex] > 0
                ? "Net Profit : ₹"
                : "Net Loss : -₹"
            } ${numberWithCommas(
              graphData.datasets[1].data[context.dataIndex]
            )} Cr`;
            pandlColorRef.current.style.background =
              netProfit[context.dataIndex] > 0
                ? "linear-gradient(180deg, #38EF7D 0%, #11998E 100%)"
                : "linear-gradient(180deg, #ED213A 0%, #93291E 100%)";
            const npmValue =
              (netProfit[context.dataIndex] /
                graphData.datasets[0].data[context.dataIndex]) *
              100;
            npmRef.current.innerText = `Net Profit Margin: ${npmValue.toFixed(
              2
            )}%`;
            if (context.datasetIndex === 1) {
              return ` ${
                netProfit[context.dataIndex] > 0
                  ? `₹ ${numberWithCommas(context.dataset.data[context.dataIndex])}`
                  : `₹ -${numberWithCommas(context.dataset.data[context.dataIndex])}`
              }`;
            } else {
              return `₹ ${numberWithCommas(context.dataset.data[context.dataIndex])}`;
            }
          },
        },
      },
    },
  };
  return (
    <div className="pros-and-cons d-flex d-flex-col">
      <h2 className="main-header mb-20">Profit and Loss statement</h2>
      <div className="flex-col-row">
        <div style={{ height: "300px" }} className="w-100-65">
          <BarChart id="paandlChart" data={graphData} options={options} />
        </div>
        <div className="flex-row-col w-100-35 ff-lato justify-content-center align-items-center ">
          <div>
            <div className="flex">
              <span
                className="legend-dot"
                style={{
                  background:
                    "linear-gradient(180deg, #00B4DB 0%, #0083B0 100%)",
                }}
              ></span>
              <p className="fs-s-16 ff-lato" ref={salesRef}></p>
            </div>
            <div className="flex">
              <span className="legend-dot" ref={pandlColorRef}></span>
              <p ref={PandLRef} className="fs-s-16 ff-lato"></p>
            </div>
            <div className="flex">
              <span
                className="mixed-dot"
                style={{
                  background: "linear-gradient(45deg, #00B4DB 50%,#11998E 50%)",
                }}
              ></span>
              <p ref={npmRef} className="fs-s-16 ff-lato"></p>
            </div>
          </div>
        </div>
      </div>
      <p className=" d-flex justify-content-end fs-s-12 text-gray">*Values in Cr.</p>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
        scrollLimit={700}
          columns={PandLtableData?.YearHeaders}
          scrollable={true}
          data={PandLtableData?.tableBodyData}
        />
      </div>
    </div>
  );
}

export default PandLGraphTable;
