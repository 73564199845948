import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPlans } from "../../api/fetchClient";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import FAQSection from "../home/components/FAQSection";
import StockFaq from "../stock details/StockFaq";
import FestivalBanner from "./FestivalBanner";
import PlanCard from "./PlanCard";

function PlanPage() {
  const { lightMode } = useSelector(getThemeMode);
  const [PlanData, setPlanData] = useState();
  const [initalState, setinitalState] = useState("Whales");
  const [SortedPlan, setSortedPlan] = useState();
  const [mailSorted, setmailSorted] = useState();

  const auth = useSelector(authStore);
  const plasidandname = {
    basic: "Life Time Free",
    "3months": "3 Months",
    "6months": "6 Months",
    year: "1 Year",
  };
  const faqData = [
    {
      head: "Is Trade Brains Portal Free?",
      content:
        "Yes, Trade Brains provides complete fundamental analysis data to its user for free to do complete analysis, however it also offers few exclusive features to its premium users ",
    },
    {
      head: "What are the different portal plans available?",
      content:
        "Trade Brains Portal offers Bulls, Sharks and Whales plan, with different subscription pricing and tenure ",
    },
    {
      head: "What is the difference between Bulls, Sharks and Whales Plan?",
      content:
        "Bulls plan is for short term subscription period of 3 months, Sharks for 6 months and whales for 12 months. In addition, they also offer different benefits and features for intensive research.",
    },
    {
      head: "How can i get premium plan?",
      content:
        "To get the premium, Select any one of our the plan and make the payment for that plan. Once the payment is successfully completed, you'll get the premium plan access.",
    },
    {
      head: "How can i make the payment?",
      content:
        "To make the payment, first you need to signup or login using your email and then you can purchase any of the premium plan by making the payment.",
    },
    {
      head: "Is the premium available on Portal App?",
      content:
        "Yes, you can get the premium plans on Portal Android and iOS apps.",
    },
    {
      head: "What will I get after getting the premium?",
      content:
        "After subscription to any of our premium plans, you'll get access to exclusive features offered by our Portal like Buckets, Portfolio Analysis, Advanced Stock Screener, Multiple Stock Comparision and many more.",
    },

    {
      head: "Can i change the plan?",
      content:
        "Yes, you can upgrade the plan and when you upgrade the plan additional subscription period will be added to your profile with premium features.",
    },
    {
      head: "Can i cancel my subscription?",
      content:
        "Yes, you can cancel your subscription at any time. If you want to cancel your existing plan, simply write to us at cancel@portal.tradebrains.in You'll receive the confirmation email within 24 hours.",
    },
    {
      head: "what kind of payment methods are available?",
      content:
        "You can make the payment using Credit card, debit card, net banking, upi, Pay Later and even EMIs. All the payments are totally secured and you'll get a payment invoice after completing the payment on your registered email.",
    },
  ];

  const arrayOFPlan = [
    { basic: "Life Time Free" },
    { "3months": "3 Months" },
    { "6months": "6 Months" },
    { year: "1 Year" },
  ];
  const plansType = ["Free", "Bulls", "Sharks", "Whales"];

  useEffect(() => {
    getPlans().then((res) => {
      setPlanData(res.data);
    });
    if (PlanData === undefined) {
      return;
    }
    PlanData.filter(
      (items) => items?.plan_sname === initalState && setSortedPlan(items)
    );
  }, []);

  useEffect(() => {
    if (PlanData === undefined) {
      return;
    }
    PlanData &&
      PlanData.filter(
        (items) => items?.plan_sname === initalState && setSortedPlan(items)
      );
  }, [initalState, PlanData]);

  const emailSupportArr = [
    {
      "Email Support": false,
      "Call Support": false,
    },

    {
      "Email Support": true,
      "Call Support": false,
    },

    {
      "Email Support": true,
      "Call Support": false,
    },

    {
      "Email Support": true,
      "Call Support": true,
    },
  ];
  const fingradSupport = [
    {
      "+60 Stock Market Courses on FinGrad (Worth Rs 999)": false,
    },
    {
      "+60 Stock Market Courses on FinGrad (Worth Rs 999)": false,
    },
    {
      "+60 Stock Market Courses on FinGrad (Worth Rs 999)": false,
    },
    {
      "+60 Stock Market Courses on FinGrad (Worth Rs 999)": true,
    },
  ];
  const emailDataArr = useMemo(() => {
    const updateddata = PlanData?.map((item, i) => {
      return { ...item, benefits: emailSupportArr[i] };
    });
    const mailSort = updateddata?.find(
      (items) => items?.plan_sname === initalState
    );

    return { updateddata, mailSort };
  }, [PlanData, initalState]);

  const fingradSupportArr = useMemo(() => {
    const updateddata = PlanData?.map((item, i) => {
      return { ...item, benefits: fingradSupport[i] };
    });
    const mailSort = updateddata?.find(
      (items) => items?.plan_sname === initalState
    );

    return { updateddata, mailSort };
  }, [PlanData, initalState]);
console.log(fingradSupportArr);
  return (
    <>
      {/* <FestivalBanner/> */}
      <ReactHelmet
        title='Trade Brains Premium - Unlock all Amazing Features'
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      <div className="my-body ">
        <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
          <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15 justify-content-center mb-0 w-100 relative">
            <p className="fs-36-20 fw-700 d-flex justify-content-center mb-0">
              Unlock Portal With Full Potential
            </p>
            <p className="fs-24-16 d-flex justify-content-center">
              “May the markets be with you”
            </p>
          </div>
          <div className="only-PC-view">
            <div className="d-flex ">
              <div
                className={` w-20p  mt-40 p-20 d-flex flex-col ${
                  lightMode
                    ? "shadow-div bg-white "
                    : "table-shadow card-drop-dark b1px-dark-mode"
                }`}
              >
                <p className="fs-s-20 fw-700 ">
                  Choose <br /> Your plan
                </p>
                <p className="fs-s-12">Sign in & explore our features</p>
              </div>
              <div style={{ width: "80%" }} className="d-flex flex-wrap  mt-40">
                {PlanData?.map((items, i) => (
                  <PlanCard
                    key={i}
                    id={items?.plan_id}
                    lightMode={lightMode}
                    name={items?.plan_sname}
                    Price={items?.plan_amount}
                    disc={items?.plan_desc}
                    initial={items?.initial_price}
                    currentPlan={auth?.userData?.user?.plan?.planId}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="only-mobile-view">
            <div>
              <p className="w-700 fs-s-20 d-flex justify-content-center fw-700">
                Choose Your plan
              </p>

              <div
                className={`${
                  lightMode ? "bg-gray" : "bg-dark-gray"
                } b1px-dark-mode br-5 w-100 pointer d-flex justify-content-around align-items-center `}
              >
                {plansType.map((items, i) => (
                  <p
                    key={i}
                    className={`mb-0 p-5 w-25 text-align-center ${
                      items === initalState && "btn-bg-primary text-white"
                    }`}
                    onClick={() => setinitalState(items)}
                  >
                    {items}
                  </p>
                ))}
              </div>
              <div className="d-flex flex-wrap   mt-40">
                <PlanCard
                  id={SortedPlan?.plan_id}
                  lightMode={lightMode}
                  initial={SortedPlan?.initial_price}
                  name={SortedPlan?.plan_sname}
                  Price={SortedPlan?.plan_amount}
                  disc={SortedPlan?.plan_desc}
                  currentPlan={auth?.userData?.user?.plan?.planId}
                />
              </div>
            </div>
          </div>

          <div>
             {/* fingrad ////////////////////////////// */}
             <p className="my-20">Bonus</p>
            <div
              className={`w-100 d-flex  ${
                lightMode ? " shadow-div bg-white" : "bg-dark-gray table-shadow"
              }
            }`}
            >
              <div className="w-20p-50">
                {fingradSupportArr?.updateddata?.length > 0 &&
                  Object.keys(fingradSupportArr?.updateddata[0]?.benefits).map(
                    (items, i) => (
                      <div
                        key={i}
                        className={` d-flex align-items-center justify-content-start ml-30 ${
                          lightMode ? "bbr-light-1px" : "bbr-1px"
                        }`}
                      >
                        <p
                          className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                        >
                          {items}
                        </p>
                      </div>
                    )
                  )}
              </div>
              <div style={{ width: "80%" }} className="only-PC-view">
                <div className="d-flex ">
                  {fingradSupportArr?.updateddata?.map((items, i) => (
                    <div key={i} className="d-flex flex-col w-25">
                      {Object.keys(fingradSupportArr?.updateddata[0]?.benefits)?.map(
                        (beni, j) => (
                          <div
                            key={j}
                            className={`   d-flex align-items-center justify-content-start ml-30 ${
                              lightMode ? "bbr-light-1px" : "bbr-1px"
                            }`}
                          >
                            <p
                              className={`h-75 d-flex align-items-center mb-0 fs-16-14 fw-500`}
                            >
                              {items?.benefits[beni] === true ? (
                                <p
                                  style={{
                                    color: lightMode
                                      ? redGreenColorPicker(1, lightMode)
                                      : redGreenColorPicker(1, lightMode),
                                  }}
                                  // className={`my-40  fs-16-14 fw-500 `}
                                  className="mb-0"
                                >
                                  <CheckOutlined />
                                </p>
                              ) : items?.benefits[beni] === false ? (
                                <p
                                  style={{
                                    color: lightMode
                                      ? redGreenColorPicker(-1, lightMode)
                                      : redGreenColorPicker(-1, lightMode),
                                  }}
                                  // className={`my-40  fs-16-14 fw-500 `}
                                  className="mb-0"
                                >
                                  <CloseOutlined />
                                </p>
                              ) : (
                                <p className="Textcapitalize mb-0">
                                  {" "}
                                  {items?.benefits[beni]}
                                </p>
                              )}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: "50%" }} className="d-flex only-mobile-view">
                <div className="d-flex flex-col w-25p-100">
                  {fingradSupportArr?.updateddata?.length > 0 &&
                    Object.keys(fingradSupportArr?.updateddata[0]?.benefits).map(
                      (beni, i) => (
                        <div
                          key={i}
                          className={`   d-flex align-items-center justify-content-start ml-30 ${
                            lightMode ? "bbr-light-1px" : "bbr-1px"
                          }`}
                        >
                          <p
                            className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                          >
                            {fingradSupportArr?.mailSort?.benefits[beni] === true ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(1, lightMode)
                                    : redGreenColorPicker(1, lightMode),
                                }}
                                className="mb-0"
                                // className={`my-40  fs-16-14 fw-500 `}
                              >
                                <CheckOutlined />
                              </p>
                            ) : fingradSupportArr?.mailSort?.benefits[beni] ===
                              false ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(-1, lightMode)
                                    : redGreenColorPicker(-1, lightMode),
                                }}
                                className="mb-0"
                                // className={`my-40  fs-16-14 fw-500 `}
                              >
                                <CloseOutlined />
                              </p>
                            ) : (
                              <p className="Textcapitalize mb-0">
                                {" "}
                                {fingradSupportArr?.mailSort?.benefits[beni]}
                              </p>
                            )}
                          </p>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>


             {/* fingrad ////////////////////////////// */}

            <p className="my-20">Package Inclusion for you</p>

            <div
              className={`w-100 d-flex  ${
                lightMode ? " shadow-div bg-white" : "bg-dark-gray table-shadow"
              }
            }`}
            >
              <div className="w-20p-50">
                {PlanData?.length > 0 &&
                  Object.keys(PlanData[0]?.benefits).map((items, i) => (
                    <div
                      key={i}
                      className={` d-flex align-items-center justify-content-start ml-30 ${
                        lightMode ? "bbr-light-1px" : "bbr-1px"
                      }`}
                    >
                      <p
                        className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                      >
                        {items}
                      </p>
                    </div>
                  ))}
              </div>
              <div style={{ width: "80%" }} className="only-PC-view">
                <div className="d-flex ">
                  {PlanData?.map((items, i) => (
                    <div key={i} className="d-flex flex-col w-25">
                      {Object.keys(PlanData[0]?.benefits)?.map((beni, j) => (
                        <div
                          key={j}
                          className={`   d-flex align-items-center justify-content-start ml-30 ${
                            lightMode ? "bbr-light-1px" : "bbr-1px"
                          }`}
                        >
                          <p
                            className={`h-75 d-flex align-items-center mb-0 fs-16-14 fw-500`}
                          >
                            {items?.benefits[beni] === true ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(1, lightMode)
                                    : redGreenColorPicker(1, lightMode),
                                }}
                                // className={`my-40  fs-16-14 fw-500 `}
                                className="mb-0"
                              >
                                <CheckOutlined />
                              </p>
                            ) : items?.benefits[beni] === false ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(-1, lightMode)
                                    : redGreenColorPicker(-1, lightMode),
                                }}
                                // className={`my-40  fs-16-14 fw-500 `}
                                className="mb-0"
                              >
                                <CloseOutlined />
                              </p>
                            ) : (
                              <p className="Textcapitalize mb-0">
                                {" "}
                                {items?.benefits[beni]}
                              </p>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ width: "50%" }} className="d-flex only-mobile-view">
                <div className="d-flex flex-col w-25p-100">
                  {PlanData?.length > 0 &&
                    Object.keys(PlanData[0]?.benefits).map((beni, i) => (
                      <div
                        key={i}
                        className={`   d-flex align-items-center justify-content-start ml-30 ${
                          lightMode ? "bbr-light-1px" : "bbr-1px"
                        }`}
                      >
                        <p
                          className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                        >
                          {SortedPlan?.benefits[beni] === true ? (
                            <p
                              style={{
                                color: lightMode
                                  ? redGreenColorPicker(1, lightMode)
                                  : redGreenColorPicker(1, lightMode),
                              }}
                              className="mb-0"
                              // className={`my-40  fs-16-14 fw-500 `}
                            >
                              <CheckOutlined />
                            </p>
                          ) : SortedPlan?.benefits[beni] === false ? (
                            <p
                              style={{
                                color: lightMode
                                  ? redGreenColorPicker(-1, lightMode)
                                  : redGreenColorPicker(-1, lightMode),
                              }}
                              className="mb-0"
                              // className={`my-40  fs-16-14 fw-500 `}
                            >
                              <CloseOutlined />
                            </p>
                          ) : (
                            <p className="Textcapitalize mb-0">
                              {" "}
                              {SortedPlan?.benefits[beni]}
                            </p>
                          )}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* /////////////////support ////////////////////// */}
            <p className="my-20">Support</p>
            <div
              className={`w-100 d-flex  ${
                lightMode ? " shadow-div bg-white" : "bg-dark-gray table-shadow"
              }
            }`}
            >
              <div className="w-20p-50">
                {emailDataArr?.updateddata?.length > 0 &&
                  Object.keys(emailDataArr?.updateddata[0]?.benefits).map(
                    (items, i) => (
                      <div
                        key={i}
                        className={` d-flex align-items-center justify-content-start ml-30 ${
                          lightMode ? "bbr-light-1px" : "bbr-1px"
                        }`}
                      >
                        <p
                          className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                        >
                          {items}
                        </p>
                      </div>
                    )
                  )}
              </div>
              <div style={{ width: "80%" }} className="only-PC-view">
                <div className="d-flex ">
                  {emailDataArr?.updateddata?.map((items, i) => (
                    <div key={i} className="d-flex flex-col w-25">
                      {Object.keys(emailDataArr?.updateddata[0]?.benefits)?.map(
                        (beni, j) => (
                          <div
                            key={j}
                            className={`   d-flex align-items-center justify-content-start ml-30 ${
                              lightMode ? "bbr-light-1px" : "bbr-1px"
                            }`}
                          >
                            <p
                              className={`h-75 d-flex align-items-center mb-0 fs-16-14 fw-500`}
                            >
                              {items?.benefits[beni] === true ? (
                                <p
                                  style={{
                                    color: lightMode
                                      ? redGreenColorPicker(1, lightMode)
                                      : redGreenColorPicker(1, lightMode),
                                  }}
                                  // className={`my-40  fs-16-14 fw-500 `}
                                  className="mb-0"
                                >
                                  <CheckOutlined />
                                </p>
                              ) : items?.benefits[beni] === false ? (
                                <p
                                  style={{
                                    color: lightMode
                                      ? redGreenColorPicker(-1, lightMode)
                                      : redGreenColorPicker(-1, lightMode),
                                  }}
                                  // className={`my-40  fs-16-14 fw-500 `}
                                  className="mb-0"
                                >
                                  <CloseOutlined />
                                </p>
                              ) : (
                                <p className="Textcapitalize mb-0">
                                  {" "}
                                  {items?.benefits[beni]}
                                </p>
                              )}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ width: "50%" }} className="d-flex only-mobile-view">
                <div className="d-flex flex-col w-25p-100">
                  {emailDataArr?.updateddata?.length > 0 &&
                    Object.keys(emailDataArr?.updateddata[0]?.benefits).map(
                      (beni, i) => (
                        <div
                          key={i}
                          className={`   d-flex align-items-center justify-content-start ml-30 ${
                            lightMode ? "bbr-light-1px" : "bbr-1px"
                          }`}
                        >
                          <p
                            className={`h-75 d-flex align-items-center mb-0  fs-16-14 fw-500`}
                          >
                            {emailDataArr?.mailSort?.benefits[beni] === true ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(1, lightMode)
                                    : redGreenColorPicker(1, lightMode),
                                }}
                                className="mb-0"
                                // className={`my-40  fs-16-14 fw-500 `}
                              >
                                <CheckOutlined />
                              </p>
                            ) : emailDataArr?.mailSort?.benefits[beni] ===
                              false ? (
                              <p
                                style={{
                                  color: lightMode
                                    ? redGreenColorPicker(-1, lightMode)
                                    : redGreenColorPicker(-1, lightMode),
                                }}
                                className="mb-0"
                                // className={`my-40  fs-16-14 fw-500 `}
                              >
                                <CloseOutlined />
                              </p>
                            ) : (
                              <p className="Textcapitalize mb-0">
                                {" "}
                                {emailDataArr?.mailSort?.benefits[beni]}
                              </p>
                            )}
                          </p>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
            {/* /////////////////support ////////////////////// */}
            <p className="fs-s-12 mt-5">
              *Call Support Available: 10 AM - 7 PM (Monday - Friday)
            </p>
          </div>
          <p className=" fs-40-32 fw-700 d-flex align-items-center justify-content-center mb-0 mt-30">
            Frequently asked Questions
          </p>
          <div className="w-100-75 mx-auto">
            <FAQSection data={faqData} lightMode={lightMode} />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanPage;
