import React, { memo, useEffect, useMemo, useState } from "react";
import StockEndpoints from "../../api/endPoints";
import { getStockGraphData } from "../../api/fetchClient";
import AreaChart from "../../components/Charts/AreaChart";
import { getGradient } from "../../components/Charts/getGradients";
import TimeLineButtons from "../../components/TimeLineButtons";
import { convertTograph } from "../../utilityFn/convertDataToChart";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
// import TradingViewWidget,{ Themes,BarStyles }  from 'react-tradingview-widget';
import { getFormatDate } from "../../utilityFn/getFormatdate";
function StockGraph({ type, symbol, lightMode, volumeBar, height = "365px" }) {
  const [graphData, setgraphData] = useState([]);
  const [graphDuration, setgraphDuration] = useState("days=1");

  useEffect(() => {
    const getData = async () => {
      const response = await getStockGraphData(
        StockEndpoints.stockGraphData(type, symbol, graphDuration)
      )
        .then((resp) => {
          setgraphData(resp.data);
        })
        .catch((err) => console.log(err));
    };
    getData();
  }, [graphDuration, symbol]);

  const chartPoints = useMemo(
    () => convertTograph(graphData, graphDuration),
    [graphData, graphDuration]
  );

  const chartVolume = useMemo(() => {
    const graphPointsArray = graphData?.map((item, i) => item.volume).reverse();
    return graphPointsArray;
  }, [graphData, graphDuration]);

  const gradientColors = [
    [
      { xValue: 0, color: "rgba(0, 133, 128, 0.7)" },
      { xValue: 1, color: "rgba(0, 133, 128, 0)" },
    ],
    [
      { xValue: 0, color: "rgba(188, 55, 84, 0.6)" },
      { xValue: 1, color: "rgba(188, 55, 84, 0)" },
    ],
  ];
  let delayBetweenPoints = 1;
  var started = {};
  const memoData = useMemo(() => {
    if (volumeBar) {
      return {
        labels: chartPoints?.labelArray,
        datasets: [
          {
            fill: true,
            backgroundColor: "transparent",
            name: "₹",
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return null;
              } else if (chartPoints?.stockTrend)
                return getGradient(ctx, chartArea, gradientColors[0]);
              else return getGradient(ctx, chartArea, gradientColors[1]);
            },
            label: {
              display: false,
            },
            data: chartPoints?.graphPointsArray,

            borderColor: chartPoints?.stockTrend ? ["#008580"] : ["#BC3754"],
            borderWidth: 2,
            animation: (context) => {
              var delay = 0;
              var index = context.dataIndex;
              var chart = context.chart;
              if (!started[index]) {
                delay = index * delayBetweenPoints;
                started[index] = true;
              }
              var { x, y } =
                index > 0
                  ? chart
                      .getDatasetMeta(0)
                      .data[index - 1].getProps(["x", "y"], true)
                  : { x: 0, y: chart.scales.y.getPixelForValue(100) };

              return {
                x: {
                  easing: "linear",
                  duration: delayBetweenPoints,
                  from: x,
                  delay,
                },
                y: {
                  easing: "linear",
                  duration: delayBetweenPoints * 500,
                  from: y,
                  delay,
                },
                skip: {
                  type: "boolean",
                  duration: delayBetweenPoints,
                  from: true,
                  to: false,
                  delay: delay,
                },
              };
            },
          },
          {
            type: "bar",
            name: "Vol :",
            label: "volume",
            yAxisID: "volume",
            fill: true,
            backgroundColor: lightMode
              ? "rgba(160, 226, 254, 0.5)"
              : "rgba(160, 226, 254, 0.25)",
            label: {
              display: false,
            },
            data: chartVolume,
            borderWidth: 0,
          },
        ],
      };
    } else {
      return {
        labels: chartPoints?.labelArray,
        datasets: [
          {
            fill: true,
            name: "₹",
            backgroundColor: "transparent",
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return null;
              } else if (chartPoints?.stockTrend)
                return getGradient(ctx, chartArea, gradientColors[0]);
              else return getGradient(ctx, chartArea, gradientColors[1]);
            },
            label: {
              display: false,
            },
            data: chartPoints?.graphPointsArray,

            borderColor: chartPoints?.stockTrend ? ["#008580"] : ["#BC3754"],
            borderWidth: 2,
            animation: (context) => {
              var delay = 0;
              var index = context.dataIndex;
              var chart = context.chart;
              if (!started[index]) {
                delay = index * delayBetweenPoints;
                started[index] = true;
              }
              var { x, y } =
                index > 0
                  ? chart
                      .getDatasetMeta(0)
                      .data[index - 1].getProps(["x", "y"], true)
                  : { x: 0, y: chart.scales.y.getPixelForValue(100) };

              return {
                x: {
                  easing: "linear",
                  duration: delayBetweenPoints,
                  from: x,
                  delay,
                },
                y: {
                  easing: "linear",
                  duration: delayBetweenPoints * 500,
                  from: y,
                  delay,
                },
                skip: {
                  type: "boolean",
                  duration: delayBetweenPoints,
                  from: true,
                  to: false,
                  delay: delay,
                },
              };
            },
          },
        ],
      };
    }
  }, [volumeBar, graphData, graphDuration]);
  // show vertical line in tooltip
  const toolTipLine = useMemo(() => {
    return [
      {
        id: "toolTipLine",
        beforeDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();
            const activePoint = chart.tooltip._active[0];
            // top line
            ctx.beginPath();
            ctx.setLineDash([5, 7]);
            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
            ctx.lineTo(activePoint.element.x, activePoint.element.y);
            ctx.lineWidth = 1;
            ctx.strokeStyle = lightMode ? "#c4c4c4" : "#F2F6F8";
            ctx.stroke();
            ctx.restore();
            //bottom line
            ctx.beginPath();
            // ctx.setLineDash([5, 7]);
            ctx.moveTo(activePoint.element.x, activePoint.element.y);
            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
            ctx.lineWidth = 1;
            ctx.strokeStyle = lightMode ? "#c4c4c4" : "#F2F6F8";
            ctx.stroke();
            ctx.restore();
          }
        },
      },
    ];
  }, [lightMode]);

  const memoOptions = useMemo(() => {
    if (volumeBar) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.3,
        scales: {
          y: {
            grid: {
              display: false,
              borderColor: lightMode ? "#A3A8B0" : "#545E78",
            },
            ticks: {
              color: lightMode ? "#3B3F4F" : "#ffff",
              font: {
                size: 14,
              },
            },
          },
          x: {
            grid: {
              display: false,
              borderColor: lightMode ? "#A3A8B0" : "#545E78",
            },
            ticks: {
              color: lightMode ? "#3B3F4F" : "#ffff",
              font: {
                size: 14,
              },
              autoSkip: true,
              maxTicksLimit: 6,
              min: chartPoints?.labelArray[1],
              max: chartPoints?.labelArray[6],
              maxRotation: 0,
            },
          },
          volume: {
            type: "linear",
            position: "right",
            display: false,
            min: 0,
            // max:graphDuration==="days=1"?10000000:100000000,
            max:
              graphDuration === "days=1"
                ? chartVolume?.at(-1) * 5
                : chartVolume?.at(-1) * 15,
            autoSkip: true,
            maxTicksLimit: 6,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleAlign: "start",
            yAlign: "bottom",
            backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
            bodyFont: {
              size: 14,
              weight: 600,
            },

            titleColor: (context) => {
              return lightMode ? "#ffff" : "rgba(0, 0, 0, 0.8)";
            },
            displayColors: false,
            callbacks: {
              title: (context) => {
                return graphDuration === "days=1"
                  ? context[0]?.label
                  : getFormatDate(
                      graphData?.at(-context[0]?.dataIndex - 1)?.date
                    );
              },
              label: (context) => {
                const valueNum = context.dataset.data[context.dataIndex];
                return `${context.dataset.name} ${
                  context.dataset.name === "Vol :"
                    ? `${numberWithCommas(Math.floor(valueNum / 1000))}K`
                    : numberWithCommas(valueNum?.toFixed(2))
                }`;
              },
              labelTextColor: (context) => {
                if (context.dataset.name === "₹") {
                  if (lightMode) {
                    if (chartPoints?.stockTrend) return "#00FF57";
                    else return "#F82E2E";
                  } else {
                    if (chartPoints?.stockTrend) return "#009633";
                    else return "#FF0000";
                  }
                } else {
                  return "#438FFF";
                }
              },
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
      };
    } else {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.3,
        scales: {
          y: {
            grid: {
              display: false,
              borderColor: lightMode ? "#A3A8B0" : "#545E78",
            },
            ticks: {
              color: lightMode ? "#3B3F4F" : "#ffff",
              font: {
                size: 14,
              },
            },
          },
          x: {
            grid: {
              display: false,
              borderColor: lightMode ? "#A3A8B0" : "#545E78",
            },
            ticks: {
              color: lightMode ? "#3B3F4F" : "#ffff",
              font: {
                size: 14,
              },
              autoSkip: true,
              maxTicksLimit: 6,
              min: chartPoints?.labelArray[1],
              max: chartPoints?.labelArray[6],
              maxRotation: 0,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleAlign: "start",
            yAlign: "center",
            backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
            bodyFont: {
              size: 14,
              weight: 600,
            },
            titleColor: (context) => {
              return lightMode ? "#ffff" : "rgba(0, 0, 0, 0.8)";
            },
            displayColors: false,
            callbacks: {
              title: (context) => {
                return graphDuration === "days=1"
                  ? context[0]?.label
                  : getFormatDate(
                      graphData?.at(-context[0]?.dataIndex - 1)?.date
                    );
              },
              label: (context) => {
                return `${type === "index" ? "" : context.dataset.name} ${
                  context.formattedValue
                }`;
              },
              labelTextColor: (context) => {
                if (context.dataset.name === "₹") {
                  if (lightMode) {
                    if (chartPoints?.stockTrend) return "#00FF57";
                    else return "#F82E2E";
                  } else {
                    if (chartPoints?.stockTrend) return "#009633";
                    else return "#FF0000";
                  }
                } else {
                  return "#438FFF";
                }
              },
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
      };
    }
  }, [volumeBar, graphData, graphDuration, lightMode]);

  const timeLineData = [
    { label: "1D", value: "days=1" },
    { label: "1M", value: "months=1" },
    { label: "3M", value: "months=3" },
    { label: "6M", value: "months=6" },
    { label: "1Y", value: "years=1" },
    { label: "Max", value: "years=12" },
  ];

  return (
    <div>
      <div className="d-flex d-flx-row justify-content-end pointer">
        <TimeLineButtons
          data={timeLineData}
          value={graphDuration}
          setValue={setgraphDuration}
          lightMode={lightMode}
        />
      </div>
      <div style={{ height: height }}>
        {/* <TradingViewWidget symbol={`BSE:${symbol}`} style={BarStyles.LINE} theme={lightMode?Themes.LIGHT: Themes.DARK} autosize /> */}
        <AreaChart
          height={300}
          widht={500}
          chartTrend={chartPoints?.stockTrend}
          labelArr={chartPoints?.labelArray}
          dataPoints={memoData}
          options={memoOptions}
          plugins={toolTipLine}
        />
      </div>
    </div>
  );
}

export default memo(StockGraph);
