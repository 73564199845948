import React, { useEffect, useMemo, useRef } from "react";
import BarChart from "../../components/Charts/BarChart";
import { getGradient } from "../../components/Charts/getGradients";
import numberWithCommas from "../../utilityFn/numberWithCommas";
function BalanceSheetGraph({ balanceSheetTable, lightMode }) {
  const currentref = useRef(null);
  const nonCurrentRef = useRef(null);
  const totalRef = useRef(null);
  const graphValues = useMemo(() => {
    let yearsHeader, currentAssets, nonAssets, totalAssets;
    yearsHeader = balanceSheetTable?.YearHeaders?.slice(1).map((year, i) => {
      return year.title.props.children;
    });

    currentAssets = Object.values(
      balanceSheetTable?.tableBodyData.find(
        (item) => item.PARTICULARS === "Current Assets +"
      ) ?? [0, 0, 0, 0, 0, 0, 0]
    ).map((data) => data);

    currentAssets.splice(currentAssets.length - 3, 3);
    // nonAssets = Object.values(
    //   balanceSheetTable?.tableBodyData.find(
    //     (item) =>
    //       item?.PARTICULARS === "Non Current Assets +"
    //       // item?.PARTICULARS === "Other Assets"
    //   ) ?? []
    // ).map((data) => data);
    // nonAssets.length>0?nonAssets.splice(nonAssets.length - 3, 3):nonAssets=[0,0,0,0,0]
    totalAssets = Object.values(
      balanceSheetTable?.tableBodyData.find(
        (item) => item.PARTICULARS === "Assets"
      ) ?? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ).map((data) => data);
    totalAssets.splice(totalAssets.length - 3, 3);
    nonAssets = totalAssets?.map((data, i) => data - currentAssets[i]);
    return { yearsHeader, currentAssets, nonAssets, totalAssets };
  }, [balanceSheetTable]);

  // display initial label
  useEffect(() => {
    if (
      graphValues.currentAssets &&
      graphValues.nonAssets &&
      graphValues.totalAssets
    ) {
      currentref.current.innerText = `Current Assets: ₹ ${numberWithCommas(
        graphValues?.currentAssets.at(-1)
      )} Cr`;
      nonCurrentRef.current.innerText = `Non-Current: ₹ ${numberWithCommas(
        graphValues?.nonAssets.at(-1)
      )} Cr`;
      const totalvalue = graphValues.totalAssets.at(-1);
      // graphValues?.currentAssets.at(-1) + graphValues?.nonAssets.at(-1);
      totalRef.current.innerText = `Total Assets: ₹ ${numberWithCommas(
        totalvalue ?? 0
      )} Cr`;
    }
  }, [graphValues, balanceSheetTable]);
  const gradientColors = [
    [
      { xValue: 0.025, color: "#FDC830" },
      { xValue: 1, color: "#F37335" },
    ],
    [
      { xValue: 0.025, color: "#FFF2AF" },
      { xValue: 1, color: "#E79904" },
    ],
  ];

  const graphData = {
    //Bring in data
    labels: graphValues?.yearsHeader,
    datasets: [
      {
        barPercentage: 0.9,
        barThickness: 40,
        type: "bar",
        label: "Current Assets",
        // borderColor: "rgb(8, 133, 128)",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[0]);
        },
        borderWidth: 0,
        fill: true,
        data: graphValues?.currentAssets,
      },
      {
        barPercentage: 0.9,
        barThickness: 40,
        type: "bar",
        label: "Current Assets",
        // borderColor: "rgb(8, 133, 128)",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[1]);
        },
        borderWidth: 0,
        fill: true,
        data: graphValues?.nonAssets,
      },
    ],
  };

  const options = {
    //Customize chart options
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 2,
          borderDash: [7, 10],
          // borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
      x: {
        ticks: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          font: {
            size: 14,
          },
          padding: 5,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 1,
          borderDash: [5, 15],
          borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "center",
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            return context.datasetIndex === 0 ? "#F37335" : "rgb(231, 153, 4)";
          },
          label: (context, data) => {
            currentref.current.innerText = `Current Assets: ₹ ${numberWithCommas(
              graphData.datasets[0].data[context.dataIndex]
            )} Cr`;
            nonCurrentRef.current.innerText = `Non-Current: ₹ ${numberWithCommas(
              graphValues?.nonAssets[context.dataIndex]
            )} Cr`;
            const totalvalue =
              graphData.datasets[0].data[context.dataIndex] +
              graphValues?.nonAssets[context.dataIndex];
            totalRef.current.innerText = `Total Assets: ₹ ${numberWithCommas(
              totalvalue
            )} Cr`;
            return `₹ ${numberWithCommas(
              context.dataset.data[context.dataIndex]
            )}`;
          },
        },
      },
    },
  };
  return (
    <div className="flex-col-row">
      <div style={{ height: "300px" }} className="w-100-65">
        <BarChart
          type="bar"
          id="balanceSheetGraph"
          data={graphData}
          options={options}
        />
      </div>
      <div className="flex-row-col w-100-35 ff-lato justify-content-center align-items-center ">
        <div>
          <div className="flex">
            <span
              className="legend-dot"
              style={{
                background: "linear-gradient(180deg, #FFF2AF 0%, #E79904 100%)",
              }}
            ></span>
            <p ref={nonCurrentRef ?? 0} className="fs-s-16 ff-lato"></p>
          </div>
          <div className="flex">
            <span
              className="legend-dot"
              style={{
                background:
                  "linear-gradient(180deg, #FDC830 0%, #F37335 49.48%)",
              }}
            ></span>
            <p className="fs-s-16 ff-lato" ref={currentref ?? 0}></p>
          </div>
          <div className="flex">
            <span
              className="mixed-dot"
              style={{
                background: "linear-gradient(45deg, #EFB941 50%,#F37335 50%)",
              }}
            ></span>
            <p ref={totalRef ?? 0} className="fs-s-16 ff-lato"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BalanceSheetGraph;
