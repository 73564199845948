import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import stockIcon from "../../../assets/images/portalFavICon.png";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";

function HeatMapcard({ data, index, watermarkstats }) {
  const { lightMode } = useSelector(getThemeMode);

  const cardColor = (key) => {
    if (key > 0 && key <= 2) return "rgb(119 191 138)";
    //    "linear-gradient(rgb(137 255 195 / 23%) 0%, rgb(98 197 142) 100%)";
    else if (key > 2 && key <= 5) return "#119F57";
    //    "linear-gradient(rgb(137 255 195 / 23%) 0%, rgb(2 193 96) 100%)";
    else if (key > 5) return "rgb(2 122 46)";
    //   "linear-gradient(rgb(137 255 195 / 23%) 0%, rgb(2 118 59) 100%)";
    else if (key < 0 && key >= -2) return "rgb(245, 128, 139)";
    //   "linear-gradient(rgb(247 154 154 / 25%) 0%, rgb(245 102 115) 100%)";
    else if (key < -2 && key >= -5) return "rgb(201, 30, 45)";
    //   "linear-gradient(rgb(247 154 154 / 25%) 0%, rgb(247 31 50 / 92%) 100%)";
    else if (key < -5) return "rgb(161 18 18)";
    //   "linear-gradient(rgb(247 154 154 / 25%) 0%, rgb(169 20 33) 100%)";
    else {
      return "#858383";
    }
  };

  return (
    <Link to={`/stock/${data?.symbol}/consolidated`}>
      <div
        className="relative p-10"
        style={{
          height: "85px",
          background: cardColor(data?.per_change),
          // opacity: "0.8",
          borderRadius: "2px",
          boxShadow: lightMode
            ? "0px 10px 10px #C7C7C7"
            : "0px 10px 10px #192231",
        }}
      >
        <p
          className=" d-flex  justify-content-end w-100 mb-0 fs-18-16 fw-700 text-white"
          // style={{ top: "10px", right: "10px" }}
        >
          {data?.per_change > 0 ? "+" : ""} {data?.per_change}%
        </p>
        <div
          className="d-flex w-100  align-items-center"
          style={{ gap: "10px" }}
        >
          <img
            src={`https://tradebrains-portal.s3.ap-south-1.amazonaws.com/NIFTY50/${
              data?.symbol
            }.png${watermarkstats ? "?x-request=download" : ""}`}
            onError={(error) => {
              error.target.src = stockIcon;
            }}
            className="h-35px w-35 br-50 bg-white"
            alt="logo"
          />
          <div className="w-100">
            <p className="text-white fw-600 mb-0">{data?.symbol}</p>
            <p className="mb-0 fs-s-12 ff-lato text-white">
              ₹ {data?.curr_price}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default HeatMapcard;
