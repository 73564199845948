import { Form, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { putStockRecommendation } from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import StockRecommendationForm from "./StockRecommendationForm";
// import PorrtfolioForm from "./PorrtfolioForm";

function EditStockRecommendation() {
  const params = useParams();
  const { state: urlState } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [brokerId, setBrokerId] = useState(null);
  const [targets, setTarget] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [files, setfiles] = useState(null);
  const [visibleType , setVisibleType] =useState (null);
  const [date , setDate] = useState(null)

  console.log(params , "params")
  const [dropdowndata ,setDropdowndata ] =useState([])
  const [addInputReturns ,setAddInputReturns ] = useState("")

  const onSubmit = async () => {
  
    const postData = new FormData();

      if (urlState?.data.id !== null) {
        postData.append("id", urlState.data.id);
      }
      
      if (selectedLabel !== null) {
        postData.append("stock", selectedLabel);
      }
      
      if (brokerId !== null) {
        postData.append("broker", brokerId);
      }

      if (date !== null) {
        postData.append("date", date);
      }
      
      if (targets !== null) {
        postData.append("target", targets);
      }
      
      if (selectedRole !== null) {
        postData.append("role", selectedRole);
      }
      
      if (files !== null) {
        postData.append("file", files);
      }
      
      if (visibleType !== null) {
        postData.append("visibility", visibleType);
      }
      
    // });
    console.log( targets, "targets")
    
 await putStockRecommendation(postData).then((response) => {
          if (response.status === 201) {
                  message.success("Stock Updated successfully");
                  navigate("/admin/stockRecommendation");
                }
      });
    // }
  };

  // console.log(data , "data");
  const breadCrumbData = [
    { label: "Stock Recommendation", link: `/admin/stockRecommendation` },
  ];
  const formInitValues = useMemo(() => {
    const newValue = { ...urlState?.data };
    delete newValue.id;
    return newValue;
  }, [urlState.data]);

  console.log(urlState?.data , "datata");
  console.log(brokerId , "valuse")

  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb linkData={breadCrumbData} current={urlState?.data?.stock_name} />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">{urlState?.data?.stock_name}</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => onSubmit()}
          className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
        >
          Update
        </button>
      </div>
      <StockRecommendationForm
        dropdowndata={dropdowndata}
        setDropdowndata={setDropdowndata}
        inputReturns={addInputReturns}
        setInputReturns={setAddInputReturns}
        initValues={formInitValues}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        brokerId = {brokerId}
        setBrokerId={setBrokerId}
        targets={targets}
        setTarget={setTarget}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        setfiles={setfiles}
        files={files}
        setDate={setDate}
        date={date}
        visibleType={visibleType}
        setVisibleType={setVisibleType}
        form={form}
      />
    </div>
  );
}

export default EditStockRecommendation;
