import { Form, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAdminSuperStarEdit,
  postStarPortfolioNew,
  putStarPortfolio,
  putStarPortfolioNew,
} from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import PorrtfolioFormNew from "./PorrtfolioFormNew";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Input, Upload } from "antd";
import AntFormInput from "../../../../components/ant/AntFormInput";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";

function EditportfolioNew() {
  const params = useParams();
  const { state: urlState } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { lightMode } = useSelector(getThemeMode);

  const { id } = useParams();

  console.log(id, "hjhkjhkk");

  const [tableData, setTableData] = useState();
  const [newStock, setNewStock] = useState();
  const [investorName, setInvestorName] = useState("");
  const [stockHoldings, setStockHoldings] = useState();
  const [Dob, setDob] = useState("");
  const [education, setEducation] = useState("");
  const [college, setCollege] = useState("");
  const [occupation, setOccupation] = useState("");
  const [description, setDescription] = useState("  ");
  const [file, setFile] = useState();
  const [apiId , setApiId] = useState();

  const getData = async () => {
    await getAdminSuperStarEdit(id)
      .then((resp) => {
        setTableData(resp);
        setInvestorName(resp?.data?.investor_name);
        setNewStock(resp?.data?.networth);
        setStockHoldings(resp?.data?.stock_holdings);
        setDob(resp?.data?.dob);
        setEducation(resp?.data?.education);
        setCollege(resp?.data?.college);
        setOccupation(resp?.data?.occupation);
        setDescription(resp?.data?.description);
        setApiId(resp?.data?.id)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, [id]);

  console.log(
    newStock,
    investorName,
    stockHoldings,
    Dob,
    education,
    college,
    occupation,
    description,
    "jkkdksjkdaskd"
  );

  const onSubmit = async () => {
    const postData = new FormData();

    if(id !== null){
      postData.append("investor" , id)
    }
    
    if (Dob ) {
      postData.append("dob", Dob);
    }

    if (education ) {
      postData.append("education", education);
    }
    
    if (college ) {
      postData.append("college", college);
    }
    
    if (occupation ) {
      postData.append("occupation", occupation);
    }
    
    if (file ) {
      postData.append("image", file);
    }
    
    if (description ) {
      postData.append("description", description);
    }
    const putData = new FormData();
    
    if (Dob ) {
      putData.append("dob", Dob);
    }

    if (education ) {
      putData.append("education", education);
    }
    
    if (college ) {
      putData.append("college", college);
    }
    
    if (occupation ) {
      putData.append("occupation", occupation);
    }
    
    if (file ) {
      putData.append("image", file);
    }
    
    if (description ) {
      putData.append("description", description);
    }
 if(apiId === null){
  await postStarPortfolioNew(postData)
  .then((response) => {
    if (response.status === 201) {
      message.success("Portfolio Updated successfully");
      navigate("/admin/star_portfolio_new");
    }
  })
 }else{
  await putStarPortfolioNew( apiId , putData)
  .then((response) => {
    if (response.status === 202) {
      message.success("Portfolio Updated successfully");
      navigate("/admin/star_portfolio_new");
    }
  })
 }
  };
  const breadCrumbData = [
    { label: "Superstar Portfolio 2.0", link: `/admin/star_portfolio_new` },
  ];
  const formInitValues = useMemo(() => {
    const newValue = { ...urlState?.data };
    delete newValue.premium;
    delete newValue.id;
    delete newValue.keywords;
    return newValue;
  }, [urlState.data]);

  console.log(formInitValues, "formInitValues");

  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb linkData={breadCrumbData} current={urlState?.data?.name} />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">{urlState?.data?.name}</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => onSubmit()}
          className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
        >
          Update
        </button>
      </div>
      {/* <PorrtfolioFormNew
        formSubmit={onSubmit}
        form={form}
        initValues={formInitValues}
        newStock={newStock}
        investorName={investorName}
        stockHoldings={stockHoldings}
        Dob={Dob}
        education={education}
        college={college}
        occupation={occupation}
        description={description}
        file={file}
      /> */}
      <Form
        form={form}
        layout="vertical"
        className="flex flex-col-row"
        name="admin_portfolio"
      >
        <div className="w-100-50">
          <Input
            label="Name"
            value={investorName}
            className={`w-100 h-40px auth-form-input  ${
              lightMode
                ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                : "bg-dark-gray email-input-login-page"
            }`}
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />

          <Form.Item
            label={
              <div>
                <p className="mb-0">Thumbnail Image</p>
                <p className="mb-0">Image Dimension 300*300px less than 50KB</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please select  Image",
              },
            ]}
            style={{ margin: "30px 0px" }}
            
            getValueFromEvent={(e) => e.file}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              // defaultFileList={[...field1]}
              maxCount={1}
              value={file}
              onChange={(e)=>setFile(e.file)}
              className="mt-10 darkmode-antdUploader"
            >
              <button
                className="btn-bg-primary p-10 mt-10 fw-500 br-5 text-white"
                icon={<UploadOutlined />}
              >
                Click to upload
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Bio"
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          ></Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>Net worth :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={newStock}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>No. of Stocks :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={stockHoldings}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>Born :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={Dob}
              onChange={(e)=> setDob(e.target.value)}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>Education :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={education}
              onChange={(e)=> setEducation(e.target.value)}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>College :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={college}
              onChange={(e)=> setCollege(e.target.value)}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ color: "white" }}>Occupation :</p>
            <Input
              className={`w-70 h-40px auth-form-input  ${
                lightMode
                  ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                  : "bg-dark-gray email-input-login-page"
              }`}
              value={occupation}
              onChange={(e)=> setOccupation(e.target.value)}
              type="text"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            />
          </div>
          <Form.Item
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter Description",
              },
            ]}
            //   style={{ margin: "30px 0px" }}
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Input.TextArea
              showCount
              // maxLength={1000}
              type="text"
              rows={8}
              value={description}
              onChange={(e)=> setDescription(e.target.value)}
              style={{
                height: "150px",
                width: "100%",
                paddingInline: "0px",
              }}
              className={`w-100  auth-form-input  ${
                lightMode
                  ? "bg-gray ant-textarea-light "
                  : "bg-dark-gray  ant-textarea-dark"
              }`}
              placeholder="Type Here"
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default EditportfolioNew;
