import React, { useMemo } from "react";
import StockEndpoints from "../../../api/endPoints";
import AreaChart from "../../../components/Charts/AreaChart";
import { getGradient } from "../../../components/Charts/getGradients";
import useGetFetch from "../../../hooks/useGetFetch";
import { getMonthYear } from "../../../utilityFn/getMonthYear";
import numberWithCommas from "../../../utilityFn/numberWithCommas";

function TotalHoldings({ lightMode, name }) {
  const {
    isLoading,
    apiData: graphData,
    serverError,
  } = useGetFetch(StockEndpoints.starPortfolioGraph(name));
  const gradientColors = [
    [
      { xValue: 0, color: "#438FFF" },
      { xValue: 1, color: "#9A60FA" },
    ],
  ];
  const createGraphMemo = useMemo(() => {
    const graphlabels = graphData?.map((item) => {
      return getMonthYear(item.date);
    });

    const graphValues = graphData?.map((item) => {
      return item.share_holdings / 10 ** 7;
    });

    // return data
    const data = {
      labels: graphlabels,
      datasets: [
        {
          fill: true,
          backgroundColor: "transparent",
          borderColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return null;
            }
            return getGradient(ctx, chartArea, gradientColors[0]);
          },
          borderWidth: 3,
          label: {
            display: false,
          },
          data: graphValues,
        },
      ],
    };
    // return options
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tension: 0,
      scales: {
        y: {
          title: {
            display: true,
            // text: "Values in Cr.",
            color: lightMode ? "gray" : "white",
          },
          ticks: {
            color: lightMode ? "#3B3F4F" : "#ffff",
            font: {
              size: 14,
            },
            padding: 5,
          },
          grid: {
            color: lightMode ? "#ABABAB" : "#545E78",
            borderColor: lightMode ? "#000" : "#545E78",
            borderWidth: 1,
            borderDash: [7, 10],
            // borderDashOffset: 5,
            drawTicks: false,
            lineWidth: 1,
            offset: false,
          },
        },
        x: {
          ticks: {
            color: lightMode ? "#3B3F4F" : "#ffff",
            font: {
              size: 14,
            },
            padding: 5,
          },
          grid: {
            color: lightMode ? "#ABABAB" : "#545E78",
            borderColor: lightMode ? "#000" : "#545E78",
            borderWidth: 1,
            borderDash: [5, 15],
            borderDashOffset: 5,
            drawTicks: false,
            lineWidth: 1,
            offset: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleAlign: "center",
          yAlign: "center",
          backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
          bodyFont: {
            size: 16,
            weight: 600,
          },

          displayColors: false,
          callbacks: {
            title: (contextArr) => {
              return "";
            },
            labelTextColor: (context) => {
              return "#438FFF";
            },
            label: (context, data) => {
              return `₹${numberWithCommas(
                Math.trunc(context.dataset.data[context.dataIndex])
              )} Cr`;
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
    };

    return { data, options };
  }, [graphData, lightMode]);

  return (
    <div style={{ minHeight: "350px" }}>
      <AreaChart
        dataPoints={createGraphMemo?.data}
        options={createGraphMemo?.options}
        height={"100%"}
      />
    </div>
  );
}

export default TotalHoldings;
