import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postGoogleAuth, postMailChimp, postReferAPI } from "../../api/fetchClient";
import DotLoader from "../../components/spinners/DotLoader";
import { setAuth } from "../../redux/reducers/authSlice";

function GoogleRedirect() {
  
  
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
const eventRedirect=localStorage.getItem('eventRedirect')

    const urlHash = location.hash.split("&");
    const access_token = urlHash[1]?.split("=")[1];
    const redir_link = urlHash[0]?.split(":")[1];
    
    // const url = 'ya29.a0AVA9y1vbdQA-OBcpV3u79KQvrCbmYAGKa6g50reAFNGfPZJP8ZcDbpcL0S5FdnEuesZDl7a2Xqhh3Z9zvT0DywVSB7slSa0YI5kSgAyBQMYE_otorlcOIsT4y1bb07na8quv-f1Cv_XJghpWjqlKvnH4n02EaCgYKATASARISFQE65dr8sOBCcOn_zmxkqEegEf041w0163'
    postGoogleAuth({ access_token: access_token }).then(async(res) => {
      if(sessionStorage.getItem("Mailchimp")){
        postMailChimp(res.data.user.email).then((res)=>{
          
        })
      }
      if(sessionStorage.getItem("referID")){
        await postReferAPI(sessionStorage.getItem("referID"), res.data.user.email)
        .then((resp) => {
          sessionStorage.removeItem("referID");
        })
        .catch((err) => console.log(err));
      }
      dispatch(setAuth(res.data));
      navigate(eventRedirect?eventRedirect:redir_link);
    });
  }, []);

  return (
    <div>
      <DotLoader />
    </div>
  );
}

export default GoogleRedirect;
