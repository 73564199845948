import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import BreadCrumb from "../../components/BreadCrumb";
import CustomTable from "../../components/CustomTable";
import PremiumBlurLayout from "../../components/PremiumBlurLayout";
import DotLoader from "../../components/spinners/DotLoader";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { getMonth } from "../../utilityFn/getMonth";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import BlurImgLight from "../../assets/images/premiumBlur/holdingPatternLight.png";
import BlurImgDark from "../../assets/images/premiumBlur/holdingPatternDark.png";
import useGetFetch from "../../hooks/useGetFetch";

function ShareHoldingIndex() {
  const UserAuth = useSelector(authStore);
  const { lightMode } = useSelector(getThemeMode);
  const { symbol } = useParams();
  const {
    isLoading,
    apiData: holdingData,
    serverError,
  } = useGetFetch(StockEndpoints.allShareHolding(symbol));
  // create table hearder array
  console.log(holdingData);
  const createHeaderMemo = useMemo(() => {
    if (holdingData === null) return;
    const quarterArr = [];
    Object.values(holdingData)?.forEach((value, i) => {
      if (value.length === 0) return;
      const strDate = String(value[0].date);
      const year = strDate.substring(0, strDate.length - 2);
      const month = strDate?.slice(4);
      const combineDate = `${getMonth(month)} ${year}`;
      quarterArr.push({
        title: <p className="fw-600 mb-0 fs-s-16">{combineDate}</p>,
        children: [
          {
            title: <p className="fw-600 mb-0 fs-s-16">No. of . Shares</p>,
            dataIndex: i === 0 ? "no_of_shares_first" : "no_of_shares_second",
            key: i === 0 ? "no_of_shares_first" : "no_of_shares_second",
            width: "50px",
            sorter: (a, b) =>{
            return  i===0?a?.no_of_shares_first - b?.no_of_shares_first:
              a?.no_of_shares_second - b?.no_of_shares_second
            }
              ,
            render(text, index) {
              return {
                props: {
                  style: lightMode
                    ? {
                        background: "rgba(242, 246, 248, 1)",
                        borderColor: "#C9D7DE",
                        borderRight: "1px solid #C9D7DE",
                        borderBottom: "none",
                      }
                    : {
                        background: "#3B3F4F",
                        borderLeft: "1px solid #545E78",
                        borderRight: "1px solid #545E78",
                        borderBottom: "none",
                      },
                },
                children: (
                  <span
                    className="ff-lato"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: lightMode ? "black" : "white",
                    }}
                  >
                    {numberWithCommas(text) ?? "NA"}
                  </span>
                ),
              };
            },
          },
          {
            title: <p className="fw-600 mb-0 fs-s-16">Percent holding</p>,
            dataIndex: i === 0 ? "percentage_first" : "percentage_second",
            key: i === 0 ? "percentage_first" : "percentage_second",
            width: "50px",
            sorter: (a, b) =>{
              return  i===0?a?.percentage_first - b?.percentage_first:
                a?.percentage_second - b?.percentage_second
              },
            render(text, index) {
              return {
                props: {
                  style: lightMode
                    ? {
                        background: "rgba(242, 246, 248, 1)",
                        borderColor: "#C9D7DE",
                        borderRight: "1px solid #C9D7DE",
                        borderBottom: "none",
                      }
                    : {
                        background: "#3B3F4F",
                        borderLeft: "1px solid #545E78",
                        borderRight: "1px solid #545E78",
                        borderBottom: "none",
                      },
                },
                children: (
                  <span
                    className="ff-lato"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: lightMode ? "black" : "white",
                    }}
                  >
                    {text ? `${text} %` : "NA"}
                  </span>
                ),
              };
            },
          },
          {
            title: <p className="fw-600 mb-0 fs-s-16">Pledged %</p>,
            dataIndex:
              i === 0 ? "pledge_percentage_first" : "pledge_percentage_second",
            key:
              i === 0 ? "pledge_percentage_first" : "pledge_percentage_second",
            width: "50px",
            sorter: (a, b) =>{
              return  i===0?a?.pledge_percentage_first - b?.pledge_percentage_first:
                a?.pledge_percentage_second - b?.pledge_percentage_second
              },
            render(text, index) {
              return {
                props: {
                  style: lightMode
                    ? {
                        background: "rgba(242, 246, 248, 1)",
                        borderColor: "#C9D7DE",
                        borderRight: "1px solid #C9D7DE",
                        borderBottom: "none",
                      }
                    : {
                        background: "#3B3F4F",
                        borderLeft: "1px solid #545E78",
                        borderRight: "1px solid #545E78",
                        borderBottom: "none",
                      },
                },
                children: (
                  <span
                    className="ff-lato"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: lightMode ? "black" : "white",
                    }}
                  >
                    {text ?? 0} %
                  </span>
                ),
              };
            },
          },
        ],
      });
    });

    return [
      {
        title: <p className="fw-600 mb-0 fs-s-16">S.No</p>,
        dataIndex: "no",
        fixed: "left",
        width: "20px",
        render: (text, item, index) => {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    fontSize: "14px",
                    borderRight: "1px solid #C9D7DE",
                    borderLeft: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                    fontSize: "14px",
                  },
            },
            children: (
              <div
                style={{ color: lightMode ? "black" : "white" }}
                className="d-flex  justify-content-between align-items-center"
              >
                <p className="mb-0">{index + 1}</p>
              </div>
            ),
          };
        },
      },
      {
        title: <p className="fw-600 mb-0 fs-s-16">Promotors</p>,
        dataIndex: "name",
        fixed: "left",
        key: "name",
        width: "70px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-poppins"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            ),
          };
        },
      },
      ...quarterArr,
    ];

    /////////////////
  }, [symbol, holdingData, lightMode]);
  // create table data
  const tableData = useMemo(() => {
    if (holdingData === null) return;
    const { firstq, secondq } = holdingData;
    if (firstq.length === 0) {
      return secondq;
    } else if (secondq.length === 0) {
      return firstq;
    } else {
      const meregeArr = [...firstq, ...secondq];
      const allNames = meregeArr?.map((item, i) => item.name);
      const filterNames = Array.from(new Set(allNames));

      const finalArr = filterNames?.map((name) => {
        const commonObj = meregeArr?.filter((item) => item.name === name);
        if (commonObj.length > 1) {
          return { ...commonObj[0], ...commonObj[1] };
        } else {
          return commonObj[0];
        }
      });
      return finalArr;
    }
  }, [symbol, holdingData, lightMode]);

  const breadCrumbData = [
    { label: "Stock Details", link: `/stock/${symbol}/consolidated` },
  ];

  if (!holdingData) {
    return <DotLoader />;
  }

  return (
    <div className="ff-poppins max-w mx-auto  h-100 px-15">
      <div className="my-body">
        <h2
          style={{ color: "inherit" }}
          className=" fs-36-20 fw-700 mb-10 line-h-36"
        >
          Shareholding
        </h2>
        <div className="mb-20">
          <BreadCrumb linkData={breadCrumbData} current="Shareholding" />
        </div>
        {UserAuth?.userData?.user?.is_premium ? (
          <div
            className={`w-100 table-shadow my-20 ${
              lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
            }`}
          >
            <CustomTable
              columns={createHeaderMemo}
              scrollable={true}
              data={tableData}
              lightMode={lightMode}
            />
          </div>
        ) : (
          <PremiumBlurLayout
            bgImg={lightMode ? BlurImgLight : BlurImgDark}
            top="50%"
            headerText="Unlock Share holding Data"
          />
        )}
      </div>
    </div>
  );
}

export default ShareHoldingIndex;
