import React from "react";
import { Modal } from "antd";
import bgImgDark from "../assets/images/bg/premiumBg.png";
import spaceMainImg from "../assets/images/premiumSpaceman.png";
import svgSheet from "../assets/svg/svgSheet";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getThemeMode } from "../redux/reducers/ThemeSlice";
import { plans } from "../utilityFn/planData";

function PremiumRedirectModal({ visible, setVisible, modalPlan,loginRediect =false,redirectState }) {
  const { lightMode } = useSelector(getThemeMode);
  const navigate = useNavigate()
  const planNames = ["Free", "Bulls", "Sharks", "Whales"];
  const cardData = {
    screener: {
      planHead: "Screener Features",
      planData: [
        plans.basic.screener,
        plans["1month"].screener,
        plans["12months"].screener,
        "Unlimited",
      ],
      footerText: "Filters",
    },
    portFolio: {
      planHead: "Portfolio Features",
      planData: [
        plans.basic.portfolioAnalsis,
        plans["1month"].portfolioAnalsis,
        plans["12months"].portfolioAnalsis,
        plans["year"].portfolioAnalsis,
      ],
      footerText: "Portfolios",
    },
    watchlist: {
      planHead: "Watchlist Features",
      planData: [
        plans.basic.watchlist,
        plans["1month"].watchlist,
        plans["12months"].watchlist,
        "Unlimited",

      ],
      footerText: "Watchlist",
    },
    stockCompare: {
      planHead: "Stock Compare Features",
      planData: [
        plans.basic.stockCompare,
        plans["1month"].stockCompare,
        plans["12months"].stockCompare,
        plans["year"].stockCompare,
      ],
      footerText: "Stocks",
    },
    excel: {
      planHead: "Export to Excel",
      planData: [
        plans.basic.excel,
        plans["1month"].excel,
        plans["12months"].excel,
        "Unlimited",
      ],
      footerText: "Per Month",
    },
  };
  return (
    <Modal
      centered
      // closable={true}
      width="800px"
      bodyStyle={{ padding: "0px", minHeight: "400px", borderRadius: "10px" }}
      visible={visible}
      footer={null}
      onCancel={() => {loginRediect ?  navigate('/signup',{replace:true}): setVisible(false)}}
      className="relative "
      wrapClassName={"filter-modal premium-mod"}
    >
      <img
        className="absolute w-100 h-100 fit-cover pb-10 "
        src={bgImgDark}
        alt="bg"
      />
      <div
        style={{ marginInline: "10%", paddingTop: "3%" }}
        className=" h-100 d-flex flex-col justify-content-center"
      >
        <div className="wh-80-120">
          { loginRediect ?svgSheet.LockIog : svgSheet.premiumIcon}
        </div>
        <h1 className="fs-50-35 fw-700 my-10 lh-50">{loginRediect? "Login to Portal": "Get Premium"}</h1>
        <p className="fs-16-14 fw-400">
 
          {loginRediect?"To get started with detailed analysis & insights.":"To get detailed analysis & insights, check our plans & Features"}
        </p>
       { modalPlan&&<div>
          <p className="fw-600 fs-24-16 mb-10">
            {cardData[modalPlan]?.planHead}
          </p>
          <div style={{ gap: "10px" }} className="d-flex flex-wrap">
            {cardData[modalPlan]?.planData?.map((num, i) => (
              <div key={i}
                className={`p-15 h-100-120 w-48-23 d-flex flex-col justify-content-between card-drop-dark-shadow`}
              >
                <p className="fw-700 text-blue-grad fs-25-20 lh-26 mb-0">
                  {planNames[i]}
                </p>
                <div>
                  <p className="fs-s-20 fw-700 lh-24 mb-0">{num}</p>
                  <p className="fs-14-14 mb-0">
                    {cardData[modalPlan]?.footerText}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>}
       {loginRediect ? <Link to={"/login"} replace state={`/${(modalPlan!=='/'&&modalPlan!=='summary')?modalPlan:modalPlan==="summary"?redirectState:''}`} style={{ zIndex: 100, margin: "auto" }}>
          <button
            style={{ width: "260px", margin: "10% 0", zIndex: 100 }}
            className="h-45 btn-bg-primary text-white br-3 fw-600 fs-16-14"
          >
            Click here to Login
          </button>
        </Link>: <Link to={"/getpremium"} style={{ zIndex: 100, margin: "auto" }}>
          <button
          onClick={()=>setVisible(false)}
            style={{ width: "260px", margin: "7% 0", zIndex: 100 }}
            className="h-45 btn-bg-primary text-white br-3 fw-600 fs-16-14"
          >
            View Pricing
          </button>
        </Link>}
      </div>
      <img
        // style={{ top: -130, right: 20,width:'120px',height:'120px' }}
        className=" modal-spaceman"
        src={spaceMainImg}
        alt="img"
      />
    </Modal>
  );
}

export default PremiumRedirectModal;
