import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import { Checkbox, Form, Input, Select } from "antd";
import AntFormInput from "../../../../../components/ant/AntFormInput";
import { Option } from "antd/lib/mentions";
import { postAffiliateAPI } from "../../../../../api/fetchClient";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../../../../redux/reducers/authSlice";

function AffiliateRegisterIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const userAuth = useSelector(authStore);
  const navigate = useNavigate();
  const [userDataForm] = Form.useForm();
  const [promotionAr, setpromotionAr] = useState([]);
  const onSubmit = async (values) => {
    const postData = { ...values, phone: `+91${values.phone}` };
    await postAffiliateAPI(postData)
      .then((resp) => {
        navigate(`/affiliate-status`);
      })
      .catch((err) => {
        const errArr = Object.entries(err.response.data)?.map((error) => {
          return {
            name: error[0],
            errors: error[1],
          };
        });
        userDataForm.setFields(errArr);
      });
  };

  const promotionOptions = [
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Youtube",
      value: "youtube",
    },
    {
      label: "Faceook",
      value: "facebook",
    },
    {
      label: "Blog",
      value: "blog",
    },
    {
      label: "Twitter",
      value: "twitter",
    },
    {
      label: "Email maketing",
      value: "email_promotion",
    },
    {
      label: "Instagram",
      value: "instgram",
    },
    {
      label: "Others",
      value: "others",
    },
  ];
  const subCount = [
    {
      label: "Below 10k",
      value: "Below 10k",
    },
    {
      label: "10k - 100k",
      value: "10k - 100k",
    },
    {
      label: "Above 100k",
      value: "Above 100k",
    },
  ];

  return (
    <section className="max-w mx-auto px-15  w-100">
      <div className="my-body">
        <h1 className="fs-40-28 fw-700 mb-30">Affiliate Registration Form</h1>
        <div>
          <Form
            initialValues={{
              email: userAuth?.userData?.user?.email,
              name: `${userAuth?.userData?.user?.first_name} ${userAuth?.userData?.user?.last_name}`,
            }}
            autoComplete="off"
            //   className="w-60-80"
            form={userDataForm}
            name="userDataForm"
            onFinish={onSubmit}
            scrollToFirstError
            className="d-flex flex-col-row"
            style={{ gap: "5rem" }}
            layout="vertical"
          >
            <div className="w-100-50">
              <AntFormInput
                disabled={true}
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Name",
                  },
                ]}
                placeholder="Type your name"
              />

              <AntFormInput
                disabled={true}
                label="Email ID"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please Enter your Email ID",
                  },
                ]}
                placeholder="Type Your Email Id"
              />

              <AntFormInput
                label="Mobile"
                name="phone"
                addonBefore="+91"
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Mobile Number",
                  },
                  {
                    pattern: /^[\d]{10,10}$/,
                    message: "Enter valid Mobile Number",
                  },
                ]}
                placeholder="Type Your Mobile Number"
              />

              <Form.Item
                label="Tell us about yourself"
                style={{ margin: "0px 0px 10px 0px" }}
                name="about"
                className={` ${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter about yourself",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={1000}
                  type="text"
                  rows={8}
                  style={{
                    height: "150px",
                    width: "100%",
                    paddingInline: "0px",
                  }}
                  className={`w-100  auth-form-input  ${
                    lightMode
                      ? "bg-gray ant-textarea-light "
                      : "bg-dark-gray  ant-textarea-dark"
                  }`}
                  placeholder="Type Here"
                />
              </Form.Item>
            </div>
            {/* rightn section  */}
            <div className="w-100-50">
              <Form.Item
                label="Promotional Method"
                valuePropName="checked"
                style={{ margin: "0px 0px 10px 0px" }}
                name="promotion"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Select Promotional Method",
                  },
                ]}
              >
                <Checkbox.Group
                  style={{ margin: "6px 0px" }}
                  className={`check-grp flex flex-wrap ${
                    lightMode ? "checkbox-light" : "checkbox-dark"
                  }`}
                  options={promotionOptions}
                  defaultValue={[]}
                  onChange={(checkedValues) => {
                    userDataForm.setFieldsValue({ promotion: checkedValues });
                    setpromotionAr(checkedValues);
                  }}
                />
              </Form.Item>
              {promotionAr?.map((arr, i) => (
                <AntFormInput
                  label={`${arr} URL`}
                  name={arr}
                  rules={[
                    {
                      required: true,
                      message: `Please Enter your ${arr} URL`,
                    },
                  ]}
                  placeholder="Enter URL"
                />
              ))}
              <Form.Item
                label="Total Subscribers/followers Range"
                tooltip="Aggregate all of your subscribers & followers from all your social media platforms and select the appropriate range."
                style={{ margin: "0px 0px 10px 0px" }}
                name="total_subscribers"
                className={`${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please select Total Subscribers/followers Range",
                  },
                ]}
              >
                <Select
                  className={
                    lightMode
                      ? "br-5 contact-select-light bg-gray border1px-light-mode"
                      : "br-5  contact-select-dark bg-dark-gray border1px-dark-mode"
                  }
                  // defaultValue={defaultSelect}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  // onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "40px",
                    margin: "auto",
                  }}
                  placeholder="Subscribers/followers Range"
                >
                  {subCount.map((items, i) => (
                    <Option key={i} value={items.value}>
                      {items.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="flex flex-col-row mt-40 align-lg-center justify-lg-between">
                <Form.Item
                  style={{ margin: "0px 0px 0px 0px" }}
                  name="isAccepted"
                  valuePropName="checked"
                >
                  <div
                    className={`${
                      lightMode ? "checkbox-light" : "checkbox-dark"
                    }`}
                  >
                    <Checkbox style={{ color: lightMode ? "black" : "white" }}>
                      Subscribe email updates from Portal
                    </Checkbox>
                  </div>
                </Form.Item>
                <button
                  type="submit"
                  style={{ margin: "6px 0px" }}
                  className="w-200px h-45 btn-bg-primary fs-16-14 fw-600 py-10 br-5  h-42 text-white pointer"
                >
                  Submit
                </button>
              </div>
            </div>
            {/* rightn section  */}
          </Form>
        </div>
      </div>
    </section>
  );
}

export default AffiliateRegisterIndex;
