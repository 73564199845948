import { Form, Input, message, Select } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { postFeedbackApi } from "../../api/fetchClient";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";

function ContactIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const [contactForm] = Form.useForm();
  const [loadingState, setloadingState] = useState(false);
  const emailList = [
    {
      head: "Affiliate",
      email: "affiliate@tradebrains.in",
    },
    {
      head: "Sales",
      email: "sales@tradebrains.in",
    },
    {
      head: "Partnership",
      email: "portal.partnership@tradebrains.in",
    },
    {
      head: "Account",
      email: "account@tradebrains.in",
    },
    {
      head: "Support",
      email: "support@tradebrains.in",
    },
    {
      head: "Payment",
      email: "payment@tradebrains.in",
    },
    {
      head: "Feedback",
      email: "feedback@tradebrains.in",
    },
  ];

  const onFeedbackSubmit = async (values) => {
    console.log(values);
    if(loadingState)return
    setloadingState(true);
    await postFeedbackApi(values)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 201) {
          message.success("Thank you for contacting us.");
          setloadingState(false);
        } else {
          message.error("Something went wrong.Try again later!");
          setloadingState(false);
        }
      })
      .catch((err) => {
        setloadingState(false);
      });
    contactForm.resetFields();
  };  
  return (
    <>
    <ReactHelmet
        title='Contact Us Trade Brains Portal - Financial Data & Stock Quotes'
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <h1 className="mb-20 fs-36-20 fw-700">Contact Us</h1>
        <section className="flex flex-col-row">
          <div className="w-100-50">
            <p className="fs-14-12 ">
              Trade brains portal helps investors make efficient stock research
              and analysis by providing quality fundamental data with insightful
              visuals.
            </p>  
            <div className="flex flex-wrap mt-30">
              {emailList?.map((item, i) => (
                <div key={i} style={{ width: "48.5%" }}>
                  <p className="fw-700 fs-18-14 mb-10">{item.head}</p>
                  <a href={`mailto:${item.email}`} className="fw-500">
                    {item.email}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="w-100-50">
            <Form
              className={`d-flex align-items-center flex-col py-20 ${
                lightMode
                  ? "bg-gray card-drop-light-shadow"
                  : "card-drop-dark-shadow"
              }`}
              form={contactForm}
              name="contact"
              autoComplete="off"
              onFinish={onFeedbackSubmit}
              scrollToFirstError
            >
              <Form.Item
                style={{ margin: "0px 0px 6px 0px" }}
                name="name"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    type: "name",
                    message: "Please Enter your Name",
                  },
                  {
                    required: true,
                    message: "Please Enter your Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Name*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="email"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please Enter your E-mail!",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Email*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="phone_no"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    pattern: /^[\d]{0,12}$/,
                    message: "Value should be less than 12 character",
                  },
                ]}
              >
                <Input
                  type="number"
                  maxLength={13}
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Phone Number"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "12px 0px" }}
                name="type"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please select type",
                  },
                ]}
              >
                <Select
                  className={
                    lightMode
                      ? "br-5 contact-select-light bg-gray border1px-light-mode"
                      : "br-5  contact-select-dark bg-dark-gray border1px-dark-mode"
                  }
                  // defaultValue={defaultSelect}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  // onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "40px",
                    margin:'auto'
                  }}
                  placeholder="Type*"
                >
                  {emailList.map((items, i) => (
                    <Option key={i} value={items.head}>
                      {items.head}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="subject"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Subject",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Subject*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="message"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Message",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={250}
                  type="text"
                  rows={8}
                  style={{
                    height: "150px",
                    width: "100%",
                    paddingInline: "0px",
                  }}
                  className={`w-100  auth-form-input  ${
                    lightMode
                      ? "bg-gray ant-textarea-light "
                      : "bg-dark-gray  ant-textarea-dark"
                  }`}
                  placeholder="Message*"
                />
              </Form.Item>
              <div className="w-90 ">
                {loadingState ? (
                  <button
                    style={{ width: "120px" }}
                    className=" btn-bg-primary h-50px br-5 text-white fw-500"
                  >
                    Sending...
                  </button>
                ) : (
                  <button
                    style={{ width: "120px" }}
                    type="submit"
                    className=" btn-bg-primary h-50px br-5 text-white fw-500"
                  >
                    Send
                  </button>
                )}
              </div>
            </Form>
          </div>
        </section>
      </div>
    </div>
    </>
  );
}

export default ContactIndex;
