import { Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import svgSheet from '../../../../../assets/svg/svgSheet'

function FormSubmitPopup({ visible, setVisible, lightMode }) {
  const navigate=useNavigate()
  return (
        <div>
          <Modal
            centered
            // closable={true}
            width="400px"
            bodyStyle={{ padding: "0px", minHeight: "330px"}}
            visible={visible === "SUBMIT"}
            footer={null}
            onCancel={() =>{
              setVisible("NONE")
              navigate('/affiliate/Dashboard')
            } }
            className="relative "
            wrapClassName={"filter-modal premium-mod"}
          >
            <div style={{ paddingInline: "24px" }} >
                <div style={{padding:'20px 0px'}}>{svgSheet.paySuccess}</div>
            <p
                className={`${
                  lightMode ? "text-dark" : "text-white"
                }  fs-20-16 fw-600`}
              >
                Thank You
              </p>
              <p className="fs-18-14">
              Your Bank details has been Submitted , will be reviewed by our admin.
              </p>
              <p className="fs-16-14 fw-600">
              You will receive an confirmation email once your Bank details has been verified.
              </p>
            </div>
          </Modal>
        </div>
  )
}

export default FormSubmitPopup