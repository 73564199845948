import React from 'react'
import { Link } from 'react-router-dom'

function Featurecard({lightMode,cardContent}) {
  return (
    <Link to={'/login'} state={cardContent.redirectState}>
    <div  style={{ minHeight: "150px",minWidth:'165px',maxWidth:'300px'}}
    className={`p-10 d-flex flex-col align-items-center justify-content-center  ${
      lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
    }`}>
        <h3 className='fw-600 text-center mb-10'>{cardContent?.title}</h3>
        <p  className='mb-0 fs-14-12 text-center'>{cardContent?.desc}</p>
    </div>
    </Link>
  )
}

export default Featurecard