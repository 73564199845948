import React, { useEffect, useState } from "react";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";
import CustomTable from "../../../../components/CustomTable";
import CustomPagination from "../../../../components/ant/CustomPagination";
import { EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { getAllStockDetails } from "../../../../api/fetchClient";
function StockDetailMain() {
  const { lightMode } = useSelector(getThemeMode);
  const [TableData, setTableData] = useState([]);
  const [input, setinput] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getAllStockDetails(input).then((res) => {
      setTableData(res?.data);
    });
  }, [input]);
  const handleEditClick = (id) => {
    navigate(`/admin/stockdetails/edit/${id}`);
  };

  const columnData = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">S.no </p>,
      key: "index",
      fixed: "left",
      width: "40px",
      render(text, record, index) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato pointer link-hover-underline"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {index + 1}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Stock Name</p>,
      dataIndex: "company_name",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0"> Action</p>,
      width: "30px",
      dataIndex: "",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              <Link
                to={`/admin/stockdetails/edit/${record.FINCODE}`}
                onClick={() => handleEditClick(record.FINCODE)}
              >
                <EditOutlined />
              </Link>
            </span>
          ),
        };
      },
    },
  ];
  return (
    <div className="my-body px-30-0">
      <div className="d-flex justify-content-between mb-30">
        <input
          style={{ width: "20%", color: "white" }}
          placeholder="Search by Stock"
          type="text"
          value={input}
          onChange={(e) => setinput(e.target.value)}
          className={`w-100 h-45 mr-5 br-3 mb-10 p-3 portFolio-Modal-inputFeild ${
            lightMode
              ? "border1px-light-mode"
              : "border1px-dark-mode bg-dark-gray"
          }`}
        ></input>
        <Link to="/admin/stockdetails/create">
          <button
            className={`p-10 pointer btn-bg-primary text-white w-180px text-white br-3 fw-500 fs-16-14 `}
          >
            Add Stock{" "}
          </button>
        </Link>
      </div>

      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable columns={columnData} data={TableData} />
        {/* <CustomPagination lightMode={lightMode}  /> */}
      </div>
    </div>
  );
}

export default StockDetailMain;
