import { MoreOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PortFolioAnalysisList({
  items,
  i,
  Portfolios,
  lightMode,
  setIsModalVisible,
  setIsModalVisible3,
  setupdateAndDelete,
  setportfolioid,
  isPopoverisopen,
  portfolioid,
  setChangePortfolio,
  setcurrectPortplioName,
  location,
  handleGeneratePdf,
}) {
  const [PortFolio, setPortFolio] = useState();
  const [visible, setVisible] = useState(isPopoverisopen);
  const [Modelpop, setModelpop] = useState(false);
  const idandlistname = sessionStorage.getItem(23);
  const arrayofporfolioid = idandlistname !== null && idandlistname.split(",");
  const navigate = useNavigate();
  useEffect(() => {
    setportfolioid(Portfolios[0]?.id);
  }, []);

  console.log("location", location?.pathname?.split("/")[2]);

  useEffect(() => {
    if (portfolioid) {
      setportfolioid(portfolioid);
    }

    // setPortFolio( arrayofporfolioid ? arrayofporfolioid[0] : portfolioid  ? portfolioid :Portfolios[0]?.id );
    // setportfolioid( arrayofporfolioid ? Number(arrayofporfolioid[0]) : portfolioid  ? portfolioid :Portfolios[0]?.id );
    // setcurrectPortplioName(arrayofporfolioid  ? arrayofporfolioid[1] :  Portfolios[0]?.name)
  }, [arrayofporfolioid[0]]);

  useEffect(() => {
    if (arrayofporfolioid.length > 0) {
      setportfolioid(Number(arrayofporfolioid[0]));
    } else {
      setportfolioid(Portfolios[0]?.id);
    }
  }, [idandlistname]);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <div
      key={i}
      onClick={() => {
        setportfolioid(items?.id);
        setPortFolio(items?.id);
        setcurrectPortplioName(items?.name);
        navigate(`/portfolio/${items?.id}`);
        sessionStorage.removeItem(23);
      }}
      className={` ${
        lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
      }    pointer d-flex  justify-content-between p-10 align-items-center justify-content-center ${
        Number(location?.pathname?.split("/")[2]) === items?.id
          ? lightMode
            ? "desktop-active"
            : "desktop-active-dark"
          : lightMode
          ? "bg-gray"
          : "bg-dark-gray"
      }`}
    >
      <p className="mb-0 fs-s-16"> {items?.name}</p>
      <Popover
        //  key={key}
        color={lightMode ? "white" : "#3B3F4F"}
        className="nameis"
        overlayClassName="Nopadding-pover Navtowatchlist"
        // getPopupContainer={trigger => trigger.parentElement}
        placement="right"
        content={
          <div className="">
            <div className={`${lightMode ? "text-dark-gray" : "text-white"}`}>
              <div
                className={`d-flex  p-3-16 ${
                  lightMode ? "popOver-child-light" : "popOver-child-dark"
                }`}
              >
                <p
                  onClick={() => {
                    setIsModalVisible(true);
                    setVisible(false);
                    setupdateAndDelete(items?.id);
                    setChangePortfolio(items?.name);
                  }}
                  className=" fs-s-12 fw-600 mb-0 pointer"
                >
                  Rename
                </p>
              </div>
              {Portfolios?.length !== 1 && (
                <div
                  className={`d-flex align-items-center  p-3-16 ${
                    lightMode ? "popOver-child-light" : "popOver-child-dark"
                  }`}
                >
                  <p
                    onClick={() => {
                      setIsModalVisible3(true);
                      setupdateAndDelete(items?.id);
                      setVisible(false);
                    }}
                    className=" fs-s-12  fw-600 mb-0 pointer"
                  >
                    Delete
                  </p>
                </div>
              )}

              {/* <div
                className={`d-flex  p-3-16 ${
                  lightMode ? "popOver-child-light" : "popOver-child-dark"
                }`}
              >
                <p
                  onClick={() => {
                    handleGeneratePdf();
                    setVisible(false);
                  }}
                  className=" fs-s-12 fw-600 mb-0 pointer"
                >
                  Export
                </p>
              </div> */}
            </div>
          </div>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <p onClick={() => setVisible(true)} className="mb-0">
          <MoreOutlined />
        </p>
      </Popover>
    </div>
  );
}

export default PortFolioAnalysisList;
