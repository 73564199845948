import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAffiliateDashboardCard,
  getAffiliateDashdata,
  getAffiliatesignupdata,
} from "../../../../../api/fetchClient";
import svgSheet from "../../../../../assets/svg/svgSheet";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import AffiliateDeshboardBarChar from "./AffiliateComponets/AffiliateDeshboardBarChar";
import AffliateDashboardTable from "./AffiliateComponets/AffliateDashboardTable";
import coin from "../../../../../assets/images/Affiliate/coins.png";
import productssold from "../../../../../assets/images/Affiliate/productssold.png";
import rupeegreen from "../../../../../assets/images/Affiliate/rupeegreen.png";
import users from "../../../../../assets/images/Affiliate/users.png";
import moment from "moment";
import AffiliateMovesDash from "./AffiliateComponets/AffiliateMovesDash";
import numberWithCommas from "../../../../../utilityFn/numberWithCommas";
import { useNavigate } from "react-router-dom";

function Affiliatedashboard() {
  const [MonthlyData, setMonthlyData] = useState();
  const [Todays, setTodays] = useState();
  const [Duration, setDuration] = useState(10);

  const onChange = (date, dateString) => {
    console.log("dateString", dateString);
    getAffiliateDashdata({ duration: dateString }).then((Res) => {
      setMonthlyData(Res?.data);
    });
  };
  useEffect(() => {
    getAffiliateDashdata({ duration: "00" }).then((Res) => {
      setTodays(Res?.data);
    });
    getAffiliateDashdata({ duration: Duration }).then((Res) => {
      setMonthlyData(Res?.data);
    });
  }, []);
  const options = {
    Revenue: {
      icon: coin,
      name: "Revenue",
      key: "revenue",
      key1: "revenue",
      unit: "₹",
    },
    Earnings: {
      icon: rupeegreen,
      name: "Earnings",
      key: "earnings",
      key1: "earnings",
      unit: "₹",
    },
    Productssold: {
      icon: productssold,
      name: "Products sold",
      key: "product_sold",
      key1: "subscriptions",
    },
    SignedUPUsers: {
      icon: users,
      key1: "signups",
      name: "Sign Up Users",
      key: "sign_ups",
    },
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };



  const Slabs = {
    bronze: {
      color: "#EC7449",
      name: "Bronze Slab",
      key: " 10%",

      icon: svgSheet.BronzeAffiliate,
    },
    silver: {
      color: "#A3A3A3",
      name: "Silver Slab ",
      key: " 20%",
      icon: svgSheet.SilverAffiliate,
    },
    gold: {
      color: "#E09524",
      name: "Gold Slab",
      key: "30%",
      icon: svgSheet.goldenAffiliate,
    },
    special: {
      color: "#945EFA",
      name: "Special Slab",
      key: " 40%",
      icon: svgSheet.SpecialAffiliate,
    },
  };
  const [affliatecode, setaffliatecode] = useState();
  const [affliatecode1, setaffliatecode1] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getAffiliateDashboardCard().then((res) => {
      setaffliatecode1(res?.data);
    });
    getAffiliatesignupdata().then((res) => {
      if (res?.data?.is_tc_accepted === false) {
        navigate("/affiliate/terms&conditions");
      }
      setaffliatecode(res?.data);
    });
  }, []);

  useEffect(() => {}, []);


  const { lightMode } = useSelector(getThemeMode);

  return (
    <div className="my-body  px-30-0">
      <div
        className={`${
          lightMode ? " card-drop-light-shadow" : "card-drop-dark-shadow"
        } br-3   d-flex flex-col-row-reverse w-100 p-30`}
      >
        <div className="w-100 d-flex justify-content-between flex-col">
          <div>
            <p
              style={{
                color: Slabs[affliatecode && affliatecode?.slab]?.color,
              }}
            >
              My Affiliate Info
            </p>
            <p
              className="d-inline"
              style={{
                color: Slabs[affliatecode && affliatecode?.slab]?.color,
              }}
            >
              You’re on{" "}
              <span className="fs-s-16 fw-600">
                {Slabs[affliatecode && affliatecode?.slab]?.name}{" "}
                {affliatecode?.commission}
                {"% "}
              </span>
              This Month{" "}
            </p>
          </div>
          <div className="d-flex w-70-100  w-80 flex-wrap justify-content-between">
            {Object.values(options).map((items) => (
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img height="32" src={items?.icon} alt=""></img>
                </div>
                <div className="ml-10 mt-10 ">
                  <p className="mb-0 fw-900 fs-s-18 ff-lato">
                    {items?.unit}{" "}
                    {affliatecode1 &&
                      numberWithCommas(affliatecode1[items?.key])}
                  </p>
                  <p className="fs-s-12 fw-500">{items?.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            style={{ width: "160px" }}
            className="mr-40 d-flex align-items-center w-160 mt-10"
          >
            {Slabs[affliatecode && affliatecode?.slab]?.icon}
          </div>
        </div>
      </div>
      <p className="fs-s-20 my-15 fw-700">Commission Information</p>
      <div>
        <AffliateDashboardTable
          affliatecode={affliatecode}
          lightMode={lightMode}
        />
      </div>
      <div style={{ height: "500px" }}>
        <AffiliateDeshboardBarChar lightMode={lightMode} />
      </div>
      <AffiliateMovesDash lightMode={lightMode} options={options} />
    </div>
  );
}

export default Affiliatedashboard;
