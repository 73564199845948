import React from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import PortalLoaderGif from '../../assets/images/loadergif/portalLoading.gif'
function DotLoader() {
  const { lightMode } = useSelector(getThemeMode);
  return (
    <div style={{width:'100%',height:'100vh',display:'flex',justifyContent:"center",alignItems:'center'}} >
      <img style={{borderRadius:'50%',width:'75px',height:'75px'}} src={PortalLoaderGif} alt="load-icon"/>
    {/* <div className="loader">
      <div style={{backgroundColor:lightMode?'#3B3F4F':'#fff'}} className="load-ball"></div>
      <div style={{backgroundColor:lightMode?'#3B3F4F':'#fff'}} className="load-ball"></div>
      <div style={{backgroundColor:lightMode?'#3B3F4F':'#fff'}} className="load-ball"></div>
    </div> */}
    </div>
  );
}

export default DotLoader;
