import React, { memo, useMemo } from "react";
import AreaChart from "../../components/Charts/AreaChart";
import { getGradient } from "../../components/Charts/getGradients";
import LineChart from "../../components/Charts/LineChart";
import { convertTograph } from "../../utilityFn/convertDataToChart";

function GraphIndexdetails({ graphData, lightMode }) {
  const chartPoints = useMemo(
    () => convertTograph(graphData, "days=5"),
    [graphData]
  );

  const gradientColors = [
    [
      { xValue: 0, color: "rgba(0, 133, 128, 0.7)" },
      { xValue: 1, color: "rgba(0, 133, 128, 0)" },
    ],
    [
      { xValue: 0, color: "rgba(188, 55, 84, 0.6)" },
      { xValue: 1, color: "rgba(188, 55, 84, 0)" },
    ],
  ];
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    labels: chartPoints?.labelArray,
    datasets: [
      {
        fill: true,
        // backgroundColor:chartPoints?.stockTrend?'rgb(237,255,246)':'#FAE4E7',
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          } else if (chartPoints?.stockTrend)
            return getGradient(ctx, chartArea, gradientColors[0]);
          else return getGradient(ctx, chartArea, gradientColors[1]);
        },
        // backgroundColor: gradient,
        label: {
          display: false,
        },
        // data: [12, 19, 3, 5, 2, 3],
        data: chartPoints?.graphPointsArray,

        borderColor: chartPoints?.stockTrend ? ["#008580"] : ["#BC3754"],
        borderWidth: 2,
      },
    ],
  };
  return (
    <div style={{ width: "100%", height: "55px" }}>
      <AreaChart
        dataPoints={data}
        options={{
          responsive: true,

          maintainAspectRatio: false,
          tension: 0,
          scales: {
            y: {
              display: false,
            },

            x: {
              display: false,
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              backgroundColor: lightMode ? "#ffff" : "#292E3F",
              displayColors: false,

              callbacks: {
                labelTextColor: () => (lightMode ? "black" : "white"),

                title: (chart, args) => {
                  return "";
                },
                label: (context) => `${context.formattedValue}`,
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
        }}
        height={"100%"}
        // labelArr={chartPoints?.labelArray}
        // dataPoints={chartPoints?.graphPointsArray}
        // stockTrend={chartPoints.stockTrend}
      />
    </div>
  );
}

export default memo(GraphIndexdetails);
