import React, { useEffect, useState } from "react";
import { getMarketReturns } from "../../api/fetchClient";
import CustomPagination from "../../components/ant/CustomPagination";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";
import IndexPerformPopup from "./IndexPerformPopup";

function MaretPerformanceTable({ value1, CurremtValue, lightMode }) {
  const [toggleModal, settoggleModal] = useState("NONE");
  const [Loading, setLoading] = useState(false);
  const [MarketStats, setMarketStats] = useState();
  const [sortState, setsortState] = useState({
    head: "change",
    sortOrder: false,
  });
  const [Page, setPage] = useState({ page: 1, perPage: 20 });
  useEffect(() => {
    setLoading(true);
    getMarketReturns(
      CurremtValue,
      value1,
      Page.page,
      sortState.head,
      sortState.sortOrder,
      Page.perPage
    ).then((res) => {
      setLoading(false);
      setMarketStats(res?.data);
    });
  }, [Page, sortState]);
  useEffect(() => {
    // setPage(1);
    setLoading(true);
    getMarketReturns(
      CurremtValue,
      value1,
      1,
      sortState.head,
      sortState.sortOrder,
      Page.perPage
    ).then((res) => {
      setLoading(false);
      setMarketStats(res?.data);
    });
  }, [value1, CurremtValue]);

  const onPageChange = (e, perPage) => {
    // setPage(e);
    window.scrollTo(0, 0);
  };

  const columns1 = [
    {
      title: (
        <SortHeader
          label="Index"
          header="name"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      //  <p className="fs-s-16 fw-600 mb-0">Index </p>,
      dataIndex: "name",
      fixed: "left",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato pointer link-hover-underline"
              onClick={() => settoggleModal(record?.symbol)}
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Points"
          header="points"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      //  <p className="fs-s-16 fw-600 mb-0">Points </p>,
      dataIndex: "points",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label={`${value1?.toUpperCase()} Change`}
          header="change"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),

      //  (
      //   <p className="fs-s-16 fw-600 mb-0">{value1?.toUpperCase()} Change </p>
      // ),
      width: "50px",
      dataIndex: "change",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),

                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label={`${value1?.toUpperCase()} Change %`}
          header="change_per"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      //  (
      //   <p className="fs-s-16 fw-600 mb-0">{value1?.toUpperCase()} Change % </p>
      // ),
      width: "50px",
      dataIndex: "change_per",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),

                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)} %
            </span>
          ),
        };
      },
    },
  ];

  return (
    <div
      className={`table-shadow  ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
      <CustomTable
        columns={columns1}
        data={MarketStats?.results}
        loading={Loading}
      />

      <CustomPagination
        current={Page.page}
        // showQuickJumper={true}
        lightMode={lightMode}
        onChange={(num, perPage) => {
          setPage({ page: num, perPage: perPage });
        }}
        total={MarketStats?.count}
        pageSize={Page.perPage}
      />
      

      <IndexPerformPopup
        lightMode={lightMode}
        toggleModal={toggleModal}
        settoggleModal={settoggleModal}
      />
    </div>
  );
}

export default MaretPerformanceTable;
