import React from "react";
import FAQSection from "../../../../home/components/FAQSection";

function AffiliateFaq({ lightMode }) {
  const faqData = [
    {
      head: `What is Tradebrains Portal?`,
      content: (
        <>
          <p>
            Portal is a stock research website offered by Trade Brains. This is
            built to help investors make efficient stock research and analysis
            by providing quality fundamental data with insightful visuals.
            <br /> We have built Trade Brains Portal with the simplest UI for
            the ease of stock research of our users. With Trade Brains Portal,
            you can find all the important financial information of stocks along
            with multiple tools to research and analyze companies, all in one
            place.
          </p>
        </>
      ),
    },
  ];
  return (
    <section className="max-w flex flex-col  align-items-center  mx-auto px-15  w-100">
      <h3 className="fs-40-32 fw-700 mb-20 text-md-center ">
        Frequently asked Questions
      </h3>
      <div className="w-100-75 mb-75">
        <FAQSection data={faqData} lightMode={lightMode} />
      </div>
    </section>
  );
}

export default AffiliateFaq;
