import React from "react";
import { Link } from "react-router-dom";
import exportExcel from "../../assets/images/new updates/exportexcel.png";
import heatmapUpdate from "../../assets/images/new updates/heatmapUpdate.png";
import pivotPoints from "../../assets/images/new updates/pivot-points.png";
import eventtracker from "../../assets/images/new updates/eventtracker.png";


import UpdateCard from "./UpdateCard";
function NewUpdates() {
  const updateData = [
    {
      head: "Event Tracker",
      desc: 'We just added a star to your portfolio section. Your portfolio just got better data. Now you can find all the corporate actions of your stocks under your portfolio section. All the historic and upcoming Board meetings, Dividends, Bonuses, Splits & AGM EGM.',
      date: "Updated on Jan 06, 2023, 11:45 a.m.",
      image: eventtracker,
      bottomDesc:'Event Tracker in your portfolio analysis'
    },
    {
      head: "Pivot Points",
      desc: <>Pivot points standard and Fibonacci levels for daily and monthly prices are now available on the Portal for all stocks for TechnoFunda investors. Now you can find the nearest support and resistance of stocks with super ease. </>,
      // date: "Release date: 26th December 2022",
      date: "Updated on Dec 26, 2022, 4:22 p.m.",

      image: pivotPoints,
      bottomDesc:'Pivot point values in stock details page'
    },
    {
      head: "Stock Heatmaps",
      desc: <>Get a bird eye view of the market using our new feature. We have added Stock Heatmaps to provide you with all the information about how the stocks have performed or are performing on the given day at just a glance at any indices you choose. Check out here:<Link to={'/index/NIFTY/heatmap'}> https://portal.tradebrains.in/index/NIFTY/heatmap</Link></>,
      date: "Updated on Dec 05, 2022, 12:30 p.m.",
      image: heatmapUpdate,
      bottomDesc:'Bird eye view for  indices'
    },
    {
      head: "Export to Excel made simple",
      desc: 'All the same amazing features taken offline on your excel sheet for easy access anywhere and anytime.',
      date: "Updated on Nov 20, 2022, 9:54 a.m.",
      image: exportExcel,
      bottomDesc:'Download detailed reports as a Excel Sheet'
    },
  
  ];
  return (
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <h1 className="mb-40 fs-36-20 fw-700">New Updates</h1>
        {updateData?.map((item, i) => (
          <UpdateCard data={item} key={i} />
        ))}
      </div>
    </div>
  );
}

export default NewUpdates;
