import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function convertTableStructure(
  apiData,
  tableType,
  arrayOfHeader,
  lightMode,
  symbolsandnames
) {
  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Particulars</p>,
      dataIndex: "PARTICULARS",
      fixed: "left",
      width:symbolsandnames.length <= 3  ? "10px" : "30px",
      render(text, record) {
        return {
          props: {
            style: lightMode
              ? tableType[record.key - 1]?.highLighted
                ? {
                    background: "#E4F0FA",
                    borderBottom: "3px solid #D3E8FB",
                    borderRight: "1px solid #C9D7DE",
                  }
                : {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
              : tableType[record.key - 1]?.highLighted
              ? {
                  background: "#494F65",
                  borderRight: "1px solid #545E78",
                  borderBottom: "2px solid #545E78",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: tableType[record.key - 1]?.isHeader ?"15  px" :"14px",
                fontWeight: tableType[record.key - 1]?.isHeader ?"600" :"400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
  ];

  symbolsandnames?.map((element, i) => {
    columns.push({
      title: <p className="fs-s-16 fw-600 mb-0">{element?.name}</p>,
      dataIndex: element?.symbole,
      // width: "30px",
      width:symbolsandnames.length <= 3  ? "20px" : "30px",
      render(text, record) {
        const ConvertCr = (text / 10000000).toFixed(2);
        return {
          props: {
            style: lightMode
              ? tableType[record.key - 1]?.highLighted
                ? {
                    background: "#E4F0FA",
                    borderBottom: "3px solid #D3E8FB",
                    borderRight: "1px solid #C9D7DE",
                  }
                : {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
              : tableType[record.key - 1]?.highLighted
              ? {
                  background: "#494F65",
                  borderRight: "1px solid #545E78",
                  borderBottom: "2px solid #545E78",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: tableType[record.key - 1]?.colorable
                  ? redGreenColorPicker(text, lightMode)
                  : lightMode
                  ? "black"
                  : "white",
              }}
            >
              {tableType[record.key - 1]?.Rupee &&
                tableType[record.key - 1]?.Rupee}
              {tableType[record.key - 1]?.isHeader
                ? ""
                : tableType[record.key - 1]?.covert
                ? numberWithCommas(Number(ConvertCr))
                : numberWithCommas(Number(text))}
              {tableType[record.key - 1]?.unit
                ? tableType[record.key - 1]?.unit
                : ""}
            </span>
          ),
        };
      },
    });
  });

  const tableBodyData = tableType?.map((item, index) => {
    let YearArryaData = {};
    if (item.isHeader) {
      return {
        key: index + 1,
        PARTICULARS: item.name,
      };
    } else {
      const yearData = Object.values(apiData).map((value, i) => {
        YearArryaData = {
          ...YearArryaData,
          [arrayOfHeader[i]]: value[item.key] ?? 0,
        };
      });
      return {
        key: index + 1,
        PARTICULARS: item.name,
        ...YearArryaData,
      };
    }
  });

  return { tableBodyData, columns };
}
export default convertTableStructure;
