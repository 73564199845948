import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Switch } from "antd";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import StockTopCard from "./StockTopCard";
import StockEndpoints from "../../api/endPoints";
import {
  balanceSheet,
  cashFlowTable,
  FiveYearTableData,
  KeyMetrics,
  ProfitAndLoss,
  quarterlyFinancial,
  shareHoldingtableType,
} from "./tableTypes";
import { createTableData } from "./createTableData";
import { createQuarterTable } from "./createQuarterTableData";
import {
  bankbalanceSheet,
  bankCashFlowTable,
  bankFiveYearTableData,
  bankProfitAndLoss,
  bankQuarterlyFinancial,
  bankShareHoldingtable,
} from "./bankTableType";
import LeftSideBar from "./LeftSideBar";
import MobileBottomBar from "./MobileBottomBar";
import StockGraph from "./StockGraph";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import QuarterGraphTable from "./QuarterGraphTable";
import PandLGraphTable from "./PandLGraphTable";
import BalanceSheetGraphTable from "./BalanceSheetGraphTable";
import FeaturesTab from "./FeaturesTab";
import RatioGraphsGroup from "./ratio graphs/RatioGraphsGroup";
import DividentTable from "./ratio graphs/DividentTable";
import GrowthTable from "./GrowthTable";
import PeerCompanies from "./PeerCompanies";
import CompanyDetails from "./compant details/CompanyDetails";
import StockFaq from "./StockFaq";
import ReturnsTable from "../IndexDetails/ReturnsTable";
import DoughnutWithLabels from "../../components/Charts/DoughnutWithLabels";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import SimilarStockSlider from "./SimilarStockSlider";
import svgSheet from "../../assets/svg/svgSheet";
import DotLoader from "../../components/spinners/DotLoader";
import PriceHighLowSlider from "../../components/PriceHighLowSlider";
import { getStockLTP, getstockRedirectStatus } from "../../api/fetchClient";
import ProsConsDiv from "./ProsConsDiv";
import { dateWithAMPM } from "../../utilityFn/datewithAMPM";
import CustomTooltip from "../../components/ant/CustomTooltip";
import {
  balanceSheetINS,
  ProfitAndLossINS,
  FiveYearTableINS,
} from "./TableInsurance";
import useGetFetch from "../../hooks/useGetFetch";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import { authStore } from "../../redux/reducers/authSlice";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import NoData from "../../components/NoData";
import TechnicalIndex from "./technical/TechnicalIndex";

function StockDetailsIndex() {
  const navigate = useNavigate();
  const URLState = useLocation();
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const { symbol, type = "stock", companyType } = useParams();
  const [stockCPM, setstockCPM] = useState({});
  // const [stockTypeData, setstockTypeData] = useState({})
  // fetch basic stock data from api
  const {
    isLoading: basicLoading,
    apiData: basicStockData,
    serverError: basicErr,
  } = useGetFetch(StockEndpoints.basicStock(type, symbol));
  //check if stock is a bank or insurance, table data is different
  const notBankStock = useMemo(() => {
    // return  basicStockData?.rformat === "MAN" || basicStockData?.rformat === "FIN";
    switch (basicStockData?.fformat) {
      case "FIN":
        return "OTHER";
      case "BNK":
        return "BANK";
      case "INS":
        return "INSURANCE";
      default:
        return "OTHER";
        break;
    }
  }, [basicStockData]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  // remove previous  state if there is no consolidated data
  //   window.onpopstate = () => {
  // if(stockTypeData?.hasConsolidated===false && stockTypeData?.default === "standalone"){
  //   navigate(-2);
  // }
  // else{
  //   navigate(-1);
  // }
  //   }

  useLayoutEffect(() => {
    const getData = async () => {
      const response = getstockRedirectStatus(symbol)
        .then((resp) => {
          // setstockTypeData(resp)
          // default state is standalone and consolidated also there 
          if (resp.hasConsolidated && resp.default === "standalone") {
            navigate(`/${type}/${symbol}/standalone`);
            //  there is no consolidated and disable consolidated state 
          } else if (!resp.hasConsolidated && resp.default === "standalone") {
            navigate(`/${type}/${symbol}/standalone`, {
              state: { disableStatus: true },
            });
          }
        })
        .catch((err) => console.log(err));
      await getStockLTP("stock", symbol).then((resp) => {
        setstockCPM(resp.data[0]);
      });
    };
    getData();
  }, [symbol]);

  // fetch metrics data from api
  const {
    isLoading,
    apiData: metricsData,
    serverError,
  } = useGetFetch(StockEndpoints.keyMetrics(type, symbol, companyType));

  const {
    isLoading: PlLoadng,
    apiData: PandLData,
    serverError: plError,
  } = useGetFetch(StockEndpoints.profitAndLoss(type, symbol, companyType));
  // fetch balance sheet data from api
  const {
    isLoading: BSLoadng,
    apiData: balanceSheetData,
    serverError: BSError,
  } = useGetFetch(StockEndpoints.balanceSheet(type, symbol, companyType));
  // fetch cash flow data from api
  const {
    isLoading: CFloading,
    apiData: cashflowData,
    serverError: CFError,
  } = useGetFetch(StockEndpoints.cashFlow(type, symbol, companyType));
  // fetch quarterly financial data from api
  const {
    isLoading: QFloading,
    apiData: quarterlydata,
    serverError: QFError,
  } = useGetFetch(
    StockEndpoints.quarterlyFinStatement(type, symbol, companyType)
  );
  // fetch share holding  data from api
  const {
    isLoading: SHloading,
    apiData: shareHoldingAPI,
    serverError: SHError,
  } = useGetFetch(StockEndpoints.shareHolding(type, symbol, companyType));
  // fetch financial ratios  data from api
  const {
    isLoading: FinRloading,
    apiData: finRatioData,
    serverError: FinRatioError,
  } = useGetFetch(StockEndpoints.finRatio(type, symbol, companyType));
  // get stock performance data
  const {
    isLoading: performloading,
    apiData: performanceData,
    serverError: performError,
  } = useGetFetch(StockEndpoints.stockPerformance(symbol));

  // get stock  returns
  const {
    isLoading: returnsLoading,
    apiData: returnsdata,
    serverError: returnserr,
  } = useGetFetch(StockEndpoints.stockReturns(symbol));
  // get peer companies
  const {
    isLoading: peerLoading,
    apiData: peerCompany,
    serverError: peerError,
  } = useGetFetch(StockEndpoints.stockPeerCompany(symbol, companyType));
  // get poivot points
  const {
    isLoading: pivotLoad,
    apiData: pivotData,
    serverError: pivotErr,
  } = useGetFetch(StockEndpoints.pivotAPI(symbol, false, "daily"));

  const returnstableData = [
    { "1M Returns": returnsdata?.one_month },
    { "6M Returns": returnsdata?.six_months },
    { "1Y Returns": returnsdata?.one_year },
    { "3Y Returns": returnsdata?.three_year },
    { "5Y Returns": returnsdata?.five_year },
  ];

  // create key metrics table  input data
  const metricsTableData = useMemo(() => {
    if (metricsData) {
      const metricsTable = KeyMetrics?.map((item, i) => ({
        key: i + 1,
        PARTICULARS: item.name,
        VALUES: metricsData && metricsData[item.key],
        tooltipText: item.tooltipText,
      }));
      return metricsTable;
    }
  }, [metricsData]);
  // create key profit and loss table  input data
  const PandLtableData = useMemo(() => {
    if (PandLData) {
      const { YearHeaders, tableBodyData } = createTableData(
        PandLData,
        notBankStock === "OTHER"
          ? ProfitAndLoss
          : notBankStock === "BANK"
          ? bankProfitAndLoss
          : ProfitAndLossINS,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [PandLData, notBankStock, lightMode]);
  // create data for balance sheet
  const balanceSheetTable = useMemo(() => {
    if (balanceSheetData) {
      const { YearHeaders, tableBodyData } = createTableData(
        balanceSheetData,
        notBankStock === "OTHER"
          ? balanceSheet
          : notBankStock === "BANK"
          ? bankbalanceSheet
          : balanceSheetINS,
        // notBankStock ? balanceSheet : bankbalanceSheet,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [balanceSheetData, lightMode]);
  // create data for cashflow sheet
  const cashFlowTableData = useMemo(() => {
    if (cashflowData) {
      const { YearHeaders, tableBodyData } = createTableData(
        cashflowData,
        notBankStock === "OTHER"
          ? cashFlowTable
          : notBankStock === "BANK"
          ? bankCashFlowTable
          : cashFlowTable,
        // notBankStock ? cashFlowTable : bankCashFlowTable,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [cashflowData, lightMode]);
  // create quarterly data
  const quraterlyFinData = useMemo(() => {
    if (quarterlydata) {
      const { YearHeaders, tableBodyData } = createQuarterTable(
        quarterlydata,
        notBankStock === "OTHER"
          ? quarterlyFinancial
          : notBankStock === "BANK"
          ? bankQuarterlyFinancial
          : quarterlyFinancial,
        // notBankStock ? quarterlyFinancial : bankQuarterlyFinancial,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [quarterlydata, lightMode]);

  // create share holding data
  const shareHoldingData = useMemo(() => {
    if (shareHoldingAPI) {
      const { YearHeaders, tableBodyData } = createQuarterTable(
        shareHoldingAPI,
        notBankStock === "OTHER"
          ? shareHoldingtableType
          : notBankStock === "BANK"
          ? bankShareHoldingtable
          : shareHoldingtableType,
        // notBankStock ? shareHoldingtableType : bankShareHoldingtable,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [shareHoldingAPI, lightMode]);

  // share holding graph data
  const shareHoldingGraphData = useMemo(() => {
    let totalValue;
    let dataObj = {};
    let dataArray;
    if (shareHoldingAPI) {
      const keys = Object.keys(shareHoldingAPI);
      dataObj = shareHoldingAPI[keys.at(-1)];
      totalValue =
        dataObj?.promoters +
        dataObj?.public +
        dataObj?.fii +
        dataObj?.total_dii +
        dataObj?.others;

      dataArray = [
        dataObj?.promoters ?? 0,
        dataObj?.public ?? 0,
        dataObj?.fii ?? 0,
        dataObj?.total_dii ?? 0,
        dataObj?.others ?? 0,
      ];
    }
    return { totalValue, dataArray };
  }, [shareHoldingAPI, lightMode]);

  // create 2 year fact sheet holding data
  const FiveYearData = useMemo(() => {
    if (finRatioData && PandLData) {
      let mergedObj = {};
      for (const [key, value] of Object.entries(finRatioData)) {
        mergedObj = { ...mergedObj, [key]: { ...PandLData[key], ...value } };
      }
      const { YearHeaders, tableBodyData } = createTableData(
        mergedObj,
        notBankStock === "OTHER"
          ? FiveYearTableData
          : notBankStock === "BANK"
          ? bankFiveYearTableData
          : FiveYearTableINS,
        // notBankStock ? FiveYearTableData : bankFiveYearTableData,
        lightMode
      );
      return { YearHeaders, tableBodyData };
    }
  }, [finRatioData, PandLData, lightMode]);
  //swith between consolidated or standalone
  const switchCompanyType = (checked) => {
    if (checked) {
      navigate(`/${type}/${symbol}/consolidated`, { replace: true });
    } else {
      navigate(`/${type}/${symbol}/standalone`, { replace: true });
    }
  };

  const columns = [
    {
      title: <p className="fw-600 mb-0 fs-s-16">PARTICULARS</p>,
      dataIndex: "PARTICULARS",
      width: "100px",
      render: (text, record) => {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  fontSize: "16px",
                  borderRight: "1px solid #C9D7DE",
                  borderLeft: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  fontSize: "16px",
                },
          },
          children: (
            <div
              style={{ color: lightMode ? "black" : "white" }}
              className="d-flex  justify-content-between align-items-center"
            >
              <p className="mb-0">{text}</p>
              <CustomTooltip text={record.tooltipText}>
                {svgSheet.infoTooltop}
              </CustomTooltip>
            </div>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">VALUES</p>,
      dataIndex: "VALUES",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {KeyMetrics[index.key - 1]?.unit === "₹"
                ? KeyMetrics[index.key - 1]?.unit
                : ""}{" "}
              {numberWithCommas(text)}{" "}
              {KeyMetrics[index.key - 1]?.unit === "%"
                ? KeyMetrics[index.key - 1]?.unit
                : ""}
            </span>
          ),
        };
      },
    },
  ];
  const navBarData = [
    {
      icon: svgSheet.overviewIcon,
      label: "Overview",
      id: "Overview",
      offset: -175 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.keyIcon,
      label: "Key Metrics",
      id: "keymetrics",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.announceIcon,
      label: "Corp Announcement",
      id: "corpAnnouncement",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.pieIcon,
      label: "Quarterly Statement",
      id: "Quarterly Statement",
      offset: -120 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.PandLIcon,
      label: "Profit/Loss",
      id: "Profit & Loss",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: (
        <div style={{ width: "24px", height: "24px" }}>
          {svgSheet.balanceIcon}
        </div>
      ),
      label: "Balance Sheet",
      id: "Balance Sheet",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.cashFlowIcon,
      label: "Cashflow",
      id: "Cashflow",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.ratioIcon,
      label: "Important Ratios",
      id: "Ratios",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.shareHoldingIcon,
      label: "Shareholding",
      id: "Shareholding",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.peerCompanyIcon,
      label: "Peers",
      id: "Peers",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.factsheetIcon,
      label: "5 Year Factsheet",
      id: "5 Year Factsheet",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
    {
      icon: svgSheet.companyDetailsIcon,
      label: "Company Details",
      id: "Company Details",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
  ];

  // faq question and answers
  const faqData = useMemo(() => {
    return [
      {
        head: `What is the share price of ${basicStockData?.company_name}?`,
        content: (
          <div
            className={lightMode ? "ff-lato text-black" : "ff-lato text-white"}
          >
            Company share price keeps changing according to the market
            conditions. As of {dateWithAMPM(new Date(stockCPM?.date))} the share
            price of {basicStockData?.company_name} is{" "}
            <span className="fw-600 ff-lato">
              ₹{numberWithCommas(stockCPM?.close)}
            </span>
          </div>
        ),
      },
      {
        head: ` What is the Market Cap of ${basicStockData?.company_name}?`,
        content: (
          <div
            className={lightMode ? "text-black ff-lato" : "text-white ff-lato"}
          >
            Market capitalization or market cap is determined by multiplying the
            current share price by total number of outstanding shares. As of{" "}
            {dateWithAMPM(new Date())} the market cap of{" "}
            {basicStockData?.company_name} is{" "}
            <span className="fw-600 ff-lato">
              {" "}
              ₹{numberWithCommas(metricsData?.mcap)} Cr
            </span>
          </div>
        ),
      },
      {
        head: ` What is the PE ratio of ${basicStockData?.company_name}?`,
        content: (
          <div className={lightMode ? "text-black" : "text-white"}>
            The (TTM) PE Ratio of {basicStockData?.company_name} as of{" "}
            {dateWithAMPM(new Date())} is{" "}
            <span className="fw-500">{metricsData?.pe}</span>
          </div>
        ),
      },
      {
        head: ` What is the 52-week high and 52-week low of 
      ${basicStockData?.company_name}?`,
        content: (
          <div
            className={lightMode ? "text-black ff-lato" : "text-white ff-lato"}
          >
            The 52-week High of {basicStockData?.company_name} is{" "}
            <span className="fw-600 ff-lato">
              {" "}
              ₹{numberWithCommas(performanceData?.high_52w)}
            </span>{" "}
            while the 52-week low is{" "}
            <span className="fw-600 ff-lato">
              {" "}
              ₹{numberWithCommas(performanceData?.low_52w)}
            </span>
          </div>
        ),
      },
      {
        head: ` What is the ROE of ${basicStockData?.company_name}?`,
        content: (
          <div className={lightMode ? "text-black" : "text-white"}>
            The Current ROE of {basicStockData?.company_name} is{" "}
            <span className="fw-500">{metricsData?.roe}%</span>
          </div>
        ),
      },
      {
        head: ` What is the Support of ${basicStockData?.company_name}?`,
        content: (
          <>
            <div className={lightMode ? "text-black" : "text-white"}>
              First Support of {basicStockData?.company_name} is at{" "}
              <span className="fw-600 ff-lato">
                ₹{pivotData && pivotData[4]?.std}
              </span>
            </div>
            <div className={lightMode ? "text-black" : "text-white"}>
              Second Support of {basicStockData?.company_name} is at
              <span className="fw-600 ff-lato">
                {" "}
                ₹{pivotData && pivotData[5]?.std}
              </span>
            </div>
            <div className={lightMode ? "text-black" : "text-white"}>
              Third Support of {basicStockData?.company_name} is at{" "}
              <span className="fw-600 ff-lato">
                {" "}
                ₹{pivotData && pivotData[6]?.std}
              </span>
            </div>
          </>
        ),
      },
      {
        head: ` What is the Resistance of ${basicStockData?.company_name}?`,
        content: (
          <>
            <div className={lightMode ? "text-black" : "text-white"}>
              First Resistance of {basicStockData?.company_name} is at{" "}
              <span className="fw-600 ff-lato">
                ₹{pivotData && pivotData[2]?.std}
              </span>
            </div>
            <div className={lightMode ? "text-black" : "text-white"}>
              Second Resistance of {basicStockData?.company_name} is at{" "}
              <span className="fw-600 ff-lato">
                {" "}
                ₹{pivotData && pivotData[1]?.std}
              </span>
            </div>
            <div className={lightMode ? "text-black" : "text-white"}>
              Third Resistance of {basicStockData?.company_name} is at{" "}
              <span className="fw-600 ff-lato">
                {" "}
                ₹{pivotData && pivotData[0]?.std}
              </span>
            </div>
          </>
        ),
      },
    ];
  }, [symbol, basicStockData, lightMode, performanceData, pivotData]);

  if (isLoading && basicLoading) {
    return <DotLoader />;
  }
  return (
    <>
      <ReactHelmet
        title={`${basicStockData?.company_name} Stock Price today | ${basicStockData?.company_name} Share Price today | ${basicStockData?.company_name} Share holding | ${basicStockData?.company_name} Support and Resistance | ${basicStockData?.company_name} Financials |  ${symbol} Share Price & Financial Data | Trade Brains`}
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      <div className="ff-poppins  relative">
        <div
          id="my-scroll-layout"
          className="d-flex max-w mx-auto  h-100 px-15"
        >
          <LeftSideBar
            linkData={navBarData}
            lightMode={lightMode}
            urlParams={{ symbol, type, companyType }}
            switchCompanyType={switchCompanyType}
            disableStatus={URLState?.state?.disableStatus}
          />
          <div className="w-75-100 ml-30-0 relative my-body">
            <div className="felx d-flex-row w-100 DetailsStoke flex-wrap  justify-content-between   w-100">
              <div className="w-100" id="Overview">
                <StockTopCard
                  excelData={{
                    metricsTableData: {
                      YearHeaders: columns,
                      tableBodyData: metricsTableData,
                    },
                    PandLtableData,
                    quraterlyFinData,
                    balanceSheetTable,
                    cashFlowTableData,
                    shareHoldingData,
                    FiveYearData,
                  }}
                  stockData={{ symbol, type, companyType }}
                  lightMode={lightMode}
                  metricsData={{
                    ...metricsData,
                    companyName: basicStockData?.company_name,
                    price: stockCPM?.close ?? 0,
                  }}
                />
                <div className="flex justify-content-end mb-25 details-top-card w-100">
                  <Switch
                    defaultChecked
                    disabled={URLState?.state?.disableStatus ? true : false}
                    onChange={switchCompanyType}
                  />
                  <span>
                    {companyType?.replace(/^./, companyType[0].toUpperCase())}
                  </span>
                </div>
                {/* graph part  */}
                <StockGraph
                  type={type}
                  symbol={symbol}
                  lightMode={lightMode}
                  volumeBar={true}
                />
              </div>
              <PriceHighLowSlider
                high={performanceData?.high_52w}
                low={performanceData?.low_52w}
                close={stockCPM?.close}
              />
              <div className=" main-pros-and-cons mb-10">
                <div className="pros-and-cons d-flex d-flex-col ">
                  <div>
                    <ReturnsTable
                      lightMode={lightMode}
                      returnsdata={returnstableData}
                    />
                  </div>
                </div>
              </div>

              <TechnicalIndex
                lightMode={lightMode}
                symbol={symbol}
                CMP={stockCPM?.close}
                index={false}
              />
              {/* {peerCompany?.length > 5 && ( */}
              <SimilarStockSlider lightMode={lightMode} data={peerCompany} />
              {/* )} */}
              <div className="w-100" id="keymetrics">
                <h2 className="main-header">Key Metrics</h2>
                <div className="main-pros-and-cons  d-flex ">
                  <div className="current-key  " style={{ width: "35%" }}>
                    <div
                      className={` sub-current-key table-shadow ${
                        lightMode
                          ? "custom-antd-head-light"
                          : "custom-antd-head-dark"
                      }`}
                    >
                      <CustomTable
                        columns={columns}
                        data={metricsTableData}
                        scrollable={true}
                        scrollLimit={300}
                      />
                    </div>
                  </div>

                  <ProsConsDiv
                    lightMode={lightMode}
                    symbol={symbol}
                    companyType={companyType}
                  />
                </div>
              </div>
            </div>

            <FeaturesTab lightMode={lightMode} symbol={symbol} />
            {/* quarterly statements  */}
            <div className=" main-pros-and-cons " id="Quarterly Statement">
              <QuarterGraphTable
                data={quarterlydata}
                tableHeaders={quraterlyFinData?.YearHeaders}
                tableData={quraterlyFinData?.tableBodyData}
                lightMode={lightMode}
              />
            </div>
            <div className=" main-pros-and-cons " id="Profit & Loss">
              <PandLGraphTable
                notBankStock={notBankStock}
                PandLtableData={PandLtableData}
                PandLData={PandLData}
                lightMode={lightMode}
              />
            </div>
            <div id="Balance Sheet" className=" main-pros-and-cons ">
              <BalanceSheetGraphTable
                balanceSheetTable={balanceSheetTable}
                lightMode={lightMode}
                notBankStock={notBankStock}
              />
            </div>
            <div className=" main-pros-and-cons " id="Cashflow">
              <div className="pros-and-cons d-flex d-flex-col">
                <h2
                  style={{ lineHeight: "28px", color: "inherit" }}
                  className="fs-28-18 fw-700 mt-20"
                >
                  Cashflow Statement
                </h2>
                <p className="d-flex justify-content-end fs-s-12 text-gray">
                  *Values in Cr.
                </p>
                <div
                  className={`table-shadow  ${
                    lightMode
                      ? "custom-antd-head-light"
                      : "custom-antd-head-dark"
                  }`}
                >
                  <CustomTable
                    scrollLimit={700}
                    columns={cashFlowTableData?.YearHeaders}
                    scrollable={true}
                    data={cashFlowTableData?.tableBodyData}
                    lightMode={lightMode}
                  />
                </div>
              </div>
            </div>
            <div className=" main-pros-and-cons " id="ratiograph">
              <RatioGraphsGroup
                finRatioData={finRatioData}
                lightMode={lightMode}
                notBankStock={notBankStock}
              />
            </div>
            <div className=" main-pros-and-cons">
              <div className="pros-and-cons d-flex d-flex-col my-30">
                <DividentTable
                  scrollLimit={700}
                  finRatioData={finRatioData}
                  notBankStock={notBankStock}
                  lightMode={lightMode}
                />
                <h3 className="fs-20-16 fw-600 my-30">Growth</h3>
                <GrowthTable
                  scrollLimit={700}
                  lightMode={lightMode}
                  symbol={symbol}
                  companyType={companyType}
                />
              </div>
            </div>

            <div className=" main-pros-and-cons " id="Shareholding">
              <div className="pros-and-cons d-flex d-flex-col">
                <h2 className="main-header">Shareholding Pattern</h2>
                <div className=" w-100">
                  {shareHoldingAPI &&
                  Object.keys(shareHoldingAPI)?.length > 0 ? (
                    <>
                      <DoughnutWithLabels
                        divID="holdings"
                        labelArray={[
                          "Promoters",
                          "Public",
                          "FII",
                          "DII",
                          "Others",
                        ]}
                        shareHoldingAPI={shareHoldingAPI}
                        lightMode={lightMode}
                        totalValue={shareHoldingGraphData.totalValue}
                        dataArray={shareHoldingGraphData.dataArray}
                      />
                      <Link
                        to={`/stock/${symbol}/shareholdings/details`}
                        className="text-gray d-flex justify-content-end my-5px underline"
                        style={{ textDecorationColor: "#AEAEAE" }}
                      >
                        View More
                      </Link>
                      <div
                        className={`w-100 table-shadow ${
                          lightMode
                            ? "custom-antd-head-light"
                            : "custom-antd-head-dark"
                        }`}
                      >
                        <CustomTable
                          scrollLimit={700}
                          columns={shareHoldingData?.YearHeaders}
                          scrollable={true}
                          data={shareHoldingData?.tableBodyData}
                          lightMode={lightMode}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="my-30">
                      <NoData />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className=" main-pros-and-cons " id="Peers">
              <div className="pros-and-cons d-flex d-flex-col">
                <h2 className="main-header">Peer Companies</h2>
                <div
                  className={`w-100 table-shadow ${
                    lightMode
                      ? "custom-antd-head-light"
                      : "custom-antd-head-dark"
                  }`}
                >
                  <PeerCompanies
                    lightMode={lightMode}
                    peerCompany={peerCompany}
                    notBankStock={notBankStock}
                  />
                </div>
              </div>
            </div>
            <div className=" main-pros-and-cons " id="5 Year Factsheet">
              <div className="pros-and-cons d-flex d-flex-col">
                <h2 className="main-header">5 Year Factsheet</h2>
                <div
                  className={`table-shadow ${
                    lightMode
                      ? "custom-antd-head-light"
                      : "custom-antd-head-dark"
                  }`}
                >
                  <CustomTable
                    scrollLimit={700}
                    columns={FiveYearData?.YearHeaders}
                    scrollable={true}
                    data={FiveYearData?.tableBodyData}
                    lightMode={lightMode}
                  />
                </div>
              </div>
            </div>
            <div className=" main-pros-and-cons " id="Company Details">
              <div className="pros-and-cons d-flex d-flex-col">
                <h2 className="main-header">Company Details</h2>
                <CompanyDetails lightMode={lightMode} symbol={symbol} />
              </div>
            </div>
            <div className=" main-pros-and-cons ">
              <div className="pros-and-cons d-flex d-flex-col">
                <StockFaq data={faqData} lightMode={lightMode} />
              </div>
            </div>
          </div>
        </div>
        <MobileBottomBar lightMode={lightMode} navData={navBarData} />
      </div>
    </>
  );
}

export default StockDetailsIndex;
