import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteAdminStarportfolio, getAdminPortfolio } from "../../../../api/fetchClient";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import DeleteModal from "../../Components/DeleteModal";
import PortfolioTable from "./PortfolioTable";

function AdminPortfolioIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const [tabledata, settabledata] = useState([]);
  const [searchInput, setsearchInput] = useState(null);
  const [deleleModal, setDeleleModal] = useState({ state: false, id: null });
  const getData = async () => {
    await getAdminPortfolio(searchInput)
      .then((resp) => {
        settabledata(resp.data?.reverse());
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, [searchInput]);
  const deleteClick = (id) => {
    setDeleleModal({ state: true, id: id });
  };
  const onDeleteConfirm=()=>{
    console.log(deleleModal.id);
    deleteAdminStarportfolio(deleleModal.id).then((resp=>{
      message.success("Portfolio Deleted successfully");
      getData()
      setDeleleModal({ state: false, id: null })
    }))
  }
  return (
    <>
      <div className="my-body px-30-0">
        <div className="d-flex justify-content-between ">
          <input
            //   width={"100%"}
            value={searchInput}
            style={{ width: "20%",minWidth:'150px', color: "white" }}
            placeholder="Search  Superstar"
            onChange={(e) => setsearchInput(e.target.value)}
            type="text"
            className={`w-100 h-45  ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          <Link to="/admin/star_portfolio/create">
            <button
              // onClick={() => setModal(true)}
              className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
            >
              Create Superstar
            </button>
          </Link>
        </div>
        <PortfolioTable
          lightMode={lightMode}
          tabledata={tabledata}
          setDeleteModal={deleteClick}
        />
      </div>
      <DeleteModal
        visible={deleleModal.state}
        setVisible={setDeleleModal}
        lightMode={lightMode}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

export default AdminPortfolioIndex;
