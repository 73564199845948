import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {deleteAdminStockRecommendation, getRecommendation } from "../../../../api/fetchClient";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import StockReccomendationTable from "./stockRecommendationTable";
import DeleteModal from "../../Components/DeleteModal";
// import PortfolioTable from "./PortfolioTable";

function AdminStockRecommendation() {
  const { lightMode } = useSelector(getThemeMode);
  const [tabledata, settabledata] = useState([]);
  const [searchInput, setsearchInput] = useState(null);
  const [deleleModal, setDeleleModal] = useState({ state: false, id: null });
  const getData = async () => {
    await getRecommendation(searchInput)
      .then((resp) => {
        settabledata(resp?.data?.results);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, [searchInput]);
  console.log(tabledata , "tabledata")
  const deleteClick = (id) => {
    setDeleleModal({ state: true, id: id });
  };
  const onDeleteConfirm=()=>{
    console.log(deleleModal.id);
    deleteAdminStockRecommendation(deleleModal.id).then((resp=>{
      message.success("Stock Recommendation Deleted successfully");
      getData()
      setDeleleModal({ state: false, id: null })
    }))
  }
  return (
    <>
      <div className="my-body px-30-0">
        <div className="d-flex justify-content-between ">
          <input
            //   width={"100%"}
            value={searchInput}
            style={{ width: "20%", color: "white" }}
            placeholder="Search by Company name"
            onChange={(e) => setsearchInput(e.target.value)}
            type="text"
            className={`w-100 h-45  ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
          <Link to="/admin/stockRecommendation/save">
            <button
              // onClick={() => setModal(true)}
              className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
            >
              Add Stock Recommendation
            </button>
          </Link>
        </div>
        <StockReccomendationTable
        lightMode={lightMode}
        tabledata={tabledata}
          setDeleteModal={deleteClick}
        />
      </div>
      <DeleteModal
         item = {true}
        visible={deleleModal.state}
        setVisible={setDeleleModal}
        lightMode={lightMode}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

export default AdminStockRecommendation;
