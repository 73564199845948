import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getIndexconstitients } from "../../api/fetchClient";
import CustomPagination from "../../components/ant/CustomPagination";
import HeatMap from "../../components/Charts/heatMap/HeatMap";
import HeatmapRange from "../../components/Charts/heatMap/HeatmapRange";
import CustomTable from "../../components/CustomTable";
import NoData from "../../components/NoData";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";

function StockConstituents({ lightMode, indexname }) {
  const [page, setPage] = useState({ num: 1, perPage: 25 });
  const [Loading, setLoading] = useState(false);
  const [IndexData, seIndexData] = useState([]);
  const [first, setfirst] = useState({ value: "ytd", text: "Change YTD%" });
  const [heatmapdata, setheatmapdata] = useState([]);
  const [sortState, setsortState] = useState({
    head: "m_cap",
    sortOrder: false,
  });
  const [filterdData, setfilterdData] = useState([]);
  const [activeRange, setactiveRange] = useState("NONE");
  console.log(heatmapdata);
  useEffect(() => {
    setPage({ num: 1, perPage: 25 });
  }, [indexname, IndexData?.count]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getIndexconstitients({
        indexname,
        page,
        sortBy: sortState.head,
        sortHead: sortState.sortOrder,
      }).then(async (res) => {
        await seIndexData(res);
        setLoading(false);
      });
    };
    getData();
  }, [page, indexname, sortState]);

  useEffect(() => {
    const getData = async () => {
      await getIndexconstitients({
        indexname,
        page,
        sortBy: "per_change",
        sortHead: true,
        page: { perPage: 50 },
      }).then(async (res) => {
        await setheatmapdata(res);
        await setfilterdData(res.results);
      });
    };
    getData();
  }, [page, indexname, sortState]);

  const onChange = (pageNumber) => {
    setPage({ ...page, num: pageNumber });
  };
  const uniquesymbuls = [];

  const StockConstituents1 = [
    {
      title: (
        <SortHeader
          label="Company Name"
          header="comp_name"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "comp_name",
      fixed: "left",
      width: "70px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated`}>
              <div
                className="link-hover-underline"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </div>
            </Link>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="MCap (Cr)"
          header="m_cap"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      //  (
      //   <div className=" d-flex align-items-center ">
      //     <p className="fs-s-16 fw-600 mb-0">MCap</p>{" "}
      //     <p className="mb-0 ml-2 fw-600 mt-5 fs-s-12">(Cr)</p>
      //   </div>
      // ),
      dataIndex: "m_cap",
      width: "50px",
      render(text, record) {
        const data = (text / 10000000).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹{numberWithCommas(Number(data))}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="CMP"
          header="curr_price"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "curr_price",
      width: "60px",
      render: (_, { curr_price }) => {
        const data = curr_price.toFixed(2);

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                ₹{numberWithCommas(Number(data))} 
              </span>

              <span
                className="ff-lato"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "10px",

                  // color: `${redGreenColorPicker(symbol2[1],lightMode)}`,
                }}
              >
                {/* {(symbol2[1]) + "%"} */}
              </span>
            </div>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Change %"
          header="per_change"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "per_change",
      width: "60px",
      render: (_, { per_change }) => {
        const data = per_change.toFixed(2);

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {numberWithCommas(Number(data))} 
              </span>

              <span
                className="ff-lato"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "10px",

                  // color: `${redGreenColorPicker(symbol2[1],lightMode)}`,
                }}
              >
                {/* {(symbol2[1]) + "%"} */}
              </span>
            </div>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="52W High"
          header="high52w"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "high52w",
      width: "50px",
      render(text, record) {
        const data = text.toFixed(2);

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹{numberWithCommas(Number(data))}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="52W Low"
          header="low52w"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "low52w",
      width: "50px",
      render(text, record) {
        const data = text.toFixed(2);

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹{numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label={first.text}
          header={first.value}
          sortState={sortState}
          setsortState={setsortState}
        />
      ),

      // <p className="fs-s-16 fw-600 mb-0">{first.text}</p>,
      dataIndex: first.value,
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderLeft: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text, lightMode),
              }}
            >
              {text > 0 && "+"}
              {text.toFixed(2)}%
            </span>
          ),
        };
      },
      filterDropdown: (props) => {
        return (
          <div className={lightMode ? "bg-white" : "bg-dark-gray text-white"}>
            {props?.filters?.map(
              (data, i) =>
                data.text !== first.text && (
                  <p
                    key={i}
                    onClick={() => filterYearLowAndHigh(data)}
                    style={{ padding: "5px 10px" }}
                    className="fs-s-16 fw-600  mb-0 pointer"
                  >
                    {data.text}
                  </p>
                )
            )}
          </div>
        );
      },
      filters: [
        {
          text: "Change 52W Low %",
          value: "low52w_p",
        },
        {
          text: "Change 52W High %",
          value: "high52w_p",
        },
        { value: "ytd", text: "Change YTD%" },
      ],
      ellipsis: false,
    },
  ];

  //removing all duplicate objcets from array using filter method

  const datafortable = IndexData["results"]?.filter((items) => {
    const isDuplicate = uniquesymbuls.includes(items?.comp_name);

    if (!isDuplicate) {
      uniquesymbuls.push(items?.comp_name);

      return true;
    }
    return false;
  });
  const updateddata = [];

  // datafortable?.forEach((element) => {
  //   element.highytd =
  //     ((element?.curr_price - element?.high52w) / element?.high52w) * 100;
  //   element.lowytd =
  //     ((element?.curr_price - element?.low52w) / element?.low52w) * 100;
  // });

  const filterYearLowAndHigh = (props) => {
    setfirst({ value: props.value, text: props.text });
  };
  const colorCodeData = [
    {
      label: "Below -5%",
      bgColor: "rgb(161 18 18)",
      onFilter: (key) => key < -5,
    },
    {
      label: "-5  to -2 %",
      bgColor: "rgb(201, 30, 45)",
      onFilter: (key) => key < -2 && key >= -5,
    },
    {
      label: "-2  to 0 %",
      bgColor: "rgb(245, 128, 139)",
      onFilter: (key) => key < 0 && key >= -2,
    },
    {
      label: "0%",
      bgColor: "#858383",
      onFilter: (key) => key === 0,
    },
    {
      label: "0  to +2 %",
      bgColor: "rgb(119 191 138)",
      onFilter: (key) => key > 0 && key <= 2,
    },
    {
      label: `+2  to  +5 %`,
      bgColor: "#119F57",
      onFilter: (key) => key > 2 && key <= 5,
    },
    {
      label: "Above  +5%",
      bgColor: "rgb(2 122 46)",
      onFilter: (key) => key > 5,
    },
  ];
  const onStocksFilter = (index) => {
    setactiveRange(index);
    if (index === "NONE") {
      return setfilterdData(heatmapdata?.results);
    } else {
      const filterArr = heatmapdata?.results?.filter((item, i) =>
        colorCodeData[index]?.onFilter(item.per_change)
      );
      setfilterdData(filterArr);
    }
  };
  return (
    <div>
      {IndexData?.count !== 0 && (
        <>
          <div className="sub-table-news-data " id="Heat Map">
            <div
              style={{ margin: "30px 0px 0px 0px" }}
              className="d-flex justify-content-between align-items-center"
            >
              <h2 style={{ margin: "0px 0px" }} className="main-header-c ">
                Stock Heatmap
              </h2>
              {/* {heatmapdata?.count > 50 && ( */}
              <Link
                to={`/index/${indexname}/heatmap`}
                className="text-gray   underline  fs-s-12"
                style={{ textDecorationColor: "#AEAEAE" }}
              >
                View All
              </Link>
              {/* )} */}
            </div>
            <div className="d-flex justify-lg-end justify-md-start">
              <HeatmapRange labelData={colorCodeData} 
              onStocksFilter={onStocksFilter}
              activeRange={activeRange}/>
            </div>
            {filterdData?.length > 0 ? (
              <div
                className="scroll "
                style={{ overflowX: "scroll", minHeight: "18.5vh" }}
              >
                <HeatMap mapdata={filterdData} index={indexname} />
              </div>
            ) : (
              <div style={{ marginTop: "11vh" }}>
                <NoData errorText="No Stocks" />
              </div>
            )}
          </div>
          <div className="sub-table-news-data " id="Stock Constituents">
            <h2 className="main-header-c ">Stock Constituents</h2>
            <div
              className={`table-shadow  ${
                lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
              }`}
            >
              <CustomTable
                columns={StockConstituents1}
                // data={Indexconstitientstable}
                data={datafortable}
                scrollable={true}
                loading={Loading}
              />
            </div>
            <CustomPagination
              showQuickJumper
              defaultCurrent={1}
              size="small"
              pageSize={page?.perPage}
              current={page.num}
              // total={Math.round(IndexData && IndexData["count"] / 1.5)}
              total={IndexData?.count}
              // onChange={onChange}
              onChange={(num, pageSize) => {
                setPage({ num: num, perPage: pageSize });
                // setactivePage({ page: num, perPage: pageSize });
              }}
              lightMode={lightMode}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default StockConstituents;
