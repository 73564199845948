import { Slider } from "antd";
import Input from "antd/lib/input/Input";
import React, { useState } from "react";

function CalculatorSlider({
  lightMode,
  data,
  setSliderValues,
  SliderValues,
  i,
}) {
  const [inputValue, setinputValue] = useState();
  console.log("SliderValues",SliderValues[i]);
  const onChange = (e) => {
    setSliderValues({ ...SliderValues, [Object.keys(SliderValues)[i]]: e });
  };
  const onInputChange = (e) =>{
    setSliderValues({ ...SliderValues, [Object.keys(SliderValues)[i]]: e.target.value });
  }
  return (
    <div className="w-100  py-20">
      <div className=" w-100">
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 fs-s-16 fw-600">{data?.name}</p>
          <Input
            type="number"
            className={`w-25 h-40px auth-form-input  ${
              lightMode
                ? "bg-gray addbefor- email-input-login-page-light"
                : "bg-dark-black calc-input-login-page"
            }`}
            onChange={onInputChange}
            placeholder="Value"
            value={Object.values(SliderValues)[i]}
          ></Input>
        </div>
        <Slider
          min={1}
          max={data?.limit}
          className="Slide-stock-incresed"
          onChange={onChange}
          value={
            typeof Number(SliderValues.monthlyPrice) === "number"
              ? Number(Object.values(SliderValues)[i])
              : 0
          }
        />
      </div>
    </div>
  );
}

export default CalculatorSlider;
