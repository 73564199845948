import React from "react";
import { urlEncode } from "../../utilityFn/urlEncode";

function LeftBar({ lightMode, data, activeSector, navigate }) {
  return (
    <div
      className={`w-100  card-shadow detail-left-card ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
      style={{
        height: "min-content",
        marginBottom: "40px",
      }}
    >
      {data?.map((sector, i) => {
        const encodeStr=urlEncode(sector.industry)
     
        return (
          <div key={i}
            onClick={() => navigate(`${encodeStr}`)}
            className={`overFlow-text pointer fs-s-14 fw-500 mb-0 w-100 ${
              lightMode ? "hover-light-blue" : "bg-dark-hover"
            }`}
            style={{
              // overflow:'hidden',
              padding: "10px 16px",
              background:
                sector.industry === activeSector &&
                (lightMode
                  ? "rgba(211, 232, 251, 0.35)"
                  : "rgba(167, 182, 196, 0.24)"),
            }}
          >
            {sector.industry}
          </div>
        );
      })}
    </div>
  );
}

export default LeftBar;
