import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  affiliateHelpAPI,
  getAffiliateHelpCenter,
  postFeedbackApi,
} from "../../../../../api/fetchClient";
import { authStore } from "../../../../../redux/reducers/authSlice";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import FAQSection from "../../../../home/components/FAQSection";
import RequestSubmitPopup from "../../RequestSubmitPopup";

function AffiliateHelp() {
  const { userData } = useSelector(authStore);
  const [raiseIssue, setraiseIssue] = useState({ subject: "", file: null });
  const [Faq, setFaq] = useState();
  const [Model, setModel] = useState(false);
  const [responsePopup, setResponsePopup] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const { lightMode } = useSelector(getThemeMode);
  const [heplForm] = Form.useForm();

  useEffect(() => {
    getAffiliateHelpCenter().then((Res) => {
      setFaq(Res?.data);
    });
  }, []);
  const submitQuery = async () => {
    const data = new FormData();
    data.append("name", userData?.user?.username);
    data.append("email", userData?.user?.email);
    data.append("subject", userData?.user?.email);
    data.append("file", raiseIssue.file);
    data.append("message", raiseIssue.subject);
    // const data = {
    //   name: userData?.user?.username,
    //   email: userData?.user?.email,
    //   subject: "Affiliate Query",
    //   // type: "affiliate",
    //   file:raiseIssue.file,
    //   message: raiseIssue.subject,
    // };
    setbtnLoading(true);
    await affiliateHelpAPI(data)
      .then((resp) => {
        console.log(resp);
        setModel(false);
        setResponsePopup(true);
        setraiseIssue({subject:'',file:null});
        setbtnLoading(false);
      })
      .catch((err) => {
        setbtnLoading(false);
      });
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  return (
    <>
      <div className="mt-30 px-30-0">
        <div className="d-flex justify-content-between flex-wrap">
          <p className="fs-s-20 fw-500">We are here to help</p>
          <button
            onClick={() => setModel(true)}
            className="p-3 px-5 br-5 p-10 h-45 fw-500 d-flex align-items-center text-white btn-bg-primary "
          >
            Raise Request
          </button>
        </div>

        <div>
          <FAQSection data={Faq} lightMode={lightMode} isHTML={true} />
        </div>
        <Modal
          title={
            <p
              className={`fs-s-18 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Submit your Response
            </p>
          }
          visible={Model}
          centered
          wrapClassName={!lightMode && "modelClassname"}
          onCancel={() => {
            setModel(false);
          }}
          footer={[
            <>
              {btnLoading ? (
                <button
                  key="submit"
                  type="primary"
                  className={` w-150px br-5 fs-s-16 pointer fw-500 btn-bg-primary text-white ${
                    lightMode ? "  h-45" : "h-45 "
                  }`}
                >
                  Submitting...
                </button>
              ) : (
                <button
                  key="submit"
                  type="primary"
                  className={` w-150px br-5 fs-s-16 pointer fw-500 btn-bg-primary text-white ${
                    lightMode ? "  h-45" : "h-45 "
                  }`}
                  onClick={submitQuery}
                >
                  Submit
                </button>
              )}
            </>,
          ]}
        >
          <div className="w-100">
            <textarea
              name="message"
              width={"100%"}
              value={raiseIssue.subject}
              placeholder="Type Here"
              style={{ width: "100%", height: "140px", padding: "5px" }}
              onChange={(e) =>
                setraiseIssue({ ...raiseIssue, subject: e.target.value })
              }
              type="text"
              className={`w-100 h-45 br-3 p-3  ${
                lightMode
                  ? "border1px-light-mode"
                  : "border1px-dark-mode bg-dark-gray"
              }`}
            ></textarea>
            <div className="mt-20">
              <Upload
                beforeUpload={() => false}
                className={!lightMode && "ant-upload-dark"}
                listType="picture"
                // defaultFileList={null}
                // fileList={raiseIssue?.file ? [...raiseIssue?.file] : []}
                onChange={(event) => {
                  setraiseIssue({ ...raiseIssue, file: event.file });
                }}
                onRemove={(e) => setraiseIssue({ ...raiseIssue, file: e?.uid })}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </div>
            {/* {inValidInput && (
              <p style={{ color: redGreenColorPicker(-20, lightMode) }}>
                Invalid Value
              </p>
            )} */}
          </div>
        </Modal>
      </div>
      <RequestSubmitPopup
        visible={responsePopup}
        setVisible={setResponsePopup}
        lightMode={lightMode}
      />
    </>
  );
}

export default AffiliateHelp;
