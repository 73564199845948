import { Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  googleTapRedirect,
  postForgoPassword,
  postLoginData,
  postMailChimp,
  postReferAPI,
  postSignupData,
} from "../../api/fetchClient";
import { setAuth } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import PortSvgLogo from "../../assets/svg/svgSheet";
import search from "../../assets/images/LoginPage/search.png";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ResetPassWord from "./ResetPassWord";
import { LeftOutlined } from "@ant-design/icons";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import AccountActive from "./AccountActive";

function LoginAndSignUps({ setisLoginPage }) {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    setisLoginPage(true);
  }, []);
  useEffect(() => {
    if(searchParams.get('refer')){
     sessionStorage.setItem('referID',searchParams.get('refer'))
    }
   }, [])
  const [resetMail, setresetMail] = useState(false);
  const isUserRoute = useMatch("/signup");
  const isSetNewPassWord = useMatch("/confirm_password");
  const isEmailVerify = useMatch("/verify-email");
  const navigate = useNavigate();
  const params = useParams();
  const [mailChimp, setmailChimp] = useState(true)
  const urlState = useLocation();
  const { lightMode } = useSelector(getThemeMode);
  const [LoginOrSignup, setLoginOrSignup] = useState(
    isUserRoute === null ? "Login" : "Sign Up"
  );
  const [apiLoader, setapiLoader] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState();
  const [isForgetPassword, setisForgetPassword] = useState(false);
  const [apiError1, setapiError1] = useState({});
  const [isReferral, setIsReferral] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [ForgotValidation, setForgotValidation] = useState();
  const googleSignUp = () => {
    const url = googleTapRedirect(urlState.state ?? "/");
    window.location.href = url;
    sessionStorage.setItem('Mailchimp',mailChimp)
  };
  useEffect(() => {
    sessionStorage.setItem('Mailchimp',mailChimp)
  }, [mailChimp])
  

  const onSubmitSignup = async (values) => {
    setapiLoader(true);
    try {
      await postSignupData(values).then(async (resp) => {

        if(mailChimp){
          postMailChimp(values.email).then((res)=>{
            console.log(res,"mailChimp");
          })
        }
        const referID = sessionStorage.getItem("referID");
       
        if (referID) {
          await postReferAPI(referID, values.email)
            .then((resp) => {
              sessionStorage.removeItem("referID");
            })
            .catch((err) => console.log(err));
        }
        else if(values.Referral){
          await postReferAPI(values.Referral, values.email)
            .then((resp) => {
              console.log("done");
            })
            .catch((err) => console.log(err));
        }
        setapiError1({ success: resp.data.detail });
        setapiLoader(false);
      });
    } catch (error) {
      const errorMsg = Object.values(error.response.data);
      setapiError1(errorMsg[0]);
      setapiError1({ error: errorMsg[0] });
      setapiLoader(false);
    }
  };
  const resentMail = (value) => {
    setLoading1(true);
    postForgoPassword({ value })
      .then((res) => {
        setresetMail(true);
        setLoading1(false);
      })
      .catch((res) => {
        setLoading1(false);
        setForgotValidation(res?.response?.data?.error);
        console.log("res", { res });
      });
  };

  const onSubmit = async (values) => {
    setapiLoader(true);
    try {
      await postLoginData(values).then((resp) => {
        if(mailChimp){
          postMailChimp(values.email).then((res)=>{
            console.log(res,"mailChimp");
          })
        }
        dispatch(setAuth(resp.data));
        if (urlState.state) {
          navigate(urlState.state);
        } else {
          navigate("/");
        }
        setapiLoader(false);
        setisLoginPage(false);
        // onCloseHandler("NONE");
      });
    } catch (error) {
      const errorMsg = Object.values(error?.response?.data);
      console.log(errorMsg);
      setApiError(errorMsg[0]);
      setapiLoader(false);
    }
  };
  const LoginsArray = ["Login", "Sign Up"];
  return (
    <div className="ff-poppins  h-100vh-max-content relative ">
      {/* <div className="ff-poppins  h-100vh relative "> */}
      <div className="w-100 h-100 d-flex mobile-view-login-page">
        <div
          style={{ width: "60%" }}
          className={`only-PC-view  ${
            lightMode ? "left-login-section-light" : "left-login-section"
          }`}
        >
          <div style={{ margin: "60px 40px " }} className="p-40">
            <p
              className="pointer"
              onClick={() => {
                navigate("/");
                setisLoginPage(false);
              }}
            >
              {PortSvgLogo.PortSvgLogo}
            </p>
            <p className="fs-s-16  mt-40">
              Trade brains portal helps investors make efficient stock research
              and <br />
              analysis by providing quality fundamental data <br /> with
              insightful visuals.
            </p>
          </div>
        </div>
        <div
          // style={{height:"fit-content" }}
          className={`login-sign-up-div w-40-100 d-flex-col justify-content-center  p-40 ${
            lightMode ? "bg-gray" : "bg-dark-gray"
          }`}
        >
          {isSetNewPassWord?.pathname === "/confirm_password" ? (
            <ResetPassWord lightMode={lightMode} />
          ) : isForgetPassword ? (
            <Form
              autoComplete="off"
              //   className="w-60-80"
              form={form}
              name="login"
              onFinish={resentMail}
              scrollToFirstError
            >
              <p
                className={`fs-36-20 mb-0 fw-700 ${
                  lightMode ? " " : "text-white"
                }`}
              >
                Password Reset
              </p>

              {resetMail ? (
                <div
                  className={`${
                    lightMode ? " " : "text-white"
                  } mt-20 display-inline`}
                >
                  <p className="fs-s-16 mb-0 display-flex-inline">
                    We have{" "}
                    <p className="fw-700  mb-0 mr-5-0 ml-5-0  ">
                      {" "}
                      sent an email with link{" "}
                    </p>{" "}
                    to reset password
                  </p>
                  <p className="fs-s-16 ">It might take few minutes</p>

                  <div className="d-flex">
                    {" "}
                    <p className="mt-30 fs-s-16  d-flex">
                      Didn’t receive yet?{" "}
                      <p
                        onClick={() => setresetMail(false)}
                        className="text-btn-dark pointer fs-s-16 ml-5 fw-700"
                      >
                        Resend Again
                      </p>
                    </p>
                  </div>
                  <div>
                    <p className="fs-s-16 mb-0 ">
                      Please Check in spam section or Drop us an email at
                    </p>
                    <p className="fs-s-16 text-btn-dark  fw-700 ">
                    account@tradebrains.in
                    </p>
                  </div>
                </div>
              ) : (
                <Form.Item
                  style={{ margin: "15px 0px " }}
                  name="email"
                  // className={"email-input-login-page"}
                  className={`w-100 ${
                    lightMode
                      ? "bg-gray email-input-login-page-light light-input-login"
                      : "bg-dark-gray email-input-login-page"
                  } `}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please Enter your E-mail!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    //   height={"50px"}
                    style={{ height: "50px" }}
                    className={`w-100 ${
                      lightMode
                        ? "bg-gray email-input-login-page-light"
                        : "bg-dark-gray email-input-login-page"
                    } auth-form-input`}
                    placeholder="Email"
                    onChange={() => setForgotValidation("")}
                  />
                </Form.Item>
              )}
              <p style={{ color: redGreenColorPicker(-1, lightMode) }}>
                {ForgotValidation && ForgotValidation}
              </p>

              {apiError && (
                <div style={{ color: "#ff4d4f", textAlign: "center" }}>
                  {apiError}
                </div>
              )}
              {Loading1 ? (
                <div
                  style={{ margin: "6px 0px", height: "50px" }}
                  className="w-100 btn-bg-primary py-10 br-5 text-white center"
                >
                  Sending....
                </div>
              ) : (
                !resetMail && (
                  <div>
                    <button
                      type="submit"
                      style={{ margin: "6px 0px" }}
                      className="w-100 btn-bg-primary fw-500 py-10  h-50-42  br-5 text-white pointer"
                    >
                      Send Reset Link To Email
                    </button>

                    <p
                      onClick={() => setisForgetPassword(false)}
                      className={`pointer d-flex align-items-center justify-content-start ${
                        lightMode ? "text-black" : "text-white"
                      }`}
                    >
                      {" "}
                      <LeftOutlined />
                      Go Back{" "}
                    </p>
                  </div>
                )
              )}
            </Form>
          ) : (
            <div>
              <div>
                <div className="only-mobile-view">
                  <p
                    onClick={() => {
                      navigate("/");
                      setisLoginPage(false);
                    }}
                    className="login-mobile-logo"
                  >
                    {PortSvgLogo.PortSvgLogo}
                  </p>
                </div>
                <p className="fs-36-20 fw-700 mb-0 only-PC-view">
                  {" "}
                  Analyse With Us
                </p>
                {LoginOrSignup === "Login" ? (
                  <p className="fs-16-14  fw-500">
                    Welcome Back! <br />
                    Choose your way to connect with us
                  </p>
                ) : (
                  <p className="fs-16-14  fw-500">
                    Choose your way to connect with us
                  </p>
                )}
              </div>

              <div
                className={`d-flex pointer my-30-15 ${
                  lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
                }  `}
              >
                {LoginsArray?.map((items) => (
                  <p
                    onClick={() => {
                      setLoginOrSignup(items);
                      items === "Login"
                        ? navigate("/login")
                        : navigate("/signup");
                    }}
                    className={` mr-25  fw-700 mb-0 ml-5 ${
                      LoginOrSignup === items ? "bb-2-white" : "opacity5"
                    }`}
                  >
                    {items}
                  </p>
                ))}
              </div>
              {LoginOrSignup === "Login" ? (
                isForgetPassword ? (
                  <Form
                    autoComplete="off"
                    //   className="w-60-80"
                    form={form}
                    name="login"
                    onFinish={onSubmit}
                    scrollToFirstError
                  >
                    <p
                      className={`fs-36-20 fw-700 ${
                        lightMode ? " " : "text-white"
                      }`}
                    >
                      Password Reset
                    </p>

                    <Form.Item
                      style={{ margin: "15px 0px " }}
                      name="email"
                      // className="email-input-login-page"
                      className={`${
                        lightMode ? "light-input-login" : "Dark-input-login"
                      }`}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please Enter your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        //   height={"50px"}
                        style={{ height: "50px" }}
                        className={`w-100 ${
                          lightMode
                            ? "bg-gray email-input-login-page-light"
                            : "bg-dark-gray email-input-login-page"
                        } auth-form-input`}
                        placeholder="Email"
                      />
                    </Form.Item>

                    {apiError && (
                      <div style={{ color: "#ff4d4f", textAlign: "center" }}>
                        {apiError}
                      </div>
                    )}

                    {apiLoader ? (
                      <div
                        style={{ margin: "6px 0px", height: "50px" }}
                        className="w-100 btn-bg-primary py-10 br-5 text-white center"
                      >
                        Sending....
                      </div>
                    ) : (
                      <div>
                        <button
                          type="submit"
                          style={{ margin: "6px 0px" }}
                          className="w-100 btn-bg-primary fw-500 h-50-42   py-10 br-5 text-white pointer"
                        >
                          Send Reset Link To Email
                        </button>
                        <p> Go Back </p>
                      </div>
                    )}
                  </Form>
                ) : (
                  <Form
                    autoComplete="off"
                    //   className="w-60-80"
                    form={form}
                    name="login"
                    onFinish={onSubmit}
                    scrollToFirstError
                  >
                    <Form.Item
                      style={{ margin: "15px 0px " }}
                      name="email"
                      className={`${
                        lightMode ? "light-input-login" : "Dark-input-login"
                      }`}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please Enter your E-mail!",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        //   height={"50px"}
                        style={{ height: "40px" }}
                        className={`w-100 ${
                          lightMode
                            ? "bg-gray email-input-login-page-light"
                            : "bg-dark-gray email-input-login-page"
                        } auth-form-input`}
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      // hasFeedback
                    >
                      <Input.Password
                        type="text"
                        style={{ height: "40px" }}
                        className={`w-100 h-40px auth-form-input  ${
                          lightMode
                            ? "bg-gray input-password-login-light"
                            : "bg-dark-gray input-password-login-dark"
                        }`}
                        placeholder="Enter Password"
                      />
                    </Form.Item>
                    <p
                      style={{ margin: "12px 0px" }}
                      className={`ff-poppins  d-flex  justify-content-end  pointer ${
                        lightMode ? "text-black" : "text-white"
                      }`}
                      onClick={() => setisForgetPassword(true)}
                    >
                      Forgot Password?
                    </p>
                  <div className={ `mb-0 ${lightMode ? "text-black"  : "text-white checkbox-dark"} d-flex mt-5`}>

                    <Checkbox style={{ color: lightMode ? "black" : "white" }}  className={` mr-5`} value={mailChimp} defaultChecked onChange={(e)=>setmailChimp(e.target.checked)}  >
                 <p className="text-gray mb-0"> I accept to receive marketing & promotional Mails from Portal</p>
                  </Checkbox>
                  </div>
                    {apiError && (
                      <div style={{ color: "#ff4d4f", textAlign: "center" }}>
                        {apiError}
                      </div>
                    )}
                    {apiLoader ? (
                      <div
                        style={{ margin: "6px 0px" }}
                        className="w-100 btn-bg-primary  h-50-42 py-10 br-5 text-white center"
                      >
                        Logging in....
                      </div>
                    ) : (
                      <button
                        type="submit"
                        style={{ margin: "6px 0px" }}
                        className="w-100 btn-bg-primary fw-500 py-10 br-5  h-42 text-white pointer"
                      >
                        Login
                      </button>
                    )}
                  </Form>
                )
              ) : (
                <Form
                  // className="w-60-80"
                  form={form1}
                  name="register"
                  autoComplete="off"
                  onFinish={onSubmitSignup}
                  scrollToFirstError
                >
                  <Form.Item
                    style={{ marginBottom: "12px" }}
                    name="name"
                    className={`${
                      lightMode ? "light-input-login" : "Dark-input-login"
                    }`}
                    rules={[
                      {
                        type: "name",
                        message: "Please Enter your Name",
                      },
                      {
                        required: true,
                        message: "Please Enter your Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      className={`w-100 h-40px auth-form-input  ${
                        lightMode
                          ? "bg-gray email-input-login-page-light"
                          : "bg-dark-gray email-input-login-page"
                      }`}
                      placeholder="Name"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ margin: "6px 0px" }}
                    name="email"
                    className={`${
                      lightMode ? "light-input-login" : "Dark-input-login"
                    }`}
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please Enter your E-mail!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      className={`w-100 h-40px auth-form-input  ${
                        lightMode
                          ? "bg-gray email-input-login-page-light"
                          : "bg-dark-gray email-input-login-page"
                      }`}
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    name="password1"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            (value.length >= 8 && value.length <= 16)
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "Password should have minimum 8 and maximum 16 characters"
                            )
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    {/* <div className='w-100 relative d-flex align-items-center '> */}
                    <Input.Password
                      type="text"
                      className={`w-100 h-40px auth-form-input  ${
                        lightMode
                          ? "bg-gray input-password-login-light"
                          : "bg-dark-gray input-password-login-dark"
                      }`}
                      placeholder="Enter Password"
                    />
                    {/* <span style={{right:'10px'}}  className='ff-rubik absolute text-skyblue fw-500 pointer'>Show</span> */}
                    {/* </div> */}
                  </Form.Item>
                  <Form.Item
                    style={{ marginBottom: "0px", marginTop: "7px" }}
                    name="password2"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password1") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    {/* <div className='w-100 relative d-flex align-items-center '> */}
                    <Input.Password
                      type="text"
                      className={`w-100 h-40px auth-form-input  ${
                        lightMode
                          ? "bg-gray input-password-login-light"
                          : "bg-dark-gray input-password-login-dark"
                      }`}
                      placeholder="Confirm Password"
                    />
                    {/* <span style={{right:'10px'}}  className='ff-rubik absolute text-skyblue fw-500 pointer'>Show</span>
  </div> */}
                  </Form.Item>
                  {!isReferral && <p onClick={()=>setIsReferral(true)} className={`${lightMode ? "text-gray":"text-white"}  mb-0 pointer d-flex justify-content-end`} >Got Referral Code ?</p>}
                 {isReferral && <Form.Item
                    style={{ marginBottom: "0px", marginTop: "7px" }}
                    name="Referral"
                    // dependencies={["password"]}
                    hasFeedback
                  
                  >
                    {/* <div className='w-100 relative d-flex align-items-center '> */}
                    <Input
                      type="text"
                      className={`w-100 h-40px auth-form-input  ${
                        lightMode
                          ? "bg-gray input-password-login-light"
                          : "bg-dark-gray input-password-login-dark"
                      }`}
                      placeholder="Referral Code(Optional)"
                    />
                    {/* <span style={{right:'10px'}}  className='ff-rubik absolute text-skyblue fw-500 pointer'>Show</span>
  </div> */}
                  </Form.Item>}
                  {apiError1?.success && (
                    <div style={{ color: "#28a745", textAlign: "center" }}>
                      {apiError1?.success}
                    </div>
                  )}
                  {apiError1.error && (
                    <div style={{ color: "#ff4d4f", textAlign: "center" }}>
                      {apiError1.error}
                    </div>
                  )}
                  <div className={ `mb-0 ${lightMode ? "text-black"  : "text-white checkbox-dark"} d-flex mt-5`}>
                <Checkbox style={{ color: lightMode ? "black" : "white" }}  className={` mr-5`} value={mailChimp} defaultChecked onChange={(e)=>setmailChimp(e.target.checked)}  >
                 <p className="text-gray mb-0"> I accept to receive marketing & promotional Mails from Portal</p>
                  </Checkbox>
                </div>
                  <button
                    type="submit"
                    className="w-100 btn-bg-primary py-10 br-5 my-20-10 d-flex  h-42  align-items-center fw-500 text-white text-center"
                  >
                    {apiLoader ? "Loading" : "Sign Up"}
                  </button>
                </Form>
              )}
              <div>
                <p className="d-flex my-20-10 mb-0 justify-content-center">
                  or
                </p>
              </div>
              <div
                id="buttonDiv"
                onClick={googleSignUp}
                style={{ height: "42px" }}
                className={` pointer ${
                  lightMode ? "bg-light-mode-green" : "bg-dark"
                } br-5     d-flex align-items-center justify-content-center h-40     `}
              >
                <img className="mr-15" width={20} src={search}></img>

                <p className="mb-0">{LoginOrSignup} With Google </p>
              </div>
              {/* <div
            style={{ height: "50px" }}
            className={` pointer mt-10 ${
              lightMode ? "bg-light-mode-green" : "bg-dark"
            } br-5     d-flex align-items-center justify-content-center h-40     `}
          >
            <img className="mr-15" width={20} src={apple}></img>
            <p className="mb-0">{LoginOrSignup} With Apple</p>
          </div> */}
            </div>
          )}
          <div className="d-flex align-items-center fs-s-12 text-center mt-40 justify-center-between flex-col">
            <p className="px-60-0">
              By signing up, you have read and agreed to our Privacy Policy and
              Terms & Conditions.
            </p>
            <p
              className={`fs-s-20 mb-0 fw-700 ${
                lightMode ? "clr-light-lightblue" : "text-dark"
              }`}
            >
              By Tradebrains
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginAndSignUps;
