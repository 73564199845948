import React from 'react'

function HeatmapRange({labelData,onStocksFilter,activeRange}) {
  return (
    <div className="flex   flex-wrap my-20">
    {labelData?.map((item, i) => (
      <button
        style={{
          width: "75px",
          height: "30px",
          borderRadius: "10px",
          background: item.bgColor,
        }}
        key={i}
        className={`d-flex div-items-center fs-s-12 mb-0 text-white  ${activeRange===i?'range-btn-scale':''}`}
        onClick={()=>activeRange===i?onStocksFilter("NONE"):onStocksFilter(i)}
      >
        {item.label}
      </button>
    ))}
  </div>
  )
}

export default HeatmapRange