import React from "react";
import svgSheet from "../../../../../assets/svg/svgSheet";

function JoinCard({lightMode,data}) {
  return (
    <div
      style={{ maxWidth: "360px", height: "315px",padding:'25px 20px' }}
      className={` d-flex flex-col  ${
        lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <div>{data.icon}</div>
      <div style={{margin:'10% 0%'}}>
        <p className="fs-s-18 mb-0 ">Step {data.key}</p>
        <h1 className="text-blue-grad fs-s-28 fw-700">{data.head}</h1>
      </div>
      <p>
       {data.desc}
      </p>
    </div>
  );
}

export default JoinCard;
