import React from "react";
import CustomTable from "../../components/CustomTable";
import BalanceSheetGraph from "./BalanceSheetGraph";
import BankBalanceSheetGraph from "./BankBalanceSheetGraph";

function BalanceSheetGraphTable({
  balanceSheetTable,
  lightMode,
  notBankStock,
}) {
  return (
    <div className="pros-and-cons d-flex d-flex-col">
      <h2 className="main-header mb-20">Balance Sheet</h2>
      {notBankStock === "BANK" ? (
        <BankBalanceSheetGraph
          balanceSheetTable={balanceSheetTable}
          lightMode={lightMode}
        />
      ) : (
        <BalanceSheetGraph
          balanceSheetTable={balanceSheetTable}
          lightMode={lightMode}
        />
      )}

      <p className=" d-flex justify-content-end fs-s-12 text-gray">
        *Values in Cr.
      </p>
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
        scrollLimit={700}
          columns={balanceSheetTable?.YearHeaders}
          scrollable={true}
          data={balanceSheetTable?.tableBodyData}
          border={false}
        />
      </div>
    </div>
  );
}

export default BalanceSheetGraphTable;
