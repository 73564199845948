import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";

import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function BrokerageCalculator() {
  const { lightMode } = useSelector(getThemeMode);
  const [GetRsult, setGetRsult] = useState([]);

  const navigator = useNavigate();
  const formlist = [
    { name: "buy", value: "Buy" },
    { name: "sell", value: "Sell" },
    { name: "qnty", value: "Quantity" },
    {
      value: "Trade type",
      name: "tradeType",
      options: [{name:"Intraday",value:"intraday"}, {name:"Delivery",value:"delivery"}, {name:"Futures",value:"future"}, {name:"Options",value:"options"}],
    },
    {
      value: "Stock Broker",
      name: "broker",
      options: [{name:"Zerodha",value:0},{name:"Upstox",value:1}, {name:"Angelone",value:2}, {name:"5Paisa",value:3}, {name:"Trade Smart",value:4}],
    },
      
  ];
  const Listdata = [
    {
      delivery: {
        brokerage: 0,
        sttTotal: 0.1,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.015,
      },

      //Zerodha
      intraday: {
        brokerage: 0.03,
        sttTotal: 0.025,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.003,
      },
      future: {
        brokerage: 0.03,
        sttTotal: 0.01,
        nseCharge: 0.002,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.002,
      },
      options: {
        brokerage: 20,
        sttTotal: 0.05,
        nseCharge: 0.053,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.003,
      },
    },
    {
      delivery: {
        brokerage: 2.5,
        sttTotal: 0.1,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 5,
        stampCharge: 0.015,
      },

      ///for equity delivery demat transaction charge 18.5
      intraday: {
        brokerage: 0.05,
        sttTotal: 0.025,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 5,
        stampCharge: 0.003,
      },

      ///Upstox
      future: {
        brokerage: 0.05,
        sttTotal: 0.01,
        nseCharge: 0.002,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 5,
        stampCharge: 0.002,
      },
      options: {
        brokerage: 20,
        sttTotal: 0.05,
        nseCharge: 0.053,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 5,
        stampCharge: 0.003,
      },
    },
    {
      delivery: {
        brokerage: 0,
        sttTotal: 0.1,
        nseCharge: 0.00335,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.015,
      },

      ///for equity delivery demat transaction charge 20
      intraday: {
        brokerage: 0.25,
        sttTotal: 0.025,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.003,
      },

      ///Angelone
      future: {
        brokerage: 0.25,
        sttTotal: 0.01,
        nseCharge: 0.00195,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.002,
      },
      options: {
        brokerage: 0.25,
        sttTotal: 0.05,
        nseCharge: 0.053,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 10,
        stampCharge: 0.003,
      },
    },
    {
      delivery: {
        brokerage: 0,
        sttTotal: 0.1,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.015,
      },

      ///for equity delivery demat transaction charge 20
      intraday: {
        brokerage: 0.25,
        sttTotal: 0.025,
        nseCharge: 0.00345,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.003,
      },

      ///5paisa
      future: {
        brokerage: 0.25,
        sttTotal: 0.01,
        nseCharge: 0.00195,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.002,
      },
      options: {
        brokerage: 0.25,
        sttTotal: 0.05,
        nseCharge: 0.053,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.001,
      },
    },
    {
      delivery: {
        brokerage: 15,
        sttTotal: 0.001,
        nseCharge: 0.000035,
        bseCharge: 0.000035,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.015,
      },

      intraday: {
        brokerage: 15,
        sttTotal: 0.000035,
        nseCharge: 0.000035,
        bseCharge: 0.00345,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.003,
      },

      ///trade smart
      future: {
        brokerage: 15,
        sttTotal: 0.0001,
        nseCharge: 0.000024,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.002,
      },
      options: {
        brokerage: 15,
        sttTotal: 0.00005,
        nseCharge: 0.000024,
        bseCharge: 0,
        gst: 18,
        sebiCharge: 0.0001,
        stampCharge: 0.003,
      },
    },
  ];

  const [buy, setBuy] = useState();
  const [sell, setSell] = useState();
  const [qnty, setQnty] = useState();
  const [nse, setNse] = useState("nse");
  const [profit, setProfit] = useState(0);
  const [turnover, setTurnover] = useState(0);
  const [brokge, setBrokge] = useState(0);
  const [gst, setGst] = useState(0);
  const [stmp, setStmp] = useState(0);
  const [exchge, setExchge] = useState(0);
  const [sebic, setSebic] = useState(0);
  const [total, setTotal] = useState(0);
  const [err, setErr] = useState(false);
  const [broker, setBroker] = useState(Listdata?.[0]);
  const [tradeType, setTradetype] = useState(broker?.intraday);
  const [ttype, setTtype] = useState("intraday");
  const [sstt, setSstt] = useState(0);

useLayoutEffect(() => {
  if (GetRsult["broker"] !== undefined) {
    GetRsult?.broker && setTradetype(Listdata[GetRsult?.broker][GetRsult?.tradeType])
    console.log("tradeType",GetRsult && Listdata[GetRsult["broker"]][GetRsult["tradeType"]],GetRsult["broker"],GetRsult?.tradeType);
    setTtype(GetRsult?.tradeType)
    console.log("thisisform",Listdata[GetRsult?.broker]);
    setBroker(Listdata[GetRsult?.broker])

  }
  
}, [GetRsult])

const bottomData = [{
  header:"What is a Brokerage Calculator?",
  body:"A brokerage calculator is an online calculator made available for traders to make it simple and easy for them to calculate the brokerage on the trade even before they enter into a trade. The Brokerage calculator is very useful as it also computes Transaction fees, GST, STT, and Stamp duty fees. So the trader is aware of the exact amount of money that they should be making to be profitable or break even in the particular trade. The brokerage calculator enables smart and quick thinking to be able to understand the cost involved without actually entering into the trade."
},]

  useEffect(() => {
if (GetRsult["broker"] !== undefined) {
    const tradeType = Listdata[GetRsult["broker"]][GetRsult["tradeType"]]
    let sstTotal;
    if (GetRsult["tradeType"] === "intraday") {
      sstTotal = (tradeType?.sttTotal / 100) * (Number(GetRsult["sell"]) * Number(GetRsult["qnty"]));
      setSstt(sstTotal);
      console.log("rendered",sstTotal);
    } else if (GetRsult["tradeType"] === "delivery") {
      sstTotal =
        (tradeType?.sttTotal / 100) * (Number(GetRsult["buy"]) * Number(GetRsult["qnty"])) +
        (tradeType?.sttTotal / 100) * (Number(GetRsult["sell"]) * Number(GetRsult["qnty"]));
      setSstt(sstTotal);
    } else if (GetRsult["tradeType"] === "future") {
      sstTotal = (tradeType?.sttTotal / 100) * (Number(GetRsult["sell"]) * Number(GetRsult["qnty"]));
      setSstt(sstTotal);
    } else if (GetRsult["tradeType"] === "options") {
      sstTotal = (tradeType?.sttTotal / 100) * (Number(GetRsult["sell"]) * Number(GetRsult["qnty"]));
      setSstt(sstTotal);
    }
    // Turn Over
    let turnover = Number(GetRsult["buy"]) * Number(GetRsult["qnty"]) + Number(GetRsult["sell"]) * Number(GetRsult["qnty"]);
    setTurnover(turnover);
    console.log("turnover",turnover);
    // Brokerage
    let brokerage = turnover * (tradeType?.brokerage / 100);
    console.log("Brokerage",brokerage);
    if (tradeType?.brokerage === 15) {
      brokerage = 30;
      setBrokge(brokerage);
    } else if (brokerage > 40) {
      brokerage = 40;
      setBrokge(brokerage);
    } else {
      setBrokge(brokerage);
    }

    // Exchange
    let exchangeCharge;
    if (nse === "nse") {
      exchangeCharge = (tradeType?.nseCharge / 100) * turnover;
      setExchge(exchangeCharge);
    } else {
      exchangeCharge = (tradeType?.bseCharge / 100) * turnover;
      setExchge(exchangeCharge);
    } 
    // GST
    let getgst = (tradeType?.gst / 100) * (brokerage + exchangeCharge);
    setGst(getgst);

    // SEBI CHARGES
    let sebiCharge = (tradeType?.sebiCharge / 10000000) * turnover;
    setSebic(sebiCharge);

    // Stamp Duty
    let stampDuty = Number(GetRsult["buy"]) * Number(GetRsult["qnty"]) * (tradeType?.stampCharge / 100);
    setStmp(stampDuty);

    // Total tax
    let totalTax =
      Number(brokerage) +
      Number(sstTotal) +
      Number(exchangeCharge) +
      Number(getgst) +
      Number(sebiCharge) +
      Number(stampDuty);
    setTotal(totalTax);
    console.log("THIS FROM TOTAL",brokerage,
    sstTotal,
    exchangeCharge,
    getgst,
    sebiCharge,
    stampDuty,
    totalTax,);

    let netProfit = GetRsult["sell"] * GetRsult["qnty"] - GetRsult["buy"] * GetRsult["qnty"] - totalTax;
    setProfit(netProfit);
  }
  }, [GetRsult])
  
  const resetValues = () =>{
    setTurnover(0)
setBrokge(0)
setGst(0)
setStmp(0)
setExchge(0)
setSebic(0)
setTotal(0)
setProfit(0)
setSstt(0)
  }

  console.log("GetRsult", brokge);
  const ResultHeder =[{
    "Net Profit ":Number(profit).toFixed(2)
  }]

  const valueData = [
    { key: "Turnover", value: Number(turnover).toFixed(2) },
    { key: "Brokerage", value: Number(brokge).toFixed(2) },
    { key: "SST Total", value: Number(sstt).toFixed(2) },
    { key: "Exchange tnc charge", value: Number(exchge).toFixed(2) },
    { key: "GST", value: Number(gst)?.toFixed(2) },
    { key: "SEBI charges", value: Number(sebic)?.toFixed(2) },
    { key: "Stamp Duty", value: Number(stmp)?.toFixed(2)},
    { key: "Total tax and charges", value: Number(total)?.toFixed(2) },
  ];
  return (
    <div>
      <div>
        <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
          <div className="my-30">
            <p className="pointer mb-0" onClick={() => navigator(-1)}>
              Calculators {">"} Brokerage Calculator
            </p>
            <p className="mb-0 fs-30-20 fw-700">Brokerage Calculator</p>
            <p>
              This is an intrinsic value calculator used by ace Investor Mohnish
              Pabrai for the purpose of picking the right stock for investing
              purposes.
            </p>
          </div>
          <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
            <div>
              <CalcInputFeilds
                setGetRsult={setGetRsult}
                formlist={formlist}
                lightMode={lightMode}
                setNse={setNse}
                resetValues={resetValues}
                nse={nse}
              />
            </div>
            <div>
              <ResultContainerCalc
                resultLise={valueData}
                ResultHeder={ResultHeder}
                lightMode={lightMode}
              />
            </div>
          </div>
          <div>
            <BottomDataSection bottomData={bottomData}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrokerageCalculator;
