import React from "react";
import stockGraphLight from "../../../assets/images/home/all stocks white theme.png";
import stockGraphDark from "../../../assets/images/home/All stocks dark theme.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function RedirectAllStocks({ lightMode }) {
  return (
    <section className="max-w flex flex-col-row mx-auto px-15 my-100 w-100">
      <div className="w-100-60">
        <img
          className="mx-auto"
          src={lightMode ? stockGraphLight : stockGraphDark}
          alt="img"
        />
      </div>
      <div className="w-100-40 d-flex flex-col justify-content-center align-md-center">
        <p className="fs-16-14 mb-0 text-md-center">
          Make better-investing decisions by browsing through your favourite
          companies listed on BSE and NSE!
        </p>
        <h3 className="fs-40-32 fw-700 my-20">4,000+ Stocks</h3>
        <Link to={`/stock/industry/Abrasives`}>
          <button
            style={{ width: "200px", height: "50px" }}
            className="btn-bg-primary  fw-600 text-white fs-16-14 br-5"
          >
            Explore Stocks <ArrowRightOutlined />
          </button>
        </Link>
      </div>
    </section>
  );
}

export default RedirectAllStocks;
