import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/PortalLogo.png";
import logoWhite from "../../assets/images/logo/logoWhite.png";
import "antd/dist/antd.css";
import ProductToggleCard from "./ProductToggleCard";
import ToolsTogglecard from "./ToolsTogglecard";
import svgSheet from "../../assets/svg/svgSheet";
import MobileSideBar from "./MobileSideBar";
import MobileSearch from "./MobileSearch";
import { Link, useNavigate } from "react-router-dom";
import JoinPortal from "../popups/JoinPortal";
import Signup from "../popups/Signup";
import Login from "../popups/Login";
import ForgotPassword from "../popups/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { authStore, setAuth } from "../../redux/reducers/authSlice";
import { Dropdown, Menu } from "antd";
import { LogoutOutlined, UpOutlined } from "@ant-design/icons";
import GoogleLogout from "../popups/GoogleLogout";
import { checkFingradAccess, getSearchData } from "../../api/fetchClient";
import { getThemeMode, toggleThemeMode } from "../../redux/reducers/ThemeSlice";
import NavComponent from "./NavComponent";
import NavToWatchList from "../NavToWatchList";
import SearchComponent from "./SearchComponent";
import { resetWatchlistArr } from "../../redux/reducers/watchListSlice";
import fingradLogo from "../../assets/images/Fingrad.png";
function NavBar({ MobileSearchDrop, setMobileSearchDrop }) {
  const navigate = useNavigate();
  const searchTrigger = useSelector((state) => state.ActiveSearch);
  const dispatch = useDispatch();
  const auth = useSelector(authStore);
  const { lightMode } = useSelector(getThemeMode);
  const [inputData, setinputData] = useState("");
  const [apiData, setapiData] = useState([]);
  const [PopupStatus, setPopupStatus] = useState("NONE");
  const [toggleStatus, setToggleStatus] = useState("NONE");
  const [WatchListTrigger, setWatchListTrigger] = useState(false);
  const [fingradAccessStatus, setfingradAccessStatus] = useState({
    url: false,
  });
  const getFinCourseAccess = async () => {
    await checkFingradAccess(auth?.userData?.user?.email)
      .then((resp) => {
        setfingradAccessStatus(resp.data);
      })
      .catch((err) => setfingradAccessStatus({ url: null }));
  };

  useEffect(() => {
    if (auth?.userData?.user?.email) getFinCourseAccess();
  }, [auth]);

  useEffect(() => {
    MobileSearchDrop && setToggleStatus("SEARCH");
  }, [MobileSearchDrop]);
  const userLogout = () => {
    dispatch(setAuth({}));
    dispatch(resetWatchlistArr());
    navigate("/");
  };

  //  icon dropdown after login
  const menu = (
    <Menu
      className={`ff-poppins ${
        lightMode ? "menu-DropDwon-light" : "menu-DropDwon-dark bg-dark-black "
      } `}
      style={{ width: "250px", height: "auto" }}
      items={[
        // {
        //   label: <h6 className="fs-s-16 ">Account</h6>,
        //   // key: '0',
        // },
        // {
        //   type: "divider",
        // },
        {
          label: (
            <Link to="/profile" className="flex align-items-center">
              {auth?.userData?.user?.profile_pic ? (
                <img
                  src={auth?.userData?.user?.profile_pic}
                  style={{ height: "30px", width: "30px" }}
                  className=" br-50 d-flex pointer"
                />
              ) : (
                <li
                  style={{ height: "30px", width: "30px" }}
                  className="avatar-img  br-50 d-flex pointer"
                ></li>
              )}
              <div>
                <h5 className="fs-s-14 fw-700">
                  {auth?.userData?.user?.first_name}
                </h5>
                <h5
                  style={{ overflow: "hidden", width: "180px" }}
                  className="fs-12 "
                >
                  {auth?.userData?.user?.email}
                </h5>
              </div>
            </Link>
          ),
          key: "1",
        },
        // {
        //   label: (
        //     <Link to='/Dashboard'  className="flex align-items-center">
        //       {auth?.userData?.user?.profile_pic ? (
        //                   <div style={{width:'24px',height:'24px'}}>{svgSheet.DashBoradIcon}</div>

        //       ) : (
        //         ""
        //       )}
        //       <div>
        //         {/* <h5 className="fs-s-14">{auth?.userData?.user?.first_name}</h5> */}
        //         <h5 className="fs-s-14 fw-700">Dashboard</h5>
        //       </div>
        //     </Link>
        //   ),
        //   key: "2",
        // },
        auth?.userData?.user?.accountType !== "admin"
          ? auth?.userData?.user?.is_affiliate && {
              label: (
                <Link
                  to="/affiliate/dashboard"
                  className="flex align-items-center"
                >
                  <div style={{ width: "24px", height: "24px" }}>
                    {svgSheet.affiliateIcon}
                  </div>
                  <h5 className="fs-s-14 fw-700">Affiliate</h5>
                </Link>
              ),
              key: "7",
            }
          : {
              label: (
                <Link to="/admin/dashboard" className="flex align-items-center">
                  <div style={{ width: "24px", height: "24px" }}>
                    {svgSheet.affiliateIcon}
                  </div>

                  <h5 className="fs-s-14 fw-700">Admin</h5>
                </Link>
              ),
              key: "7",
            },
        auth?.userData?.user?.plan?.sname === "Whales" && {
          label: (
            <div
              onClick={() => {
                return fingradAccessStatus.url !== false &&
                  fingradAccessStatus.url !== null
                  ? window.open(fingradAccessStatus?.url)
                  : fingradAccessStatus.url === null
                  ? window.open("https://joinfingrad.com/")
                  : navigate("/joinfingrad");
              }}
              className="flex align-items-center"
            >
              <div style={{ width: "24px", height: "24px" }}>
                <img src={fingradLogo} alt="fingrad" />
              </div>
              <h5 className="fs-s-14 fw-700">
                {fingradAccessStatus.url === null
                  ? "Learn on Fingrad"
                  : "Fingrad Access"}
              </h5>
            </div>
          ),
          key: "8",
        },
        auth?.userData?.user?.is_premium
          ? {
              label: (
                <Link to="/premium-support" className="flex align-items-center">
                  <div style={{ width: "24px", height: "24px" }}>
                    {svgSheet.premiumIcon}
                  </div>
                  <h5 className="fs-s-14 fw-700">Premium Support</h5>
                </Link>
              ),
              key: "4",
            }
          : {
              label: (
                <Link to="/contactus" className="flex align-items-center">
                  <div style={{ width: "24px", height: "24px" }}>
                    {svgSheet.contactUsIcon}
                  </div>
                  <h5 className="fs-s-14 fw-700">Contact Us</h5>
                </Link>
              ),
              key: "6",
            },
        // {
        //   label: (
        //     <Link to='/profile' className="fs-s-14 fw-500 flex">
        //       <UserOutlined className="mx-10" style={{ fontSize: "25px" }} />
        //       <span>Profile</span>
        //     </Link>
        //   ),
        //   key: "3",
        // },
        {
          label: (
            <div className="fs-s-14 fw-500 flex" onClick={userLogout}>
              {/* <div style={{width:'24px',height:'24px'}}>{svgSheet.logoutIcon}</div> */}
              <LogoutOutlined className="mx-10" style={{ fontSize: "23px" }} />
              {/* <span>Logout</span> */}
              <GoogleLogout />
            </div>
          ),
          key: "5 ",
        },
      ]}
    />
  );
  // send search input API
  const getResults = async (e) => {
    setinputData(e);

    const response =
      (await !WatchListTrigger) &&
      getSearchData(e)
        .then((resp) => {
          setapiData(resp.data);
        })
        .catch((err) => console.log(err));
  };

  const openCloseToggle = (current) => {
    if (current === toggleStatus) {
      setToggleStatus("NONE");
      setMobileSearchDrop(false);
    } else {
      setToggleStatus(current);
    }
  };

  const dropdowndata = [];

  const renderItem = (title, symbol, type) => ({
    value: symbol,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "50px",
        }}
        className="align-items-center"
      >
        <div
          onClick={() => navigate(`/stock/${symbol}/consolidated`)}
          className="d-flex-col ml-5"
        >
          <div className="fw-500">{title}</div>
          <div
            style={{ color: "#6DB8FD", fontWeight: "500px" }}
            className="btn- fw-500"
          >
            {symbol}
          </div>
        </div>
        {type === "stock" && (
          <NavToWatchList
            symbol={[symbol]}
            setWatchListTrigger={setWatchListTrigger}
          />
        )}
      </div>
    ),
    array: [type, title],
  });

  const uniqdataarrray = [];
  apiData.map((items) => {
    !uniqdataarrray.includes(items.symbol) &&
      dropdowndata.push(renderItem(items.company, items.symbol, items?.type));
    uniqdataarrray.push(items.symbol);
  });

  // search result div
  const searchResultDiv = apiData?.map((stock, i) => (
    <div
      key={i}
      className={`d-flex align-items-center justify-content-between ${
        lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
      }`}
    >
      <NavComponent stock={stock} setinputData={setinputData} />
    </div>
  ));
  // toggle popup
  const selectPopup = (key) => {
    switch (key) {
      case "SIGNUP":
        return (
          <Signup popupStatus={PopupStatus} onCloseHandler={setPopupStatus} />
        );
      case "JOIN":
        return (
          <JoinPortal
            popupStatus={PopupStatus}
            onCloseHandler={setPopupStatus}
          />
        );
      case "LOGIN":
        return (
          <Login popupStatus={PopupStatus} onCloseHandler={setPopupStatus} />
        );
      case "FORGOT":
        return (
          <ForgotPassword
            popupStatus={PopupStatus}
            onCloseHandler={setPopupStatus}
          />
        );
      default:
        return <div></div>;
        break;
    }
  };

  return (
    <div
      className={`${
        lightMode ? "bg-white" : "bg-dark-black"
      } card-shadow-black`}
      onClick={() => {
        toggleStatus === "Tools"
          ? openCloseToggle("Tools")
          : toggleStatus === "Products" && openCloseToggle("Products");
      }}
    >
      <div className={` w-100  ${toggleStatus === "NONE" ? "shadow-b" : ""} `}>
        <nav className="h-75 px-15   max-w mx-auto  d-flex align-items-center">
          {/* logo  */}
          <Link to={"/"}>
            <img
              src={lightMode ? logo : logoWhite}
              className="header-logo"
              alt="logo"
            />
          </Link>
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="only-PC-view w-100">
              <div className="d-flex w-100">
                <SearchComponent
                  lightMode={lightMode}
                  setMobileSearchToggle={setToggleStatus}
                  planData={auth}
                />
              </div>
            </div>
            <ul className="nav-list m-0 flex ff-poppins fw-500 list-style-none justify-content-between align-items-center">
              <li
                className="d-flex pointer align-items-center"
                // onClick={() => openCloseToggle("Tools")}
              >
                {lightMode ? (
                  <span
                    style={{ marginTop: "7px" }}
                    onClick={() => dispatch(toggleThemeMode(false))}
                  >
                    {svgSheet.LightModeMoon}
                  </span>
                ) : (
                  <span
                    style={{ marginTop: "7px" }}
                    onClick={() => dispatch(toggleThemeMode(true))}
                  >
                    {svgSheet.DarkModeSun}
                  </span>
                )}
                {/* <img
                className="ml-5"
                src={toggleStatus === "Tools" ? upArrowIcon : downArrow}
                alt="icon"
              /> */}
              </li>

              <li
                className="d-flex pointer align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  openCloseToggle("Tools");
                }}
              >
                <span className="mr-5">Features</span>
                {/* <img
                className="ml-5"
                src={toggleStatus === "Tools" ? upArrowIcon : downArrow}
                alt="icon"
              /> */}
                {toggleStatus === "Tools" ? (
                  <UpOutlined className="ml-5" style={{ fontSize: "13px" }} />
                ) : (
                  <UpOutlined
                    rotate={180}
                    className="ml-5"
                    style={{ fontSize: "13px" }}
                  />
                )}
              </li>
              <li
                className="d-flex pointer align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  openCloseToggle("Products");
                }}
              >
                <span className="mr-5">Market</span>
                {toggleStatus === "Products" ? (
                  <UpOutlined className="ml-5" style={{ fontSize: "13px" }} />
                ) : (
                  <UpOutlined
                    rotate={180}
                    className="ml-5"
                    style={{ fontSize: "13px" }}
                  />
                )}
                {/* <img
                className="ml-5"
                src={toggleStatus === "Products" ? upArrowIcon : downArrow}
                alt="icon"
              /> */}
              </li>
              {!auth?.userData?.user?.is_premium && (
                <li
                  onClick={() => navigate("/getpremium")}
                  className="d-flex pointer mt-5"
                >
                  <p className="mr-5 mb-0 " style={{ marginTop: "2px" }}>
                    {svgSheet.NavBarPremiumIcon}
                  </p>{" "}
                  Pricing
                </li>
              )}
              {auth.userData.access_token ? (
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="fixed"
                >
                  {auth?.userData?.user?.profile_pic ? (
                    <img
                      src={auth.userData.user.profile_pic}
                      className=" h-45 w-45 br-50 d-flex pointer"
                    />
                  ) : (
                    <li className="avatar-img h-45 w-45 br-50 d-flex pointer">
                      {/* {(auth.userData.user.first_name).charAt(0)} */}
                    </li>
                  )}
                </Dropdown>
              ) : (
                <li
                  // onClick={() => setPopupStatus("JOIN")}
                  onClick={() => navigate("/login")}
                  style={{ width: "76px", height: "35px" }}
                  className="btn-bg-primary text-white content-center br-5 pointer"
                >
                  Login
                </li>
              )}
            </ul>
            <div className="burger-menu flex align-items-center">
              <li
                className="d-flex pointer  align-items-center"
                // onClick={() => openCloseToggle("Tools")}
              >
                {lightMode ? (
                  <span
                    style={{ marginTop: "7px" }}
                    onClick={() => dispatch(toggleThemeMode(false))}
                  >
                    {svgSheet.LightModeMoon}
                  </span>
                ) : (
                  <span
                    style={{ marginTop: "7px" }}
                    onClick={() => dispatch(toggleThemeMode(true))}
                  >
                    {svgSheet.DarkModeSun}
                  </span>
                )}
                {/* <img
                className="ml-5"
                src={toggleStatus === "Tools" ? upArrowIcon : downArrow}
                alt="icon"
              /> */}
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => openCloseToggle("SEARCH")}
                className="w-20 h-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              {
                toggleStatus === "MOBILE" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => openCloseToggle("NONE")}
                    className="w-20 h-20"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <div
                    className="w-20 h-20 "
                    onClick={() => openCloseToggle("MOBILE")}
                  >
                    {svgSheet.burgerIcon}
                  </div>
                )
                // <img
                //   src={BurgerIcon}
                //   className="w-30px h-30px"
                //   onClick={() => openCloseToggle("MOBILE")}
                //   alt="icon"
                // />
              }
            </div>
          </div>
        </nav>
        <ProductToggleCard
          authStatus={auth.userData?.access_token}
          lightMode={lightMode}
          productsToggle={toggleStatus}
          setproductsToggle={setToggleStatus}
          setPopupStatus={setPopupStatus}
        />
        <ToolsTogglecard
          authStatus={auth.userData?.access_token}
          lightMode={lightMode}
          toolsToggle={toggleStatus}
          setToolsToggle={setToggleStatus}
          setPopupStatus={setPopupStatus}
        />
        <MobileSideBar
          authStatus={auth.userData}
          lightMode={lightMode}
          mobileToggle={toggleStatus}
          setMobileToggle={setToggleStatus}
          userLogout={userLogout}
          fingradAccessStatus={fingradAccessStatus}
        />
        <MobileSearch
          lightMode={lightMode}
          searchResultDiv={searchResultDiv}
          inputData={inputData}
          setinputData={setinputData}
          mobileSearchToggle={toggleStatus}
          setMobileSearchToggle={setToggleStatus}
          getResults={getResults}
          setMobileSearchDrop={setMobileSearchDrop}
          MobileSearchDrop={MobileSearchDrop}
        />
      </div>
      {selectPopup(PopupStatus)}
    </div>
  );
}

export default NavBar;
