import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { authStore } from "../../redux/reducers/authSlice";
import { getFilterData, postScreenerData } from "../../redux/reducers/screenerSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import FilterIndex from "./filter/FilterIndex";
import MobOpenFilter from "./MobOpenFilter";
import Popularscreens from "./PopularScreens";
import RightSection from "./table section/RightSectionIndex";

function ScreenerIndex() {
  const dispatch = useDispatch();
  const UserAuth = useSelector(authStore);
  const params=useLocation()
  const { lightMode } = useSelector(getThemeMode);
  const [activePage, setactivePage] = useState({});
  // state for storing appled filters 
  const [filterTypeArr, setfilterTypeArr] = useState([
    {
      label: "Market Cap (Rs Cr)",
      firstValue: 0,
      secondValue: 2500000,
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
  ]);
  console.log(filterTypeArr);
  const [postSectorArr, setpostSectorArr] = useState([]);
  const [postIndexArr, setpostIndexArr] = useState([]);
  const [premiumModal, setpremiumModal] = useState(false)
  // state for storing table data with default columns
  const [columnsArr, setcolumnsArr] = useState([
    {
      label: "Current Price (Rs)",
      firstValue: 0,
      secondValue: 100000,
      particular: "PRICE",
      operator: "&",
      min: 0,
      max: 100000,
      unit: "",
      symbol: "rs",
    },
    {
      label: "1Yr Returns(%)",
      firstValue: 0,
      secondValue: 5000,
      particular: "returns_1y",
      operator: "&",
      min: 0,
      max: 5000,
      unit: "",
      symbol: "%",
    },
    {
      label: "PE Ratio TTM",
      firstValue: 0,
      secondValue: 100,
      particular: "TTMPE",
      operator: "&",
      min: 0,
      max: 100,
      unit: "",
      symbol: "",
    },
    {
      label: "Dividend Yield(%)",
      firstValue: 0,
      secondValue: 30,
      particular: "YIELD",
      operator: "&",
      min: 0,
      max: 30,
      unit: "",
      symbol: "%",
    },
  ]);
  console.log("params.state",params.state);
  useEffect(() => {
    setactivePage({ page: 1, perPage: 25 });
  }, [filterTypeArr,columnsArr]);
  useEffect(() => {
    if(params.state){
      setfilterTypeArr(params.state)
      dispatch(
        postScreenerData({
          applyFilters: params.state,
          industries: [],
          indices: [],
        })
      );
    }
    else{
      dispatch(getFilterData());
    }
  }, []);

  return (
    <>
     <ReactHelmet
        title='Fastest Stock Screener | Trade Brains Screener - Easiest Stock Screener & Analysis Tool'
        desc='Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis'
      />
    <div className="relative">
      <div className="ff-poppins max-w mx-auto  px-15  w-100">
        <div className="my-body">
          <div className="d-flex align-items-center  mb-30">
            <p className="mr-10 lh-36  mb-0 d-flex align-self-center">{svgSheet.screenerLogo}</p>
            <h1 className="mb-0 fs-36-20 fw-700 lh-36">Stock Screener</h1>
          </div>
          <div className="d-flex">
            <div
              style={{ width: "20%", top: `${130+bannerOffsetCalc(UserAuth)}px`, height: "min-content" }}
              className="detail-left-card p-fixed"
            >
              <FilterIndex
                lightMode={lightMode}
                filterTypeArr={filterTypeArr}
                setfilterTypeArr={setfilterTypeArr}
                postSectorArr={postSectorArr}
                setpostSectorArr={setpostSectorArr}
                postIndexArr={postIndexArr}
                setpostIndexArr={setpostIndexArr}
                columnsArr={columnsArr}
                setcolumnsArr={setcolumnsArr}
                premiumModal={premiumModal}
                setpremiumModal={setpremiumModal}
              />
            </div>
            <div className=" w-75-100 ml-30-0">
              <RightSection
                lightMode={lightMode}
                filterTypeArr={filterTypeArr}
                postSectorArr={postSectorArr}
                postIndexArr={postIndexArr}
                columnsArr={columnsArr}
                setcolumnsArr={setcolumnsArr}
                activePage={activePage}
                setactivePage={setactivePage}
              />
            </div>
          </div>
          <div>
            <h4 className="fs-18-14 mt-20 fw-600 mb-20">Popular Screens</h4>
            <Popularscreens
              lightMode={lightMode}
              setfilterTypeArr={setfilterTypeArr}
            />
          </div>
        </div>
      </div>
      {/* apply filter screen in mobile view  */}
      <MobOpenFilter
        lightMode={lightMode}
        filterTypeArr={filterTypeArr}
        setfilterTypeArr={setfilterTypeArr}
        postSectorArr={postSectorArr}
        setpostSectorArr={setpostSectorArr}
        postIndexArr={postIndexArr}
        setpostIndexArr={setpostIndexArr}
        columnsArr={columnsArr}
        setcolumnsArr={setcolumnsArr}
        premiumModal={premiumModal}
        setpremiumModal={setpremiumModal}
        activePage={activePage}
        setactivePage={setactivePage}
        UserAuth={UserAuth}
      />
    </div>
    </>
  );
}

export default ScreenerIndex;
