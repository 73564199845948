import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authStore } from "../redux/reducers/authSlice";

function AffiliateRouter({ children }) {
  const UserAuth = useSelector(authStore);
  const navigate=useNavigate()
  console.log(UserAuth?.userData?.user?.is_affiliate);
  if (UserAuth?.userData?.user?.is_affiliate===undefined||UserAuth?.userData?.user?.is_affiliate===false ) {
    navigate('/login')
  }
  return children;
}

export default AffiliateRouter;
