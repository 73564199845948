import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import GetMobileAppSection from "../home/components/GetMobileAppSection";
import FestivalBanner from "../PlanPage/FestivalBanner";
import PopularScreens from "../screener/PopularScreens";
import DashboardHeatmap from "./DashboardHeatmap";
import FeatureCardSection from "./FeatureCardSection";
import MarketMoverTable from "./MarketMoverTable";
import MarketWatchIndex from "./marketWatch.js/MarketWatchIndex";
import TrendingNewsSection from "./TrendingNewsSection";

function DashboardIndex({ setisLoginPage }) {
  const { lightMode } = useSelector(getThemeMode);
  const { userData } = useSelector(authStore);
  const navigate = useNavigate();
  const redirectToScreener = (data) => {
    console.log(data);
    navigate(`/screener`, {
      state: data,
    });
  };
  useEffect(() => {
    setisLoginPage(false);
    const ChimpId = sessionStorage.getItem("Mailchimp");
    console.log("ChimpId", ChimpId);
  }, []);

  return (
    <>
      <ReactHelmet
        title="Trade Brains Portal - Financial Data & Stock Quotes"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      {/* {userData?.user?.is_premium === false && <FestivalBanner />} */}
      <div className="mt-40">
        <div className="ff-poppins w-100 max-w mx-auto px-15  ">
          <div className="d-flex align-items-center mb-30">
            <p
              style={{ width: "36px", height: "36px" }}
              // style={{ marginRight: "5px", marginTop: "5px" }}
              className="fs-36-20 mb-0 mr-5 fw-700 d-flex align-items-center"
            >
              {svgSheet.DashBoradIcon}{" "}
            </p>
            <h1 className="fs-36-20  mb-0 fw-700 d-flex align-items-center">
              Dashboard
            </h1>
          </div>
          <section>
            <MarketWatchIndex lightMode={lightMode} />
            <div className="w-100 flex flex-col-row mt-70">
              <div className="w-70-100 ">
                <MarketMoverTable lightMode={lightMode} />
              </div>
              <div className="w-100-30 ">
                <p className="fs-20-16  fw-600 lh-20">Popular Screens</p>
                <PopularScreens
                  mobView={true}
                  lightMode={lightMode}
                  setfilterTypeArr={redirectToScreener}
                />
              </div>
            </div>
            <div className="mt-70">
              <DashboardHeatmap index={"NIFTY"} />
            </div>
            <div className="mt-70">
              <FeatureCardSection lightMode={lightMode} />
            </div>
            <div className="mt-70 w-100">
              <TrendingNewsSection lightMode={lightMode} />
            </div>
            <div>
              <GetMobileAppSection lightMode={lightMode} />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default DashboardIndex;
