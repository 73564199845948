import React, { useCallback, useState } from "react";
import ICICI from "../../assets/images/symbolImg/ICICI Bank.png";
import HDFC from "../../assets/images/symbolImg/HDFC Bank.png";
import Kotak from "../../assets/images/symbolImg/Kotak Mahindra.png";
import SBI from "../../assets/images/symbolImg/SBI.png";
import Axis from "../../assets/images/symbolImg/Axis Bank.png";
import Infosys from "../../assets/images/symbolImg/Infosys.png";
import TCS from "../../assets/images/symbolImg/TCS.png";
import HCL from "../../assets/images/symbolImg/HCL.png";
import Tech from "../../assets/images/symbolImg/Tech.png";
import Wipro from "../../assets/images/symbolImg/Wipro.png";
import WiproD from "../../assets/images/symbolImg/WiproD.png";
import HUL from "../../assets/images/symbolImg/HUL.png";
import Marico from "../../assets/images/symbolImg/Marico.png";
import ITCD from "../../assets/images/symbolImg/ITCD.png";
import ITC from "../../assets/images/symbolImg/ITC.png";
import Britannia from "../../assets/images/symbolImg/Britannia.png";
import Nestle from "../../assets/images/symbolImg/Nestle.png";
import NestleD from "../../assets/images/symbolImg/NestleD.png";
import Adani from "../../assets/images/symbolImg/Adani.png";
import NTPC from "../../assets/images/symbolImg/NTPC.png";
import TATA from "../../assets/images/symbolImg/TATA.png";
import MM from "../../assets/images/symbolImg/MM.png";
import Maruti from "../../assets/images/symbolImg/Maruti.png";
import SUN from "../../assets/images/symbolImg/SUN.png";
import Torrent from "../../assets/images/symbolImg/Torrent.png";
import Divis from "../../assets/images/symbolImg/Divis.png";
import TATAMotos from "../../assets/images/symbolImg/TATAMoto.png";
import HULD from "../../assets/images/symbolImg/HULD.png";

function TreandingStockSC({ lightMode, setInputDataIsOnChange ,setInputdatais,setIsDisplay,setTreandingStocks,setCountOfInputs,setInputFullName}) {
  const companies = [
    [
      { name: "ICICI Bank", img: ICICI },
      { name: "HDFC Bank", img: HDFC },
      { name: "Kotak Mahindra", img: Kotak },
      { name: "SBI", img: SBI },
      { name: "Axis Bank", img: Axis },
    ],
    [
      { name: "Infosys", img: Infosys },
      { name: "TCS", img: TCS },
      { name: "HCL", img: HCL },
      { name: "Tech Mahindra ", img: Tech },
      { name: "Wipro", img: Wipro, dark: WiproD },
    ],
    [
      { name: "HUL", img: HUL, dark: HULD },
      { name: "Marico", img: Marico },
      { name: "ITC", img: ITC,dark: ITCD  },
      { name: "Britannia", img: Britannia },
      { name: "Nestle", img: Nestle, dark: NestleD },
    ],
    [
      { name: "Adani Power", img: Adani },
      { name: "NTPC", img: NTPC },
      { name: "TATA Power", img: TATA },
    ],
    [
      { name: "TATA Motors", img: TATAMotos },
      { name: "M&M", img: MM },
      { name: "Maruti Suzuki", img: Maruti },
    ],
    [
      { name: "SUN Pharma", img: SUN },
      { name: "Torrent Pharma", img: Torrent },
      { name: "Divis Labs", img: Divis },
    ],
  ];

  const comparetype = ['Compare Banking Stocks','Compare IT Stocks','Compare FMCG Stocks','Compare Power Stocks','Compare Auto Stocks','Compare Pharma Stocks']

  const passdata = (props) => {
    setIsDisplay(true)

    if(props === 0 ){
      setInputDataIsOnChange({
          firstvalue: "ICICI Bank Ltd.",
        secondvalue: "HDFC Bank Ltd.",
        thirdvalue: "Kotak Mahindra Bank Ltd.",
        forthvalue: "State Bank Of India ",
        fifthvalue: "Axis Bank Ltd."})
        setInputFullName({
          firstvalue: "ICICI Bank Ltd.",
        secondvalue: "HDFC Bank Ltd.",
        thirdvalue: "Kotak Mahindra Bank Ltd.",
        forthvalue: "State Bank Of India ",
        fifthvalue: "Axis Bank Ltd."})
        setInputdatais({
          firstvalue: "ICICIBANK",
        secondvalue: "HDFCBANK",
        thirdvalue: "KOTAKBANK",
        forthvalue: "SBIN ",
        fifthvalue: "AXISBANK"
      })
      setCountOfInputs({
        name1:"ICICI Bank Ltd.",
        name2:"HDFC Bank Ltd.",
        name3: "Kotak Mahindra Bank Ltd.",
        name4: "State Bank Of India ",
        name5: "Axis Bank Ltd.",
      })
      setTreandingStocks(true)
      }
    else if (props === 1) {
      setInputDataIsOnChange({
        firstvalue: "Infosys Ltd.",
      secondvalue: "Tata Consultancy Services Ltd.",
      thirdvalue: "HCL Technologies Ltd.",
      forthvalue: "Tech Mahindra Ltd.",
      fifthvalue: "Wipro Ltd."})
      setInputFullName({
        firstvalue: "Infosys Ltd.",
      secondvalue: "Tata Consultancy Services Ltd.",
      thirdvalue: "HCL Technologies Ltd.",
      forthvalue: "Tech Mahindra Ltd.",
      fifthvalue: "Wipro Ltd."})
      setInputdatais({
        firstvalue: "INFY",
      secondvalue: "TCS",
      thirdvalue: "HCLTECH",
      forthvalue: "TECHM",
      fifthvalue: "WIPRO"})
      setCountOfInputs({
        name1:"Infosys Ltd.",
        name2:"Tata Consultancy Services Ltd.",
        name3: "HCL Technologies Ltd.",
        name4: "Tech Mahindra Ltd.",
        name5: "Wipro Ltd.",
      })
      setTreandingStocks(true)  
    } else if(props === 2){
      setInputDataIsOnChange({
        firstvalue: "Hindustan Unilever Ltd.",
      secondvalue: "MARICO",
      thirdvalue: "ITC Ltd.",
      forthvalue: "Britannia Industries Ltd.",
      fifthvalue: "Nestle India Ltd."})

      setInputFullName({
        firstvalue: "Hindustan Unilever Ltd.",
      secondvalue: "MARICO",
      thirdvalue: "ITC Ltd.",
      forthvalue: "Britannia Industries Ltd.",
      fifthvalue: "Nestle India Ltd."})
      setCountOfInputs({
        name1: "Hindustan Unilever Ltd.",
        name2: "MARICO",
        name3: "ITC Ltd.",
        name4: "Britannia Industries Ltd.",
        name5: "Nestle India Ltd."})
     
      setInputdatais({
        firstvalue: "HINDUNILVR",
      secondvalue: "MARICO",
      thirdvalue: "ITC",
      forthvalue: "BRITANNIA",
      fifthvalue: "NESTLEIND"})
      setTreandingStocks(true)  
    }
    else if(props === 3){
      setInputDataIsOnChange({
        firstvalue: "Adani Power Ltd.",
      secondvalue: "NTPC Ltd.",
      thirdvalue: "Tata Power Company Ltd.",
     })

     setInputFullName({
      firstvalue: "Adani Power Ltd.",
    secondvalue: "NTPC Ltd.",
    thirdvalue: "Tata Power Company Ltd.",
   })
   setCountOfInputs({
    name1: "Adani Power Ltd.",
    name2: "NTPC Ltd.",
    name3: "Tata Power Company Ltd.",
 })

     setInputdatais({
      firstvalue: "ADANIPOWER",
    secondvalue: "NTPC",
    thirdvalue: "TATAPOWER",
   })
   setTreandingStocks(true)  
  
    }
    else if(props === 4){
      setInputDataIsOnChange({
        firstvalue: "Tata Motors Ltd.",
      secondvalue: "Mahindra & Mahindra Ltd.",
      thirdvalue: "Maruti Suzuki India Ltd.",
     })
     setCountOfInputs({
      name1: "Tata Motors Ltd.",
      name2: "Mahindra & Mahindra Ltd.",
      name3: "Maruti Suzuki India Ltd.",
   })

     setInputFullName({
      firstvalue: "Tata Motors Ltd.",
    secondvalue: "Mahindra & Mahindra Ltd.",
    thirdvalue: "Maruti Suzuki India Ltd.",
   })

     setInputdatais({
      firstvalue: "TATAMOTORS",
    secondvalue: "M%26M",
    thirdvalue: "MARUTI",
   })
   setTreandingStocks(true)  
  
    }
    else if(props === 5){
      
      setInputDataIsOnChange({
        firstvalue: "Sun Pharmaceutical Industries Ltd.",
      secondvalue: "Torrent Pharmaceuticals Ltd.",
      thirdvalue: "Divi's Laboratories Ltd.",
     })
       
     setCountOfInputs({
      firstvalue: "Sun Pharmaceutical Industries Ltd.",
    secondvalue: "Torrent Pharmaceuticals Ltd.",
    thirdvalue: "Divi's Laboratories Ltd.",
   })

     setCountOfInputs({
      name1: "Sun Pharmaceutical Industries Ltd.",
      name2: "Torrent Pharmaceuticals Ltd.",
      name3: "Divi's Laboratories Ltd.",
   })

        
     setInputFullName({
      firstvalue: "Sun Pharmaceutical Industries Ltd.",
    secondvalue: "Torrent Pharmaceuticals Ltd.",
    thirdvalue: "Divi's Laboratories Ltd.",
   })
     

     setInputdatais({
      firstvalue: "SUNPHARMA",
      secondvalue: "TORNTPHARM",
      thirdvalue: "DIVISLAB",
   })
    
   setTreandingStocks(true)  
  
    }
  };

  return (
    <div>
      <div className="main-header-c">
        <h4 className="mb-0 fs-s-16 fw-600">Trending Stocks Comparison</h4>
      </div>
      <div className="w-100 d-flex justify-content-between animation-border-blue flex-wrap ">
        {companies.map((items, i) => (
          <div
            key={i}
            className={`${
              lightMode ? "bg-gray" : "bg-dark-gray"
            } w49-w100 mb-40 p-10 animation-div br-3 d-flex flex-col justify-content-center`}
          >
            <div className="d-flex  p-10w-100 justify-content-around">
              {items &&
                items?.map((items) => (
                  <div style={{ margin: "5px" }} className="d-flex flex-col">
                    <div className="d-flex mb-5 flex-col align-items-center justify-content-center">
                      <div
                        className={`round-Stock d-flex justify-content-center align-items-center ${
                          lightMode ? "bg-white" : "bg-dark"
                        }`}
                      >
                        {" "}
                        <img
                          src={
                            !lightMode
                              ? items?.dark
                                ? items.dark
                                : items.img
                              : items.img
                          }
                          alt=""
                        ></img>{" "}
                      </div>
                      <p className="mb-0 fs-14-10 mt-5 fw-500 text-align-center">
                        {items.name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <button
              onClick={() =>{
                passdata(i)
                
                window.scrollTo(0, 0);

               }
              }
              className={`${
                lightMode ? "bg-white" : "bg-dark"
              } w-100 br-3 fs-16-13 fw-600 Group-company-compare my-10 pointer`}
            >
             {comparetype[i]}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TreandingStockSC;
