import React, { useEffect, useMemo, useState } from "react";
import { getProfitAndLossCompareStocks } from "../../../api/fetchClient";
import SelectOptions from "../../../components/ant/SelectOptions";
import CustomTable from "../../../components/CustomTable";

import convertTableStructure from "../convertTableStructure";
import { comparebankProfitAndLoss, compareProfitAndLoss, ProfitAndLossINS } from "../TableType";

function ProfitAndLossCompareStocks({
  lightMode,
  removedEmptyData,
  symbolsandnames,
  columns,
  CompanyType,
}) {
  const [selectd, setselectd] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [profitAndLoss, setprofitAndLoss] = useState();
  const [LatestYear, setLatestYear] = useState();
  let yearstr = `year=${selectd}`
  useEffect(() => {
    const getData = async () => {
      setisLoading(true);
      getProfitAndLossCompareStocks(
        removedEmptyData,
        selectd ? yearstr : "" ,
        CompanyType
      ).then(async (res) => {
        setprofitAndLoss(res.data);
        LatestYear === undefined && setLatestYear(res?.data?.year)
        setisLoading(false);
      });
    };
    getData();
  }, [selectd, CompanyType]);

  const yeararraay =[]
  for(var i=0;i<5;i++){
    yeararraay.push({"value":LatestYear - i,"label":LatestYear - i})
  }

  console.log("profitAndLoss",profitAndLoss); 
  // GetMatricsData?.is_banking
  // ? CompareKeyMetricsBanking
  // : CompareKeyMetrics,
  const returnsTableData = useMemo(() => {
    if (profitAndLoss?.stocks === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        profitAndLoss?.stocks,
        profitAndLoss?.type === 'INS'? ProfitAndLossINS : profitAndLoss?.type === 'BNK'  ? comparebankProfitAndLoss : compareProfitAndLoss,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [profitAndLoss,lightMode]);

  return (
    <div id="Profit & Loss">
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c ">Profit & Loss Statement</h2>
        </div>
        {LatestYear &&<div className="d-flex flex-col align-items-end">
          <SelectOptions
            lightMode={lightMode}
            data={yeararraay} 
            setselectd={setselectd}
            defaultSelect={LatestYear}
            width={116}
          />
          <p className="fs-s-12 mb-0 text-gray mt-15 ">*Values in Cr.</p>
        </div>}
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={returnsTableData?.columns}
          loading={isLoading}
          scrollable={true}
          data={returnsTableData?.tableBodyData}
        />
      </div>
    </div>
  );
}

export default ProfitAndLossCompareStocks;
