import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNews } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import NewsComponent from "./NewsComponent";

function MarketNewsList({ lightMode, id, title }) {
  const [loader, setloader] = useState(false);
  const [NewsData, setNewsData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setloader(true);
    getNews("1", "6", id).then((res) => {
      setNewsData(res.data);
      setloader(false);
    });
  }, []);

  return (
    <div className="">
      <div className="d-flex align-items-center justify-content-between ">
        <h2 style={{ marginTop: "10px" }} className="main-header   mb-0 fw-700">
          {title}
        </h2>
        <span
          onClick={() => navigate(title, { state: id })}
          className="ml-10 pointer"
        >
          View All
        </span>
      </div>

     { loader ? <><Skeleton /> <Skeleton /></>:<div className="d-flex justify-content-between flex-wrap">
        {NewsData?.map((items, i) => (
          <div className="w-32-48">
            <NewsComponent
              title={items?.title?.rendered}
              img={items?.yoast_head_json?.og_image[0].url}
              lightMode={lightMode}
              url={items?.link}
              id={items?.id}
            />
          </div>
        ))}
      </div>}
    </div>
  );
}

export default MarketNewsList;
