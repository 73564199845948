import React, { useState } from "react";
import CustomTable from "../../../../../../components/CustomTable";
import numberWithCommas from "../../../../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../../../../utilityFn/redGreenColor";

function AffliateDashboardTable({ lightMode,affliatecode }) {
  const [iscopied, setiscopied] = useState(false)
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Type </p>,
      dataIndex: "IndexCompany",
      fixed: "left",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Revenue Range </p>,
      dataIndex: "Points",

      
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Commission % </p>,

      
      dataIndex: "Change",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              // borderRight: lightMode
              //   ? "1px solid #C9D7DE"
              //   : "1px solid #545E78",
              // borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",

                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text + "%"}
            </span>
          ),
        };
      },
    },
  ];
  const data = [
    {
      IndexCompany: "Bronze",
      Points: "Less  than  ₹10,000",
      Change: "10",
    },
    {
      IndexCompany: "Silver",
      Points: "₹ 10,000 - ₹30,000",
      Change: "20",
    },
    {
      IndexCompany: "Gold",
      Points: "More than ₹ 30,000",
      Change: "30",
    },
  ];
  const copyText=()=>{
    setiscopied(true)
    navigator.clipboard.writeText(`https://portal.tradebrains.in/signup/?refer=${affliatecode?.code}`);
  }
  return (
    <div>
      <div className="d-flex flex-wrap justify-content-between d-flex-row-col  w-100" style={{gap:"10px"}}>
        <div
          className={`table-shadow w-49-100 ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable  scrollable={true} scrollLimit={500}  columns={columns1} data={data} />
        </div>
        <div
          className={`${lightMode ? "bg-gray " : "card-drop-dark-shadow"} justify-content-between d-flex-row-col  w-49-100 br-3 d-flex p-20`}
        >
          <div>
          <p className="fs-s-16   fw-700">My Affiliate Details</p>
          <p className={`fw-500 fs-s-16 ${lightMode  ? " text-gray-sec" : "text-dark-mode"} `}>Affiliate Code</p>
          <p className={` ${lightMode  ? " text-btn-dark" : "text-btn-dark"} `}>{affliatecode?.code}</p>
          <p className={`fw-500 fs-s-16 ${lightMode  ? " text-gray-sec" : "text-dark-mode"} `}>Affiliate Link</p>
          <p className={` ${lightMode  ? " text-btn-dark" : "text-btn-dark"} `}>https://portal.tradebrains.in/signup/?refer={affliatecode?.code}</p>
          </div>
          <div className="d-flex justify-content-end flex-col">
          <button className="p-5 h-30px w-100px text-white btn-bg-primary br-3 mb-10" onClick={copyText}> {iscopied ?   "Copied":"Copy Link" }</button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default AffliateDashboardTable;
