import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBucketdata } from "../../api/fetchClient";
import BreadCrumb from "../../components/BreadCrumb";
import DotLoader from "../../components/spinners/DotLoader";
import BucketCard from "./BucketCard";
import demoImg from "../../assets/images/Demo/dronedemo.png";
import { useSelector } from "react-redux";
import { authStore } from "../../redux/reducers/authSlice";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";

function ViewAllBuckets() {
  const { category } = useParams();
  const UserAuth = useSelector(authStore);
  const navigate = useNavigate();
  const [Bucketdata, setBucketdata] = useState();
  const [premiumModal, setpremiumModal] = useState(false)
  useEffect(() => {
    const data = () => {
      getBucketdata().then((res) => {
        const categoryArr = res.data.buckets?.filter((bucket) =>
          bucket.category.includes(category)
        );
        setBucketdata(categoryArr);
      });
    };
    data();
  }, []);
  const breadCrumbData = [{ label: "Buckets", link: `/buckets` }];

  const cardNavigate = (isPremium,bucketname) => {
    if (isPremium&&!UserAuth?.userData?.user?.is_premium)  setpremiumModal(true)
    // navigate(`/getpremium`);
    else{
      navigate(`/buckets/${bucketname}`, {
        state: category,
      });
    }
   
  };

  if (!Bucketdata) {
    return <DotLoader />;
  }
  return (
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
      <p className=" mb-0 fs-36-20 fw-700 mb-10 line-h-36">{category}</p>
      <div className="mb-20">
      <BreadCrumb linkData={breadCrumbData} current={category} />
          </div>
  
      <div className="bucket-card-wrap mb-40">
        {Bucketdata?.map((bucket, i) => (
          <BucketCard
            key={i}
            isPremium={(bucket.premium&&!UserAuth?.userData?.user?.is_premium)?true:false}
            bucketname={bucket.title}
            stockNum={bucket.stocks.length}
            viewAllPage={category}
            cardImg={bucket.image}
            clickFn={()=>cardNavigate(bucket.premium,bucket.title)}
          />
        ))}
      </div>
      </div>
    <PremiumRedirectModal  visible={premiumModal} setVisible={setpremiumModal} />
    </div>
  );
}

export default ViewAllBuckets
