import React, { useEffect, useMemo, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import StockFaq from "../stock details/StockFaq";
import GainerLoserTable from "./component/GainerLoserTable";
import IndexLeftBar from "./component/IndexLeftBar";
import IndexDropdown from "./component/IndexDropdown";
import svgSheet from "../../assets/svg/svgSheet";
import StockEndpoints from "../../api/endPoints";
import useGetFetch from "../../hooks/useGetFetch";
import { authStore } from "../../redux/reducers/authSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
function LosersIndex({setisLoginPage}) {
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  useEffect(() => { 
    setisLoginPage(false)
  }, [])
  const [activeIndex, setactiveIndex] = useState({
    symbol: "NIFTY",
    label: "NIFTY 50",
  });
  const {
    isLoading: loading,
    apiData: faqData,
    serverError: faqErr,
  } = useGetFetch(StockEndpoints.getFaq(`losers/?index=${activeIndex.symbol}`))
  const convertFaq=useMemo(() => {
    const testdata=faqData?.map((item)=>{
     return {
       head:item.question,
       content:item.answer
     }
    })
    return testdata
    }, [faqData,activeIndex])
 
  return (
    <>
    <ReactHelmet
        title="Top Losers Today | BSE NSE Losers Companies List | BSE NSE Losers Stock List"
        desc="Complete List of Top Losers on NSE and BSE"
      />
    <div className="ff-poppins max-w mx-auto  h-100 px-15">
      <div className="my-body">
      <div className="d-flex align-items-center  mb-30">
            <p className="mr-10 lh-36  mb-0 d-flex align-self-center">{svgSheet.losersLogo}</p>
            <h1
         
          className="mb-0 fs-36-20 fw-700 lh-36"
        >
          Top Losers <CaretDownOutlined  style={{
            color: lightMode ? "#FF0000" : "#F82E2E",
          }}/>
        </h1>
          </div>
        
        <div className="d-flex">
          <div
            style={{ width: "20%", top:`${130+bannerOffsetCalc(UserAuth)}px`, height: "min-content" }}
            className="detail-left-card p-fixed"
          >
            <IndexLeftBar
              lightMode={lightMode}
              setactiveIndex={setactiveIndex}
              activeIndex={activeIndex}
            />
          </div>
          <div className=" w-75-100 ml-30-0">
            <div className="mb-20 d-flex justify-content-end">
              <IndexDropdown
                lightMode={lightMode}
                // data={sectorName}
                navigate={setactiveIndex}
                activeValue={activeIndex}
              />
            </div>
            <GainerLoserTable
              lightMode={lightMode}
              activeIndex={activeIndex}
              apiEndpoint="losers"
            />
            <StockFaq data={convertFaq} lightMode={lightMode} />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default LosersIndex;
