import React from 'react'
import { Link } from 'react-router-dom'
import svgSheet from '../../../assets/svg/svgSheet'

function InvestersSidebar({lightMode,data}) {
   
  return (
    <div
      className={`w-100  card-shadow detail-left-card ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
      style={{
        height: "min-content",
        marginBottom: "40px",
       padding:'20px'
      }}
    >
      <p className="mb-15 fs-s-16 fw-600">Top Ace Investors</p>
      {data?.map((item, i) => (
        <Link
        to={`/superstars/${item.name}`}
          key={i}
          style={{ height: "50px" }}
          className={`${lightMode ? "hover-light-blue" : "bg-dark-hover"} p-15 flex align-items-center`}
        >
          <img src={item.image} className="w-35 h-35 br-50" />
          <p className="fs-s-15 fw-500 mb-0">{item.name}</p>
        </Link>
      ))}
      {/* {
          data?.map((item,i)=>(
            <Link
            to={`/superstars/${item.name}`}
            key={i}
            style={{ height: "35px" }}
            className={`${lightMode ? "hover-light-blue" : "bg-dark-hover"} mb-15 d-flex align-items-center`}
          >
              {svgSheet.starIcon}
            <p className="fs-s-15 fw-600 mb-0">{item.name}</p>
          </Link>
          ))
      } */}
       
    </div>
  )
}

export default InvestersSidebar