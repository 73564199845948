import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThemeMode } from '../../../redux/reducers/ThemeSlice';
import BottomDataSection from '../BottomDataSection';
import CalcInputFeilds from '../CalcInputFeilds';
import CalculatorSlider from '../CalculatorSlider'
import ResultContainerCalc from '../ResultContainerCalc';

function SystematicInvestment() {
  const navigator = useNavigate()
  const [SliderValues, setSliderValues] = useState({
    monthlyPrice:2000,
    ExpectedCAGR:12,
    Duration:10
  })
  const [Result, setResult] = useState({
    Result:"",
    investAmt:"",
    return:""
  })
    const { lightMode } = useSelector(getThemeMode);
    


      const sliderList = [
          {name:"Monthly SIP (in Rupees)",limit:100000},
          {name:"Expected CAGR (%)",limit:30},
          {name:"Duration (in Years)",limit:20},
      ]

      useEffect(() => {
        let value =
          (SliderValues.monthlyPrice *
            (1 + Number(SliderValues.ExpectedCAGR) / 1200) *
            ((1 + Number(SliderValues.ExpectedCAGR) / 1200) ** (SliderValues.Duration * 12) - 1)) /
          (SliderValues.ExpectedCAGR / 1200);
        // setResult({...Result,"investAmt" : SliderValues.monthlyPrice * (SliderValues.Duration * 12)});
        // setResult({...Result,"return" : Result.Result - Result.investAmt});

        let invtamt = SliderValues.monthlyPrice * (SliderValues.Duration * 12);
        let getsipWealth = value - invtamt;
        setResult({...Result,[Object.keys(Result)[0]] : value,[Object.keys(Result)[1]] : invtamt,[Object.keys(Result)[2]] : getsipWealth});


        // setInvaumt(invtamt ? invtamt : 0)v;
        // setEstretrn(getsipWealth ? getsipWealth : 0);
      }, [SliderValues.monthlyPrice, SliderValues.ExpectedCAGR, SliderValues.Duration]);
    
      const ResultHeder = [{
        "Expected Amount" : Result.Result
      }]
      const bottomData = [{
        header:"What Is SIP?",
        body:"SIP stands for Systematic Investment Plan. SIP is a systematic method of investing your money in Mutual Funds. A SIP is a planned approach towards investments and it helps you to develop the habit of saving and building wealth for the future. This is a basic Compound Interest Calculator. It will give the future value of one time lump-sum investment. For example, if you invest Rs 100,000 with an Expected CAGR of 16% over 35 years, its future value will be Rs 18,031,389 (Rs 1.80 Cr)."
      },]
  return (
    <div>
          <div>
         <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
      <p className='pointer' onClick={()=>navigator(-1)}>Calculators / Systematic Investment Plan</p>
        <p className="mb-0 fs-30-20 fw-700">Systematic Investment Plan</p>
        <p>This is an intrinsic value calculator used by ace Investor Mohnish Pabrai for the purpose of picking the right stock for investing purposes.
</p>
      </div>
      <div  className="mx-50-0 p-45-10 br-0 custom-input-dark">
      <div>
          <CalcInputFeilds SliderValues={SliderValues} setSliderValues={setSliderValues} sliderList={sliderList} lightMode={lightMode}/>
      </div>
      <div>
          <ResultContainerCalc ResultHeder={ResultHeder} DonutChartResult={Result} donutChart={true}  lightMode={lightMode}/>
      </div>
      </div>  
      <div>
          <BottomDataSection bottomData={bottomData}/>
      </div>  
    </div>
    </div>
    </div>
  )
}

export default SystematicInvestment