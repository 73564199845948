import {
  InstagramFilled,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import React, { useState } from "react";
import logoimage from "../assets/images/PortalLogo.png";
import logoWhiteimage from "../assets/images/logo/logoWhite.png";
import playstore from "../assets/images/playstore.png";
import iosImage from "../assets/images/iosDownload.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleThemeMode } from "../redux/reducers/ThemeSlice";
import svgSheet from "../assets/svg/svgSheet";
import { authStore } from "../redux/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import PremiumRedirectModal from "./PremiumRedirectModal";

function Footer({ lightMode }) {
  const dispatch = useDispatch();
  const auth = useSelector(authStore);
  const [Visible, setVisible] = useState(false)

  const navigate = useNavigate();

  const navRedirect = (path,state={state:'/'}) => {
    if (auth.userData?.access_token) {
      navigate(path);
    } else {
      navigate(path);

      // navigate("/login",state);
    }
  };

  const ListOfDropdownData = [
    {
      name: "Portfolio Analysis",
      disc: "Get visual reports of your portfolio similar to what million dollar investment managers get!",
      Logo: svgSheet.portFolioSvg,
      link: "portfolio",
      redirectState:'/portfolio',
      premium: true,

    },
    {
      name: "Screener",
      disc: "Stock search made easier. This powerful screener helps you saperate glitter from gold.",
      Logo: svgSheet.ScreenerSvg,
      link: "screener",
      redirectState:'/screener'
    },
    {
      name: "Stock compare",
      disc: "Compare stocks side to side and pick the winner!",
      Logo: svgSheet.CompareStocksSvg,
      link: "stockCompare",
      redirectState:'/stockCompare'
    },
    {
      name: "Buckets",
      disc: "Find ready made bucket of stocks across several themes, and sectors. ",
      Logo: svgSheet.BuckectNewSvg,
      link: "Buckets",
      redirectState:'/Buckets'
    },
    {
      name: "Watchlist",
      disc: "Track your favourite stocks to find the best opportunity.",
      Logo: svgSheet.WatchListNewSvg,
      link: "watchlist",
      redirectState:'/watchlist'
    },
    {
      name: "Superstar Portfolio",
      disc: "Find out what the big names like the Jhunjhunwala's of the investing world are buying.",
      Logo: svgSheet.SuperStarPorNewSvg,
      link: "superstars",
      redirectState:'/superstars'
    },
  ];

  const MarketDropdownData = [
    {
      name: "All Stocks",
      disc: "Every Indian stock methodically sorted by sector.",
      Logo: svgSheet.AllStocks,
      link: "stock/industry/Abrasives",
    },
    // {
    //   name: "Market Returns",
    //   disc: "Find returns and constituents of Sensex, Nifty and every other Indian Index!",
    //   Logo: svgSheet.MarketRetuenSvg,
    //   link: "",
    // },
    {
      name: "Top Gainers",
      disc: "Top Gainers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopGainersNewSvg,
      link: "marketstats/gainers",
    },
    {
      name: "Top Losers",
      disc: "Top Losers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopLosers,
      link: "marketstats/losers",
    },
    {
      name: "Market News",
      disc: "Stay updated with the latest news in the business world",
      Logo: svgSheet.MarketNewsSvg,
      link: "news",
    },
  ];
  return (
    <div
      className={`foooter ${
        lightMode ? "bg-white" : "bg-dark-black"
      } shadow-top ff-poppins`}
    >
      <div className=" w-100 foooter-sub max-w mx-auto px-15  d-flex mb-20 flex-wrap d-flex-row justify-content-between">
        <div className=" mt-3r products-div-footer d-flex d-flex-col  justify-content-between">
          <img
            src={lightMode ? logoimage : logoWhiteimage}
            width={"180px"}
            alt="logo"
          />
          <p className="fw-500 my-40">
            Trade brains portal helps investors make efficient stock research
            and analysis by providing quality fundamental data with insightful
            visuals.
          </p>
          <div className="flex ">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.dailyraven.portal_app&hl=en_IN&gl=US"
            >
              <img
                src={playstore}
                style={{ marginTop: "7px" }}
                width={"150px"}
                alt="icon"
              />
            </a>{" "}
            <a
              target="_blank"
              href="https://apps.apple.com/in/app/trade-brains-portal/id1590981027"
            >
              <img src={iosImage} width={"150px"} alt="icon" />
            </a>
          </div>
        </div>
        <div className="mt-3r">
          <p className="fw-500 fs-s-16 mt-0-45">Features</p>
          {ListOfDropdownData?.map((items,i) => (
            <p className="pointer" key={i}  onClick={() => {
                    items?.premium
                      ? auth?.userData?.user?.plan?.planId === "basic"
                        ? setVisible(true)
                        : navRedirect(`/${items?.link}`)
                      : navRedirect(`/${items?.link}`);
                  }}>
              {items?.name}
            </p>
          ))}
        </div>
        <div className="mt-3r">
          <p className="fw-500 fs-s-16">Market</p>
          {MarketDropdownData?.map((items,i) => (
            <p key={i} className="pointer" onClick={() => navigate(items?.link)}>
              {items?.name}
            </p>
          ))}
        </div>
        <div className="mt-3r">
          <p className="fw-500 fs-s-16">Other Products</p>
          <p>
            <a href="https://joinfingrad.com/" target="_blank">
              Fingrad
            </a>
          </p>
          <p>
            <a href="https://tradebrains.in/" target="_blank">
              Tradebrains
            </a>
          </p>
          {/* <p><a href="https://tradebrains.in/blog/" target="_blank" >Tradebrains Blog</a></p> */}
          <p>
            <a href="https://tradebrains.in/features/news/" target="_blank">
              Tradebrains News
            </a>
          </p>
          <p> <Link to="/new-updates">
             What's New?
            </Link>
            </p>
        </div>
      </div>
      <div className={`${lightMode ? "bg-gray" : "bg-dark-gray"}`}>
        <div
          className={`d-flex d-flex-row flex-wrap footer-media justify-content-between mt-3 w-100 max-w mx-auto  px-15 `}
        >
          <div className="d-flex fs-s-14 footer-text d-flex-row justify-content-center">
            <p>Follow us on:</p>
            <p>
              <a
                href="https://www.youtube.com/channel/UCzw35O6toJtjqEAAt4LTjKQ"
                target="_blank"
              >
                <YoutubeFilled />
              </a>
            </p>
            <p>
              <a
                href="https://in.linkedin.com/company/trade-brains"
                target="_blank"
              >
                <LinkedinFilled />
              </a>
            </p>
            <p>
              <a
                href="https://instagram.com/portal.tradebrains?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <InstagramFilled />
              </a>
            </p>
            <p>
              <a
                href="https://twitter.com/TbPortal?t=AB4kcPk54CUIpf1-DH3AJQ&s=09"
                target="_blank"
              >
                <TwitterOutlined />
              </a>
            </p>
          </div>
          {/* <div className="d-flex fs-s-14 footer-text d-flex-row">
          <p className={`pointer ${lightMode?'':'text-black'}`}  onClick={()=> dispatch(toggleThemeMode(false))}>Dark</p>
          <p className="pointer" onClick={()=> dispatch(toggleThemeMode(true))}>Light</p>
        </div> */}
          <div className="d-flex fs-s-14 footer-text d-flex-row flex-wrap">
            {/* <p className={`pointer ${lightMode?'':'text-black'} d-flex align-items-center`}  onClick={()=> dispatch(toggleThemeMode(false))}>
         {svgSheet.moonIcon}
          Dark</p> */}
            {/* <p className="pointer d-flex align-items-center" onClick={()=> dispatch(toggleThemeMode(true))}>
          {svgSheet.sunIcon}
            Light</p> */}
             <p> <Link to="/getpremium">
             Pricing
            </Link>
            </p>
            <p> <Link to={auth?.userData?.user?.is_affiliate?'/affiliate':'/become-affiliate'}>
              Affiliate
            </Link>
            </p>
             <p> <Link to="/contactus">
             Contact
            </Link>
            </p>
            <p> <Link to="/terms-and-conditions" target="_blank">
             Terms
            </Link>
            </p>
            <p><Link to="/privacy" target="_blank">
              {" "}
            Privacy
            </Link>
            </p>
            <p><Link to="/disclaimer" target="_blank">
              Disclaimer
            </Link>
            </p>
            
            <p> <Link to="/refund-policy"  target="_blank">
              Refund Policy
            </Link>
            </p>
          </div>
        </div>
      </div>
      <span className="d-flex flex-row justify-content-center">
        <span className="fs-12 mt-10 d-flex flex-row display-inline px-20 text-align-center">
          © Copyright 2023 © Dailyraven Technologies Pvt Ltd. Datafeed provided
          by
          <span className="ml-5">
            {" "}
            <a href="https://www.accordfintech.com/" target="_blank">
              Accord Fintech
            </a>
          </span>
        </span>
        <PremiumRedirectModal setVisible={setVisible} modalPlan={"portFolio"} visible={Visible}/>
      </span>
    </div>
  );
}

export default Footer;
