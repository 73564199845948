import React from "react";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function ReturnsTable({ lightMode, returnsdata }) {
  return (
    <div>
      <div
        className={`w-100 flex-col-row justify-content-between on mt-30 box-shadow-card  p-10 flex-col ${
          lightMode ? "bg-gray" : "bg-dark-gray"
        }`}
      >
        {returnsdata?.map((item, i) => (
          <div
            key={i}
            className="flex-row-col p-10 d-flex justify-content-between"
          >
            <div
              style={{ height: "37px" }}
              className={`w-150-45p fs-s-14  d-flex align-items-center text-align-center justify-content-center ${
                lightMode ? "bg-white" : "bg-dark"
              }`}
            >
              <p className="fw-500 mb-0 fs-s-14 p-10">{Object.keys(item)}</p>
            </div>
            <div
              style={{ height: "37px" }}
              className={`w-150-45p ${
                lightMode ? "border1px-light-mode" : "border1px-dark-mode"
              }  d-flex align-items-center text-align-center justify-content-center`}
            >
              <p style={{color:redGreenColorPicker(Object.values(item),lightMode)}} className="fs-s-14 fw-600 mb-0 ff-lato">
              {Object.values(item) >0&& "+"}{Object.values(item)}%
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReturnsTable;
