import React, { useEffect, useState } from "react";
import { getNews } from "../../api/fetchClient";
import NewsBars from "./NewsBars";
import NewsComponent from "./NewsComponent";

function TopTrendingNews({ lightMode }) {
  const [TrendingNews, setTrendingNews] = useState([]);

  useEffect(() => {
    const getdata = () => {
      getNews("1", "5", "1789").then((res) => {
        setTrendingNews(res.data);
      });
    };
    getdata();
  }, []);
  return (
    <div className="d-flex d-flex-row-col mb-10 justify-content-between">

      <div className="w-100 d-flex-row-col justify-content-between">
        <div className="w-49-100">
          <NewsComponent
            lightMode={lightMode}
            img={TrendingNews[0]?.yoast_head_json?.og_image[0]?.url}
            title={TrendingNews[0]?.title?.rendered}
            he={"fs-s-18"}
            url={TrendingNews[0]?.link}
            id={TrendingNews[0]?.id}
            isHeader ={true}
          />
        </div>
        <div className="only-PC-view w-49-100" >
          <div   className=" d-flex justify-content-between flex-col h-100  ">
            <NewsBars
              lightMode={lightMode}
              img={TrendingNews[1]?.yoast_head_json?.og_image[0].url}
              title={TrendingNews[1]?.title?.rendered}
              fontSize={"fs-s-18"}
              url={TrendingNews[1]?.link}
              id={TrendingNews[1]?.id}
            />
            <NewsBars
              lightMode={lightMode}
              img={TrendingNews[2]?.yoast_head_json?.og_image[0].url}
              title={TrendingNews[2]?.title?.rendered}
              fontSize={"fs-s-18"}
              url={TrendingNews[2]?.link}
              id={TrendingNews[2]?.id}
            />

            <NewsBars
              lightMode={lightMode}
              img={TrendingNews[3]?.yoast_head_json?.og_image[0].url}
              title={TrendingNews[3]?.title?.rendered}
              fontSize={"fs-s-18"}
              url={TrendingNews[3]?.link}
              id={TrendingNews[3]?.id}
            />

<NewsBars
              lightMode={lightMode}
              img={TrendingNews[4]?.yoast_head_json?.og_image[0].url}
              title={TrendingNews[4]?.title?.rendered}
              fontSize={"fs-s-18"}
              url={TrendingNews[4]?.link}
              id={TrendingNews[4]?.id}
            />
          </div>
        </div>
        <div className="only-mobile-view">
          <div className="d-flex justify-content-between my-40 flex-wrap">
            <div className="w-32-48 mb-10 ">
              <NewsComponent
                title={TrendingNews[1]?.title?.rendered}
                img={TrendingNews[1]?.yoast_head_json?.og_image[0].url}
                lightMode={lightMode}
                url={TrendingNews[1]?.link}
                id={TrendingNews[1]?.id}
              />
            </div>
            <div className="w-32-48 mb-10 ">
              <NewsComponent
                title={TrendingNews[2]?.title?.rendered}
                img={TrendingNews[2]?.yoast_head_json?.og_image[0].url}
                lightMode={lightMode}
                url={TrendingNews[2]?.link}
                id={TrendingNews[2]?.id}
              />
            </div>

            <div className="w-32-48 mb-10 ">
              <NewsComponent
                title={TrendingNews[3]?.title?.rendered}
                img={TrendingNews[3]?.yoast_head_json?.og_image[0].url}
                lightMode={lightMode}
                url={TrendingNews[3]?.link}
                id={TrendingNews[3]?.id}
              />
            </div>
            <div className="w-32-48 mb-10 ">
              <NewsComponent
                title={TrendingNews[4]?.title?.rendered}
                img={TrendingNews[4]?.yoast_head_json?.og_image[0].url}
                lightMode={lightMode}
                url={TrendingNews[4]?.link}
                id={TrendingNews[4]?.id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopTrendingNews;
