import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAffiliateSignup, getAffiliateSugnuptotal } from '../../../../../api/fetchClient';
import CustomPagination from '../../../../../components/ant/CustomPagination';
import CustomTable from '../../../../../components/CustomTable'
import { getThemeMode } from '../../../../../redux/reducers/ThemeSlice';
import numberWithCommas from '../../../../../utilityFn/numberWithCommas';
import { redGreenColorPicker } from '../../../../../utilityFn/redGreenColor';

function AffiliateSign() {
const [isloading, setisloading] = useState()
const [SignTotal, setSignTotal] = useState()
const [page, setPage] = useState(1);
const [getSignupdata, setgetSignupdata] = useState()
const onChange = (pageNumber) => {
  setPage(pageNumber);
};
  useEffect(() => {
    setisloading(true)
    getAffiliateSignup().then((res)=>{
      setisloading(false)
      setgetSignupdata(res?.data)
    }).catch((res)=>{
      setisloading(false)
    })
    getAffiliateSugnuptotal().then((res)=>{
      setSignTotal(res?.data)
    })
  }, [])

  
  const { lightMode } = useSelector(getThemeMode);
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Name </p>,
      dataIndex: "user",
      fixed: "left",
      width: "40px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Gmail</p>,
      dataIndex: "email",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
   
    {
      title: <p className="fs-s-16 fw-600 mb-0">Signed Up Date</p>,
      width: "50px",
      dataIndex: "date",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
  
   
    
    
 
  ];
  const data = [
    {
      IndexCompany: "Demo Index",
      Points: "58,980.95",
      Change: "480.89",
      ChangePers: "2.98%",
    },
    {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      },
  ];
  return (
    <div className="mt-30 px-30-0">
          {/* <p className='fs-s-20 fw-700 mb-0 '>Sign Up Analysis</p> */}
          <div className='d-flex align-center-start d-flex-row-col  justify-content-between'>
          <p className='fs-s-20 fw-500'>Overall Sign Up Data </p>
          <p className='fs-s-20 d-flex justify-content-end fw-700 ff-lato'>Total Sign Ups: {SignTotal?.total_signups}</p>
          </div>
          <div
      className={`table-shadow  ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
          <CustomTable data={getSignupdata?.results} scrollable={true} scrollLimit={500} loading={isloading} columns={columns1}/>
          <CustomPagination
          showQuickJumper
          defaultCurrent={1}
          size="small"
          current={page}
          total={Math.ceil(getSignupdata && getSignupdata["count"]/5 )}
          // total={20}

          onChange={onChange}
          lightMode={lightMode}
        />
        </div>

    </div>
  )
}

export default AffiliateSign