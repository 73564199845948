import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PremiumRedirectModal from '../components/PremiumRedirectModal';
import { authStore } from '../redux/reducers/authSlice';

function AuthRouter({children,afterLogin=false,redirState="/"}) {
  const location =useLocation()
  const UserAuth = useSelector(authStore);
  console.log(`${location.pathname?.substring(1)}${location.search}`);
    if (!UserAuth?.userData.access_token && afterLogin===false) {
        // return <Navigate to="/login" state={redirState}  replace />;
        return <>
    {children}
        <PremiumRedirectModal modalPlan={redirState} redirectState={`${location.pathname?.substring(1)}${location.search}`} loginRediect={true} visible={true}/>
        </>
      }
      else if(UserAuth?.userData.access_token&&afterLogin){
        return <Navigate to="/" replace />;
      }
    
      return children;
}

export default AuthRouter
