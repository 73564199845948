import React, { useState } from "react";
import BSEAnnouncements from "./Corp Announce/BSEAnnouncements";
import CorporateActionsStock from "./Corp Announce/CorporateActionsStock";
import NewsTabStockDetails from "./Corp Announce/NewsTabStockDetails";

function FeaturesTab({ lightMode,symbol }) {
  const [tabName, setTabName] = useState("News Updates");
  const tabValues = ["News Updates", "Corporate Actions", "BSE Announcements"];
  return (
    <div className=" main-pros-and-cons mb-10 " id="corpAnnouncement">
      <div className="pros-and-cons d-flex d-flex-col features-of-stockdetails w-100">
        <div>
          <h2 className="main-header">Corporate Announcement</h2>
        </div>
        <div className={`${lightMode ? "bg-gray" : "bg-dark-gray"} w-100 p-20`}>
          <div style={{height:'40px',overflowX:'auto',overflowY:"hidden"}} className="bg-white w-100 ">
            <div
              style={{minWidth:'500px'}}
              className={`d-flex h-100  align-items-center  justify-content-around ${
                lightMode ? "" : "bg-dark"
              }`}
            >
              {tabValues.map((items, i) => (
                <div
                  key={i}
                  style={{
                    minWidth:'130px',
                    width: "33%",
                    borderBottom:
                      tabName === items &&
                      `1px solid  ${lightMode ? "black" : "#ffff"}`,
                  }}
                  className="pointer h-100 d-flex align-items-center  justify-content-center"
                  onClick={() => setTabName(items)}
                >
                  <div key={i}>
                    <p
                      className="mb-0 fs-s-15"
                      style={{ fontWeight: tabName === items ? "600" : "400" }}
                    >
                      {items}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="Tab-content">
            {tabName === "News Updates" ? (
              <NewsTabStockDetails symbol={symbol} lightMode={lightMode} />
            ) : tabName === "Corporate Actions" ? (
              <CorporateActionsStock lightMode={lightMode} symbol={symbol}/>
            ) : (
              <BSEAnnouncements lightMode={lightMode} symbol={symbol}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturesTab;
