import moment from "moment";
import { Modal, Radio, Row, Col, Form, Input, DatePicker } from "antd";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";

function NotificationModal({
  lightMode,
  openModal,
  changeModalState,
  onFinish,
  onSubmit,
  initialValues = {},
  propdate,
  propdate1,editModal
}) {
  const [form] = Form.useForm();
  const dateFormat = ["YYYY-MM-DD"];
  const { RangePicker } = DatePicker;
  const [modalDate, setModalDate] = useState(propdate);
  const [modalDate1, setModalDate1] = useState(propdate1);

  console.log(initialValues,propdate,propdate1, "initialValues");
  return (
    <Form
      form={form}
      onFinish={(data) => onSubmit(data, form)}
      initialValues={initialValues}
      // initialValues={editInitValues?.data}
      layout="vertical"
    >
      <Modal
        width={"700px"}
        title={
          <p
            className={`fs-s-18 mb-0 fw-800 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Notification Settings
          </p>
        }
        visible={openModal}
        centered
        wrapClassName={!lightMode && "modelClassname"}
        onCancel={() => {
          changeModalState(false);
          form.resetFields();
          console.log("dadsdasd");
        }}
        footer={[
          <button
            key="submit"
            type="submit"
            className={` w-150px br-5 fs-s-16 pointer fw-500 btn-bg-primary text-white ${
              lightMode ? "  h-45" : "h-45 "
            }`}
            onClick={() => {
              form.submit();
            }}
          >
       {propdate? "Update" :"Add Notification"}  
            
          </button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Heading"
              name="heading"
              className={`w-90  ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
              rules={[
                {
                  required: true,
                  message: "Please Enter your Heading",
                },
              ]}
            >
              <Input.TextArea
                name="heading"
                type="text"
                className={`w-100  auth-form-input  ${
                  lightMode
                    ? "bg-gray ant-textarea-light "
                    : "bg-dark-gray  ant-textarea-dark"
                }`}
                style={{
                  width: "90%",
                  height: "35px",
                  background: lightMode ? "white" : "#3B3F4F",
                  border: "1px",
                }}
                placeholder="type here"
                showCount
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              label="Offer Text"
              name="sub_text"
              className={`w-90 ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
              rules={[
                {
                  required: true,
                  message: "Please Enter your sub text!",
                },
              ]}
            >
              <Input.TextArea
                type="text"
                className={`w-100  auth-form-input  ${
                  lightMode
                    ? "bg-gray ant-textarea-light "
                    : "bg-dark-gray  ant-textarea-dark"
                }`}
                style={{
                  width: "90%",
                  height: "35px",
                  background: lightMode ? "white" : "#3B3F4F",

                  border: "1px",
                }}
                placeholder="type here"
                showCount
                maxLength={60}
              />
            </Form.Item>

            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                valuePropName={'date'}
                label="Validity From"
                name="start_date"
                style={{
                  display: "inline-block",
                  width: "calc(50%)",
                }}
                className={`w-90 ${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter your validity start date!",
                  },
                ]}
              >
                <DatePicker
                  label="Date"
                  format={dateFormat}
                  style={{ width: "80%", height: "35px" }}
                  inputReadOnly={true}
                  // value={
                  //   editInitValues?.data?.start_date
                  //     ? moment(editInitValues?.data?.start_date)
                  //     : undefined
                  // }
                  value={propdate}
                  className={!lightMode && "DatePicker-Antd-Dark"}
                  dropdownClassName={
                    !lightMode && "DatePickerdropdrow-Antd-Dark"
                  }
                />
              </Form.Item>

              <Form.Item
                valuePropName={"date"}
                label="Validity To"
                name="end_date"
                style={{
                  display: "inline-block",
                  width: "calc(50%)",
                }}
                className={`w-90 ${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter your validity end date!",
                  },
                ]}
              >
                <DatePicker
                  label="Date"
                  style={{ width: "80%", height: "35px" }}
                  format={dateFormat}
                  inputReadOnly={true}
                  className={!lightMode && "DatePicker-Antd-Dark"}
                  value={propdate1}
                  // value={
                  //   editInitValues?.data?.end_date
                  //     ? moment(editInitValues?.data?.end_date)
                  //     : undefined
                  // }
                  dropdownClassName={
                    !lightMode && "DatePickerdropdrow-Antd-Dark"
                  }
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Button Text"
              name="button_text"
              className={`w-90 ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
              rules={[
                {
                  required: true,
                  message: "Please Enter your Button text!",
                },
              ]}
            >
              <Input.TextArea
                type="text"
                className={`w-100  auth-form-input  ${
                  lightMode
                    ? "bg-gray ant-textarea-light "
                    : "bg-dark-gray  ant-textarea-dark"
                }`}
                style={{
                  width: "90%",
                  height: "35px",
                  background: lightMode ? "white" : "#3B3F4F",
                  color: lightMode ? "black" : "white",
                }}
                placeholder="type here"
              />
            </Form.Item>
            <Form.Item
              label="Button Link"
              name="button_link"
              className={`w-90 ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
              rules={[
                {
                  type: "url",
                  message: "The input is not valid!",
                },
                {
                  required: true,
                  message: "Please Enter your Button Link!",
                },
              ]}
            >
              <Input.TextArea
                type="text"
                className={`w-100  auth-form-input  ${
                  lightMode
                    ? "bg-gray ant-textarea-light "
                    : "bg-dark-gray  ant-textarea-dark                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    "
                }`}
                style={{
                  width: "90%",
                  height: "35px",
                  background: lightMode ? "white" : "#3B3F4F",
                  color: lightMode ? "black" : "white",
                }}
                placeholder="type here"
              />
            </Form.Item>
            <Form.Item
              name="visibility"
              label="Visibility"
              className={`w-90 ${
                lightMode ? "light-input-login" : "dark-input-login"
              }`}
            >
              <Radio.Group name="radiogroup">
                <Radio style={{ color: "white" }} value={true}>
                  Yes
                </Radio>
                <Radio style={{ color: "white" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </Form>
  );
}
export default NotificationModal;
