import React from "react";
import Featurecard from "./Featurecard";
import globetheme from "../../../assets/images/home/globe-theme.png";
import keyFeatBg from "../../../assets/images/bg/keyFeatBg.png";

function KeyFeatures({ lightMode }) {
  const cardContent = {
    starPortfolio: {
      title: "Superstar Portfolios",
      desc: "Track the actions of all your favourite ace investors in one place.",
      redirectState:'/superstars'
    },
    portAnalysis: {
      title: "Portfolio Analysis",
      desc: " Build a dream portfolio using world-class analytics.",
      redirectState:'/'
    },
    StockCompare: {
      title: "Stock Compare",
      desc: "Compare investment-worthy companies using 50+ parameters.",
      redirectState:'/stockCompare'
    },
    StockBuckets: {
      title: "Stock Buckets",
      desc: "Assorted baskets of stocks to make your sectoral and thematic purchases easier.",
      redirectState:'/Buckets'
    },
    Screener: {
      title: "Screener",
      desc: "Find winning stocks by creating personalised filters.",
      redirectState:'/screener'
    },
    Backtesting: {
      title: "Portfolio Backtesting",
      desc: "A tool for titan investors. Find out how your portfolio has performed.",
      redirectState:'/upcoming'
    },
  };
  return (
    <section className="home-ring-bg  my-100">
      <img
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0px",
          // opacity: ".6",
          objectFit: "fill",
        }}
        src={keyFeatBg}
        alt="bg"
      />
      <div className="max-w  bg-image-fit mx-auto px-15  w-100">
        <div className="d-flex flex-col align-items-center">
          <h3 className="fs-40-32 fw-700 mb-20">Key Features</h3>
          <p className="fs-16-14 text-align-center">
            All new Trade Brains Portal with powerful features to help you stand
            out from the average investors.
          </p>
        </div>
        <div className="w-100-50 display-md">
          <img className="mx-auto " src={globetheme} alt="globe" />
        </div>
        <div className="flex div-items-center">
          <div style={{ gap: "2rem" }} className="w-50-25 d-flex flex-col">
            <Featurecard lightMode={lightMode} cardContent={cardContent.starPortfolio}/>
            <Featurecard lightMode={lightMode} cardContent={cardContent.portAnalysis} />
            <Featurecard lightMode={lightMode} cardContent={cardContent.StockCompare}/>
          </div>
          <div className="w-100-50 display-lg">
            <img className="mx-auto " src={globetheme} alt="globe" />
          </div>
          <div style={{ gap: "2rem" }} className="w-50-25 d-flex flex-col">
            <Featurecard lightMode={lightMode} cardContent={cardContent.Screener}/>
            <Featurecard lightMode={lightMode} cardContent={cardContent.StockBuckets}/>
            <Featurecard lightMode={lightMode} cardContent={cardContent.Backtesting}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyFeatures;
