import React, { useEffect, useMemo, useState } from "react";
import { getBalanceStocksCompareStocks } from "../../../api/fetchClient";
import SelectOptions from "../../../components/ant/SelectOptions";
import CustomTable from "../../../components/CustomTable";
import convertTableStructure from "../convertTableStructure";
import { balanceSheetINS, BankCompareBalanceSheetdata, CompareBalanceSheetdata } from "../TableType";

function CompareBalanceSheet({
  lightMode,
  removedEmptyData,
  symbolsandnames,
  columns,
  CompanyType,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [BalanceSheetData, setBalanceSheetData] = useState();
  const [selectd, setselectd] = useState();
  const [LatestYear, setLatestYear] = useState();

  useEffect(() => {
    let yearstr = `year=${selectd}`;
    const getData = async () => {
      setIsLoading(true);
      getBalanceStocksCompareStocks(
        removedEmptyData,
        selectd ? yearstr : "",
        CompanyType
      ).then(async (res) => {
        LatestYear === undefined && setLatestYear(res?.data?.year);
        setBalanceSheetData(res.data);
        setIsLoading(false);
      });
    };
    getData();
  }, [selectd, CompanyType]);

  const yeararraay = [];

  for (var i = 0; i < 5; i++) {
    yeararraay.push({ value: LatestYear - i, label: LatestYear - i });
  }

  console.log("BalanceSheetData",BalanceSheetData);
  const returnsTableData = useMemo(() => {
    if (BalanceSheetData === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        BalanceSheetData?.stocks,

        BalanceSheetData?.type === 'INS' ? balanceSheetINS :  BalanceSheetData?.type === 'BNK'  ? BankCompareBalanceSheetdata  : CompareBalanceSheetdata,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [BalanceSheetData,lightMode]);

  return (
    <div id="Balance Sheet">
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c "> Balance Sheet</h2>
        </div>
        {LatestYear && (
          <div className="d-flex flex-col align-items-end">
            <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setselectd}
              defaultSelect={LatestYear}
              width={116}
            />
            <p className="fs-s-12 mb-0 text-gray mt-15">*Values in Cr.</p>
          </div>
        )}
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          loading={isLoading}
          columns={returnsTableData?.columns}
          scrollable={true}
          data={returnsTableData?.tableBodyData}
        />
      </div>
    </div>
  );
}

export default CompareBalanceSheet;
