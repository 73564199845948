import React, { useState } from 'react'
import { Form, Modal,Input } from 'antd'
import usePostFetch from '../../hooks/usePostFetch';
import { postSignupData } from '../../api/fetchClient';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../../assets/images/logo/googleIcon.png'
import GoogleAuth from './GoogleAuth';

function Signup({popupStatus,onCloseHandler}) {
  const navigate=useNavigate()
  const [form] = Form.useForm();
  const { isLoading, apiData, serverError,setbodyData}=usePostFetch('rest-auth/registration/')
  const [apiError, setapiError] = useState({})
  const [apiLoader, setapiLoader] = useState('NONE')
  const onSubmit=async(values)=>{
    setapiLoader('PENDING')
   try{
     await postSignupData(values).then((resp)=>{
       console.log(resp);
       setapiError({success:resp.data.detail})
       setapiLoader("SUCCESS")
     })
   }
   catch(error){
     const errorMsg= Object.values(error.response.data)
     setapiError(errorMsg[0])
     setapiError({error:errorMsg[0]})
    setapiLoader('NONE')
   }
  }

  return (
    <div>
        <Modal
                        centered
                        closable={false}
                       width='800px'
                       bodyStyle={{padding:'0px'}}
                        visible={popupStatus==="SIGNUP"}
                        footer={null}
                        onCancel={()=>onCloseHandler('/')}
                        >
                       <div className='d-flex flex-col align-items-center ff-poppins w-100  p-50-16 bg-popup-img'>
                           <p className='fw-500 fs-s-20'>Join Trade Brains Portal</p>
                       {/* <form className='w-60-80'> */}
                       <Form
                            className='w-60-80'
                            form={form}
                            name="register"
                            onFinish={onSubmit}
                            scrollToFirstError
                          >
                             <Form.Item
                             style={{marginBottom:'12px'}}
                              name="name"
                              rules={[
                                {
                                  type: 'name',
                                  message: 'Please Enter your Name'
                                },
                                {
                                  required: true,
                                  message: 'Please Enter your Name',
                                },
                              ]}
                            >
                            <Input type='text' className='w-100  auth-form-input' placeholder='Name'/>
                            </Form.Item>
                            <Form.Item
                             style={{margin:'6px 0px'}}
                              name="email"
                              rules={[
                                {
                                  type: 'email',
                                  message: 'The input is not valid E-mail!'
                                },
                                {
                                  required: true,
                                  message: 'Please Enter your E-mail!',
                                },
                              ]}
                            >
                        <Input type='text' className='w-100  auth-form-input' placeholder='Email'/>
                        </Form.Item>
                        <Form.Item
                             style={{marginBottom:'0px'}}
                              name="password1"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input your password!',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value ||(value.length>=8&&value.length<=16)) {
                                      return Promise.resolve();
                                    }
                      
                                    return Promise.reject(
                                      new Error('Password should have minimum 8 and maximum 16 characters')
                                    );
                                  },
                                }),
                              ]}
                              hasFeedback
                            >
                        {/* <div className='w-100 relative d-flex align-items-center '> */}
                        <Input.Password type='text' className='w-100 h-40px auth-form-input'   placeholder='Enter Password'/>
                          {/* <span style={{right:'10px'}}  className='ff-rubik absolute text-skyblue fw-500 pointer'>Show</span> */}
                        {/* </div> */}
                        </Form.Item>
                        <Form.Item
                             style={{marginBottom:'0px'}}
                              name="password2"
                              dependencies={['password']}
                               hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue('password1') === value) {
                                      return Promise.resolve();
                                    }
                      
                                    return Promise.reject(
                                      new Error('The two passwords that you entered do not match!')
                                    );
                                  },
                                }),
                              ]}
                            >
                        {/* <div className='w-100 relative d-flex align-items-center '> */}
                        <Input.Password type='text' className='w-100 h-40px auth-form-input'   placeholder='Confirm Password'/>
                          {/* <span style={{right:'10px'}}  className='ff-rubik absolute text-skyblue fw-500 pointer'>Show</span>
                        </div> */}
                        </Form.Item>
                        {apiError.success&&<div style={{color:'#28a745',textAlign:'center'}}>{apiError.success}</div>}
                        {apiError.error&&<div style={{color:'#ff4d4f',textAlign:'center'}}>{apiError.error}</div>}
                       {
                      apiLoader==="NONE"?
                      <button type='submit' style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white pointer'>Get Started</button>
                      :
                      (apiLoader==="PENDING"?
                      <div  style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white text-center'>Signing In...</div>
                      :
                      <div  style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white text-center'>Done</div>
                      )
                       } 
                       </Form>
                       {/* <div style={{margin:'6px 0px'}} className='center w-60-80 py-10 br-5 bg-white b-gray pointer'>
                        <img src={googleIcon} alt="icon" className='mx-10px h-20 w-20'/>
                        <span>Sign up with Google</span>
                          </div> */}
                       <GoogleAuth title="Sign up with Google" onCloseHandler={onCloseHandler}/>
                       <p style={{color:'#6c757d',textAlign:'center'}} className="fs-s-12 w-60-80 my-10">By signing up, you have read and agreed to our  
                       <span style={{color:'#343a40'}}> Privacy</span> and <span style={{color:'#343a40'}}>Terms & Conditions</span>.</p>
                       <p className='fw-700'>Already have an account ? <span className='pointer' onClick={()=>onCloseHandler("LOGIN")} style={{color:"#28a745"}}>Login</span></p>
                       </div>
                        </Modal>
    </div>
  )
}

export default Signup