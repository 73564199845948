import React from "react";
import lockImg from '../../assets/images/lock.svg'
import premiumIcon from '../../assets/images/premiumIcon.svg'
import svgSheet from "../../assets/svg/svgSheet";
function BucketCard({ bucketname, stockNum, isPremium, cardImg, clickFn }) {
  return (
    <div onClick={clickFn} className="w-100 pointer ">
      <div className="w-100  relative overlay-main">
        <img
          src={cardImg}
          loading="lazy"
          alt="thumbnail"
          className="w-100 br-5  bucket-card-main  bucket-img"
          // style={{ opacity: isPremium ? 0.6 : 1 }}
        />
        {isPremium && (
          <div style={{top:'10px',right:'10px',width:'32px',height:'32px'}} className="absolute">
             {svgSheet.premiumIcon}
          </div>
          // <div className="bucket-overlay ">
          //   <div className="p-center">
          //     {/* {svgSheet.colorLock} */}
          //   <img src={premiumIcon} className='mx-auto'/>
          //     <button
          //       style={{ padding: "9px" }}
          //       className={`pointer fs-14-10 btn-bg-primary text-white table-shadow text-white br-5 fw-500`}
          //     >
          //      View Pricing
          //     </button>
          //   </div>
          // </div>
        )}
      </div>
      <p className="fs-18-14  my-5px">{bucketname}</p>
      {stockNum&&<p className="text-gray mb-0 fs-14-12">{stockNum} Stocks</p>}
    </div>
  );
}

export default BucketCard;
