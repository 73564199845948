import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SelectOptions from "../../components/ant/SelectOptions";
import Movetowatchlist from "../../components/Movetowatchlist";
import { getScreenerStore } from "../../redux/reducers/screenerSlice";
import FilterResultTable from "../screener/table section/FilterResultTable";

function RightSectionInedx({
  lightMode,
  filterTypeArr,
  postSectorArr,
  postIndexArr,
}) {
  const screenerStore = useSelector(getScreenerStore);
  const [watchlistTickToggle, setwatchlistTickToggle] = useState(false);
  const [watchlistStocks, setwatchlistStocks] = useState([]);
  const [toggleColumnModal, settoggleColumnModal] = useState(false);

  // create appiled filter arr
  const defaultChecked = useMemo(() => {
    const particularArr = filterTypeArr?.map((data) => data.label);
    return particularArr;
  }, [filterTypeArr]);
  return (
    <>
      <div>
        <div className="d-flex flex-col-row justify-content-start">
          <div className="flex flex-col-row-reverse ">
            <div className="flex align-items-center">
              {watchlistTickToggle && (
                <div className="flex align-items-center">
                  <p className="mb-0 fs-14-12 ">
                    {watchlistStocks?.length} Selected
                  </p>
                  <Movetowatchlist
                    lightMode={lightMode}
                    symbol={watchlistStocks}
                  >
                    <button className="  btn-bg-primary text-white br-3 fw-500  p-5 fs-14-12">
                      + Add to Watchlist
                    </button>
                  </Movetowatchlist>
                </div>
              )}
            </div>
          </div>
        </div>
        <FilterResultTable
          filterTypeArr={filterTypeArr}
          checkedColumns={[]}
          lightMode={lightMode}
          screenerStore={screenerStore}
          postSectorArr={postSectorArr}
          postIndexArr={postIndexArr}
          watchlistTickToggle={watchlistTickToggle}
          setwatchlistTickToggle={setwatchlistTickToggle}
          watchlistStocks={watchlistStocks}
          setwatchlistStocks={setwatchlistStocks}
        />
      </div>
    </>
  );
}

export default RightSectionInedx;
