import React from "react";
import { Link } from "react-router-dom";

function QueryInput({ lightMode, inputQuery, setinputQuery }) {
  return (
    <div
      className={`relative w-100 br-5 h-70vh-60vh  bg-transparent  relative  ${
        lightMode ? "border2px-light-mode" : "border2px-dark-mode"
      }`}
    >
      <textarea
        value={inputQuery}
        onChange={(e) => setinputQuery(e.target.value)}
        placeholder="// Please enter your query here
Example:
Market Cap > 1000 , PE < 20 , 
Debt < 100 , ROE = 20"
        className="w-100  border-none bg-transparent"
        type="text"
        style={{ height: "90%" }}
      />
      <Link to={`/screener/query/Market Cap (Rs Cr) > 30000`}>
      <div
        className="w-100 fs-18-14"
        style={{ position: "absolute", bottom: "5px", left: "3px" }}
      >
        <button
          style={{ width: "98%" }}
          className="h-45  btn-bg-primary text-white br-3 fw-600  fs-16-14"
        >
          Run Query
        </button>
      </div>
      </Link>
    </div>
  );
}

export default QueryInput;
