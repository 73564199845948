import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../redux/reducers/authSlice";
import numberWithCommas from "../../utilityFn/numberWithCommas";
// import { PostPlanSubScription } from "../../api/fetchClient"

function PlanCard({ id, lightMode, name, Price, disc, initial, currentPlan }) {
  const auth = useSelector(authStore);
  const navigate = useNavigate();
  console.log("auth", auth?.userData?.access_token);
  const arrayOFPlan = {
    basic: "Life Time Free",
    "3months": "3 Months",
    "6months": "6 Months",
    year: "1 Year",
  };
  console.log("arrayOFPlan", arrayOFPlan);
  const PoseSubScription = (id) => {
    const data = {
      plan: id,
      coupon_code: "",
      status: "",
    };
    console.log("dafafafa", data);
    // PostPlanSubScription(data)
    //   .then((res) => {
    //     if (res.data.request_url) {
    //       window.location.href = res.data.request_url;
    //     }
    //   })
    //   .catch((err) => console.log(err));
    navigate(`summary/${id}/`)
    
  };
  return (
    <div
      className={` w-25p-100 p-20  relative justify-content-between d-flex flex-col ${
        lightMode
          ? "shadow-div bg-white"
          : "table-shadow card-drop-dark b1px-dark-mode"
      }`}
    >
      {"Whales" === name && (
        <div
          className={`absolute d-flex justify-content-center text-white popular-card-icon ${
            lightMode ? "btn-bg-secondary white" : "btn-bg-secondary "
          }`}
        >
          <p style={{ padding: "3px" }} className="mb-0 fw-500 my-5">
           Most Popular
           {/* Republic Day Offer */}
          </p>
        </div>
      )}
      <p className="fs-s-20 fw-700 mt-10 gradient-text-blue ">{name}</p>
      {/* <p className="mb-0 ff-lato"></p> */}
      <div className="d-flex">
        <p className=" ml-10    mb-0 ff-lato lineThrough text-gray fw-500 fs-s-16">
          {initial ? "₹" + initial : ""}
        </p>
        <p className=" ml-5   mb-0 ff-lato  fw-500 fs-s-16">
          {initial ? (((initial - Price) / initial) * 100).toFixed(0) : ""}
          {initial && "% OFF"}
        </p>
      </div>
      <div className="d-flex mb-0 align-items-center">
        <p className="mb-0 ff-lato">₹</p>
        <p className="fs-s-30 ml-5 mr-5 fw-700 mb-0 ">{numberWithCommas(Price)}</p>
        <p className="fs-s-16 mb-0">/ {arrayOFPlan[id]}</p>
      </div>
      <p className="mt-30">{disc}</p>
      <div className={id === "basic" ? "visible-hidden w-100" : "w-100 " }>
      {
        (currentPlan === id ? (
          <button
            onClick={() => {
              auth?.userData?.access_token
                ? PoseSubScription(id)
                : navigate("/login",{state:'/getpremium'});
            }}
            className={` h-35px fw-500 fs-s-14 h-45 w-100 br-5 btn-bg-primary text-white`}
          >
            Subscribed
          </button>
        ) : 
        (
          <button
            onClick={() => {
              auth?.userData?.access_token
                ? PoseSubScription(id)
                : navigate("/login",{state:'/getpremium'});
            }}
            className={` h-35px fw-500 fs-s-14 h-45  w-100 btn-blue-border white`}
          >
            Subscribe
          </button>
        )
        )
        }
        </div>
      <div className="packge-inclusion w-100"></div>
    </div>
  );
}

export default PlanCard;
