import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import bucketIcon from "../../assets/images/bucketIcon.svg";
import testImg from "../../assets/images/testImage.png";
import quizIcon from "../../assets/images/quizIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import DiscoundCoupon from "./DiscoundCoupon";
import Fingrad from "./../../assets/images/Fingrad.png";
import { authStore } from "../../redux/reducers/authSlice";
import { useSelector } from "react-redux";
import PremiumRedirectModal from "../PremiumRedirectModal";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";

function ToolsTogglecard({
  toolsToggle,
  setToolsToggle,
  lightMode,
  authStatus,
  setPopupStatus,
}) {
  const [Visible, setVisible] = useState(false);
  const UserAuth = useSelector(authStore);
  const navigate = useNavigate();
  const navRedirect = (path) => {
    if (authStatus) {
      navigate(path);
      setToolsToggle("NONE");
    } else {
      // navigate("/login", { state: path === "/portfolio" ? "/" : path });
      // // setPopupStatus("JOIN");
      // setToolsToggle("NONE");
      navigate(path);
    }
  };

  const ListOfDropdownData = [
    {
      name: "Portfolio Analysis",
      disc: "Get visual reports of your portfolio similar to what million dollar investment managers get!",
      Logo: svgSheet.portFolioSvg,
      link: "portfolio",
      premium: true,
    },
    {
      name: "Screener",
      disc: "Stock search made easier. This powerful screener helps you separate glitter from gold.",
      Logo: svgSheet.ScreenerSvg,
      premium: false,
      link: "screener",
    },
    {
      name: "Stock compare",
      disc: "Compare stocks side to side and pick the winner!",
      Logo: svgSheet.CompareStocksSvg,
      link: "stockCompare",
    },
    {
      name: "Buckets",
      disc: "Find ready made bucket of stocks across several themes, and sectors. ",
      Logo: svgSheet.BuckectNewSvg,
      link: "Buckets",
    },
    {
      name: "Watchlist",
      disc: "Track your favourite stocks to find the best opportunity.",
      Logo: svgSheet.WatchListNewSvg,
      link: "watchlist",
    },
    {
      name: "Superstar Portfolio",
      disc: "Find out what the big names like the Jhunjhunwala's of the investing world are buying.",
      Logo: svgSheet.SuperStarPorNewSvg,
      link: "superstars",
    },
    // {
    //   name: "Calculators",
    //   disc: "Find out what the big names like the Jhunjhunwala's of the investing world are buying.",
    //   Logo: svgSheet.calculatorNavbarIcon,
    //   link: "calculator",
    // },
  ];

  return (
    <Drawer
      style={{ top: toolsToggle === "Tools" ? `${74+bannerOffsetCalc(UserAuth)}px` : "0px" }}
      //     drawerStyle={{padding:'0px'}}
      drawerStyle={{
        background: lightMode ? "white" : "#292E3F",
        color: lightMode ? "black" : "white",
      }}
      headerStyle={{ marginInline: "16px", padding: "0px" }}
      bodyStyle={{ margin: "16px 0px 0px 0px", padding: "0px" }}
      contentWrapperStyle={{ width: "99%" }}
      // title="Tools"
      placement={"top"}
      closable={false}
      onClose={() => setToolsToggle("NONE")}
      visible={toolsToggle === "Tools" ? true : false}
      key="tools"
      height="auto"
      zIndex={500}
    >
      <div
        className=""
        //   style={{ maxWidth: "1410px", marginInline: "auto", padding: "0px" }}
      >
        <div className="flex ff-poppins ff-poppins max-w mx-auto h-100  px-15 relative">
          <div
            style={{ width: "70%" }}
            className="w-70 bbr-blue d-flex flex-wrap"
          >
            {/* <div
              className="w-100  fw-500 fs-s-20 mt-15 fw-700 d-flex align-items-center"
              style={{ height: "50px" }}
            >
              Top Features
            </div> */}
            <div className="d-flex flex-wrap my-50  justify-content-between">
              {ListOfDropdownData.map((items,i) => (
                <div key={i}
                  onClick={() => {
                    items?.premium
                      ? UserAuth?.userData?.user?.plan?.planId === "basic"
                        ? setVisible(true)
                        : navRedirect(`/${items?.link}`)
                      : navRedirect(`/${items?.link}`);
                  }}
                  className={` h-100px mt-10 flex p-10 ${
                    lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
                  } pointer`}
                  style={{ width: "31%" }}
                >
                  <p> {items.Logo}</p>
                  <div>
                    <p className="fw-700 fs-s-14 line-h-18">{items?.name}</p>
                    <p className="fs-s-12 line-h-18 pr-8">{items.disc}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* <div
              className="w-100  fw-500 fs-s-20 mb-10 fw-700 d-flex align-items-center"
              // style={{ height: "50px" }}
            >
              Market
            </div> */}
            {/* <div className="d-flex flex-wrap  justify-content-between">
            {MarketDropdownData.map((items) => (
              <div
              style={{width:"31%"}}
                onClick={() => navRedirect(`/${items?.link}`)}
                className={` h-100px mt-10 flex p-10 ${
                  lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
                } pointer`}
              >
                <p> {items.Logo}</p>
                <div>
                  <p className="fw-700 fs-s-14 line-h-18">{items?.name}</p>
                  <p className="fs-s-12 line-h-18 pr-8">{items.disc}</p>
                </div>
              </div>
            ))}
            </div> */}
          </div>
          {/* Knowledge Center  */}
          <div className="w-30 d-flex-col mt-25">
            <div className="w-100 py-15 flex align-items-center">
              <div
                className="w-100  fw-500 fs-s-20 mt-15 fw-700 d-flex align-items-center"
                // style={{ height: "50px" }}
              >
                Start Learning
              </div>
            </div>
            <div className="">
              <DiscoundCoupon lightMode={lightMode} />
            </div>
            {/* <div
              //   onClick={() => navRedirect(`/${items?.link}`)}
              className={`w-100 h-100px mt-30  flex  ${
                lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
              } pointer`}
            >
              <p> {svgSheet.KnowlegeCenter}</p>
              <div>
                <p className="fw-700 fs-s-14 line-h-18">Knowledge Center</p>
                <p className="fs-s-12 line-h-18">
                  This is an extensive collection of questions answered on
                  everything about the stock market.{" "}
                </p>
              </div>
            </div> */}
            <div
              //   onClick={() => navRedirect(`/${items?.link}`)}
              className={`w-100 h-100px mt-10 flex py-10 pr-10 mb-5  ${
                lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
              } pointer`}
            >
              <img width={22} height={22} src={Fingrad} alt=""></img>
              <a href="https://joinfingrad.com/subscribe" target="_blank">
                <div>
                  <p className="fw-700 fs-s-14 line-h-18">Fingrad</p>
                  <p className="fs-s-12 line-h-18 ">
                    New to the world of investing? Learn everything about your
                    finances from industry experts here!
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          style={{ height: "50px" }}
          className={` d-flex align-items-center justify-content-around ${
            lightMode ? "bg-gray " : "bluedark-gradient"
          } `}
        >
          {!authStatus && (
            <div className="d-flex align-items-center">
              <p className="fw-700 fs-s-16  mb-0">Ready to get started ?</p>
              <p onClick={()=>navigate('/signup')}
                className={`mb-0 ml-10 fw-700 pointer ${
                  lightMode ? "" : "text-btn-dark"
                }`}
              >
                Sign up for free
              </p>
            </div>
          )}
          <div>
            <p
              onClick={() => navigate("/getpremium")}
              className="fw-700 fs-s-16  mb-0 underline pointer"
            >
              View Pricing
            </p>
          </div>
        </div>
      </div>
      <PremiumRedirectModal
        visible={Visible}
        setVisible={setVisible}
        modalPlan="portFolio"
      />
    </Drawer>
  );
}

export default ToolsTogglecard;
