import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import NewsComponent from "./NewsComponent";
import { getNews } from "../../api/fetchClient";
import NewsBars from "./NewsBars";
import loadergif from "../../assets/images/loadergif/Free-Content.gif";
import svgSheet from "../../assets/svg/svgSheet";

function NewsCategorys() {
  const { category } = useParams();
  const { lightMode } = useSelector(getThemeMode);
  const [Data, setData] = useState([]);
  const [LoadMoreCount, setLoadMoreCount] = useState(1);
  const [loaderMoreloader, setloaderMoreloader] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const locations = useLocation();
  useEffect(() => {
    setloaderMoreloader(true);

    getNews(LoadMoreCount, perPage, locations?.state).then((res) => {
      setloaderMoreloader(false);
      setData([...Data, ...res.data]);
    });
  }, [LoadMoreCount]);
  const headerIcon =(category) => {
    switch (category) {
      case "Editors Picks":
       return svgSheet.allStocksLogo;
      case "Company Results":
      return  svgSheet.compResultIcon;
      case "Corporate Action":
      return  svgSheet.corpAction;
      case "Economy News":
       return <div style={{width:'32px',height:'32px'}}>{svgSheet.newsIcon}</div>;
      default:<div></div>;
        break;
    }
  }
  console.log(headerIcon(category));
  const containerdata = Data?.slice(5,-1);
  const defaultImg =
    "https://tradebrains.in/features/wp-content/uploads/2022/07/Portal-Logo-Cover-Image.jpg";

  return (
    <div className="my-body">
      <div className="ff-poppins w-100 max-w mx-auto px-15 h-95 ">
        <div className="d-flex align-items-center mb-30">
          <p  className="fs-36-20 mb-0 fw-700 d-flex  lh-36 mr-5">{headerIcon(category)}</p>
        <h1 className="fs-36-20  mb-0 fw-700 d-flex  lh-36" >{category}</h1>
        </div>

        <div className="w-100 d-flex-row-col justify-content-between">
          <div className="w-49-100">
            <NewsComponent
              lightMode={lightMode}
              img={Data[0]?.yoast_head_json?.og_image[0].url}
              title={Data[0]?.title?.rendered}
              fontSize={"fs-s-18"}
              url={Data[0]?.link}
              id={Data[0]?.id}
              isHeader={true}
            />
          </div>

          <div className="only-PC-view w-49-100">
            <div className=" d-flex justify-content-between flex-col h-100 ">
              <NewsBars
                lightMode={lightMode}
                img={Data[1]?.yoast_head_json?.og_image[0].url}
                title={Data[1]?.title?.rendered}
                fontSize={"fs-s-18"}
                url={Data[1]?.link}
                id={Data[1]?.id}
              />
              <NewsBars
                lightMode={lightMode}
                img={Data[2]?.yoast_head_json?.og_image[0].url}
                title={Data[2]?.title?.rendered}
                fontSize={"fs-s-18"}
                url={Data[2]?.link}
                id={Data[2]?.id}
              />

              <NewsBars
                lightMode={lightMode}
                img={Data[3]?.yoast_head_json?.og_image[0].url}
                title={Data[3]?.title?.rendered}
                fontSize={"fs-s-18"}
                url={Data[3]?.link}
                id={Data[3]?.id}
              />
              <NewsBars
                lightMode={lightMode}
                img={Data[4]?.yoast_head_json?.og_image[0].url}
                title={Data[4]?.title?.rendered}
                fontSize={"fs-s-18"}
                url={Data[4]?.link}
                id={Data[4]?.id}
              />
            </div>
          </div>
          <div className="only-mobile-view">
            <div className="d-flex justify-content-between my-40 flex-wrap">
              <div className="w-32-48 mb-10 ">
                <NewsComponent
                  title={Data[1]?.title?.rendered}
                  img={Data[1]?.yoast_head_json?.og_image[0].url}
                  lightMode={lightMode}
                  url={Data[1]?.link}
                  id={Data[1]?.id}
                />
              </div>
              <div className="w-32-48 mb-10 ">
                <NewsComponent
                  title={Data[2]?.title?.rendered}
                  img={Data[2]?.yoast_head_json?.og_image[0].url}
                  lightMode={lightMode}
                  url={Data[2]?.link}
                  id={Data[2]?.id}
                />
              </div>

              <div className="w-32-48 mb-10 ">
                <NewsComponent
                  title={Data[3]?.title?.rendered}
                  img={Data[3]?.yoast_head_json?.og_image[0].url}
                  lightMode={lightMode}
                  url={Data[3]?.link}
                  id={Data[3]?.id}
                />
              </div>
              <div className="w-32-48 mb-10 ">
                <NewsComponent
                  title={Data[4]?.title?.rendered}
                  img={Data[4]?.yoast_head_json?.og_image[0].url}
                  lightMode={lightMode}
                  url={Data[4]?.link}
                  id={Data[4]?.id}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between my-40 flex-wrap">
          {containerdata?.map((items, i) => (
            <div className="w-32-48 mb-10">
              <NewsComponent
                title={items?.title?.rendered}
                img={
                  items?.yoast_head_json?.og_image
                    ? items?.yoast_head_json?.og_image[0]?.url
                    : defaultImg
                }
                lightMode={lightMode}
                url={items?.link}
                id={items.id}
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center ">
          {loaderMoreloader ? (
            <div style={{ height: "20px" }}>
              <img alt="" width={"30"} src={loadergif}></img>
            </div>
          ) : (
            <button
              onClick={() => {
                setLoadMoreCount(LoadMoreCount + 1);
                setPerPage(9);
              }}
              className="pointer text-white btn-bg-primary animation-button br-3 p-5 w-200px"
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsCategorys;
