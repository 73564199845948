import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";

function StockListTable({ lightMode, isLoading,tableData,sortState,setsortState }) {
  const [tableYTD, setTableYTD] = useState({
    text: "Ch YTD %",
    value: "ytd"
  });
  const StockConstituents = [
    {
      title:
      (
        <SortHeader
        label="Company Name"
        header="company_name"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "company_name",
      fixed: "left",
      width: "70px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              <Link to={`/stock/${record?.symbol==="0"?record.scripcode:record?.symbol}/consolidated`}>{text}</Link>
            </div>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="MCap (Cr)"
        header="mcap"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "mcap",
      width: "50px",
      render(text, record) {
        const formatedMcap = (text / 10 ** 7).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(Number(formatedMcap))}
            </span>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="CMP"
        header="close"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "close",
      width: "60px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                ₹ {numberWithCommas(text)}
              </span>

              <span
                className="ff-lato"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "10px",

                  color: `${redGreenColorPicker(record?.per_change, lightMode)}`,
                }}
              >
                {record?.per_change > 0 && "+"}
                {record?.per_change?.toFixed(2) + "%"}
              </span>
            </div>
          ),
        };
      },
    },
    {
      title: 
      (
        <SortHeader
        label="PE Ratio (TTM)"
        header="pe"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "pe",
      width: "50px",
      render(text, record) {
        const numberTwoFixed = Number(text?.toFixed(2));
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {numberWithCommas(numberTwoFixed)}
            </span>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="ROE"
        header="roe"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "roe",
      width: "50px",
      render(text, record) {
        const numberTwoFixed = Number(text?.toFixed(2));
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {numberWithCommas(numberTwoFixed)}%
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
        label={tableYTD.text}
        header={tableYTD.value}
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      
      // <p className="fs-s-16 fw-600 mb-0">{tableYTD.text}</p>,
      dataIndex:tableYTD.value,
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderLeft: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text, lightMode),
              }}
            >
              {text > 0 && "+"}
              {text?.toFixed(2) + "%"}
            </span>
          ),
        };
      },
      filterDropdown: (props) => {
        return (
          <div className={lightMode ? "bg-white" : "bg-dark-gray text-white"}>
            {props?.filters?.map((data, i) => (

              
              data.text !== tableYTD.text&&<p
                key={i}
                onClick={() => setTableYTD(data)}
                style={{ padding: "5px 10px" }}
                className="fs-s-14 fw-600  mb-0 pointer"
              >
                {data.text}
              </p>
            ))}
          </div>
        );
      },
      filters: [
        {
          text: "Change YTD %",
          value: "ytd",
        },
        {
          text: "Change 52W Low %",
          value: "change_52W_low",
        },
        {
          text: "Change 52W High %",
          value: "change_52W_high",
        },
      ],
      ellipsis: false,
    },
  ];

  return (
    <div
      className={`table-shadow  ${  
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
      <CustomTable
        columns={StockConstituents}
        data={tableData}
        scrollable={true}
        loading={isLoading}
      />
    </div>
  );
}

export default StockListTable;
