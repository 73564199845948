import React, { useState } from "react";
import { Link } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import CustomTable from "../../components/CustomTable";
import useGetFetch from "../../hooks/useGetFetch";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function MarketMoverTable({ lightMode }) {
  const [tabName, setTabName] = useState("Top Gainers");
  const tabValues = ["Top Gainers", "Top Losers", "Most Traded Stocks"];
  //   get index movers
  const { isLoading, serverError, apiData } = useGetFetch(
    StockEndpoints.marketMovers("NIFTY")
  );
  const moverColumns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
      dataIndex: "comp_name",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              <Link className="overFlow-text" to={`/stock/${record?.symbol}/consolidated`}>{text}</Link>
            </div>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">CMP</p>,
      dataIndex: "close",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Change </p>,
      width: "50px",
      dataIndex: "change",
      render(text, record) {
        const textwithComma = text.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text >= 0 ? "+" : "-"} ₹{" "}
              {text > 0
                ? numberWithCommas(Number(textwithComma))
                : numberWithCommas(Math.abs(Number(textwithComma)))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Percent</p>,
      dataIndex: "percent",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: redGreenColorPicker(text, lightMode),
              }}
            >
              {text >= 0 ? "+" : "-"}   {text > 0
                ? numberWithCommas(Number(text)?.toFixed(2))
                : numberWithCommas(Math.abs(Number(text)?.toFixed(2)))}%
            </span>
          ),
        };
      },
    },
  ];
 const volumeColumn= {
  title: <p className="fs-s-16 fw-600 mb-0">Volume</p>,
  dataIndex: "volume",
  width: "50px",
  render(text, record) {
    return {
      props: {
        style: {
          background: lightMode ? "#F2F6F8 " : "#3B3F4F",
          borderRight: lightMode
            ? "1px solid #C9D7DE"
            : "1px solid #545E78",
          borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
          borderBottom: "none",
        },
      },
      children: (
        <span
          className="ff-lato"
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: lightMode ? "black" : "white",
          }}
        >
          {numberWithCommas(text)}
        </span>
      ),
    };
  },
}

  return (
    <div style={{minHeight:'447px'}}
      className={` relative ${
        lightMode
          ? "bg-gray card-drop-light-shadow"
          : "bg-dark card-drop-dark-shadow"
      } w-100 p-10md-20lg`}
    >
      <div
        style={{ height: "40px", overflowX: "auto", overflowY: "hidden" }}
        className="bg-white w-100"
      >
        <div
          style={{ minWidth: "500px" }}
          className={`d-flex h-100  align-items-center  justify-content-around ${
            lightMode ? "" : "bg-dark"
          }`}
        >
          {tabValues.map((items, i) => (
            <div
              key={i}
              style={{
                minWidth: "130px",
                width: "33%",
                borderBottom:
                  tabName === items &&
                  `1px solid  ${lightMode ? "black" : "#ffff"}`,
              }}
              className="pointer h-100 d-flex align-items-center  justify-content-center "
              onClick={() => setTabName(items)}
            >
              <div key={i}>
                <p
                  className="mb-0 fs-s-15"
                  style={{ fontWeight: tabName === items ? "600" : "400" }}
                >
                  {items}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="Tab-content">
        {tabName === "Top Gainers" ? (
           <div style={{margin: "23px 0px 20px 0px"}}
           className={`table-shadow  ${
             lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
           }`}
         >
           <CustomTable
             columns={moverColumns}
             data={apiData?.gainers}
             scrollable={true}
             scrollLimit={500}
             loading={isLoading}
           />
         </div>
        ) : tabName === "Top Losers" ? (
            <div style={{margin: "23px 0px 20px 0px"}}
            className={`table-shadow  ${
              lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
            }`}
          >
            <CustomTable
              columns={moverColumns}
              data={apiData?.losers}
              scrollable={true}
              scrollLimit={500}
              loading={isLoading}
            />
          </div>
        ) : (
          <div style={{margin: "23px 0px 20px 0px"}}
          className={`table-shadow  ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            columns={[...moverColumns,volumeColumn]}
            data={apiData?.volume_movers}
            scrollable={true}
            scrollLimit={500}
            loading={isLoading}
          />
        </div>
        )}
      </div>
     {
      tabName !== "Most Traded Stocks" &&
      <Link to={ tabName === "Top Gainers"? `/marketstats/gainers`:'/marketstats/losers'}  style={{bottom:'10px',right:'20px'}} className="absolute mb-0 fs-s-12 d-flex align-self-center link-hover-underline">View All</Link>
     }
    </div>
  );
}

export default MarketMoverTable;
