import React, { useEffect, useState } from "react";
import StockDetailsForm from "./StockDetailsForm";
import { Form } from "antd";
import {
  getStockToStockDetails,
  putStockToStockDetails,
} from "../../../../api/fetchClient";
import { useNavigate, useParams } from "react-router-dom";

function EditStockDetails() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [stockData, setStockData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getStockToStockDetails(id)
      .then((response) => {
        const stockDetails = response.data;
        setStockData(stockDetails);
        form.setFieldsValue({
          ...stockDetails,
          company: stockDetails.company_name, // Set the selected value to 'company' field
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, form]);

  return (
    <>
      {stockData && <StockDetailsForm form={form} initialData={stockData} 
      disableautocomplete={true}/>}

      <div className="d-flex justify-content-end">
        <button
          type="primary"
          className="btn-bg-primary mb-10 p-10 h-45 fw-500 br-5 text-white"
          onClick={() => form.submit()}
        >
          Update Details
        </button>
      </div>
    </>
  );
}

export default EditStockDetails;
