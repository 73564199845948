import { Modal } from "antd";
import React from "react";

function AddKeywordModal({ visible, setVisible, onConfirm, lightMode }) {
  return (
    <Modal
      style={{ width: "500px" }}
      width={"500px"}
      visible={visible}
      centered
      wrapClassName={!lightMode && "modelClassname"}
      onCancel={() => {
        setVisible(false);
      }}
      footer={false}    
    >
      <p className="fs-18-16 fw-600 ">Add Keywords</p>
      <form onSubmit={onConfirm}>
        <input
          name="keyword"
          className={`w-100 h-40px auth-form-input  ${
            lightMode
              ? "bg-gray email-input-login-page-light input-with-beforeFeild "
              : "bg-dark-gray email-input-login-page"
          }`}
          type="text"
        />
        <div className="flex justify-content-end">
          <button
            type="submit"
            className="btn-bg-primary mt-10   p-10 br-5 fw-500 text-whit "
          >
            Confirm
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddKeywordModal;
