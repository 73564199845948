import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { getNewsSymbolsData, getStockLTP } from "../../api/fetchClient";
import demoing from "../../assets/images/Demo/image10.png";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
function NewsBars({lightMode,width,height,title,img,fontSize,url,id}) {
const [changePrice, setChangePrice] = useState()
const [Compname, setCompname] = useState()
useEffect(() => {
  if(id=== undefined){
    return
  }
    getNewsSymbolsData(id).then((res)=>{
      setCompname(res?.data?.name);
      getStockLTP(res?.data?.type,res?.data?.symbol).then((res)=>{
        setChangePrice(res?.data[0].close);
      })
    })
  
}, [id])
const defaultImg =
"https://tradebrains.in/features/wp-content/uploads/2022/07/Portal-Logo-Cover-Image.jpg";

const convertTitleUnicode = title
?.replaceAll("&#8217;", '‘')  
.replaceAll("#038;", "")
.replaceAll("&#8216;", "")
.replaceAll(";", ",");

  return (
    <a href={url} target="_blank" className=" ">
      { !title ?  <Skeleton /> :
      <div className="d-flex w-100 pb-10px">  

        <div  className="mr-10">
          <img className="img-classname" alt="" width={"500"} src={img ? img : defaultImg}></img>

        </div>
        <div>

          <div>
            <p style={{lightHight:"24px"}} className=" mb-0 fs-16-13 overFlow-text">
              {convertTitleUnicode}
            </p>
          </div>
          {Compname &&<div
        style={{ width: "fit-content" }}
        className={`d-flex p-5 mt-10 ${lightMode ? "bg-gray" : "bg-dark-gray"}`}
      >
        <p className="mb-0  fs-14-12  ">{Compname} - {"  "}</p> <p className="mb-0 ff-lato" style={{marginLeft:"2px",color:redGreenColorPicker(2445.89,lightMode)}}>₹{changePrice}</p>
      </div>}
        </div>
      </div>}
      <div className={`${lightMode ? " bb1px-light-mode" : "bb1px-dark-mode"} w-100`}></div>
      
    </a>
  );
}

export default NewsBars;
