import React, { useEffect, useMemo, useState } from "react";
import { GetReturnsComparestocks } from "../../../api/fetchClient";
import CustomTable from "../../../components/CustomTable";
import { ReturnsTableData } from "../TableType";
import convertTableStructure from "../convertTableStructure";

function ReturnOfComapreStocks({ lightMode, columns, removedEmptyData,symbolsandnames,CompanyType }) {
  const [retuensData, setRetuensData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      GetReturnsComparestocks(removedEmptyData,CompanyType).then(async (res) => {
        await setRetuensData(res?.data);
      });
    };
    getData();
  }, []);
  const returntabledata = {};

  removedEmptyData?.map((item, i) => {
    if (retuensData.length === 0) return;
    returntabledata[item] =retuensData[item] && retuensData[item]["month1"];
  });
  const returnsTableData = useMemo(() => {
    if(Object.keys(retuensData).length > 0){
      const {tableBodyData ,columns} = convertTableStructure(retuensData,ReturnsTableData,removedEmptyData,lightMode,symbolsandnames)
      return {tableBodyData , columns}
    }
    return false
  },

  [Object.keys(retuensData)])
 


  return (
    <div id="Returns">
      <div className="d-flex justify-content-between">
        <h2 className="main-header-c ">Returns</h2>
      </div>

      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable columns={returnsTableData?.columns} scrollable={true} data={returnsTableData.tableBodyData} />
      </div>
    </div>
  );
}

export default ReturnOfComapreStocks;
