import React from "react";
import { useSelector } from "react-redux";
import svgSheet from "../../assets/svg/svgSheet";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import LeftSectionCompanies from "../IndexDetails/LeftSectionCompanies";
import TestInput from "./backtest input/TestInput";
import ResultIndex from "./backtestResults/ResultIndex";
import TrendingStocks from "../../components/TrendingStocks";
import MobileBottomBar from "../stock details/MobileBottomBar";
import RecentSearch from "../watchlist/RecentSearch";
function BacktestingIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const sidebarData = [
    {
      id: "Overview",
      icon: svgSheet.overviewIcon,
      label: "Overview",
      offset: -150 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Analysis",
      icon: svgSheet.graphIcon,
      label: "Analysis",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Diversification",
      icon: svgSheet.pieIcon,
      label: "Diversification",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Growth Chart",
      icon: svgSheet.heatMapIcon,
      label: "Growth Chart",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Returns Analysis",
      icon: svgSheet.candleStickicon,
      label: "Returns Analysis",
      offset: -135 - bannerOffsetCalc(UserAuth),
    },
  ];
  return (
    <>
      <ReactHelmet
        title="Stock Buckets by Trade Brains Portal - Hand picked stocks exclusive for you"
        desc="Stock Buckets by Trade Brains offers curated collection of best stocks organized for you to simplify your stock research. Start investing with readymade stocks."
      />
      <div className="ff-poppins max-w mx-auto  px-15  w-100">
        <div className="my-body">
          <div className="d-flex align-items-center mb-30">
            <p className="mr-10 w-m-28 mt-5 mb-0">{svgSheet.watchlistlogo}</p>
            <h1 className="mb-0 fs-36-20 fw-700">Portfolio Backtesting</h1>
          </div>
          <div className="d-flex">
            <div
              style={{
                width: "20%",
                top: `${130 + bannerOffsetCalc(UserAuth)}px`,
                height: "min-content",
              }}
              className="detail-left-card p-fixed"
            >
              <LeftSectionCompanies
                lightMode={lightMode}
                Navdata={sidebarData}
              />
              <div className="mt-20">
              <RecentSearch lightMode={lightMode} isOverlay={false} />
              </div>
            </div>
            <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
              <TestInput lightMode={lightMode} />
              <ResultIndex lightMode={lightMode} />
            </div>
          </div>
          {/* <TrendingStocks lightMode={lightMode} /> */}
        </div>
        <MobileBottomBar navData={sidebarData} lightMode={lightMode} />
      </div>
    </>
  );
}

export default BacktestingIndex;
