import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'
import { getAffiliatePayoutTotal, getAffiliateSales } from '../../../../../api/fetchClient';
import SelectOptions from '../../../../../components/ant/SelectOptions';
import CustomTable from '../../../../../components/CustomTable'
import { getThemeMode } from '../../../../../redux/reducers/ThemeSlice';
import numberWithCommas from '../../../../../utilityFn/numberWithCommas';
import { redGreenColorPicker } from '../../../../../utilityFn/redGreenColor';

function SalesIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const [selectd, setselectd] = useState();
  const [isLoading, setisLoading] = useState(false)
  const [SalesData, setSalesData] = useState()
  const [value, setvalue] = useState()
  const [PayoutTotal, setPayoutTotal] = useState()
  const [Filter, setFilter] = useState([])
  
  const yeararraay = [{value:"amount_min",label:"is greater than"},{value:"amount_max",label:"is less than or equal to"}];
    useEffect(() => {
      setisLoading(true)
      getAffiliateSales({plan:null}).then((Res)=>{
        
        setSalesData(Res?.data)
        setisLoading(false)
      })
      getAffiliatePayoutTotal().then((Res)=>{
        setPayoutTotal(Res?.data)
      })
    }, [])
    const sale = (data)=>{
      setFilter([...Filter,data])
      setisLoading(true)
      getAffiliateSales(Filter).then((Res)=>{
        setisLoading(false)
        
        setSalesData(Res?.data)
      })
    }

    useEffect(() => {

      getAffiliateSales(Filter).then((Res)=>{
        setisLoading(false)
        
        setSalesData(Res?.data)
      })
    }, [Filter])
    

  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Name </p>,
      dataIndex: "full_name",
      fixed: "left",
      width: "70px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
   

    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Product  </p>,
      dataIndex: "plan",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
      filterDropdown: (props) => {
        return (
          <div className={lightMode ? "bg-white" : "bg-dark-gray text-white"}>
            {props?.filters?.map((data, i) => (

              
              <p
                key={i}
    
      onClick={()=>{  setFilter([...Filter,data?.value]);}}
                style={{ padding: "5px 20px" }}
                className="fs-s-14 fw-500  mb-0 pointer"
              >
                {data.text}
              </p>
            ))}
          </div>
        );
      },
      filters: [
        {
          text: "Bulls",
          value: "plan=Bulls",
        },
        {
          text: "Sharks",
          value: "plan=Sharks",
        },
        {value:"plan=Whales"
        ,text:"Whales"}
      ],
      ellipsis: true,
    },
   
    {
      title: <p className="fs-s-16 fw-600 mb-0">Purchased Date</p>,
      width: "50px",
      dataIndex: "date",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    
     
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Coupon Code</p>,
      width: "50px",
      dataIndex: "coupon",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text ? numberWithCommas(text):'NA'}
            </span>
          ),
        };
      },
     
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Coupon Value</p>,
      width: "50px",
      dataIndex: "coupon_value",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              ₹{text ? numberWithCommas(text):0}
            </span>
          ),
        };
      },
    },
    
    {
      title: <p className="fs-s-16 fw-600 mb-0">Initial Amt</p>,
      width: "50px",
      dataIndex: "initial_amount",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              ₹{text ?numberWithCommas(text):0}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Final Amt</p>,
      width: "50px",
      dataIndex: "amount",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              ₹{text && numberWithCommas(text)}
            </span>
          ),
        };
      },
      filterDropdown: (props) => {
        return (
          <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
             <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setselectd}
              width={"200px"}
            />
            <input
            width={"100%"}
            value={value}
            style={{ width: "200px", color: "gray" }}
            placeholder=" Price"
            onChange={(e) => setvalue(e.target.value)}
            type="text"
            className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode text-black"
                : "border1px-dark-mode bg-dark-gray text-white"
            }`}
          ></input>
          <button onClick={()=>sale(`${selectd}=${value}`)} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
      
          </div>
        );
      },
      filters: [
        {
          text: "52W Low %",
          value: "lowytd",
        },
        {
          text: "52W High %",
          value: "highytd",
        },
        {value:"ytd"
        ,text:"YTD%"}
      ],
      ellipsis: true,
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Comm'n %</p>,
      width: "50px",
      dataIndex: "commision",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                

                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}%
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Earnings</p>,
      width: "50px",
      dataIndex: "earning",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",


                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              ₹{text && numberWithCommas(text)}
            </span>
          ),
          
        };
        
      },
      filterDropdown: (props) => {
        return (
          <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
             <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setselectd}
              width={"200px"}
            />
            <input
            width={"100%"}
            value={value}
            style={{ width: "200px", color: "gray" }}
            placeholder=" Price"
            onChange={(e) => setvalue(e.target.value)}
            type="text"
            className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode text-black"
                : "border1px-dark-mode bg-dark-gray text-white"
            }`}
          ></input>
          <button onClick={()=>sale(`${selectd}=${value}`)} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
      
          </div>
        );
      },
      filters: [
        {
          text: "52W Low %",
          value: "lowytd",
        },
        {
          text: "52W High %",
          value: "highytd",
        },
        {value:"ytd"
        ,text:"YTD%"}
      ],
      ellipsis: true,
    
    },
  ];
  const data = [
    {
      IndexCompany: "Demo Index",
      Points: "58,980.95",
      Change: "480.89",
      ChangePers: "2.98%",
    },
    {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      }, {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      },
  ];



  return (
    <div className="mt-30 px-30-0">
          {/* <p className='fs-s-20 fw-700 mb-0 '>Sales</p> */}
          <div className='d-flex align-center-start d-flex-row-col  justify-content-between'>

          <p className='fw-500 fs-s-20'>Overall sales made through subscribtion </p>
          <p className='fs-s-20 d-flex justify-content-end fw-700 ff-lato'>Total Earnings:  ₹ {numberWithCommas(Number(PayoutTotal?.total_earnings?.toFixed(2)))}</p>
          </div>
          <div
      className={`table-shadow  ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
          <CustomTable data={SalesData?.results}  columns={columns1}/>
        </div>
    </div>
  )
}

export default SalesIndex