import { Checkbox, Modal } from "antd";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authStore } from "../../../redux/reducers/authSlice";
import { plans } from "../../../utilityFn/planData";

function AddFilterModal({
  lightMode,
  toggleModal,
  settoggleModal,
  screenerStore,
  setfilterTypeArr,
  filterTypeArr,
  premiumModal,
  setpremiumModal,
}) {
  const [activeKey, setactiveKey] = useState("Most Common");
  const [activeValues, setactiveValues] = useState([]);
  const filterInputRef = useRef(null);
  const [checkedFilters, setcheckedFilters] = useState(filterTypeArr);
  const UserAuth = useSelector(authStore);

  useEffect(() => {
    if (screenerStore === null) return;
    setactiveValues(screenerStore?.filterData[activeKey]);
  }, [activeKey, screenerStore, toggleModal]);
  useEffect(() => {
    setcheckedFilters(filterTypeArr);
  }, [filterTypeArr]);

  // get all values into  a single array
  const allFilterValues = useMemo(() => {
    let totalArr = Object.values(screenerStore?.filterData).map((data) => {
      return data;
    });
    return totalArr.flat();
  }, [screenerStore.filterData]);

  // show search results
  const searchResults = (e) => {
    const lowerValue = e.target.value.toLowerCase();
    const results = allFilterValues?.filter((data) =>
      data.name.toLowerCase().includes(lowerValue)
    );
    setactiveValues(results);
  };
  // add to checked filter array 
  const addToFilterList = (data, event) => {
    if (event.target.name === "Market Cap (Rs Cr)") return;
    else if (event.target.checked) {
      setcheckedFilters([
        ...checkedFilters,
        {
          label: data.name,
          firstValue: data.min_value,
          secondValue: data.max_value,
          particular: data.particular,
          operator: "&",
          min: data.min_value,
          max: data.max_value,
          unit: data.unit,
          symbol: data.symbol,
        },
      ]);
    } else {
      setcheckedFilters(
        checkedFilters.filter((item) => item.label !== event.target.name)
      );
    }
  };
  // add data to array
  const addToArraySubmit = () => {
    const planData = plans[UserAuth?.userData?.user?.plan?.planId];
    if (checkedFilters.length > planData.screener) {
      setcheckedFilters(filterTypeArr)
      setpremiumModal(true);
      settoggleModal(false);
    } else {
      setfilterTypeArr([...checkedFilters]);
      settoggleModal(false);
    }
    filterInputRef.current.value = "";
  };

  return (
    <div>
      <Modal
        width={600}
        header
        bodyStyle={{ padding: "0px" }}
        title={
          <p
            style={{ marginLeft: "-12px" }}
            className={`fs-20-16 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Add Filters
          </p>
        }
        visible={toggleModal}
        centered
        wrapClassName={!lightMode && "filter-modal "}
        // onOk={handleOk2}
        onCancel={() => {
          filterInputRef.current.value = "";
          settoggleModal(false);
          setcheckedFilters([...filterTypeArr]);
          setactiveKey("Most Common");
        }}
        footer={[
          <button
            onClick={addToArraySubmit}
            key="submit"
            type="primary"
            className={` w-150px h-45 br-5 pointer
            btn-bg-primary fs-s-14 fw-600 text-white`}
            // onClick={() => handleOk2(CreateWatchlist)}
          >
            Done
          </button>,
        ]}
      >
        <div>
          <div className="mb-20 pt-10px px-10px">
            <input
              style={{ height: "32px" }}
              ref={filterInputRef}
              onChange={searchResults}
              placeholder="Search for Filters"
              className={`w-100 ${
                lightMode ? "custom-input-light" : "custom-input-dark"
              }`}
              type="text"
            />
          </div>
          <div className={`d-flex `}>
            {/* left section  */}
            {!filterInputRef.current?.value ? (
              <div className="w-40">
                {Object.keys(screenerStore?.filterData).map((key, i) => (
                  <p
                    key={i}
                    onClick={() => setactiveKey(key)}
                    style={{
                      color: lightMode ? "black" : "white",

                      background:
                        key === activeKey &&
                        (lightMode
                          ? "rgba(211, 232, 251, 0.35)"
                          : "rgba(167, 182, 196, 0.24)"),
                    }}
                    className={`modal-list-item pointer fs-s-14 fw-400 ${
                      lightMode ? "hover-light-blue" : "bg-dark-hover"
                    } `}
                  >
                    {key}
                  </p>
                ))}
              </div>
            ) : (
              <div className="w-40"></div>
            )}
            {/* left section  */}
            {/* right section  */}
            <div
              className="w-60"
              style={{
                background: lightMode
                  ? "rgba(211, 232, 251, 0.35)"
                  : "rgba(167, 182, 196, 0.24)",
              }}
            >
              <div
                className="scroll h-462-552"
                style={{
                  // height: "462px",
                  overflow: "auto",
                }}
              >
                {activeValues?.map((filterValue, i) => {
                  return (
                    <div
                      key={i}
                      className={`${
                        lightMode ? "checkbox-light" : "checkbox-dark"
                      }`}
                    >
                      <Checkbox
                        checked={
                          checkedFilters?.find(
                            (item) => item.label === filterValue.name
                          )
                            ? true
                            : false
                        }
                        name={filterValue.name}
                        onChange={(e) => addToFilterList(filterValue, e)}
                        style={{ color: lightMode ? "black" : "white" }}
                        className={`modal-list-item `}
                      >
                        {filterValue.name}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* right section  */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(AddFilterModal);
