import React from "react";
import ScreenCard from "../screener/components/ScreenCard";
import featScreener from "../../assets/images/home/featScreener.svg";
import featPortfolio from "../../assets/images/home/featPortfolio.svg";
import featCompare from "../../assets/images/home/featCompare.svg";
import featSuperStar from "../../assets/images/home/featSuperStar.svg";
import { Link } from "react-router-dom";
function FeatureCardSection({lightMode}) {
    const cardContents = [
        {
          icon: featScreener,
          head: "Screener",
          content:
            "Find winning stocks by creating filters that meet your investment criteria.",
            redirectLink:'/screener'
        },
        {
          icon: featPortfolio,
          head: "Portfolio Analysis",
          content:
            "Perform fundamental analysis of the stocks to build a world-class portfolio.",
            redirectLink:'/portfolio'
        },
        {
          icon: featCompare,
          head: "Stock Compare",
          content: "Compare companies across 50+ parameters to find the best among them.",
          redirectLink:'/stockCompare'
        },
        {
          icon: featSuperStar,
          head: "Superstar Portfolio",
          content: "Track the actions of your favourite ace investors in one place.",
          redirectLink:'/superstars'
        },
      ];
  return (
    <div>
      <h3 className="fs-28-18 fw-700 mb-30">Features</h3>
      <div className={"bucket-card-wrap"}>
        {cardContents?.map((card, i) => (
            <Link key={i} to={card.redirectLink}>
          <ScreenCard
            key={i}
            lightMode={lightMode}
            icon={card.icon}
            head={card.head}
            content={card.content}
          />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default FeatureCardSection;
