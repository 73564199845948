import React, { useEffect, useMemo, useState } from "react";
import { starTableReturns } from "../../../api/fetchClient";
import { getMonthYear } from "../../../utilityFn/getMonthYear";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";

function TableExpandRow({ lightMode, portId, finCode }) {
  const [data, setdata] = useState({});
  const dateReturn = [
    { label: "JUN 2022", value: 0.04 },
    { label: "MAR 2022", value: 0.04 },
    { label: "DEC 2021", value: -2.5 },
    { label: "SEP 2021", value: 0.04 },
    { label: "JUN 2021", value: 0.04 },
  ];
  useEffect(() => {
    const getData = async () => {
      await starTableReturns(portId, finCode)
        .then((resp) => {
          setdata(resp);
        })
        .catch((err) => {
          setdata({});
        });
    };
    getData();
  }, [finCode]);

  const quarterArr = useMemo(() => {
    if (data._holdings === undefined) return;
    const resultArr = data?._holdings?.reverse()?.map((hold) => {
      return {
        label: getMonthYear(String(hold.DATE_END)),
        value: hold.PERCENTAGE,
      };
    });
    return resultArr;
  }, [data]);
  console.log(quarterArr);
  return (
    <div
      style={{
        color: lightMode ? "black" : "white",
        minHeight: "80px",
        borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
        borderRight: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
      }}
      className="w-100 flex justify-content-between flex-wrap "
    >
      <div
        // style={{
        //   background:
        //     data?._change_from_prev >= 0
        //       ? "rgb(158 219 179 / 50%)"
        //       : "rgb(231 135 135 / 50%)",
        // }}
        className="d-flex p-10  w-sm-100 flex-col justify-content-between align-items-center"
      >
        <p className="mb-0 fs-s-14 fw-600">Change From Prev Qtr</p>
        <p
          style={{
            color: redGreenColorPicker(data?._change_from_prev, lightMode),
          }}
          className="ff-lato mb-0  fs-s-14 fw-600  d-flex div-items-center"
        >
        {data?._change_from_prev>0&&'+'}{data?._change_from_prev ?? "NA"}%
        </p>
      </div>
      {quarterArr?.map((item, i) => (
        <div className="d-flex  flex-col justify-content-between p-10">
          <p className="mb-0 fs-s-14 fw-600">{item.label ?? "NA"}</p>
          <p
            // style={{
            //   color: redGreenColorPicker(item.value, lightMode),
            // }}
            className="ff-lato mb-0 fw-600 fs-s-14 d-flex div-items-center"
          >
            {item.value.toFixed(2) ?? "NA"} %
          </p>
        </div>
      ))}
      {/* <div className="d-flex  flex-col justify-content-between p-10">
        <p className="mb-0 fs-s-14 fw-600">Holding %</p>
        <p className="ff-lato mb-0 fw-600 fs-s-14 d-flex div-items-center">
          6.5 %
        </p>
      </div> */}
    </div>
  );
}

export default TableExpandRow;
