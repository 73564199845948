import React, { useEffect } from 'react'
// import TradingViewWidget,{ Themes }  from 'react-tradingview-widget';
function ChartIndex() {

  return (
  <div>
    test
    {/* <TradingViewWidget symbol="BSE:TCS" theme={Themes.DARK} /> */}
  </div>
  )
}

export default ChartIndex