import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PremiumRedirectModal from "../../../components/PremiumRedirectModal";
import {
  getScreenerStore,
  postScreenerData,
} from "../../../redux/reducers/screenerSlice";
import AddFilterModal from "./AddFilterModal";
import SearchWithDropdown from "./SearchWithDropdown";
import SliderWithInput from "./SliderWithInput";

function FilterIndex({
  lightMode,
  filterTypeArr,
  setfilterTypeArr,
  postSectorArr,
  setpostSectorArr,
  postIndexArr,
  setpostIndexArr,
  settoggleFilter ,
  columnsArr,
  setcolumnsArr,
  premiumModal,
  setpremiumModal
}) {
  const { Panel } = Collapse;
  const screenerStore = useSelector(getScreenerStore);
  const dispatch = useDispatch();
  const [toggleModal, settoggleModal] = useState(false);
  //select index and sectors
  // format index lists
  const indexArr = useMemo(() => {
    if (screenerStore?.indices?.length > 0) {
      const nseData = screenerStore?.indices?.filter(
        (element) => element.exchange === "NSE"
      );
      const bseData = screenerStore?.indices?.filter(
        (element) => element.exchange === "BSE"
      );

      const finalStuct = [...nseData, ...bseData]?.map((item, i) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      return finalStuct;
    }
    // return []
  }, [screenerStore.indices]);
  // get industries 
  const sectorArr = useMemo(() => {
    if (screenerStore?.industries?.length === 0) return;
    const structArr = screenerStore?.industries?.map((data) => {
      return {
        id: data.industry_code,
        label: data.industry,
      };
    });
    return structArr;
  }, [screenerStore.industries]);

  // edit filter array object when input onchange
  const editFilterTypes = (id, keyName, value) => {
    setfilterTypeArr((prevState) =>
      prevState?.map((item, i) => {
        if (i === id) {
          return { ...item, [keyName]: value };
        } else {
          return item;
        }
      })
    );
  };
  // clear all aplied filter
  const clearAllFilter = () => {
    const defaultColumns = ["PRICE", "returns_1y", "TTMPE", "YIELD"];
    setpostIndexArr([]);
    setpostSectorArr([]);
    setcolumnsArr(
      columnsArr.filter((item) => defaultColumns.includes(item.particular))
    );
    const defaultType = [
      {
        label: "Market Cap (Rs Cr)",
        firstValue: 0,
        secondValue: 2500000,
        particular: "MCAP",
        operator: "&",
        min: 0,
        max: 2500000,
        symbol: "rs",
        unit: "Cr",
      },
    ];
    setfilterTypeArr((prevState) => [
      {
        label: "Market Cap (Rs Cr)",
        firstValue: 0,
        secondValue: 2500000,
        particular: "MCAP",
        operator: "&",
        min: 0,
        max: 2500000,
        symbol: "rs",
        unit: "Cr",
      },
    ]);
    dispatch(
      postScreenerData({
        applyFilters: defaultType,
        industries: [],
        indices: [],
        customColumns: [],
      })
    );
    //  postFilterToStore();
  };
  // delete particular filter
  const deleteFilterObj = (index) => {
    if (index === 0) {
      setfilterTypeArr((prevState) => {
        return prevState.map((item, i) => {
          if (i === 0) {
            return {
              label: "Market Cap (Rs Cr)",
              firstValue: 0,
              secondValue: 2500000,
              particular: "MCAP",
              operator: "&",
              min: 0,
              max: 2500000,
              symbol: "rs",
              unit: "Cr",
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setfilterTypeArr((prevState) =>
        prevState.filter((data, i) => i !== index)
      );
    }
    // postFilterToStore();
  };

  // send applied filter to redux
  const postFilterToStore = () => {
      const defaultColumns = ["PRICE", "returns_1y", "TTMPE", "YIELD"];
      setcolumnsArr(
        columnsArr.filter((item) => defaultColumns.includes(item.particular))
      );
      dispatch(
        postScreenerData({
          applyFilters: filterTypeArr,
          industries: postSectorArr,
          indices: postIndexArr,
          customColumns: [],
        })
      );
    settoggleFilter && settoggleFilter(false);
  };

  return (
    <div
      className={`w-100  card-shadow relative  ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
      style={{
        height: "70vh",
      }}
    >
      {/* // first section  */}
      <div className="display-lg">
        <div
          className={` d-flex justify-content-between p-10  ${
            lightMode
              ? "border1px-light-mode bg-white"
              : "border1px-dark-mode bg-dark"
          }`}
        >
          <p className="mb-0 fs-16-14 fw-600">Filters</p>
          <div className="flex">
            <button
              onClick={() => settoggleModal(true)}
              style={{
                height: "26px",
                textAlign: "center",
                lineHeight: "12px",
              }}
              className={`br-3 px-3 bg-transparent fs-s-12 pointer btn-blue-border 
              ${lightMode ? "text-btn-light" : "text-btn-dark"}`}
            >
              {/* <PlusOutlined /> */}
              Add Filters
            </button>
            <button
              onClick={clearAllFilter}
              className={`px-3 ${
                lightMode ? "text-btn-light" : "text-btn-dark"
              } fs-s-12 btn-blue-border`}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      {/* // first section  */}
      <div style={{ height: "55vh", overflowY: "auto" }} className="scroll">
        <Collapse
          accordion
          // style={{ background: lightMode ? "#fff" : "#292E3F" }}
          bordered={false}
          expandIconPosition="end"
          className={` ${
            lightMode ? " filter-collapse-light" : " filter-collapse-dark"
          }`}
          expandIcon={(props) => (
            <CaretRightOutlined rotate={props.isActive ? 90 : 0} />
          )}
        >
          {/* ////////////////////// */}
          <Panel
            key={`${0}`}
            className={`fs-s-12 `}
            style={{
              color: lightMode ? "black" : "white",
              background: lightMode ? "#F2F6F8" : "#3B3F4F",
              borderBottom: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
            }}
            header={
              <div className="d-flex justify-content-between collapse-head w-100">
                <p
                  className={`fw-500 fs-s-14 mb-0 ${
                    lightMode ? "text-black " : "text-white"
                  }`}
                >
                  Sectors
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setpostSectorArr([]);
                    postFilterToStore();
                  }}
                  className={`fw-500 fs-s-14 mb-0 btn-hover-show   text-gray`}
                >
                  Reset
                </button>
              </div>
            }
          >
            <div
              className={`br-5 p-10 scroll ${
                lightMode ? "bg-white" : "bg-dark"
              }`}
              style={{
                height: "200px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <SearchWithDropdown
                lightMode={lightMode}
                data={sectorArr}
                placeholder="Search Sector"
                setCheck={setpostSectorArr}
                checkedArr={postSectorArr}
              />
            </div>
          </Panel>

          <Panel
            key={`${1}`}
            className={`fs-s-12 `}
            style={{
              color: lightMode ? "black" : "white",
              background: lightMode ? "#F2F6F8" : "#3B3F4F",
              borderBottom: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
            }}
            header={
              <div className="d-flex justify-content-between collapse-head w-100">
                <p
                  className={`fw-500 fs-s-14 mb-0 ${
                    lightMode ? "text-black " : "text-white"
                  }`}
                >
                  Index
                </p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setpostIndexArr([]);
                    postFilterToStore();
                  }}
                  className={`fw-500 fs-s-14 mb-0 btn-hover-show text-gray`}
                >
                  Reset
                </button>
              </div>
            }
          >
            <div
              className={`br-5 p-10 scroll ${
                lightMode ? "bg-white" : "bg-dark"
              }`}
              style={{
                height: "200px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <SearchWithDropdown
                lightMode={lightMode}
                data={indexArr}
                placeholder="Search Index"
                setCheck={setpostIndexArr}
                checkedArr={postIndexArr}
              />
            </div>
          </Panel>
          {filterTypeArr?.map((filterType, i) => (
            <Panel
              key={`${i + 2}`}
              className={`fs-s-12`}
              style={{
                color: lightMode ? "black" : "white",
                background: lightMode ? "#F2F6F8" : "#3B3F4F",
                borderBottom: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
              }}
              header={
                <div className="d-flex justify-content-between collapse-head w-100">
                  <p
                    className={`fw-500 fs-s-14 mb-0 ${
                      lightMode ? "text-black " : "text-white"
                    }`}
                  >
                    {filterType?.label}
                  </p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteFilterObj(i);
                    }}
                    className={`fw-500 fs-s-14 mb-0 btn-hover-show text-gray`}
                  >
                    {i === 0 ? "Reset" : "Remove"}
                  </button>
                </div>
              }
            >
              <SliderWithInput
                lightMode={lightMode}
                editFilterTypes={editFilterTypes}
                typeData={{ ...filterType, arrIndex: i }}
              />
            </Panel>
          ))}
          {/* ////////////////// */}
        </Collapse>
      </div>
      <div
        className="w-100 flex "
        style={{ padding: "0px 16px", bottom: "15px", position: "absolute" }}
      >
        <button
          onClick={() => settoggleModal(true)}
          className={`${
            lightMode ? "text-btn-light" : "text-btn-dark"
          } display-md h-45 w-50-100 mt-30 fw-600  fs-16-14 btn-blue-border`}
        >
          Add more Filters
        </button>
        <button
          onClick={postFilterToStore}
          className="w-50-100 mt-30 h-45 btn-bg-primary text-white br-3 fw-600  fs-16-14"
        >
          Apply Filter
        </button>
      </div>
      {/* add new filters   */}
      <AddFilterModal
        setfilterTypeArr={setfilterTypeArr}
        filterTypeArr={filterTypeArr}
        screenerStore={screenerStore}
        lightMode={lightMode}
        toggleModal={toggleModal}
        settoggleModal={settoggleModal}
        defaultChecked={["Market Cap (Rs Cr)"]}
        premiumModal={premiumModal}
        setpremiumModal={setpremiumModal}
      />
    <PremiumRedirectModal modalPlan="screener"  visible={premiumModal} setVisible={setpremiumModal} />
    </div>
  );
}

export default FilterIndex;
