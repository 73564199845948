import React, { useMemo } from "react";
import svgSheet from "../../../assets/svg/svgSheet";
import CustomTooltip from "../../../components/ant/CustomTooltip";
import CustomTable from "../../../components/CustomTable";
import numberWithCommas from "../../../utilityFn/numberWithCommas";

function Technicaltable({ lightMode, tableData }) {
  const pivotArr = [
    "Resistance 3",
    "Resistance 2",
    "Resistance 1",
    "Pivot",
    "Support 1",
    "Support 2",
    "Support 3",
  ];

  const tableArr = useMemo(() => {
    const levelAddArr = tableData?.map((item, i) => {
      return { ...item, level: pivotArr[i] };
    });
    return levelAddArr;
  }, [tableData]);

  const columns = [
    {
      title: (
        <div className="flex align-items-center">
          <p className="fw-600 mb-0 fs-s-16">Level</p>
          <CustomTooltip text={"The levels here show the different support and resistances for the trading session"}>{svgSheet.infoTooltop}</CustomTooltip>
        </div>
      ),
      dataIndex: "level",
      width: "100px",
      render: (text, record) => {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  fontSize: "16px",
                  borderRight: "1px solid #C9D7DE",
                  borderLeft: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  fontSize: "16px",
                },
          },
          children: (
            <p
              style={{ color: lightMode ? "black" : "white" }}
              className="mb-0"
            >
              {text}
            </p>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">Standard</p>,
      dataIndex: "std",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <p
              style={{ color: lightMode ? "black" : "white" }}
              className="mb-0"
            >
              {numberWithCommas(Number(text))}
            </p>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">Fibonacci</p>,
      dataIndex: "fib",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <p
              style={{ color: lightMode ? "black" : "white" }}
              className="mb-0"
            >
              {numberWithCommas(Number(text))}
            </p>
          ),
        };
      },
    },
  ];

  return (
    <div
      className={` sub-current-key table-shadow ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
      <CustomTable
        columns={columns}
        data={tableArr}
        scrollable={true}
        scrollLimit={300}
      />
    </div>
  );
}

export default Technicaltable;
