import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import PerformanceBarLine from "../../components/PerformanceBarLine";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import StockGraph from "../stock details/StockGraph";
import { getIndexreturns, Indexperformance } from "../../api/fetchClient";
import PriceHighLowSlider from "../../components/PriceHighLowSlider";
import useGetFetch from "../../hooks/useGetFetch";
import StockEndpoints from "../../api/endPoints";
import { useNavigate } from "react-router-dom";
function IndexPerformPopup({ lightMode, settoggleModal, toggleModal }) {
  console.log(toggleModal);
  const [Stockdata, setStockdata] = useState();
  const [IndexReturn, setIndexReturn] = useState([]);

  const { isLoading, serverError, apiData } = useGetFetch(
    StockEndpoints.marketMovers(toggleModal)
  );

console.log("");
  useEffect(() => {
    const getdata = () => {
      Indexperformance({ indexname: toggleModal }).then((res) => {
        setStockdata(res);
      });
    };

    getdata();
  }, [toggleModal]);
  let IndexReturndata = [
    "1W Returns",
    "3M Returns",
    "6M Returns",
    "1Y Returns",
  ];
  const indexReturns = {
    one_week:"1W Returns",
    three_months:"3M Returns",
    six_months:"6M Returns",
    one_year:"1Y Returns",
  }
  const [IndexR, setIndexR] = useState()
  useEffect(() => {
    const getData = async () => {
      await getIndexreturns({ indexname: toggleModal }).then(async (res) => {
        setIndexR(res)
        console.log(res,"rere");
        let IndexReturndata1 = [];

        for (let i = 0; i < Object.keys(res)?.length; i++) {
          IndexReturndata1.push({
            label: IndexReturndata[i],
            value: Object.values(res)[i],
          });
        }
        setIndexReturn(IndexReturndata1);
      });
    };
    getData();
  }, [toggleModal]);
 
  const returns = Object.keys(indexReturns).map((key,i)=>  {
    return {[indexReturns[key]]:IndexR && IndexR[key]}
  })
  console.log(returns,"returnasss");
const navigate = useNavigate()
  return (
    <Modal
      width={1000}
      className=""
      header
      bodyStyle={{ padding: "0px" }}
      visible={toggleModal !== "NONE"}
      centered  
      wrapClassName={!lightMode && " trans-close"}
      // onOk={handleOk2}
      onCancel={() => {
        settoggleModal("NONE");
        // setcheckedColumns([])
      }}
      footer={null}
    >
      <div className="w-100 p-30">
      <p onClick={()=>navigate(`/index/${toggleModal}`)} style={{paddingRight:"13px"}} className="d-flex link-hover-underline w-100 mt-10  justify-content-end pointer viewmore-performance">View More</p>

        <div className="d-flex d-flex-row-col">
          
          <div className="w-100-50" style={{ height: "300px" }}>
            <StockGraph
              type="index"
              lightMode={lightMode}
              symbol={toggleModal}
              volumeBar={false}
              height="280px"
            />
          </div>
          <div className="w-100-50   mx-20-0 mb-10 d-flex align-items-center justify-content-evenly d-flex-col">

            <PriceHighLowSlider
              high={Stockdata?.high_52w}
              low={Stockdata?.low_52w}
              close={Stockdata?.price}
            />
            <div className="w-100">
            <div className="flex justify-content-between">
              <p className="fw-500 mb-20">Gainers </p>
              <p className="fw-500 mb-20">Losers </p>

 
              </div>
              <PerformanceBarLine
                LoserCount={apiData?.losers_count}
                gainerCount={apiData?.gainers_count}
                totalCount={apiData?.total_count}
                lineHeight="5px"
              />
              
            </div>
          </div>
        </div>
        <div style={{paddingRight:"20px"}} className="w-100 mt-20 d-flex flex-wrap justify-content-between">
          {returns?.map((items, i) => (
            <div key={i} className="d-flex flex-col mt-10 align-items-center">
              <p>{Object.keys(items)}</p>
              <p style={{ color: redGreenColorPicker(items?.value,lightMode) }} className="mb-0">
                {Object.values(items)}%
              </p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default IndexPerformPopup;
