import { utils, write } from "xlsx";
import { fromDataAsync, RichText } from "xlsx-populate/browser/xlsx-populate";
import { alphabet } from "../../../utilityFn/excel/columnAlphabet";
import {
  excelHeaderCalc,
  numToAlpha,
} from "../../../utilityFn/excel/excelHeaderCalc";
import { excelWorkbookToBlob } from "../../../utilityFn/excel/excelWorkbookToBlob";

export const screenerExcel = (tableData, tableBodydata) => {
  const convertExeclTable = (data, tableBodydata) => {
    let headersData = {};
    data?.forEach((item, i) => {
      headersData[item.dataIndex] =
        item.title.props.children || item.title.props.label;
    });

    const tableBodyArr = tableBodydata?.map((item) => {
      const dataObj = {};
      delete item.FFORMAT;
      delete item.FINCODE;
      delete item.SCRIPCODE;
      delete item.SYMBOL;
      // return item;
      Object.entries(item).forEach((data) => {
        dataObj[data[0]] = data[1] ?? 0;
      });
      const convertMcap = (item.MCAP / 10 ** 7)?.toFixed(2);
      return { ...dataObj, MCAP: convertMcap };
    });

    return { headersData, tableBodyArr };
  };

  //adding styles for headers and body
  const addStyles = (workBookBlob, dataInfo) => {
    return fromDataAsync(workBookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        //add header styles
        for (let i = 0; i < dataInfo.headerRange.length; i++) {
          sheet.range(dataInfo.headerRange[i]).style({
            fill: "3B3F4F",
            bold: "true",
            fontColor: "FFFFFFF",
          });
        }
        // add table border
        for (let i = 0; i < dataInfo.tBodyRange.length; i++) {
          sheet.range(dataInfo.tBodyRange[i]).style({
            fontFamily: "poppins",
            horizontalAlignment: "center",
            border: true,
          });
        }
      });

      //   // add stock name
      const cell = workbook.sheet(0).range("A1:B1");
      cell.value(new RichText());
      cell.value("Screener Report");
      cell.style({ bold: true, fontFamily: "poppins", fontSize: 15 });
      cell.merged(true);
      //   // add data type
      //   const dataTypeCell = workbook.sheet(0).cell("A2");
      //   dataTypeCell.value(new RichText());
      //   dataTypeCell.value(`Figures represented in ${dataType}`);
      //   dataTypeCell.style({ fontFamily: "poppins", fontSize:12 });
      //   // add page link
      const linkCell = workbook.sheet(0).cell("F1");
      linkCell.hyperlink(`https://portal.tradebrains.in/screener`);
      linkCell.value(`https://portal.tradebrains.in/screener`);
      linkCell.style({ bold: true, fontFamily: "poppins", fontSize: 13 });
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const convertedArr = convertExeclTable(tableData, tableBodydata?.results);
  const finalArr = [
    {},
    {},
    {},
    {},
    convertedArr.headersData,
    ...convertedArr.tableBodyArr,
  ];
  const wsRowData = finalArr?.map((item) => {
    return {
      hpx: 30,
    };
  });
  let wsColSize = Object.keys(convertedArr.headersData).map((item) => {
    return {
      wch: 15,
    };
  });
  wsColSize = [{ wch: 30 }, { wch: 30 }, ...wsColSize];
  const wb = utils.book_new();
  const ws = utils.json_to_sheet(finalArr, { skipHeader: true });
  ws["!rows"] = wsRowData;
  ws["!cols"] = wsColSize;
  utils.book_append_sheet(wb, ws, "Screener Data");
  const wbBlob1 = excelWorkbookToBlob(wb);
  const colNumber = Object.keys(convertedArr.headersData);
  const dataInfo = {
    headerRange: [`A5:${numToAlpha(colNumber.length - 1)}5`],
    tBodyRange: [`A5:${numToAlpha(colNumber.length - 1)}${finalArr.length}`],
  };
  return addStyles(wbBlob1, dataInfo);
};
