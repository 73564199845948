import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll } from "react-scroll/modules";
import { getCorpActionsIndexData, getSearchData } from "../../api/fetchClient";
import CustomPagination from "../../components/ant/CustomPagination";
import CustomTable from "../../components/CustomTable";
import MobileSlider from "../../components/MobileSlider";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";

function CorporateActionIndex({ lightMode, Value }) {
  const [sortState, setsortState] = useState();
  const [Corporate, setCorporate] = useState();
  const [Page, setPage] = useState({ page: 1, perPage: 25 });
  const [isLoading, setisLoading] = useState(false);

  const onPageChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const TableColumnData = {
    Dividends: [
      ["Company Name", "company", "symbol"],
      ["Type", "type"],
      ["Ex-Date", "ex_date"],
      ["Record Date", "record_date"],
      ["Rs", "dividend"],
      ["Percentage", "div_ratio"],
    ],
    "Board Meetings": [
      ["Company Name", "company", "symbol"],
      ["Date", "date"],
      ["Agenda", "agenda"],
    ],
    Bonus: [
      ["Company Name", "company", "symbol"],
      ["Annoucement Date", "annnouncement_date"],
      ["Ex Date", "ex_bonus_date"],
      ["Record Date", "record_date"],
      ["Bonus Ratio", "ratio"],
    ],
    Splits: [
      ["Company Name", "company", "symbol"],
      ["Ex Date", "split_date"],
      ["Split Ratio", "ratio"],
    ],
    "Rights Issue": [
      ["Company Name", "company", "symbol"],
      ["Annoucement Date", "annnouncement_date"],
      ["Ex-Date", "ex_date"],
      ["Record Date", "record_date"],
      ["Rights Ratio", "rights_ratio"],
      ["Premium", "premium"],
    ],
    "AGM/EGM": [
      ["Company Name", "company", "symbol"],
      ["Date", "date"],
      ["Agenda", "agenda"],
    ],
  };

  const [CompanyData, setCompanyData] = useState("");
  const [Input, setInput] = useState();
  const [inputReturns, setInputReturns] = useState();
  useEffect(() => {
    setisLoading(true);
    setPage({ page: 1, perPage: 25 });
    getCorpActionsIndexData(Page, Value, String(CompanyData)).then((res) => {
      setisLoading(false);
      setCorporate(res?.data);
    });
  }, [Value, CompanyData]);
  useEffect(() => {
    setisLoading(true);
    getCorpActionsIndexData(Page, Value, String(CompanyData)).then((res) => {
      setisLoading(false);
      setCorporate(res?.data);
    });
  }, [Page]);
  console.log("CompanyData", CompanyData);

  const navigate = useNavigate();
  const columnGen = (array) => {
    const GeneratedColumns =
      array?.length > 0 &&
      array?.map((items) => {
        return {
          title: <p className="fs-s-16 fw-600 mb-0">{items[0]}</p>,
          dataIndex: items[1],
          // fixed:items[0] !== "Company Name" ? "" : "left",
          width: items[0] === "Company Name" ? "20px" : "50px",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  onClick={() => {
                    items[0] === "Company Name" &&
                      navigate(`/stock/${record?.symbol}/consolidated`);
                  }}
                  title={text === 'TBA'&&"To Be Announce"}
                  className={` ${
                    items[0] === "Company Name" &&
                    "link-hover-underline pointer"
                  } ff-lato  `}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: lightMode ? "black" : "white",
                  }}
                >
                  {text === null ? "NA" : text}
                </span>
              ),
            };
          },
        };
      });

    return GeneratedColumns;
  };

  const dropdowndata = [];
  const comparesymbols = [];
 {
  inputReturns &&
    inputReturns.map((items) => {
      items.type !== "index" &&
        dropdowndata.push({
          // value: isNaN(items.symbol) ? items.symbol : items.symbol,
          value: items.symbol,
          label: items.company,
        });
    });
}

 

  const getResults = async (e) => {
    // setinputData(e.target.value);
    const response = await getSearchData(e)
      .then((resp) => {
        setInputReturns(resp.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-20">
        <p className="fw-700 lh-24 fs-28-18 mb-0-10">{Value}</p>
        <div className="w-100-unset d-flex justify-content-end ">
          <AutoComplete
            style={{
              width: "250px",
            }}
            dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
            className={
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode antd-Selete-Custom-dark "
            }
            onFocus={() => getResults("")}
            value={Input}
            options={dropdowndata}
            placeholder=" Select Stock"
            allowClear={() => setCompanyData("")}
            onChange={(e) => {
              setInput(e);
              setCompanyData(e);
              getResults(e);
            }}
            notFoundContent="No Stocks"
            defaultActiveFirstOption={true}
            onSelect={(e, options) => {
              console.log("this is the value form search", e);
              setCompanyData(e);
              setInput(e);
            }}
          />
        </div>
      </div>
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={columnGen(TableColumnData[Value])}
          data={Corporate?.results}
          loading={isLoading}
        />
        <CustomPagination
          // onShowSizeChange={false}
          current={Page.page}
          lightMode={lightMode}
          onChange={(page, perpage) => {
            setPage({ page: page, perPage: perpage });
            animateScroll.scrollTo(110, 0);
          }}
          total={Corporate?.count}
          pageSize={Page.perPage}
        />
      </div>
    </div>
  );
}

export default CorporateActionIndex;
