import React from "react";
import { useSelector } from "react-redux";
import bgImgDark from "../assets/images/bg/bg404Dark.png";
import bgImgLight from "../assets/images/bg/bg404Light.png";
import { getThemeMode } from "../redux/reducers/ThemeSlice";

function Error404() {
  const { lightMode } = useSelector(getThemeMode);
  return (
    <div className="relative" style={{ height: "90vh" }}>
      <img
        className="absolute w-100 h-100 fit-cover"
        src={lightMode ? bgImgLight : bgImgDark}
        alt="bg"
      />
      <div  className="flex flex-col div-items-center h-100">
        <h1 style={{zIndex:20}} className="fs-40-28 fw-700">Looks like you’re lost </h1>
        <h2 style={{fontSize:'12rem',lineHeight:'12rem',zIndex:10}} className=" fw-700">404</h2>
      </div>
    </div>
  );
}

export default Error404;
