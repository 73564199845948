import { Form, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAdminStar, addStockRecommendation } from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import EditStockRecommendation from "./EditStockRecommendation";
import StockRecommendationForm from "./StockRecommendationForm";
// import PorrtfolioForm from "./PorrtfolioForm";

function SaveStockRecommendation() {
  const [form] = Form.useForm();
  const [dropdowndata, setDropdowndata] = useState([]);
  const [addInputReturns, setAddInputReturns] = useState();
  const [selectedLabel, setSelectedLabel] = useState();
  const [brokerId, setBrokerId] = useState("");
  const [files, setfiles] = useState(null);
  const [targets, setTarget] = useState("");
  const [selectedRole, setSelectedRole] = useState("buy");
  // const [isVisible , setIsVisible] = useState("")
  const [visibleType , setVisibleType] =useState ();
  const [date , setDate] = useState("")


  const navigate = useNavigate();
  const breadCrumbData = [
    { label: "Stock Recommendation", link: `/admin/stockRecommendation` },
  ];
 
  const onSubmit = async (values) => {
   
    const postData = new FormData();
  
      postData.append("stock" ,selectedLabel)
      postData.append("broker" , brokerId)
      postData.append("date" , date)
      postData.append("target" , targets)
      postData.append("role" , selectedRole)
      if (files !== null) {
        postData.append("file", files);
      }
      postData.append("visibility" , visibleType)

    console.log(values ,"values")
    
    console.log(selectedLabel , "label")
    await addStockRecommendation(postData)
      .then((response) => {
        if (response.status === 201) {
          message.success("Stock Added successfully");
          navigate("/admin/stockRecommendation");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb
          linkData={breadCrumbData}
          current={"Save Stock Recommendation"}
        />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">Recommend Stock</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => form.submit()}
          className="btn-bg-primary p-10 h-45 fw-500 br-5 text-white"
        >
          Save Recommendation
        </button>
      </div>
         <StockRecommendationForm
        dropdowndata={dropdowndata}
        setDropdowndata={setDropdowndata}
        inputReturns={addInputReturns}
        setInputReturns={setAddInputReturns}
        form={form}
        onSubmit={onSubmit}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        brokerId = {brokerId}
        setBrokerId={setBrokerId}
        targets={targets}
        setTarget={setTarget}
        setSelectedRole={setSelectedRole}
        selectedRole={selectedRole}
        setfiles={setfiles}
        setDate={setDate}
        date={date}
        // isVisible={isVisible}
        // setIsVisible={setIsVisible}
        visibleType={visibleType}
        setVisibleType={setVisibleType}
        files={files}
      />
      

    </div>
  );
}

export default SaveStockRecommendation;
