import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getFeedbackdataAdmin } from '../../../../api/fetchClient';
import CustomPagination from '../../../../components/ant/CustomPagination';
import CustomTable from '../../../../components/CustomTable';
import { getThemeMode } from '../../../../redux/reducers/ThemeSlice';
import numberWithCommas from '../../../../utilityFn/numberWithCommas';

function Feedback() {
    const { lightMode } = useSelector(getThemeMode);
    const [Page, setPage] = useState(1);
    const [input, setinput] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [Filter, setFilter] = useState({
        maxdate: "",
        "mindate=": "",
        search: "",
        commi: "",
        revenue: "",
        earnings: "",
      });
      const onPageChange = (e) => {
        setPage(e);
      };
      const columns1 = [
     
        {
          title: <p className="fs-s-16 fw-600 mb-0">Email ID</p>,
          dataIndex: "email",
          width: "50px",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  className="ff-lato "
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: lightMode ? "black" : "white",
                  }}
                >
                  {text}
                </span>
              ),
            };
          },
        },
    
        {
          title: <p className="fs-s-16 fw-600 mb-0">Date</p>,
          width: "50px",
          dataIndex: "created_at",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  className="ff-lato"
                  style={{
                    color: lightMode ? "black" : "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {text && numberWithCommas(text)}
                </span>
              ),
            };
          },
        },
        {
          title: <p className="fs-s-16 fw-600 mb-0">Feedback</p>,
          width: "50px",
          dataIndex: "review",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  className="ff-lato"
                  style={{
                    color: lightMode ? "black" : "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {text && numberWithCommas(text)}
                </span>
              ),
            };
          },
        },
        {
          title: <p className="fs-s-16 fw-600 mb-0">Rating</p>,
          width: "50px",
          dataIndex: "rating",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  className="ff-lato"
                  style={{
                    color: lightMode ? "black" : "white",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {(text && text?.length === 0) || text === "NA"
                    ? "NA"
                    : numberWithCommas(text)}
                </span>
              ),
            };
          },
        },
      ];
      const [transationDetails, settransationDetails] = useState();
      useEffect(() => {
        setisLoading(true);
        getFeedbackdataAdmin(Page,Filter).then((res) => {
            console.log("getFeedbackdataAdmin",res);
          setisLoading(false);
          settransationDetails(res?.data);
          console.log("resdata ", res);
        });
      }, [Page,Filter]);
  return (
    <div className="my-body px-30-0">
      <input
          //   width={"100%"}
          // value={NewWatchList}
          style={{ width: "20%", color: "white" }}
          placeholder="Search User Name"
          value={input}
          onChange={(e) => setFilter({ ...Filter, search: e.target.value })}
          type="text"
          className={`w-100 h-45 mr-5 br-3  p-3 mt-10 ${
            lightMode
              ? "border1px-light-mode"
              : "border1px-dark-mode bg-dark-gray"
          }`}
        ></input>
      <div
        className={`table-shadow mt-30 ${
          lightMode ? "custom-antd-head-light"  : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          loading={isLoading}
          columns={columns1}
          data={transationDetails?.results}
        />
        <CustomPagination
          current={Page}
          lightMode={lightMode}
          onChange={onPageChange}
          total={transationDetails?.count}
        />
      </div>
      <div></div>
    </div>
  )
}

export default Feedback