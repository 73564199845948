import React, { useEffect, useState } from "react";
import { getMultiGraphProfitPortfolio } from "../../api/fetchClient";
import AreaChart from "../../components/Charts/AreaChart";
import { getGradient } from "../../components/Charts/getGradients";
import { dateFrmater } from "./DateFormaterCustom";
import moment from "moment";
import numFormatter from "../../utilityFn/numFormatter";
import numberWithCommas from "../../utilityFn/numberWithCommas";
function MultiGraphPortFolioAnalysis({
  portfolioid,
  trigger,
  lightMode,
  Portfolios,
  Portfoliostocksdata,
  daysDuration,
  setdaysDuration,
  setstatusdata,
  statusdata
}) {
  const [MultiLineGraph, setMultiLineGraph] = useState([]);
  console.log("daysDuration", daysDuration);
  const backdropColor1 = ["rgba(0, 130, 239, 0.7)", "rgba(101, 216, 253, 0.6)"];
  const gradientColors = [
    [
      { xValue: 0, color: "rgba(0, 130, 239, 0.7)" },
      { xValue: 1, color: "rgba(0, 130, 239, 0)" },
    ],
    [
      { xValue: 0, color: "rgba(101, 216, 253, 0.6) " },
      { xValue: 1, color: "rgba(101, 216, 253, 0) " },
    ],
  ];
  useEffect(() => {
    getMultiGraphProfitPortfolio(portfolioid, daysDuration).then((res) => {
      setMultiLineGraph(res?.data);
    }).catch((res)=>setdaysDuration("1y"));

    getMultiGraphProfitPortfolio(portfolioid, "1m").then((res)=>{
      setstatusdata(res?.data)
      console.log("dadasdasda",res?.data);
    })
  }, [
    portfolioid,
    trigger,
    Portfoliostocksdata?.length,
    Portfoliostocksdata,
    daysDuration,
  ]);

  console.log(MultiLineGraph,"MultiLineGraph");
  // { this is test }
  const CurrentValue = MultiLineGraph?.length > 0 && MultiLineGraph?.map((items) => items?.current_value);
  const total_invested_value = MultiLineGraph?.length > 0 &&  MultiLineGraph?.map(
    (items) => items?.investement_value
  );

  // const CurrentValue = MultiLineGraph?.length > 0 && MultiLineGraph?.map((items) => items?.current_val);
  // const total_invested_value = MultiLineGraph?.length > 0 &&  MultiLineGraph?.map(
  //   (items) => items?.total_invested_value
  // );
  const dateFormat = ["DD MMM  YY", "DD-MM-YY"];
  const day = MultiLineGraph?.length > 0 && MultiLineGraph?.map((items) =>{
    // return items?.day ? moment(items?.day).format(dateFormat[0]) : undefined
  //   console.log("items?.key",items?.key)
   return moment(items?.key).format("MMM YYYY")
}
  );


  const data1 = {
    labels: day,
    datasets: [
      {
        pointStyle: "rectRot",
        label: "Current Value",
        fill: true,
        backgroundColor: "transparent",
        borderColor: "rgba(0, 130, 239, 1)",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          } else {
            return getGradient(ctx, chartArea, gradientColors[0]);
          }
        },
        data: CurrentValue,
        borderWidth: 2.5,
      },
      {
        pointStyle: "rectRot",
        label: "Investment Value",

        data: total_invested_value,
        borderColor: "rgba(101, 216, 253, 1)",

        borderWidth: 2.5,
        fill: true,
        backgroundColor: "transparent",
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          } else {
            return getGradient(ctx, chartArea, gradientColors[1]);
          }
        },
      },
    ],
  };
  return (
    <div >
      <div
        style={{ height: "400px", width: "100%" }}
        className="my-55 responsive-grap-stock-comaprepage"
      >
        <AreaChart
          dataPoints={data1}
          options={{
            responsive: true,

            maintainAspectRatio: false,
            tension: 0,
            scales: {
              y: {
                grid: {
                  display: false,
                  borderColor: lightMode ? "#A3A8B0" : "#545E78",
                },
                borderColor: "white",
                ticks: {
                  callback: (v, i) =>
                  numFormatter(v),
                  color: lightMode
                    ? "rgba(84, 94, 120, 1)"
                    : "rgba(84, 94, 120, 1)",
                  font: {
                    size: 14,
                  },
                  padding: 5,
                },
              },

              x: {
                grid: {
                  display: false,
                  borderColor: lightMode ? "#A3A8B0" : "#545E78",
                },
                tickColor: "red",
                ticks: {
                  color: lightMode
                    ? "rgba(84, 94, 120, 1)"
                    : "rgba(84, 94, 120, 1)",
                  font: {
                    size: 14,
                  },
                  padding: 5,
                },
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  color: lightMode ? "#3B3F4F" : "rgba(84, 94, 120, 1)",
                  fontSize: 14,
                  usePointStyle: true,
                  boxWidth: 5,
                  padding: 30,
                },
              },
              tooltip: {
                titleAlign: "center",
                yAlign: "center",
                backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
                bodyFont: {
                  size: 14,
                  weight: 600,
                },
                displayColors: false,

                callbacks: {
                  title: (chart, args) => {
                    // console.log("chart",chart,args);
                    return "";
                  },
                  labelTextColor: (context) => {
                    return backdropColor1[context.datasetIndex];
                  },
                  label: (context, value) => {
                    return (
                      context?.dataset.label +
                      " : " +
                     "₹" + " "+numberWithCommas(numFormatter(context?.raw))
                    );
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
          }}
        />
      </div>
    </div>
  );
}

export default MultiGraphPortFolioAnalysis;
