import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getAffiliateDashdata } from "../../../../../../api/fetchClient";
import AffiliateRevenueCard from "./AffiliateRevenueCard";

function AffiliateMovesDash({ lightMode, options }) {
  const [Duration, setDuration] = useState(11);
  const [FullMonth, setFullMonth] = useState();
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  const dateFormat = ["MM"];
  const dateFullFormat = ["MMMM"];
  const shortMonth = ["MMM"];

  const [MonthlyData, setMonthlyData] = useState();
  const [date, setdate] = useState();

  const onChange = (date, dateString) => {
    getAffiliateDashdata({ duration: moment(date).format(dateFormat[0]) }).then(
      (Res) => {
        setMonthlyData(Res?.data);
      }
    );
  };
  useEffect(() => {
    getAffiliateDashdata({ duration: "00" }).then((Res) => {
      setTodays(Res?.data);
    });
    getAffiliateDashdata({ duration: Duration }).then((Res) => {
      setMonthlyData(Res?.data);
    });
  }, []);
  const [Todays, setTodays] = useState();

  return (
    <div>
      <p style={{ margin: "25px 0px" }} className="fs-s-20 my-15 fw-700">
        Today
      </p>

      <div
        className="d-flex flex-wrap justify-content-between"
        style={{ gap: "5px" }}
      >
        {Object.values(options)?.map((items) => {
          return (
            <AffiliateRevenueCard
              cato={items?.name}
              value={Todays && Todays[items?.key1]}
              unit={items?.unit}
              lightMode={lightMode}
            />
          );
        })}
      </div>
      <div
        style={{ margin: "25px 0px" }}
        className="d-flex justify-content-between align-center-start my-15 d-flex-row-col"
      >
        <p className="fs-s-20 mb-10  fw-700">Monthwise</p>
        <div className="h-45">
          <DatePicker
            className={!lightMode && "DatePicker-Antd-Dark"}
            defaultValue={moment(new Date(), dateFormat)}
            disabledDate={disabledDate}
            format={dateFullFormat}
            dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
            value={date ? moment(date, dateFormat) : undefined}
            onChange={onChange}
            inputReadOnly={true}
            picker="month"
          />
        </div>
      </div>
      <div
        className="d-flex flex-wrap justify-content-between "
        style={{ gap: "5px" }}
      >
        {Object.values(options)?.map((items) => {
          return (
            <AffiliateRevenueCard
              cato={items?.name}
              value={MonthlyData && MonthlyData[items?.key1]}
              unit={items?.unit}
              lightMode={lightMode}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AffiliateMovesDash;
