import { Slider } from "antd";
import React, { useState } from "react";

function SliderWithInput({ lightMode, editFilterTypes, typeData }) {
  const [currentCap, setcurrentCap] = useState("LARGE");
  return (
    <div>
      <Slider
        // autoFocus={false}
        className={lightMode ? "filter-slider-light" : "filter-slider-dark"}
        range={{
          draggableTrack: true,
        }}
        value={[typeData.firstValue, typeData.secondValue]}
        defaultValue={[typeData.min, typeData.max]}
        trackStyle={{ background: lightMode ? "#545E78" : "#C9D7DE" }}
        handleStyle={{
          background: lightMode ? "#fff" : "#C9D7DE",
          border: "1px solid #545E78",
        }}
        min={typeData.min}
        max={typeData.max}
        onChange={(value) => {
          currentCap !== "NONE" && setcurrentCap("NONE");
          if (value[0] === typeData.firstValue) {
            editFilterTypes(typeData.arrIndex, "secondValue", value[1]);
          } else {
            editFilterTypes(typeData.arrIndex, "firstValue", value[0]);
          }
        }}
      />
      <div className="mt-20 d-flex justify-content-between align-items-center">
        <div className={`${lightMode ? "border1px-light-mode" : "border1px-dark-mode" }`}>
          <span className="ff-lato">{typeData?.symbol === "rs" && "₹"}</span>
          <input
            min={Number(typeData.min)}
            onChange={(e) => {
              currentCap !== "NONE" && setcurrentCap("NONE");
              editFilterTypes(typeData.arrIndex, "firstValue", e.target.value);
            }}
            value={typeData.firstValue}
            className={`br-3 bg-transparent`}
            style={{ maxWidth: "90px", height: "24px",border:'none' }}
            type="number"
          />
          {typeData.symbol === "%" && `%`}
        </div>
        <span>to</span>
        <div  className={`${lightMode ? "border1px-light-mode" : "border1px-dark-mode" }`}>
          <span className="ff-lato">{typeData?.symbol === "rs" && "₹"}</span>
          <input  
            max={Number(typeData.max)}  
            onChange={(e) => {
              currentCap !== "NONE" && setcurrentCap("NONE");
              editFilterTypes(typeData.arrIndex, "secondValue", e.target.value);
            }}
            value={typeData.secondValue}
            className={`br-3 bg-transparent `}
            style={{ maxWidth: "90px", height: "24px",border:'none' }}
            type="number"
          />
          {typeData.symbol === "%" && "%"}
        </div>
      </div>
      {typeData?.particular === "MCAP" && (
        <div className="my-20 d-flex justify-content-between">
          <button
            style={{ padding: "5px 10px", minWidth: "80px" }}
            className={`br-3 ${
              typeData.firstValue=="0"&& typeData.secondValue=="16800" ? "btn-bg-active" : "bg-transparent"
            } ${lightMode ? "border1px-light-mode" : "border1px-dark-mode"}`}
            onClick={() => {
              setcurrentCap("SMALL");
              editFilterTypes(typeData.arrIndex, "firstValue", "0");
              editFilterTypes(typeData.arrIndex, "secondValue", "16800");
            }}
          >
            Smallcap
          </button>
          <button
            onClick={() => {
              setcurrentCap("MID");
              editFilterTypes(typeData.arrIndex, "firstValue", "16800");
              editFilterTypes(typeData.arrIndex, "secondValue", "48900");
            }}
            style={{ padding: "5px 10px", minWidth: "80px" }}
            className={`br-3 ${
              typeData.firstValue=="16800"&& typeData.secondValue=="48900" ? "btn-bg-active" : "bg-transparent"
            }  ${lightMode ? "border1px-light-mode" : "border1px-dark-mode"}`}
          >
            Midcap
          </button>
          <button
            onClick={() => {
              setcurrentCap("LARGE");
              editFilterTypes(typeData.arrIndex, "firstValue", "48900");
              editFilterTypes(typeData.arrIndex, "secondValue", "2500000");
            }}
            style={{ padding: "5px 10px", minWidth: "80px" }}
            className={`br-3 ${
              typeData.firstValue==48900&& typeData.secondValue==2500000 ? "btn-bg-active" : "bg-transparent"
            }  ${lightMode ? "border1px-light-mode" : "border1px-dark-mode"}`}
          >
            Largecap
          </button>
        </div>
      )}
    </div>
  );
}

export default SliderWithInput;
