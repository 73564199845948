import React from "react";
import { useSelector } from "react-redux";
import StockEndpoints from "../../api/endPoints";
import TrendingStocks from "../../components/TrendingStocks";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import InvestersSidebar from "./sidebar/InvestersSidebar";
import BucketCard from "../Buckects/BucketCard";
import DotLoader from "../../components/spinners/DotLoader";
import { useNavigate } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import useGetFetch from "../../hooks/useGetFetch";
import RecentSearch from "../watchlist/RecentSearch";
import { authStore } from "../../redux/reducers/authSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";

function PortfolioIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const navigate = useNavigate();
  const topInverstors = [
    { image: 'https://tradebrains.in/wp-content/uploads/2020/10/RK-Damani-Cover.jpg', name: "Radhakishan Damani" },
    { image: 'https://tradebrains.in/wp-content/uploads/2020/10/Vijay-Kedia-Cover.jpg', name: "Vijay Kedia" },
    { image: 'https://tradebrains.in/wp-content/uploads/2020/10/Dolly-Khanna-Cover.jpg', name: "Dolly Khanna" },
    { image: 'https://tradebrains.in/wp-content/uploads/2020/10/Ashish-Kacholia-cover.jpg', name: "Ashish Kacholia" },
  ];
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.getSuperstars
  );
console.log(apiData);
  const cardNavigate = (isPremium, starName) => {
    if (isPremium) return;
    navigate(`/superstars/${starName}`);
  };

  if (!apiData) {
    return <DotLoader />;
  }
  return (
    <>
     <ReactHelmet
        title="Superstar Portfolio - Track the portfolio of your favourite super investors -  Trade Brains"
        desc="Track the investments and latest portfolios of the super investors in India share market"
      />
      <div className="ff-poppins max-w mx-auto  px-15  w-100">
        <div className="my-body">
        <div className="d-flex align-items-center  mb-30">
            <p className="mr-10 lh-36  mb-0 d-flex align-self-center">{svgSheet.analysisLogo}</p>
            <h1 className="mb-0 fs-36-20 fw-700 lh-36">Superstar Portfolio</h1>
          </div>
          {/* <p className=" mb-0 fs-36-20 fw-700 mb-15 ">Superstar Portfolio</p> */}
          <div className="d-flex">
            <div
              style={{ width: "20%", top: `${130+bannerOffsetCalc(UserAuth)}px`, height: "min-content" }}
              className="detail-left-card p-fixed"
            >
              <InvestersSidebar lightMode={lightMode} data={topInverstors} />
              <RecentSearch lightMode={lightMode} isOverlay={false} />
            </div>
            <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
              <div className="bucket-card-wrap">
                {apiData?.map((star, i) => (
                  <BucketCard
                    key={i}
                    isPremium={false}
                    bucketname={star.name}
                    stockNum={star.stocks_count}
                    cardImg={star.image}
                    clickFn={() => cardNavigate(false, star.name)}
                  />
                ))}
              </div>
            </div>
          </div>
          <TrendingStocks lightMode={lightMode} />
        </div>
      </div>
      {/* <MobileBottomBar navData={sidebarData} lightMode={lightMode} /> */}
    </>
  );
}

export default PortfolioIndex;
