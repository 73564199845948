import React, { useMemo, useState } from "react";
import CustomTable from "../../../components/CustomTable";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";
import { Table } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import TableExpandRow from "./TableExpandRow";
import { Link } from "react-router-dom";
import SortHeader from "../../screener/table section/SortHeader";
import useGetFetch from "../../../hooks/useGetFetch";
import StockEndpoints from "../../../api/endPoints";
function StarStockList({ lightMode, portId, name }) {
  const [sortState, setsortState] = useState({
    head: "NO_OF_SHARES",
    sortOrder: false,
  });
  const {
    isLoading: tableLoading,
    apiData: stockData,
    serverError: tableErr,
  } = useGetFetch(
    StockEndpoints.starDetails(name, sortState.head, sortState.sortOrder)
  );

  const tableData = useMemo(() => {
    const tableObj = stockData?.map((item, i) => {
      return {
        key: i,
        ...item,
      };
    });
    return tableObj;
  }, [lightMode, stockData]);
  // console.log(tableData);
  // const getDropList = async (finCode) => {
  //   await starTableReturns(portId,finCode).then((resp) => {
  //     settableDropData(resp)
  //   }).catch((err)=>{
  //     settableDropData({})
  //   })
  // };

  const StockConstituents = [
    {
      title: (
        <SortHeader
          label="Company Name"
          header="company_name"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "company_name",
      fixed: "left",
      width: "70px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              <Link to={`/stock/${record?.symbol}/consolidated`}>{text}</Link>
            </div>
          ),
        };
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: (
        <SortHeader
          label="No of shares"
          header="NO_OF_SHARES"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "NO_OF_SHARES",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="CMP"
          header="cmp"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "cmp",
      width: "50px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                ₹ {numberWithCommas(text)}
              </span>

              {/* <span
                className="ff-lato"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "10px",

                  color: `${redGreenColorPicker(20, lightMode)}`,
                }}
              >
                +{20 + "%"}
              </span> */}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Holdings"
          header="PERCENTAGE"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "PERCENTAGE",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text?.toFixed(2)} %
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Current Value(Cr)"
          header="current_value"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "current_value",
      width: "50px",
      render(text, record) {
        // const currValue = record.NO_OF_SHARES * record.cmp;
        // const fomatNum = (currValue / 10 ** 7).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(Number(text?.toFixed(2)))}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Change YTD%"
          header="ytd"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "ytd",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderLeft: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text, lightMode),
              }}
            >
              {text > 0 && "+"}
              {text}%
            </span>
          ),
        };
      },
      // filterDropdown: (props) => {
      //   return (
      //     <div>
      //       {props?.filters?.map((data, i) => (
      //         <p
      //           style={{ padding: "5px 10px" }}
      //           className="fs-s-16 fw-600  mb-0 pointer"
      //         >
      //           {data.text}
      //         </p>
      //       ))}
      //     </div>
      //   );
      // },
      // filters: [
      //   {
      //     text: "YTD %",
      //     value: "YTD",
      //   },
      //   {
      //     text: "52W Low %",
      //     value: "52WLow",
      //   },
      //   {
      //     text: "52W High %",
      //     value: "52WHigh",
      //   },
      // ],
      // ellipsis: true,
    },
  ];

  return (
    <div className="ant-tab-expand">
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={StockConstituents}
          data={tableData}
          scrollable={true}
          scrollLimit={800}
          loading={tableLoading}
          expandableData={{
            expandedRowRender: (record) => (
              <TableExpandRow
                lightMode={lightMode}
                finCode={record.FINCODE}
                portId={portId}
              />
            ),
            columnWidth: 15,
            fixed: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <div className=" w-100-100">
                  <CaretDownOutlined
                    style={{ color: lightMode ? "black" : "white" }}
                    className="w-100 h-100"
                    rotate={180}
                    onClick={(e) => onExpand(record, e)}
                  />
                </div>
              ) : (
                <CaretDownOutlined
                  style={{ color: lightMode ? "black" : "white" }}
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                />
              ),
          }}
        />
      </div>
    </div>
  );
}

export default StarStockList;
