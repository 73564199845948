import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateWatchListModel from "./CreateWatchListModel";

function MobileBottomSlider({setWatchList,WatchList, lightMode, watchlists,planLimit,setIsPremiumModel }) {
  const [OpenModel,SetOpenModel] = useState(false)
  const navigate  = useNavigate()
  return (
    <div
      style={{ height: "55px", zIndex: 500, position: "sticky", bottom: "0" }}
      className={` ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      } table-shadow   details-top-card w-100 br-5  d-flex align-items-center`}
    >
      <CreateWatchListModel OpenModel={OpenModel} SetOpenModel={SetOpenModel} lightMode={lightMode}/>

      <div className="overflow-x h-100">
        {watchlists.map((items,i) => (
          <div key={i}
          onClick={()=>setWatchList(items)}
            className="d-flex justify-content-center"
            style={{ minWidth: "100px" }}
          >
            <span
              style={{ minHeight: "38px" }}
              className={`${items === WatchList ? "" : "opacity5"} fs-s-12 fw-600 mr-10 text-align-center d-flex align-items-center `}
            >
              {items}
            </span>
          </div>
        ))}

        <div className="p-5 align-items-center d-flex justify-centen-center">
          <button
          onClick={()=>planLimit <= watchlists.length  ? setIsPremiumModel(true) :  SetOpenModel(true)}
            style={{ minWidth: "150px" }}
            className={`mr-5 fs-s-12 btn-blue-border ${lightMode ? "bg-table-data text-btn-light " : "bg-dark-gray text-btn-dark"} `}
          >
            + Create Watchlist{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MobileBottomSlider;
