export const ProfitAndLossINS = [
    {
      key: "revenue_from_operations",
      name: "Revenue From Operations ",
      highlight: true,
      unit: "₹",
      
    //   tooltipText:'This refers to the total money made by a company through the sale of goods and services for a given period. Sometimes, net sales and revenue are used interchangeably.'
    },
    {
      key: "income_from_investments",
      name: "Income From Investments ",
      unit: "₹",
    //   tooltipText:'Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.'
    },
    {
      key: "other_income",
      name: "Other Income",
      isNested: true,
      unit: "₹",
      tooltipText:'The income that a company generates from sources other than its core business functions. Ex: interest received on deposits, profits made on the sale of assets.'
    },
    {
      key: "expenses",
      name: "Expenses",
      isNested: true,
      unit: "₹",
    //   tooltipText:'Salaries spent on workforce.'
    },
    {
      key: "provisions",
      name: "Provisions",
      isNested: true,
      unit: "₹",
      tooltipText:'Provisions in accounting represent funds set aside to meet any probable contingency or losses. Some expenses or losses for which provisions are created are bad debts, depreciation, and inventory obsolescence.'
    },
    {
        key: "profit_before_tax",
        name: "Profit Before Tax",
        highlight: true,
        unit: "₹",
        tooltipText:'Profits made by the company before they have to pay corporate income tax.'
      },
      {
        key: "tax",
        name: "Tax",
        unit: "₹",
        tooltipText:'Taxes are compulsory contributions paid to the government.'
      },
      {
        key: "adjustments",
        name: "Adjustments",
        unit: "₹",
        // tooltipText: "Taxes are compulsory contributions paid to the government.",
      },
    {
      key: "net_profit",
      name: "Profit After Tax",
      highlight: true,
      unit: "₹",
    tooltipText:'The actual profit after working expenses, interest and taxes.'
    },
    {
      key: "adj_eps",
      name: "Adj. EPS (Rs)",
      unit: "₹",
  tooltipText:'Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.'
    },
    {
      key: "net_profit_margin",
      name: "Net Profit Margin",
      unit: "%",
      tooltipText:'The profit margin shows what percentage of sales are profits after all the expenses have been deducted.'
    },
  ];

  export const balanceSheetINS = [
    {
      key: "assets_ins",
      name: "Assets",
      isHeaderWithData: true,
      unit: "₹",
    highlight: true,
      tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
        key: "investments",
        name: "Investments",
        unit: "₹",
        tooltipText:
        "All investments made by the bank. These mainy include govt seurities, treasuries and bonds",
      },
      {
        key: "share_holders",
        name: "Shareholders",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "policy_holders",
        name: "Policyholders",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "assest_linked_liabilities",
        name: "Assets Linked Liabilities",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "loans",
        name: "Loans",
        isHeaderWithData: true,
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "secured_loans",
        name: "Secured Loans",
        isNested2x: true,
        // highlight: true,
        unit: "₹",
        tooltipText:'Secured loans are loans for which a company has submitted collateral to the bank. Let us say a company takes a loan of Rs. 1,000 crores from a bank and submits its office building as collateral. Tomorrow, if the company is not able to pay back the debt, the bank will seize the control of the building and sell it to recover its dues.'
      },
      {
        key: "non_secured_loans",
        name: "Non Secured Loans",
        isNested2x: true,
        // highlight: true,
        unit: "₹",
        tooltipText:'Non-secured loans have no underlying collateral. They are usually given at a higher rate of interest. Non-secured loans are generally provided to strong companies with a sustainable history of paying back debts.'
      },
      {
        key: "net_block",
        name: "Fixed Assets",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
    {
      key: "current_assets",
      name: "Current Assets +",
      isNested: true,
      highlight: true,
      unit: "₹",
      tooltipText:'Current assets are the assets which get used by the company within one financial year. They do not have future earning capacity. Common examples of current assets are raw materials, stationery, and cash.'
    },
    {
        key: "cash_and_bank_balances",
        name: "Cash & Bank Balances",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "advance_and_other_assets",
        name: "Advances & Other Assets",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "other_current_assets",
        name: "Other Current Assets",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "liabilities_ins",
        name: "Current Liabilities +",
        isNested: true,
        highlight: true,
        unit: "₹",
        tooltipText:'Current liabilities are payment obligations a company plans to pay within a year. Two examples are the amount owed to a supplier for purchasing raw material on credit and pending salary payable to the employees.'
      },
      {
        key: "share_holders_fund",
        name: "Shareholders Funds",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "share_capital",
        name: "Share Capital",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "reserve",
        name: "Reserve and Surplus",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "borrowings",
        name: "Borrowings",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "policy_holders_funds",
        name: "Policyholders Funds",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
      {
        key: "fund_of_appropriation",
        name: "Funds for Appropriation",
        unit: "₹",
        // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
      },
    
      // {
      //   key: "current_liabilities",
      //   name: "Current Liabilities +",
      //   isNested: true,
      //   highlight: true,
      //   unit: "₹",
      //   tooltipText:'Current liabilities are payment obligations a company plans to pay within a year. Two examples are the amount owed to a supplier for purchasing raw material on credit and pending salary payable to the employees.'
      // },
  ]; 

  export const FiveYearTableINS = [
    {
      key: "",
      name: "Profitability Ratios",
      isHeader: true,
    },
    {
      key: "net_margin",
      name: "Net Profit Margin",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Net Profit ⁄ Total revenue x 100
          </p>
          <p className="mb-0">
          The ratio calculates the percentage of profit a company produces from its total revenue after accounting for all its expenses.
          </p>
        </>
      )
    },
    {
      key: "ebitda",
      name: "EBITDA Margin",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          (EBIT + Depreciation + Amortization) / Total revenue X 100
          </p>
          <p className="mb-0">
          It is a measure of a company's operating profit as a percentage of its revenue after considering its cash flows.
          </p>
        </>
      )
    },
    {
      key: "roe",
      name: "Return On Equity (ROE)",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          ROE = Net Income / Shareholders’ Equity
          </p>
          <p className="mb-0">
          ROE is the return on your money invested into the company, which is calculated per share.
          </p>
        </>
      )
    },
    {
      key: "roce",
      name: "Return On Cap Employed (ROCE)",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          EBIT/ Capital Employed
          </p>
          <p className="mb-0">
          The ratio measures the comapany's profitability in terms of its capital which includes equity and debt. 
          </p>
        </>
      )
    },
    {
      key: "roa",
      name: "Return On Assets (ROA)",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Net Income / Total Assets
          </p>
          <p className="mb-0">
          It measure how well the company is able to use its assets to generate profits.
          </p>
        </>
      )
    },
    {
      key: "eps",
      name: "Earnings Per Share (EPS)",
      isNested: true,
      unit: "₹",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          (Net Income - Preference dividend ) / Total shares out standing
          </p>
          <p className="mb-0">
          It measures how much profit the company makes for each of its shareholders.
          </p>
        </>
      )
    },
    {
      key: "adj_eps",
      name: "Adj. Earnings Per Share",
      isNested: true,
      unit: "₹",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          (Net Income - Extraordinary gains / losses) / Total shares out standing
          </p>
          <p className="mb-0">
          Adjusted EPS removes any non-recurring extraordinary gains or losses from the net income and then calculates the EPS.
          </p>
        </>
      )
    },
    {
      key: "",
      name: "Valuation Ratios",
      isHeader: true,
  
    },
    {
      key: "ev_ebitda",
      name: "EV/EBITDA ",
      isNested: true,
      tooltipText:'It is used a valuation metric to compare the relative value of different businesses in a sector'
    },
    {
      key: "yield",
      name: "Dividend Yield ",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Annual Dividend per share / Current share price
          </p>
          <p className="mb-0">
          It shows how much a company pays out in dividends each year relative to its current market price.
          </p>
        </>
      )
    },
    {
      key: "adj_pe",
      name: "PE Ratio",
      isNested: true,
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Current market price / Earnings per share
          </p>
          <p className="mb-0">
          It provides an indication whether a stock at its current market price is expensive or cheap realtive to its earnings
          </p>
        </>
      )
    },
    {
      key: "price_to_book_value",
      name: "Price To Book Value ",
      isNested: true,
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Market price per share / Book Value per share
          </p>
          <p className="mb-0">
          P/B Value is the ratio of the current market value of the company shares to the book value of equity. It is used to estimate if the company is over or undervalued.
          </p>
        </>
      )
    },
    {
      key: "price_to_sales_ratio",
      name: "Price/Sales Ratio ",
      isNested: true,
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Market price per share / Sales per share
          </p>
          <p className="mb-0">
          It measures how much the investors are willing to pay per rupee of sales for a stock
          </p>
        </>
      )
    },
    {
      key: "mcap_to_sales",
      name: "Market Cap To Sales ",
      isNested: true,
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Market capitalization / Operating cashflow
          </p>
          <p className="mb-0">
          It indicates how the market is valuing every rupee of the company's sales.
          </p>
        </>
      )
    },
    {
      key: "price_to_cash_flow",
      name: "Price To Cashflow ",
      isNested: true,
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Market capitalization / Total sales or Revenue
          </p>
          <p className="mb-0">
          It measures the value of a stock's price relative to its operating cash flow per share
          </p>
        </>
      )
    },
    {
      key: "",
      name: "Dividend & Other Metrics",
      isHeader: true,
    },
    {
      key: "ev",
      name: "Enterprise Value",
      isNested: true,
      unit: "₹",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Market Capitalization + Market Value of Debt – Cash and Equivalents.
          </p>
          <p className="mb-0">
          Enterprise Value is the measure of a company's total value, including equity and short term and long term debt.
          </p>
        </>
      )
    },
    {
      key: "dividend_per_share",
      name: "Dividend Per Share",
      isNested: true,
      unit: "₹",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          (Total Dividend paid - Special Dividend) / Shares outstanding
          </p>
          <p className="mb-0">
          It is the sum of declared dividends issued by a company for every ordinary share outstanding
          </p>
        </>
      )
    },
    {
      key: "adj_dividend_per_share",
      name: "Adj Dividend Per Share",
      isNested: true,
      unit: "₹",
    },
    {
      key: "dividend_payout_ratio",
      name: "Dividend Payout Ratio",
      isNested: true,
      unit: "%",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Total Dividend paid / Net Income
          </p>
          <p className="mb-0">
          It is the percentage of net earnings paid to shareholders in the form of dividends
          </p>
        </>
      )
    },
    {
      key: "free_cash_flow_per_share",
      name: "Free Cash Flow Per Share",
      isNested: true,
      unit: "₹",
      tooltipText: (
        <>
          <p className="mb-10 fw-500">
          Free cashflows / Total shares outstanding
          </p>
          <p className="mb-0">
          It measures the company's financial flexibility to repay its bligations and also predicts the growth of the business
          </p>
        </>
      )
    },
  ];
  