import { CaretUpOutlined } from "@ant-design/icons";
import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import AreaChart from "../../../components/Charts/AreaChart";
import { getGradient } from "../../../components/Charts/getGradients";
import LineChart from "../../../components/Charts/LineChart";
import { convertTograph } from "../../../utilityFn/convertDataToChart";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";

function IndexGraphCard({ lightMode, name, stockChange, graphData = [] }) {
  const chartPoints = useMemo(
    () => convertTograph(graphData, "days=1"),
    [graphData]
  );

  const gradientColors = [
    [
      { xValue: 0, color: "rgba(0, 133, 128, 0.7)" },
      { xValue: 1, color: "rgba(0, 133, 128, 0)" },
    ],
    [
      { xValue: 0, color: "rgba(188, 55, 84, 0.6)" },
      { xValue: 1, color: "rgba(188, 55, 84, 0)" },
    ],
  ];
  const data = {
    labels: chartPoints?.labelArray,
    datasets: [
      {
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          } else if (stockChange?.change >= 0)
            return getGradient(ctx, chartArea, gradientColors[0]);
          else return getGradient(ctx, chartArea, gradientColors[1]);
        },
        label: {
          display: false,
        },
        data: chartPoints?.graphPointsArray,
        borderColor: stockChange?.change >= 0 ? ["#008580"] : ["#BC3754"],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div
      style={{ height: "275px" }}
      className={`animation-div ${
        lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <div className="p-15">
        <Link to={`Index/${name}`}>
          <p className="fs-s-16 fw-500 mb-10">{name}</p>
        </Link>
        <p
          className={`d-flex d-flex-row lh-20  fs-s-20 mb-10 fw-500 align-items-center fw-600`}
        >
          <CaretUpOutlined
            style={{
              color: redGreenColorPicker(stockChange?.change, lightMode),
            }}
            rotate={stockChange?.change < 0 ? 180 : 0}
          />
          {numberWithCommas(stockChange?.close)}
        </p>
        <p
          className={` fw-500 fs-s-14 mb-0 `}
          style={{ color: redGreenColorPicker(stockChange?.change, lightMode) }}
        >
          {stockChange?.percent?.toFixed(2)}% ({stockChange?.change?.toFixed(2)}
          )
        </p>
      </div>
      <div style={{ height: "57%" }}>
        <AreaChart
          dataPoints={data}
          options={{
            responsive: true,

            maintainAspectRatio: false,
            tension: 0,
            scales: {
              y: {
                display: false,
              },

              x: {
                display: false,
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                backgroundColor: lightMode ? "#ffff" : "#292E3F",
                displayColors: false,

                callbacks: {
                  labelTextColor: () => (lightMode ? "black" : "white"),

                  title: (chart, args) => {
                    return "";
                  },
                  label: (context) => `${context.formattedValue}`,
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
          }}
          height={"100%"}
        />
      </div>
    </div>
  );
}

export default memo(IndexGraphCard);
