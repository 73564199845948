import React from "react";
import CustomPieChart from "../../../components/Charts/CustomPieChart";

function BacktestAnalysis({ lightMode }) {
  const data = {
    labels: ["Reliance", "Adani", "ITC", "TCS", "VEDL"],
    datasets: [
      {
        data: [3, 4, 34, 23, 21],
        backgroundColor: [
          "#FF9177",
          "#EB5757",
          "#5ECBC8",
          "#438FFF",
          "#BF81FB",
          "#4A95DA",
        ],
        // backgroundColor:(context)=>{
        // },
        borderColor: ["#FF9177", "#EB5757", "#5ECBC8", "#438FFF", "#BF81FB"],
        borderWidth: 0,
        hoverOffset: 15,
      },
    ],
  };
  const options = {
    layout: { padding: 10 },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        enabled: true,
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            return context.dataset.backgroundColor[context.dataIndex];
          },
          //   labelTextColor: (context) => {
          //     return "#438FFF";
          //   },
          label: (context) => {
            return `${context.label}:${context.formattedValue}%`;
          },
        },
      },
    },
  };
  return (
    <div id="Analysis">
      <h2 className="main-header">Analysis</h2>
      <div
        style={{ gap: "30px" }}
        className="flex flex-col-row justify-content-between"
      >
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 1</h3>
          <CustomPieChart data={data} options={options} />
          <div
            // onClick={removeHover}
            className=" w-100 mt-20  ff-rubik d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                overflowY: "scroll ",
                maxHeight: "350px",
              }}
              className="scroll pointer"
            >
              {data?.labels?.map((holder, i) => {
                return (
                  <div
                    key={i}
                    className="flex"
                    style={{ color: lightMode ? "black" : "white" }}
                    id={`${"stock"}${i}`}
                  >
                    <span
                      className="legend-dot"
                      style={{
                        background: data?.datasets[0]?.backgroundColor[i],
                      }}
                    ></span>
                    <p className="fs-s-16">
                      {holder} : {data?.datasets[0]?.data[i]?.toFixed(2)}%
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 2</h3>
          <CustomPieChart data={data} options={options} />
          <div
            // onClick={removeHover}
            className=" w-100  mt-20 ff-rubik d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                overflowY: "scroll ",
                maxHeight: "350px",
              }}
              className="scroll pointer"
            >
              {data?.labels?.map((holder, i) => {
                return (
                  <div
                    key={i}
                    className="flex"
                    style={{ color: lightMode ? "black" : "white" }}
                    id={`${"stock"}${i}`}
                  >
                    <span
                      className="legend-dot"
                      style={{
                        background: data?.datasets[0]?.backgroundColor[i],
                      }}
                    ></span>
                    <p className="fs-s-16">
                      {holder} : {data?.datasets[0]?.data[i]?.toFixed(2)}%
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 3</h3>
          <CustomPieChart data={data} options={options} />
          <div
            // onClick={removeHover}
            className=" w-100  mt-20 ff-rubik d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                overflowY: "scroll ",
                maxHeight: "350px",
              }}
              className="scroll pointer"
            >
              {data?.labels?.map((holder, i) => {
                return (
                  <div
                    key={i}
                    className="flex"
                    style={{ color: lightMode ? "black" : "white" }}
                    id={`${"stock"}${i}`}
                  >
                    <span
                      className="legend-dot"
                      style={{
                        background: data?.datasets[0]?.backgroundColor[i],
                      }}
                    ></span>
                    <p className="fs-s-16">
                      {holder} : {data?.datasets[0]?.data[i]?.toFixed(2)}%
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BacktestAnalysis;
