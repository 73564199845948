import { InfoCircleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import CustomTooltip from "../../components/ant/CustomTooltip";
import GaugeAnt from "../../components/Charts/GaugeAnt";

function DividendsPortfolioAnalysis({ lightMode, divident, IsOverFlowHidden }) {
  const gaugetChartfirstData = {
    title: {
      formatter: ({ percent }) => {
        return ``;
      },
      offsetY: 15,
      style: ({ percent }) => {
        return {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: 2,
          // color: percent<.35?'#F5AF19':percent>.35&&percent<.65?'#1AFF00':'#F27121',
          color: lightMode ? "#575757" : "white",
        };
      },
    },
    content: {
      // formatter: ( {percent} ) => {
      //   percent*10
      //   return ``;
      // },
      offsetY: 45,
      style: () => {
        return {
          fontSize: "16px",
          color: lightMode ? "#575757" : "white",
          fontWeight: "400",
        };
      },
      formatter: ({ percent }) => ``,

      // formatter: ({ percent }) => console.log("percents",percent*100),
    },
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h2 className="main-header" style={{ marginBottom: "5px" }}>
            Dividends Payers List
          </h2>
          <p>Annual yield of Divident payers</p>
        </div>

      </div>
      <div
        className={` w-100 br-5 d-flex justify-content-between d-flex-row-col mt-15 p-20 box-shadow-card`}
      >
        <div className="w-25-100 scroll ">
          <div style={{ height: "300px", marginInline: "auto" }}>
            <GaugeAnt
              id="DPR"
              labelFormat={{
                formatter(v) {
                  return Number(v) * 100;
                },
              }}
              percetage={
                divident?.average_dividend
                  ? divident?.average_dividend / 10
                  : 100
              }
              colors={[
                "l(0) 0.1:rgba(234, 54, 54, 1) 0.2:rgba(255, 252, 0, 1) 0.6:rgba(0, 199, 79, 1) 0.8:rgba(255, 252, 0, 1) 0.9:rgba(234, 54, 54, 1)",
              ]}
              width={300}
              labelData={gaugetChartfirstData}
            />
          </div>
          <div className="d-flex  align-items-center flex-col">
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-5"> Avg Div Yield</p>
              <CustomTooltip
                placement="bottom"
                text={
                  "The Yield shows how much a company pays out in dividends each year relative to its stock price. But here we factor in the portion taken up by stocks in your portfolio to give you a realistic picture of its impact."
                }
              >
                {svgSheet.infoTooltop}
              </CustomTooltip>
            </div>
            <p>
              {divident?.average_dividend}
              {"%"}
            </p>
          </div>
        </div>
        <div className="w-65-100">
          <div
            className={`d-flex justify-content-between mt-15 ${
              IsOverFlowHidden ? "" : "scroll"
            }`}
          >
            <p
              style={{ width: "25%" }}
              className="fs-s-16 fw-500  d-flex align-items-center fw-700 justify-content-start  "
            >
              Name
            </p>
            <p
              style={{ width: "25%" }}
              className="fs-s-16 fw-500  d-flex align-items-center fw-700 justify-content-start  "
            >
              Dividend yield
            </p>
            <p
              style={{ width: "25%" }}
              className="fs-s-16 fw-500  d-flex align-items-center fw-700 justify-content-start  "
            >
              DPS
            </p>
          </div>
          <div
            style={{ height: IsOverFlowHidden ? "100%" : "300px" }}
            className={`${IsOverFlowHidden ? "" : "overflow-scroll  scroll"}`}
          >
            {divident?.items?.map((item) => (
              <div
                className={`w-100 ${
                  lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
                } d-flex justify-content-between align-items-center my-15 `}
              >
                {console.log("itemsdata", item)}

                <Link
                  to={`/stock/${item?.symbol}/consolidated`}
                  className="w-25"
                >
                  {" "}
                  <p
                    style={{ width: "100%" }}
                    className="fs-s-14 fw-500 link-hover-underline d-flex align-items-center justify-content-start  "
                  >
                    {item?.company}
                  </p>
                </Link>
                <p
                  style={{ width: "25%" }}
                  className="fs-s-14 fw-500 ff-lato d-flex align-items-center justify-content-start"
                >
                  {item?.dividend_yield}%
                </p>
                <p
                  style={{ width: "25%" }}
                  className="fs-s-14 fw-500 ff-lato d-flex align-items-center justify-content-start"
                >
                  ₹{item?.dps}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DividendsPortfolioAnalysis;
