import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './reducers/authSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import watchListSlice from './reducers/watchListSlice';
import ThemeSlice from './reducers/ThemeSlice';
import screenerSlice from './reducers/screenerSlice';
import ActiveSearchSlice from './reducers/ActiveSearchSlice';
import RecenSearchSlice from './reducers/RecenSearchSlice';
export const reducers = combineReducers({
      auth: authSlice,
      watchlist:watchListSlice,
      theme:ThemeSlice,
      screener:screenerSlice,
      ActiveSearch:ActiveSearchSlice,
      RecentSearch:RecenSearchSlice,
  })

  const persistConfig = {
    key: 'root',
    storage,
  };
  

  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV === 'development',
    // middleware: [thunk],
  });
  

export default store;