export const ArrayofSectors = [
    {apiValue:"Abrasives",shortValue:"Abrasives"},
    {apiValue:"Pesticides & Agrochemicals",shortValue:"Agrochemicals"},
    {apiValue:"Air Conditioners",shortValue:"Air Conditioners"},
    {apiValue:"Airlines",shortValue:"Airlines"},
    {apiValue:"Aluminium & Aluminium Products",shortValue:"Aluminium"},
    {apiValue:"Amusement Parks, Recreation, Club",shortValue:"Amusement Parks"},
    {apiValue:"Animation",shortValue:"Animation"},
    {apiValue:"Aquaculture",shortValue:"Aquaculture"},
    {apiValue:"Auto Ancillary",shortValue:"Auto Ancillary"},
    {apiValue:"Automobile Two & Three Wheelers",shortValue:"Auto - 2 & 3 Wheelers"},
    {apiValue:"Automobiles - Passenger Cars",shortValue:"Auto - Passenger Cars"},
    {apiValue:"Automobiles-Tractors",shortValue:"Auto-Tractors"},
    {apiValue:"Automobiles-Trucks,Lcv",shortValue:"Auto -Trucks"},
    {apiValue:"Bank - Private",shortValue:"Bank - Private"},
    {apiValue:"Bank - Public",shortValue:"Bank - Public"},
    {apiValue:"Batteries",shortValue:"Batteries"},
    {apiValue:"Bearings",shortValue:"Bearings"},
    {apiValue:"Breweries & Distilleries",shortValue:"Breweries"},
    {apiValue:"Cable",shortValue:"Cable"},
    {apiValue:"Carbon Black",shortValue:"Carbon Black"},
    {apiValue:"Castings,Forgings",shortValue:"Castings/Forgings"},
    {apiValue:"Cement & Construction Materials",shortValue:"Construction Materials"},
    {apiValue:"Ceramics,Marble,Granite,Sanitaryware",shortValue:"Ceramics"},
    {apiValue:"Chemicals",shortValue:"Chemicals"},
    {apiValue:"Cigarettes,Tobacco",shortValue:"Cigarettes"},
    {apiValue:"Compressors, Pumps",shortValue:"Compressors "},
    {apiValue:"Construction - Real Estate",shortValue:"Real Estate"},
    {apiValue:"Consumer Durables - Domestic Appliances",shortValue:"Domestic Appliances"},
    {apiValue:"Consumer Durables - Electronics",shortValue:"Electronics"},
    {apiValue:"Consumer Food",shortValue:"Consumer Food"},
    {apiValue:"Courier Services",shortValue:"Courier Services"},
    {apiValue:"Detergents & Soaps",shortValue:"Soaps"},
    {apiValue:"Diamond  &  Jewellery",shortValue:"Jewellery"},
    {apiValue:"Diesel Engines",shortValue:"Diesel Engines"},
    {apiValue:"Diversified",shortValue:"Diversified"},
    {apiValue:"Dyes & Pigments",shortValue:"Dyes & Pigments"},
    {apiValue:"Electric Equipment",shortValue:"Electric Equipment"},
    {apiValue:"Electrodes & Welding Equipment",shortValue:"Welding Equipment"},
    {apiValue:"Electronics - Components",shortValue:"Electronics - Components"},
    {apiValue:"Engineering",shortValue:"Engineering"},
    {apiValue:"Engineering - Construction",shortValue:"Construction"},
    {apiValue:"Engineering - Industrial Equipments",shortValue:"Industrial Equipments"},
    {apiValue:"Fasteners",shortValue:"Fasteners"},
    {apiValue:"Fertilizers",shortValue:"Fertilizers"},
    {apiValue:"Finance - Housing",shortValue:"Finance - Housing"},
    {apiValue:"Finance - Investment",shortValue:"Finance - Investment"},
    {apiValue:"Finance - NBFC",shortValue:"Finance - NBFC"},
    {apiValue:"Finance - Stock Broking",shortValue:"Finance - Stock Broking"},
    {apiValue:"Finance Term Lending",shortValue:"Finance Term Lending"},
    {apiValue:"Film Production, Distribution & Entertainment",shortValue:"Entertainment"},
    {apiValue:"Floriculture",shortValue:"Floriculture"},
    {apiValue:"Forgings",shortValue:"Forgings"},
    {apiValue:"Glass",shortValue:"Glass"},
    {apiValue:"Hospital & Healthcare Services",shortValue:"Healthcare Services"},
    {apiValue:"Hotel, Resort & Restaurants",shortValue:"Hotel"},
    {apiValue:"Household & Personal Products",shortValue:"Household Products"},
    {apiValue:"Industrial Gases & Fuels",shortValue:"Industrial Gases & Fuels"},
    {apiValue:"IT - Education",shortValue:"IT - Education"},
    {apiValue:"IT - Hardware",shortValue:"IT - Hardware"},
    {apiValue:"IT - Software",shortValue:"IT - Software"},
    {apiValue:"Laminates, Decoratives",shortValue:"Laminates"},
    {apiValue:"Leather",shortValue:"Leather"},
    {apiValue:"Logistics",shortValue:"Logistics"},
    {apiValue:"Lubricants",shortValue:"Lubricants"},
    {apiValue:"Medical Equipment, Supplies, Accessories",shortValue:"Medical Equipment"},
    {apiValue:"Metal - Ferrous",shortValue:"Metal - Ferrous"},
    {apiValue:"Metal - Non Ferrous",shortValue:"Metal - Non Ferrous"},
    {apiValue:"Mining & Minerals",shortValue:"Mining & Minerals"},
    {apiValue:"Miscellaneous",shortValue:"Miscellaneous"},
    {apiValue:"Oil Exploration",shortValue:"Oil Exploration"},
    {apiValue:"Packaging",shortValue:"Packaging"},
    {apiValue:"Paints",shortValue:"Paints"},
    {apiValue:"Paper & Paper Products",shortValue:"Paper"},
    {apiValue:"Petrochemicals",shortValue:"Petrochemicals"},
    {apiValue:"Pharmaceuticals & Drugs",shortValue:"Pharmaceuticals"},
    {apiValue:"Photographic Products",shortValue:"Photographic Products"},
    {apiValue:"Plastic Products",shortValue:"Plastic Products"},
    {apiValue:"Power Generation, Distribution",shortValue:"Power Generation"},
    {apiValue:"Transmission Towers, Equipments",shortValue:"Transmission Towers"},
    {apiValue:"Telecommunication - Service  Provider",shortValue:"Telecom - Service Provider"},
    {apiValue:"Printing & Stationery",shortValue:"Printing & Stationery"},
    {apiValue:"Ratings",shortValue:"Ratings"},
    {apiValue:"Refractories",shortValue:"Refractories"},
    {apiValue:"Retailing",shortValue:"Retailing"},
    {apiValue:"Rubber  Products",shortValue:"Rubber Products"},
    {apiValue:"Shipping",shortValue:"Shipping"},
    {apiValue:"Solvent Extraction",shortValue:"Solvent Extraction"},
    {apiValue:"Steel & Iron Products",shortValue:"Steel & Iron Products"},
    {apiValue:"Steel,Sponge & Iron,Pig Iron",shortValue:"Steel"},
    {apiValue:"Sugar",shortValue:"Sugar"},
    {apiValue:"Tea,Coffee",shortValue:"Tea/Coffee"},
    {apiValue:"Telecommunication - Equipment",shortValue:"Telecom - Equipment"},
    {apiValue:"Textile",shortValue:"Textile"},
    {apiValue:"Textile - Machinery",shortValue:"Textile - Machinery"},
    {apiValue:"Textile - Manmade Fibres",shortValue:"Textile - Manmade Fibres"},
    {apiValue:"Textile - Spinning",shortValue:"Textile - Spinning"},
    {apiValue:"Textile - Weaving",shortValue:"Textile - Weaving"},
    {apiValue:"Trading",shortValue:"Trading"},
    {apiValue:"Travel Services",shortValue:"Travel Services"},
    {apiValue:"TV Broadcasting & Software Production",shortValue:"TV Broadcasting"},
    {apiValue:"Tyres & Allied",shortValue:"Tyres & Allied"},
    {apiValue:"Watches & Accessories",shortValue:"Watches & Accessories"},
    {apiValue:"Wood & Wood Products",shortValue:"Wood"},
    {apiValue:"Port",shortValue:"Port"},
    {apiValue:"Railways Wagons",shortValue:"Railways Wagons"},
    {apiValue:"Printing And Publishing",shortValue:"Printing And Publishing"},
    {apiValue:"Unspecified",shortValue:"Unspecified"},
    {apiValue:"Agriculture",shortValue:"Agriculture"},
    {apiValue:"Ship Building",shortValue:"Ship Building"},
    {apiValue:"Other",shortValue:"Other"},
    {apiValue:"Insurance",shortValue:"Insurance"},
    {apiValue:"Ferro & Silica Manganese",shortValue:"Ferro & Silica Manganese"},
    {apiValue:"Gas Transmission, Marketing",shortValue:"Gas Transmission"},
    {apiValue:"Refineries",shortValue:"Refineries"},
    {apiValue:"Index",shortValue:"Index"},
    {apiValue:"Educational Institutions",shortValue:"Educational Institutions"},
    {apiValue:"BPO, ITeS",shortValue:"BPO/ITeS"},
    {apiValue:"IT - Networking",shortValue:"IT - Networking"},
    {apiValue:"Automobiles - Dealers & Distributors",shortValue:"Auto - Dealers"},
    {apiValue:"Cycles",shortValue:"Cycles"},
    {apiValue:"ETF",shortValue:"ETF"},
    {apiValue:"Cash and Cash Equivalents",shortValue:"Cash &Cash Equivalents"},
    {apiValue:"e-Commerce",shortValue:"e-Commerce"},
    {apiValue:"Defence",shortValue:"Defence"},
    {apiValue:"Finance - Others",shortValue:"Finance - Others"},
    {apiValue:"Footwear",shortValue:"Footwear"},
    {apiValue:"G-Sec",shortValue:"G-Sec"},
    {apiValue:"Finance - Asset Management",shortValue:"Finance - AMC"},
    {apiValue:"Edible oil",shortValue:"Edible oil"},

]

const newSector = [
"INDUSTRY",
"Abrasives",
"Pesticides & Agrochemicals",
"Air Conditioners",
"Airlines",
"Aluminium & Aluminium Products",
"Amusement Parks, Recreation, Club",
"Animation",
"Aquaculture",
"Auto Ancillary",
"Automobile Two & Three Wheelers",
"Automobiles - Passenger Cars",
"Automobiles-Tractors",
"Automobiles-Trucks,Lcv",
"Bank - Private",
"Bank - Public",
"Batteries",
"Bearings",
"Breweries & Distilleries",
"Cable",
"Carbon Black",
"Castings,Forgings",
"Cement & Construction Materials",
"Ceramics,Marble,Granite,Sanitaryware",
"Chemicals",
"Cigarettes,Tobacco",
"Compressors, Pumps",
"Construction - Real Estate",
"Consumer Durables - Domestic Appliances",
"Consumer Durables - Electronics",
"Consumer Food",
"Courier  Services",
"Detergents & Soaps",
"Diamond  &  Jewellery",
"Diesel Engines",
"Diversified",
"Dyes & Pigments",
"Electric Equipment",
"Electrodes & Welding Equipment",
"Electronics - Components",
"Engineering",
"Engineering - Construction",
"Engineering - Industrial Equipments",
"Fasteners",
"Fertilizers",
"Finance - Housing",
"Finance - Investment",
"Finance - NBFC",
"Finance - Stock Broking",
"Finance Term Lending",
"Film Production, Distribution & Entertainment",
"Floriculture",
"Forgings",
"Glass",
"Hospital & Healthcare Services",
"Hotel, Resort & Restaurants",
"Household & Personal Products",
"Industrial  Gases & Fuels",
"IT - Education",
"IT - Hardware",
"IT - Software",
"Laminates, Decoratives",
"Leather",
"Logistics",
"Lubricants",
"Medical Equipment, Supplies, Accessories",
"Metal - Ferrous",
"Metal - Non Ferrous",
"Mining & Minerals",
"Miscellaneous",
"Oil Exploration",
"Packaging",
"Paints",
"Paper & Paper Products",
"Petrochemicals",
"Pharmaceuticals & Drugs",
"Photographic Products",
"Plastic Products",
"Power Generation, Distribution",
"Transmission Towers, Equipments",
"Printing & Stationery",
"Ratings",
"Refractories",
"Retailing",
"Rubber  Products",
"Shipping",
"Solvent  Extraction",
"Steel & Iron Products",
"Steel,Sponge & Iron,Pig Iron",
"Sugar",
"Tea,Coffee",
"Telecommunication - Equipment",
"Telecommunication - Service  Provider",
"Textile",
"Textile - Machinery",
"Textile - Manmade  Fibres",
"Textile - Spinning",
"Textile - Weaving",
"Trading",
"Travel Services",
"TV Broadcasting & Software Production",
"Tyres & Allied",
"Watches & Accessories",
"Wood & Wood Products",
"Port",
"Railways Wagons",
"Printing And Publishing",
"Unspecified",
"Agriculture",
"Ship Building",
"Other",
"Insurance",
"Ferro & Silica Manganese",
"Gas Transmission, Marketing",
"Refineries",
"Index",
"Educational Institutions",
"BPO, ITeS",
"IT - Networking",
"Automobiles - Dealers & Distributors",
"Cycles",
"ETF",
"Cash and Cash Equivalents",
"e-Commerce",
"Defence",
"Finance - Others",
"Footwear",
"G-Sec",
"Finance - Asset Management",
"Edible Oils",]