import React, { useEffect } from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import SimilarStockCard from "./SimilarStockCard";

function SimilarStockSlider({ lightMode, data }) {
  const props = {
    dots: false,
    infinite: true,
    // speed: 500,
    slidesToShow: data?.length>5?5:data?.length,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1400, // laptop breakpoint
        settings: {
          slidesToShow: data?.length>4?4:data?.length,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 1150, // tablet breakpoint
        settings: {
          slidesToShow: data?.length>3?3:data?.length,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 500, // mobile breakpoint
        settings: {
          slidesToShow: data?.length>2?2:data?.length,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };
  
  const toggleArrow = (from, to) => {
    if (from === 1 && to === 0) {
    }
  };
  return (
    <div className="mb-30 w-100">
      <p className="my-20 fs-20-16 fw-500">Similar Stocks</p>
      <Carousel
        {...props}
        className="flex"
        beforeChange={(from, to) => toggleArrow(from, to)}
        prevArrow={
          <LeftOutlined
            id="silder-leftArr "
            style={{ fontSize: "14px", fontWeight: "600" }}
          />
        }
        nextArrow={
          <RightOutlined style={{ fontSize: "14px", fontWeight: "600" }} />
        }
      >
        {data?.map((item, i) => (
          <div key={i}>
            <SimilarStockCard lightMode={lightMode} symbol={item.symbol??item.scripcode} comp_name={item.company_name}/>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default SimilarStockSlider;
