import { Gauge } from "@ant-design/plots";
import React from "react";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import numFormatter from "../../utilityFn/numFormatter";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function PortFolioCard({ lightMode, value, type, colored, change, isMeter }) {
  console.log("type",type);
  const config = {
    style: {
      width: 90,
      height: 90,
      fill: "red",
      stroke: "black",
    },
    percent: type,
    range: {
      color:
        "l(0) 0:rgba(234, 54, 54, 1) 0.5:rgba(255, 252, 0, 1) 1:rgba(0, 199, 79, 1)",
    },

    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          fontSize: "14px",
          color: redGreenColorPicker(type, lightMode),
        },
        formatter: () => type + "%",
      },

      // content: {
      //   style: {
      //     fontSize: '24px',
      //     lineHeight: '44px',
      //     color: '#4B535E',

      //   },
      //   formatter: () => '加载进度',
      // },
    },
  };
  return (
    <div
      className={`${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }  box-shadow-card PortFolioCard  d-flex align-items-center p-10 ${
        isMeter ? "justify-content-center" : "justify-content-start"
      }`}
    >
      <div className="d-flex flex-col w-100 p-10 relative">
        {!isMeter ? (
          <p
            style={{ color: colored && redGreenColorPicker(change, lightMode) }}
            className="fw-600 fs-s-20 ff-lato justify-content-center mb-10 fw-900"
          >
            {colored && (value >= 0 ? "+" : "-")} ₹{" "}
            {value > 0
              ? numberWithCommas(numFormatter(Number(value)))
              : numberWithCommas(numFormatter(Math.abs(Number(value))))}
          </p>
        ) : (
          // <div className="mb-20  d-flex justify-content-center">
          //   <Gauge {...config} />
          // </div>
          <div>
            <p

            style={{ color: colored && redGreenColorPicker(type, lightMode) ,marginBottom:"26px"}}
            className="fw-600 fs-s-20 d-flex justify-content-start ff-lato mb-10 fw-900"
          >
          {type?.toFixed(2)}%
          </p>
          </div>
        )}
        {!isMeter ? (
          <div className="d-flex w-100 jusify-content-center">
            <p className="fs-s-14 fw-500  mb-0">{type ? type : 0}</p>
            <p

              className="fs-s-14 d-flex justify-content-start align-items-center ml-10 mb-0"
              style={{
                color: colored && redGreenColorPicker(change, lightMode),
              }}
            >
              {change && change + "%"}
            </p>
          </div>
        ) : (
          <p
            style={{
              width: "100%",
              position: "absolute",
              top: "47px",
              // left: "-10px",
              // color: redGreenColorPicker(type > 0 ? 1 : -1, lightMode),
            }}
            className="Textcapitalize d-flex justify-content-start align-items-center fs-s-14 mb-25 fw-500 mb-0"
          >
            {"Today's Performance"}
          </p>
        )}
      </div>
    </div>
  );
}

export default PortFolioCard;
