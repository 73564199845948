import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authStore } from '../redux/reducers/authSlice';

function AdminRouterAuth({children}) {
    const UserAuth = useSelector(authStore);
    const navigate=useNavigate()
    console.log(UserAuth?.userData?.user?.accountType === 'admin');
    if (UserAuth?.userData?.user?.accountType === undefined||UserAuth?.userData?.user?.accountType !== 'admin' ) {
      navigate('/login')
    }
    return children;
}

export default AdminRouterAuth