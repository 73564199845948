import React, { useEffect, useState } from 'react'
import { getTrendingStocks } from '../api/fetchClient';
import WatchListCard from '../pages/watchlist/WatchListCard';

function TrendingStocks({lightMode}) {
  const [TrendingStocks, setTrendingStocks] = useState()
useEffect(() => {
  getTrendingStocks().then((res)=>{
    setTrendingStocks(res.data?.volume_movers)
  })
}, [])


  return (
    <div className="d-flex flex-col mt-30 ">
          <h4 className="fs-s-16 fw-700 mb-20">Trending Stocks</h4>
          <div className="d-flex flex-wrap justify-content-between w-100 mb-10 Trending-Stocks-watchlist">
            {TrendingStocks?.map((items,i) => (
              <WatchListCard lightMode={lightMode} key={i} symbol={items?.symbol} items={items?.comp_name}/>
            ))}
          </div>
        </div>
  )
}

export default TrendingStocks