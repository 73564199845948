import React, { useEffect, useMemo, useState } from "react";
import {
  getAffiliateDashdata,
  getDashboardGraph,
} from "../../../../../../api/fetchClient";
import BarChart from "../../../../../../components/Charts/BarChart";
import { getGradient } from "../../../../../../components/Charts/getGradients";
import TimeLineButtons from "../../../../../../components/TimeLineButtons";

function AffiliateDeshboardBarChar({ lightMode }) {
  const [graphData, setgraphData] = useState();
  const [value, setvalue] = useState("monthly");

  useEffect(() => {
  getDashboardGraph({value}).then((res) => {
      setgraphData(res?.data);
    });
  }, [value]);
 

  const timeLineData = [
    { label: "7D", value: "daily" },
    { label: "1M", value: "monthly" },
    // { label: "1Y", value: "Yearly" },
  ];
  const dateGraph = graphData?.map(({ created_at }) => created_at);
  console.log(graphData,"graphData1");
  console.log(dateGraph,"graphData2");
  const dataGraph = graphData?.map(({ earning }) => earning);
  const gradientColors = [
    [ 
      { xValue: 0, color: "#50EAE6" },
      { xValue: 1, color: "#02ABD0" },
    ],

    [
      { xValue: 0, color: "#438FFF" },
      { xValue: 1, color: "#9A60FA" },
    ],
    [
      { xValue: 0, color: "#438FFF" },
      { xValue: 0.25, color: "#9A60FA" },
    ],
    [
      { xValue: 0, color: "#E4E846" },
      { xValue: 0.25, color: "#ACE991" },
    ],
    [
      { xValue: 0, color: "#FF9177" },
      { xValue: 0.25, color: "#FC73BD" },
    ],
  ];

  const data = {
    labels: dateGraph,
    datasets: [
      {
        pointStyle: "rectRot",
        label: "Price",
        barPercentage: 0.9,
        categoryPercentage: 1,
        barThickness: 25,
        type: "bar",
        // backgroundColor: backgroundColors[i],
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[1]);
        },
        // backgroundColor:"red",
        borderWidth: 0,
        fill: true,
        data: dataGraph,
      },
    ],
  };

  const options1 = {
    //Customize chart options

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: true,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 2,
          borderDash: [7, 10],
          // borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
      x: {
        ticks: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          font: {
            size: 14,
          },
          padding: 5,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 0.5,
          borderDash: [5, 15],
          borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,

        position: "bottom",
        // labels: {
        //   color: lightMode ? "#3B3F4F" : "#ffff",
        //   fontSize: 14,
        //   usePointStyle: true,
        //   boxWidth: 5,
        //   padding: 30,
        // },
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "center",
        // backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          
        //   labelTextColor: (context) => {
        //     return backgroundColors1[context.datasetIndex];
        //   },
        },
      },
    },
  };
  return (
    <div style={{ height: "420px" }} className="">
      <div className="d-flex align-items-center my-15 justify-content-between ">
      <p  className="fs-s-20  mb-0 fw-700 ">Overall Earnings</p>
<div style={{height:"20px"}}>
        <TimeLineButtons
          data={timeLineData}
          setValue={setvalue}
          lightMode={lightMode}
          value={value}
        />
        </div>
      </div>
      <BarChart
        type="bar"
        id="balanceSheetGraph"
        data={data}
        options={options1}
      />
    </div>
  );
}

export default AffiliateDeshboardBarChar;
