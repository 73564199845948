import React, { useState } from "react";
import LeftSideBar from "../stock details/LeftSideBar";
import CustomTable from "../../components/CustomTable";
import svgSheet from "../../assets/svg/svgSheet";
import KeyMetricsCompareStocks from "./StockCompareSubComponents/KeyMetricsCompareStocks";
import ReturnOfComapreStocks from "./StockCompareSubComponents/ReturnOfComapreStocks";
import GraphsOfComareStocks from "./StockCompareSubComponents/GraphsOfComareStocks";
import QuarterlyResultsCompareStocks from "./StockCompareSubComponents/QuarterlyResultsCompareStocks";
import ProfitAndLossCompareStocks from "./StockCompareSubComponents/ProfitAndLossCompareStocks";
import CompareBalanceSheet from "./StockCompareSubComponents/CompareBalanceSheet";
import CompareCashFlow from "./StockCompareSubComponents/CompareCashFlow";
import CompareShareholding from "./CompareShareholding";
import CompareFactSheet from "./StockCompareSubComponents/CompareFactSheet";
import PremiumBlurLayout from "../../components/PremiumBlurLayout";
import GraphBasic from '../../assets/images/Compare Stocks/GraphBasic.png'
import { plans } from "../../utilityFn/planData";

function MainStockComparePage({ lightMode, Inputdatais,EmptylessFullName, dropdowndata ,navBarData,stockcomapredark,plan,TreandingStocks}) {
  const symbolsandnames = [];
  const [CompanyType, setCompanyType] = useState("consolidated");

  const removedEmptyData = Object.values(Inputdatais).filter((elements) => {
    return elements !== "";
  });
  const removedEmptyData1 = Object.values(dropdowndata).filter((elements) => {
    return elements !== "";
  });
  const validatedRemoved  = removedEmptyData.slice(0,plans[plan]?.stockCompare)
  const validatedFullname = EmptylessFullName.slice(0,)
  

  validatedRemoved?.map((element, i) => {
    symbolsandnames.push({
      symbole: element,
      name:validatedFullname[i]  ,
    });
  });


  const data = [];
  {
    [...new Array(7)].map((items) =>
      data.push({
        Particulars: "Market Cap",
        Kotak: "101",
        HDFCBank: "121",
        Axis: "121",
        ICICIBank: "233",
        SBI: "12",
      })
    );
  }

  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Particulars</p>,
      dataIndex: "PARTICULARS",
      fixed: "left",
      width: "30px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
  ];

  symbolsandnames.map((element) => {
    columns.push({
      title: <p className="fs-s-16 fw-600 mb-0">{element?.name}</p>,
      dataIndex: element.symbole,
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    });
  });

  const switchCompanyType = (checked) => {
    checked ? setCompanyType("consolidated") : setCompanyType("standalone");
  };

  return (
    <div className="w-100 d-flex flex-col">
      <div className="d-flex">
        <LeftSideBar
          lightMode={lightMode}
          urlParams={{ companyType: CompanyType }}
          switchCompanyType={switchCompanyType}
          linkData={navBarData}
        />
         <div className="ml-30-0 w-78-100 mt-10">
          <div id="Key Metrics Comparison">
            <KeyMetricsCompareStocks
              lightMode={lightMode}
              removedEmptyData={removedEmptyData}
              columns={columns}
              symbolsandnames={symbolsandnames}
              CompanyType={CompanyType}
              EmptylessFullName={EmptylessFullName}
            />
          </div>
          <div id="Financial Ratios" className="w-100 mt-10">
           
            <GraphsOfComareStocks
              lightMode={lightMode}
              removedEmptyData={removedEmptyData}
              dropdowndata={dropdowndata}
              CompanyType={CompanyType}
            />
          </div>

          <ReturnOfComapreStocks
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />
          <QuarterlyResultsCompareStocks
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />
          <ProfitAndLossCompareStocks
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />
          <CompareBalanceSheet
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />
          <CompareCashFlow
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />
          <CompareShareholding
            lightMode={lightMode}
            removedEmptyData={removedEmptyData}
            symbolsandnames={symbolsandnames}
            columns={columns}
            CompanyType={CompanyType}
          />

        <CompareFactSheet 
        
        lightMode={lightMode}
        removedEmptyData={removedEmptyData}
        symbolsandnames={symbolsandnames}
        columns={columns}
        CompanyType={CompanyType}/>
        </div>
      </div>
    </div>
  );
}

export default MainStockComparePage;
