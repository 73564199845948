import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkFingradAccess } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import FAQSection from "../home/components/FAQSection";
import StepCard from "./StepCard";

function JoinFingradIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const { userData } = useSelector(authStore);
  const [fingradAccessStatus, setfingradAccessStatus] = useState({
    url: false,
  });
  const getFinCourseAccess = async () => {
    await checkFingradAccess(userData?.user?.email).then((resp) => {
      setfingradAccessStatus(resp.data);
    });
  };
  useEffect(() => {
    getFinCourseAccess();
  }, []);
  console.log(fingradAccessStatus);
  const cardContent = [
    {
      icon: svgSheet.stepOneIcon,
      head: "Step 1",
      content: (
        <p>Register on fingrad with same E-mail id which used in portal</p>
      ),
    },
    {
      icon: svgSheet.stepTwoIcon,
      head: "Step 2",
      content: (
        <p className="fs-s-14">
          In fingrad, make the purchse with zero payment to enable your free
          subscription
        </p>
      ),
    },
    {
      icon: svgSheet.stepThreeIcon,
      head: "Step 3",
      content: (
        <p className="fs-s-14">
          Start learning, by enrolling into any of our courses & webinars
        </p>
      ),
    },
  ];
  const faqData = [
    {
      head: `How many courses and webinars can I access?`,
      content: (
        <p>
          After getting the premium plan of FinGrad free with the Whales plan of
          Trade Brains Portal, you'll get access to +60 stock market courses and
          webinars, including courses on a few other financial concepts like
          mutual funds, insurances etc.
        </p>
      ),
    },
    {
      head: `How long can I access FinGrad?`,
      content: (
        <p>
          You will get 3 months of complete access to the FinGrad platform free
          with the Whales Plan.
        </p>
      ),
    },
    {
      head: `Will I be able to learn investing after taking FinGrad subscription?`,
      content: (
        <p>
          FinGrad offers over 60 courses and webinars created by Market experts to teach you different stock market and financial concepts. You can learn all the investing and trading-related concepts at FinGrad.
        </p>
      ),
    },
    {
      head: `How can I contact you in case of issues?`,
      content: (
        <p>
         You can contact us at <a style={{color:'#1890ff'}} href={'mailto:portal@tradebrains.in'}>portal@tradebrains.in</a>  in case you're facing any issues while accessing the course and webinars at FinGrad.
        </p>
      ),
    },
  ];

  return (
    <section className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <section className="flex flex-col align-items-center">
          <h1 className="fs-40-32 fw-700">Learn on Fingrad</h1>
          <h3 className="fs-20-18 fw-600">Get your 60+ courses & webinars</h3>
          <p className="w-100-50 text-center">
            Interactive online learning exclusively for traders.Self-paced
            Learning, technical explanation from experts to outgrow your trading
            knowledge.
          </p>
          <a
            className="z-10"
            href={
              fingradAccessStatus.url
                ? fingradAccessStatus.url
                : "https://joinfingrad.com/"
            }
            target="_blank"
          >
            <button
              style={{ width: "200px", height: "50px" }}
              className="btn-bg-primary fw-600 text-white fs-16-14 br-5"
            >
              Go to Fingrad
            </button>
          </a>
        </section>
        <section className="mt-70">
          <h1 className="fs-40-32 fw-700 text-center mb-30">
            How to access Fingrad?
          </h1>
          <div className="d-flex flex-col-row gap-4r justify-content-between">
            {cardContent?.map((item, i) => (
              <StepCard data={item} key={i} lightMode={lightMode} />
            ))}
          </div>
        </section>
        <section className=" flex flex-col  align-items-center mt-70">
          <h3 className="fs-40-32 fw-700 mb-20 text-md-center ">
            Frequently asked Questions
          </h3>
          <div className="w-100-75">
            <FAQSection data={faqData} lightMode={lightMode} />
          </div>
        </section>
      </div>
    </section>
  );
}

export default JoinFingradIndex;
