import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getGraphData, getStockChange } from "../../api/fetchClient";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import GraphIndexdetails from "./GraphIndexdetails";

function LeftIndexMoves({ CurrentIndex,lightMode }) {
  const [Stockmaininvest, setStockmaininvest] = useState([]);
  
  const [indexGraphData, setindexGraphData] = useState({});
  const mainStock = ["NIFTY", "BANKNIFTY", "NIFTYPHARMA", "SENSEX"];

  useEffect(() => {
    const getdata = async () => {
      let datastock = [];
      for (let i = 0; i < 4; i++) {
        const responsedata = await getStockChange(mainStock[i]).then(
          async (res) => {
            datastock = [...datastock, { [mainStock[i]]: res?.data[0]   }];
            setStockmaininvest(datastock);
          }
        );
      }
      let graphObj = {};
      for (let index = 0; index < mainStock.length; index++) {
        const response = await getGraphData(mainStock[index],1)
          .then((resp) => {
            graphObj = { ...graphObj, [mainStock[index]]: resp.data };
          })
          .catch((err) => console.log(err));
      }
      setindexGraphData(graphObj);
    };
    getdata();
  }, [getStockChange]);
  const navigate = useNavigate();
  return (
    <div>
      {Stockmaininvest &&
        Stockmaininvest?.map(
          (items, i) =>
            Object.keys(items)[0] !== CurrentIndex && (
              <div key={i}
                className={`brokers-sub-div pointer  d-flex flex-col ${lightMode ? "bb1px-light-mode" :"bb1px-dark-mode" }`}
                onClick={() => navigate(`/Index/${Object.keys(items)[0]}`)}
              >
                <p className="fs-s-16 fw-500">{Object.keys(items)[0]}</p>
                <div>
                  <GraphIndexdetails lightMode={lightMode} graphData={indexGraphData[mainStock[i]]} />
                </div>
                <div className="d-flex align-items-center">
                  <div className="d-flex  mr-10">
                    <div className="fs-s-16 ff-lato"
                      style={{
                        color: redGreenColorPicker(
                          Object.values(items)[0]["percent"],lightMode
                        ),
                      }}
                    >
                      {Object.values(items)[0]["percent"] > 0 ? <CaretUpOutlined /> : <CaretDownOutlined  /> }
                     
                     
                    </div>
                    <p className="fs-s-16 ml-5">
                      {numberWithCommas(Object.values(items)[0]["close"])}
                    </p>
                  </div>
                  <p
                    style={{
                      color: redGreenColorPicker(
                        Object.values(items)[0]["percent"],lightMode
                      ),
                    }}
                    className="fs-s-12 ff-lato"
                  >
                    {(Object.values(items)[0]["percent"]) > 0 && "+"}{(Object.values(items)[0]["percent"])?.toFixed(2)}% {" "} (
                      {(Object.values(items)[0]["percent"]) > 0 && "+"}{(Object.values(items)[0]["change"])?.toFixed(2)})
                  </p>
                </div>
              </div>
            )
        )}
    </div>
  );
}

export default LeftIndexMoves;
