import { MoreOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

import React, { useState } from 'react'

function  WatchListList({key,items,showModal,setWatchList,showModal1,toTriggerPopup,showModal3,WatchList,lightMode,watchListStore}) {
    const [visible, setVisible] = useState(toTriggerPopup);
    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
      };
     
  return (
    <div key={key} onClick={() => setWatchList(items)} className={` ${lightMode ?"bb1px-light-mode":"bb1px-dark-mode" }    pointer d-flex  justify-content-between p-10 align-items-center justify-content-center ${WatchList === items ? (lightMode ? "desktop-active" :"desktop-active-dark") : (lightMode ? "bg-gray":"bg-dark-gray")}`}>
    <p className='mb-0' > {items}</p>
    <Popover
     key={key}
     color={lightMode ? "white" :"#3B3F4F"}
      className="nameis"
      placement="right"
      content={
        <div className="">
          <div className={`${lightMode ? "text-dark-gray" :"text-white"}`}>
            <div className="d-flex  align-items-center mt-5 ">
              <p onClick={()=>{showModal(); setVisible(false)}} className="ml-5 fs-s-12 fw-600 mb-3 pointer">
                Rename
              </p>
            </div>
            <div className="d-flex  align-items-center mt-5">
              <p onClick={()=>{showModal1(); setVisible(false)}} className="ml-5 fs-s-12 fw-600 mb-3  pointer">
                Clear watchlist
              </p>
            </div>
            {Object.keys(watchListStore?.watchListData)?.length !== 1 && 

            <div className="d-flex align-items-center mt-5">
              <p onClick={()=>{showModal3(); setVisible(false)}} className="ml-5 fs-s-12  fw-600 mb-3 pointer">
                Delete
              </p>
            </div>
      }
          </div>
        </div>
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <p className='mb-0'>
        <MoreOutlined />
      </p>
    </Popover>
  </div>
  )
}

export default WatchListList