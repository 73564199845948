import React, { useEffect, useState } from "react";
import StockEndpoints from "../../../api/endPoints";
import { getStockRelatedNews } from "../../../api/fetchClient";
import GetNewscard from "./GetNewsCard";
import { Skeleton } from "antd";
function NewsTabStockDetails({ symbol, lightMode }) {
  const [newsdata, setnewsdata] = useState([]);
  useEffect(() => {
    const getData = async () => {
      await getStockRelatedNews(StockEndpoints.stockRelatednews(symbol))
        .then(async (resp) => {
          if (resp.data.length>0) {
            setnewsdata(resp.data);
          } else {
            await getStockRelatedNews(
              StockEndpoints.stockRelatednews("")
            ).then((resp) => {
              setnewsdata(resp.data);
            });
          }
        })
        .catch((err) => console.log(err));
    };
    getData();
  }, [symbol]);
  if (newsdata.length === 0) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }
  return (
    <div className="w-100">
      {newsdata?.slice(0, 10)?.map((news, i) => (
        <GetNewscard postId={news.id} key={i} lightMode={lightMode} />
      ))}
    </div>
  );
}

export default NewsTabStockDetails;
