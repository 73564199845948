import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function GrahamNumberCalc() {
  const [GetRsult, setGetRsult] = useState([]);
    const { lightMode } = useSelector(getThemeMode);
    const [eps, setEps] = useState();
    const [egr, setEgr] = useState();
    const [gvtbond, setGvtbond] = useState();
    const [crnt, setCrnt] = useState();
    const [result, setResult] = useState(0);
    const [err, setErr] = useState(false);
  

    useEffect(() => {
     
        const Value = (GetRsult["eps"] * (8.5 + 2 * GetRsult["egr"]) * 4.4) / GetRsult["gvtbond"];
        setResult(Value);
      
    }, [GetRsult])
    
    const resetValues = () =>{
      setGetRsult([])
    }

    const ResultHeder =[{
      "Intrinsic Value ":Math.floor(
      result !== Infinity && result 
          ? Number(result).toFixed(2)
          : 0
      ).toLocaleString("en-US")
    }]

    const bottomData = [{
      header:"Graham Formula:",
      body:"The Graham formula proposes to calculate a company's intrinsic value V* as: V* = (E PSX (8.5+2g) x 4.4) /Y V* = the value expected from the growth formulas over the next 7 to 10 years EPS= the company's last 12-month earnings per share 8.5 P/E base for a no growth company g= reasonably expected 7 to 10 year growth rate 4.4 the average yield of AAA corporate bonds in 1962 (Graham did not specify the duration of the bonds, though it has been asserted that he used 20 year AAA bonds as his benchmark for this variable[4]) the current yield on AAA corporate bonds"
    }]

    const options = {
        scales: {
          y: {
            beginAtZero: true
          }
          
        },
        plugins:{
            legend:{
                position:"bottom",
                labels:{
    
                }
            },
        },
    
        maintainAspectRatio: false
      }
      var data = {
        labels: ["Chocolate", "Vanilla", "Strawberry","Strawberry1"],
        datasets: [
            {
                label: "Blue",
                backgroundColor: "#6DB8FD",
                data: [3,7,4,5],
                
      
            },
            {
                label: "Red",
                backgroundColor: "#1774FF",
                data: [4,3,5,5],
                
      
            },
         
        ]
      };
      const formlist = [
        {name:"crnt",value:"Current Share Price (in Rupees)"},
        {name:"eps",value:"Eps (last 4 Quarters)(in Cr)"},
        {name:"egr",value:"Expected Growth Rate (Next 5 years)(%)"},
        {name:"gvtbond",value:"10 Yrs Govt.Bond Yield (%)"},
      ];
      const navigate = useNavigate()
  return (
    <div>
      <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
        <div className="my-30">
        <p className="pointer " onClick={()=>navigate(-1)}>Calculators {">"} Graham Number Valuation</p>
          <p className="mb-0 fs-30-20 fw-700">Graham Number Valuation</p>
          <p className="w-60-80">A graham calculator is a good tool for finding a rough estimate of the intrinsic value.
</p>
        </div>
        <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
          <div>
            <CalcInputFeilds resetValues={resetValues} setGetRsult={setGetRsult} formlist={formlist} lightMode={lightMode} />
          </div>
          <div>
            <ResultContainerCalc ResultHeder={ResultHeder}  lightMode={lightMode} />
          </div>
        </div>
        <div>
          <BottomDataSection bottomData={bottomData} />
        </div>
      </div>
    </div>
  );
}

export default GrahamNumberCalc;
