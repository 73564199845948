import React from "react";
import { Switch } from "antd";
import { Link } from "react-scroll";
import { useSelector } from "react-redux";
import { authStore } from "../../redux/reducers/authSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";

function LeftSideBar({
  urlParams,
  switchCompanyType,
  disableStatus,
  lightMode,
  linkData,
}) {
  const UserAuth = useSelector(authStore);

  return (
    <div
      className={`my-body p-fixed card-shadow detail-left-card ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
      style={{
        width: "20%",
        height: "min-content",
        top:`${145+bannerOffsetCalc(UserAuth)}px`,
        // top: "186px",
      }}
    >
      <div className="bg-dark bl-dark h-50px flex align-items-center pl-10px">
        <Switch
          defaultChecked
          checked={urlParams?.companyType === "consolidated" ? true : false}
          disabled={disableStatus ? true : false}
          onChange={(e) => switchCompanyType(e)}
        />
        <span className="text-white">
          {urlParams?.companyType?.replace(
            /^./,
            urlParams?.companyType[0]?.toUpperCase()
          )}
        </span>
      </div>
      {linkData?.map((data, i) => (
        <Link
          key={i}
          activeClass={lightMode ? "desktop-active" : "desktop-active-dark"}
          to={data.id}
          spy={true}
          smooth={true}
          offset={data.offset}
          duration={500}
          className={`p-10 flex align-items-center ${
            lightMode ? "bb1px-light-mode hover-light-blue" : "bb1px-dark-mode bg-dark-hover"
          }`}
        >
          {data.icon}
          <span className=" fw-500">{data.label}</span>
        </Link>
      ))}
    </div>
  );
}

export default LeftSideBar;
