import React from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import HeatMapcard from "./HeatMapcard";
import portalFavICon from "../../../assets/images/logo/logoWhite.png";

function HeatMap({ mapdata, watermarkstats = false,index }) {
  const lightMode = useSelector(getThemeMode);
  return (
    <div
      className={`heatmap-grid-wrap scroll`}
      id="my-heatmap"
      style={{ minWidth: "850px", position: "relative" }}
    >
      {watermarkstats && (
        <img 
          style={{ position: "absolute", top: "50%", left: "50%",transform:'translate(-50%,-50%)', zIndex: 20,opacity:0.3}}
          src={portalFavICon}
        />
      )}
      {mapdata?.map((item, i) => (
        <HeatMapcard key={i} data={item} index={index} watermarkstats={watermarkstats}/>
      ))}
    </div>
  );
}

export default HeatMap;
