import axios from "axios";
const instance = axios.create({
    baseURL: process.env.REACT_APP_NEWS_BASE_URL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
  });


  export const newspost=(url, data)=> {
    return instance.post(url, data)
 }
  export const newsGet=(url)=> {
    return instance.get(url)
  }
 export const  newsdel=(url, data)=> {
    return instance.delete(url)
  }
 export const newsput=(url, data) =>{
    return instance.put(url, data)
  }
 export const newspatch=(url, data)=> {
    return instance.patch(url, data)

  }