import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import CorporateActionIndex from "./CorporateActionIndex";
import { useNavigate } from "react-router-dom";
import MobileSlider from "../../components/MobileSlider";
import svgSheet from "../../assets/svg/svgSheet";
import RecenSearchSlice from "../../redux/reducers/RecenSearchSlice";
import RecentSearch from "../watchlist/RecentSearch";
import TreandingStockSC from "../CompareStocks/TreandingStockSC";
import TrendingStocks from "../../components/TrendingStocks";
function CorporateActions() {
  const { lightMode } = useSelector(getThemeMode);
  const [CurremtValue, setCurremtValue] = useState("all");
  const [Value, setValue] = useState("Dividends");
  const leftsidebar = [
    { label: "Dividends", value: "all" ,icon:svgSheet.dividend},
    { label: "Board Meetings", value: "bse" ,icon:svgSheet.Meating},
    { label: "Bonus", value: "nse" ,icon:svgSheet.bonus},
    { label: "Splits", value: "nse1",icon:svgSheet.splits},
    { label: "Rights Issue", value: "nse2",icon:svgSheet.Rightsissues },
    { label: "AGM/EGM", value: "nse3" ,icon:svgSheet.Agmsegm},
  ];
  const navigate = useNavigate();

  return (
    <div >
      <div className="my-body">
        <div
          className={` ${
            lightMode ? "" : "bg-dark-black"
          } d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative`}
        >
          <div className="d-flex align-items-center mb-30">
          <div className="w-m-28 mr-10">{svgSheet.corporateActionMain}</div>
          <p className="fw-700 fs-36-30-20 mb-0">Corporate Actions</p>
          </div>

          <div className="w-100 d-flex flex-wrap justify-content-between    ">
            <div style={{ width: "20%" }} className="only-PC-view">
              <div
                className={`${lightMode ? "" : "bg-dark-gray"}  `}
              >
                {leftsidebar?.map((items) => (
                  <div
                    onClick={() => {
                      setCurremtValue(items?.value);
                      setValue(items?.label);
                    }}
                    style={{ height: "45px" }}
                    className={`px-20 flex align-items-center pointer ${
                      lightMode
                        ? "bb1px-light-mode hover-light-blue bt-first-child-light  "
                        : "bb1px-dark-mode bg-dark-hover bg-dark  bt-first-child-dark "
                    }
              ${
                items.value === CurremtValue
                  ? `${lightMode ? "desktop-active" : "desktop-active-dark"}`
                  : `${lightMode ? "desktop-active" : " bg-dark-gray"}`
              }
              
              `}
                  >
                    {items?.icon}
                    {items?.label}
                  </div>
                ))}
              </div>
              <div className="my-30"> 
              <RecentSearch />
              </div>
            </div>
            <div className="w-75-100">
              <CorporateActionIndex lightMode={lightMode} Value={Value} />
            </div>
          </div>
        {/* <TrendingStocks lightMode={lightMode} /> */}

        </div>
        
      </div>
      

        <MobileSlider
          leftsidebar={leftsidebar}
          setValue={setValue}
          lightMode={lightMode}
          Value={Value}
        />
    </div>
  );
}

export default CorporateActions;
