import React from "react";
import Link from "react-scroll/modules/components/Link";

function MobileBottomBar({ lightMode,navData,offset ,watchlists}) {
  
  return (
    <div
      style={{ height: "65px", zIndex: 500, position: "sticky", bottom: "0" }}
      className={` ${
        lightMode ? "bg-gray bt-light-mode" : "bg-dark-gray bt-dark-mode"
      } table-shadow   details-top-card w-100 br-5   `}
    >
          <div className="overflow-x h-100">
          {
            navData?.map((nav,i)=>(
              <Link key={i}
            style={{ minWidth: "120px",width:`${100/navData.length}%` }}
            activeClass={lightMode ? "mob-active" : "desktop-active-dark"}
            to={nav.id}
            spy={true}
            smooth={true}
            offset={nav.offset}
            duration={500}
            className="pt-10px  d-flex flex-col  align-items-center  "
          >
            {nav.icon}
            <span style={{minHeight:'30px'}} className="fs-s-10 fw-500 text-gray text-align-center d-flex align-items-center">{nav.label}</span>
          </Link>
            ))
          }
        </div>
       
  
    </div>
  );
}

export default MobileBottomBar;
