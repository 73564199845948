import React from "react";
import { Link } from "react-router-dom";

function KeyInfocard({ lightMode, data }) {
  return (
    <div className="w-100-40">
      <h3 className="fs-s-18 fw-600 mb-20">Key Info</h3>
      <div
        style={{ minHeight: "292px" }}
        className={`table-shadow p-5 ${lightMode ? "bg-gray" : "bg-dark-gray"}`}
      >
        <div className={`d-flex p-10 `}>
          <p className="fs-s-16  w-50 fw-500">Incorporation Year : </p>
          <p className="w-50">{data?.incop_year}</p>
        </div>
        <div className={`d-flex px-10px `}>
          <p className="fs-s-16 w-50 fw-500">Address:</p>
          <p className="w-50">{data?.address}</p>
        </div>
        <div className={`d-flex px-10px `}>
          <p className="fs-s-16 w-50 fw-500">Headquarter:</p>
          <p className="w-50">{data?.city}</p>
        </div>
        <div className={`d-flex px-10px `}>
          <p className="fs-s-16 w-50 fw-500">Website:</p>
          <p onClick={()=>window.open(`https://${data?.website}`,'_blank')} style={{color:'#3ec1e9d4',lineBreak:"anywhere"}} className="w-50 pointer">{data?.website}</p>
        </div>
        <div className={`d-flex px-10px `}>
          <p className="fs-s-16 w-50 fw-500">Previous Name:</p>
          <p className="w-50">{data?.old_name ?? "NA"}</p>
        </div>
      </div>
    </div>
  );
}

export default KeyInfocard;
