export const KeyMetrics = [
  {
    key: "fv",
    name: "Face Value",
    unit: "₹",
    tooltipText:
      <p className="mb-0">Face value is the initial cost of the stock, as shown in the stock certificate.</p>,
  },
  {
    key: "mcap",
    name: "Market Cap (Cr)",
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Current Market Price per share * Total Number of Outstanding Shares
        </p>
        <p className="mb-0">
          Market capitalisation is the total rupee value of a company's
          outstanding shares of stock.
        </p>
      </>
    ),
  },
  {
    key: "ttm_eps",
    name: "EPS (TTM)",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        [ (Net profit) - (Dividends paid) ] / (Outstanding shares of stock).
        </p>
        <p className="mb-0">
        EPS is the earnings per share of a company. It is calculated by dividing its outstanding shares by profits.
        </p>
      </>
    )
  },
  {
    key: "price_bv",
    name: "Price To Book Value ",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market price per share / Book Value per share
        </p>
        <p className="mb-0">
        P/B Value is the ratio of the current market value of the company shares to the book value of equity. It is used to estimate if the company is over or undervalued.
        </p>
      </>
    )
  },
  {
    key: "ttm_pe",
    name: "PE Ratio (TTM)",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Current market price / Earnings per share
        </p>
        <p className="mb-0">
        PE Ratio is the ratio used to measure current share price to its earnings per share.
        </p>
      </>
    )
  },
  {
    key: "yield",
    name: "Dividend Yield ",
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Annual Dividend per share / Current share price
        </p>
        <p className="mb-0">
        It shows how much a company pays out in dividends each year relative to its current market price.
        </p>
      </>
    )
  },
  {
    key: "roe",
    name: "ROE",
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        ROE = Net Income / Shareholders’ Equity
        </p>
        <p className="mb-0">
        ROE is the return on your money invested into the company, which is calculated per share.
        </p>
      </>
    )
  },
  {
    key: "roce",
    name: "ROCE",
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        EBIT/ Capital Employed
        </p>
        <p className="mb-0">
        The ratio measures the comapany's profitability in terms of its capital which includes equity and debt.
        </p>
      </>
    )
  },
  {
    key: "ev",
    name: "Enterprise Value (Cr)",
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market Capitalization + Market Value of Debt – Cash and Equivalents.
        </p>
        <p className="mb-0">
        Enterprise Value is the measure of a company's total value, including equity and short term and long term debt.
        </p>
      </>
    )
  },
  {
    key: "no_shs_subscribed",
    name: "Outstanding Shares",
    tooltipText:'It refers to all shares held by all its shareholders, including restricted shares and shares held by institutional investors.'
  },
];

export const ProfitAndLoss = [
  {
    key: "net_sales",
    name: "Net Sales",
    highlight: true,
    unit: "₹",
    tooltipText:'This refers to the total money made by a company through the sale of goods and services for a given period. Sometimes, net sales and revenue are used interchangeably.'
  },
  {
    key: "operating_expenditure",
    name: "Total Expenditure",
    unit: "₹",
    tooltipText:'Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.'
  },
  {
    key: "raw_materials",
    name: "Raw Materials",
    isNested: true,
    unit: "₹",
    tooltipText:'Materials used in primary production of goods. '
  },
  {
    key: "employees",
    name: "Employees",
    isNested: true,
    unit: "₹",
    tooltipText:'Salaries spent on workforce.'
  },
  {
    key: "selling_admin_expenses",
    name: "Selling, Administration Expenses",
    isNested: true,
    unit: "₹",
    tooltipText:'All everyday operation expenses of running a business excluding production and delivery.'
  },
  {
    key: "operating_profit",
    name: "Operating Profits",
    highlight: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Operating Profit = Revenue - Operating Costs - Cost of Goods Sold (COGS) - Other Day-to-Day Expenses
        </p>
        <p className="mb-0">
        Operating profit is the number arrived at after considering incomes and expenses from a company’s core business functions.
        </p>
      </>
    )
  },
  {
    key: "other_income",
    name: "Other Income",
    unit: "₹",
    tooltipText:'The income that a company generates from sources other than its core business functions. Ex: interest received on deposits, profits made on the sale of assets.'
  },
  {
    key: "depreciation",
    name: "Depreciation",
    unit: "₹",
    tooltipText:'The value of assets decreases over time due to wear and tear, obsolescence, usage rights, and perishability. This reduction in value is called depreciation. It is used to quantify and allocate this loss in terms of cost over the assets useful life.'
  },
  {
    key: "interest",
    name: "Interest",
    unit: "₹",
    tooltipText:'Interest paid on borrowings to various creditors.'
  },
  {
    key: "profit_before_tax",
    name: "Profit Before Tax",
    highlight: true,
    unit: "₹",
    tooltipText:'Profits made by the company before they have to pay corporate income tax.'
  },
  {
    key: "tax",
    name: "Tax",
    unit: "₹",
    tooltipText:'Taxes are compulsory contributions paid to the government.'
  },
  {
    key: "net_profit",
    name: "Profit After Tax",
    highlight: true,
    unit: "₹",
    tooltipText:'The actual profit after working expenses, interest and taxes.'
  },
  { key: "adj_eps",
   name: "Adj. EPS (Rs)",
    unit: "₹",
  tooltipText:'Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.'
  },
  {
    key: "net_profit_margin",
    name: "Net Profit Margin",
    unit: "%",
    tooltipText:'The profit margin shows what percentage of sales are profits after all the expenses have been deducted.'
  },
];

export const balanceSheet = [
  {
    key: "total_assets",
    name: "Assets",
    isHeaderWithData: true,
    unit: "₹",
    tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
  },
  {
    key: "current_assets",
    name: "Current Assets +",
    isNested: true,
    highlight: true,
    unit: "₹",
    tooltipText:'Current assets are the assets which get used by the company within one financial year. They do not have future earning capacity. Common examples of current assets are raw materials, stationery, and cash.'
  },
  {
    key: "inventory",
    name: "Inventory",
    isNested: true,
    unit: "₹",
    tooltipText:'Inventory refers to the goods the company deals in. It can be finished as well as in its unfinished form. Inventory is classified under current assets assuming the company will sell those goods in one year. A simple example of inventory: unsold biscuits, flour, and box for packaging.'
  },
  {
    key: "debtors",
    name: "Accounts (Receivable)",
    isNested: true,
    unit: "₹",
    tooltipText:'Account receivables are the monies the company expects to receive in one year. A receivable basically means the amount a customer or debtor owes to the company for purchasing the goods on credit.'
  },
  {
    key: "current_investments",
    name: "Short Term Investments",
    isNested: true,
    unit: "₹",
    tooltipText:'Short-term investments are classified as current assets. These are the investments made by the company with the intention of selling them in 3-12 months. Some examples are government bonds, certificate of deposits, and money market accounts.'
  },
  {
    key: "cash_and_bank_balances",
    name: "Cash & Bank Balances",
    isNested: true,
    unit: "₹",
    tooltipText:'Cash and bank balances are the monies a company can access immediately. This section includes cash in hand, cash at bank, and demand deposits.'
  },
  {
    key: "non_current_assets",
    name: "Non Current Assets +",
    isNested: true,
    highlight: true,
    unit: "₹",
    tooltipText:'Non-current assets are the assets purchased by the company for long-term use. These assets mostly provide future benefits. Some common examples are land, building, plant, and vehicles.'
  },
  {
    key: "net_block",
    name: "Net Block",
    isNested2x: true,
    unit: "₹",
    tooltipText:'All the non-current assets undergo wear and tear over time which is called depreciation. Net block is the value of non-current assets minus depreciation. '
  },
  {
    key: "working_capital",
    name: "Capital Work in Progress",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Capital work in progress is the ongoing development or construction of a non-current asset. It means the asset is not fully functional and requires more investment and time to make it run. Some examples are an under-construction office that will take two years to complete.'
  },
  {
    key: "intangible_assets",
    name: "Intangible assets under dev",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Intangible assets under development are intangible assets which will take more resources and time to get into functional capacity. Two examples are an app being developed by a company and a biotechnology company working on a vaccine.'
  },
  {
    key: "long_term_investments",
    name: "Long Term Investments",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Long-term investments are investments made by a company with the intention of holding them for at least one year. These investments can be bonds, stocks, real estate, etc.'
  },
  {
    key: "total_liabilities",
    name: "Liabilities & Equity",
    isHeaderWithData: true,
    unit: "₹",
    tooltipText:'This section tells the source of funds used by the company. Liabilities refer to outside money such as bank loans and creditors. Equity refers to the funds supplied by the owners of the company and the past profits which have been saved.'
  },
  {
    key: "current_liabilities",
    name: "Current Liabilities +",
    isNested: true,
    highlight: true,
    unit: "₹",
    tooltipText:'Current liabilities are payment obligations a company plans to pay within a year. Two examples are the amount owed to a supplier for purchasing raw material on credit and pending salary payable to the employees.'
  },
  {
    key: "account_payables",
    name: "Account Payables",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Account Payables are the monies the company expects to pay in one year. A payable basically means the amount company owes to a supplier for purchasing the goods on credit.'
  },
  {
    key: "provisions",
    name: "Provisions",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Provisions in accounting represent funds set aside to meet any probable contingency or losses. Some expenses or losses for which provisions are created are bad debts, depreciation, and inventory obsolescence.'
  },
  {
    key: "short_term_loans",
    name: "Short Term Loans",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Short-term loans are current liabilities that a bank has to pay within a year. These loans usually arise when companies have an immediate need of cash.'
  },
  {
    key: "non_current_liabilities",
    name: `Non Current Liabilities +`,
    isNested: true,
    highlight: true,
    unit: "₹",
    tooltipText:'Non-current liabilities are long-term payment obligations on the company which are not expected to be paid within a year. Some examples are long-term debentures, secured bank loan on building, etc. '
  },
  {
    key: "secured_loans",
    name: "Secured Loans",
    isNested2x: true,
    // highlight: true,
    unit: "₹",
    tooltipText:'Secured loans are loans for which a company has submitted collateral to the bank. Let us say a company takes a loan of Rs. 1,000 crores from a bank and submits its office building as collateral. Tomorrow, if the company is not able to pay back the debt, the bank will seize the control of the building and sell it to recover its dues.'
  },
  {
    key: "non_secured_loans",
    name: "Non Secured Loans",
    isNested2x: true,
    // highlight: true,
    unit: "₹",
    tooltipText:'Non-secured loans have no underlying collateral. They are usually given at a higher rate of interest. Non-secured loans are generally provided to strong companies with a sustainable history of paying back debts.'
  },
  {
    key: "shareholders_funds",
    name: "Shareholder's Funds +",
    highlight: true,
    isNested: true,
    unit: "₹",
    tooltipText:'Reserves and promoter capital together make up Shareholders Funds. Reserves are the past profits of the company which it has not distributed. Promoter capital is the amount invested by the shareholders in the company.'
  },
  {
    key: "share_capital",
    name: "Share Capital",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Share capital is the amount invested by the shareholders in the company. It is of two types: equity and preference. The preference share capital has more specifications than equity capital with respect to voting power, profit distribution, liquidation, etc.'
  },
  {
    key: "reserve",
    name: "Reserves",
    isNested2x: true,
    unit: "₹",
    tooltipText:'Reserves are the past profits a company has either reinvested or kept as cash with itself. Reserves are classified into different types as per their use: general, statutory, debenture redemption, etc.'
  },
]; 
export const cashFlowTable = [
 
  {
    key: "cash_from_operation",
    name: "Cash From Operating Activities",
    unit: "₹",
    tooltipText:'Cash flow from operating activities refers to the inflow or outflow of cash from the regular  activities of a company. It includes cash flow from buying and selling its products and services, employees remuneration, administrative and maintainance activities. It is usually the first section of the cash flow statement.'
  },
  {
    key: "cash_from_investment",
    name: "Cash From Investing Activities",
    unit: "₹",
    tooltipText:'Cash flow from investing activities includes the inflow or outflow of cash from buying fixed assets, investments in securities, income from investments (dividends, interest, etc.), and the sale of securities or assets.'
  },
  {
    key: "cash_from_financing",
    name: "Cash From Financial Activities",
    unit: "₹",
    tooltipText:'Cash flows from financial activities give an insight into the companys financial strength and indicates how well the capital structure is managed. It includes cash flows from issuing equity or debt, dividends paid, and repurchase of equity and debt.'
  },
  {
    key: "net_cash_flow",
    name: "Net Cash Flow",
    unit: "₹",
    tooltipText:'Net cash flow is the difference between the total inflows and total outflows during a specific period.'
  },
  {
    key: "free_cash_flow",
    name: "Free Cash Flow",
    unit: "₹",
    tooltipText:'Free cash flow (FCF) is the cash available with the company to repay creditors or pay dividends and interest to investors.'
  }
];
export const quarterlyFinancial = [
  {
    key: "net_sales",
    name: "Net Sales",
    highlight: true,
    unit: "₹",
    tooltipText:'This refers to the total money made by a company through the sale of goods and services for a given period. Sometimes, net sales and revenue are used interchangeably.'
  },
  {
    key: "operating_expenses",
    name: "Operating Expenses",
    unit: "₹",
    tooltipText:'Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.'
  },
  {
    key: "operating_profit",
    name: "Operating Profit",
    highlight: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Operating Profit = Revenue - Operating Costs - Cost of Goods Sold (COGS) - Other Day-to-Day Expenses
        </p>
        <p className="mb-0">
        Operating profit is the number arrived at after considering incomes and expenses from a company’s core business functions.
        </p>
      </>
    )
  },
  {
    key: "other_income",
    name: "Other Income",
    unit: "₹",
    tooltipText:'The income that a company generates from sources other than its core business functions. Ex: interest received on deposits, profits made on the sale of assets.'
  },
  {
    key: "depreciation",
    name: "Depreciation",
    unit: "₹",
    tooltipText:'The value of assets decreases over time due to wear and tear, obsolescence, usage rights, and perishability. This reduction in value is called depreciation. It is used to quantify and allocate this loss in terms of cost over the assets useful life.'
  },
  {
    key: "interest",
    name: "Interest",
    unit: "₹",
    tooltipText:'Interest paid on borrowings to various creditors.'
  },
  {
    key: "profit_before_tax",
    name: "Profit Before Tax",
    highlight: true,
    unit: "₹",
    tooltipText:'Profits made by the company before they have to pay corporate income tax.'
  },
  {
    key: "tax",
    name: "Tax",
    unit: "₹",
    tooltipText:'Taxes are compulsory contributions paid to the government.'
  },
  {
    key: "net_profit",
    name: "Profit After Tax",
    highlight: true,
    unit: "₹",
    tooltipText:'The actual profit after working expenses, interest and taxes.'
  },
  {
    key: "adj_eps",
    name: "Adj EPS (Rs)",
    unit: "₹",
    tooltipText:'Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.'
  },
  {
    key: "net_profit_margin",
    name: "Net Profit Margin",
    unit: "%",
    tooltipText:'The quarterly profit margin shows what percentage of sales are profits after all the expenses have been deducted.'
  },
];

export const shareHoldingtableType = [
  {
    key: "promoters",
    name: "Promoters",
    unit: "%",
    tooltipText:'Promoters refers to founders, director or controlling shareholders of a company. They have control over the affairs of th company, either directly or indirectly. The percentage of equity that they hold in a company is called as promoters shareholding.'
  },
  {
    key: "share_holding_pledge",
    name: "Shareholding Pledge",
    unit: "%",
    tooltipText:'Promoters can avail loans by pledging their shares. However, they retain their ownership. The percentage of shares out of promoters holdings that are pledged are called shareholding pledge.'
  },
  {
    key: "public",
    name: "Public",
    unit: "%",
    tooltipText:'Collection of all individual non-professional investors who have inveted in the security.'
  },
  {
    key: "fii",
    name: "FII",
    unit: "%",
    tooltipText:'A foreign institutional investor (FII) is an investor or investment fund investing in a country outside of the one in which it is registered or headquartered. The shares held by these foreign investors in Indian companies is called FII holding.'
  },
  {
    key: "total_dii",
    name: "Total DII +",
    unit: "%",
  },
  {
    key: "mf",
    name: "MF",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "insurances",
    name: "Insurances",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "fin_inst",
    name: "Fin inst & other DII's",
    isNested2x: true,
    unit: "%",
  },
  {
    key: "others",
    name: "Others",
    unit: "%",
  },
];

export const FiveYearTableData = [
  {
    key: "",
    name: "Profitability Ratios",
    isHeader: true,
  },
  {
    key: "net_margin",
    name: "Net Profit Margin",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Net Profit ⁄ Total revenue x 100
        </p>
        <p className="mb-0">
        The ratio calculates the percentage of profit a company produces from its total revenue after accounting for all its expenses.
        </p>
      </>
    )
  },
  {
    key: "ebitda",
    name: "EBITDA Margin",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (EBIT + Depreciation + Amortization) / Total revenue X 100
        </p>
        <p className="mb-0">
        It is a measure of a company's operating profit as a percentage of its revenue after considering its cash flows.
        </p>
      </>
    )
  },
  {
    key: "roe",
    name: "Return On Equity (ROE)",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        ROE = Net Income / Shareholders’ Equity
        </p>
        <p className="mb-0">
        ROE is the return on your money invested into the company, which is calculated per share.
        </p>
      </>
    )
  },
  {
    key: "roce",
    name: "Return On Cap Employed (ROCE)",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        EBIT/ Capital Employed
        </p>
        <p className="mb-0">
        The ratio measures the comapany's profitability in terms of its capital which includes equity and debt. 
        </p>
      </>
    )
  },
  {
    key: "roa",
    name: "Return On Assets (ROA)",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Net Income / Total Assets
        </p>
        <p className="mb-0">
        It measure how well the company is able to use its assets to generate profits.
        </p>
      </>
    )
  },
  {
    key: "eps",
    name: "Earnings Per Share (EPS)",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Net Income - Preference dividend ) / Total shares out standing
        </p>
        <p className="mb-0">
        It measures how much profit the company makes for each of its shareholders.
        </p>
      </>
    )
  },
  {
    key: "adj_eps",
    name: "Adj. Earnings Per Share",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Net Income - Extraordinary gains / losses) / Total shares out standing
        </p>
        <p className="mb-0">
        Adjusted EPS removes any non-recurring extraordinary gains or losses from the net income and then calculates the EPS.
        </p>
      </>
    )
  },
  {
    key: "",
    name: "Valuation Ratios",
    isHeader: true,

  },
  {
    key: "ev_ebitda",
    name: "EV/EBITDA ",
    isNested: true,
    tooltipText:'It is used a valuation metric to compare the relative value of different businesses in a sector'
  },
  {
    key: "yield",
    name: "Dividend Yield ",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Annual Dividend per share / Current share price
        </p>
        <p className="mb-0">
        It shows how much a company pays out in dividends each year relative to its current market price.
        </p>
      </>
    )
  },
  {
    key: "adj_pe",
    name: "PE Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Current market price / Earnings per share
        </p>
        <p className="mb-0">
        It provides an indication whether a stock at its current market price is expensive or cheap realtive to its earnings
        </p>
      </>
    )
  },
  {
    key: "price_to_book_value",
    name: "Price To Book Value ",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market price per share / Book Value per share
        </p>
        <p className="mb-0">
        P/B Value is the ratio of the current market value of the company shares to the book value of equity. It is used to estimate if the company is over or undervalued.
        </p>
      </>
    )
  },
  {
    key: "price_to_sales_ratio",
    name: "Price/Sales Ratio ",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market price per share / Sales per share
        </p>
        <p className="mb-0">
        It measures how much the investors are willing to pay per rupee of sales for a stock
        </p>
      </>
    )
  },
  {
    key: "mcap_to_sales",
    name: "Market Cap To Sales ",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market capitalization / Operating cashflow
        </p>
        <p className="mb-0">
        It indicates how the market is valuing every rupee of the company's sales.
        </p>
      </>
    )
  },
  {
    key: "price_to_cash_flow",
    name: "Price To Cashflow ",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market capitalization / Total sales or Revenue
        </p>
        <p className="mb-0">
        It measures the value of a stock's price relative to its operating cash flow per share
        </p>
      </>
    )
  },
  {
    key: "",
    name: "Debt and Liquidity Ratios",
    isHeader: true,
  },
  {
    key: "current_ratio",
    name: "Current Ratio ",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Current Assets / Current Liabilities
        </p>
        <p className="mb-0">
        It measures the company's ability to meet their short-term obligations which are to be repaid within a year
        </p>
      </>
    )
  },
  {
    key: "interest_coverage_ratio",
    name: "Interest Coverage Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        EBIT / Interest Expenses
        </p>
        <p className="mb-0">
        It measures the company's ability to pay interest on its outstanding debt
        </p>
      </>
    )
  },
  {
    key: "debt_to_equity",
    name: "Debt To Equity Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Total Liabilities / Total shareholder's equity
        </p>
        <p className="mb-0">
        It measures the degree to which a company is financing its operations with debt rather than its own resources.
        </p>
      </>
    )
  },
  {
    key: "debt_to_mcap",
    name: "Total Debt To Market Cap",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Short term debt + Long term debt) / (Short term debt + Long term debt + shareholder's equity)
        </p>
        <p className="mb-0">
        It measures the degree of the company's leverage (debt) as a percentage of the firm’s total capitalization.
        </p>
      </>
    )
  },
  {
    key: "",
    name: "Efficiency Ratios",
    isHeader: true,

  },
  {
    key: "asset_turnover",
    name: "Asset Turnover Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Total Sales / [(Assets at the start of the year + Assets at the end of the year) / 2]
        </p>
        <p className="mb-0">
        It measures the efficiency with which a company is deploying its assets to produce the revenue
        </p>
      </>
    )
  },
  {
    key: "inventory_turnover",
    name: "Inventory Turnover Ratio",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Cost of goods sold / Average value of Inventory
        </p>
        <p className="mb-0">
        It measures the rate at which the inventory stock is sold, or used, and replaced in a given period
        </p>
      </>
    )
  },
  {
    key: "receivable_days",
    name: "Receivable Days (DSO)",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Accounts Receivable/Net Credit Sales)x Number of days
        </p>
        <p className="mb-0">
        It measures the average time a customer takes to pay back the business for products or services purchased on credit
        </p>
      </>
    )
  },
  {
    key: "inventory_days",
    name: "Inventory Days (DIO)",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Accounts Receivable/Net Credit Sales)x Number of days
        </p>
        <p className="mb-0">
        It measures the average time a customer takes to pay back the business for products or services purchased on credit
        </p>
      </>
    )
  },
  {
    key: "payable_days",
    name: "Payable Days (DPO)",
    isNested: true,
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Average inventory / Cost of goods sold) x Number of days
        </p>
        <p className="mb-0">
        It measures the  average time (in days) that a company takes to pay its bills and invoices to its trade creditors
        </p>
      </>
    )
  },
  {
    key: "cash_conversion_cycle",
    name: "Cash conversion cycle",
    isNested: true,
    unit: "",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        days inventory outstanding + days sales outstanding - days payables outstanding
        </p>
        <p className="mb-0">
        It measures how fast a company can convert cash on hand into even more cash on hand
        </p>
      </>
    )
  },
  {
    key: "",
    name: "Dividend & Other Metrics",
    isHeader: true,
  },
  {
    key: "ev",
    name: "Enterprise Value",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Market Capitalization + Market Value of Debt – Cash and Equivalents.
        </p>
        <p className="mb-0">
        Enterprise Value is the measure of a company's total value, including equity and short term and long term debt.
        </p>
      </>
    )
  },
  {
    key: "dividend_per_share",
    name: "Dividend Per Share",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Total Dividend paid - Special Dividend) / Shares outstanding
        </p>
        <p className="mb-0">
        It is the sum of declared dividends issued by a company for every ordinary share outstanding
        </p>
      </>
    )
  },
  {
    key: "adj_dividend_per_share",
    name: "Adj Dividend Per Share",
    isNested: true,
    unit: "₹",
  },
  {
    key: "dividend_payout_ratio",
    name: "Dividend Payout Ratio",
    isNested: true,
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Total Dividend paid / Net Income
        </p>
        <p className="mb-0">
        It is the percentage of net earnings paid to shareholders in the form of dividends
        </p>
      </>
    )
  },
  {
    key: "free_cash_flow_per_share",
    name: "Free Cash Flow Per Share",
    isNested: true,
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Free cashflows / Total shares outstanding
        </p>
        <p className="mb-0">
        It measures the company's financial flexibility to repay its bligations and also predicts the growth of the business
        </p>
      </>
    )
  },
];

export const revenueTable = [
  {
    key: "net_sales",
    name: "Net Sales",
  },
];
export const DividentTableData = [
  {
    key: "dividend_payout_ratio",
    name: "Dividend Payout Ratio",
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Total Dividend paid / Net Income
        </p>
        <p className="mb-0">
        It is the percentage of net earnings paid to shareholders in the form of dividends
        </p>
      </>
    )
  },
  {
    key: "yield",
    name: "Dividend Yield",
    unit: "%",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        Annual Dividend per share / Current share price
        </p>
        <p className="mb-0">
        It shows how much a company pays out in dividends each year relative to its current market price.
        </p>
      </>
    )
  },
  {
    key: "dividend_per_share",
    name: "Dividend Per Share",
    unit: "₹",
    tooltipText: (
      <>
        <p className="mb-10 fw-500">
        (Total Dividend paid - Special Dividend) / Shares outstanding
        </p>
        <p className="mb-0">
        It is the sum of declared dividends issued by a company for every ordinary share outstanding
        </p>
      </>
    )
  },
  {
    key: "adj_dividend_per_share",
    name: "Adj Dividend Per Share",
    unit: "₹",
  },
];
