import React, { useEffect, useMemo, useState } from "react";
import { getCashFlowCompareStocks } from "../../../api/fetchClient";
import SelectOptions from "../../../components/ant/SelectOptions";
import CustomTable from "../../../components/CustomTable";
import convertTableStructure from "../convertTableStructure";
import { compareCashflow } from "../TableType";

function CompareCashFlow({
  lightMode,
  removedEmptyData,
  symbolsandnames,
  columns,
  CompanyType,
}) {
  const [Cashflow, setCashflow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectd, setselectd] = useState();
  const [LatestYear, setLatestYear] = useState();
 

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
    let yearstr = `year=${selectd}`;
      getCashFlowCompareStocks(removedEmptyData, CompanyType, selectd && yearstr).then(
        async (res) => {
          setCashflow(res.data);
          LatestYear === undefined && setLatestYear(res?.data?.year);
          setIsLoading(false);
        }
      );
    };
    getData();
  }, [selectd, CompanyType]);

  const yeararraay = [];

  for (var i = 0; i < 5; i++) {
    yeararraay.push({ value: LatestYear - i, label: LatestYear - i });
  }

  const returnsTableData = useMemo(() => {
    if (Cashflow === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        Cashflow?.stocks,
        compareCashflow,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [Cashflow, lightMode]);

  return (
    <div id="Cashflow">
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c ">Cash Flow Statement</h2>
        </div>
        <div className="d-flex flex-col align-items-end">
          {LatestYear && (
            <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setselectd}
              defaultSelect={LatestYear}
              width={116}
            />
          )}
          <p className="fs-s-12 mb-0 text-gray mt-15">*Values in Cr.</p>
        </div>
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={returnsTableData?.columns}
          loading={isLoading}
          scrollable={true}
          data={returnsTableData?.tableBodyData}
        />
      </div>
    </div>
  );
}

export default CompareCashFlow;
