import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function StockProfitCalc() {
  const { lightMode } = useSelector(getThemeMode);
  const [GetRsult, setGetRsult] = useState([]);
  const [symbl, setsymbl] = useState();
  const [shares, setshares] = useState();
  const [buy, setBuy] = useState();
  const [sell, setSell] = useState();
  const [buycom, setBuycom] = useState();
  const [sellcom, setSellcom] = useState();
  const [brght, setBrght] = useState(0);
  const [sold, setSold] = useState(0);
  const [profit, setProfit] = useState(0);
  const [Percentage, setPercentage] = useState(0);
  const [err, setErr] = useState(false);
  const navigator = useNavigate();
  const formlist = [
    { name: "shares", value: "# of Shares" },
    { name: "buycom", value: "Buy Commission" },
    { name: "buy", value: "Buy Price" },
    { name: "sellcom", value: "Sell Commission" },
    { name: "sell", value: "Sell Price" },
  ];
console.log("brght",isNaN(brght));
const bottomData = [{
  header:"What is a Stock Profit Calculator?",
  body:"This calculator is an investor favourite. A stock profit calculator enables the investor to find the exact profit that he would be making on his long-term holdings."
},
{
  header:"How does this calculator Work?",
  body:"The Stock Profit calculator works on the premise of knowing the Return on the investment made on a particular stock over a period of time. Various other information required in this calculator includes the Number of shares bought, the Sell price of the shares, and the duration of the trade. This calculator is also useful for the purpose of backtesting as it enables you to find the profit that you would have made if you had held onto your position for a certain number of years."
},

]
  const valueData = [
    {
      key: "Bought For ",
      value: brght !== Infinity && !isNaN(brght) ? Number(brght).toFixed(2) : 0 ,
    },
    {
      key: "Sold For ",
      value: sold !== Infinity && !isNaN(sold) ? Number(sold).toFixed(2) : 0,
    },
  ];
  const resetValues = () =>{
    setBrght(0)
    setSold(0)
    setProfit(0)
setPercentage(0)
  }
  useEffect(() => {
    let Boughtfor =
      Number(GetRsult["shares"]) * Number(GetRsult["buy"]) + Number(GetRsult["buycom"]);
    console.log(Boughtfor, "values");
    setBrght(Boughtfor);
    let Soldfor =
     Number(GetRsult["shares"]) * Number(GetRsult["sell"]) + Number(GetRsult["sellcom"]);
    console.log(Soldfor);
    setSold(Soldfor);
    let NetProfit = Number(Soldfor) - Number(Boughtfor);
    console.log(Soldfor, "NetProfit");
    setProfit(NetProfit);
    let Percentage = (Number(NetProfit) / Number(Boughtfor)) * 100;
    console.log(Percentage);
    setPercentage(Percentage);
  }, [GetRsult]);

  const ResultHeder = [
    {
      " Net Profit ": 
        profit && profit !== Infinity ? Number(profit).toFixed(2) : 0
      ,
    },
    {
      "  Net Percentage":
        Percentage && Percentage !== Infinity
          ? Number(Percentage)?.toFixed(2)
          : 0 ,
    },
  ];
  return (
    <div>
      <div>
        <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
          <div className="my-30">
            <p className="pointer" onClick={() => navigator(-1)}>
              Calculators / Stock Profit Calculator
            </p>
            <p className="mb-0 fs-30-20 fw-700">Stock Profit Calculator</p>
            <p>
              This calculator can be used to find the overall profit on the
              shares bought and sold over a period of time.
            </p>
          </div>
          <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
            <div>
              <CalcInputFeilds
                setGetRsult={setGetRsult}
                formlist={formlist}
                resetValues={resetValues}
                lightMode={lightMode}
              />
            </div>
            <div>
              <ResultContainerCalc
                ResultHeder={ResultHeder}
                resultLise={valueData}
                lightMode={lightMode}
              />
            </div>
          </div>
          <div>
            <BottomDataSection bottomData={bottomData}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockProfitCalc;
