import { Checkbox, Modal } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { postScreenerData } from "../../../redux/reducers/screenerSlice";

function AddColumnModal({
  columnsArr,
  setcolumnsArr,
  lightMode,
  toggleModal,
  settoggleModal,
  screenerStore,
  filterTypeArr,
  defaultChecked,
  postSectorArr,
  postIndexArr
}) {

  const dispatch=useDispatch()
  const searchInputRef = useRef(null);
  // get all columns
  const [checkedColumns, setcheckedColumns] = useState([]);
  const [columnList, setcolumnList] = useState({});
useEffect(() => {
  setcheckedColumns(columnsArr)
}, [columnsArr])
useEffect(() => {
  setcolumnList(screenerStore?.filterData)
}, [screenerStore?.filterData,toggleModal])
  const searchResults = (e) => {
    const initObj = {};
    const lowerValue = e.target.value.toLowerCase();
    Object.entries(screenerStore?.filterData).forEach((item) => {
      const filterKeys = item[1].filter((key) =>
        key.name.toLowerCase().includes(lowerValue)
      );
      if (filterKeys.length > 0) initObj[item[0]] = filterKeys;
    });
    setcolumnList(initObj);
  };

  const addToFilterList = (data, event) => {
    if(event.target.checked){
      setcheckedColumns([...checkedColumns,
        {
          label: data.name,
          firstValue: data.min_value,
          secondValue: data.max_value,
          particular: data.particular,
          operator: "&",
          min: data.min_value,
          max: data.max_value,
          unit: data.unit,
          symbol: data.symbol,
        },]);
      
    }
    else{
      if(defaultChecked.includes(event.target.name)) return
      else{
        setcheckedColumns(
          checkedColumns?.filter((item) => item.particular !== event.target.name)
        );
      }
    }
  };
 // send applied filter to redux
 const postFilterToStore = () => {
  const columnData=checkedColumns.map((data)=>data.particular)
  dispatch(
    postScreenerData({
      applyFilters: filterTypeArr,
      industries: postSectorArr,
      indices: postIndexArr,
      customColumns:columnData
    })
  );
  setcolumnsArr(checkedColumns)
  setcheckedColumns([])
  // setpageOffset(0);
};

  return (
    <div>
      <Modal
        width={900}
        header
        bodyStyle={{ padding: "0px" }}
        title={
          <div style={{ marginLeft: "-12px" }}>
            <p
              className={`fs-20-16 mb-0 fw-600 ${
                lightMode ? "text-dark" : "text-white"
              }`}
            >
              Add Columns
            </p>
            <p className="text-gray fs-s-12 mb-0">
              Select the required columns to be added to the table
            </p>
          </div>
        }
        visible={toggleModal}
        centered
        wrapClassName={!lightMode && "filter-modal "}
        // onOk={handleOk2}
        onCancel={() => {
          searchInputRef.current.value=""
          settoggleModal(false);
          // setcheckedColumns([])
        }}
        footer={[
          <button
            onClick={() => {
             postFilterToStore()
              settoggleModal(false);
          searchInputRef.current.value=""
            }}
            key="submit"
            type="primary"
            className={` w-150px h-45 br-5 pointer
            btn-bg-primary fs-s-14 fw-600 text-white`}
            // onClick={() => handleOk2(CreateWatchlist)}
          >
            Done
          </button>,
        ]}
      >
        <div>
          <div className="mb-20 pt-10px px-10px">
            <input
              style={{ height: "32px" }}
              ref={searchInputRef}
              onChange={searchResults}
              placeholder="Search for Filters"
              className={`w-100 ${
                lightMode ? "custom-input-light" : "custom-input-dark"
              }`}
              type="text"
            />
          </div>
          <div style={{ overflow: "auto" }} className="scroll h-70vh-50vh">
            {Object.entries(columnList)?.map((data, i) => {
              return (
                <div key={i}
                  className={`px-20 ${
                    lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
                  }`}
                >
                  <p className="fs-16-14 fw-600 my-10">{data[0]}</p>
                  <div className="column-c-3">
                    {data[1]?.map((checklist, i) => (
                      <div
                        key={i}
                        className={`${
                          lightMode ? "checkbox-light" : "checkbox-dark"
                        }`}
                      >
                        <Checkbox
                        checked={[...filterTypeArr,...checkedColumns]?.find(data=>data.particular===checklist.particular)?true:false}
                        // checked={[...defaultChecked,...checkedColumns].includes(checklist.particular)}
                          name={checklist.particular}
                          onChange={(e) => addToFilterList(checklist, e)}
                          style={{ color: lightMode ? "black" : "white" }}
                          className={`modal-list-item `}
                        >
                          {checklist.name}
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddColumnModal;
