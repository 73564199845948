import React from "react";
import DashboardIndexCard from "../../../components/DashboardIndexCard";

function MainIndexList({ lightMode }) {
  const indexArr = ["NIFTY", "SENSEX", "BANKNIFTY"];
  return (
    <div className="flex overflow-md-scroll">
      {indexArr.map((index, i) => (
        <DashboardIndexCard index={index} key={i} lightMode={lightMode} />
      ))}
    </div>
  );
}

export default MainIndexList;
