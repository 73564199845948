import React, { useEffect, useMemo, useState } from "react";
import { CompareStocksApi } from "../../../api/fetchClient";
import CustomTable from "../../../components/CustomTable";
import { CompareKeyMetrics, CompareKeyMetricsBanking } from "../TableType";
import convertTableStructure from "../convertTableStructure";
import SelectOptions from "../../../components/ant/SelectOptions";

function KeyMetricsCompareStocks({
  lightMode,
  removedEmptyData,
  columns,
  symbolsandnames,
  CompanyType,
  EmptylessFullName
}) {
  const [isLoading, setisLoading] = useState(false);
  const [GetMatricsData, setGetMatricsData] = useState();
  const [year, setYear] = useState();
  const [LatestYear, setLatestYear] = useState();
  useEffect(() => {
    const getData = async () => {
      setisLoading(true);
      let yearstr = `year=${year}`;
      CompareStocksApi(CompanyType, removedEmptyData, year ? yearstr : "").then(
        async (res) => {
          setGetMatricsData(res.data);
          LatestYear === undefined && setLatestYear(res?.data?.year);
          setisLoading(false);
        }
      );
    };
    getData();
  }, [year, CompanyType]);
  const yeararraay = [];

  for (var i = 0; i < 5; i++) {
    yeararraay.push({ value: LatestYear - i, label: LatestYear - i });
  }

  const returnsTableData = useMemo(() => {
    if (GetMatricsData?.stocks === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        GetMatricsData?.stocks,
        GetMatricsData?.is_banking
          ? CompareKeyMetricsBanking
          : CompareKeyMetrics,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [GetMatricsData,lightMode]);
  return (
    <div className="w-100 ">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c ">Key Metrics</h2>
        </div>
        {LatestYear && (
          <div className="d-flex flex-col align-items-end">
            <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setYear}
              defaultSelect={LatestYear}
              width={116}
            />
          </div>
        )}
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={returnsTableData?.columns}
          scrollable={true}
          data={returnsTableData?.tableBodyData}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default KeyMetricsCompareStocks;
