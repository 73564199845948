import React, { useMemo } from "react";
import StockEndpoints from "../../api/endPoints";
import svgSheet from "../../assets/svg/svgSheet";
import CustomTooltip from "../../components/ant/CustomTooltip";
import CustomTable from "../../components/CustomTable";
import useGetFetch from "../../hooks/useGetFetch";

function GrowthTable({ lightMode, symbol, companyType }) {
  const {
    isLoading,
    apiData: growthData,
    serverError,
  } = useGetFetch(StockEndpoints.growthReturns(symbol, companyType));
  const tootipData = [
    "This refers to the amount of money your company makes over a pre-determined time compared to the previous, identical amount of time.",
    "This refers to profits the company makes over a pre-determined time compared to the previous, identical amount of time.",
    "Book value growth tells an investor how quickly a company is building its asset base.",
    "The dividend growth rate is the annualized percentage rate of growth that a particular stocks dividend undergoes over a period of time.",
  ];
  const tableData = useMemo(() => {
    const tableObj =
      growthData &&
      Object.values(growthData)?.map((item, i) => {
        return {
          ...item.value,
          PARTICULARS: item.name,
          tooltipText: tootipData[i],
        };
      });
    return tableObj;
  }, [growthData]);

  const yarsHeader = [
    {
      title: <p className="fw-600 mb-0 fs-s-16">PARTICULARS</p>,
      dataIndex: "PARTICULARS",
      fixed: "left",
      width: "50px",
      render: (text, index) => {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  fontSize: "14px",
                  borderRight: "1px solid #C9D7DE",
                  borderLeft: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  fontSize: "14px",
                },
          },
          children: (
            <div
              style={{ color: lightMode ? "black" : "white" }}
              className="d-flex  justify-content-between align-items-center"
            >
              <p className="mb-0">{text}</p>
              {index.tooltipText && (
                <CustomTooltip text={index?.tooltipText}>
                  {svgSheet.infoTooltop}
                </CustomTooltip>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">1 Yr CAGR</p>,
      dataIndex: "one_year",
      key: "one_year",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text ? `${text} %` : "NA"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">3 Yr CAGR</p>,
      dataIndex: "three_year",
      key: "three_year",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text ? `${text} %` : "NA"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">4 Yr CAGR</p>,
      dataIndex: "five_year",
      key: "five_year",
      width: "50px",
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text ? `${text} %` : "NA"}
            </span>
          ),
        };
      },
    },
  ];

  return (
    <div
      className={`table-shadow ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
      <CustomTable columns={yarsHeader} scrollable={true} data={tableData} />
    </div>
  );
}

export default GrowthTable;
