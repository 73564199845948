export const plans = {
    basic: {
    watchlist: 1,
    portfolioAnalsis: 0,
    stockCompare: 2,
    screener: 3,
    excel:0
  },
  "1month": {
    watchlist: 3,
    portfolioAnalsis: 1,
    stockCompare: 3,
    screener: 10,
    excel:50
  },
  "3months": {
    watchlist: 3,
    portfolioAnalsis: 1,
    stockCompare: 3,
    screener: 10,
    excel:50
  },
  "12months": {
    watchlist: 5,
    portfolioAnalsis: 3,
    stockCompare: 4,
    screener: 20,
    excel:100
  },
  "6months": {
    watchlist: 5,
    portfolioAnalsis: 3,
    stockCompare: 4,
    screener: 20,
    excel:100
  },
  "3years": {
    watchlist: 100,
    portfolioAnalsis: 5,
    stockCompare: 5,
    screener: 500,
    excel:1000
  },
  "year": {
    watchlist: 100,
    portfolioAnalsis: 5,
    stockCompare: 5,
    screener: 500,
    excel:1000
  },
};
