import axios from "axios";
import { useState, useEffect } from "react";
import { get, getHeader } from "../api/MainClient";

export default function useGetFetch(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [reFetch, setreFetch] = useState("");
  const [needFetching, setNeedFetching] = useState(false);
  // let mounted = true;
  useEffect(() => {
    // if(needFetching) return
    const controller = new AbortController();
    setIsLoading(true);
    const fetchData = async () => {
      try {
        // const resp = await get(url);
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${url}`,getHeader(),
          {
            signal: controller.signal,
          }
        );
        const data = await resp?.data;
        setApiData(data);
        setIsLoading(false);
      } catch (error) {
        setServerError(error);
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchData();
   return ()=>{
      controller.abort();
   }
  }, [url, reFetch]);

  return { isLoading, apiData, serverError, setreFetch };
}
//   export default useGetFetch()
