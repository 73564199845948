import React, { useState } from 'react'
import { Form, Input, Modal } from 'antd'
import googleIcon from '../../assets/images/logo/googleIcon.png'
import { postLoginData } from '../../api/fetchClient';
import { setAuth } from '../../redux/reducers/authSlice';
import { useDispatch } from 'react-redux';
import GoogleAuth from './GoogleAuth';

function Login({popupStatus,onCloseHandler}) {
    const dispatch=useDispatch()
  const [form] = Form.useForm();
  const [apiLoader, setapiLoader] = useState(false)
  const [apiError, setApiError] = useState()
const onSubmit=async(values)=>{
    setapiLoader(true)
    console.log(values);
   try{
       await postLoginData(values).then((resp)=>{
           dispatch(setAuth(resp.data))
           setapiLoader(false)
           onCloseHandler('NONE')
       })
   }
   catch(error){
       const errorMsg=Object.values(error.response.data)
       console.log(errorMsg);
       setApiError(errorMsg[0])
       setapiLoader(false)
   }
}
  return (
    <Modal
    centered
    closable={false}
    width='800px'
    bodyStyle={{padding:'0px'}}
    visible={popupStatus==="LOGIN"}
    footer={null}
    onCancel={()=>onCloseHandler('NONE')}
    >
    <div className='d-flex flex-col align-items-center ff-poppins w-100  p-50-16 bg-popup-img'>
    <p className='fw-500 fs-s-20'>Join Trade Brains Portal</p>
    <Form
    className='w-60-80'
    form={form}
    name="login"
    onFinish={onSubmit}
    scrollToFirstError>
    <Form.Item
        style={{margin:'6px 0px'}}
        name="email"
        rules={[
        {
            type: 'email',
            message: 'The input is not valid E-mail!'
        },
        {
            required: true,
            message: 'Please Enter your E-mail!',
        },
        ]}
          >
        <Input type='text' className='w-100  auth-form-input' placeholder='Email'/>
        </Form.Item>
        <Form.Item
            style={{marginBottom:'0px'}}
            name="password"
            rules={[
            {
                required: true,
                message: 'Please input your password!',
            },
            ]}
            // hasFeedback
        >
    <Input.Password type='text' className='w-100 h-40px auth-form-input'   placeholder='Enter Password'/>
    </Form.Item>
    <p className='ff-poppins my-10 text-skyblue pointer' onClick={()=>onCloseHandler("FORGOT")}>Forgot Password?</p>
    {apiError&&<div style={{color:'#ff4d4f',textAlign:'center'}}>{apiError}</div>}
    {
        apiLoader?
    <div  style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white center'>Logging in....</div>
    :
    <button type='submit' style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white pointer'>Login</button>
    }
    </Form>
    {/* <div style={{margin:'6px 0px'}} className='center w-60-80 py-10 br-5 bg-white b-gray pointer'>
        <img src={googleIcon} alt="icon" className='mx-10px h-20 w-20'/>
         <span>Login with Google</span>
          </div> */}
          <GoogleAuth title="Login with Google" onCloseHandler={onCloseHandler}/>
          <p style={{color:'#6c757d',textAlign:'center'}} className="fs-s-12 w-60-80 my-10">By signing up, you have read and agreed to our  
            <span style={{color:'#343a40'}}> Privacy</span> and <span style={{color:'#343a40'}}>Terms & Conditions</span>.</p>
            <p className='fw-700'>Don't have an account yet ? <span onClick={()=>onCloseHandler("SIGNUP")} className='pointer' style={{color:"#28a745"}}>Sign up</span></p>
    </div>
        </Modal>
    )
}

export default Login