import React from "react";
import Link from "react-scroll/modules/components/Link";
import { Link as RouterLink } from "react-router-dom";
function LeftSidebar({ lightMode, sidebarData,subHead,subData,baseURL,premiumCheck}) {
 
  return (
    <div
      className={`w-100  card-shadow detail-left-card ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
      style={{
        height: "min-content",
        marginBottom: "40px",
        paddingBottom: "10px",
      }}
    >
      {sidebarData?.map((data, i) => (
        <Link
          key={i}
          activeClass={lightMode ? "desktop-active" : "desktop-active-dark"}
          to={data.id}
          spy={true}
          smooth={true}
          offset={data.offset}
          duration={500}
          className={`px-20 flex align-items-center ${
            lightMode ? "bb1px-light-mode hover-light-blue bt-first-child-light" : "bb1px-dark-mode bt-first-child-dark bg-dark-hover"
          }`}
          style={{ minHeight: "45px" }}
        >
          {data.icon}
          <span className=" fs-s-15 fw-500">{data.label}</span>
        </Link>
      ))}
      <p className="px-20 my-15 fs-s-15 fw-500">{subHead}</p>
      {subData?.map((bucket, i) => (
        <RouterLink
          to={bucket.isPremium&&!premiumCheck?'/getpremium':`${baseURL}/${bucket.label}`}
          key={i}
          style={{ height: "50px" }}
          className={`${lightMode ? "hover-light-blue" : "bg-dark-hover"} p-15 flex align-items-center`}
        >
          <img src={bucket.image} className="w-35 h-35 br-50" />
          <p className="fs-s-15 fw-500 mb-0">{bucket.label}</p>
        </RouterLink>
      ))}
    </div>
  );
}

export default LeftSidebar;
