import { AutoComplete, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  addStockToStockDetails,
  getStockDetails,
  putStockToStockDetails,
} from "../../../../api/fetchClient";
import { useNavigate, useParams } from "react-router-dom";

function StockDetailsForm({
  form,
  dropdowndata,
  setDropdowndata,
  inputReturns,
  setInputReturns,
  initialData,
  disableautocomplete,
}) {
  const navigate = useNavigate();
  const [selectedLabel, setSelectedLabel] = useState(
    (initialData && initialData?.company_name) || ""
  );
  const { id } = useParams();
 const [ekeditor, setekeditor] = useState(initialData?.research_report || "");
  const [ekkeditor, setekkeditor] = useState(initialData?.description || "");
  const { lightMode } = useSelector(getThemeMode);
  const [isLoading, setisLoading] = useState(false);

  const getResults = async (e) => {
    const response = await getStockDetails(e)
      .then((resp) => {
        setInputReturns(resp.data);
        setSelectedLabel(resp.data[0]?.FINCODE);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (inputReturns) {
      const options = inputReturns.map((items) => ({
        value: items.company,
        label: items.company,
      }));
      setDropdowndata(options);
    }
  }, [inputReturns]);
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
      setSelectedLabel(initialData.company);
      // setekeditor(initialData.research_report);
      setekkeditor(initialData.description);
    }
  }, [initialData]);
  const onSubmit = async (values) => {
    const updatedValues = {
      ...values,
      fincode: selectedLabel,
    };
    // updatedValues.research_report = ekeditor;
    updatedValues.description = ekkeditor;

    if (initialData) {
      try {
        const response = await putStockToStockDetails(updatedValues, id);
        navigate(`/admin/stockdetails`, { replace: true });
      } catch (error) {
        console.log(error);
      }
    } else {
      // Adding new stock details
      try {
        const response = await addStockToStockDetails(updatedValues);
        navigate(`/admin/stockdetails`, { replace: true });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item type="hidden" name="fincode" initialValue={selectedLabel}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Stock Name"
        rules={[
          {
            required: true,
            message: "Please select a stock",
          },
        ]}
        name="company"
        className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
      >
        <AutoComplete
          className={
            lightMode
              ? "border1px-light-mode"
              : "border1px-dark-mode antd-Selete-Custom-dark  "
          }
          style={{
            width: "100%",
          }}
          notFoundContent="No Stocks"
          placeholder=" Select Stock"
          options={dropdowndata}
          dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
          onFocus={() => getResults("")}
          onChange={(e) => {
            getResults(e);
            setSelectedLabel(e);
          }}
          value={selectedLabel}
          onSelect={(value, option) => {
            setSelectedLabel(option?.label);
          }}
          disabled={disableautocomplete}
          defaultActiveFirstOption={true}
        />
      </Form.Item>

      <Form.Item
        label="Description"
        rules={[
          {
            required: true,
            message: "Please enter description",
          },
        ]}
        name="description"
        className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
      >
        <CKEditor
          editor={ClassicEditor}
          data={ekkeditor}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setekkeditor(data);
          }}
        />
      </Form.Item>
      {/* <Form.Item
        label="Research Report"
        rules={[
          {
            required: true,
            message: "Please enter research report",
          },
        ]}
        name="research_report"
        className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
      >
        <CKEditor
          editor={ClassicEditor}
          data={ekeditor}
          onReady={(editor) => {
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setekeditor(data);
          }}
        />
      </Form.Item> */}
    </Form>
  );
}

export default StockDetailsForm;
