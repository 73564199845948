import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb({linkData, current}) {
  return (
    <div className=" fs-s-12 d-flex">
      {linkData?.map((item,i) => (
        <div key={i}>
        <Link className="link-hover-underline" replace={true} to={item.link}>
          {item.label}
        </Link>
       &nbsp;<span>{">"}</span>&nbsp;
        </div>
      ))}
      <span>{"  "}{current}</span>
    </div>
  );
}

export default BreadCrumb;
