import Item from "antd/lib/list/Item";
import React, { useEffect, useMemo, useState } from "react";
import { getFinational } from "../../../api/fetchClient";
import AreaChart from "../../../components/Charts/AreaChart";
import BarChart from "../../../components/Charts/BarChart";
import { getGradient } from "../../../components/Charts/getGradients";
import convertToGraphData from "../convertToGraphData";

function GraphsOfComareStocks({ lightMode, dropdowndata, removedEmptyData ,CompanyType}) {
  //graph options
  const [GraphData, setGraphData] = useState({});

  useEffect(() => {
    const getData = () => {
      getFinational(removedEmptyData,CompanyType).then((res) => {
        setGraphData(res?.data);
      });
    };
    getData();
  }, [CompanyType]);
    const backgroundColors = [
    "rgba(80, 234, 230, 1)",
    "rgba(154, 182, 251, 1)",
    "rgba(67, 143, 255, 1)",
    "rgba(228, 232, 70, 1)",
    "rgba(255, 145, 119, 1)",
  ];
  const backgroundColors1 = [
    "rgba(80, 234, 230, 1)",
    "rgba(154, 182, 251, 1)",
    "rgba(67, 143, 255, 1)",
    "rgba(228, 232, 70, 1)",
    "rgba(255, 145, 119, 1)",
  ];
  
  const symbols = [];
  if (GraphData?.stocks !== undefined) {
    Object.keys(GraphData?.stocks)?.map((Item) => {
      symbols.push(Item);
    });
  }

  const gradientColors = [
   
    [
      { xValue: 0, color: "#50EAE6" },
      { xValue: 0.25, color: "#02ABD0" },
    ],
    
    [
      { xValue: 0, color: "#9AB6FB" },
      { xValue: 0.25, color: "#A0E7CA" },
    ], [
      { xValue: 0, color: "#438FFF" },
      { xValue: 0.25, color: "#9A60FA" },
    ], [
      { xValue: 0, color: "#E4E846" },
      { xValue: 0.25, color: "#ACE991" },
    ], [
      { xValue: 0, color: "#FF9177" },
      { xValue: 0.25, color: "#FC73BD" },
    ],
    
  ];

  const graphDataSets = useMemo(() => {
    const epsdata = convertToGraphData(
      symbols,
      GraphData,
      "earnings_per_share"
    );
    const graphdata1 = convertToGraphData(
      symbols,
      GraphData,
      "net_profit_margin"
    );
    const roe = GraphData && convertToGraphData(symbols, GraphData, "roe");
    const dte =GraphData && convertToGraphData(symbols, GraphData, "total_debt_to_equity");

    return { epsdata, graphdata1, roe, dte };
  }, [GraphData]);

  const eps = symbols.map((items, index) => {
    const graphdata1 = [];
     GraphData?.stocks[items].map((values, i) => {
      graphdata1.push(values["earnings_per_share"]);
    });
    return graphdata1;
  });
const graphDataset = useMemo(() => {
  const data =  graphDataSets?.roe?.map((items, i) => {
   return{
      pointStyle: "rectRot",
      label: symbols[i],
      fill: false,
      backgroundColor: backgroundColors[i],
      data: items.reverse(),
      borderColor: backgroundColors[i],
      borderWidth: 4,
    }
  });
  return data
}, [graphDataSets?.roe])

console.log("graphDataset",graphDataset);

const graphDataset1 =  useMemo(() => {
    const data = graphDataSets?.graphdata1?.map((items, i) => {
   return{
      pointStyle: "rectRot",
      label: symbols[i],
      fill: false,
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return getGradient(ctx, chartArea, gradientColors[i]);
      },
      data: items.reverse(),
      borderColor: backgroundColors[i],
      borderWidth: 4,
    }
  });
  return data
}, [graphDataSets?.graphdata1])


const earningPerShareDateSet = useMemo(() =>{
 const data =  graphDataSets?.epsdata?.map((items, i) => {
    return{
      pointStyle: "rectRot",
      label: symbols[i],
      barPercentage: 0.9,
      barThickness: 40,
      type: "bar",
      // backgroundColor: backgroundColors[i],
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return getGradient(ctx, chartArea, gradientColors[i]);
      },
      borderWidth: 0,
      fill: true,
      data: items.reverse(),
    }
  });

  return data

}, [graphDataSets?.epsdata])




  console.log("earningPerShareDateSet",earningPerShareDateSet);

  // Debt to Equity
const dataasss = useMemo(() => {
 const graphDebit =  graphDataSets?.dte?.map((items, i) => {

   return{
      pointStyle: "rectRot",
      label: symbols[i],
      barPercentage: 0.9,
      barThickness: 20,
      type: "bar",
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return getGradient(ctx, chartArea, gradientColors[i]);
      },
      borderWidth: 0,
      fill: true,
      data: items.reverse(),
    }
  }
  );
  return graphDebit
  console.log("graphDebit",graphDebit);
}, [graphDataSets?.dte])

console.log("dataValusdadas",dataasss);


  

  const options1 = {
    //Customize chart options

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 2,
          borderDash: [7, 10],
          // borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
      x: {
        ticks: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          font: {
            size: 14,
          },
          padding: 5,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 1,
          borderDash: [5, 15],
          borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          fontSize: 14,
          usePointStyle: true,
          boxWidth: 5,
          padding: 30,
        },
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "center",
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            return backgroundColors1[context.datasetIndex];
          },
        },
      },
    },
  };

  const options2 = {
    //Customize chart options
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          font: {
            size: 14,
          },
          padding: 5,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 2,
          borderDash: [7, 10],
          // borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          color: lightMode ? "#ABABAB" : "#545E78",
          borderColor: lightMode ? "#000" : "#545E78",
          borderWidth: 1,
          borderDash: [5, 15],
          borderDashOffset: 5,
          drawTicks: false,
          lineWidth: 1,
          offset: false,
        },
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: lightMode ? "#3B3F4F" : "#ffff",
          fontSize: 14,
          usePointStyle: true,
          boxWidth: 5,
          padding: 30,
        },
      },
      tooltip: {
        titleAlign: "center",
        yAlign: "center",
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            return backgroundColors1[context.datasetIndex];
          },
        },
      },
    },
  };

  //graph data

  const EarningperS = {
    //Bring in data
    labels: ["2018", "2019", "2020", "2021", "2022"],
    datasets: earningPerShareDateSet,
  };
  console.log("EarningperS",EarningperS);
  const graphData2 = {
    //Bring in data
    labels: ["2018", "2019", "2020", "2021", "2022"], 
    datasets: dataasss,
  };

  //gragh data

  const data2 = {
    labels: [2018, 2019, 2020, 2021, 2022],
    datasets: graphDataset1,
  };

  const data1 = {
    labels: [2018, 2019, 2020, 2021, 2022],
    datasets: graphDataset,
  };

  return (
    <div id="Financial Ratios" className="w-100 mt-10">
      <h2 className="main-header-c">Financial Ratios</h2>
      <div className="d-flex mobile-wrap  justify-content-between">
        <div
          style={{ height: "300px" }}
          className="my-55 responsive-grap-stock-comaprepage"
        >
          <p className="fs-s-20">Return On Equity (ROE)</p>

          <AreaChart
            dataPoints={data1}
            options={{
              responsive: true,

              maintainAspectRatio: false,
              tension: 0,
              scales: {
                y: {
                  display: false,
                },

                x: {
                  ticks: {
                    color: lightMode ? "#3B3F4F" : "#ffff",
                    font: {
                      size: 14,
                    },
                    padding: 5,
                  },
                },
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    color: lightMode ? "#3B3F4F" : "#ffff",
                    fontSize: 14,
                    usePointStyle: true,
                    boxWidth: 5,
                    padding: 30,
                  },
                },
                tooltip: {
                  titleAlign: "center",
                  yAlign: "center",
                  backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
                  bodyFont: {
                    size: 14,
                    weight: 600,
                  },
          
                  displayColors: false,
                  callbacks: {
                    title: (chart, args) => {
                      return "";
                    },
                    label: (context) => `${context.formattedValue}`,
                    labelTextColor: (context) => {
                      return backgroundColors1[context.datasetIndex];
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
                mode: "index",
              },
            }}
          />
        </div>
        <div
          style={{ height: "300px" }}
          className="my-55 responsive-grap-stock-comaprepage graph-compare-stockspage"
        >
          <p className="fs-s-20 mt-0-15">{GraphData?.is_banking ? " Net Interest Margin" : "Net Profit Margin"}</p>

          <AreaChart
            dataPoints={data2}
            options={{
              responsive: true,

              maintainAspectRatio: false,
              tension: 0,
              scales: {
                y: {
                  display: false,
                },

                x: {
                  ticks: {
                    color: lightMode ? "#3B3F4F" : "#ffff",
                    font: {
                      size: 14,
                    },
                    padding: 5,
                  },
                },
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    color: lightMode ? "#3B3F4F" : "#ffff",
                    fontSize: 14,
                    usePointStyle: true,
                    boxWidth: 5,
                    padding: 30,
                  },
                },
                tooltip: {
                  titleAlign: "center",
                  yAlign: "center",
                  backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
                  bodyFont: {
                    size: 14,
                    weight: 600,
                  },
          
                  displayColors: false,
                  callbacks: {
                    title: (chart, args) => {
                      return "";
                    },
                    label: (context) => `${context.formattedValue}`,
                    labelTextColor: (context) => {
                      return backgroundColors1[context.datasetIndex];
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
                mode: "index",
              },
            }}
          />
        </div>
      </div>
      <div className="d-flex mt-25-0  mobile-wrap w-100 justify-content-between">
        <div
          className="my-40 responsive-grap-stock-comaprepage mt-10"
          style={{ height: "300px" }}
        >
          <p className="fs-s-20 mb-10">Earnings Per Share</p>

          <BarChart
            type="bar"
            id="balanceSheetGraph"
            data={EarningperS}
            options={options1}
          />
        </div>
        <div
          className="my-40 mobile-mb-70 mt-0-15 responsive-grap-stock-comaprepage "
          style={{ height: "300px" }}
        >
          <p className="fs-s-20">{GraphData?.is_banking ? "Non Performing Assets" : "Debt to Equity"}</p>

          <BarChart
            type="bar"
            id="balanceSheetGraph"
            data={graphData2}
            options={options2}
          />
        </div>
      </div>
    </div>
  );
}

export default GraphsOfComareStocks;
