import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProfitTrackerData } from "../../api/fetchClient";
import CustomTable from "../../components/CustomTable";
import TimeLineButtons from "../../components/TimeLineButtons";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function ProfileTrackerPortfolioAnalysis({ Portfoliostocksdata, lightMode,portfolioid,IsOverFlowHidden }) {
  const [daysDuration, setdaysDuration] = useState("1");
  const [ProfitTracker, setProfitTracker] = useState();
  const [Loading, setLoading] = useState(false)
  const timeLineData = [
    // { label: "1M", value: "1m" },
    { label: "Dec 22", value: "1" },
    { label: "SEP 22", value: "2" },
    { label: "JUN 22", value: "3" },
    { label: "MAR 22", value: "4" },
    
  ];
  useEffect(() => {
    setLoading(true)
    getProfitTrackerData(portfolioid,daysDuration).then((res) => {
    setLoading(false)
      setProfitTracker(res?.data);
    });
  }, [daysDuration,portfolioid,Portfoliostocksdata]);
  console.log("ProfitTracker", ProfitTracker);

  const navigate = useNavigate();
  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name </p>,
      dataIndex: "company",
      fixed: "left",
      width: "40px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              onClick={() => navigate(`/stock/${record?.symbol}/consolidated`)}
              className="ff-lato pointer link-hover-underline"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Net Sales</p>,
      dataIndex: "net_sales",
      width: "50px",
      render(text, record) {
        {console.log("dadasdasdada",text)}
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
           {text === null ? <p style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }} title="To Be Announced">TBA</p>:   
            <div className={`${IsOverFlowHidden ? " d-flex-col" :"d-flex"}`}>
            <span
              className="ff-lato mb-0 mr-10 "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹{numberWithCommas(text)}
            </span>
            <p
              className="ff-lato mb-0 "
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: redGreenColorPicker(record?.net_sales_change,lightMode),
              }}
            >
              ({record?.net_sales_change  >= 0 ? "+" + record?.net_sales_change :record?.net_sales_change }%)

            </p>
            </div>}
            </div>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Net Profit</p>,
      width: "50px",
      dataIndex: "net_profit",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
              {text === null ? <p style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }} title="To Be Announced">TBA</p>: 
            <div className={`${IsOverFlowHidden ? " d-flex-col" :"d-flex"}`}>

            <span
              className="ff-lato mr-10"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              ₹{text && numberWithCommas(text)}
            </span>
            <p

              className="ff-lato mb-0"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(record?.net_profit_change,lightMode),
              }}
            >
              ({record?.net_profit_change >= 0 ? "+" +record?.net_profit_change :record?.net_profit_change }%)

            </p>
            </div>}
            </div>

          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Profit Margin</p>,
      width: "50px",
      dataIndex: "net_profit_margin",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div>
            {text === null ? <p style={{
              fontSize: "14px",
              fontWeight: "400",
              color: lightMode ? "black" : "white",
            }} title="To Be Announced">TBA</p>:
            <div className={`${IsOverFlowHidden ? " d-flex-col" :"d-flex"}`}>
            <span
              className="ff-lato mr-10"
              style={{
                color:lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}%
            </span>
             <p
             className="ff-lato mb-0 "
             style={{
               fontSize: "14px",
               fontWeight: "400",

               color: redGreenColorPicker(record?.net_profit_margin_change,lightMode),
             }}
           >
             ({record?.net_profit_margin_change >= 0 ? "+" + record?.net_profit_margin_change : record?.net_profit_margin_change }%)
           </p>
           </div>}
           </div>
          ),
        };
      },
    },
  ];

  return (
    <div >
      <div className="d-flex align-items-center-start d-flex-row-col justify-content-between w-100">
        {" "}
        <div className="d-flex align-items-center ">
          {" "}
          <h2 className="main-header">Performance Tracker</h2>
          
        </div>
       {!IsOverFlowHidden && <div className="d-flex  d-flex-row align-items-center">
          <TimeLineButtons
            data={timeLineData}
            value={daysDuration}
            setValue={setdaysDuration}
            lightMode={lightMode}
          />
        </div>}
      </div>
      <div>
        <div
          className={`table-shadow    ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <div className="d-flex justify-content-end w-100 text-gray">
            <span>*Value In Cr</span>
          </div>
          <CustomTable
            columns={columns}
            data={ProfitTracker?.data}
            // scrollable={true}LoaderMonth
            loading={Loading}
          />
        </div>
        <span className="fs-s-12 text-gray">
          *Values shown are latest quarter vs same quarter last year (YoY Change)
        </span>
      </div>
    </div>
  );
}

export default ProfileTrackerPortfolioAnalysis;
