import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import svgSheet from "../../assets/svg/svgSheet";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import TrendingStocks from "../../components/TrendingStocks";
import TreandingStockSC from "./TreandingStockSC";
import { AutoComplete } from "antd";
import { CompareStocksApi, getSearchData } from "../../api/fetchClient";
import MainStockComparePage from "./MainStockComparePage";
import MobileViewComparestocks from "./MobileViewComparestocks";
import MobileBottomBar from "../stock details/MobileBottomBar";
import stockcomapredark from "../../assets/images/Compare Stocks/stockcomapredark.png";
import stockcomparelight from "../../assets/images/Compare Stocks/stockcomparelight.png";
import PremiumBlurLayout from "../../components/PremiumBlurLayout";
import { authStore } from "../../redux/reducers/authSlice";
import { plans } from "../../utilityFn/planData";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";

function StocksCompare() {
  const { lightMode } = useSelector(getThemeMode);

  const [inputReturns, setInputReturns] = useState();
  const [isDisplay, setIsDisplay] = useState(false);
  const [Validation, setSetValidation] = useState(false);
  const [isBothEmpty, setisBothEmpty] = useState(false);
  const auth = useSelector(authStore);
  const [PlanName, setPlanName] = useState();
  const [Treanding, setTreanding] = useState(false);

  useEffect(() => {
    setPlanName(auth?.userData?.user?.plan?.planId);
  }, []);

  const [TreandingStocks, setTreandingStocks] = useState(false);
  const [Inputdatais, setInputdatais] = useState({
    firstvalue: "",
    secondvalue: "",
    thirdvalue: "",
    forthvalue: "",
    fifthvalue: "",
  });
  const [InputDataisOnChange, setInputDataIsOnChange] = useState({
    firstvalue: "",
    secondvalue: "",
    thirdvalue: "",
    forthvalue: "",
    fifthvalue: "",
  });
  const [InputFullName, setInputFullName] = useState({
    firstvalue: "",
    secondvalue: "",
    thirdvalue: "",
    forthvalue: "",
    fifthvalue: "",
  });
  const [CountOfInputs, setCountOfInputs] = useState({ name1: "", name2: "" });

  const removedEmptyData = Object.values(InputDataisOnChange).filter(
    (elements) => {
      return elements !== "";
    }
  );
  const EmptylessFullName = Object.values(InputFullName).filter((elements) => {
    return elements !== "";
  });

  useEffect(() => {
    if (isDisplay) {
      setIsDisplay(false);
    }
  }, [removedEmptyData?.length]);

  useEffect(() => {
    if (TreandingStocks) {
      setIsDisplay(true);
    }
  }, [TreandingStocks]);

  const onSubmit = () => {
    EmptylessFullName?.length < 2 ? setSetValidation(true) : setIsDisplay(true);
  };

  useEffect(() => {
    removedEmptyData?.length >= 2 && setSetValidation(false);
  }, [EmptylessFullName?.length]);

  const navBarData = [
    {
      icon: svgSheet.overviewIcon,
      label: "Key Metrics",
      id: "Key Metrics Comparison",
      offset: -110 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.pieIcon,
      label: "Financial Ratios",
      id: "Financial Ratios",
      offset: -120 - bannerOffsetCalc(auth),
    },
    {
      icon: (
        <div style={{ width: "24px", height: "24px" }}>
          {svgSheet.balanceIcon}
        </div>
      ),
      label: "Returns",
      id: "Returns",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.peerCompanyIcon,
      label: "Quarterly Results",
      id: "Quarterly Results",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.cashFlowIcon,
      label: "Profit & Loss",
      id: "Profit & Loss",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.ratioIcon,
      label: "Balance Sheet",
      id: "Balance Sheet",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.shareHoldingIcon,
      label: "Cashflow",
      id: "Cashflow",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.factsheetIcon,
      label: "Shareholding Pattern",
      id: "Shareholding Pattern",
      offset: -100 - bannerOffsetCalc(auth),
    },
    {
      icon: svgSheet.companyDetailsIcon,
      label: "5 Yr Factsheet",
      id: "5 Yr Factsheet",
      offset: -100 - bannerOffsetCalc(auth),
    },
  ];

  const makeReset = () => {
    setIsDisplay(false);
    setTreandingStocks(false);
    setInputDataIsOnChange({
      firstvalue: "",
      secondvalue: "",
      thirdvalue: "",
      forthvalue: "",
      fifthvalue: "",
    });
    setInputdatais({
      firstvalue: "",
      secondvalue: "",
      thirdvalue: "",
      forthvalue: "",
      fifthvalue: "",
    });
    setInputFullName({
      firstvalue: "",
      secondvalue: "",
      thirdvalue: "",
      forthvalue: "",
      fifthvalue: "",
    });
    setCountOfInputs({
      name1: "",
      name2: "",
    });
  };
  // send search input API
  const getResults = async (e) => {
    // setinputData(e.target.value);
    const response = await getSearchData(e)
      .then((resp) => {
        setInputReturns(resp.data);
      })
      .catch((err) => console.log(err));
  };

  const dropdowndata = [];
  const comparesymbols = [];

  {
    inputReturns &&
      inputReturns.map((items) => {
        items.type !== "index" &&
          ![
            Inputdatais["firstvalue"],
            Inputdatais["secondvalue"],
            Inputdatais["thirdvalue"],
            Inputdatais["forthvalue"],
            Inputdatais["fifthvalue"],
          ].includes(items.symbol) &&
          dropdowndata.push({
            // value: isNaN(items.symbol) ? items.symbol : items.symbol,
            value: items.symbol,
            label: items.company,
          });
      });
  }

  return (
    <div>
      <ReactHelmet
        title="Compare Stocks | Compare Stock prices | Compare Stock Financials | Compare Stock Profits | Compare Stock Returns - Advance Stock Comparison Tool"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
      <div className="d-flex flex-col my-body">
        <div className="w-100  ff-poppins max-w mx-auto  d-flex-col h-100 px-15 relative mobile-only-col ">
          <div className="d-flex mb-10">
            <p
              style={{ width: "36px", height: "36px" }}
              className="fs-36-20 mb-0 mr-10 lh-36"
            >
              {svgSheet.balanceIcon}
            </p>
            <h1 className="mb-0 fs-36-20  fw-700 lh-36"> Stock Compare</h1>
          </div>
          <div>
            <p className="fs-20-13 ">
              Compare & Find best companies to invest using our Advanced Stock
              Comparison Tool
            </p>
          </div>
          <div
            className={`${
              lightMode ? "bg-gray" : "bg-dark-gray"
            } w-100 Stockcompare-div p-15 d-flex flex-col justify-content-between`}
          >
            <div className="tab-mobile-view">
              <MobileViewComparestocks
                setInputDataIsOnChange={setInputDataIsOnChange}
                getResults={getResults}
                InputDataisOnChange={InputDataisOnChange}
                lightMode={lightMode}
                setIsDisplay={setIsDisplay}
                dropdowndata={dropdowndata}
                isDisplay={isDisplay}
                Inputdatais={Inputdatais}
                makeReset={makeReset}
                removedEmptyData={removedEmptyData}
                setInputdatais={setInputdatais}
                onSubmit={onSubmit}
                setInputFullName={setInputFullName}
                InputFullName={InputFullName}
                Validation={Validation}
                EmptylessFullName={EmptylessFullName}
                setCountOfInputs={setCountOfInputs}
                CountOfInputs={CountOfInputs}
                plan={plans[PlanName]?.stockCompare}
                TreandingStocks={TreandingStocks}
              />
            </div>
            <div className="stock-compare-header-div">
              <div className="d-flex ">
                <div
                  className={`Stockcompare-stock-card p-10 relative w-100 m-10px d-flex align-items-center justify-content-center flex-col ${
                    lightMode ? "bg-white" : "bg-dark"
                  }`}
                >
                  <div className=" d-flex w-100 flex-col align-items-center">
                    {svgSheet.InitialImageL}

                    <div
                      className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                        lightMode
                          ? "stock-comapre-input-light"
                          : "stock-comapre-input-dark"
                      }`}
                    >
                      <AutoComplete
                        className={
                          lightMode
                            ? "border1px-light-mode"
                            : "border1px-dark-mode antd-Selete-Custom-dark mb-10"
                        }
                        style={{
                          width: "100%",
                        }}
                        notFoundContent="No Stocks"
                        defaultValue={""}
                        onFocus={() => getResults("")}
                        allowClear={true}
                        placeholder=" Select Stock"
                        fieldNames={"firstOne"}
                        options={dropdowndata}
                        dropdownClassName={`${
                          !lightMode && "drop-down-stock invert-text"
                        }`}
                        value={InputDataisOnChange.firstvalue}
                        onChange={(e) => {
                          getResults(e);
                          setInputDataIsOnChange({
                            ...InputDataisOnChange,
                            firstvalue: e,
                          });
                          e?.length === 0 &&
                            setInputFullName({
                              ...InputFullName,
                              firstvalue: "",
                            });
                          setInputdatais({ ...Inputdatais, firstvalue: "" });
                        }}
                        defaultActiveFirstOption={true}
                        onSelect={(e, options) => {
                          setInputdatais({ ...Inputdatais, firstvalue:isNaN(e) ?  e.replace("&","%26"):e });
                          setInputDataIsOnChange({
                            ...InputDataisOnChange,
                            firstvalue: (options?.label),
                          });
                          setInputFullName({
                            ...InputFullName,
                            firstvalue: options?.label,
                          });
                        }}
                      />
                    </div>
                    {Validation ? (
                      <p className="fs-s-12 mb-0" style={{ color: "#F82E2E" }}>
                        minimum two fields must be filled
                      </p>
                    ) : (
                      ""
                    )}
                    {InputDataisOnChange.firstvalue.length > 0 &&
                      InputFullName.firstvalue.length === 0 && (
                        <p
                          className="fs-s-12 mb-0 absolute "
                          style={{ bottom: "8px", color: "#F82E2E" }}
                        >
                          Select Stock
                        </p>
                      )}
                  </div>
                </div>

                <div
                  className={`Stockcompare-stock-card p-10 relative  w-100 m-10px d-flex align-items-center justify-content-center flex-col ${
                    lightMode ? "bg-white" : "bg-dark"
                  }`}
                >
                  <div className=" d-flex w-100 flex-col align-items-center">
                    {svgSheet.InitialImageL}

                    <div
                      className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                        lightMode
                          ? "stock-comapre-input-light"
                          : "stock-comapre-input-dark"
                      }`}
                    >
                      <AutoComplete
                        className={
                          lightMode
                            ? "border1px-light-mode"
                            : "border1px-dark-mode antd-Selete-Custom-dark mb-10 "
                        }
                        style={{
                          width: "100%",
                        }}
                        onFocus={() => getResults("")}
                        allowClear={true}
                        notFoundContent="No Stocks"
                        placeholder=" Select Stock"
                        options={dropdowndata}
                        dropdownClassName={`${
                          !lightMode && "drop-down-stock invert-text"
                        }`}
                        value={InputDataisOnChange.secondvalue}
                        onChange={(e) => {
                          getResults(e);
                          setInputDataIsOnChange({
                            ...InputDataisOnChange,
                            secondvalue: e,
                          });
                          e?.length === 0 &&
                            setInputFullName({
                              ...InputFullName,
                              secondvalue: "",
                            });
                          setInputdatais({ ...Inputdatais, secondvalue: "" });
                        }}
                        defaultActiveFirstOption={true}
                        onSelect={(e, options) => {
                          setInputdatais({ ...Inputdatais, secondvalue:isNaN(e) ?  e.replace("&","%26") : e});
                          setInputDataIsOnChange({
                            ...InputDataisOnChange,
                            secondvalue: options?.label,
                          });
                          setInputFullName({
                            ...InputFullName,
                            secondvalue: options?.label,
                          });
                        }}
                      />
                    </div>
                    {Validation && (
                      <p className="fs-s-12 mb-0" style={{ color: "#F82E2E" }}>
                        minimum two fields must be filled
                      </p>
                    )}
                    {InputDataisOnChange.secondvalue.length > 0 &&
                      InputFullName.secondvalue.length === 0 && (
                        <p
                          className="fs-s-12 mb-0 absolute "
                          style={{ bottom: "8px", color: "#F82E2E" }}
                        >
                          Select Stock
                        </p>
                      )}
                  </div>
                </div>
                {auth?.userData?.user?.plan?.planId === "basic" ? (
                  <div className="Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col">
                    {" "}
                    <p className="absolute " style={{ width: "40px" }}>
                      {" "}
                      {svgSheet?.premiumIcon}{" "}
                    </p>{" "}
                    <p
                      className="absolute fs-s-16 fw-700 "
                      style={{ top: "77px" }}
                    >
                      Get Bulls Plan
                    </p>
                    <img
                      className="image-empty-compare"
                      src={lightMode ? stockcomparelight : stockcomapredark}
                      alt=""
                    ></img>
                  </div>
                ) : (
                  <div
                    className={`Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col ${
                      lightMode ? "bg-white" : "bg-dark"
                    }`}
                  >
                    <div className=" d-flex w-100 flex-col align-items-center">
                      {svgSheet.InitialImageL}

                      <div
                        className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                          lightMode
                            ? "stock-comapre-input-light"
                            : "stock-comapre-input-dark"
                        }`}
                      >
                        <AutoComplete
                          className={
                            lightMode
                              ? "border1px-light-mode"
                              : "border1px-dark-mode antd-Selete-Custom-dark mb-10 "
                          }
                          style={{
                            width: "100%",
                          }}
                          notFoundContent="No Stocks"
                          onFocus={() => getResults("")}
                          allowClear={true}
                          placeholder=" Select Stock"
                          options={dropdowndata}
                          dropdownClassName={`${
                            !lightMode && "drop-down-stock invert-text"
                          }`}
                          value={InputDataisOnChange.thirdvalue}
                          onChange={(e) => {
                            getResults(e);
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              thirdvalue: e,
                            });
                            e?.length === 0 &&
                              setInputFullName({
                                ...InputFullName,
                                thirdvalue: "",
                              });
                            setInputdatais({ ...Inputdatais, thirdvalue: "" });
                          }}
                          defaultActiveFirstOption={true}
                          onSelect={(e, options) => {
                            setInputdatais({ ...Inputdatais, thirdvalue:isNaN(e) ? e.replace("&","%26"):e});
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              thirdvalue: options?.label,
                            });
                            setInputFullName({
                              ...InputFullName,
                              thirdvalue: options?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {auth?.userData?.user?.plan?.planId === "1month" ||
                auth?.userData?.user?.plan?.planId === "basic" ||
                auth?.userData?.user?.plan?.planId === "3months" ? (
                  <div className="Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col">
                    {" "}
                    <p className="absolute " style={{ width: "40px" }}>
                      {" "}
                      {svgSheet?.premiumIcon}{" "}
                    </p>{" "}
                    <p
                      className="absolute fs-s-16 fw-700 "
                      style={{ top: "77px" }}
                    >
                      Get Sharks Plan
                    </p>
                    <img
                      className="image-empty-compare"
                      src={lightMode ? stockcomparelight : stockcomapredark}
                      alt=""
                    ></img>
                  </div>
                ) : (
                  <div
                    className={`Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col ${
                      lightMode ? "bg-white" : "bg-dark"
                    }`}
                  >
                    <div className=" d-flex w-100 flex-col align-items-center">
                      {svgSheet.InitialImageL}

                      <div
                        className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                          lightMode
                            ? "stock-comapre-input-light"
                            : "stock-comapre-input-dark"
                        }`}
                      >
                        <AutoComplete
                          className={
                            lightMode
                              ? "border1px-light-mode"
                              : "border1px-dark-mode antd-Selete-Custom-dark mb-10 "
                          }
                          style={{
                            width: "100%",
                          }}
                          allowClear={true}
                          // {} === '1month' && disabled
                          //
                          // disabled={ auth?.userData?.user?.plan?.planId === ('1month' || 'basic') ? true : false}
                          onFocus={() => getResults("")}
                          placeholder=" Select Stock"
                          options={dropdowndata}
                          dropdownClassName={`${
                            !lightMode && "drop-down-stock invert-text"
                          }`}
                          value={InputDataisOnChange.forthvalue}
                          onChange={(e) => {
                            getResults(e);
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              forthvalue: e,
                            });
                            e?.length === 0 &&
                              setInputFullName({
                                ...InputFullName,
                                forthvalue: "",
                              });
                            setInputdatais({ ...Inputdatais, forthvalue: "" });
                          }}
                          defaultActiveFirstOption={true}
                          onSelect={(e, options) => {
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              forthvalue: options?.label,
                            });
                            setInputdatais({ ...Inputdatais, forthvalue:isNaN(e) ? e.replace("&","%26"):e });

                            setInputFullName({
                              ...InputFullName,
                              forthvalue: options?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {auth?.userData?.user?.plan?.planId === "1month" ||
                auth?.userData?.user?.plan?.planId === "basic" ||
                auth?.userData?.user?.plan?.planId === "12months" ||
                auth?.userData?.user?.plan?.planId === "3months" ||
                auth?.userData?.user?.plan?.planId === "6months" ? (
                  <div className="Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col">
                    {" "}
                    <p className="absolute " style={{ width: "40px" }}>
                      {" "}
                      {svgSheet?.premiumIcon}{" "}
                    </p>{" "}
                    <p
                      className="absolute fs-s-16 fw-700 "
                      style={{ top: "77px" }}
                    >
                      Get Whales Plan
                    </p>
                    <img
                      className="image-empty-compare"
                      src={lightMode ? stockcomparelight : stockcomapredark}
                      alt=""
                    ></img>
                  </div>
                ) : (
                  <div
                    className={`Stockcompare-stock-card p-10  relative w-100 m-10px d-flex align-items-center justify-content-center flex-col ${
                      lightMode ? "bg-white" : "bg-dark"
                    }`}
                  >
                    <div className=" d-flex w-100 flex-col align-items-center">
                      {svgSheet.InitialImageL}

                      <div
                        className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                          lightMode
                            ? "stock-comapre-input-light"
                            : "stock-comapre-input-dark"
                        }`}
                      >
                        <AutoComplete
                          className={
                            lightMode
                              ? "border1px-light-mode"
                              : "border1px-dark-mode antd-Selete-Custom-dark mb-10 "
                          }
                          style={{
                            width: "100%",
                          }}
                          allowClear={true}
                          onFocus={() => getResults("")}
                          placeholder=" Select Stock"
                          options={dropdowndata}
                          dropdownClassName={`${
                            !lightMode && "drop-down-stock invert-text"
                          }`}
                          value={InputDataisOnChange.fifthvalue}
                          // disabled={ auth?.userData?.user?.plan?.planId === '12months' || '1months' ? true : false}
                          onChange={(e) => {
                            getResults(e);
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              fifthvalue: e,
                            });
                            e?.length === 0 &&
                              setInputFullName({
                                ...InputFullName,
                                fifthvalue: "",
                              });
                            setInputdatais({ ...Inputdatais, fifthvalue: "" });
                          }}
                          defaultActiveFirstOption={true}
                          onSelect={(e, options) => {
                            setInputdatais({ ...Inputdatais, fifthvalue:isNaN(e) ? e.replace("&","%26"):e });
                            setInputDataIsOnChange({
                              ...InputDataisOnChange,
                              fifthvalue: options?.label,
                            });
                            setInputFullName({
                              ...InputFullName,
                              fifthvalue: options?.label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center"></div>
            <div className="d-flex align-items-center justify-content-center w-100">
              <div className="d-flex justify-content-center"></div>
              <div className=" mb-10 only-PC-view ">
                {isDisplay && EmptylessFullName.length >= 2 ? (
                  <button
                    onClick={makeReset}
                    className={` fw-500 fs-s-14 w-200px btn-bg-primary animation-button text-white pointer br-3 p-10 
                   
                  `}
                  >
                    Reset
                  </button>
                ) : (
                  <button
                    onClick={() => (
                      onSubmit(),
                      removedEmptyData?.length < 2
                        ? setSetValidation(true)
                        : setSetValidation(false)
                    )}
                    className={` fw-500 fs-s-14 w-200px btn-bg-primary text-white animation-button  pointer br-3 p-10 `}
                  >
                    Compare Stocks
                  </button>
                )}
              </div>
            </div>
          </div>
          {isDisplay ? (
            <div className="">
              <MainStockComparePage
                lightMode={lightMode}
                Inputdatais={Inputdatais}
                EmptylessFullName={EmptylessFullName}
                dropdowndata={InputDataisOnChange}
                navBarData={navBarData}
                stockcomapredark={stockcomapredark}
                plan={PlanName}
                TreandingStocks={TreandingStocks}
              />
            </div>
          ) : (
            <div>
              <div>
                <TreandingStockSC
                  setInputDataIsOnChange={setInputDataIsOnChange}
                  setIsDisplay={setIsDisplay}
                  lightMode={lightMode}
                  setInputdatais={setInputdatais}
                  setTreandingStocks={setTreandingStocks}
                  setCountOfInputs={setCountOfInputs}
                  setInputFullName={setInputFullName}
                />
              </div>

              <TrendingStocks lightMode={lightMode} />
            </div>
          )}
        </div>
      </div>
      {isDisplay && (
        <MobileBottomBar navData={navBarData} lightMode={lightMode} />
      )}
    </div>
  );
}

export default StocksCompare;
