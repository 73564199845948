import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { getNewsSymbolsData, getStockLTP } from "../../api/fetchClient";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function NewsComponent({
  lightMode,
  width,
  height,
  title,
  img,
  url,
  id,
  isHeader,
  showTicker=true
}) {

  const [changePrice, setChangePrice] = useState();
  const [Compname, setCompname] = useState();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    setloader(true);
    getNewsSymbolsData(id).then((res) => {
      setCompname(res?.data?.name);
      getStockLTP(res?.data?.type, res?.data?.symbol).then((res) => {
        setChangePrice(res?.data[0].close);
        setloader(false);
      }).catch((err)=>console.log(err))
    }).catch((err)=>console.log(err))
  }, [id]);
  const defaultImg =
    "https://tradebrains.in/features/wp-content/uploads/2022/07/Portal-Logo-Cover-Image.jpg";

  const convertTitleUnicode = title
    ?.replaceAll("&#8217;", "‘")
    .replaceAll("#038;", "")
    .replaceAll("&#8216;", "")
    .replaceAll(";", ",");

  return (
    <div className="mb-20">
      {!title ? <Skeleton /> : <div>
      <a href={url} target="_blank">
        <img
          width={"100%"}
          height={height}
          style={{ objectFit: "cover" }}
          className="news-image-hover br-5"
          src={img ? img : defaultImg}
        ></img>

        <div className="h-40-64">
          <p
            style={{ fontSize: isHeader && "18px" }}
            className={`fw-500 mb-0 mt-15 overFlow-text `}
          >
            {convertTitleUnicode}
          </p>
        </div>
        {changePrice &&showTicker&& (
          <div
            style={{ width: "fit-content" }}
            className={`d-flex p-5 mt-10 ${
              lightMode ? "bg-gray" : "bg-dark-gray"
            }`}
          >
            <p className="mb-0  fs-14-12  ">
              {Compname} - {"  "}
            </p>{" "}
            <p
              className="mb-0 ff-lato"
              style={{
                marginLeft: "2px",
                color: redGreenColorPicker(2445.89, lightMode),
              }}
            >
              ₹{changePrice}
            </p>
          </div>
        )}
      </a>
      </div>}
    </div>
  );
}

export default NewsComponent;
