import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function BankUpdateBanner({ bankStatus }) {
  const bankBanner = (data) => {
    switch (data) {
      case "approval":
        return (
          <Alert
            style={{ background: "rgba(116, 170, 250, 0.2)", border: "none" }}
            message={
              <div
                style={{ color: "#5A99F7" }}
                className="fw-600 fs-18-14 text-center"
              >
                Bank Details will be reviwed soon
              </div>
            }
            type="error"
            closable
          />
        );
      case "accepted":
        return (
          <Alert
            style={{ background: "rgba(65, 208, 131, 0.2)", border: "none" }}
            message={
              <div
                style={{ color: "#41D083" }}
                className="fw-600 fs-18-14 text-center"
              >
                Bank Details has been approved
              </div>
            }
            type="error"
            closable
          />
        );
      case "rejected":
        return (
          <Alert
            style={{ background: "#FFE5E5", border: "none" }}
            message={
              <div
                style={{ color: "#F82E2E", gap: "8px" }}
                className="fw-600 fs-18-14  d-flex justify-content-center"
              >
                <span>Bank Details has been Rejected. Please</span>
                <Link className="link-hover-underline" to="/contactus">
                  Contact Us
                </Link>
              </div>
            }
            type="error"
            closable
          />
        );
      case "pending":
        return (
          <Alert
            style={{ background: "rgba(116, 170, 250, 0.2)", border: "none" }}
            message={
              <div
                style={{ color: "#5A99F7" }}
                className="fw-600 fs-18-14 text-center"
              >
                Bank Details will be reviwed soon
              </div>
            }
            type="error"
            closable
          />
        );
      default:
        return (
          <Alert
            style={{ background: "rgba(224, 130, 130, 0.3)", border: "none" }}
            message={
              <Link to="/affiliate/user-data">
                <div
                  style={{ color: "#ef4a4a" }}
                  className="fw-600 fs-18-14 text-center"
                >
                  Update your bank details for payout option
                </div>
              </Link>
            }
            type="error"
            closable
          />
        );
    }
  };

  return <div>{bankBanner(bankStatus)}</div>;
}

export default BankUpdateBanner;
