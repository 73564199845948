import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getIndexconstitients } from "../../api/fetchClient";
import * as htmlToImage from "html-to-image";
import svgSheet from "../../assets/svg/svgSheet";
import HeatMap from "../../components/Charts/heatMap/HeatMap";
import HeatmapRange from "../../components/Charts/heatMap/HeatmapRange";
import NoData from "../../components/NoData";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import DotLoader from "../../components/spinners/DotLoader";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import IndexDropdown from "../gainresAndLosers/component/IndexDropdown";

function ViewAllHeatmap({setisLoginPage}) {
  const { indexname } = useParams();
  const navigate = useNavigate();
  const { lightMode } = useSelector(getThemeMode);
  const [heatmapData, setheatmapData] = useState([]);
  const [filterdData, setfilterdData] = useState([]);
  const [activeIndex, setactiveIndex] = useState({
    symbol: indexname,
    label: indexname,
  });
  const [pageLoang, setpageLoang] = useState(false);
  const [activeRange, setactiveRange] = useState("NONE");
  const [watermarkstats, setwatermarkstats] = useState(false);
  useEffect(() => {
    setisLoginPage(false);
  }, []);
  useEffect(() => {
    const getData = async () => {
      setpageLoang(true);
      await getIndexconstitients({
        indexname: activeIndex.symbol,
        page: 1,
        sortBy: "per_change",
        sortHead: true,
        page: { perPage: 5000 },
      })
        .then((res) => {
          setheatmapData(res);
          setfilterdData(res?.results);
          setpageLoang(false);
        })
        .catch((err) => setpageLoang(false));
    };
    getData();
  }, [activeIndex]);
  useEffect(() => {
    navigate(`/index/${activeIndex.symbol}/heatmap`);
  }, [activeIndex]);

  const colorCodeData = [
    {
      label: "Below -5%",
      bgColor: "rgb(161 18 18)",
      onFilter: (key) => key < -5,
    },
    {
      label: "-5  to -2 %",
      bgColor: "rgb(201, 30, 45)",
      onFilter: (key) => key < -2 && key >= -5,
    },
    {
      label: "-2  to 0 %",
      bgColor: "rgb(245, 128, 139)",
      onFilter: (key) => key < 0 && key >= -2,
    },
    {
      label: "0%",
      bgColor: "#858383",
      onFilter: (key) => key === 0,
    },
    {
      label: "0  to +2 %",
      bgColor: "rgb(119 191 138)",
      onFilter: (key) => key > 0 && key <= 2,
    },
    {
      label: `+2  to  +5 %`,
      bgColor: "#119F57",
      onFilter: (key) => key > 2 && key <= 5,
    },
    {
      label: "Above  +5%",
      bgColor: "rgb(2 122 46)",
      onFilter: (key) => key > 5,
    },
  ];
  const indexOnchange = (data) => {
    setactiveIndex(data);
    setactiveRange("NONE");
  };
  const onStocksFilter = (index) => {
    setactiveRange(index);
    if (index === "NONE") {
      return setfilterdData(heatmapData?.results);
    } else {
      const filterArr = heatmapData?.results?.filter((item, i) =>
        colorCodeData[index]?.onFilter(item.per_change)
      );
      setfilterdData(filterArr);
    }
  };
  const downloadToPNG = () => {
    const mapRef = document.getElementById("my-heatmap");
    htmlToImage.toPng(mapRef,{ cacheBust: true }).then(function (dataUrl) {
      var el = document.createElement("a");
      el.setAttribute("href", dataUrl);
      // ?x-request=download
      el.setAttribute("download", `${indexname}-heatmap.png`);
      document.body.appendChild(el);
      el.click();
      el.remove();
    });
  };
  if (pageLoang) {
    return <DotLoader />;
  }
 
  return (
    <>
      <ReactHelmet
        title={`${indexname} Heatmap -  Track Daily Market movements | Trade Brains Portal`}
        desc="Track the daily market movements using Stock Heatmap by Trade Brains Portal. Find out a bird-eye view of how the market is performing today."
      />

      <div className="ff-poppins max-w mx-auto  px-15  w-100">
        <div className="my-body">
          <h1 className="mb-20 fs-36-20 fw-700">Stock Heatmap</h1>
          <div className="flex flex-col-row justify-lg-between align-lg-center">
            <div className="flex align-items-center">
              <IndexDropdown
                lightMode={lightMode}
                // data={sectorName}
                navigate={indexOnchange}
                activeValue={activeIndex}
              />
              <p
                className="mb-10 pointer"
                onClick={async () => {
                  await setwatermarkstats(true);
                  setTimeout(() => {
                    downloadToPNG();
                    setwatermarkstats(false);
                  }, 1000);
                }}
                style={{ width: "25px", height: "25px" }}
              >
                {svgSheet.screeshortIcon}
              </p>
            </div>
            <HeatmapRange
              labelData={colorCodeData}
              onStocksFilter={onStocksFilter}
              activeRange={activeRange}
            />
          </div>
          {filterdData?.length > 0 ? (
            <div
              className="scroll "
              style={{ overflowX: "scroll", minHeight: "18.5vh" }}
            >
              <HeatMap mapdata={filterdData} watermarkstats={watermarkstats} index={indexname}/>
            </div>
          ) : (
            <div style={{ marginTop: "11vh" }}>
              <NoData errorText="No Stocks" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewAllHeatmap;
