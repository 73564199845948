import { useSelector } from "react-redux";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { getThemeMode } from "./redux/reducers/ThemeSlice";
import Routers from "./routers/Routers";

function App() {
  const { lightMode } = useSelector(getThemeMode);
  return (
    <HelmetProvider>
      <div className={`${lightMode ? "bg-white" : "invert-text"}`}>
        <Routers />
      </div>
    </HelmetProvider>
  );
}

export default App;
