import { Form, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserManagementUserData } from "../../../../../api/fetchClient";
import UserPermissionTable from "./UserPermissionTable";
import UserAboutSection from "../UserAboutSection";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";

// import PorrtfolioForm from "./PorrtfolioForm";

function EditUserManagement() {
  const params = useParams();
  const { state: urlState } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { lightMode } = useSelector(getThemeMode);
const userId = Number(params?.id)
  const [pagePermission, setPagePermission] = useState([]);
  const [userData, setuserData] = useState();
  const getIndividualData = async () => {
    const res = await getUserManagementUserData(Number(params?.id));
    console.log(res, "indi res");
    setPagePermission(res?.data?.permissions);
    setuserData(res?.data?.user_data[0]);
  };
  useEffect(() => {
   

    getIndividualData();
  }, [params?.id]);
  const onPermissionChange = (permissions) => {
    setPagePermission(permissions);
  };
  return (
    <div className="my-body px-30-0">
      <div>
        <UserAboutSection userData={userData} lightMode={lightMode} />
        {pagePermission?.length !== 0 && (
          <UserPermissionTable
          userData={userData}
            pagePermission={pagePermission}
            onPermissionChange={onPermissionChange}
            userId={userId}
            getIndividualData={getIndividualData}
          />
        )}
      </div>
    </div>
  );
}

export default EditUserManagement;
