import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThemeMode } from '../../../redux/reducers/ThemeSlice';
import numberWithCommas from '../../../utilityFn/numberWithCommas';
import { redGreenColorPicker } from '../../../utilityFn/redGreenColor';
import BottomDataSection from '../BottomDataSection';
import CalcInputFeilds from '../CalcInputFeilds';
import ResultContainerCalc from '../ResultContainerCalc';

function DhandoValuation() {
  const [GetRsult, setGetRsult] = useState([]);
  const [gry1, setGry1] = useState();
  const [gry2, setGry2] = useState();
  const [gry3, setGry3] = useState();
  const [dr, setDr] = useState();
  const [fcf, setFcf] = useState();
  const [ec, setEc] = useState();
  const [mc, setMc] = useState();
  const [tm, setTm] = useState();
  const [list1, setlist1] = useState();
  const [list2, setlist2] = useState();
  const [intrvalue, setIntrvalue] = useState(0);
  const [div, setDiv] = useState(0);
  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);
  const { lightMode } = useSelector(getThemeMode);
  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
      
    },
    plugins:{
        legend:{
            position:"bottom",
            labels:{
  
            }
        },
    },
  
    maintainAspectRatio: false
  }
  useEffect(() => {
    let fcflist = [];
    let pvFcfList = [];
    let intrinsicValue;
    console.log(fcflist, "fcflist");
    console.log(pvFcfList, "Pvfcflist");
    setlist1(fcflist);
    setlist2(pvFcfList);
    fcflist.push(GetRsult["fcf"] * (1 + Number(GetRsult["gry1"]) / 100));
    for (let i = 0; i < 2; i++) {
      fcflist.push(fcflist[i] * (1 + Number(GetRsult["gry1"]) / 100));
    }
    for (let i = 2; i < 5; i++) {
      fcflist.push(fcflist[i] * (1 + Number(GetRsult["gry2"]) / 100));
    }
    for (let i = 5; i < 9; i++) {
      fcflist.push(fcflist[i] * (1 + Number(GetRsult["gry3"]) / 100));
    }
    let getDhandhoFinalFcf = fcflist[fcflist.length - 1] * GetRsult["tm"];

    fcflist.push(getDhandhoFinalFcf);

    pvFcfList.push(Number(GetRsult["ec"]));

    for (let i = 0; i < 10; i++) {
      let a = fcflist[i] / (1 + Number(GetRsult["dr"]) / 100) ** (i + 1);
      pvFcfList.push(a);
    }
    pvFcfList.push(getDhandhoFinalFcf / (1 + Number(GetRsult["dr"]) / 100) ** 10);

    intrinsicValue = pvFcfList.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    });

    let getDhanoDiff = (Number(GetRsult["mc"]) / intrinsicValue - 1) * 100;

    console.log(intrinsicValue, "InstrinsValue");
    setIntrvalue(intrinsicValue);
    console.log(getDhanoDiff, "Diff");
    setDiv(getDhanoDiff);
    setShow(true);
  }, [GetRsult])
  
  const ResultHeder =[{
    "Intrinsic Value ":intrvalue ? (Number(intrvalue).toFixed(2)) : 0
  }]

  console.log("GetRsult",GetRsult);
  var data = {
    labels: list1?.map((items,i)=>{return i+1}),
    datasets: [
        {
            label: "Fcf",
            backgroundColor: "#6DB8FD",
            data: list1,
            
  
        },
        {
            label: "Pv",
            backgroundColor: "#1774FF",
            data: list2,
            
  
        },
       
    ]
  
  };
  
const result = list1?.map((items,i)=>{
  return{
    comp_name:i+1,
      Free_cash:items,
      Present_Value:list2[i+1]
  }
})


  const ResultColumns = [
      {
        title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
        dataIndex: "comp_name",
        fixed: "left",
        width: "60px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <div
                className="fs-s-14 link-hover-underline ff-poppins"
                style={{ color: lightMode ? "black" : "white" }}
              >
                {text}
              </div>
            ),
          };
        },
      },
      {
        title: <p className="fs-s-16 fw-600 mb-0">Free Cash Flow </p>,
        dataIndex: "Free_cash",
        width: "50px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text && "₹" + numberWithCommas(text)}
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fs-s-16 fw-600 mb-0">Present Value </p>,
        width: "50px",
        dataIndex: "Present_Value",
        render(text, record) {
          const textwithComma = Number(text).toFixed(2);
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  color: redGreenColorPicker(text, lightMode),
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {text >= 0 ? "+" : "-"} ₹{" "}
                {text > 0
                  ? numberWithCommas(Number(textwithComma))
                  : numberWithCommas(Math.abs(Number(textwithComma)))}
              </span>
            ),
          };
        },
      },
     
    ];
  const navigate = useNavigate()
  const formlist = [
    {name:"gry1",value:"Growth Rate in Years 1-3 (%)"},
    {name:"fcf",value:"FCF (in Mn/Cr)"},
    {name:"gry2",value:"Growth Rate in Years 4-6 (%)"},
    {name:"ec",value:"Excess Cash (in Mn/Cr)"},
    {name:"gry3",value:"Growth Rate in Years 7-10 (%)"},
    {name:"mc",value:"Market Cap (in Mn/Cr)"},
    {name:"dr",value:"Discount Rate (%)"},
    {name:"tm",value:"Terminal Multiple "},
  ];
  const valueData = [
      { key:"Current Market Cap ", value: Math.floor(GetRsult["mc"] ? GetRsult["mc"] : 0).toLocaleString("en-US") },
      { key:"Difference to IV", value:div ? div.toFixed(2).toLocaleString("en-US") + "%" : 0 },
      
    ];
    const resetValues = () =>{
      setIntrvalue(0)
      setlist1([])
      setGetRsult([])
      setlist2()
setDiv(0)
    }
    const bottomData = [{
      header:"What is Dhandho Calculator?",
      body:"This is an intrinsic value calculator used by ace Investor Mohnish Pabrai for the purpose of picking the right stock for investing purposes.This calculator has a series of checklists of factors that every investor must focus on before making an investment decision.Mohnish Pabroi says, “The Checklist is a wonderful tool and I think every investor must use it”"
    },
   ]
  return (
    <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
    <div className="my-30">
    <p className='pointer'  onClick={()=>navigate(-1)}>Calculators / Dhando Valuation</p>
      <p className="mb-0 fs-30-20 fw-700">Dhando Valuation</p>
      <p className='w-60-80'> This is an intrinsic value calculator used by ace Investor Mohnish Pabrai for the purpose of picking the right stock for investing purposes.
</p>
    </div>
    <div  className="mx-50-0 p-45-10 br-0 custom-input-dark">
    <div>
        <CalcInputFeilds resetValues={resetValues} setGetRsult={setGetRsult}  lightMode={lightMode} formlist={formlist}/>
    </div>
    <div>
        <ResultContainerCalc ResultHeder={ResultHeder} tableResult={result} tabelColumn={ResultColumns}  resultLise={valueData} chartOptions={options} chartData={data} lightMode={lightMode}/>
    </div>
    </div>  
    <div>
        <BottomDataSection bottomData={bottomData}/>
    </div>  
  </div>
  )
}

export default DhandoValuation