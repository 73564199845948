export const dateFrmater = (date) =>{
    const day = date.slice(0,2)
    const month = date.slice(2,6)
    const year = date.slice(6,10)
  
    const redated = year + month + day
  
    return redated
  }

//  conveting  date format  12-07-2022 = > 2022-08-02
