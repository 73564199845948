import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, DatePicker, Switch } from "antd";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { getSearchData } from "../../../api/fetchClient";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";

function TestInput({ lightMode }) {
  const dateFormat = ["DD-MM-YYYY", "DD-MM-YYYY"];
  const [inputData, setinputData] = useState({
    dataDuration: "YoY",
    startDate: null,
    endDate: null,
    amount: 100000,
  });
  const [selectedStocks, setselectedStocks] = useState([
    {
      stock: "",
      portfolio1: 0,
      portfolio2: 0,
      portfolio3: 0,
    },
    {
      stock: "",
      portfolio1: 0,
      portfolio2: 0,
      portfolio3: 0,
    },
    {
      stock: "",
      portfolio1: 0,
      portfolio2: 0,
      portfolio3: 0,
    },
    {
      stock: "",
      portfolio1: 0,
      portfolio2: 0,
      portfolio3: 0,
    },
  ]);
  const [stockList, setstockList] = useState([]);
  const changeInputData = (key, value) => {
    setinputData({ ...inputData, [key]: value });
  };
  function disabledDate(current) {
    // Can not select sundays and predfined days
    return (
      moment(current).day() === 0 ||
      moment(current).day() === 6 ||
      (current && current > moment().endOf("day"))
    );
  }
  const dateOnchange = (dateString, key) => {
    setinputData({ ...inputData, [key]: dateString });
  };
  const stockOnchangeObj = (value, key, index) => {
    const nextList = [...selectedStocks];
    for (let i in nextList) {
      if (i == index) {
        nextList[i][key] = value;
        break; //Stop this loop, we found it!
      }
    }
    setselectedStocks(nextList);
  };
  // send search input API
  const getResults = async (e) => {
    // setinputData(e.target.value);
    await getSearchData(e)
      .then((resp) => {
        setstockList(resp.data);
      })
      .catch((err) => console.log(err));
  };
  const dropdowndata = useMemo(() => {
    const uniqueArr = [];
    stockList?.map((items) => {
      items.type !== "index" &&
        !selectedStocks?.map((item) => item.stock)?.includes(items?.symbol) &&
        uniqueArr.push({
          // value: isNaN(items.symbol) ? items.symbol : items.symbol,
          value: items.symbol,
          label: items.company,
        });
    });
    return uniqueArr;
  }, [stockList, selectedStocks]);
  // add stock rows
  const addStockRow = () => {
    setselectedStocks((prev) => [
      ...prev,
      {
        stock: "",
        portfolio1: 0,
        portfolio2: 0,
        portfolio3: 0,
      },
    ]);
  };
  const deleteStockRow = (index) => {
    setselectedStocks((prev) => prev.filter((item, i) => i !== index));
  };

  const calcPortAllocation = useCallback(
    (key) => {
      console.log(selectedStocks);
      let totalValue = 0;
      selectedStocks?.forEach((item) => {
        totalValue = totalValue + item[key] ?? 0;
      });
      console.log(totalValue);
      if (totalValue <= 100) {
        return (
          <p
            className="mb-0"
            style={{ color: redGreenColorPicker(2, lightMode) }}
          >
            {totalValue?.toFixed(2)}
          </p>
        );
      } else {
        return (
          <p
            className="mb-0"
            style={{ color: redGreenColorPicker(-2, lightMode) }}
          >
            Total allocation should add up to 100%
          </p>
        );
      }
    },
    [selectedStocks]
  );

  console.log(selectedStocks);
  return (
    <div id='Overview'>
      <div
        style={{ minHeight: "140px" }}
        className={`${lightMode ? "bg-gray" : "bg-dark-gray"} p-10 br-5`}
      >
        <div className="flex">
          <Switch
            defaultChecked
            onChange={(checked) =>
              changeInputData("dataDuration", checked ? "YoY" : "MoM")
            }
          />
          <span className="fw-500">
            {inputData.dataDuration === "YoY"
              ? "Year to Year"
              : "Month to Month"}
          </span>
        </div>
        {/* date section  */}
        <div className="my-30 flex flex-col-row justify-lg-between">
          <div className="flex align-items-center justify-md-between">
            <span className="fw-500">Start Date</span>
            <DatePicker
              style={{ width: "130px", height: "35px" }}
              format={dateFormat}
              disabledDate={disabledDate}
              inputReadOnly={true}
              className={!lightMode && "DatePicker-Antd-Dark"}
              value={
                inputData?.startDate
                  ? moment(inputData?.startDate, dateFormat)
                  : undefined
              }
              dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
              onChange={(date, dateString) =>
                dateOnchange(dateString, "startDate")
              }
            />
          </div>
          <div className="flex align-items-center justify-md-between">
            <span className="fw-500">End Date</span>
            <DatePicker
              style={{ width: "130px", height: "35px" }}
              format={dateFormat}
              disabledDate={disabledDate}
              inputReadOnly={true}
              className={!lightMode && "DatePicker-Antd-Dark"}
              value={
                inputData?.endDate
                  ? moment(inputData?.endDate, dateFormat)
                  : undefined
              }
              dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
              onChange={(date, dateString) =>
                dateOnchange(dateString, "endDate")
              }
            />
          </div>
          <div className="flex align-items-center justify-md-between">
            <span className="fw-500">Initial Amount</span>
            <div
              className={`${
                lightMode
                  ? "border1px-light-mode bg-white"
                  : "border1px-dark-mode"
              }`}
            >
              <span
                className={`ff-lato ml-5 ${
                  lightMode ? "bg-white" : "bg-transparent"
                }`}
              >
                ₹
              </span>
              <input
                onChange={(e) =>
                  setinputData({ ...inputData, amount: e.target.value })
                }
                value={inputData.amount}
                className={`br-3 ${lightMode ? "bg-white" : "bg-transparent"} `}
                style={{ maxWidth: "115px", height: "35px", border: "none" }}
                type="number"
              />
            </div>
          </div>
        </div>
        <p className="fs-s-12 text-align-end mb-0">
          *Stock Price Data Available Since 2010
        </p>
        {/* date section  */}
      </div>
      {/* stock data inputs */}
      <div className="mt-40 mb-20 flex flex-col-row ">
        <div className="w-100">
          <h4 className="fs-s-16 fw-600 mb-20">Stocks</h4>
          <div style={{ gap: "20px" }} className="d-flex flex-col">
            {selectedStocks?.map((data, i) => (
              <div key={i}>
                <AutoComplete
                  allowClear={true}
                  style={{
                    width: "100%",
                    minWidth: "180px",
                    borderRadius: "3px",
                  }}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  className={
                    lightMode
                      ? "border1px-light-mode"
                      : "border1px-dark-mode antd-Selete-Custom-dark"
                  }
                  onFocus={() => getResults("")}
                  value={[selectedStocks[i]?.stock]}
                  options={dropdowndata}
                  placeholder=" select stock"
                  onChange={(e) => {
                    getResults(e);
                    stockOnchangeObj(e, "stock", i);
                  }}
                  notFoundContent="No Stocks"
                  defaultActiveFirstOption={true}
                  onSelect={(e, options) => {
                    stockOnchangeObj(e, "stock", i);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {/* portfolio 1 */}
        <div className="w-100">
          <h4 className="fs-s-16 fw-600 mb-20">Portfolio 1</h4>
          <div style={{ gap: "20px" }} className="d-flex flex-col">
            {selectedStocks?.map((item, i) => (
              <div
                key={i}
                style={{ minWidth: "178px", height: "34px" }}
                className={`d-flex align-items-center br-3 ${
                  lightMode ? "border1px-light-mode" : "border1px-dark-mode"
                }`}
              >
                <input
                  className={` bg-transparent w-100`}
                  style={{ border: "none" }}
                  type="number"
                  defaultValue={0}
                  value={selectedStocks[i]?.portfolio1}
                  onChange={(e) =>
                    stockOnchangeObj(e.target.valueAsNumber, "portfolio1", i)
                  }
                />
                <span className="mr-5">%</span>
              </div>
            ))}
          </div>
          <div className="mt-10 text-center">
            {calcPortAllocation("portfolio1")}
          </div>
        </div>
        {/* portfolio 1 */}
        {/* portfolio 2 */}
        <div className="w-100">
          <h4 className="fs-s-16 fw-600 mb-20">Portfolio 2</h4>
          <div style={{ gap: "20px" }} className="d-flex flex-col">
            {selectedStocks?.map((item, i) => (
              <div
                key={i}
                style={{ minWidth: "178px", height: "34px" }}
                className={`d-flex align-items-center br-3 ${
                  lightMode ? "border1px-light-mode" : "border1px-dark-mode"
                }`}
              >
                <input
                  className={` bg-transparent w-100`}
                  style={{ border: "none" }}
                  type="number"
                  defaultValue={0}
                  value={selectedStocks[i]?.portfolio2}
                  onChange={(e) =>
                    stockOnchangeObj(e.target.valueAsNumber, "portfolio2", i)
                  }
                />
                <span className="mr-5">%</span>
              </div>
            ))}
          </div>
          <div className="mt-10  text-center">
            {calcPortAllocation("portfolio2")}
          </div>
        </div>
        {/* portfolio 2 */}
        {/* portfolio 3 */}
        <div className="w-100">
          <h4 className="fs-s-16 fw-600 mb-20">Portfolio 3</h4>
          <div style={{ gap: "20px" }} className="d-flex flex-col">
            {selectedStocks?.map((item, i) => (
              <div className="flex align-items-center" key={i}>
                <div
                  style={{ minWidth: "178px", height: "34px", flexGrow: 1 }}
                  className={`d-flex align-items-center br-3 ${
                    lightMode ? "border1px-light-mode" : "border1px-dark-mode"
                  }`}
                >
                  <input
                    className={` bg-transparent w-100`}
                    style={{ border: "none" }}
                    type="number"
                    defaultValue={0}
                    value={selectedStocks[i]?.portfolio3}
                    onChange={(e) =>
                      stockOnchangeObj(e.target.valueAsNumber, "portfolio3", i)
                    }
                  />
                  <span className="mr-5">%</span>
                </div>
                <div className="pointer" onClick={() => deleteStockRow(i)}>
                  <CloseOutlined
                    style={{ color: redGreenColorPicker(-3, lightMode) }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10  text-center">
            {calcPortAllocation("portfolio3")}
          </div>
        </div>
        {/* portfolio 3*/}
      </div>
      <div className="flex ">
        <div
          onClick={addStockRow}
          className="w-20 h-20 pointer btn-bg-primary d-flex div-items-center br-50"
        >
          <PlusOutlined className="text-black" />
        </div>
        <span onClick={addStockRow} className="fw-500 pointer">
          Add Stocks
        </span>
      </div>
      <div className="flex my-20">
        <button
          style={{ width: "100px" }}
          className="  h-45 btn-bg-primary text-white br-3 fw-600  fs-16-14"
        >
          Backtest
        </button>
        <button
          style={{ height: "45px", width: "100px" }}
          className={`px-3 ${
            lightMode ? "text-btn-light" : "text-btn-dark"
          }  btn-blue-border`}
        >
          Reset Value
        </button>
      </div>
      {/* stock data inputs */}
    </div>
  );
}

export default TestInput;
