
import React from "react";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import loadergif from "../../assets/images/loadergif/Free-Content.gif";
import { Link } from "react-router-dom";

function TopIndexTable({
  tableContent,
  lightMode,
  onSelectFn,
  activeSymbol,
  isLoading,
}) {
  if (isLoading) {
    return (
      <div className="d-flex div-items-center w-100 h-100">
        <img className="h-50px w-50px" src={loadergif} alt="load" />
      </div>
    );
  }
  return (
    <div>
      <h3 className="fs-s-18 fw-700 h-100 mb-10">Index</h3>
      <table className="w-100 h-100 ">
        {tableContent?.map((content, i) => (
          <tr key={i}
            onClick={() => onSelectFn(content.symbol)}
            style={{
              background:
                activeSymbol === content.symbol &&
                (lightMode
                  ? "rgba(211, 232, 251, 0.35)"
                  : "rgba(167, 182, 196, 0.24)"),
            }}
            className={`pointer  ${
              lightMode ? "hover-light-blue" : "bg-dark-hover"
            }`}
          >
            <td style={{ height: "44px" }} className="fw-500 pl-5px">
              <Link to={`/Index/${content.symbol}`} className='link-hover-underline' >
              {content.symbol}
              </Link>
            </td>
            <td className="ff-lato">{content.value}</td>
            <td
              style={{
                color: redGreenColorPicker(content.per_change, lightMode),
              }}
            >
              {content.per_change}%
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default TopIndexTable;
