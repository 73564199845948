import { Form, Input, message } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { postFeedbackApi } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import PremiumContactCard from "./PremiumContactCard";

function PremiumSupportIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const { userData } = useSelector(authStore);
  const [contactForm] = Form.useForm();
  const [loadingState, setloadingState] = useState(false);

  const onFeedbackSubmit = async (values) => {
    if(loadingState) return
    const apiData = { ...values, type: "Premium user" };
    console.log(apiData);
    setloadingState(true);
    await postFeedbackApi(apiData)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 201) {
          message.success("Thank you for contacting us.");
          setloadingState(false);
        } else {
          message.error("Something went wrong.Try again later!");
          setloadingState(false);
        }
      })
      .catch((err) => {
        setloadingState(false);
      });
    contactForm.resetFields();
  };
  const contactData =
    userData?.user?.plan?.sname === "Whales"
      ? [
          {
            icon: svgSheet.messageIcon,
            desc: (
              <>
                Send your query in detail
                <br /> to our support email
              </>
            ),
            contact: "premiumsupport@tradebrains.in",
            contactType: "mailto",
            contactInfo:
              "All your queries will be resolved within 24 working hours.",
          },
          {
            icon: svgSheet.callIcon,
            desc: (
              <>
                Call our support team for
                <br /> further clarification
              </>
            ),
            contact: "+91 72040 62492",
            contactType: "tel",
            contactInfo: "Call timings : Monday To Friday (10AM to 7PM).",
          },
        ]
      : [
          {
            icon: svgSheet.messageIcon,
            desc: (
              <>
                Send your query in detail
                <br /> to our support email
              </>
            ),
            contact: "premiumsupport@tradebrains.in",
            contactType: "mailto",
            contactInfo:
            "All your queries will be resolved with in 24 working hours.",
          },
        ];

  return (
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <h1 className="mb-20 fs-36-20 fw-700">Premium Support</h1>
        <section className="flex  flex-col-row-reverse">
          <div className="w-100-50 d-flex gap-2r flex-col">
            {contactData?.map((card) => (
              <PremiumContactCard lightMode={lightMode} data={card} />
            ))}
          </div>
          <div className="w-100-50">
            <Form
              className={`d-flex align-items-center flex-col py-20 ${
                lightMode
                  ? "bg-gray card-drop-light-shadow"
                  : "bg-gray card-drop-dark-shadow"
              }`}
              form={contactForm}
              name="contact"
              autoComplete="off"
              onFinish={onFeedbackSubmit}
              scrollToFirstError
            >
              <Form.Item
                style={{ margin: "0px 0px 6px 0px" }}
                name="name"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    type: "name",
                    message: "Please Enter your Name",
                  },
                  {
                    required: true,
                    message: "Please Enter your Name",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Name*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="email"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please Enter your E-mail!",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Email*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="phone_no"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    pattern: /^[\d]{0,12}$/,
                    message: "Value should be less than 12 character",
                  },
                ]}
              >
                <Input
                  type="number"
                  maxLength={13}
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Phone Number"
                />
              </Form.Item>

              <Form.Item
                style={{ margin: "6px 0px" }}
                name="subject"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Subject",
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-gray email-input-login-page"
                  }`}
                  placeholder="Subject*"
                />
              </Form.Item>
              <Form.Item
                style={{ margin: "6px 0px" }}
                name="message"
                className={`w-90 ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Message",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={250}
                  type="text"
                  rows={8}
                  style={{
                    height: "150px",
                    width: "100%",
                    paddingInline: "0px",
                  }}
                  className={`w-100  auth-form-input  ${
                    lightMode
                      ? "bg-gray ant-textarea-light "
                      : "bg-dark-gray  ant-textarea-dark"
                  }`}
                  placeholder="Message*"
                />
              </Form.Item>
              <div className="w-90 ">
                {loadingState ? (
                  <button
                    style={{ width: "120px" }}
                    className=" btn-bg-primary h-50px br-5 text-white fw-500"
                  >
                    Sending...
                  </button>
                ) : (
                  <button
                    style={{ width: "170px" }}
                    type="submit"
                    className=" btn-bg-primary h-50px br-5  text-white fw-600"
                  >
                    Send Your Message
                  </button>
                )}
              </div>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PremiumSupportIndex;
