import React from "react";
import FAQSection from "./FAQSection";

function HomeFaq({ lightMode }) {
  const faqData = [
    {
      head: `What is Tradebrains Portal?`,
      content: (
       
          <p>
            Portal is a stock research website offered by Trade Brains. This is
            built to help investors make efficient stock research and analysis
            by providing quality fundamental data with insightful visuals.
            <br /> We have built Trade Brains Portal with the simplest UI for
            the ease of stock research of our users. With Trade Brains Portal,
            you can find all the important financial information of stocks along
            with multiple tools to research and analyze companies, all in one
            place.
          </p>
       
      ),
    },
    {
      head: `How to use Tradebrains Portal? `,
      content: (
        <>
          <p>
            "Using Trade Brains Portal, you can perform the complete fundamental
            analysis of any stock listed in the Indian stock market.
            <br /> Here, you can find all the essential financial details of
            stock, charts to analyze the financial trends, check the
            pre-calculated financial ratios, analyze financial statements on
            quarter and annual results P&L Statements, Balance Sheet, Cashflow
            statement; compare with peers, get information on the shareholding
            pattern and other key stock analysis matrices.
            <br /> To use Trade Brains Portal, simply search the company name in
            the TOP search bar, visit the company page and start looking into
            the company's financials. Further, if you need stock research ideas,
            you can use the other tools and products provided by us. "
          </p>
        </>
      ),
    },
    {
      head: `What are the benifits of subscribing to Trade Brains Portal premium plans?`,
      content: (
        <>
          <p>
            "We’re providing many premium products and tools for our users to
            simplify their stock research journey, which are not available to
            free users. Here the benefits that you’ll get after subscribing to
            any one of our PREMIUM plans:
            <br />
            All the features UNLOCKED
            <br />
            Access to our Stock Buckets (Our handpicked categorized stock lists)
            <br />
            Advanced screener with Unlimited Filters use (with export)
            <br />
            Compare up to Five Stocks at once in our stock comparison tool
            <br />
            Unlimited Backtesting on our Portfolio Backtesting tool (upcoming)
            <br />
            Import Data in Excel (upcoming) & Many More"
          </p>
        </>
      ),
    },
    {
      head: "Is Trade Brains Portal free?",
      content: (
        <>
          <p>
            Yes, Trade Brains provides complete fundamental analysis data to its
            user for free to do complete analysis, however it also offers few
            exclusive features to its premium users{" "}
          </p>
        </>
      ),
    },
    {
      head: "Which is the best Stock Screener in India?",
      content:
        "Trade Brains Portal is one of the best stock screener in India ",
    },
    {
      head: "What is Stock Portal?",
      content:
        "Stock Portal provides you with all the fundamental data along with complete tools to research and analyze companies. Trade Brains Portal is the best stock portal available for indian stock market ",
    },
    {
      head: "Which is best site for Stock Analysis?",
      content:
        "Stock analysis will require stock quotes, financial ratios, financial statements and many other key metrics to make a fair and correct investement decision. Trade Brains Portal is one of the best stock analysis website available for the Indian stock market ",
    },
    {
      head: "How to do Fundamental Analysis ?",
      content:
        "Fundamental analysis is the complete qualitative and quantitative analysis of the comapany based on the publically available data. You can perform fundamental analysis on Trade Brains Portal by reading the companys top information like financial ratios, financial statement, last 5yr factsheet and many more metrices using historical data and charts",
    },
  ];
  return (
    <section className="max-w flex flex-col  align-items-center  mx-auto px-15  w-100">
      <h3 className="fs-40-32 fw-700 mb-20 text-md-center ">
        Frequently asked Questions
      </h3>
      <div className="w-100-75 mb-75">
        <FAQSection data={faqData} lightMode={lightMode} />
      </div>
    </section>
  );
}

export default HomeFaq;
