import { Drawer } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import svgSheet from "../../assets/svg/svgSheet";
import { postScreenerData } from "../../redux/reducers/screenerSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import FilterIndex from "./filter/FilterIndex";

function MobOpenFilter({
  lightMode,
  filterTypeArr,
  setfilterTypeArr,
  postSectorArr,
  setpostSectorArr,
  postIndexArr,
  setpostIndexArr,
  columnsArr,
  setcolumnsArr,
  premiumModal,
  setpremiumModal,
  UserAuth,
}) {
  const dispatch = useDispatch();
  const [toggleFilter, settoggleFilter] = useState(false);
  console.log(toggleFilter);
  // clear all aplied filter
  const clearAllFilter = () => {
    const defaultColumns = ["PRICE", "returns_1y", "TTMPE", "YIELD"];
    setpostIndexArr([]);
    setpostSectorArr([]);
    setcolumnsArr(
      columnsArr.filter((item) => defaultColumns.includes(item.particular))
    );
    const defaultType = [
      {
        label: "Market Cap (Rs Cr)",
        firstValue: 0,
        secondValue: 2500000,
        particular: "MCAP",
        operator: "&",
        min: 0,
        max: 2500000,
        symbol: "rs",
        unit: "Cr",
      },
    ];
    setfilterTypeArr((prevState) => [
      {
        label: "Market Cap (Rs Cr)",
        firstValue: 0,
        secondValue: 2500000,
        particular: "MCAP",
        operator: "&",
        min: 0,
        max: 2500000,
        symbol: "rs",
        unit: "Cr",
      },
    ]);
    dispatch(
      postScreenerData({
        applyFilters: defaultType,
        industries: [],
        indices: [],
      })
    );
    settoggleFilter(false);
    //  postFilterToStore();
  };

  return (
    <div className="display-md ">
      <Drawer
        style={{ position: "fixed", top: 105+bannerOffsetCalc(UserAuth) }}
        className="ff-poppins"
        title={
          <div
            style={{ color: lightMode ? "black" : "white" }}
            className="d-flex justify-content-between"
          >
            <div className="flex">
              <div onClick={() => settoggleFilter(false)}>
                {svgSheet.doubleLeftArrow}
              </div>
              <p className="fw-700 fs-s-20 mb-0">Filters</p>
            </div>
            <button
              onClick={clearAllFilter}
              className={`fs-s-12 px-3 ${
                lightMode ? "text-btn-light" : "text-btn-dark"
              } btn-blue-border`}
            >
              Clear All
            </button>
          </div>
        }
        getContainer={false}
        //  drawerStyle={{maxWidth:'1440px',marginInline:'auto',padding:'0px'}}
        drawerStyle={{
          background: lightMode ? "white" : "#292E3F",
          color: lightMode ? "black" : "white",
        }}
        headerStyle={{
          background: lightMode ? "white" : "#292E3F",
          padding: "16px",
          borderBottom: "none",
        }}
        bodyStyle={{ padding: "0px" }}
        // title="Tools"
        placement={"right"}
        closable={false}
        onClose={() => settoggleFilter(false)}
        visible={toggleFilter}
        key="FILTER_OPEN"
        // height={"80vh"}
        zIndex={100}
        width="100%"
      >
        <FilterIndex
          lightMode={lightMode}
          filterTypeArr={filterTypeArr}
          setfilterTypeArr={setfilterTypeArr}
          postSectorArr={postSectorArr}
          setpostSectorArr={setpostSectorArr}
          postIndexArr={postIndexArr}
          setpostIndexArr={setpostIndexArr}
          settoggleFilter={settoggleFilter}
          columnsArr={columnsArr}
          setcolumnsArr={setcolumnsArr}
          premiumModal={premiumModal}
          setpremiumModal={setpremiumModal}
        />
      </Drawer>
      <div
        style={{ bottom: "0px", position: "sticky", zIndex: 10 }}
        className={`${
          lightMode ? "bg-gray" : "bg-dark-gray"
        } p-10 table-shadow   w-100`}
      >
        <button
          onClick={() => settoggleFilter(true)}
          className=" p-10 btn-bg-primary text-white br-3 fw-500  fs-s-12"
        >
          Open Filters
        </button>
      </div>
    </div>
  );
}

export default MobOpenFilter;
