import React from "react";
import svgSheet from "../../../../../assets/svg/svgSheet";
import JoinCard from "./JoinCard";

function AffiliateJoinSteps({ lightMode }) {
  const cardData = [
    {
      icon: svgSheet.userBlueIcon,
      head: "Sign Up",
      desc: "First & Foremost is to signing up with us and become an part of our affiliate team",
    },
    {
        icon: svgSheet.userGroupBlueIcon,
        head: "Share",
        desc: "Recommend Portal’s Features to your targeted audience. Paste your Affiliate URL in your websites, Youtube Channel, and Social Media Platforms ",
      },
      {
        icon: svgSheet.walletBlueIcon,
        head: "Earn",
        desc: "In the initial youll get to earn up to 10% for every purchase of any one of the subscription plans by your targeted audience",
      },
  ];
  return (
    <section className="max-w mx-auto px-15  w-100">
      <div className="d-flex flex-col align-items-center">
        <h1 className="fs-40-28 fw-700 mb-30 text-center">
          Join Portal’s <br /> Affiliate program
        </h1>
        <p className=" w-100-50 text-center">
          Be a part of our affliates and earn by recommending Portal’s Features
          to your targeted audience and converting those recommendations into a
          Successful purchases
        </p>
      </div>
      <div className="my-30 flex-col-row flex justify-content-between">
        {
            cardData?.map((card,i)=>(
                <JoinCard lightMode={lightMode} data={{...card,key:i+1}}/>
            ))
        }
       
      </div>
    </section>
  );
}

export default AffiliateJoinSteps;
