import axios from "axios";
import { authPost } from "./authClient";
import StockEndpoints from "./endPoints";
import { get, post, del, put, patch, delWithParams } from "./MainClient";
import { newsGet } from "./newsClient";
import moment from "moment";
import Search from "antd/lib/transfer/search";
import { logDOM } from "@testing-library/react";

//get search results
export const getSearchData = async (input) => {
  const searchResp = await get(`search?keyword=${input}&length=4`).then(
    (resp) => {
      return resp;
    }
  );
  return searchResp;
};

// post signup data

export const postSignupData = async (data) => {
  const SignupResp = await authPost(`rest-auth/registration/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return SignupResp;
};
// ..post login data
export const postLoginData = async (data) => {
  const loginResp = await authPost(`rest-auth/login/`, data).then((resp) => {
    return resp;
  });
  return loginResp;
};
//post forgot password data
// export const postForgoPassword = async (data) => {
//   const forgotResp = await authPost(`accounts/password/reset/`, data).then(
//     (resp) => {
//       return resp;
//     }
//   );
//   return forgotResp;
// };

export const postForgoPassword = async ({ value }) => {
  const forgotResp = await authPost(`rest-auth/password/reset/`, value).then(
    (resp) => {
      return resp;
    }
  );
  return forgotResp;
};
//post google login

export const postGoogleAuth = async (data) => {
  const googleResp = await authPost(`rest-auth/google/`, data).then((resp) => {
    return resp;
  });
  return googleResp;
};
export const postOnetapLogin = async (data) => {
  const googleResp = await authPost(`onetap/`, data).then((resp) => {
    return resp;
  });
  return googleResp;
};
// getstock basic data
export const getStockChange = async (index) => {
  const url = StockEndpoints.stockGraphData(
    "index",
    index,
    `days=1`,
    "&limit=1"
  );
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};
// get index graph data
export const getGraphData = async (index, i) => {
  const url = StockEndpoints.stockGraphData("index", index, `days=${i}`);
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};
export const getStockGraphData = async (url) => {
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};

//related news
export const getStockRelatedNews = async (url) => {
  const response = await newsGet(url).then((resp) => {
    return resp;
  });
  return response;
};
// get news date
export const getStockRelatedNewsDate = async (url) => {
  const response = await newsGet(url).then((resp) => {
    return resp;
  });
  return response;
};
//rget key metrics
export const getKeyMetrics = async (url) => {
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};
// get uset watchlists
export const getUserWatchlist = async () => {
  const url = StockEndpoints.userWatchlist;
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};
// add stock to watchlist
export const addToWatchlist = async (listname, data) => {
  const url = StockEndpoints.addStockToWatchlist(listname);
  const response = await post(url, data).then((resp) => {
    return resp;
  });
  return response;
};
//delete stock from watchlist
export const delStockFromWatchlist = async (activeTab, data) => {
  const response = await del(`watchlists/${activeTab}/`).then((resp) => {
    return resp;
  });
  return response;
};
// delete watchlist
export const delWatchlist = async (listName) => {
  const response = await del(StockEndpoints.addStockToWatchlist(listName)).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
// get stock last traded price
export const getStockLTP = async (type, symbol) => {
  const url = StockEndpoints.priceChange(type, symbol);
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};
export const getBucketdata = async () => {
  const response = await get("/buckets/").then((resp) => {
    return resp;
  });
  return response;
};
export const getBucketCompany = async (
  name,
  sortBy = "mcap",
  sortHead = false
) => {
  const response = await get(
    `/buckets/${name}?by=${sortBy}&ascending=${sortHead}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
// BalanceSheet
export const getBalanceSheet = async () => {
  const response = await get(`/stock/RELIANCE/standalone/balance-sheet`).then(
    (resp) => {
      return resp.data;
    }
  );
  return response;
};
//renamme watchlist name
export const PatchRenameWatchList = async ({ WatchList, data }) => {
  // const data = {new_name:'One',stocks:['RELIANCE']}
  const response = await patch(`watchlists/${WatchList}/`, data).then(
    (resp) => {
      return resp.data;
    }
  );
  return response;
};
//delete watch list data
export const DeleteWatchlist = async ({ WatchList }) => {
  const data = { stocks: ["BAJAJHLDNG", "RELIANCE"] };
  // fetch('watchlists/sanal12' , {
  // method: 'DELETE',
  //   })
  const response = await del(`watchlists/${WatchList}/`).then((resp) => {
    return resp;
  });
  return response;
};
//Clear watch list data
export const ClearWatchlist = async ({ WatchList, data }) => {
  // fetch('watchlists/sanal12' , {
  // method: 'DELETE',
  //   })
  const response = await delWithParams(`watchlists/${WatchList}/`, data).then(
    (resp) => {
      return resp.data;
    }
  );
  return response;
};
//create watchlist
export const CreateWatchlsit = async (data) => {
  const response = await post(`watchlists/`, data).then((resp) => {
    return resp.data;
  });
  return response;
};
export const getstockRedirectStatus = async (symbol) => {
  const response = await get(`stock/${symbol}/company-type/`).then((resp) => {
    return resp.data;
  });
  return response;
};
export const getIndexconstitients = async ({
  indexname,
  page,
  sortBy = "m_cap",
  sortHead = false,
}) => {
  const response = await get(
    `index/constitients/${indexname}/?page=${
      page.num ?? 1
    }&by=${sortBy}&ascending=${sortHead}&per_page=${page.perPage ?? 25}`
  ).then((resp) => {
    return resp.data;
  });
  return response;
};
export const getIndexreturns = async ({ indexname }) => {
  const response = await get(`/index-returns/${indexname}/`).then((resp) => {
    return resp.data;
  });
  return response;
};
export const getCorpActions = async (url) => {
  const response = await get(url).then((resp) => {
    return resp.data;
  });
  return response;
};

export const Indexperformance = async ({ indexname }) => {
  const response = await get(`/index/${indexname}/performance`).then((resp) => {
    return resp.data;
  });
  return response;
};
export const CompareStocksApi = async (CompanyType, removedEmptyData, year) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `stock/compare/${CompanyType}/?${urlStr}${year}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const GetUpdatedChanges = async (symbol, times) => {
  const response = await get(`stock/${symbol}/change/?${times}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

// stockComapre page returns api
export const GetReturnsComparestocks = async (removedEmptyData) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(`/stock/returns/compare/?${urlStr}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getNews = async (page, perpage, categoryId) => {
  const url = StockEndpoints.categoryNews(page, perpage, categoryId);

  const response = await newsGet(url).then((resp) => {
    return resp;
  });
  return response;
};

//getting finational graph data
export const getFinational = async (removedEmptyData, CompanyType) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `/stock/graphs/compare/${CompanyType}/?${urlStr}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getNewsSymbolsData = async (id) => {
  const response = await get(`/identify/${id}`).then((resp) => {
    return resp;
  });
  return response;
};

export const getProfitAndLossCompareStocks = async (
  removedEmptyData,
  selectd,
  CompanyType
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `/stock/prof/compare/${CompanyType}/?${urlStr}&${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getBalanceStocksCompareStocks = async (
  removedEmptyData,
  selectd,
  CompanyType
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `/stock/balancesheet/compare/${CompanyType}/?${urlStr}${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getCashFlowCompareStocks = async (
  removedEmptyData,
  CompanyType,
  selectd
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `/stock/cashflow/compare/${CompanyType}/?${urlStr}&${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getShareHoldingCompareStocks = async (
  removedEmptyData,
  selectd,
  CompanyType
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `stock/shareholding/compare/${CompanyType}/?${urlStr}&year=${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
// stock/shareholding/compare/standalone/?stocks=RELIANCE&stocks=ICICIBANK&stocks=ZOMATO&stocks=TCS&stocks=INDIGO
export const getQuaterlyCompareStocks = async (
  removedEmptyData,
  selectd,
  CompanyType
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `stock/quaterly/compare/${CompanyType}/?${urlStr}&year=${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getFactSheetCompareStocks = async (
  removedEmptyData,
  selectd,
  CompanyType
) => {
  let urlStr = "";
  removedEmptyData.forEach((element) => {
    urlStr += `stocks=${element}&`;
  });

  const response = await get(
    `stock/factsheet/compare/${CompanyType}/?${urlStr}${selectd}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

// get portfolioanalysis data
export const getPortfolioAnalysis = async () => {
  const response = await get(StockEndpoints.getPortfolioAnaltsis).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

//create portfolio analysis

export const CreatePortfolioAnalysis = async ({ data }) => {
  const response = await post(StockEndpoints.getPortfolioAnaltsis, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
//Update portfolio analysis
export const UpadatePortfolioAnalysis = async ({ updateAndDeleteid, data }) => {
  const response = await put(
    `update/portfolio/${updateAndDeleteid}/`,
    data
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const DeletePortfolioAnalysis = async ({ updateAndDeleteid }) => {
  const response = await del(`update/portfolio/${updateAndDeleteid}/`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const AddStockPortfolioAnalysis = async ({ data }) => {
  const response = await post("/add/stock/", data).then((resp) => {
    return resp;
  });
  return response;
};

export const getPortfolioHolding = async (portfolioid) => {
  // if(portfolioid.portfolioid === undefined ){
  //   return
  // }
  const response = await get(`/list/stock/${portfolioid}/`).then((resp) => {
    return resp;
  });
  return response;
};
// / /list/stock/5/

export const getCMP = async ({ Inputdatais, NewFormatOfDate }) => {
  const response = await get(
    `/portfolio/mcap/${Inputdatais}/?date=${NewFormatOfDate}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const removeStockFromportfolio = async (e) => {
  const response = await del(`/remove/stock/${e}/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getStockTransationPortfolio = async (e) => {
  if (e === undefined) {
    return;
  }
  const response = await get(`/transcation/stock/${e}/`).then((resp) => {
    return resp;
  });
  return response;
};

// remove/stock/4/

export const getEditPertiicullarTrasation = async ({
  perticularTrasationid,
  data,
}) => {
  const response = await put(
    `/transcation/edit/stock/${perticularTrasationid}/`,
    data
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getPortfolioAnalysisCardData = async ({ portfolioid }) => {
  if (portfolioid === undefined) {
    return;
  }
  // const response = await get(`/portfolio/value/${portfolioid}/`).then(
  const response = await get(`port_val/${portfolioid}/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getSizeIndustryAnalysisGraph = async ({ portfolioid }) => {
  if (portfolioid === undefined) {
    return;
  }
  const response = await get(`/industry/analysis/stock/${portfolioid}/`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getAllTradeRecordsdata = async (portfolioid, selectd1) => {
  const response = await get(
    `/traderecords/stock/${portfolioid}/?date=${selectd1}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getDividentApi = async (portfolioid) => {
  if (portfolioid === undefined) {
    return;
  }
  const response = await get(`/dividend/ratio/${portfolioid}/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getRelizedProfitPortfolio = async (portfolioid, date) => {
  const response = await get(
    `/realized-profit/${portfolioid}/?date=${date}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getMultiGraphProfitPortfolio = async (
  portfolioid,
  daysDuration
) => {
  if (portfolioid === undefined) return;
  const response = await get(
    // `portfolio/graph/${portfolioid}/?date=${daysDuration}`
    // `portfolio/graph/${portfolioid}/?date=${daysDuration}`
    // `portfolio/graph/${portfolioid}/${
    //   daysDuration !== "1m" ? `?date=${daysDuration}` : "daily/"
    // }`
    `new_graph_port/${portfolioid}/${`?date=${daysDuration}`}`
    // new_graph_port/415/
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const googleTapRedirect = (redir_link) => {
  const datastate = `redir_state:${redir_link}`;
  const scope =
    "https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile";
  const googleRes = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://admin.tradebrains.in/onetap/callback/&prompt=consent&response_type=token&client_id=1065016260363-bt80d75995gpcisjjq345bmfj8qjq49h.apps.googleusercontent.com&scope=${scope}&state=${datastate}`;

  return googleRes;
};

export const starTableReturns = async (portId, finCode) => {
  const url = StockEndpoints.starTableDrop(portId, finCode);
  const response = await get(url).then((resp) => {
    return resp.data;
  });
  return response;
};

export const getPlans = async () => {
  const response = await get(`/payments/plans`).then((resp) => {
    return resp;
  });
  return response;
};

export const postStockArr = async (arr) => {
  const response = await post(StockEndpoints.getstockArrCmp, {
    stocks: arr,
  }).then((resp) => {
    return resp;
  });
  return response;
};

export const getTrendingStocks = async () => {
  const response = await get(`/index/NIFTY/movers/?limit=8`).then((resp) => {
    return resp;
  });
  return response;
};

export const PostConfirmPassword = async ({ data }) => {
  const response = await authPost("rest-auth/password/confirm/", data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
// update userData
export const patchUserData = async (data) => {
  const response = await patch(`/profile/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const PostPlanSubScription = async (data) => {
  const response = await post("/payments/purchase/", data).then((resp) => {
    return resp;
  });
  return response;
};
// send callback
export const paymentAPI = async (token) => {
  const response = await get(StockEndpoints.paymentCallback(token)).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
// post feedback api
export const postFeedbackApi = async (data) => {
  const response = await post(StockEndpoints.postFeedback, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const postEmailActivation = async ({ email, id }) => {
  const response = await get(`/confirm-email/${email}/${id}`).then((resp) => {
    return resp;
  });
  return response;
};

export const getCouponsList = async ({ plan_id }) => {
  const response = await get(`/coupons/?plan_id=${plan_id}`).then((resp) => {
    return resp;
  });
  return response;
};

export const PostApplyCoupen = async ({ plan_id, CouponValue }) => {
  const response = await get(
    `/coupons/validate/?plan_id=all&code=${CouponValue}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const postAffiliateAPI = async (data) => {
  const response = await post(StockEndpoints.postAffiliate, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getAffiliateDashboardCard = async () => {
  const response = await get(`affiliate/dashboard/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getAffiliatesignupdata = async () => {
  const response = await get(`affiliate/signup/`).then((resp) => {
    return resp;
  });
  return response;
};
// affiliate/sales/?amount_min=1000&amount_max=100000&plan=12months
export const getAffiliateSales = async (Filter) => {
  const data = Filter.toString();
  const response = await get(
    `affiliate/sales/?${data.replaceAll(",", "&")}`
  ).then((resp) => {
    // ?${plan !== undefined && plan !== false&&`plan=${plan}`} ${amount !==undefined && `&${amount}`}
    return resp;
  });
  return response;
};
// affiliate/payout/?amount_min=1000&amount_max=100000&plan=12months
export const getAffiliatePayout = async (Filter) => {
  const data = Filter.toString();
  const response = await get(
    `affiliate/payout/?${data.replaceAll(",", "&")}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAffiliatePayoutTotal = async () => {
  const response = await get(`affiliate/payout/total_earnings/`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
// affiliate/signup/analysis/?search=Lisa
export const getAffiliateSignup = async () => {
  const response = await get(`affiliate/signup/analysis/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getAffiliateHelpCenter = async () => {
  const response = await get(`faq/?category=affiliate`).then((resp) => {
    return resp;
  });
  return response;
};

export const getAffiliateSugnuptotal = async () => {
  const response = await get(`affiliate/signup/total_signup/`).then((resp) => {
    return resp;
  });
  return response;
};

export const putAffiliateTermm = async (data) => {
  const response = await put(`/affiliate/termsaccept/`, data).then((resp) => {
    return resp;
  });
  return response;
};

// affiliate/signup/total_signup/

export const getAffiliateDashdata = async ({ duration }) => {
  if (duration === undefined) {
    return;
  }
  const response = await get(
    `/affiliate/dashboard/data/?month=${duration}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAffiliatePromotion = async () => {
  const response = await get(`/affiliate/promotion/images/ `).then((resp) => {
    return resp;
  });
  return response;
};

export const getDashboardGraph = async ({ value }) => {
  const response = await get(`affiliate/dashboard/graph/?search=${value}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const postReferAPI = async (postId, email) => {
  const url = StockEndpoints.referAffiliate(postId, email);
  const response = await post(url, {}).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminStages = async (State, Stages) => {
  const response = await get(
    `${Stages}/?${Stages === "stage-one" ? "is_verified" : "status"}=${State}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getSearchUserAdmin = async (input, State, Stages, dateString) => {
  // if(input === undefined){
  //   return
  // }
  //  const response = await get(`${Stages}/?status=${State}&search=${input}&min_date="2022-11-02"&max_date="2022-11-03"
  const response = await get(`${Stages}/?${
    Stages === "stage-one" ? "is_verified" : "status"
  }=${State}${input === undefined ? "" : `&search=${input}`}${
    dateString[0] === undefined ? "" : `&min_date=${dateString[0]}`
  }${dateString[0] === undefined ? "" : `&max_date=${dateString[1]}`}
       `).then((resp) => {
    return resp;
  });
  return response;
};

// export const getDateFilterUserAdmin = async (State,Stages,dateString) => {

//   // if(input === undefined){
//   //   return
//   // }
//   //  const response = await get(`${Stages}/?status=${State}&search=${input}&min_date="2022-11-02"&max_date="2022-11-03"
//    const response = await get(`${Stages}/?${Stages === "stage-one" ? "is_verified" : "status"}=${State}${input !== undefined `&search=${input}`}&min_date=${dateString[0]}&max_date=${dateString[1]}
//    `).then((resp) => {
//        return resp;
//      });
//      return response;
//    };

export const getCoundofAffiliate = async (Stages) => {
  const response = await get(`${Stages}/count/`).then((resp) => {
    return resp;
  });
  return response;
};
export const getReviewofAffilate = async (id, type) => {
  const response = await get(`${type}/review/${id}/`).then((resp) => {
    return resp;
  });
  return response;
};

export const updateDatailsAdmin = async (commision, type, id, status) => {
  const data = {
    verification: status?.status,
    commision: status?.commision ? status?.commision : commision?.commision,
  };
  const response = await put(`${type}/review/${id}/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const postAffiliateBank = async (data) => {
  const url = StockEndpoints.postAffiliateBankAPI;
  const response = await post(url, data).then((resp) => {
    return resp;
  });
  return response;
};
export const getAffiliateBank = async () => {
  const url = StockEndpoints.postAffiliateBankAPI;
  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};

export const getAllAffiliateData = async (Page, input, dateString) => {
  const response = await get(
    `/affiliate/admin/overvew/?page=${Page.page}&page_size=${Page.perPage}&${
      input !== undefined ? `&search=${input}` : ""
    }  ${dateString !== undefined ? `&max_date=${dateString[1]}` : ""} ${
      dateString !== undefined ? `&min_date=${dateString[0]}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAllAffiliateindividualData = async (id) => {
  const response = await get(
    `affiliate/admin/individual-affiliate/${id}/`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const postMailChimp = async (mail) => {
  const response = await post(`mailchimp/add/?email=${mail}`).then((resp) => {
    return resp;
  });
  return response;
};

export const updateCommitinAllaffiliate = async (commision, id) => {
  const data = { commision: commision?.commision };
  const response = await put(`stage-one/review/${id}/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminAffiliateSales = async (
  id,
  Filter,
  plan,
  commi,
  plandropdown
) => {
  const response = await get(
    `affiliate/admin/sales_performance/${id}/?${
      plandropdown !== undefined ? `&plan=${plandropdown}` : " "
    }
    ${
      Filter?.search !== undefined && Filter?.search.length !== 0
        ? `&search=${Filter?.search}`
        : ""
    } ${
      Filter?.mindate !== undefined && Filter?.mindate.length > 0
        ? `&min_date=${Filter?.mindate}`
        : ""
    } ${
      Filter?.maxdate !== undefined && Filter?.maxdate.length > 0
        ? `&max_date=${Filter?.maxdate}`
        : ""
    } ${
      Filter?.planvalue !== undefined && Filter?.planvalue.length > 0
        ? `&${plan}=${Filter?.planvalue}`
        : ""
    } ${
      Filter?.commi !== undefined && Filter?.commi > 0
        ? `&${commi}=${Filter?.commi}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminindividualAffiliatePayoutDetails = async () => {
  const response = await get(
    `affiliate/admin/individual-affiliate-payout/1`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminAffiliatePayoutDetails = async () => {
  const response = await get(`affiliate/admin/payout/`).then((resp) => {
    return resp;
  });
  return response;
};
export const getAllafiliateSalesPerformance = async (id, dateString) => {
  const response = await get(
    `affiliate/admin/sales_performance/data/${id}/${
      dateString === undefined
        ? ""
        : `?min_date=${moment(dateString[0]).format("YYYY-MM-DD")}`
    }${
      dateString === undefined
        ? ""
        : `&max_date=${moment(dateString[1]).format("YYYY-MM-DD")}`
    }
    `
  ).then((resp) => {
    return resp;
  });
  return response;
};

// affiliate/admin/sales_performance/data/1/?min_earnings=600&max_earnings=700&min_date=2022-10-28&max_date=2022-10-28&plan=6months&search=Maulika
export const getOverallAffiliateSales = async (
  Filter,
  revenue,
  commi,
  earnings,
  Page
) => {
  const response = await get(
    `affiliate/admin/overall-sales/?&page=${Page.page}&perPage=${Page.perPage}${
      Filter?.search !== undefined && Filter?.search?.length !== 0
        ? `&search=${Filter?.search}`
        : ""
    }${
      Filter?.revenue !== undefined && Filter?.revenue?.length > 0
        ? `&${revenue}=${Filter?.revenue}`
        : ""
    }${
      Filter?.earnings !== undefined && Filter?.earnings?.length > 0
        ? `&${earnings}=${Filter?.earnings}`
        : ""
    }${
      Filter?.commi !== undefined && Filter?.commi?.length > 0
        ? `&${commi}=${Filter?.commi}`
        : ""
    }${
      Filter?.mindate !== undefined && Filter?.mindate?.length > 0
        ? `&min_date=${Filter?.mindate}`
        : ""
    }${
      Filter?.maxdate !== undefined && Filter?.maxdate?.length > 0
        ? `&max_date=${Filter?.maxdate}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

// affiliate/admin/overall-sales/?search=MS&revenue_generated_min=1500&revenue_generated_max=1500&commision_max=10&commision_min=19&min_earnings=0&max_earnings=800

export const getAdmindashboardmovers = async ({ value, dateString }) => {
  const response = await get(
    `signups-dashboard-v2/?${value !== undefined ? `&filter=${value}` : ""}${
      dateString === undefined ? "" : `&min_date=${dateString[0]}`
    }${dateString === undefined ? "" : `&max_date=${dateString[1]}`}
    `
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminAffiliateReports = async (date) => {
  const response = await get(
    `affiliate/admin/report/slab/?${date !== undefined ? `date=${date}` : ""}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminAffiliateReportsgraph = async (dateString) => {
  const response = await get(
    `affiliate/admin/report/?${
      dateString !== undefined ? `start_date=${dateString[0]}` : ""
    }${dateString !== undefined ? `&end_date=${dateString[1]}` : ""} 
    `
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminaffiliateReportTopAffiliate = async () => {
  const response = await get(`affiliate/admin/top-affiliates/`).then((resp) => {
    return resp;
  });
  return response;
};

export const postAffiliatePromotionimage = async (data) => {
  const response = await post(`affiliate/admin/promotion-images/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getAffiliatePromotionimage = async () => {
  const response = await get(`affiliate/admin/promotion-images/`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
export const deleAffiliatePromotionImage = async (id) => {
  const data = {
    id: id,
  };
  const response = await delWithParams(
    `affiliate/admin/promotion-images/`,
    data
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const updateaffiliatepayoutstatus = async (id, setSelected) => {
  const data = {
    status: setSelected,
  };
  const response = await put(`affiliate/admin/payout/set/${id}/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getPayoutDetailsindividual = async (id, Filter) => {
  const response = await get(
    `affiliate/admin/individual-affiliate-payout/${id}/?${
      Filter?.search !== undefined ? `search=${Filter?.search}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminUserlisttabledata = async (Filter, Page) => {
  const response = await get(
    `users-dashboard/?${
      Page !== undefined ? `&page=${Page.page}` : ""
    }&page_size=${Page.perPage}${
      Filter?.search !== undefined && Filter?.search !== ""
        ? `&search=${Filter?.search}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getUserDetails = async (Filter, page, perPage, plan) => {
  const response = await get(
    `/users/admin/list/?&page=${page}&page_size=${perPage}${
      Filter?.search !== undefined && Filter?.search?.length !== 0
        ? `&search=${Filter?.search}`
        : ""
    }${
      Filter?.mindate !== undefined && Filter?.mindate?.length > 0
        ? `&joined_min_date=${Filter?.mindate}`
        : ""
    }${
      Filter?.maxdate !== undefined && Filter?.maxdate?.length > 0
        ? `&joined_max_date=${Filter?.maxdate}`
        : ""
    }&plan_name=${plan}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const excelCouterAPI = async (data) => {
  const url = StockEndpoints.excelCounter;
  const response = await post(url, data).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminUsrcarddata = async () => {
  const response = await get("users-dashboard/data").then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminTopData = async () => {
  const response = await get("/users/admin/top-data/").then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminusersGraph = async (Filter) => {
  const response = await get(
    `/users/signups/graph/?${
      Filter?.maxdate !== undefined && Filter?.mindate?.length !== 0
        ? `&end_date=${Filter?.maxdate}`
        : ""
    }${
      Filter?.mindate !== undefined && Filter?.mindate?.length !== 0
        ? `&start_date=${Filter?.mindate}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAffiliateQueries = async () => {
  const response = await get(`affiliate/admin/questions/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getTransactionDetails = async (id) => {
  const response = await get(`/users/dashboard/trans-list/?pk_ud=${id}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getExpiryDetails = async (Filter, page, perPage, search) => {
  const response = await get(
    `/user/plan/expired/list/?&page=${page}&page_size=${perPage}&search=${search}${
      Filter?.mindate !== undefined && Filter?.mindate?.length > 0
        ? `&expired_min_date=${Filter?.mindate}`
        : ""
    }${
      Filter?.maxdate !== undefined && Filter?.maxdate?.length > 0
        ? `&expired_max_date=${Filter?.maxdate}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getPlanList = async () => {
  const response = await get(`/user/plans/list/`).then((resp) => {
    return resp;
  });
  return response;
};

export const getEditData = async (id) => {
  const response = await get(`/user/get/edit/user-status/?pk_au=${id}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const putEditData = async (data) => {
  const response = await put(`/user/get/edit/user-status/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const DeleteAffiliateQueries = async (id) => {
  const data = {
    id: id,
  };
  const response = await delWithParams(`affiliate/admin/questions/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const CreateAffiliateQueries = async (question, answer) => {
  const data = {
    question: question,
    answer: answer,
  };
  const response = await post(`affiliate/admin/questions/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const UpdateAffiliateQueries = async (question, answer, id) => {
  const data = {
    question: question,
    answer: answer,
    id: id,
  };
  const response = await put(`affiliate/admin/questions/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getAdminAllQueries = async (Filter, earnings, revenue, commi) => {
  const response = await get(
    `affiliate/admin/all-queries/?${
      Filter?.search !== undefined && Filter?.search?.length !== 0
        ? `&search=${Filter?.search}`
        : ""
    } ${
      Filter.revenue !== undefined && Filter.revenue.length > 0
        ? `&${revenue}=${Filter.revenue}`
        : ""
    } ${
      Filter.earnings !== undefined && Filter.earnings.length > 0
        ? `&${earnings}=${Filter.earnings}`
        : ""
    } ${
      Filter.commi !== undefined && Filter.commi.length > 0
        ? `&${commi}=${Filter.commi}`
        : ""
    } ${
      Filter.maxdate !== undefined && Filter.maxdate.length > 0
        ? `&max_date=${Filter.maxdate}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminQueriesDetails = async (id) => {
  const response = await get(`affiliate/admin/queries/?id=${id}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const UpdateAdminQueriesDetails = async (id, Status) => {
  const data = {
    id: id,
    status: Status?.status,
    reply: Status?.reply,
  };
  const response = await put(`affiliate/admin/queries/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const getAllScreenStocks = async (data) => {
  const response = await post(
    `screener/?page=${data.page}&per_page=${data.perPage}`,
    data.payload
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminprimumnSupport = async (Filter, Page) => {
  const response = await get(
    `/premium-support/dashboard/?${
      Filter?.search ? `search=${Filter?.search}` : ""
    }&page=${Page.page}&page_size=${Page.perPage}${
      Filter?.mindate !== undefined && Filter?.mindate.length > 0
        ? `&start_date=${Filter?.mindate}`
        : ""
    }${
      Filter?.maxdate !== undefined && Filter?.maxdate.length > 0
        ? `&end_date=${Filter?.maxdate}`
        : ""
    } `
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminprimumnSupportDetails = async (id) => {
  const response = await get(`/premium-support/detail/?id=${id}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
export const UpdateAdminprimumnSupportDetails = async (id, status) => {
  const data = {
    id: id,
    status,
    status,
  };

  const response = await put(`/premium-support/detail/?id=${id}`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const affiliateHelpAPI = async (data) => {
  const response = await post(StockEndpoints.affiliateQuery, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
export const getAdminBucketsList = async (Filter) => {
  const response = await get(
    `/bucket-list/?${
      Filter?.search?.length > 0 ? `search=${Filter?.search}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getBucketDetails = async (Filter) => {
  const response = await get(
    `/bucket-list/?${
      Filter?.search?.length > 0 ? `search=${Filter?.search}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const postAdminBucketDetails = async (data) => {
  const response = await post(`bucket-list/`, data).then((resp) => {
    return resp;
  });
  return response;
};
export const putAdminBucketDetails = async (data) => {
  const response = await put(`bucket-list/`, data).then((resp) => {
    return resp;
  });
  return response;
};
export const DeleteAdminBucketDetails = async (id) => {
  let data = {
    id: id,
  };

  const response = await delWithParams(`bucket-list/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const checkFingradAccess = async (email) => {
  const response = await axios
    .get(
      `https://joinfingrad.com/api/payments/get-event-coupon/?email=${email}`
      // `https://learn.tradebrains.in/api/payments/get-event-coupon/?email=${email}`
    )
    .then((resp) => {
      return resp;
    });
  return response;
};

export const getAdminAllaffiliatesignupusers = ({ id, Search, Page }) => {
  const response = get(
    `affiliate/admin/free-signups/${id}/?search=${Search}&page=${Page.page}&page_size=${Page.perPage}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminTrasationDetails = (
  Page,
  Filter,
  dateString,
  dropdown,
  sourceFilter
) => {
  const response = get(
    `user-transections/dashboard/?page=${Page.page}&page_size=${
      Page.perPage
    }&source=${sourceFilter}${dropdown !== " " ? `&plan=${dropdown}` : " "}${
      Filter?.search !== undefined && Filter?.search?.length !== 0
        ? `&search=${Filter?.search}`
        : ""
    }${
      Filter?.mindate !== undefined && Filter?.mindate.length > 0
        ? `&min_date=${Filter?.mindate}`
        : ""
    }${
      Filter?.maxdate !== undefined && Filter?.maxdate.length > 0
        ? `&max_date=${Filter?.maxdate}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getFundamentalPorfolioAnalysis = (sortState, id) => {
  const response = get(
    `fundamentals/${id}/?by=${sortState?.head}&ascending=${sortState?.sortOrder}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getMonthlyWiseAnalysedData = (id, Selected) => {
  if (id === undefined) {
    return;
  }
  const response = get(`test_port/${id}/?val=${Selected}`).then((resp) => {
    return resp;
  });
  return response;
};

export const getMarketReturns = (
  CurremtValue,
  value1,
  Page,
  sortBy,
  sortOrder,
  perPage
) => {
  const response = get(
    `indexstas/?exchange=${CurremtValue}&by=${value1}&page=${Page}&sort_by=${sortBy}&ascending=${sortOrder}&per_page=${perPage}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getProfitTrackerData = (porfolioid, Duration) => {
  const response = get(`profit_tracker/${porfolioid}/?quater=${Duration}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getEventTrackerData = (porfolioid, status, Page) => {
  const response = get(
    `event_tracker/${porfolioid}/?status=${status}&page=${Page}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getEventTracker = (porfolioid, status, Page, type) => {
  const response = get(
    `event_tracker/${porfolioid}/?page=${Page}&type=${type}&status=${status}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getCorpActionsIndexData = (Page, type, CompanyData) => {
  const response = get(
    `all-corp-action/?page=${Page.page}&type=${type}&per_page=${Page.perPage}&${
      (CompanyData === undefined && `search=""`) || CompanyData?.length > 0
        ? `search=${CompanyData}`
        : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const postAddCoupon = async (data) => {
  const response = await post(`coupons/add/`, data).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminCoupon = (page, perPage, Search) => {
  const response = get(
    `coupons/list/?page=${page}&per_page=${perPage}${
      Search === undefined ? "" : `&search=${Search}`
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getIndividualAffiliateData = ({ id, value }) => {
  const response = get(
    `/affiliate/admin/individual-signup/?filter=${value}&affililate_id=${id}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const putEditCoupon = async (data, id) => {
  const response = await put(`coupons/edit/${id}/`, data).then((resp) => {
    return resp;
  });
  return response;
};
export const deleteCoupon = async (id) => {
  const response = await del(`coupons/edit/${id}/`).then((resp) => {
    return resp;
  });
  return response;
};
export const getAdminPortfolio = (searchInput) => {
  const response = get(
    `superstar-list/${searchInput ? `?search=${searchInput}` : ""}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminSuperStar = (searchInput) => {
  const response = get(
    `prices/admin/superstars/list/${
      searchInput ? `?search=${searchInput}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getAdminSuperStarEdit = (id) => {
  const response = get(
    `prices/admin/superstars/data/?investor_code=${id}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const addAdminStar = async (data) => {
  const response = await post(`superstar/add/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const addAdminStarCreate = async (data) => {
  const response = await post(`prices/admin/superstars/create/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
export const putStarPortfolio = async (data, id) => {
  const response = await put(`superstar/update/${id}/`, data).then((resp) => {
    return resp;
  });
  return response;
};

export const postStarPortfolioNew = async (data) => {
  const response = await post(`/prices/admin/superstars/create/`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const putStarPortfolioNew = async (id, data) => {
  const response = await put(`prices/admin/superstars/edit/${id}`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const deleteAdminStarportfolio = async (id) => {
  const response = await del(`superstar/update/${id}/`).then((resp) => {
    return resp;
  });
  return response;
};
export const getFeedbackdataAdmin = async (page, Filter) => {
  const response = await get(
    `contact/admin/user-testimonial-report/?min_date=2023-01-20&max_date=2023-06-20&search=${Filter?.search}&page=${page}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

// Revenue graph  end point : /api/affiliate/admin/revenue-graph/?filter=3M
export const getAllAffiliateGraphData = async (
  filter,
  monthString,
  isDateRange,
  dateString
) => {
  var url = `/affiliate/admin/affilate-signup/?filter=${filter}`;
  if (isDateRange) {
    url = `/affiliate/admin/affilate-signup/?min_date=${filter[0].format(
      "YYYY-MM-DD"
    )}&max_date=${filter[1].format("YYYY-MM-DD")}`;
  } else if (monthString) {
    url = `/affiliate/admin/affilate-signup/?min_date=${filter.format(
      "YYYY-MM-01"
    )}`;
  }

  const response = await get(url).then((resp) => {
    return resp;
  });
  return response;
};

export const addNotification = async (data) => {
  const response = await post(`/admin-notification-bar/`, data).then((resp) => {
    return resp;
  });
  return response;
};
export const getAllNotification = async (date, Search, Page) => {
  const response = await get(
    `/admin-notification-bar/?${
      date === "Invalid date"
        ? `&min_date=${moment(new Date())
            .startOf("month")
            .format("YYYY-MM-DD")}`
        : `&min_date=${date}`
    }${Search === undefined ? "" : `&search=${Search}`}&page=${
      Page.page
    }&page_size=${Page.perPage}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const editNotification = async (data, id) => {
  const response = await put(`/admin-notification-bar/?id=${id}`, data).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

//all signup api
export const getAllSignUps = async (Page, Search, dateString) => {
  const response = await get(`/affiliate/admin/all-affiliate-signups/?page=${
    Page.page
  }&page_size=${Page.perPage}
  ${Search === undefined || Search.length === 0 ? "" : `&search=${Search}`}
  ${dateString[0] === undefined ? "" : `&min_date=${dateString[0]}`}${
    dateString[1] === undefined ? "" : `&max_date=${dateString[1]}`
  }
    `).then((resp) => {
    return resp;
  });
  return response;
};
//admin signup count api
export const getAllAdminSignupCount = async (
  input,
  value,
  dateString,
  Page
) => {
  const response = await get(
    `/affilate-signups/?${
      input === undefined || input?.length === 0 ? "" : `&search=${input}`
    }${value !== undefined ? `&filter=${value}` : ""}${
      dateString === undefined ? "" : `&min_date=${dateString[0]}`
    }${dateString === undefined ? "" : `&max_date=${dateString[1]}`}&page=${
      Page.page
    }&page_size=${Page.perPage}`
  ).then((resp) => {
    return resp;
  });
  return response;
};
//testsearch-api
export const getStockDetails = async (input) => {
  const response = await get(`test-search?keyword=${input}&length=4`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
//admin stock details
export const addStockToStockDetails = async (data) => {
  const data1 = {
    ...data,
    research_report: "",
  };
  const response = await post(`stocks/about-admin/`, data1).then((resp) => {
    return resp;
  });
  return response;
};
export const getStockToStockDetails = async (id) => {
  const response = await get(`stocks/about-admin/?fincode=${id}`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

export const getAllStockDetails = async (input) => {
  const response = await get(
    `stocks/about-admin-all/?${
      input === undefined ? `&search=` : `&search=${input}`
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const putStockToStockDetails = async (data, id) => {
  const data1 = {
    description: data?.description,
    research_report: data?.research_report,
    fincode: id,
  };
  const response = await put(`stocks/about-admin/?fincode=${id}`, data1).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};
//userlist in admin
export const UpdateUserDetails = async (data) => {
  const data1 = {
    ...data,
    transaction_id: "",
    coupon: "",
  };
  const response = await post(
    `payments/add/
  `,
    data1
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getBrokerDetails = async () => {
  const response = await get(`stock/list/broker-data`).then((resp) => {
    return resp;
  });
  return response;
};

export const addStockRecommendation = async (data) => {
  const response = await post(
    `stock/recommendations/admin-create-recommendation/`,
    data
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getRecommendation = async (searchInput) => {
  const response = await get(
    `/stock/recommendations/admin-view/${
      searchInput ? `?search=${searchInput}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const putStockRecommendation = async (data) => {
  const response = await put(
    `/stock/recommendations/admin-update-recommendation/`,
    data
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const deleteAdminStockRecommendation = async (id) => {
  const response = await del(`/stock/recommendations/delete/${id}/`).then(
    (resp) => {
      return resp;
    }
  );
  return response;
};

/*user management*/

export const getUserManagementUserList = async (searchInput, Page) => {
  // &page=${Page.page}&page_size=${Page.perPage}
  const response = await get(
    `/user-management/admin/?search=${searchInput}`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const getUserManagementUserData = async (user_id) => {
  const response = await get(
    `/user-management/admin/user-details/${user_id}/`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const putUserManagementUserData = async (updatedData) => {
  const response = await put(
    `/user-management/admin/user-details/edit/`,
    updatedData
  ).then((resp) => {
    return resp;
  });
  return response;
};
export const getUserAccountStatus = async (user_id) => {
  const response = await get(
    `/user-management/admin/account-status/${user_id}/`
  ).then((resp) => {
    return resp?.data;
  });
  return response;
};
export const putUserAccountStatus = async (data) => {
  const response = await put(
    `/user-management/admin/account-status/edit/`,
    data
  ).then((resp) => {
    return resp?.data;
  });
  return response;
};
export const putUserRoleUpdate = async (data) => {
  const response = await put(`user/type/`, data).then((resp) => {
    return resp?.data;
  });
  return response;
};
export const getUserPagePermissionData = async () => {
  const response = await get(`user/roles/`).then((resp) => {
    return resp?.data;
  });
  return response;
};

/*user management*/

export const getScreenerData = async (searchInput, page) => {
  const response = await get(
    `/screener/admin/view/?${searchInput ? `search=${searchInput}&` : ""}${
      page ? `page=${page}` : ""
    }`
  ).then((resp) => {
    return resp;
  });
  return response;
};

export const deleteScreener = async (id) => {
  const response = await del(`/screener/admin/delete/${id}/`).then((resp) => {
    return resp;
  });
  return response;
};
