import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";

function PeerCompanies({ lightMode, peerCompany,notBankStock }) {

  const headerData=useMemo(() => {
     return [
      {
        title: <p className="fw-600 mb-0 fs-s-16">Company Name</p>,
        dataIndex: "company_name",
        fixed: "left",
        width: "70px",
        render: (text, record) => {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    fontSize: "16px",
                    borderRight: "1px solid #C9D7DE",
                    borderLeft: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                    fontSize: "16px",
                  },
            },
            children: (
              <div
                className="fs-s-14 link-hover-underline ff-poppins"
                style={{ color: lightMode ? "black" : "white" }}
              >
                <Link to={`/stock/${record?.symbol}/consolidated`}>{text}</Link>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <div className=" d-flex align-items-center ">
            <p className="fs-s-16 fw-600 mb-0">MCap</p>{" "}
            <p className="mb-0 ml-2 fw-600 mt-5 fs-s-12">(Cr)</p>
          </div>
        ),
        dataIndex: "m_cap",
        key: "m_cap",
        width: "50px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                ₹{numberWithCommas((text / 10 ** 7)?.toFixed(2))}
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fw-600 mb-0 fs-s-16">PE</p>,
        dataIndex: "pe",
        key: "pe",
        width: "50px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fw-600 mb-0 fs-s-16">ROE</p>,
        dataIndex: "roe",
        key: "roe",
        width: "50px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}%
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fw-600 mb-0 fs-s-16">{ notBankStock==='BANK'?'Net Interest Margin': "Debt to Equity"}</p>,
        dataIndex: notBankStock==='BANK'?'net_intrest_margin':"debt_of_equity",
        key: notBankStock==='BANK'?'net_intrest_margin':"debt_of_equity",
        width: "50px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text??'NA'} {notBankStock==='BANK'&&text?'%':''}
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fw-600 mb-0 fs-s-16">{notBankStock==="BANK"?'Net NPA':"Current Ratio"}</p>,
        dataIndex: notBankStock==="BANK"?'net_npa':"current_ratio",
        key: notBankStock==="BANK"?'net_npa':"current_ratio",
        width: "50px",
        render(text, index) {
          return {
            props: {
              style: lightMode
                ? {
                    background: "rgba(242, 246, 248, 1)",
                    borderColor: "#C9D7DE",
                    borderRight: "1px solid #C9D7DE",
                    borderBottom: "none",
                  }
                : {
                    background: "#3B3F4F",
                    borderLeft: "1px solid #545E78",
                    borderRight: "1px solid #545E78",
                    borderBottom: "none",
                  },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text??'NA'}
              </span>
            ),
          };
        },
      },
    ];
  }, [notBankStock,peerCompany,lightMode])

  const data = [
    {
      Company_Name: "Reliance Industries",
      Market_Cap: 1710957,
      PE: 52.34,
      ROE: 26.46,
      "D/E": 43.65,
      Current_Ratio: 27.74,
      key: 1,
    },
    {
      Company_Name: "Reliance Industries",
      Market_Cap: 1710957,
      PE: 52.34,
      ROE: 26.46,
      "D/E": 43.65,
      Current_Ratio: 27.74,
      key: 2,
    },
    {
      Company_Name: "Reliance Industries",
      Market_Cap: 1710957,
      PE: 52.34,
      ROE: 26.46,
      "D/E": 43.65,
      Current_Ratio: 27.74,
      key: 3,
    },
    {
      Company_Name: "Reliance Industries",
      Market_Cap: 1710957,
      PE: 52.34,
      ROE: 26.46,
      "D/E": 43.65,
      Current_Ratio: 27.74,
      key: 4,
    },
  ];
  return (
    <CustomTable 
    scrollLimit={700}
    columns={headerData} scrollable={true} data={peerCompany} />
  );
}

export default PeerCompanies;
