import React from "react";

function PerformanceBarLine({gainerCount=5,LoserCount=5,totalCount=10,lineHeight}) {
  return (
    <>
      <div
        style={{
          background: `linear-gradient(90deg,#04A94C 0%,  #B4D74D ${
            (gainerCount / totalCount) * 100 + "%"
          }, #EF8B2E ${
            (gainerCount / totalCount) * 100 + "%"
          },#EF0000 100% )`,height:lineHeight?lineHeight:'10px'
        }}
        
      ></div>
      <div className="d-flex justify-content-between mt-5">
        <p className="mb-0">{gainerCount}</p>
        <p className="mb-0">{LoserCount}</p>
      </div>

    </>
  );
}

export default PerformanceBarLine;
