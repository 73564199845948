import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import numberWithCommas from "../../../../../../utilityFn/numberWithCommas";

function AffiliateRevenueCard({ lightMode, cato, unit, value, Link }) {
  const { id, name } = useParams();
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        cato === "Total  Signed Up Users"
          ? navigate(`/admin/affiliate/AllAffiliates/users/${name}/${id}`)
          : " "
      }
      className={`pointer ${
        lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
      } br-5 w-20p-49 p-20`}
    >
      <p className="ff-lato fw-900  ff-lato fs-s-20">
        {unit} {numberWithCommas(value) ?? 0}
      </p>
      <p className="fw-500">{cato}</p>
    </div>
  );
}

export default AffiliateRevenueCard;
