import { Form, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAdminStar } from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import PorrtfolioForm from "./PorrtfolioForm";

function CreatePortfolio() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const breadCrumbData = [
    { label: "Superstar Portfolio", link: `/admin/star_portfolio` },
  ];
  const [keywordArr, setkeywordArr] = useState([]);
  const onSubmit = async (values) => {
    const convertKeyword = keywordArr?.map((item) => item.keyword)?.join("|");
    const convertData = { ...values, keywords: convertKeyword };
    const postData = new FormData();
    Object.entries(convertData).map((res) => {
      postData.append(res[0], res[1]);
    });
    await addAdminStar(postData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Portfolio Added successfully");
          navigate("/admin/star_portfolio");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb
          linkData={breadCrumbData}
          current={"Creation of Super star"}
        />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">Superstar Creation</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => form.submit()}
          className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
        >
          Create
        </button>
      </div>
      <PorrtfolioForm
        formSubmit={onSubmit}
        form={form}
        keywordArr={keywordArr}
        setkeywordArr={setkeywordArr}
      />
    </div>
  );
}

export default CreatePortfolio;
