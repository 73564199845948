import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { getBucketCompany } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import TrendingStocks from "../../components/TrendingStocks";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import LeftSidebar from "../Buckects/sidebar/LeftSidebar";
import MobileBottomBar from "../stock details/MobileBottomBar";
import StockListTable from "./StockListTable";
import BreadCrumb from "../../components/BreadCrumb";
import TextReducer from "../../components/TextReducer";
import NewsTabStockDetails from "../stock details/Corp Announce/NewsTabStockDetails";
import DotLoader from "../../components/spinners/DotLoader";
import { authStore } from "../../redux/reducers/authSlice";
import { removeEmptyObj } from "../../utilityFn/removeEmptyObj";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
function BucketDetailsIndex() {
  const { bucketname } = useParams();
  const prevLoc = useLocation();
  const UserAuth = useSelector(authStore);
  const { lightMode } = useSelector(getThemeMode);
  const [bucketData, setbucketData] = useState({});
  const [sortState, setsortState] = useState({
    head: "mcap",
    sortOrder: false,
  })
  // get bucket details
  const [isLoading, setisLoading] = useState(false)
  useEffect(() => {
    const data = () => {
      setisLoading(true)
      getBucketCompany(bucketname,sortState.head,sortState.sortOrder).then((res) => {
        console.log(res.data.stocks);
        const stockList=removeEmptyObj(res.data.stocks)
        setisLoading(false)
        console.log();
        setbucketData({...res.data,stocks:stockList});
      });
    };
    data();
  }, [bucketname,sortState]);

  // side and bottom bar data
  const sidebarData = [
    {
      id: "Overview",
      icon: svgSheet.overviewIcon,
      label: "Overview",
      offset: -250-bannerOffsetCalc(UserAuth),
    },
    {
      id: "Stock List",
      icon: svgSheet.factsheetIcon,
      label: "Stock List",
      offset: -127-bannerOffsetCalc(UserAuth),
    },
    {
      id: "News",
      icon: (
        <div style={{ width: "24px", height: "24px" }}>{svgSheet.newsIcon}</div>
      ),
      label: "News",
      offset: -127-bannerOffsetCalc(UserAuth),
    },
  ];
  const topBuckets = [
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/drone+stocks.png",
      label: "Drone Stocks",
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/5G.png",
      label: "5G Stocks",
      isPremium: true,
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Semiconductor+stocks.png",
      label: "Semiconductor Stocks",
      isPremium: true,
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Bluechip+stocks.png",
      label: "Blue Chip Stocks",
      isPremium: true,
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/EV+Stocks.png",
        label: "Electric Vehicles (EV) India",
      isPremium: true,
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Navratan+stocks.png",
        label: "Navratna Stocks",
    },
  ];

  const breadCrumbData = useMemo(() => {
    if (prevLoc.state) {
      return [
        { label: "Buckets", link: `/buckets` },
        { label: prevLoc.state, link: `/bucket/${prevLoc.state}` },
      ];
    } else {
      return [{ label: "Buckets", link: `/buckets` }];
    }
  }, [bucketname]);
  if (!bucketData?.overview) {
    return <DotLoader />;
  }
  return (  
    <>
     <ReactHelmet
        title={`${bucketname} Stock Buckets by Trade Brains Portal `}
        desc={`${bucketData?.overview?.slice(0,20)}`}
      />
      <div className="ff-poppins max-w mx-auto  px-15  w-100 relative">
        <div className="my-body">
          <h1 className=" mb-0 fs-36-20 fw-700 mb-10 line-h-36">{bucketname}</h1>
          <div className="mb-20">
            <BreadCrumb linkData={breadCrumbData} current={bucketname} />
          </div>
          <div className="d-flex">
            <div
              style={{ width: "20%", top:`${130+bannerOffsetCalc(UserAuth)}px`, height: "max-content" }}
              className="detail-left-card p-fixed"
            >
              <LeftSidebar
                lightMode={lightMode}
                sidebarData={sidebarData}
                subHead="Top Buckets"
                subData={topBuckets}
                baseURL="/buckets"
                premiumCheck={UserAuth?.userData?.user?.is_premium}
              />
              {/* <RecentSearch lightMode={lightMode} /> */}
            </div>
            <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
              {/* overview  */}
              <div id="Overview">
                <h2
                  className="fs-28-18 fw-600 d-flex mb-0 mb-20"
                  style={{ lineHeight: "27px" }}
                >
                  Overview
                </h2>
                <img
                  src={bucketData?.featured_image ?? bucketData?.image}
                  className="w-100 fit-cover br-5"
                  style={{ maxHeight: "440px" }}
                  alt="img"
                />
                <TextReducer
                  data={bucketData?.overview}
                  textLimit={800}
                  lightMode={lightMode}
                />
              </div>
              {/* overview  */}
              {/* stocl list  */}
              <div id="Stock List">
                <h2
                  className="fs-28-18 fw-600 d-flex mb-0 my-20"
                  style={{ lineHeight: "27px" }}
                >
                  Stock List
                </h2>
                <StockListTable
                  tableData={bucketData?.stocks}
                  lightMode={lightMode}
                  isLoading = {isLoading}
                  sortState={sortState}
                  setsortState={setsortState}
                />
              </div>
              {/* stocl list  */}
              {/* news  */}
              <div id="News">
                <h2
                  className="fs-28-18 fw-600 d-flex mb-0 my-20"
                  style={{ lineHeight: "27px" }}
                >
                  News
                </h2>
                <NewsTabStockDetails lightMode={lightMode} symbol="R" />
              </div>
              {/* news  */}
            </div>
          </div>
          <TrendingStocks lightMode={lightMode} />
        </div>
      </div>
      <MobileBottomBar
        navData={sidebarData}
        offset={-125}
        lightMode={lightMode}
      />
    </>
  );
}

export default BucketDetailsIndex;
