import React from "react";
import HightlightCardsLight from "../../../../../assets/images/hightlightCardLight.png";
import HightlightCardsDark from "../../../../../assets/images/hightlightCardDark.png";
function HighlightSec({ lightMode,userRedirect }) {
  return (
    <div className="max-w mx-auto px-15  w-100">
      <div className="flex flex-col-row-reverse align-lg-center my-70">
        <div className="w-100-50 d-flex flex-col align-md-center">
          <h1 className="fs-40-28 fw-700 mb-30 text-md-center w-100-70">
            Highlights of being a part
            of our Affliate Network
          </h1>
          <p className="text-md-center mb-20 w-100-60">
            We offer a greater commisioning oppoutunity as an affiliate
            to earn more by your sales influences.Our platform provides
             exlusive dashboard to track your sales and payments{" "}
          </p>
          <button
          onClick={()=>userRedirect()}
            style={{ width: "200px", height: "50px" }}
            className="btn-bg-primary fw-600 text-white fs-16-14 br-5"
          >
            Sign up as An Affiliate
          </button>
        </div>
        <div className="w-100-50 " style={{ maxHeight: "500px" }}>
          <img
            className="mx-auto"
            src={lightMode ? HightlightCardsLight : HightlightCardsDark}
          />
        </div>
      </div>
    </div>
  );
}

export default HighlightSec;
