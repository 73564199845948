import { AutoComplete } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSearchData } from '../../api/fetchClient';
import CreateWatchListModel from '../../pages/watchlist/CreateWatchListModel';
import { AddStock } from '../../redux/reducers/RecenSearchSlice';
import { bannerOffsetCalc } from '../../utilityFn/bannerOffsetcalc';
import NavToWatchList from '../NavToWatchList';

function SearchComponent({lightMode,setMobileSearchToggle,planData={}}) {
  const navigate = useNavigate();
  const [inputData, setinputData] = useState("");
  const [apiData, setapiData] = useState([]);
  const [inputSelected, setinputSelected] = useState("");
  const [open, setopen] = useState(false)
  const [WatchListTrigger, setWatchListTrigger] = useState(false)
  const dispatch = useDispatch()
  const [DropDown, setDropDown] = useState(false)
  const [OpenModel, SetOpenModel] = useState(false)
    const getResults = async (e) => {
        setinputData(e);
        
        const response = await !WatchListTrigger &&   getSearchData(e)
          .then((resp) => {
            setapiData(()=>resp.data?.filter(item=>item.symbol));
          })
          .catch((err) => console.log(err));
        
      };
    const intialData = [
        {value:"TCS",label:"TATA Consultancy", array:["stock","TCS"]},
        {value:"HDFC",label:"Housing Development Finance Corporation",array:["stock","HDFC"]},
        {value:"BAJFINANCE",label:"Bajaj Finance",array:["stock","BAJFINANCE"]},
        {value:"TATAMOTORS",label:"TATA Motors",array:["stock","TATAMOTORS"]},
        {value:"TRIDENT",label:"Trident",array:["stock","TRIDENT"]}
    ]
  
    let dropdowndata = [];

  
    const renderItem = (title, symbol,type) => ({
      
      value: symbol,
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
          className="align-items-center"
        >
                   
       
       
          <div onClick={()=>{ type !== "index" && dispatch(AddStock({name:title,symbol:symbol}));}} className="d-flex-col ml-5">
            <div className="fw-500 w-180-line-dots ">{title}</div>
            <div style={{ color: "#6DB8FD",fontWeight:"500px" }} className="btn- fw-500">
              {symbol}
            </div>
          </div>
          {type === "stock" && <NavToWatchList lightMode={lightMode}  symbol={[symbol]} setWatchListTrigger={setWatchListTrigger} SetOpenModel={SetOpenModel}/>}
        </div>
      ),
      array:[type,title],
    });
  
  const uniqdataarrray = []
    apiData.map((items) => {
    
      !uniqdataarrray.includes(items.symbol) &&inputSelected&& dropdowndata.push(renderItem(items.company, items.symbol,items?.type))
      uniqdataarrray.push(items.symbol)
    });
  return (
    <div className="header-search-body relative">
      { !WatchListTrigger &&  <CreateWatchListModel onClick={()=>  !WatchListTrigger && setWatchListTrigger(true)} OpenModel={OpenModel}  SetOpenModel={SetOpenModel} lightMode={lightMode}/>}

    {/* <div
      className={`${
        lightMode ? "bg-gray" : "bg-darkmode-black"
      } header-search d-flex align-items-center px-15`}
    > */}
    {/* <input
        style={{ border: "none" }}
        type="text"
        value={inputData}
        placeholder="Search Company"
        onChange={getResults}
        ref={tiggerInput}
        onFocus={() => dispatch(toggleInput(false))}
      
      /> */}

    <AutoComplete
      className={`${
        lightMode ? "antd-Selete-Custom-light-mode" : " antd-Selete-Custom-black "
      } nav-Bar-autofix ${open ? "navbar-search-active" : "navbar-search-deactive"} `}
      style={{
        height: "40px",
        marginLeft: "70px",
        width: "100%",
      }}
      defaultValue={""} 
      onFocus={() => {!WatchListTrigger && setopen(true)}}
      allowClear={true}
      placeholder="Search Company"
      fieldNames={"firstOne"} 
      open={open}
      id={"searchNav"}
      options={dropdowndata?.length === 0  ? intialData : dropdowndata}
      value={inputSelected}
      onDropdownVisibleChange={(e)=>{!WatchListTrigger && setopen(e);}}
      dropdownClassName={`${bannerOffsetCalc(planData)!==0?'common-drop-Down-nav-banner':'common-drop-Down-nav'}  ${
        dropdowndata?.length > 0   ?  lightMode ? "drop-down-stock-nav-lightmode" : "drop-down-stock-nav invert-text" :  lightMode ? "drop-down-stock-nav-initial-light" : "drop-down-stock-nav-initial-dark invert-text"
      }`}
      onChange={(e) => {
        !WatchListTrigger &&  getResults(e);
        !WatchListTrigger &&  setinputSelected(e);
      }}
      onSelect={(e, record) => {
        getResults("")
        dropdowndata.length = 0
        dropdowndata.splice(0,dropdowndata.length);
        !WatchListTrigger && setopen(false);
        !WatchListTrigger && setinputSelected(record?.array[1]);
        !WatchListTrigger && record?.array[0]=== 'stock' ?  navigate(`/stock/${record?.value}/consolidated`) : !WatchListTrigger && navigate(`Index/${record?.value}`)
        !WatchListTrigger && setinputSelected("")
        
        !WatchListTrigger && record?.array[0] !=="index" && dispatch(AddStock({name:record?.array[1],symbol:record?.value}));
        !WatchListTrigger && setMobileSearchToggle("NONE")
        !WatchListTrigger && getResults("")
       
        !WatchListTrigger && setopen(false)
      }}
      defaultActiveFirstOption={true}
    />
    {/* <img className="w-15 h-15" src={searchIcon} alt="icon" /> */}
    {/* {svgSheet.searchIcon}
    </div> */}
    {/* {inputData && (
      <div className="main-search-body-navbar">
      <div
        className={`w-100 search-result-body absolute ${
          lightMode
            ? "bg-white border1px-light-mode"
            : "bg-dark-gray border1px-dark-mode"
        } px-15 `}
      >
        {searchResultDiv}
      </div>
      </div>
    )} */}
  </div>
  )
}

export default SearchComponent