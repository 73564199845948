import React from 'react'
// import { GoogleLogout } from 'react-google-login';
function GoogleLogoutFn() {
  return (
    <span className='fw-700'>Logout</span> 
    // <GoogleLogout
    //   clientId="1065016260363-bt80d75995gpcisjjq345bmfj8qjq49h.apps.googleusercontent.com"
    //   buttonText="Logout"
    //   onLogoutSuccess={(resp)=>console.log(resp)}
    //   render={(renderProps)=>(
    //     <span>Logout</span> 
    //   )}
    // >
    // </GoogleLogout>
  )
}

export default GoogleLogoutFn