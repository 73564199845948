import {
  CaretDownOutlined,
  DeleteFilled,
  MoreOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import WatchlistEmpty from "../../assets/images/WatchlistEmpty.png";
import CustomTable from "../../components/CustomTable";
import WatchListList from "./WatchListList";
import { Modal, Popover } from "antd";
import { message } from "antd";
import TimeLineButtons from "../../components/TimeLineButtons";
import {
  getWatchListStore,
  WatchListFetch,
} from "../../redux/reducers/watchListSlice";
import {
  ClearWatchlist,
  CreateWatchlsit,
  DeleteWatchlist,
  getStockLTP,
  GetUpdatedChanges,
  PatchRenameWatchList,
} from "../../api/fetchClient";
import { toggleInput } from "../../redux/reducers/ActiveSearchSlice";
import { Link, useNavigate } from "react-router-dom";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import TrendingStocks from "../../components/TrendingStocks";
import MobileBottomSlider from "./MobileBottomSlider";
import RecentSearch from "./RecentSearch";
import svgSheet from "../../assets/svg/svgSheet";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import NewsTabStockDetails from "../stock details/Corp Announce/NewsTabStockDetails";
import { authStore } from "../../redux/reducers/authSlice";
import { plans } from "../../utilityFn/planData";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";

function WatchListMain({setMobileSearchDrop,MobileSearchDrop}) {
  const watchListStore = useSelector(getWatchListStore);
  const [NewWatchList, SetNewWatchList] = useState();
  const [daysDuration, setdaysDuration] = useState("days=1");
  const [WatchList, setWatchList] = useState(
    Object.keys(watchListStore.watchListData)[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [IsPremiumModel, setIsPremiumModel] = useState(false)
  const navigate = useNavigate()
  const [WatchListdata, setWatchListdata] = useState([]);

  const [visible, setVisible] = useState(false);
console.log("setWatchList",setWatchList);
  // create new watchlist
  const [CreateWatchlist, setCreateWatchlist] = useState();
  // to hide popup
  const [toTriggerPopup, setToTriggerPopup] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    let companyname = [];

    Object.keys(watchListStore.watchListData).map((items) => {
      companyname.push(items);
    });
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(WatchListFetch());
  }, [WatchList]);

  //model div states
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    SetNewWatchList(WatchList);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const auth = useSelector(authStore);

  //delete model of watchlist
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  const handleOk3 = () => {
    setIsModalVisible3(false);
    DeleteWatchlist({ WatchList }).then((res) => {
      dispatch(WatchListFetch());
    });
    setWatchList(
      Object.keys(watchListStore.watchListData)[0]
        ? Object.keys(watchListStore.watchListData)[0]
        : Object.keys(watchListStore.watchListData)[1]
    );
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };

  //Create watch list
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
    CreateWatchlsit({ watchlist_name: CreateWatchlist }).then((res) => {
      dispatch(WatchListFetch());
      setWatchList(CreateWatchlist)
      setCreateWatchlist("");
    });
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  let companys = [];

  const [HidePopover, setHidePopover] = useState(false);
  const deleteFromWatchlist = (text) => {
    setHidePopover(true);
    const data = { stocks: [text] };
    ClearWatchlist({ WatchList, data }).then((res) => {
      success(res?.detail && res?.detail);
      dispatch(WatchListFetch());
      setHidePopover(false);
    });
  };
  const success = (res) => {
    // message.success(res);
    message.success({content:res,className:!lightMode && "darkmodemessge"})
  };
//   const success = (res) => {
      
//     !auth.userData?.access_token ?(
//       message.error({ content:"Login To Add To WatchList",className:!lightMode && "darkmodemessge"})

//    ) :(
//      message.success({content:res,className:!lightMode && "darkmodemessge"})
//    )


// };
  useEffect(() => {
    watchListStore?.watchListData[WatchList] &&
      Object.values(watchListStore?.watchListData[WatchList]).map((items) =>
        companys.push({ name: items.symbol, fullname: items.company_name })
      );
  }, [
    WatchList,
    watchListStore.watchListData[WatchList],
    Object.keys(watchListStore.watchListData),
  ]);

  //model for clearWatch listt
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  const handleOk1 = () => {
    setIsModalVisible1(false);
    let list = [];
    watchListStore?.watchListData[WatchList] &&
      Object.values(watchListStore?.watchListData[WatchList]).map((items) =>
        list.push(items.symbol)
      );

    const data = { stocks: list };
    if (
      list?.length >=
      Object.values(watchListStore?.watchListData[WatchList])?.length
    ) {
      ClearWatchlist({ WatchList, data });
      dispatch(WatchListFetch());
    }
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  useEffect(() => {
    let watchlistArray = [];
    setIsLoading(true);
    const getData = async () => {
      companys?.map(async (a, b) => {
        const ltpData = await GetUpdatedChanges(a["name"], daysDuration).then(
          async (resp) => {
            watchlistArray = [
              {
                ...resp.data,
                companyName: a["fullname"],
                companysymbol: a["name"],
                daydata: resp.data,
              },
              ...watchlistArray,
            ];
            setIsLoading(false);
          }
        );
        setWatchListdata([...watchlistArray]);

        return ltpData;
      });
    };

    getData();
  }, [watchListStore.watchListData[WatchList], daysDuration]);

  //rename the watchlist name

  const handleOk = async (newname) => {
    let newdata = [];

    watchListStore?.watchListData[WatchList] &&
      Object.values(watchListStore?.watchListData[WatchList]).map((items) =>
        newdata.push(items.symbol)
      );
    setIsModalVisible(false);
    const data = { new_name: newname, stocks: newdata };
    PatchRenameWatchList({ WatchList, data }).then((res) => {
      dispatch(WatchListFetch());
      setWatchList(newname);
    });
  };

  const { lightMode } = useSelector(getThemeMode);
  const onSearch = () => {
    dispatch(toggleInput(true));
    // const el = ;
    sessionStorage.setItem("pageView", JSON.stringify({state:true}));
    document.getElementById("searchNav").focus()
    // console.log("el",el);
    
  };


  const [PlanName, setPlanName] = useState()

  useEffect(() => {
    setPlanName(auth?.userData?.user?.plan?.planId)
  }, [])
  

  console.log("PlanName0",plans[PlanName]?.watchlist,Object.keys(watchListStore.watchListData)?.length);

  const StockConstituents = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
      dataIndex: "companyName",
      fixed: "left",
      width: "70px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.companysymbol}/consolidated`}>
              <div
                className="link-hover-underline pointer"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </div>
            </Link>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">CMP</p>,
      dataIndex: "close",
      width: "50px",
      render(text, record) {
        const commawithtext = numberWithCommas(text);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: lightMode ? "black" : "white",
              }}
            >
              {"₹" + " " + commawithtext}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Change %</p>,
      dataIndex: "per_change",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
              color: redGreenColorPicker(text, lightMode),
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {text > 0 && "+"}
              {text + "%"}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <p className="fs-s-16 fw-600 mb-0">
          {daysDuration === "days=1"
            ? "1 Day Change"
            : daysDuration === "months=1"
            ? "1 Month Change"
            : daysDuration === "months=3"
            ? "3 Month Change"
            : daysDuration === "months=6"
            ? "6 Month Change"
            : "1 Year Change"}{" "}
        </p>
      ),
      dataIndex: "daydata",
      width: "150px",
      render(text, record) {
        const low = (text?.low).toFixed(2);

        const high = (text?.high).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className=""
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: lightMode ? "black" : "white",
              }}
            >
              <div
                style={{ position: "relative" }}
                className="ff-lato w-98 h-25"
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: lightMode ? "black" : "white",
                    position: "absolute",
                    left:
                      ((text.close - text.low) / (text.high - text.low)) * 100 <
                      0
                        ? 0 + "%"
                        : ((text.close - text.low) / (text.high - text.low)) *
                            100 >
                          100
                        ? 100 + "%"
                        : ((text.close - text.low) / (text.high - text.low)) *
                            100 +
                          "%",
                    bottom: "-4px",
                  }}
                  className="d-flex flex-col mb-3"
                >
                  {/* <p className="fs-16-13 mb-0">{text.close}</p> */}
                  <CaretDownOutlined color="clr-green" />
                </div>
              </div>
              <div className="dynamic-stockbar "></div>
              <div className="d-flex mt-5 justify-content-between">
                <p className="mb-0  fs-s-14 fw-500 ff-lato">
                  ₹ {numberWithCommas(Number(low))}
                </p>
                <p className="mb-0  fs-s-14 fw-500 ff-lato">
                  ₹ {numberWithCommas(Number(high))}
                </p>
              </div>
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0"></p>,
      dataIndex: "companysymbol",
      width: "20px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderLeft: lightMode
                ? lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78"
                : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="d-flex justify-content-center pointer "
              style={{
                fontSize: "15px",
                fontWeight: "500",
                color: "#F82E2E",
              }}
            >
              <div className=" " onClick={() => deleteFromWatchlist(text)}>
                <DeleteFilled />
              </div>
            </span>
          ),
        };
      },
    },
  ];
  const timeLineData = [
    { label: "1D", value: "days=1" },
    { label: "1M", value: "months=1" },
    { label: "3M", value: "months=3" },
    { label: "6M", value: "months=6" },
    { label: "1Y", value: "years=5" },
  ];
  return (
    <>
     <ReactHelmet
        title="Trade Brains Portal - Financial Data & Stock Quotes    "
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="my-body">
      <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex    w-100 relative">
        <div
          className="px-15 "
          onClick={() => HidePopover === true && setHidePopover(true)}
        >
          <div className="w-100">
            <div className="d-flex mb-20 align-items-center mb-0 justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mr-10 w-m-28 mt-5 mb-0">
                  {svgSheet.watchlistlogo}
                </p>
                <h1 className="mb-0 fs-36-20 fw-700">Watchlist</h1>
              </div>
              {watchListStore.watchListData[WatchList]?.length > 0 && (
                <div className="only-PC-view">
                  <div className="d-flex mt-15 d-flex-row ">
                    <TimeLineButtons
                      data={timeLineData}
                      value={daysDuration}
                      setValue={setdaysDuration}
                      lightMode={lightMode}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="only-mobile-view  d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="fs-s-14 mb-0 fw-500 d-flex justify-content-center">
                    {WatchList}
                  </p>
                  <Popover
                    color={lightMode ? "white" : "#3B3F4F"}
                    className="nameis"
                    placement="right"
                    content={
                      <div className="">
                        <div
                          className={`${
                            lightMode ? "text-dark-gray" : "text-white"
                          }`}
                        >
                          <div className="d-flex  align-items-center mt-5 ">
                            <p
                              onClick={() => {
                                showModal();
                                setVisible(false);
                              }}
                              className="ml-5 fs-s-12 fw-600 mb-3 pointer"
                            >
                              Rename
                            </p>
                          </div>
                          <div className="d-flex  align-items-center mt-5">
                            <p
                              onClick={() => {
                                showModal1();
                                setVisible(false);
                              }}
                              className="ml-5 fs-s-12 fw-600 mb-3  pointer"
                            >
                              Clear watchlist
                            </p>
                          </div>
                          {Object.keys(watchListStore?.watchListData)
                            ?.length !== 1 && (
                            <div className="d-flex align-items-center mt-5">
                              <p
                                onClick={() => {
                                  showModal3();
                                  setVisible(false);
                                }}
                                className="ml-5 fs-s-12  fw-600 mb-3 pointer"
                              >
                                Delete
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <p className="mb-0">
                      <MoreOutlined />
                    </p>
                  </Popover>
                </div>
                <div className="d-flex align-items-center">
                  {" "}
                  <div className="d-flex mb-10 d-flex-row align-items-center">
                    <TimeLineButtons
                      data={timeLineData}
                      value={daysDuration}
                      setValue={setdaysDuration}
                      lightMode={lightMode}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* main content div */}
            <div className="d-flex w-100 watch-list-main-content">
              <div className="only-PC-view w-25">
                <div
                  className={`${
                    lightMode ? "bg-gray" : "bg-dark-gray"
                  } d-flex flex-col p-15 mb-10 watchlist-items br-3 `}
                >
                  {Object.keys(watchListStore.watchListData)?.map(
                    (items, i) => (
                      <WatchListList
                        setWatchList={setWatchList}
                        showModal1={showModal1}
                        showModal3={showModal3}
                        lightMode={lightMode}
                        handleVisibleChange={handleVisibleChange}
                        key={i}
                        visible={visible}
                        WatchList={WatchList}
                        showModal={showModal}
                        toTriggerPopup={toTriggerPopup}
                        items={items}
                        watchListStore={watchListStore}
                      />
                    )
                  )}
 
                  <button
                    className={`fs-s-16  fw-600 pointer  p-10 br-5 fs-s-18 btn-bg-primary text-white mt-10`}
                    onClick={()=>plans[PlanName]?.watchlist <= Object.keys(watchListStore.watchListData)?.length ? setIsPremiumModel(true) : showModal2()}
                  >
                    {console.log("watchListStore.watchListData",plans[PlanName]?.watchlist,  Object.keys(watchListStore.watchListData)?.length)}
                    + Add Watchlist
                  </button>

                </div>
                <RecentSearch lightMode={lightMode} />
              </div>

              {watchListStore.watchListData[WatchList]?.length > 0 ? (
                <div className="watch-list-content">
                  <div className="w-100">
                    <div className="sub-table-news-data ">
                      <div
                        className={`table-shadow  ${
                          lightMode
                            ? "custom-antd-head-light"
                            : "custom-antd-head-dark"
                        }`}
                      >
                        <CustomTable
                          columns={StockConstituents}
                          data={WatchListdata}
                          scrollable={true}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                    <h2 className="main-header sub-table-news-data" id="News">
                      News
                    </h2>
                    <div className="sub-table-news-data w-100 d-flex flex-wrap mb-30">
                      <NewsTabStockDetails
                        symbol={
                          watchListStore.watchListData[WatchList][0]["symbol"]
                        }
                      />    
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ marginLeft: "5px" }}
                  className="d-flex flex-col watch-list-content"
                >
                  <div className="d-flex flex-col align-items-center w-100">
                    <img src={WatchlistEmpty} width={"60%"} alt=""></img>
                    <div className="d-flex flex-col justify-content-center align-items-center">
                      <p className="fs-20-13">
                        Start adding stocks to watchlist
                      </p>
                      <button
                        onClick={onSearch}
                        className={`p-10 pointer only-PC-view btn-bg-primary text-white w-250px text-white br-3 fw-500 fs-16-14 `}
                      >
                        Search Stocks
                      </button>
                      <button
    
    onClick={()=>setMobileSearchDrop(true)}
                        className={`p-10 pointer only-mobile-view btn-bg-primary text-white w-250px text-white br-3 fw-500 fs-16-14 `}
                      >
                        Search Stocks
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Footer Section Divs and Treanding stocks */}

            <TrendingStocks lightMode={lightMode} />
          </div>
          <Modal
            title={
              <p
                className={`fs-s-18 mb-0 fw-600 ${
                  lightMode ? "text-dark" : "text-white"
                }`}
              >
                Rename Watchlist
              </p>
            }
            visible={isModalVisible}
            centered
            onOk={handleOk}
            wrapClassName={!lightMode && "modelClassname"}
            onCancel={handleCancel}
            footer={[
              <button
                key="submit"
                type="primary"
                className={` w-150px btn-bg-primary text-white  br-5 h-45 fs-s-14 fw-500 `}
                onClick={() => handleOk(NewWatchList)}
              >
                Rename
              </button>,
            ]}
          >
            <div className="w-100">
              <input
                width={"100%"}
                value={NewWatchList}
                style={{ width: "100%" }}
                onChange={(e) => SetNewWatchList(e.target.value)}
                type="text"
                className={`w-100 h-45 br-3 p-3 ${
                  lightMode
                    ? "border1px-light-mode"
                    : "border1px-dark-mode bg-dark-gray"
                }`}
              ></input>
            </div>
          </Modal>

          <Modal
            title={
              <p
                className={`fs-s-18 mb-0 fw-600 ${
                  lightMode ? "text-dark" : "text-white"
                }`}
              >
                Create Watchlist
              </p>
            }
            visible={isModalVisible2}
            centered
            wrapClassName={!lightMode && "modelClassname"}
            onOk={handleOk2}
            onCancel={handleCancel2}
            footer={[
              <button
                key="submit"
                type="primary"
                className={` w-150px btn-bg-primary text-white br-5 h-45 fs-s-14 fw-600 `}
                onClick={() => handleOk2(CreateWatchlist)}
              >
                Create Watchlist
              </button>,
            ]}
          >
            <div className="w-100">
              <input
                width={"100%"}
                style={{ width: "100%" }}
                onChange={(e) => setCreateWatchlist(e.target.value)}
                value={CreateWatchlist}
                type="text"
                className={`w-100 h-45 br-3 p-5 ${
                  lightMode
                    ? "border1px-light-mode"
                    : "border1px-dark-mode bg-dark-gray"
                }`}
              ></input>
            </div>
          </Modal>

          <Modal
            title={
              <p
                className={`fs-s-18 mb-0 fw-600 ${
                  lightMode ? "text-dark" : "text-white"
                }`}
              >
                Clear Watchlist
              </p>
            }
            visible={isModalVisible1}
            wrapClassName={!lightMode && "modelClassname"}
            centered
            onOk={handleOk1}
            onCancel={handleCancel1}
            footer={[
              <button
                key="submit"
                style={{ background: "#AA1919" }}
                type="primary"
                className={` w-150px br-5 fs-s-14 fw-600 ${
                  lightMode ? " text-white h-45" : " h-45"
                }`}
                onClick={handleOk1}
              >
                Confirm
              </button>,
            ]}
          >
            <div className="w-100">
              <p>Do you want to clear your watchlist</p>
            </div>
          </Modal>
          <Modal
            title={
              <p
                className={`fs-s-18 mb-0 fw-600 ${
                  lightMode ? "text-dark" : "text-white"
                }`}
              >
                Delete Watchlist
              </p>
            }
            visible={isModalVisible3}
            centered
            wrapClassName={!lightMode && "modelClassname"}
            onOk={handleOk3}
            onCancel={handleCancel3}
            footer={[
              <button
                key="submit"
                style={{ background: "#AA1919" }}
                type="primary"
                className={` w-150px br-5 fs-s-14 fw-600 ${
                  lightMode ? " text-white h-45" : " h-45 "
                }`}
                onClick={handleOk3}
              >
                Confirm
              </button>,
            ]}
          >
            <div className="w-100   ">
              <p className="mb-0">Do you want to clear your watchlist</p>
            </div>
          </Modal>
        </div>
        <MobileBottomSlider
          lightMode={lightMode}
          setWatchList={setWatchList}
          WatchList={WatchList}
          watchlists={Object.keys(watchListStore.watchListData)}
          planLimit = {plans[PlanName]?.watchlist}
          setIsPremiumModel={setIsPremiumModel}
        />
      </div>
      <PremiumRedirectModal visible={IsPremiumModel} modalPlan='watchlist' setVisible={setIsPremiumModel}/>

    </div>
    </>
  );
}

export default WatchListMain;
