import { Cascader } from "antd";
import React, { useMemo } from "react";
import StockEndpoints from "../../../api/endPoints";
import useGetFetch from "../../../hooks/useGetFetch";

function IndexDropdown({ lightMode, activeValue, navigate }) {
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.getIndexList
  );

  const filteredData = useMemo(() => {
    if (apiData === null) return;
    const nseData = apiData?.filter((element) => element.exchange === "NSE");
    const bseData = apiData?.filter((element) => element.exchange === "BSE");
    
      const finalStuct= [...nseData,...bseData].map((item)=>{
             return {
              label: item.name,
              value: item.symbol,
             }
           })
    return finalStuct
  }, [apiData]);

  const onChange = (value, selectedOptions) => {
    if (Array.isArray(value)) {
      navigate({
        symbol: selectedOptions[0].value,
        label: selectedOptions[0].label,
      });
    }
  };

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  return (
    <div>
      <Cascader
        style={{ width: "305px" }}
        value={activeValue.label}
        className={
          lightMode
            ? "border1px-light-mode mb-10"
            : "border1px-dark-mode antd-Selete-Custom-dark mb-10 "
        }
        dropdownRender={(menus) => (
          <div style={{ maxWidth: "305px" }}>{menus}</div>
        )}
        options={filteredData}
        onChange={onChange}
        placeholder="Select Sector"
        showSearch={{
          filter,
        }}
        onSearch={(value) => console.log(value)}
        dropdownClassName={`${!lightMode && " invert-text dark-ant-cascader "}`}
        allowClear={false}
      />
    </div>
  );
}

export default IndexDropdown;
