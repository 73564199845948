import { Collapse } from "antd";
import React, { useMemo } from "react";
import StockEndpoints from "../../../api/endPoints";
import useGetFetch from "../../../hooks/useGetFetch";

function IndexLeftBar({ lightMode, setactiveIndex, activeIndex }) {
  const { Panel } = Collapse;
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.getIndexList
  );

  const filteredData = useMemo(() => {
    if (apiData === null) return;
    const nseData = apiData?.filter((element) => element.exchange === "NSE");
    const bseData = apiData?.filter((element) => element.exchange === "BSE");
    return {
      nse: nseData,
      bse: bseData,
    };
  }, [apiData]);

  return (
    <div
      className={`w-100  card-shadow  ${
        lightMode ? "bg-white" : "bg-dark-gray"
      }`}
      style={{
        height: "min-content",
        padding: "5px 0px",
        maxHeight: "80vh",
      }}
    >
      <div>
        <p
          style={{
            background:
              "NIFTY" === activeIndex.symbol &&
              (lightMode
                ? "rgba(211, 232, 251, 0.35)"
                : "rgba(167, 182, 196, 0.24)"),
            padding: "10px 16px",
          }}
          onClick={() => setactiveIndex({ symbol: "NIFTY", label: "NIFTY 50" })}
          className={`pointer fs-s-14 fw-600 mb-0 ${lightMode ? "hover-light-blue " : "bg-dark-hover"}`}
        >
          Nifty 50
        </p>
        <p
          style={{
            background:
              "SENSEX" === activeIndex.symbol &&
              (lightMode
                ? "rgba(211, 232, 251, 0.35)"
                : "rgba(167, 182, 196, 0.24)"),
            padding: "10px 16px",
          }}
          onClick={() => setactiveIndex({ symbol: "SENSEX", label: "SENSEX" })}
          className={`pointer fs-s-14 fw-600 mb-0 ${lightMode ? "hover-light-blue " : "bg-dark-hover"}`}
        >
          Sensex
        </p>
        <Collapse
          accordion
          style={{ background: "transparent" }}
          bordered={false}
          expandIconPosition="end"
        >
          <Panel
            key="1"
            className="fs-16-14"
            style={{
              color: lightMode ? "black" : "white",
              padding: "0px",
            }}
            header={
              <p
                className={`fw-600  mb-0  ${
                  lightMode ? "text-black " : "text-white"
                }`}
              >
                NSE
              </p>
            }
          >
            <div
              className="scroll"
              style={{ overflowY: "scroll ", maxHeight: "42vh" }}
            >
              {filteredData?.nse.map((index, i) => (
                <p key={i}
                  onClick={() =>
                    setactiveIndex({ symbol: index.symbol, label: index.name })
                  }
                  style={{
                    color: lightMode ? "black" : "white",
                    padding: "10px 0px 10px 10px",
                    background:
                      index.symbol === activeIndex.symbol &&
                      (lightMode
                        ? "rgba(211, 232, 251, 0.35)"
                        : "rgba(167, 182, 196, 0.24)"),
                    marginRight: "5px",
                    marginBottom: "0px",
                  }}
                  className={`pointer fs-s-14 fw-400 ${
                    lightMode ? "hover-light-blue " : "bg-dark-hover"
                  } `}
                >
                  {index.name}
                </p>
              ))}
            </div>
          </Panel>
          <Panel
            key="2"
            className="fs-16-14"
            style={{
              color: lightMode ? "black" : "white",
              padding: "0px",
            }}
            header={
              <p
                className={`fw-600  mb-0  ${
                  lightMode ? "text-black " : "text-white"
                }`}
              >
                BSE
              </p>
            }
          >
            <div
              className="scroll"
              style={{ overflowY: "scroll ", maxHeight: "42vh" }}
            >
              {filteredData?.bse.map((index, i) => (
                <p key={i}
                  onClick={() =>
                    setactiveIndex({ symbol: index.symbol, label: index.name })
                  }
                  style={{
                    color: lightMode ? "black" : "white",
                    padding: "10px 0px 10px 10px",
                    background:
                      index.symbol === activeIndex.symbol &&
                      (lightMode
                        ? "rgba(211, 232, 251, 0.35)"
                        : "rgba(167, 182, 196, 0.24)"),
                    marginRight: "5px",
                    marginBottom: "0px",
                  }}
                  className={`pointer fs-s-14 fw-400 ${
                    lightMode ? " hover-light-blue " : "bg-dark-hover"
                  } `}
                >
                  {index.name}
                </p>
              ))}
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default IndexLeftBar;
