import React,{useState,useEffect} from 'react'
import { authPost } from '../api/authClient';
import { post } from '../api/MainClient';

function usePostFetch(url=null) {
    const [bodyData, setbodyData] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setApiData] = useState(null);
    const [serverError, setServerError] = useState(null);
  
    useEffect(() => {
        if(url===null||bodyData===null) return
        setIsLoading(true);
        const fetchData = async () => {
          try {
            const resp = await post(url,bodyData);
            const data = await resp?.data;
    
            setApiData(data);
            setIsLoading(false);
          } catch (error) {
            setServerError(error);
            setIsLoading(false);
          }
        };
    
        fetchData();
      }, [url,bodyData]);
    
      return { isLoading, apiData, serverError,setbodyData};
}

export default usePostFetch