import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getIndexconstitients } from "../../api/fetchClient";
import HeatMap from "../../components/Charts/heatMap/HeatMap";
import HeatmapRange from "../../components/Charts/heatMap/HeatmapRange";
import NoData from "../../components/NoData";

function DashboardHeatmap({ index }) {
  const [heatmapdata, setheatmapdata] = useState([]);
  const [filterdData, setfilterdData] = useState([]);
  const [activeRange, setactiveRange] = useState("NONE");
  useEffect(() => {
    const getData = async () => {
      await getIndexconstitients({
        indexname: index,
        page: 1,
        sortBy: "per_change",
        sortHead: true,
        page: { perPage: 5000 },
      }).then(async (res) => {
        await setheatmapdata(res);
       await setfilterdData(res.results);
      });
    };
    getData();
  }, [index]);

  const colorCodeData = [
    {
      label: "Below -5%",
      bgColor: "rgb(161 18 18)",
      onFilter: (key) => key < -5,
    },
    {
      label: "-5  to -2 %",
      bgColor: "rgb(201, 30, 45)",
      onFilter: (key) => key < -2 && key >= -5,
    },
    {
      label: "-2  to 0 %",
      bgColor: "rgb(245, 128, 139)",
      onFilter: (key) => key < 0 && key >= -2,
    },
    {
      label: "0%",
      bgColor: "#858383",
      onFilter: (key) => key === 0,
    },
    {
      label: "0  to +2 %",
      bgColor: "rgb(119 191 138)",
      onFilter: (key) => key > 0 && key <= 2,
    },
    {
      label: `+2  to  +5 %`,
      bgColor: "#119F57",
      onFilter: (key) => key > 2 && key <= 5,
    },
    {
      label: "Above  +5%",
      bgColor: "rgb(2 122 46)",
      onFilter: (key) => key > 5,
    },
  ];

  const onStocksFilter = (index) => {
    setactiveRange(index);
    if (index === "NONE") {
      return setfilterdData(heatmapdata?.results);
    } else {
      const filterArr = heatmapdata?.results?.filter((item, i) =>
        colorCodeData[index]?.onFilter(item.per_change)
      );
      setfilterdData(filterArr);
    }
  };
  return (
    <div>
      <div
        style={{ margin: "0px 0px 0px 0px" }}
        className="d-flex justify-content-between align-items-center"
      >
        <h2 style={{ margin: "20px 0px 10px 0px" }} className="main-header-c ">
          Stock Heatmap
        </h2>
        <Link
          to={`/index/${index}/heatmap`}
          className="text-gray   underline  fs-s-14"
          style={{
            textDecorationColor: "#AEAEAE",
            margin: "20px 0px 10px 0px",
          }}
        >
          View All
        </Link>
      </div>
      <div
        style={{ gap: "0px" }}
        className="d-flex justify-lg-between align-lg-center flex-col-row justify-md-start"
      >
        <p style={{ margin: "10px 0px" }} className="fs-22-18 fw-700 ">
          {index}
        </p>
        <HeatmapRange
          labelData={colorCodeData}
          onStocksFilter={onStocksFilter}
          activeRange={activeRange}
        />
      </div>
      {filterdData?.length > 0 ? (
        <div
          className="scroll "
          style={{ overflowX: "scroll", minHeight: "18.5vh" }}
        >
          <HeatMap mapdata={filterdData} index={"NIFTY"}/>
        </div>
      ) : (
        <div style={{ marginTop: "11vh" }}>
          <NoData errorText="No Stocks" />
        </div>
      )}
    </div>
  );
}

export default DashboardHeatmap;
