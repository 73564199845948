import { LikeFilled } from "@ant-design/icons";
import React, { useMemo } from "react";

function ProsAndConscard({ prosOrCons, data, lightMode }) {
  const fontClr = useMemo(() => {
    if (lightMode) {
      if (prosOrCons) return "#009633";
      else return "#FF0000";
    } else {
      if (prosOrCons) return "#00FF57";
      else return "#F82E2E";
    }
  }, [lightMode, prosOrCons]);
  const rotateAngle = prosOrCons ? 0 : 180;
  return (
    <div
      style={{ width: "100%", minHeight: "307px" }}
      className={`pros d-flex table-shadow mb-20 d-flex-col ${
        lightMode ? " bg-white" : "bg-dark-gray"
      }`}
    >
      <div>
        <p className="fw-700 fs-24-16 mb-0">{prosOrCons ? "Pros" : "Cons"}</p>
      </div>
      <div className="corns-text">
        {data?.map((text, i) => (
          <div key={i} className="d-flex my-15  align-items-center">
            <LikeFilled
              rotate={rotateAngle}
              style={{ color: fontClr, fontSize: "17px " }}
            />{" "}
            <p className="ml-15 mb-0 fs-16-14">
             {text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProsAndConscard;
