import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAffiliatePayout, getAffiliatePayoutTotal } from '../../../../../api/fetchClient';
import SelectOptions from '../../../../../components/ant/SelectOptions';
import CustomTable from '../../../../../components/CustomTable';
import { getThemeMode } from '../../../../../redux/reducers/ThemeSlice';
import numberWithCommas from '../../../../../utilityFn/numberWithCommas';
import { redGreenColorPicker } from '../../../../../utilityFn/redGreenColor';

function AffiliatePayout() {
  const [Payout, setPayout] = useState()
  const [Filter, setFilter] = useState([])
  const [PayoutTotal, setPayoutTotal] = useState()
  
  const [isloading, setisloading] = useState(false)
  const [value, setvalue] = useState()
  useEffect(() => {
    setisloading(true)
    getAffiliatePayout().then((res)=>{
      setPayout(res?.data)
      setisloading(false)
    })
   
  }, [])
  const [selectd, setselectd] = useState();

  useEffect(() => {
    setisloading(true)
    getAffiliatePayout(Filter).then((Res)=>{
      setisloading(false)
      console.log("getAffiliatePayoutTotal",Res);
      setPayout(Res?.data)
    })
  }, [Filter])
  const yeararraay = [{value:"rev_gen_min",label:"is greater than"},{value:"rev_gen_max",label:"is less than or equal to"}];
  const ProductSold = [{value:"prod_min",label:"is greater than"},{value:"prod_max",label:"is less than or equal to"}];
  const Commission = [{value:"com_min",label:"is greater than"},{value:"com_max",label:"is less than or equal to"}];
  const EarnedFilter = [{value:"rev_ear_min",label:"is greater than"},{value:"rev_ear_max",label:"is less than or equal to"}];
  
    const { lightMode } = useSelector(getThemeMode);
    const columns1 = [
      {
        title: <p className="fs-s-16 fw-600 mb-0">Month </p>,
        dataIndex: "date",
        fixed: "left",
        width: "30px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            ),
          };
        },
      },
      {
        title: <p className="fs-s-16 fw-600 mb-0">Product Sold  </p>,
        dataIndex: "no_of_products",
        width: "50px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato "
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            ),
          };
        },
        filterDropdown: (props) => {
          return (
            <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
               <SelectOptions
                lightMode={lightMode}
                data={ProductSold}
                setselectd={setselectd}
              
                width={"200px"}
              />
              <input
              width={"100%"}
              value={value}
              style={{ width: "200px", color: "gray" }}
              placeholder=" Price"
              onChange={(e) => setvalue(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode text-black"
                  : "border1px-dark-mode bg-dark-gray text-white"
              }`}
            ></input>
            <button onClick={()=> setFilter([...Filter,`${selectd}=${value}`])} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
        
            </div>
          );
        },
        filters: [
          {
            text: "Bulls",
            value: "plan=Bulls",
          },
          {
            text: "Sharks",
            value: "plan=Sharks",
          },
          {value:"plan=Whales"
          ,text:"Whales"}
        ],
        ellipsis: true,
      },
     
      {
        title: <p className="fs-s-16 fw-600 mb-0">Revenue Generated</p>,
        width: "50px",
        dataIndex: "revenue_generated",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  color: lightMode ? "black" : "white",
  
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                ₹{text && numberWithCommas(text)}
              </span>
            ),
          };
        },
        filterDropdown: (props) => {
          return (
            <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
               <SelectOptions
                lightMode={lightMode}
                data={yeararraay}
                setselectd={setselectd}
                width={"200px"}
              />
              <input
              width={"100%"}
              value={value}
              style={{ width: "200px", color: "gray" }}
              placeholder=" Price"
              onChange={(e) => setvalue(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode text-black"
                  : "border1px-dark-mode bg-dark-gray text-white"
              }`}
            ></input>
            <button onClick={()=> setFilter([...Filter,`${selectd}=${value}`])} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
        
            </div>
          );
        },
        filters: [
          {
            text: "Bulls",
            value: "plan=Bulls",
          },
          {
            text: "Sharks",
            value: "plan=Sharks",
          },
          {value:"plan=Whales"
          ,text:"Whales"}
        ],
        ellipsis: true,
      },
      {
        title: <p className="fs-s-16 fw-600 mb-0">Commission %</p>,
        width: "50px",
        dataIndex: "slab",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  color: lightMode ? "black" : "white",
  
  
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {text && numberWithCommas(text)}%
              </span>
            ),
          };
        },
        filterDropdown: (props) => {
          return (
            <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
               <SelectOptions
                lightMode={lightMode}
                data={Commission}
                setselectd={setselectd}
                width={"200px"}
              />
              <input
              width={"100%"}
              value={value}
              style={{ width: "200px", color: "gray" }}
              placeholder="Enter Value"
              onChange={(e) => setvalue(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode text-black"
                  : "border1px-dark-mode bg-dark-gray text-white"
              }`}
            ></input>
            <button onClick={()=> setFilter([...Filter,`${selectd}=${value}`])} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
        
            </div>
          );
        },
        filters: [
          {
            text: "Bulls",
            value: "plan=Bulls",
          },
          {
            text: "Sharks",
            value: "plan=Sharks",
          },
          {value:"plan=Whales"
          ,text:"Whales"}
        ],
        ellipsis: true,
      },
      {
        title: <p className="fs-s-16 fw-600 mb-0 ff-lato">Earnings in ₹</p>,
        width: "50px",
        dataIndex: "revenue_earned",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  color: lightMode ? "black" : "white",
  
  
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                ₹{text && numberWithCommas(text)}
              </span>
            ),
          };
        },
        filterDropdown: (props) => {
          return (
            <div className={`${lightMode ? "bg-white" : "bg-dark-gray d-flex d-flex-col text-white"} p-10 d-flex-col`}>
               <SelectOptions
                lightMode={lightMode}
                data={yeararraay}
                setselectd={setselectd}
                width={"200px"}
              />
              <input
              width={"100%"}
              value={value}
              style={{ width: "200px", color: "gray" }}
              placeholder=" Price"
              onChange={(e) => setvalue(e.target.value)}
              type="text"
              className={`w-100 h-45 br-3  p-3 mt-10 portFolio-Modal-inputFeild ${
                lightMode
                  ? "border1px-light-mode text-black"
                  : "border1px-dark-mode bg-dark-gray text-white"
              }`}
            ></input>
            <button onClick={()=> setFilter([...Filter,`${selectd}=${value}`])} className='btn-bg-primary w-200px text-white br-3 mt-10'>Submit</button>
        
            </div>
          );
        },
        filters: [
          {
            text: "Bulls",
            value: "plan=Bulls",
          },
          {
            text: "Sharks",
            value: "plan=Sharks",
          },
          {value:"plan=Whales"
          ,text:"Whales"}
        ],
        ellipsis: true,
      },
      
      {
        title: <p className="fs-s-16 fw-600 mb-0">Status</p>,
        width: "50px",
        dataIndex: "status",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato"
                style={{
                  color: text === "outstanding" ? "#6DB8FD" : redGreenColorPicker(-2,lightMode),
  
  
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {text && numberWithCommas(text)}
              </span>
            ),
          };
        },
      },
   
    ];
    const data = [
      {
        IndexCompany: "Demo Index",
        Points: "58,980.95",
        Change: "480.89",
        ChangePers: "2.98%",
      },
      {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        }, {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        }, {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        }, {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        }, {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        }, {
          IndexCompany: "Demo Index",
          Points: "58,980.95",
          Change: "480.89",
          ChangePers: "2.98%",
        },
    ];
    return (
      <div className="mt-30 px-30-0">
            {/* <p className='fs-s-20 fw-700 mb-0 '>Payout Details</p> */}
            <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-s-20 fw-500'>My monthly payout data</p>
            {/* <p className='fs-s-20 d-flex justify-content-end fw-700 ff-lato'>Pending Amount:  ₹190</p> */}
            </div>
            <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
            <CustomTable data={Payout?.results} loading={isloading} columns={columns1}/>
          </div>
         
      </div>
    )
}

export default AffiliatePayout