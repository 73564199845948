import { Collapse, Switch } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEventTracker, getEventTrackerData } from "../../api/fetchClient";
import CustomPagination from "../../components/ant/CustomPagination";
import CustomTable from "../../components/CustomTable";
import NoData from "../../components/NoData";
import numberWithCommas from "../../utilityFn/numberWithCommas";

function EventTrackerPortfolioAnalysis({
  portfolioid,
  Portfoliostocksdata,
  lightMode,
}) {
  const [EventTrackerData, setEventTrackerData] = useState([]);
  const [Status, setStatus] = useState();
  const navigate = useNavigate();
  const [Trigger, setTrigger] = useState(true);
  const [Page, setPage] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const onPageChange = (e) => {
    setPage(e);
  };
  const dateFormat = ["DD-MM-YYYY"];

  const { Panel } = Collapse;

  const typeObejct = {
    Dividends: "dividend",
    "Board Meetings": "board_meetings",
    Bonus: "bonus",
    Splits: "splits",
    "Rights Issue": "rights",
    "AGM/EGM": "agm",
  };
  const TableColumnData = {
    Dividends: [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Type", "type"],
      ["Ex-Date", "ex_date"],
      ["Record Date", "record_date"],
      ["Rs", "dividend"],
      ["Percentage", "div_ratio"],
    ],
    "Board Meetings": [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Date", "date"],
      ["Agenda", "agenda"],
    ],
    Bonus: [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Annoucement Date", "annnouncement_date"],
      ["Ex Date", "ex_date"],
      ["Record Date", "record_date"],
      ["Bonus Ratio", "ratio"],
    ],
    Splits: [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Ex Date", "date"],
      ["Split Ratio", "ratio"],
    ],
    "Rights Issue": [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Annoucement Date", "annnouncement_date"],
      ["Ex-Date", "ex_date"],
      ["Record Date", "record_date"],
      ["Rights Ratio", "rights_ratio"],
      ["Premium", "premium"],
    ],
    "AGM/EGM": [
      ["Company Name", "stock__COMPNAME","stock__SYMBOL"],
      ["Date", "date"],
      ["Agenda", "agenda"],
    ],
  };
  const columnGen = (array) => {
    const GeneratedColumns =
      array?.length > 0 &&
      array?.map((items) => {
        return {
          title: <p className="fs-s-16 fw-600 mb-0">{items[0]}</p>,
          dataIndex: items[1],
          // fixed:items[0] !== "Company Name" ? "" : "left",
          width: "100px",
          render(text, record) {
            return {
              props: {
                style: {
                  background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                  borderRight: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderLeft: lightMode
                    ? "1px solid #C9D7DE"
                    : "1px solid #545E78",
                  borderBottom: "none",
                },
              },
              children: (
                <span
                  onClick={() =>
                    items[0] === "Company Name" && navigate(`/stock/${record?.stock__SYMBOL}/consolidated`)
                  }
                  className={` ${   items[0] === "Company Name" && "link-hover-underline pointer"} ff-lato  `}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: lightMode ? "black" : "white",
                  }}
                >
                  {text}
                </span>
              ),
            };
          },
        };
      });

    return GeneratedColumns;
  };

 

  const GetTableData = (type) => {
    setisLoading(true)
    getEventTracker(
      portfolioid,
      Trigger ? "upcoming" : "history",
      Page,
      Status
    ).then((res) => {
      setisLoading(false)
      setEventTrackerData(res?.data);
    });
  };

  useEffect(() => {
    setisLoading(true)
    getEventTracker(
      portfolioid,
      Trigger ? "upcoming" : "history",
      Page,
      Status
    ).then((res) => {
      setEventTrackerData(res?.data);
      setisLoading(false)
    });
  }, [Status,portfolioid,Trigger])
  

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        {" "}
        <h2 className="main-header">Event Tracker</h2>
        <div className=" h-50px flex align-items-center pl-10px">
          <Switch
            defaultChecked
            checked={Trigger}
            onChange={(e) => {
              setPage(1);
              setTrigger(e);
            }}
          />
          <span className="">{Trigger ? "Upcoming" : "Historic"}</span>
        </div>
      </div>
      <div>
        <div>
            <Collapse 
              style={{
                background: lightMode ? "#ffff" : "#3B3F4F",
                padding: "10px",
              }}
              
              bordered={false}
              expandIconPosition="end"
              accordion
              onChange={(e) => {
                GetTableData(typeObejct[e]);
                setStatus(typeObejct[e])
              }}
            >
          {Object.keys(typeObejct)?.map((items, i) => {

              {/* {console.log(columnGen(TableColumnData[items]),"THIIS IS THE ")} */}
            return  <Panel
            
              
                key={items}
                className="fs-16-14 collapse-borderless"
                style={{
                  background: lightMode ? "#F2F6F8" : "#292E3F",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
                
                header={
                  <p
                  
                    className={`fw-500 fs-18-16 mb-0 ${
                      lightMode ? "text-black " : "text-white"
                    }`}
                  >
                    {items}
                  </p>
                }
              >
                <div
                  className={`${lightMode ? "text-black" : "text-white"}`}
                >
               {EventTrackerData?.length > 0 ?    <div
                  style={{maxHeight:"600px"}}
                    className={`table-shadow overflow-scroll scroll  ${
                      lightMode
                        ? "custom-antd-head-light"
                        : "custom-antd-head-dark"
                    }`}
                  >
                    <CustomTable
                      columns={columnGen(TableColumnData[items])}
                      data={EventTrackerData}
                      loading={isLoading}
                      scrollable={true}
                      scrollLimit={800}
                      // scrollable={true}LoaderMonth
                      // loading={LoaderMonth}
                    />
                    {/* <CustomPagination
                      onShowSizeChange={false}
                      current={Page}
                      lightMode={lightMode}
                      onChange={onPageChange}
                      total={
                        Math.ceil(
                          Trigger
                            ? EventTrackerData?.count / 10
                            : EventTrackerData?.count / 5
                        ) * 10
                      }
                    /> */}
                  </div> :<NoData/>}
                </div>
              </Panel>
})}

            </Collapse>
        </div>
      </div>
    </div>
  );
}

export default EventTrackerPortfolioAnalysis;
