import { CloseCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { AutoComplete, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function MobileViewComparestocks({
  dropdowndata,
  lightMode,
  makeReset,
  Inputdatais,
  isDisplay,
  setIsDisplay,
  InputDataisOnChange,
  getResults,
  setInputdatais,
  setInputDataIsOnChange,
  removedEmptyData,
  onSubmit,
  setInputFullName,
  InputFullName,
  Validation,
  EmptylessFullName,
  setCountOfInputs,
  CountOfInputs,
  plan,
  TreandingStocks,
}) {
  const [LimitPremuim, setLimitPremuim] = useState(1);

  const [OpenModel, setOpenModel] = useState(false);
  const handleOk2 = () => {
    setOpenModel(false);
  };
  const [number, setnumber] = useState(3);
  // useEffect(() => {
  //   if (removedEmptyData?.length > 2) {
  //     setCountOfInputs(removedEmptyData?.length);
  //   }
  // }, [removedEmptyData?.length]);

  const handleCancel2 = () => {
    setOpenModel(false);
  };
  const navigate = useNavigate();
  {
  }


  


  return (
    <div className="w-100 ">
      <div className="d-flex w-100 flex-wrap justify-content-center">
        {Object.keys(CountOfInputs).map((items, i) => (
          <div
            className={`Stockcompare-stock-card p-10 relative  w-44 m-10px d-flex align-items-center justify-content-center flex-col ${
              lightMode ? "bg-white" : "bg-dark"
            }`}
          >

            {Object.keys(CountOfInputs).length > 2 && (
              <div
                onClick={() => {
                  // CountOfInputs.splice(1,1);

                  // setCountOfInputs((products) => products.filter((_, index) => index !== 2));
                  // setCountOfInputs((products) =>products.filter((_, index) =>index !== i) );
                  setInputDataIsOnChange({
                    ...InputDataisOnChange,
                    [Object.keys(InputDataisOnChange)[i]]: "",
                  });
                  setInputdatais({
                    ...Inputdatais,
                    [Object.keys(Inputdatais)[i]]: "",
                  });
                  setInputFullName({
                    ...InputFullName,
                    [Object.keys(InputFullName)[i]]: "",
                  });

                  setCountOfInputs((current) => {
                    const rest = { ...current };
                    delete rest[items];
                    return rest;
                  });

                }}
                className="absolute Stockcompare-stock-card-close"
              >
                <CloseCircleFilled />
              </div>
            )}


            <div className=" d-flex w-100 flex-col align-items-center">
              <div
                className={`mt-20 w-100 p-5 fs-s-14 fw-600 ${
                  lightMode
                    ? "stock-comapre-input-light"
                    : "stock-comapre-input-dark"
                }`}
              >
                {/* {console.log("planData",)} */}
                <AutoComplete
                  style={{
                    width: "100%",
                  }}
                  dropdownClassName={`${
                    !lightMode && "drop-down-stock invert-text"
                  }`}
                  className={
                    lightMode
                      ? "border1px-light-mode"
                      : "border1px-dark-mode antd-Selete-Custom-dark mb-10"
                  }
                  onFocus={() => getResults("")}
                  value={[Object.values(CountOfInputs)[i]]}
                  options={dropdowndata}
                  placeholder=" Select Stock"
                  onChange={(e) => {
                    getResults(e);
                    setCountOfInputs({
                      ...CountOfInputs,
                      [Object.keys(CountOfInputs)[i]]: e,
                    });
                  }}
                  notFoundContent="No Stocks"
                  defaultActiveFirstOption={true}
                  onSelect={(e, options) => {
                    setInputDataIsOnChange({
                      ...InputDataisOnChange,
                      [Object.keys(InputDataisOnChange)[i]]: options?.label,
                    });
                    setCountOfInputs({
                      ...CountOfInputs,
                      [Object.keys(CountOfInputs)[i]]: options?.label,
                    });
                    setInputdatais({
                      ...Inputdatais,
                      [Object.keys(Inputdatais)[i]]: isNaN(options?.value) ?(options?.value).replace("&","%26"):options?.value,
                    });
                    setInputFullName({
                      ...InputFullName,
                      [Object.keys(InputFullName)[i]]: options?.label,
                    });
                  }}
                  disabled={plan < LimitPremuim + i ? true : false}
                />
                {Validation ? (
                  <p className="fs-s-12 mb-0" style={{ color: "#F82E2E" }}>
                    minimum two fields must be filled
                  </p>
                ) : (
                  ""
                )}
                {Object.values(CountOfInputs)[i]?.length > 0 &&
                  Object.keys(InputFullName)[i]?.length === 0 && (
                    <p
                      className="fs-s-12 mb-0 absolute "
                      style={{ bottom: "8px", color: "#F82E2E" }}
                    >
                      Select Stock
                    </p>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {Object.keys(CountOfInputs).length < 5 && (
        <div
          className="d-flex justify-content-center fs-s-16"
          onClick={() => {
            plan <= Object.keys(CountOfInputs).length
              ? navigate("/getpremium")
              : setCountOfInputs({ ...CountOfInputs, ["name" + number]: "" });
            setnumber(number + 1);
          }}
        >
          <PlusCircleFilled />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center mt-10 w-100">
        <div className=" mb-10 only-mobile-view ">
          {isDisplay && Inputdatais?.firstvalue?.length > 0 ? (
            <button
              onClick={makeReset}
              className={` fw-600 fs-20-16 pointer fw-500 fs-s-14 w-200px animation-button text-white btn-bg-primary br-3 p-5 ${
                lightMode ? "bg-dark text-white" : "bg-light-gray text-white"
              }`}
            >
              Reset
            </button>
          ) : (
            <button
              onClick={() => onSubmit()}
              className={` fw-500 fs-20-16 w-200px text-white fs-s-14 w-200px animation-button btn-bg-primary  pointer br-3 p-10   ${
                lightMode ? "bg-dark " : "bg-light-gray "
              }`}
            >
              Compare Stocks
            </button>
          )}
        </div>
      </div>
      <Modal
        title={
          <p
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Create Watchlist
          </p>
        }
        visible={OpenModel}
        centered
        wrapClassName={!lightMode && "modelClassname"}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
          <button
            key="submit"
            type="primary"
            className={` w-150px btn-bg-primary text-white br-5 h-45 fs-s-14 fw-600 `}
            onClick={() => handleOk2()}
          >
            Create Watchlist
          </button>,
        ]}
      >
        <div className="w-100"></div>
      </Modal>
    </div>
  );
}

export default MobileViewComparestocks;
