import React from 'react'
import ReactHelmet from '../../components/react-helmet/ReactHelmet'
import HeaderParagraph from './HeaderParagraph'

function Refund() {

    const data = [{
            
        header:"General Disclaimer",
        value:<p>Please check the payment plans carefully before subscribing to any of the premium plans as there is no refund or cancellation applicable once the payment is made on the Tradebrains Portal platform. All the payments made to this platform are non-refundable, irrevocable and non-creditable. But you can always change your plan anytime further increasing the validity of your current chosen plan. In addition, if you need any help or support, you can reach out to us using the contact page.</p>
    }]
  return (
    <>
     <ReactHelmet
        title='Refund Policy at Trade Brains Portal'
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="my-body">
    <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
      <div>
        <p className="fs-40-32 d-flex fw-700 align-items-center justify-content-center">
        Refund Policy
        </p>
      </div>
     
      <div>
          <HeaderParagraph data={data}/>
      </div>
    </div>
  </div>
  </>
  )
}

export default Refund