import React, { useEffect, useMemo, useState } from "react";
import { getFactSheetCompareStocks } from "../../../api/fetchClient";
import SelectOptions from "../../../components/ant/SelectOptions";
import CustomTable from "../../../components/CustomTable";
import convertTableStructure from "../convertTableStructure";
import { FactSheetStockComapreData ,FactSheetStockComapreDataBanking} from "../TableType";

function CompareFactSheet({
  lightMode,
  removedEmptyData,
  symbolsandnames,
  columns,
  CompanyType,
}) {
  const [selectd, setselectd] = useState();
  const [factSheet, setFactSheet] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [LatestYear, setLatestYear] = useState();

 
  useEffect(() => {
    const getData = async () => {
      setisLoading(true);
    let yearstr = `year=${selectd}`;
      getFactSheetCompareStocks(removedEmptyData, selectd ? yearstr : "", CompanyType).then(
        async (res) => {
          setFactSheet(res.data);
          LatestYear === undefined && setLatestYear(res?.data?.year);
          setisLoading(false);
        }
      );
    };
    getData();
  }, [selectd, CompanyType]);
  const yeararraay = [];

  for (var i = 0; i < 5; i++) {
    yeararraay.push({ value: LatestYear - i, label: LatestYear - i });
  }
  const returnsTableData = useMemo(() => {
    if (factSheet?.stocks === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        factSheet?.stocks,
        factSheet?.is_banking ? FactSheetStockComapreDataBanking : FactSheetStockComapreData,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [factSheet,lightMode]);

  return (
    <div id="5 Yr Factsheet">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c ">Factsheet</h2>
        </div>
        {LatestYear &&  <div className="d-flex flex-col align-items-end">
          <SelectOptions
            lightMode={lightMode}
            data={yeararraay}
            setselectd={setselectd}
            defaultSelect={LatestYear}  
            width={116}
          />
        </div>}
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={returnsTableData?.columns}
          scrollable={true}
          data={returnsTableData?.tableBodyData}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default CompareFactSheet;
