import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import logoWhite from "../../../../../assets/images/logo/logoWhite.png";
import logo from "../../../../../assets/images/PortalLogo.png";
import AffiliateNav from "../../AffiliateNav";
import BankUpdateBanner from "../../BankUpdateBanner";
import { getAffiliateBank, getAffiliatesignupdata } from "../../../../../api/fetchClient";

function AffliateDash({ setisLoginPage }) {
  const navigate = useNavigate();
const location=useLocation()
  useEffect(() => {
    setisLoginPage(true);
  }, []);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [open, setOpen] = useState(true);
  const [Value, setValue] = useState("Dashboard");
  const [Mobile, setMobile] = useState(false);
  const [bankStatus, setbankStatus] = useState('No_ACC');
  const showDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    navigate("dashboard", { replace: true });

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const getData = async () => {
    await getAffiliateBank().then((resp) => {
      setbankStatus(resp.data.status);
    });
  };
  useEffect(() => {
    getData();
  }, [location.pathname]);
  useEffect(() => {
    if (windowSize?.innerWidth < 1000) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowSize?.innerWidth]);

  const data = {
    Dashboard: { name: "Dashboard", link: "Dashboard" },
    Sales: { name: "Sales", link: "Sales" },
    PayoutDetails: { name: "Payout Details", link: "payoutdetails" },
    SignUps: { name: "Sign Ups", link: "SignUps" },
    Promotions: { name: "Promotions", link: "Promotions" },
    HelpCenter: { name: "Help Center", link: "HelpCenter" },
  };

  const onClose = () => {
    setOpen(false);
  };

  const chackData = () => {
    getAffiliatesignupdata().then((res) => {
      if (res?.data?.is_tc_accepted === false) {
        navigate("/affiliate/terms&conditions");
      }
    });
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const { lightMode } = useSelector(getThemeMode);

  return (
    <div className="h-100">
      <div className=" w-100 h-100vh relative">
        <AffiliateNav showDrawer={showDrawer} open={open} value={Value} />
        <div
          className={`${
            open && "pl-300 affliate-dash  scroll "
          } px-20 affliate-dash `}
        >
          <BankUpdateBanner bankStatus={bankStatus}/>
          <Outlet />
        </div>
        <Drawer
          drawerStyle={{
            background: lightMode ? "#F2F6F8" : "#353a49",
            color: lightMode ? "black" : "white",
          }}
          headerStyle={{ marginInline: "16px", padding: "0px" }}
          bodyStyle={{ margin: "16px 0px 0px 0px", padding: "0px" }}
          placement={"left"}
          closable={Mobile && true}
          onClose={() => {
            Mobile && setOpen(false);
          }}
          visible={open}
          key="tools"
          width={"290px"}
          height="auto"
          zIndex={500}
          className={`${
            lightMode ? "drawer-lightmode" : "drawer-darkmode"
          } w-unset`}
        >
          <div className="">
            <div className="ml-30 mt-20">
              {/* Knowledge Center  */}
              <div className="w-30 d-flex-col">
                <Link to={"/"}>
                  <div
                    style={{ width: "160px" }}
                    className="  flex align-items-center"
                  >
                    <img
                      src={lightMode ? logo : logoWhite}
                      className=""
                      width={"160"}
                      alt="logo"
                    />
                  </div>
                </Link>
                <div style={{ width: "160px" }} className="mt-30 ">
                  {Object.values(data)?.map((items) => (
                    <p
                      onClick={() => {
                        navigate(`${items?.link}`);
                        setValue(items?.name);
                        chackData();
                        Mobile && setOpen(false);
                      }}
                      className={`fs-s-18 pointer ${
                        Value === items?.name && "fw-700"
                      }`}
                    >
                      

                      {items?.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default AffliateDash;
