import BusinessStandard from "../../../assets/images/symbolImg/Business Standard.png";
import businessworld from "../../../assets/images/symbolImg/business world.png";
import cardrates from "../../../assets/images/symbolImg/card rates.png";
import DailyHunt from "../../../assets/images/symbolImg/Daily Hunt.png";
import deccanchroniccle from "../../../assets/images/symbolImg/deccan chroniccle.png";
import hindustantimes from "../../../assets/images/symbolImg/hindustan times.png";
import livemint from "../../../assets/images/symbolImg/live mint.png";
import MarketWatch from "../../../assets/images/symbolImg/Market Watch.png";
import theprint from "../../../assets/images/symbolImg/the print.png";
import timesOfIndia from "../../../assets/images/symbolImg/times of india.png";
import dailyhuntDark from "../../../assets/images/symbolImg/daily hunt white.png";
import deccanchroniccleDark from "../../../assets/images/symbolImg/deccan chronical white.png";
import MarketWatchDark from "../../../assets/images/symbolImg/market watch white.png";
import hindustantimesSDark from "../../../assets/images/symbolImg/hindustan times white theme.png";
import cardratesDark from "../../../assets/images/symbolImg/card-rates-white-redo.png";
import timesOfIndiaDark from "../../../assets/images/symbolImg/times-of-india-white-redo.png";
import livemintDark from "../../../assets/images/symbolImg/live-mint-white-redo.png";

export const lightModeImgs = [
  BusinessStandard,
  businessworld,
  cardrates,
  DailyHunt,
  deccanchroniccle,
  hindustantimes,
  livemint,
  theprint,
  MarketWatch,
  timesOfIndia,
  BusinessStandard,
  businessworld,
  cardrates,
];
export const darkModeImgs = [
  BusinessStandard,
  businessworld,
  cardratesDark,
  dailyhuntDark,
  deccanchroniccleDark,
  hindustantimesSDark,
  livemintDark,
  theprint,
  MarketWatchDark,
  timesOfIndiaDark,
  BusinessStandard,
  businessworld,
  cardratesDark,
];
export const redirectURLs = [
  "https://www.business-standard.com/content/press-releases-ani/indian-entrepreneur-takes-the-initiative-launches-indigenous-stock-analysis-portal-trade-brains-121021501027_1.html",
  "https://www.businessworld.in/article/Indian-entrepreneur-takes-the-initiative-launches-indigenous-stock-analysis-portal-trade-brains/15-02-2021-377872/",
  "https://www.cardrates.com/news/trade-brains-on-investing-and-avoiding-credit-reliance/",
  "https://m.dailyhunt.in/news/india/english/newsvoir-epaper-newsvoir/indian+entrepreneur+takes+the+initiative+launches+indigenous+stock+analysis+portal+trade+brains-newsid-n254403640",
  "https://www.deccanchronicle.com/in-focus/140422/fingrad-a-fool-proof-platform-for-comprehensive-financial-learning.html",
  "https://www.hindustantimes.com/brand-post/trade-brains-aims-to-make-diy-investing-mainstream-in-india-101624550340167.html",
  "https://www.livemint.com/brand-post/trade-brains-launches-fingrad-a-whole-new-level-of-financial-education-platform-11649847624413.html",
  "https://theprint.in/ani-press-releases/trade-brains-is-organizing-biggest-stock-market-quiz-event-elevate/713772/",
  "https://www.marketwatch.com/press-release/trade-brains-announced-elevate-21-biggest-stock-market-quiz-competition-2021-08-12",
  "https://timesofindia.indiatimes.com/indian-entrepreneur-takes-the-initiative-launches-indigenous-stock-analysis-portal-trade-brains/articleshow/80926432.cms",
  "https://www.business-standard.com/content/press-releases-ani/indian-entrepreneur-takes-the-initiative-launches-indigenous-stock-analysis-portal-trade-brains-121021501027_1.html",
  "https://www.businessworld.in/article/Indian-entrepreneur-takes-the-initiative-launches-indigenous-stock-analysis-portal-trade-brains/15-02-2021-377872/",
  "https://www.cardrates.com/news/trade-brains-on-investing-and-avoiding-credit-reliance/",
];
