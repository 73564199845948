import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import svgSheet from "../../../assets/svg/svgSheet";
import TrendingStocks from "../../../components/TrendingStocks";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import LeftSidebar from "../../Buckects/sidebar/LeftSidebar";
import MobileBottomBar from "../../stock details/MobileBottomBar";
import useGetFetch from "../../../hooks/useGetFetch";
import StockEndpoints from "../../../api/endPoints";
import TextReducer from "../../../components/TextReducer";
import TotalHoldings from "./TotalHoldings";
import DotLoader from "../../../components/spinners/DotLoader";
import StarStockList from "./StarStockList";
import BreadCrumb from "../../../components/BreadCrumb";
import SectorDiverse from "./SectorDiverse";
import StockFaq from "../../stock details/StockFaq";
import { bannerOffsetCalc } from "../../../utilityFn/bannerOffsetcalc";
import { authStore } from "../../../redux/reducers/authSlice";
import ReactHelmet from "../../../components/react-helmet/ReactHelmet";

function StarDetailsIndex() {
  const { name } = useParams();
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  // get start details
  const {
    isLoading,
    apiData: starData,
    serverError,
  } = useGetFetch(StockEndpoints.starDescdata(name));
  const {
    isLoading: loading,
    apiData: faqData,
    serverError: faqErr,
  } = useGetFetch(StockEndpoints.getFaq(`superstar/?superstar=${name}`));

  // get all gainers
  const convertFaq = useMemo(() => {
    if (!faqData) return;
    const testdata = faqData?.map((item) => {
      return {
        head: item.question,
        content: item.answer,
      };
    });
    return testdata;
  }, [faqData, name]);

  const sidebarData = [
    {
      id: "Overview",
      icon: svgSheet.overviewIcon,
      label: "Overview",
      offset: -250 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Total Holding",
      icon: svgSheet.graphIcon,
      label: "Total Holding",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Diversification",
      icon: svgSheet.pieIcon,
      label: "Diversification",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Stock List",
      icon: (
        <div style={{ width: "24px", height: "24px" }}>{svgSheet.newsIcon}</div>
      ),
      label: "Stock List",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
  ];
  const topInverstors = [
    {
      image:
        "https://tradebrains.in/wp-content/uploads/2020/10/RK-Damani-Cover.jpg",
      label: "Radhakishan Damani",
    },
    {
      image:
        "https://tradebrains.in/wp-content/uploads/2020/10/Vijay-Kedia-Cover.jpg",
      label: "Vijay Kedia",
    },
    {
      image:
        "https://tradebrains.in/wp-content/uploads/2020/10/Dolly-Khanna-Cover.jpg",
      label: "Dolly Khanna",
    },
    {
      image:
        "https://tradebrains.in/wp-content/uploads/2020/10/Ashish-Kacholia-cover.jpg",
      label: "Ashish Kacholia",
    },
  ];
  const breadCrumbData = [
    { label: "Superstar Portfolio", link: `/superstars` },
  ];
  if (isLoading) {
    return <DotLoader />;
  }
  return (
    <>
      <ReactHelmet
        title={`Superstar Portfolio - Track the portfolio of your favourite ${name} -  Trade Brains`}
        desc="Track the investments and latest portfolios of the super investors in India share market"
      />
      <div className="ff-poppins max-w mx-auto  px-15  w-100 relative">
        <div className="my-body">
          <h1 className=" mb-0 fs-36-20 fw-700 mb-10 line-h-36">{name}</h1>
          <div className="mb-20">
            <BreadCrumb linkData={breadCrumbData} current={name} />
          </div>
          <div className="d-flex">
            <div
              style={{
                width: "20%",
                top: `${130 + bannerOffsetCalc(UserAuth)}px`,
                height: "min-content",
              }}
              className="detail-left-card p-fixed"
            >
              <LeftSidebar
                lightMode={lightMode}
                sidebarData={sidebarData}
                subHead="Other Ace Investors"
                subData={topInverstors}
                baseURL="/superstars"
              />
              {/* <RecentSearch lightMode={lightMode} /> */}
            </div>
            <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
              {/* overview  */}
              <div id="Overview">
                <h2
                  className="fs-28-18 fw-600 d-flex  mb-20 "
                  style={{ lineHeight: "27px" }}
                >
                  Overview
                </h2>
                <img
                  src={starData?.featured_image ?? starData?.image}
                  className="w-100  br-5"
                  style={{ maxHeight: "440px", objectFit: "contain" }}
                  alt="img"
                />
                <h4 className="fs-18-16 fw-700 my-20">Biography</h4>
                <TextReducer
                  data={starData?.bio}
                  textLimit={950}
                  lightMode={lightMode}
                />
              </div>
              {/* overview  */}
              {/* Total Holding  */}
              <div id="Total Holding">
                <h2
                  className="fs-28-18 fw-600 d-flex  mb-20 "
                  style={{ lineHeight: "27px" }}
                >
                  Total Holding
                </h2>
                <TotalHoldings lightMode={lightMode} name={name} />
              </div>
              {/* Total Holding  */}
              {/* Diversification  */}
              <div id="Diversification">
                <h2
                  className="fs-28-18 fw-600 d-flex my-30 "
                  style={{ lineHeight: "27px" }}
                >
                  Diversification
                </h2>
                <SectorDiverse lightMode={lightMode} name={name} />
              </div>
              {/* Diversification  */}
              <div id="Stock List">
                <h2
                  className="fs-28-18 fw-600 d-flex my-30 "
                  style={{ lineHeight: "27px" }}
                >
                  Stock List
                </h2>
                <StarStockList
                  lightMode={lightMode}
                  stockData={starData?.stocks}
                  portId={starData?.id}
                  name={name}
                />
              </div>
              {convertFaq && (
                <StockFaq data={convertFaq} lightMode={lightMode} />
              )}
            </div>
          </div>
          <TrendingStocks lightMode={lightMode} />
        </div>
      </div>
      <MobileBottomBar
        navData={sidebarData}
        offset={-125}
        lightMode={lightMode}
      />
    </>
  );
}

export default StarDetailsIndex;
