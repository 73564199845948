import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllTradeRecordsdata,
  getRelizedProfitPortfolio,
} from "../../api/fetchClient";
import SelectOptions from "../../components/ant/SelectOptions";
import CustomTable from "../../components/CustomTable";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function AllTradeRecords() {
  const location = useLocation();
  const paramsData = useParams()
  const navigate = useNavigate();
  const dateFormat = ["DD-MM-YYYY", "DD-MM-YY"];
  const [allTradeRecords, setallTradeRecords] = useState();
  const [isLoading, setisLoading] = useState(false)
  const [allRealizedData, setallRealizedData] = useState();
  const [selectd, setselectd] = useState();
  const [selectd1, setselectd1] = useState();

  console.log("locationsss", location);
  useEffect(() => {
    setisLoading(true)
    getAllTradeRecordsdata(paramsData?.id, selectd1).then((res) => {
      setallTradeRecords(res?.data);
      setisLoading(false)
    });
  }, [selectd1]);

  useEffect(() => {
    getRelizedProfitPortfolio(paramsData?.id,selectd).then((res) => {
      setallRealizedData(res?.data);
    });
  }, [selectd]);


  const yeararraay = [
  { value: "90", label: "last 90 days" },
  { value: "180", label: "last 180 days" },
 ,]
 const yeararraay1 = []
 
const currentMarketYear = new Date()
    if(currentMarketYear.getMonth() < 4){
      yeararraay.push({ value: currentMarketYear.getFullYear()-1, label: currentMarketYear.getFullYear()-1 },{ value: currentMarketYear.getFullYear()-2, label:currentMarketYear.getFullYear()-2 })
    }else{
      yeararraay.push({ value: currentMarketYear.getFullYear(), label: currentMarketYear.getFullYear() },{ value: currentMarketYear.getFullYear()-1, label: currentMarketYear.getFullYear()-1 })

    }
    yeararraay.push( { value: "All Records", label: "All Records" },)
    if(currentMarketYear.getMonth() < 4){
      for(var i =0 ; i<5;i++){
        yeararraay1.push({ value: currentMarketYear.getFullYear()-(i+1), label: "FY" +" " +(currentMarketYear.getFullYear()-(i+1)) },)
      }
   }else{
    for(var i =0 ; i<5;i++){
      yeararraay1.push({ value: currentMarketYear.getFullYear()-i, label: "FY" + " "+( currentMarketYear.getFullYear()-i) },)
    
    }}
    yeararraay1.push( { value: "All Records", label: "All Records" },)


  const { lightMode } = useSelector(getThemeMode);
 

  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
      dataIndex: "company",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated` }>
              <span
                className=" link-hover-underline"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            </Link>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Type</p>,
      dataIndex: "transcation_type",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className=""
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text === "buy" ? 1 : -10),
              }}
            >
              {text === "buy" ? "Buy" : "Sell"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Qty </p>,
      width: "50px",
      dataIndex: "quantity",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",

                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Date</p>,
      dataIndex: "date",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text ? moment(text).format(dateFormat[0]) : undefined}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Price</p>,
      dataIndex: "price",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + " " + numberWithCommas(text)}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Total Price</p>,
      dataIndex: "total_price",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && "₹" + " " + numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
  ];
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Stock</p>,
      dataIndex: "company",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated`}>
            <span
              className="pointer  link-hover-underline"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
            </Link>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Purchased Date</p>,
      dataIndex: "purchased_date",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {" "}
              {text ? moment(text).format(dateFormat[0]) : undefined}
              {/* {text && "₹" + numberWithCommas(text)} */}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Sell Date </p>,
      width: "50px",
      dataIndex: "sold_date",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text ? moment(text).format(dateFormat[0]) : undefined}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Days Held</p>,
      dataIndex: "holding_period",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Qty</p>,
      dataIndex: "quantity_sold",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Purchase Price</p>,
      dataIndex: "purchased_price",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && "₹" +" " +  numberWithCommas(text)}

              {/* {text} */}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Selling Price</p>,
      dataIndex: "sold_price",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && "₹" +" " +  numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Realized P / L</p>,
      dataIndex: "realized_profit",
      width: "50px",

      render(text, record) {
        const textwithComma = text.toFixed(2) 

        return {
          
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color:redGreenColorPicker(text,lightMode),
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
            {text > 0 ? "+" : "-"}{" "}₹{" "}{text > 0  ? numberWithCommas(Number(textwithComma)) : numberWithCommas(Math.abs(Number(textwithComma)) )}

            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Type</p>,
      dataIndex: "profit_type",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className=""
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text === "STCG" ? "Short Term " : "Long Term"}
            </span>
          ),
        };
      },
    },
  ];



  return (
    <div className="my-body">
      <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-30-20  mb-0  fw-700">All Trade Records</p>{" "}
          <SelectOptions
              lightMode={lightMode}
              data={yeararraay}
              setselectd={setselectd1}
              defaultSelect={"90"}
              width={150}
            />
        </div>
        <p className="mb-20 pointer d-flex">
          <p className="mb-0 Textcapitalize mr-5" onClick={() => {location?.pathname?.split('/')[2] !== undefined && navigate(`/portfolio/${Number(location?.pathname?.split('/')[2])}`,{state:location?.state?.name})} }>
            {location?.state?.name}{" "}
          </p>
          / All Trade Records
        </p>

        <div
          className={`table-shadow   ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            columns={columns}
            data={allTradeRecords}
            isLoading={isLoading}
            scrollable={true}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-30-20  my-40 fw-700">Realised Profit / Loss</p>{" "}
          <SelectOptions
              lightMode={lightMode}
              data={yeararraay1}
              setselectd={setselectd}
              defaultSelect={currentMarketYear.getFullYear()}
              width={116}
            />
        </div>
        <div
          className={`table-shadow   ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            columns={columns1}
            data={allRealizedData?.items}
            scrollable={true}
          />
        </div>
        <div className="d-flex mt-40">
          <div className="d-flex align-items-center mr-30">
            <p className="fs-30-20 mb-0 fw-600 mr-20">STCG Tax </p>
            <p className="fs-s-18 ff-lato mb-0">₹ {allRealizedData?.sctg_tax}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="fs-30-20 mb-0 fw-600  mr-20">LTCG Tax </p>
            <p className="fs-s-18 ff-lato mb-0">₹ {allRealizedData?.ltcg_tax}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTradeRecords;
