import React from "react";

function StepCard({ lightMode, data }) {
  return (
    <div
      style={{ minHeight: "235px",minWidth:'30%'}}
      className={`p-20 d-flex flex-col  justify-content-between ${
        lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <div style={{width:'50px',height:'50px',borderRadius:'50%'}} className={`d-flex div-items-center ${
        lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}>{data.icon}</div>
        <h3 className="fs-s-20 fw-700 mb-10">{data.head}</h3>
        {data.content}
    </div>
  );
}

export default StepCard;
