import React from "react";
import { Link } from "react-router-dom";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";
import loadergif from "../../../assets/images/loadergif/Free-Content.gif";
import NoData from "../../../components/NoData";
function MarketMoverTable({
  lightMode,
  tableData,
  tableHead,
  link,
  isLoading,
}) {
  return (
    <div
      className={`p-10md-20lg w-100 h-100 ${
        lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <div className="d-flex  justify-content-between align-items-center mb-20">
        <h3 className="fs-28-20 fw-700">{tableHead}</h3>
        <Link to={link} className="mb-0 fs-14-12 underline">
          View All
        </Link>
      </div>

      { !isLoading&&tableData?.length>0  ? (
        <table className="w-100 home-table">
          <tbody>
            <tr className={lightMode?'text-dark-mode':'text-gray-sec'}>
              <th>Company</th>
              <th>Price</th>
              <th>Percent</th>
              <th>Change</th>
            </tr>
            {tableData?.map((item, i) => (
              <tr key={i}>
                <td className="w-40 link-hover-underline">
                  <Link
                    className="fw-500 overFlow-text "
                    to={`stock/${item?.symbol}/consolidated`}
                  >
                    {item.comp_name}
                  </Link>
                </td>
                <td className="w-20p">₹{item?.close}</td>
                <td
                  style={{
                    color: redGreenColorPicker(item.percent, lightMode),
                  }}
                  className="w-20p"
                >
                  {item.percent > 0 && "+"}
                  {item.percent?.toFixed(2)}%
                </td>
                <td
                  style={{
                    color: redGreenColorPicker(item.percent, lightMode),
                  }}
                  className="w-20p"
                >
                  {item.change > 0 ?"+":'-'}
                  ₹{Math.abs(item?.change)?.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) :
       tableData?.length===0&&!isLoading ? (
        <NoData errorText="No market movers"/>
      ) :
      (
        <div
          style={{ minHeight: "250px" }}
          className="d-flex div-items-center w-100"
        >
          <img className="h-50px w-50px" src={loadergif} alt="load" />
        </div>
      )}
    </div>
  );
}

export default MarketMoverTable;
