import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../../../../components/CustomTable";

function PortfolioTable({ lightMode, tabledata, setDeleteModal }) {
  const [Page, setPage] = useState({ page: 1, perPage: 25 });

  const tableColumnData = [
    {
      title: "Superstar",
      apiKey: "name",

      returnText: (text, record) => (
        <Link
          to={`/admin/star_portfolio/edit/${record.id}`}
          state={{ data: record }}
          className="ff-lato pointer link-hover-underline"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Action",
      apiKey: "",
      returnText: (text) => text,
    },
  ];
  const starTableData = useMemo(() => {
    const resp = tableColumnData?.map((data, i) => {
      return {
        title: <p className="fs-s-16 fw-600 mb-0">{data.title}</p>,
        dataIndex: data.apiKey,
        // fixed: i === 0 ? "left" : "",
        width: "10px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato "
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {data.title === "Action" ? (
                  <div className="flex">
                    <Link
                      to={`/admin/star_portfolio/edit/${record.id}`}
                      state={{ data: record }}
                      className="ff-lato pointer "
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <EditOutlined />
                    </Link>
                    <span
                      className="ff-lato pointer"
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: lightMode ? "black" : "white",
                      }}
                      onClick={() => setDeleteModal(record.id)}
                    >
                      <DeleteOutlined />
                    </span>
                  </div>
                ) : (
                  data.returnText(text, record)
                )}
              </span>
            ),
          };
        },
      };
    });
    return resp;
  }, []);

  return (
    <div className="mt-40">
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          data={tabledata}
          columns={starTableData}
          scrollable={true}
          scrollLimit={400}
        />
        {/* <CustomPagination
          current={Page.page}
          pageSize={Page.perPage}
          lightMode={lightMode}
          onChange={onPageChange}
          total={tableData?.count}
        /> */}
      </div>
    </div>
  );
}

export default PortfolioTable;
