import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import svgSheet from "../../assets/svg/svgSheet";
function LeftSectionCompanies({ lightMode, Navdata }) {
  const [tab, settab] = useState("Overview");

  return (
    <div
      className={`Brockers-div-indexdetails card-shadow d-flex flex-col ${
        lightMode ? "bg-white" : " bg-dark-gray"
      }`}
    >
      {Navdata.map((items,i) => (
        <Link key={i}
          activeClass={lightMode ? "desktop-active" : "desktop-active-dark"}
          to={items.id}
          smooth={true}
          offset={items.offset}
          duration={500}
          spy={true}
          className={`px-20 flex align-items-center ${
            lightMode
              ? "bb1px-light-mode hover-light-blue"
              : "bb1px-dark-mode bg-dark-hover"
          }`}
          style={{ minHeight: "45px" }}
        >
          {items.icon}
          <span className=" fs-s-15 fw-500">{items.id}</span>
        </Link>
      ))}
    </div>
  );
}

export default LeftSectionCompanies;
