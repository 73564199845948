import React, { useEffect, useState } from "react";
import { getPortfolioAnalysisCardData } from "../../api/fetchClient";
import PortFolioCard from "./PortFolioCard";

function PortfolioMainCards({ lightMode, portfolioid,portfolioLength,setstatusdata ,statusdata}) {
  const [getData, setGetData] = useState();
console.log("setstatusdatasetstatusdata",statusdata);
  useEffect(() => {
    getPortfolioAnalysisCardData({ portfolioid }).then((res) => {
      setGetData(res?.data);
    });
  }, [portfolioid,portfolioLength]);
 
  return (
    <div
      style={{ gap: "10px" }}
      className="portfolio-right-section d-flex flex-wrap  w-100 "
    >
      <PortFolioCard
        lightMode={lightMode}
        value={getData && getData["invest_value"]}
        type={"Investment Value"}
      />

      <PortFolioCard
        lightMode={lightMode}
        value={getData && getData["current_value"]}
        type={"Current Value"}
      />
      <PortFolioCard
        lightMode={lightMode}
        value={getData && getData["profit_loss"]}
        type={"Profit / Loss"}
        colored={true}
        change={getData && getData['profit_loss_per']}
      />
      <PortFolioCard
        lightMode={lightMode}
        type={getData&&getData['portfolio_val']}
        colored={true}
        isMeter={true}
      />
    </div>
  );
}

export default PortfolioMainCards;
