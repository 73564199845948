import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Trigger: false,
};

const ActiveSearchSlice = createSlice({
  name: "Active",
  initialState,
  reducers: {
    toggleInput: (state, { payload }) => {
      state.Trigger = payload
    },
  },
});

export const {toggleInput} = ActiveSearchSlice.actions
// export const getThemeMode=(state)=>state.theme
export default ActiveSearchSlice.reducer;
