import { Collapse } from "antd";
import React from "react";

function StockFaq({ lightMode,data }) {
  const { Panel } = Collapse;

  return (
    <div>
      <h2 className="main-header">FAQ’s</h2>
      <Collapse
        accordion
        style={{ background: lightMode ? "#fff" : "#292E3F" }}
        bordered={false}
        expandIconPosition="end"
      >
        {data?.map((faq, i) => (
          <Panel
            key={`${i}`}
            className="fs-16-14 mt-10 collapse-borderless"
            style={{
              color: lightMode ? "black" : "white",
              background: lightMode ? "#F2F6F8" : "#3B3F4F",
              borderRadius: "5px",
            }}
            header={    
              <p
                className={`fw-500 fs-18-16 mb-0 ${
                  lightMode ? "text-black " : "text-white"
                }`}
              >
                {faq.head}
              </p>
            }
          >
            {faq.content}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default StockFaq;
