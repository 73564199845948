import React from "react";
import { useState } from "react";
import { CreateWatchlsit } from "../../api/fetchClient";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { WatchListFetch } from "../../redux/reducers/watchListSlice";

function CreateWatchListModel({ OpenModel, lightMode, SetOpenModel ,setWatchListTrigger}) {
  const [CreateWatchlist, setCreateWatchlist] = useState();
  const [isModalVisible2, setIsModalVisible2] = useState(OpenModel);

  const dispatch = useDispatch();
  const handleOk2 = () => {
    SetOpenModel(false);
    CreateWatchlsit({ watchlist_name: CreateWatchlist }).then((res) => {
      dispatch(WatchListFetch());
      setCreateWatchlist("")
    });
  };

  const handleCancel2 = () => {
    SetOpenModel(false);
  };
  return (
    <div>
      <Modal
        title={
          <p  
            className={`fs-s-18 mb-0 fw-600 ${
              lightMode ? "text-dark" : "text-white"
            }`}
          >
            Create Watchlist
          </p>
        }
        visible={OpenModel}
        centered
        wrapClassName={!lightMode && "modelClassname"}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
          <button
            key="submit"
            type="primary"
            className={` w-150px br-5 btn-bg-primary text-white  fs-s-14 fw-600 h-45 `}
            onClick={() => handleOk2(CreateWatchlist)}
          >
            Create Watchlist
          </button>,
        ]}
      >
        <div className="w-100" onClick={(e)=>e.stopPropagation()}>
          <input
            width={"100%"}
            style={{ width: "100%" }}
            onChange={(e) => setCreateWatchlist(e.target.value)}
            value={CreateWatchlist}
            type="text"
            className={`w-100 h-45 br-3 p-5 ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
        </div>
      </Modal>
    </div>
  );
}

export default CreateWatchListModel;
