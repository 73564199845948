import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import CustomPagination from "../../../../components/ant/CustomPagination";
import CustomTable from "../../../../components/CustomTable";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../../../utilityFn/numberWithCommas";
import { getAllSignUps } from "../../../../api/fetchClient";
import moment from "moment";
import { DatePicker } from "antd";

function AdminAllSignup() {
  const { lightMode } = useSelector(getThemeMode);
  const [isLoading, setisLoading] = useState(false);
  const [signupTable, setsignupTable] = useState();
  const [input, setinput] = useState();
  const [Search, setSearch] = useState();
  const [Page, setPage] = useState({ page: 1, perPage: 50 });

  const { RangePicker } = DatePicker;
  const dateFormat = ["YYYY-MM-DD"];
  const onPageChange = (page, perPage) => {
    // getAllSignUps(Page, Search).then((res) => {
      setPage({ page: page, perPage: perPage });
    //   setsignupTable(res?.data);
    // });
  };
  useEffect(() => {
    const dateString = [];

    setisLoading(true);
    getAllSignUps(Page, Search, dateString).then((res) => {
      console.log(Search, "leeeeee");

      setisLoading(false);
      setsignupTable(res?.data);
      console.log(res?.data, "signupdata");
    });
  }, [Page, Search]);
  const onChange = (dateString) => {
    console.log(dateString, "datedada");

    let dateString1 = [];
    if (dateString === null) {
      dateString1 = [];
    } else {
      const date = moment(dateString[0]).format("YYYY-MM-DD");
      const date1 = moment(dateString[1]).format("YYYY-MM-DD");
      dateString1 = [date, date1];
    }

    getAllSignUps(Page, Search, dateString1).then((res) => {
      setsignupTable(res?.data);
    });
  };
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">User Name</p>,
      dataIndex: "name",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Email ID</p>,
      width: "50px",
      dataIndex: "email",
      render(text) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Affiliate Code</p>,
      width: "50px",
      dataIndex: "code",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Affiliate Name</p>,
      width: "50px",
      dataIndex: "affilate_name",
      render(text) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Registered Date</p>,
      width: "50px",
      dataIndex: "created_at",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {moment(text).format("YYYY-MM-DD")}
            </span>
          ),
        };
      },
    },
  ];

  return (
    <div className="my-body px-30-0">
      <div className="d-flex justify-content-between">
        <div>
          <p className="fs-24-16  mb-0 fw-700">Sign ups through Affiliate </p>
          <p>
            All Details of the signups so far will be shown in the table below.{" "}
          </p>
        </div>
      </div>

      <div className="">
        <p className="d-flex justify-content-end fs-s-18 fw-700">
          Total Signups: {signupTable?.count}
        </p>
      </div>
      <input
        style={{ width: "20%", color: "white", height: "38px" }}
        placeholder="Search By Name"
        value={input}
        onChange={(e) => {
          setPage({page:1 , perPage:50});
          setSearch(e.target.value);
        }}
        type="text"
        className={`w-100 h-45 mr-5 br-3  p-3 mt-10 ${
          lightMode
            ? "border1px-light-mode"
            : "border1px-dark-mode bg-dark-gray"
        }`}
      ></input>
      <RangePicker
        dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
        className={!lightMode && "DatePicker-Antd-Dark"}
        format={dateFormat}
        style={{ height: "37px" }}
        onChange={onChange}
      />
      <div
        className={`table-shadow mt-30 ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={columns1}
          loading={isLoading}
          data={signupTable?.results}
        />
        <CustomPagination
          current={Page.page}
          lightMode={lightMode}
          onChange={onPageChange}
          total={signupTable?.count}
          pageSize={Page.perPage}
        />
      </div>
    </div>
  );
}

export default AdminAllSignup;
