import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import svgSheet from "../../assets/svg/svgSheet";
function Calculator() {
  const { lightMode } = useSelector(getThemeMode);

  const navigate = useNavigate();

  const popularcalculator = [
    {
      icons: svgSheet.DiscountCalc,
      id: 1,
      link: "discount-cash-flow",
      name: "Discounted Cash Flow Calculation",
    },
    {
      icons: svgSheet.SystematicCalcicon,
      id: 2,
      link: "systematic-investment",

      name: "Systematic Investment Plan",
    },
    {
      icons: svgSheet.OneTimeValueicon,
      id: 3,
      link: "one-time-value",

      name: "LumpSum Calculator",
    },
    {
      icons: svgSheet.BrokerCalcIcon,
      id: 4,
      link: "brokerage-calculator",
      name: "Brokerage Calculator",
    },
   
  ];

  const calculatorList = [
    {
      icons: svgSheet.DiscountCalc,
      id: 1,
      link: "discount-cash-flow",
      name: "Discounted Cash Flow Calculation",
    },
    {
      icons: svgSheet.ReturnEquityicon,
      id: 2,
      link: "return-on-Equity",
      name: "Return On Equity Valuation",
    },
    {
      icons: svgSheet.GrahamCalIcon,
      id: 3,
      link: "graham-number",

      name: "Graham Number Valuation",
    },
    {
      icons: svgSheet.AdvanceGrahamCalIcon,
      id: 4,
      link: "Advanced-graham-number",

      name: "Advanced Graham Number Valuation",
    },
    {
      icons: svgSheet.PriceEarningscalcicon,
      id: 6,
      link: "price-earning",

      name: "Price Earning Valuation",
    },
    {
      icons: svgSheet.DividentDiscounticon,
      id: 5,
      link: "diviend-discount",

      name: "Dividend Discount Model Valuation",
    },
    {
      icons: svgSheet.DhanoValueIcon,
      id: 7,
      link: "dhando-valuation",

      name: "Dhando Valuation",
    },
  ];
  const stockAveerage = [
    {
      icons: svgSheet.StockAvergateCalcicon,
      id: 11,
      link: "stock-average",

      name: "Stock Average Calculator",
    },
    {
      icons: svgSheet.StockProfitCalcicon,
      id: 8,
      link: "stock-profit",

      name: "Stock Profit Calculator",
    },
    {
      icons: svgSheet.SystematicCalcicon,
      id: 9,
      link: "systematic-investment",

      name: "Systematic Investment Plan",
    },
    {
      icons: svgSheet.OneTimeValueicon,
      id: 10,
      link: "one-time-value",

      name: "LumpSum Calculator ",
    },
  ];
  const brokerCalc = [
    {
      icons: svgSheet.BrokerCalcIcon,
      id: 12,
      link: "brokerage-calculator",
      name: "Brokerage Calculator",
    },
  ];
  return (
    <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
        <div>
          <p className="mb-0 fs-36-30-20 fw-700">Calculators</p>
        </div>
        <p className="fs-22-18 fw-700 mt-20">Popular Calculators</p>

<div
  style={{ gap: "10px" }}
  className="calc-card-wrap flex-wrap bucket-card-wrap  justify-content-between "
>
  {popularcalculator?.map((items) => (
    <div
      onClick={() => navigate(`${items.link}`)}
      style={{ height: "275px" }}
      className={`w-100 pointer d-flex justify-content-between flex-col p-30 align-items-start  ${
        lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <div>{items?.icons}</div>
      <div
        style={{ width: "90%" }}
        className="d-flex justify-content-start  "
      >
        <p className="fs-24-16 fw-700 ">{items?.name}</p>
      </div>
      <p>Calculate {">"}</p>
    </div>
  ))}
</div>
        <p className="fs-22-18 fw-700 mt-20">Intrinsic value Calculator </p>

        <div
          style={{ gap: "10px" }}
          className="calc-card-wrap flex-wrap calc-card-wrap  justify-content-between "
        >
          {calculatorList?.map((items) => (
            <div
              onClick={() => navigate(`${items.link}`)}
              style={{ height: "275px" }}
              className={`w-100 pointer d-flex justify-content-between flex-col p-30 align-items-start  ${
                lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
              }`}
            >
              <div>{items?.icons}</div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-start  "
              >
                <p className="fs-24-16 fw-700 ">{items?.name}</p>
              </div>
              <p>Calculate {">"}</p>
            </div>
          ))}
        </div>
        <p className="fs-22-18 fw-700 mt-20">Stock Average Calculator</p>

        <div
          style={{ gap: "20px" }}
          className="bucket-card-wrap flex-wrap bucket-card-wrap  justify-content-between "
        >
          {stockAveerage?.map((items) => (
            <div
              onClick={() => navigate(`${items.link}`)}
              style={{ height: "275px" }}
              className={`w-100 pointer d-flex justify-content-between flex-col p-30 align-items-start  ${
                lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
              }`}
            >
              <div>{items?.icons}</div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-start  "
              >
                <p className="fs-24-16 fw-700 ">{items?.name}</p>
              </div>
              <p>Calculate {">"}</p>
            </div>
          ))}
        </div>
        <p className="fs-22-18 fw-700 mt-20">Brokerage Calculator </p>

        <div
          style={{ gap: "10px" }}
          className="bucket-card-wrap flex-wrap bucket-card-wrap  justify-content-between "
        >
          {brokerCalc?.map((items) => (
            <div
              onClick={() => navigate(`${items.link}`)}
              style={{ height: "275px" }}
              className={`w-25p-100  pointer d-flex justify-content-between flex-col p-30 align-items-start  ${
                lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
              }`}
            >
              <div>{items?.icons}</div>
              <div
                style={{ width: "90%" }}
                className="d-flex justify-content-start  "
              >
                <p className="fs-24-16 fw-700 ">{items?.name}</p>
              </div>
              <p>Calculate {">"}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Calculator;
