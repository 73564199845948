import { message, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import CreateWatchListModel from "../../pages/watchlist/CreateWatchListModel";
import { AddStock } from "../../redux/reducers/RecenSearchSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import {
  addToWatchlistWithData,
  getWatchListStore,
  WatchListFetch,
} from "../../redux/reducers/watchListSlice";

function NavComponent({ stock, setinputData }) {
  const watchListStore = useSelector(getWatchListStore);
  const [visible, setVisible] = useState(false);
  const [Watchlist, setWatchlist] = useState(
    Object.keys(watchListStore.watchListData)[0]
  );
  const [OpenModel, SetOpenModel] = useState(false);

  const [toggleStatus, setToggleStatus] = useState("NONE");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(WatchListFetch())
  // }, [dispatch])

  const hide = () => {
    setVisible(false);
  };
  const success = () => {
    message.success("Added To Watchlist");
  };
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };
  const { lightMode } = useSelector(getThemeMode);
  // add to watch list function
  const addStock = async (symbol) => {
    // console.log("start run");
    dispatch(
      addToWatchlistWithData({ listName: Watchlist, stock: [symbol] })
    ).then((resp) => {
      hide();
      dispatch(WatchListFetch());
      success();
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div>
        <CreateWatchListModel
          OpenModel={OpenModel}
          SetOpenModel={SetOpenModel}
          lightMode={lightMode}
        />
        {/* {stock.?type } */}
        <Link
          onClick={(e) => {
            // e.preventDefault()
            setinputData("");
            dispatch(WatchListFetch());

            setToggleStatus(false);
            stock.type !== "index" &&
              dispatch(
                AddStock({ name: stock?.company, symbol: stock?.symbol })
              );
          }}
          to={
            stock?.type === "stock"
              ? `${stock?.type}/${stock?.symbol}/consolidated`
              : `Index/${stock?.symbol}`
          }
        >
          <div
            className={`ff-poppins fs-s-16 `}
            style={{ minHeight: "60px", paddingTop: "5px" }}
          >
            <h4
              style={{ marginBottom: "0px" }}
              className={lightMode ? "text-light-black" : "text-white"}
            >
              {stock.company}
            </h4>
            <p className="text-green mb-0 ff-poppins">{stock.symbol}</p>
          </div>
        </Link>
      </div>
      <div className="ff-poppins">
        <Popover
          color={lightMode ? "white" : "#3B3F4F"}
          className="nameis ff-poppins"
          placement="rightTop"
          content={
            <div className="ff-poppins">
              <div>
                {Object.keys(watchListStore.watchListData).map((items) => (
                  <div className="d-flex  align-items-center mt-5 ">
                    <input
                      onChange={(e) =>
                        e.target.checked && setWatchlist(e.target.value)
                      }
                      checked={Watchlist === items && true}
                      className={`${!lightMode && "text-dark-gray"}`}
                      value={items}
                      type="checkbox"
                    ></input>
                    <p
                      className={`ml-5 mb-3 pointer ${
                        lightMode ? "text-dark-gray" : "text-white"
                      }`}
                    >
                      {items}
                    </p>
                  </div>
                ))}
              </div>
              <div className="d-flex mt-15">
                <button
                  className={`mr-5 p-10 br-3 btn-blue-border  ${
                    lightMode
                      ? "bg-table-data text-btn-light "
                      : "bg-dark-gray text-btn-dark"
                  }`}
                  onClick={() => (SetOpenModel(true), setVisible(false))}
                >
                  + Create Watchlist{" "}
                </button>
                <button
                  className={`pointer px-20 mr-0 btn-bg-primary text-white  fw-600 text-white br-3  mr-5`}
                  onClick={() => addStock(stock.symbol)}
                >
                  Done
                </button>
              </div>
            </div>
          }
          title={
            <p
              className={`fs-s-16 fw-700 mb-0 ${
                lightMode ? "text-dark-gray" : "text-white"
              }`}
            >
              Add Stock to
            </p>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <div className="square-18-14">{svgSheet.SaveForLater}</div>
        </Popover>
      </div>
    </div>
  );
}

export default NavComponent;
