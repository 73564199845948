import {
  CaretDownOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

import React, { useEffect, useState } from "react";
import { Indexperformance } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import { marketHourStatus } from "../../utilityFn/marketHourStatus";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";

function TodaysPerformanceSection({ Stockdata, indexname, lightMode }) {
  const [PerformData, setPerformData] = useState();

  useEffect(() => {
    const getdata = () => {
      Indexperformance({ indexname }).then((res) => {
        setPerformData(res);
      });
    };

    getdata();
  }, [indexname]);

  const high = Number(PerformData?.today_high).toFixed(2);
  const low = Number(PerformData?.today_low).toFixed(2);
  const close = Number(PerformData?.price).toFixed(2);
  const firstValue = high - low;
  const secondValue = close - low;

  const percentage = (secondValue / firstValue) * 100;

  const Whigh = Number(PerformData?.high_52w)?.toFixed(2);
  const Wlow = Number(PerformData?.low_52w)?.toFixed(2);
  const Wclose = Number(PerformData?.price)?.toFixed(2);
  const WfirstValue = PerformData?.high_52w - PerformData?.low_52w;
  const WsecondValue = Wclose - PerformData?.low_52w;

  const Wpercentage = (WsecondValue / WfirstValue) * 100;

  return (
    <div
      className={`d-flex justify-content-between br-3   p-30 center-company-data box-shadow-card flex-col  ${
        lightMode ? "bg-gray" : "bg-dark-gray text-white"
      }`}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
            <div style={{height:"40px"}} className="d-flex align-items-center ">
              <h1 className="fs-27-16  m-0 fw-700 mr-30-0 fs-s-10 uppercase">
                {indexname}
              </h1>

              {/* <DownloadOutlined /> */}
              <p className=" mt-5-0 Broker-info fw-500">
                {indexname === "SENSEX" ? "BSE" : "NSE"}
              </p>  
              <div className="ml-15 ">
                <Tooltip
                  className="square-18-14"
                  title="Prices are delayed by 15 Mins"
                >
                  <div
                    style={{ display: marketHourStatus() ? "block" : "none" }}
                  >
                    {svgSheet.clockIcon}
                  </div>
                </Tooltip>
              </div>
            </div>
      
        </div>
        <div
          style={{
            color: redGreenColorPicker(
              Stockdata && Stockdata[0] && Stockdata[0]["percent"],
              lightMode
            ),
          }}
          className={`d-flex pb-20px align-items-end  ff-lato clr-light-green ff-lato d-flex flex-col
          
          `}
        >
          <p className="fs-24-16 m-0 mr-2 d-flex align-items-end   ff-lato fw-600">
             {numberWithCommas(Stockdata &&Stockdata[0] && Stockdata[0]["close"])}
          </p>
          <small className="fs-16-13 ff-lato">
            <small className="mr-2">
              {Stockdata && Stockdata[0]["percent"] > 0 && "+"}{" "}
              {(Stockdata && Stockdata[0]["change"])?.toFixed(2)}
            </small>{" "}
            ( {Stockdata && Stockdata[0]["percent"] > 0 && "+"}
            {(Stockdata && Stockdata[0]["percent"])?.toFixed(2)}%)
          </small>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between mt-10 mb-10">
        <div className="d-flex">
          <p className="fs-16-13 fw-400">52 Wk Low -</p>
          <p className="fs-16-13 fw-400">
            {Stockdata && Stockdata[0]["low"]}
          </p>
        </div>
        <div className="d-flex Previous-Close">
          <p className="fs-16-13 fw-400">Previous Close -</p>
          <p className="fs-16-13 fw-400">
            {Stockdata && Stockdata[0]["close"]}
          </p>
        </div>
        <div className="d-flex ">
          <p className="fs-16-13 fw-400">52 Wk High -</p>
          <p className="fs-16-13 fw-400">
            {Stockdata && Stockdata[0]["high"]}
          </p>
        </div>
      </div> */}
      <div style={{ marginTop: "25px" }} className="d-flex  w-100 mobile-wrap">
        <div className="border-bond mb-20 w-100-50 mr-15">
          <div
            style={{ position: "relative", width: "92%" }}
            className="mt-10 h-25"
          >
            <div
              style={{
                position: "absolute",
                // left: Wpercentage + "%",
                bottom: "-4px",
                left:
                  Wpercentage > 100
                    ? 93 + "%"
                    : Wpercentage > 0
                    ? Wpercentage + "%"
                    : 0 + "%",
              }}
              className="d-flex flex-col mb-3"
            >
              <p className="fs-16-13 mb-0 ff-lato">
                {numberWithCommas(Number(Wclose))}
              </p>
              <CaretDownOutlined color="clr-green" />
            </div>
          </div>
          <div className="dynamic-stockbar "></div>
          <div className="d-flex mt-5 justify-content-between ff-lato">
            <p className="mb-0">{numberWithCommas(Number(Wlow))}</p>
            <p className="mb-0">{numberWithCommas(Number(Whigh))}</p>
          </div>
          <div className="d-flex justify-content-between  mt-5 fs-12">
            <p className="fw-500 mb-0">52W Low</p>
            <p className="fw-500 mb-0">52W High</p>
          </div>
        </div>
        <div className="border-bond w-100-50 ml-15">
          <div
            style={{ position: "relative", width: "92%" }}
            className="mt-10  h-25 ff-lato"
          >
            <div
              style={{
                position: "absolute",
                left: percentage + "%",
                bottom: "-4px",
              }}
              className="d-flex flex-col mb-3"
            >
              <p className="fs-16-13 mb-0">{numberWithCommas(Number(close))}</p>
              <CaretDownOutlined color="clr-green" />
            </div>
          </div>
          <div className="dynamic-stockbar "></div>
          <div className="d-flex mt-5 justify-content-between ff-lato">
            <p className="mb-0">{numberWithCommas(Number(low))}</p>
            <p className="mb-0">{numberWithCommas(Number(high))}</p>
          </div>
          <div className="d-flex justify-content-between  mt-5 fs-12 ">
            <p className="fw-500 mb-0">Today’s Low</p>
            <p className="fw-500 mb-0">Today’s High</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TodaysPerformanceSection;
