import { Skeleton } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getNews } from "../../api/fetchClient";
import NewsComponent from "../Market News/NewsComponent";

function TrendingNewsSection({ lightMode }) {
  const [loader, setloader] = useState(false);
  const [NewsData, setNewsData] = useState([]);

  useEffect(() => {
    setloader(true);
    getNews("1", "9", 1789)
      .then((res) => {
        setNewsData(res.data);
        setloader(false);
      })
      .catch((err) => setloader(false));
  }, []);

  const innerArray = useMemo(() => {
    const spliceArr = NewsData?.slice(1);
    // spliceArr?.splice(-1)
    return spliceArr;
  }, [NewsData]);

  if (loader) {
    return (
      <>
        <Skeleton /> <Skeleton />
      </>
    );
  }
  console.log("NewsData",NewsData?.at(0)?.yoast_head_json?.og_image);

  return (
    <section>
      <div className="w-100 flex justify-content-between  mb-30 ">
      <h3 className="fs-28-18 fw-700 ">Trending News</h3>
      <Link to={'/news'} className="mb-0 d-flex align-self-center link-hover-underline">View All</Link>
      </div>
      <div className="news-grid-container ">
        {/* <div className="news-lg">
        <NewsComponent
          title={NewsData[0]?.title?.rendered}
          img={NewsData[0]?.yoast_head_json?.og_image[0].url}
          lightMode={lightMode}
          url={NewsData[0]?.link}
          id={NewsData[0]?.id}
          height={"380px"}
        />
      </div>
      <div className="news-md">
        <NewsComponent
          title={NewsData[1]?.title?.rendered}
          img={NewsData[1]?.yoast_head_json?.og_image[0].url}
          lightMode={lightMode}
          url={NewsData[1]?.link}
          id={NewsData[1]?.id}
          height={"380px"}
        />
      </div> */}
        <div className="news-md-2">
      {NewsData?.at(0)?.yoast_head_json?.og_image && <NewsComponent
            title={NewsData.at(0)?.title?.rendered}
            img={NewsData?.at(0)?.yoast_head_json?.og_image ? NewsData?.at(0)?.yoast_head_json?.og_image[0]?.url :undefined} 
            lightMode={lightMode}
            url={NewsData.at(0)?.link}
            id={NewsData.at(0)?.id}
            height={"380px"}
            showTicker={false}
          />}
        </div>
        <div className="news-sm">
          {innerArray?.map((news, i) => (
            <NewsComponent key={i}
              title={news?.title?.rendered}
              img={news?.yoast_head_json?.og_image ? news?.yoast_head_json?.og_image[0].url :undefined}
              lightMode={lightMode}
              url={news?.link}
              id={news?.id}
              height={"150px"}
              showTicker={false}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TrendingNewsSection;
