import React from "react";
import { Link } from "react-router-dom";

function SideHeader({ icon, label, id }) {
  return (
    <div className="d-flex align-items-center justify-content-between mb-30">
      <div style={{ gap: ".5rem" }} className="d-flex">
        {icon}
        <h3
          className="fs-28-18 fw-600 d-flex mb-0"
          style={{ lineHeight: "27px" }}
        >
          {label}
        </h3>
      </div>
      <Link to={`/bucket/${label}`} className="fs-s-12 d-flex align-self-start link-hover-underline">
        View all {">"}
      </Link>
    </div>
  );
}

export default SideHeader;
