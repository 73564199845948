import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  getBrokerDetails,
  getStockDetails,
  getStockLTP,
} from "../../../../api/fetchClient";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AntFormInput from "../../../../components/ant/AntFormInput";
import { UploadOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import svgSheet from "../../../../assets/svg/svgSheet";
import moment from "moment";

function StockRecommendationForm({
  form,
  dropdowndata,
  setDropdowndata,
  inputReturns,
  setInputReturns,
  initialData,
  disableautocomplete,
  onSubmit,
  selectedLabel,
  setSelectedLabel,
  setBrokerId,
  brokerId,
  setfiles,
  files,
  initValues,
  setTarget,
  targets,
  selectedRole,
  setSelectedRole,
  visibleType,
  setVisibleType,
  date,
  setDate,
}) {
  const navigate = useNavigate();

  const { id } = useParams();
  const { lightMode } = useSelector(getThemeMode);
  const [isLoading, setisLoading] = useState(false);
  const [changedPrice, SetChangedPrice] = useState("");
  const [symbol, setSymbol] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [broker, setBroker] = useState([]);
  const [brokerDropdown, setBrokerDropdown] = useState([]);

  const { state: urlState } = useLocation();
  useEffect(() => {
    urlState?.data?.stock_name && getResults(urlState?.data?.stock_name);
  }, [urlState?.data?.stock_name]);

  console.log(urlState, "uel starra");

  const getResults = async (e) => {
    const response = await getStockDetails(e);
    if (response?.status === 200) {
      setSymbol(response.data[0].symbol);
      setInputReturns(response.data);
      setSelectedLabel(response.data[0]?.FINCODE);
     
    }


  };
  console.log("symbolasdasddd", symbol);

  useEffect(() => {
    if (inputReturns) {
      const options = inputReturns.map((items) => ({
        value: items.company,
        label: items.company,
      }));
      setDropdowndata(options);
    }
  }, [inputReturns]);

  console.log(inputReturns, "initialData");

  useEffect(() => {
    if(targets !== ""){
      const getAllData = async () => {
        await getStockLTP("stock", symbol).then((res) => {
          SetChangedPrice(res?.data[0]);
          setCurrentPrice(res?.data[0].close);
        });
      };
      symbol && getAllData();
    }
  }, [targets ]);

  console.log(changedPrice, currentPrice, "hhjh");
  console.log(symbol, "syymboll");

  const getBrokerResults = async () => {
    const response = await getBrokerDetails().then((resp) => {
      setBroker(resp.data);
      // setBrokerId(resp?.data[0]?.id)
    });
  };

  useEffect(() => {
    if (broker) {
      const options = broker.map((items) => ({
        value: items.name,
        label: items.name,
        id: items?.id,
      }));
      setBrokerDropdown(options);

    }
  }, [broker]);

  console.log(brokerId, "brokerrrrrrrrrrrr");


  console.log(targets, "target");

  const featpdfField = [
    {
      uid: "-1",
      name: "yyy.pdf",
      status: "done",
      url: initValues?.file,
      thumbUrl: initValues?.file,
    },
  ];


const potentialUpside = Number(targets - currentPrice);
let displayValue = "";

if (targets !== 0 && currentPrice !== 0) {
  const calculatedDifference = (potentialUpside / currentPrice) * 100;
  if (!isNaN(calculatedDifference) && isFinite(calculatedDifference)) {
    displayValue = calculatedDifference.toFixed(2);
  }
}


const visibleTypes = [
    {
      label: "Visible",
      value: "visible",
    },
    {
      label: "Not Visible",
      value: "hidden",
    },
  ];

  
  const handleRadioChange = (event) => {
    setSelectedRole(event.target.value);
  };

  function handleVisibleTypeChange(value) {
    setVisibleType(value);
  }

  const handleDateChange = (date)=>{
    const formattedDate = moment(date).format('YYYY-MM-DD')

    setDate(formattedDate)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        stock: urlState?.data?.stock_name,
        broker: urlState?.data?.broker_name,
        target: urlState?.data?.target,
        role: urlState?.data?.role,
        visibility: urlState?.data?.visibility,
        file: urlState?.data?.file,
      }}
    >
      <Form.Item type="hidden" initialValue={selectedLabel}>
        <Input type="hidden" />
      </Form.Item>
      <div className="w-100-50">
        <Form.Item
          label="Stock Name"
          name="stock"
          rules={[
            {
              required: true,
              message: "Please select a stock",
            },
          ]}
          className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
        >
          <AutoComplete
            className={`${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode antd-Selete-Custom-dark"
            }`}
            // style={{
            //   width: "50%",
            // }}
            notFoundContent="No Stocks"
            placeholder=" Select Stock"
            options={dropdowndata}
            dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
            onFocus={() => getResults("")}
            onChange={(e) => {
              getResults(e);
              setSelectedLabel(e);
            }}
            value={selectedLabel}
            onSelect={(value, option) => {
              setSelectedLabel(option?.label);
            }}
            disabled={disableautocomplete}
            defaultActiveFirstOption={true}
          />
        </Form.Item>
              <Form.Item
                // valuePropName={'date'}
                label="Date"
                name="date"
                style={{
                  display: "inline-block",
                  width: "calc(100%)",
                }}
                className={`w-90 ${
                  lightMode ? "light-input-login" : "dark-input-login"
                }`}
                rules={[
                  {
                    required: true,
                    message: "Please select the date!",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%", height: "35px" }}
                   onChange={handleDateChange}
                  className={!lightMode && "DatePicker-Antd-Dark"}
                  dropdownClassName={
                    !lightMode && "DatePickerdropdrow-Antd-Dark"
                  }
                />
              </Form.Item>
       
        <Form.Item
          label="Stock Brokerage"
          name="broker"
          rules={[
            {
              required: true,
              message: "Please select a stock",
            },
          ]}
          className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
        >
          <AutoComplete
            className={
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode antd-Selete-Custom-dark  "
            }
            // style={{
            //   width: "50%",
            // }}
            notFoundContent="No Stocks"
            placeholder=" Select Stock"
            options={brokerDropdown}
            dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
            onFocus={() => getBrokerResults("")}
            onChange={(e) => {
              getBrokerResults(e);
              setBrokerId(e);
            }}
            value={brokerId}
            onSelect={(value, option) => {
              // setSelectedLabel(option?.label);
              // setBrokerId(option?.name);
              setBrokerId(option?.id);
            }}
            disabled={disableautocomplete}
            defaultActiveFirstOption={true}
          />
        </Form.Item>
      </div>
      
      <div className="w-100-50">
        <Form.Item
          label="Target"
          name="target"
          rules={[
            {
              required: true,
              message: "Please enter the target",
            },
          ]}
          className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
        >
          <Input
            label="Target"
            className={`w-100 h-40px auth-form-input  ${
              lightMode
                ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                : "bg-dark-gray email-input-login-page"
            }`}
            type="text"
            value={targets}
            onChange={(e) => setTarget(e.target.value)}
            rules={[
              {
                required: true,
                message: "Please Enter Target",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Potential Upside"
          rules={[
            {
              required: false,
            },
          ]}
          // name=""
          className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
        >
          <Input
            label="Potential Upside"
            // name=""
            type="text"
            readOnly
            className={`w-100 h-40px auth-form-input  ${
              lightMode
                ? "bg-gray email-input-login-page-light input-with-beforeFeild "
                : "bg-dark-gray email-input-login-page"
            }`}
            value={displayValue}
            // onChange={(e)=>setPotential(e.target.value)}
            rules={[
              {
                required: false,
              },
            ]}
          />
        </Form.Item>
      </div>
      <Form.Item
        label="Select"
        name="role"
        rules={[
          {
            required: true,
            message: "Please select Buy/Sell",
          },
        ]}
        className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
      >
        <Radio.Group name="radiogroup" onChange={handleRadioChange} value={selectedRole}>
          <Radio style={{ color: "green" }} value={"buy"}>
            Buy
          </Radio>
          <Radio style={{ color: "red" }} value={"sell"}>
            Sell
          </Radio>
          <Radio style={{ color: "#FF9900" }} value={"hold"}>
            Hold
          </Radio>
        </Radio.Group>
      </Form.Item>
      <div className="w-100-50">
        {/* <Form.Item
          label="Upload File"
          rules={[
            {
              required: false,
              // message: "Please Select file",
            },
          ]}
          // style={{ margin: "30px 0px" }}
          name="file"
          initialValue={featpdfField}
          getValueFromEvent={(e) => e.file}
         
          className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
        >
          <Upload
            beforeUpload={() => false}
            // listType="picture"
            // showUploadList={remove}
            defaultFileList={[...featpdfField]}
            onChange={(e)=>setfiles(e.file)}
             
            maxCount={1}
            className="mt-10 darkmode-antdUploader stock-recommendation-upload"
          >
            <button
              className="btn-bg-primary p-10 mt-10 fw-500 br-5 text-white"
              icon={<UploadOutlined />}
            >
              Upload PDF
            </button>
          </Upload>
        </Form.Item> */}
         <Form.Item
        name="file"
        label="URL"
        rules={[{ required: false }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
        className={` ${lightMode ? "light-input-login" : "dark-input-login"}`}
      >
        <Input placeholder="Please enter url"
         className={`w-100 h-40px auth-form-input  ${
          lightMode
            ? "bg-gray email-input-login-page-light input-with-beforeFeild "
            : "bg-dark-gray email-input-login-page"
        }`}
        value={files}
        onChange={(e)=>setfiles(e.target.value)}
        />
      </Form.Item>
      </div>
      <div className="w-100-20">
        <Form.Item
          label="Visiblity"
          style={{ margin: "6px 0px 6px 0px", display: "block" }}
          name="visibility"
          className={`${lightMode ? "light-input-login" : "dark-input-login"}`}
          rules={[
            {
              required: true,
              message: "Please Select visible",
            },
          ]}
        >
          <Select
            className={`${
              lightMode
                ? "br-5 contact-select-light bg-gray border1px-light-mode"
                : "br-5  contact-select-dark bg-d ark-gray border1px-dark-mode"
            } d-flex justfity-content-center align-items-`}
            dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
            onChange={handleVisibleTypeChange}
            style={{
              // width: "16%",
              height: "45px",
              margin: "6px 0px",
            }}
            placeholder="Visible"
            // defaultValue={"visible"}
            value={visibleType}
          >
            {visibleTypes.map((items, i) => (
              <Option key={i} value={items.value}>
                {items.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </Form>
  );
}

export default StockRecommendationForm;
