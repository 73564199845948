import { Modal } from "antd";
import React from "react";
import bgImgDark from "../../assets/images/bg/premiumBg.png";
import confetti from "../../assets/images/confetti portal.png";
import numberWithCommas from "../../utilityFn/numberWithCommas";
function CouponAppliedModel({ setVisible, visible,CouponDiscount,AppliedCoupon }) {
    console.log("AppliedCoupondata",AppliedCoupon);
  return (
    <div>
      {" "}
      <Modal
        centered
        closable={true}
        width="350px"
        bodyStyle={{ padding: "0px", minHeight: "200px", borderRadius: "18px" }}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        className="relative "
        wrapClassName={"filter-modal premium-mod"}
      >
        <img
          className="absolute w-100 h-100 fit-cover pb-10 "
          src={bgImgDark}
          style={{ borderRadius: "19px" }}
          alt="bg"
        />
        <div  className="d-flex d-flex-col h-100 p-30 ">
          <div className="d-flex align-items-center  ">
            <p className="fs-s-20 fw-700 mt-5 mr-10">  {AppliedCoupon?.code}</p>
            <p>applied</p>
          </div>
          <div>
            <p className="ff-lato gradient-text-blue mb-0 fs-34-20 fw-700">₹ {numberWithCommas(Number(CouponDiscount))} </p>
            <p>Saved with this applied Coupon</p>
          </div>
        </div>
        <img
          style={{ top: -50, left: 30,width:'80px' }}
          className="absolute"
          src={confetti}
          alt="img"
        />
      </Modal>
    </div>
  );
}

export default CouponAppliedModel;
