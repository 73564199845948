import React, { useMemo, useRef, useState } from "react";
import Technicaltable from "./Technicaltable";
import { Gauge } from "@ant-design/plots";
import StockEndpoints from "../../../api/endPoints";
import useGetFetch from "../../../hooks/useGetFetch";
import { Switch } from "antd";
import CustomTooltip from "../../../components/ant/CustomTooltip";
import svgSheet from "../../../assets/svg/svgSheet";

function TechnicalIndex({ lightMode, symbol, CMP,index }) {
  const [pivotSort, setpivotSort] = useState(true);
  const graphRef = useRef(null);
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.pivotAPI(symbol,index, pivotSort ? "daily" : "monthly")
  );
  const gaugePercent = useMemo(() => {
    if (!apiData) return;
    const pivorStdArr = apiData?.map((item) => item.std);
    const pivotDiff = pivorStdArr?.at(0) - pivorStdArr?.at(-1);
    const cmpDiff = CMP - pivorStdArr?.at(-1);
    const pivotPercent = cmpDiff / pivotDiff;
    return pivotPercent;
  }, [symbol, pivotSort, apiData]);

  const gaugeChart = {
    title: {
      formatter: ({ percent }) => {
        return ``;
      },
      style: ({ percent }) => {
        return {
          fontSize: "18px",
          lineHeight: 1,
          fontWeight: "500",
          color: lightMode ? "#575757" : "white",
        };
      },
    },
    content: {
      offsetY: 25,
      style: () => {
        return {
          fontSize: "18px",
          color: lightMode ? "#575757" : "white",
          fontWeight: "500",
        };
      },
      formatter: ({ percent }) => "",
    },
  };
  const config = {
    // percent: percetage,
    percent: gaugePercent,
    range: {
      ticks: [0, 1],
      color: [
        "l(0) 0.1:rgba(0, 199, 79, 1) 0.5:rgba(255, 252, 0, 1) 0.9: rgba(234, 54, 54, 1)",
      ],
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#978f8f",
        },
      },
      pin: {
        style: {
          stroke: "#978f8f",
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return "";
        },
      },
      subTickLine: {
        count: 6,
      },
    },
    statistic: gaugeChart,
    onReady: (plot) => {
      graphRef.current = plot;
    },
  };

  return (
    <div className="w-100" id="TechnicalIndex">
      <h2 style={{ margin: "20px 0px 10px 0px" }} className="main-header">
        Technicals
      </h2>
      <div className="flex justify-content-between align-items-center">
        <div className="flex align-items-center">
          <h3 className="fs-20-16 fw-600 ">Pivot Points</h3>
          <CustomTooltip text={"The pivot point calculations is used to determine the support and resistances at different time frames and is simply the average of the intraday high and low, and the closing price from the previous trading session."}>{svgSheet.infoTooltop}</CustomTooltip>
        </div>
        <div className="  h-50px flex align-items-center pl-10px">
          <Switch
            defaultChecked
            checked={pivotSort}
            onChange={(e) => setpivotSort(e)}
          />
          <span className="fw-600" style={{ minWidth: "60px" }}>
            {pivotSort ? "Daily" : "Monthly"}
          </span>
        </div>
      </div>
      <div className="flex flex-col-row align-lg-center">
        <div className="w-100-50">
          <div style={{ maxHeight: "300px", marginInline: "auto" }}>
            <div className="d-flex justify-content-center ">
              <div className="relative" style={{ width: "300px" }}>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "11%", left: "-5%" }}
                >
                  S3
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "44%", left: "-9%" }}
                >
                  S2
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "73%", left: "4%" }}
                >
                  S1
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", top: "5%", left: "43%" }}
                >
                  Pivot
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "73%", right: "4%" }}
                >
                  R1
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "44%", right: "-9%" }}
                >
                  R2
                </p>
                <p
                  className="mb-0 fw-600 fs-16-14"
                  style={{ position: "absolute", bottom: "11%", right: "-5%" }}
                >
                  R3
                </p>

                <Gauge {...config} height={300} width={300} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100-50">
          <Technicaltable lightMode={lightMode} tableData={apiData} />
          <p className="mb-0 my-10 fs-s-12 text-align-start text-gray">
            *Calculated based on price range of the previous trading{" "}
            {pivotSort ? "day" : "month"}.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TechnicalIndex;
// "The pivot point calculations is used to determine the support and resistances at different time frames and is simply the average of the intraday high and low, and the closing price from the previous trading session."
// "The levels here show the different support and resistances for the trading session"