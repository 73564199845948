import axios from "axios";
import store from "../redux/store";



 // Add a request interceptor
//  axios.interceptors.request.use(function (config) {
//   const token = storeData?.userData?.access_token;
//   config.headers.Authorization =  token;
//   return config;
// });


export const getHeader=()=> {
  const storeData =store.getState().auth;
  if(storeData?.userData?.access_token){
    return {
      headers: {
        Authorization: `jwt ${storeData?.userData?.access_token??null}`
      }
    }
  }
}
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: 'https://dev.portal.tradebrains.in/api/',
    // timeout: 1000,
  });
  const storeData =store.getState().auth;

  const headers = { 
    'Authorization': `jwt ${storeData?.userData?.access_token??null}`,
    // 'My-Custom-Header': 'foobar'
};


  export const post= (url, data)=> {
    return instance.post(url, data,getHeader())
 }
  export const get=(url)=> {  
    return instance.get(url,getHeader())
  }
 export const  del=(url)=> {
    return instance.delete(url,getHeader())
  }
  export const  delWithParams=(url,data)=> {
    return instance.delete(url,{...getHeader(),data:data})
  }
 export const put=(url, data) =>{
    return instance.put(url, data,getHeader())
  }
 export const patch=(url, data)=> {
    return instance.patch(url, data,getHeader())

  }