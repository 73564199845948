import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import GaugeAnt from "../../components/Charts/GaugeAnt";
import svgSheet from "../../assets/svg/svgSheet";
import CustomTooltip from "../../components/ant/CustomTooltip";
function KeyPortfolioMetrics({ divident, lightMode }) {
  const data = ["PE", "ROE", "ROCE"];
  const data1 = ["Avg PE Ratio", "Avg ROE ", "Avg ROCE  "];
  const tooltips = [
    "The PE ratio is used for valuing a company by measuring its current share price relative to its EPS. Here by taking in the weighted average we factor in the portion taken up by stocks in your portfolio to give you a realistic picture of its impact.",
    "ROE is the measure of a company's net income divided by its shareholders' equity. Here by taking in the weighted average we factor in the portion taken up by stocks in your portfolio to give you a realistic picture of its impact.",
    "ROCE ratio can assist in determining how successfully a firm generates profits from its capital when it is used. Here by taking in the weighted average we factor in the portion taken up by stocks in your portfolio to give you a realistic picture of its impact.",
  ];
  console.log("Object.values(divident?.key_p)");
  return (
    <div className="">
      <h2 className="main-header ">Key Portfolio Metrics</h2>
      <div className="d-flex-row mt-40 flex-wrap">
        {" "}
        {divident?.key_p &&
          Object.values(divident?.key_p)?.map((items, i) => (
            <div
              className="d-flex justify-content-center relative flex-col align-items-center key-portfolio-MObileMargin"
              style={{ maxHeight: "300px", marginInline: "auto" }}
            >
              <p
                style={{ position: "absolute", top: "-20px" }}
                className="fs-s-18 fw-700 mr-10 mb-0 d-flex justify-content-center align-items-center"
              >
                {data[i]}
              </p>
              <GaugeAnt
                id="DPR"
                percetage={items / 100}
                labelFormat={{
                  formatter(v) {
                    return Number(v) * 100;
                  },
                }}
                colors={[
                  "l(0) 0.1:rgba(234, 54, 54, 1) 0.5:rgba(255, 252, 0, 1) 0.9:rgba(0, 199, 79, 1)",
                ]}
                // labelData={{
                //   title: {
                //     formatter: ({ percent }) => {
                //       return (`${data1[i]} `) ;
                //     },
                //     offsetY: 15,

                //     style: ({ percent }) => {
                //       return {
                //         fontSize: "16px",
                //         lineHeight: 2,
                //         fontWeight: "400",
                //         // color: percent<.35?'#8A2387':percent>.35&&percent<.65?'#FFFC00':'#38EF7D',
                //         color: lightMode ? "#575757" : "white",
                //       };
                //     },
                //   },
                //   content: {
                //     offsetY: 45,
                //     style: () => {
                //       return {
                //         fontSize: "16px",
                //         color: lightMode ? "#575757" : "white",
                //         fontWeight: "400",
                //       };
                //     },
                //     formatter: ({ percent }) =>`${(percent * 10).toFixed(2)}${data1[i] === "Avg PE Ratio" ? " " : "%"}`,
                //   },
                // }}
                width={300}
              />
              <div className="d-flex  align-items-center flex-col">
                <div className="d-flex align-items-center">
                  <p className="mb-0 mr-5"> {data1[i]}</p>
                  <CustomTooltip placement="bottom" text={tooltips[i]}>
                  <p className="mb-0">{svgSheet.infoTooltop}</p>
                  </CustomTooltip>
                </div>
                <p className="mb-20">
                  {items}
                  {data1[i] === "Avg PE Ratio" ? " " : "%"}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default KeyPortfolioMetrics;
