import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminprimumnSupportDetails, getAdminQueriesDetails, UpdateAdminQueriesDetails } from "../../../../api/fetchClient";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";

function AdaminHelpCenterDetails() {
  const { id } = useParams();
  const [Details, setDetails] = useState();
  const [raiseIssue, setraiseIssue] = useState();
  const { lightMode } = useSelector(getThemeMode);
  const [Status, setStatus] = useState({reply:"",status:""})
  const [isLoading, setisLoading] = useState(false)
  useEffect(() => {
    getAdminQueriesDetails(id).then((res) => {
      setDetails(res?.data);
    });
  }, []);

 const UpdateStatus = () =>{
  setisLoading(true)
    getAdminQueriesDetails(id).then((res) => {
      setisLoading(false)
      setDetails(res?.data);
    });
  }
  const update = () =>{
    UpdateAdminQueriesDetails(id,Status).then((res)=>{
      UpdateStatus()
    })
  }
  useEffect(() => {
    
    UpdateAdminQueriesDetails(id,Status).then((res)=>{
      UpdateStatus()
    })
   
  }, [Status?.status])
  
  const dateFormate = ["DD MMM YY"];
  console.log("Details", Details);
  return (
    <div className="w-100 my-body  px-30-0 ">
      <div className="d-flex justify-content-between">
        <p className="fs-24-16 fw-700">Message Preview</p>
       {Details?.status !== 'closed' && <button
          onClick={()=>{setisLoading(true); setStatus({...Status,status:"close"})}}
          style={{ height: "45px" }}
          className="p-10 text-white br-5 btn-bg-primary"
        >
          {isLoading ? "Loading..." :"Close Query"}
        </button>}
      </div>
      <div>
        <div className="d-flex justify-content-between b1px-dark-mode p-40">
          <div>
            <p className="fw-500 fs-s-16">Affiliate’s Details</p>
            <p>{Details?.name}</p>
            <p>{Details?.email}</p>
          </div>
          <div>
            <p className="fw-500">Created Date</p>
            <p className="mt-10">
              {moment(Details?.created_at).format(dateFormate[0])}
            </p>
          </div>
        </div>
        <div className="bg-dark-gray p-40">
          <p className="fs-s-16 fw-500">Query / Message</p>
          <p className="my-40">{Details?.question}</p>
          <p className="fs-s-16 fw-500 mb-0">File Attached</p>
          <p>query.pdf</p>
        </div>
      </div>
      <div className="">
        <p className="fs-s-18 fw-700 mt-30">Admin’s Reply</p>
        <textarea
          width={"100%"}
          value={Details?.reply ? Details?.reply : Status?.reply}
          style={{ width: "100%", height: "140px", padding: "5px" }}
          onChange={(e) => setStatus({...Status,reply:e.target.value})}
          type="text"
          placeholder="Type Here"
          className={`w-100 h-45 br-3 p-3  ${
            lightMode
              ? "border1px-light-mode"
              : "border1px-dark-mode bg-dark-gray"
          }`}
        ></textarea>
        <div className="w-100 d-flex justify-content-end">
          {Details?.reply === "" && (
            <button
            onClick={update}
              style={{ height: "45px" }}
              className="p-10 text-white d-flex justify-content-end br-5 btn-bg-primary"
            >
              Sent Reply 
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdaminHelpCenterDetails;
