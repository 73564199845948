import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getPlans, paymentAPI } from "../api/fetchClient";
import svgSheet from "../assets/svg/svgSheet";
import { authStore, setAuth } from "../redux/reducers/authSlice";
import { getThemeMode } from "../redux/reducers/ThemeSlice";
import PaymentOfferStatus from "./PaymentOfferStatus";

function PaymentStatus() {
  const { lightMode } = useSelector(getThemeMode);
  const dispatch=useDispatch()
  const {userData} = useSelector(authStore);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [planData, setplanData] = useState({ status: true, plan: {} });
  // get plan api
  useEffect(() => {
    const getPlanAPI = async () => {
      await getPlans()
        .then((res) => {
          setplanData({
            status:
              searchParams?.get("razorpay_payment_link_status") === "paid",
            plan: res?.data?.find(
              (item) => item.plan_id === searchParams.get("plan")
            ),
          });
        })
        .catch((err) => console.log(err));
      if( searchParams?.get('event')) return
      await paymentAPI(location.search)
        .then((resp) => {
          dispatch(setAuth({
            access_token:userData.access_token,
            refresh_token:userData.refresh_token,
            user:resp.data
          }))
        })
        .then((err) => console.log(err));
    };  
    getPlanAPI();
  }, []);
if(searchParams?.get("razorpay_payment_link_status") === "paid"&&searchParams.get("plan")==='year'){
  return <PaymentOfferStatus />
}
console.log(searchParams?.get("razorpay_payment_link_status") === "paid"&&searchParams.get("plan")==='year');
  return (
    <div
      className="my-body flex justify-content-center"
      style={{ height: "fit-content" }}
    >
      <div
        style={{ maxWidth: "600px", maxHeight: "auto" }}
        className={`w-49-100 flex flex-col align-items-center br-5 ${
          lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
        }`}
      >
        
        <div className="mt-30">{planData?.status ? svgSheet.paySuccess : svgSheet.payReject}</div>

        <h1 className="fs-40-28 fw-700">
          {planData?.status ? "Payment Successful" : "Payment Failed"}
        </h1>
        {planData?.status ? (
          <div className="text-center">
            <p className="fs-18-14 fw-500 mb-10">
              You have Successfully Subscribed to{" "}
            </p>
            <p className="fs-20-16 text-blue-grad fw-700">
              "{planData?.plan?.plan_sname}"
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p className="fs-18-14 fw-500 mb-10">
              For further Queries & clarifications
            </p>
            <p className="fs-18-14  fw-600">Please Email us</p>
          </div>
        )}
        <Link to={"/"}>
          <button
            style={{ width: "200px", height: "50px" }}
            className="mb-30 btn-bg-primary fw-600 text-white fs-16-14 br-5"
          >
            Back to Dashboard
          </button>
        </Link>
        <p className="fw-500">
          Write to us{" "}
          <a href="mailto:payment@tradebrains.in" className="text-blue-grad">
          payment@tradebrains.in
          </a>
        </p>
      </div>
    </div>
  );
}

export default PaymentStatus;
