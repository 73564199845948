import { Checkbox } from "antd";
import React, { useRef, useState } from "react";

function SearchWithDropdown({
  lightMode,
  data,
  placeholder,
  setCheck,
  checkedArr,
}) {
  const searchInput = useRef(null);
  const [resultArr, setresultArr] = useState(data);
  const searchResults = (e) => {
    const lowerValue = e.target.value.toLowerCase();
    const results = data?.filter((ele) =>
      ele?.label?.toLowerCase().includes(lowerValue)
    );
    setresultArr(results);
  };
  return (
    <div className="relative">
      <div className="mb-5">
        <input
          ref={searchInput}
          onChange={searchResults}
          placeholder={placeholder}
          className={`w-100 ${
            lightMode ? "custom-input-light" : "custom-input-dark"
          }`}
          type="text"
        />
      </div>
      <div>
        {resultArr?.map((element, i) => {
          return (
            <div
              key={i}
              className={`${lightMode ? "checkbox-light" : "checkbox-dark"}`}
            >
              <Checkbox
                checked={checkedArr?.includes(element.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheck([...checkedArr, element.id]);
                  } else {
                    setCheck(checkedArr.filter((ele) => ele !== element.id));
                  }
                }}
                style={{
                  color: lightMode ? "black" : "white",
                  padding: "5px 0px ",
                }}
                className={` fs-s-12`}
              >
                {element.label}
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SearchWithDropdown;
