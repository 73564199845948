import React from "react";
import StockEndpoints from "../../../api/endPoints";
import CustomTable from "../../../components/CustomTable";
import useGetFetch from "../../../hooks/useGetFetch";
import KeyInfocard from "./KeyInfocard";

function CompanyDetails({ lightMode, symbol }) {
  const { isLoading, apiData, serverError } = useGetFetch(
    StockEndpoints.stockAddress(symbol)
  );

  const headerData = [
    {
      title: <p className="fw-600 mb-0 fs-s-16">Name</p>,
      dataIndex: "DIRNAME",
      width:'50%',
      render: (text) => {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  fontSize: "14px",
                  borderRight: "1px solid #C9D7DE",
                  borderLeft: "1px solid #C9D7DE",
                  borderBottom: "none",
                  padding: "12px 14px",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  fontSize: "14px",
                  padding: "12px 14px",
                },
          },
          children: (
            <div style={{ color: lightMode ? "black" : "white" }}>{text}</div>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">Designation</p>,
      dataIndex: "Reported_DSG",
      width:'50%',
      render(text, index) {
        return {
          props: {
            style: lightMode
              ? {
                  background: "rgba(242, 246, 248, 1)",
                  borderColor: "#C9D7DE",
                  borderRight: "1px solid #C9D7DE",
                  borderBottom: "none",
                  padding: "12px 14px",
                }
              : {
                  background: "#3B3F4F",
                  borderLeft: "1px solid #545E78",
                  borderRight: "1px solid #545E78",
                  borderBottom: "none",
                  padding: "12px 14px",
                },
          },
          children: (
            <span
              className="ff-poppins"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
  ];

  return (
    <div style={{ gap: "2rem" }} className="flex-col-row w-100">
      <KeyInfocard lightMode={lightMode} data={apiData} />
      <div className="w-100-60">
        <h3 className="fs-s-18 fw-600 mb-20">Management</h3>
        <div
          className={`table-shadow w-100 ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            columns={headerData}
            scrollable={true}
            scrollLimit={500}
            data={apiData?.management}
            border={false}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
