import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import svgSheet from "../../assets/svg/svgSheet";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import RightSectionIndex from "./RightSectionInedx";
import QueryInput from "./QueryInput";
import BreadCrumb from "../../components/BreadCrumb";
import SelectOptions from "../../components/ant/SelectOptions";
import { useParams } from "react-router-dom";

function CustomScreenerIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const { query } = useParams();
  const [filterTypeArr, setfilterTypeArr] = useState([
    {
      label: "Market Cap (Rs Cr)",
      firstValue: 25000,
      secondValue: 2500000,
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
  ]);

  const [inputQuery, setinputQuery] = useState('')
  const breadCrumbData = [{ label: "Screener", link: `/screener` }];
  useEffect(() => {
    setinputQuery(query==='new'?'':query)
  }, [query])
  
  return (
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <div className="d-flex align-items-center mb-10">
          <p className="mr-10 w-m-28  mb-0">{svgSheet.watchlistlogo}</p>
          <p className="mb-0 fs-36-20 fw-700 lh-36">Create New Query </p>
        </div>
        <BreadCrumb linkData={breadCrumbData} current={"Custom Query"} />
        <div className="flex mt-10 align-items-center justify-content-end">
          <SelectOptions
            lightMode={lightMode}
            data={[
              { value: "test", label: "test query" },
              { value: "test2", label: "test query2" },
            ]}
            // setselectd={setselectd}
            // defaultSelect={selectd}
            width={200}
          />
          {/* <button className={`h-30px btn-blue-border  br-3 fw-500  fs-14-12 ${lightMode?'text-btn-light':'text-btn-dark'}`}>
                Examples
                </button> */}
          <button className=" h-30px btn-bg-primary text-white br-3 fw-600  fs-14-12">
            Save Query
          </button>
        </div>
        <div className="d-flex flex-col-row">
          <div style={{ height: "min-content",top:'130px'}} className="  w-100-25 mt-20 p-lg-fixed">
            <QueryInput inputQuery={inputQuery} setinputQuery={setinputQuery}  lightMode={lightMode} />
          </div>
          <div  className=" ml-30-0 w-100-70">
            {
              query==='new'?
             <div  
             className={`w-100 br-5 h-70vh-60vh mt-20 bg-transparent    ${
              lightMode ? "border2px-light-mode" : "border2px-dark-mode"
            }`}></div>
              :
              <RightSectionIndex
              lightMode={lightMode}
              filterTypeArr={filterTypeArr}
              postSectorArr={[]}
              postIndexArr={[]}
            />
            }
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomScreenerIndex;
