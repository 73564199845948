import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteScreener,
  getFeedbackdataAdmin,
  getScreenerData,
} from "../../../../api/fetchClient";
import CustomPagination from "../../../../components/ant/CustomPagination";
import CustomTable from "../../../../components/CustomTable";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../../../utilityFn/numberWithCommas";
import { DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import svgSheet from "../../../../assets/svg/svgSheet";

function Screener() {
  const { lightMode } = useSelector(getThemeMode);
  const [page, setPage] = useState(1);
  const [input, setinput] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [searchInput, setsearchInput] = useState(null);
  const [Pending, setPending] = useState(false);
  const [id, setid] = useState();
  const onPageChange = (e) => {
    setPage(e);
  };
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">User name</p>,
      dataIndex: "user_name",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Query Name</p>,
      width: "50px",
      dataIndex: "name",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Email ID</p>,
      width: "50px",
      dataIndex: "email",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Action</p>,
      width: "50px",
      dataIndex: "rating",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              <DeleteOutlined
                onClick={() => {
                  setPending(true);
                  setid(record?.id);
                }}
                style={{ marginRight: "15px" }}
              />
            </span>
          ),
        };
      },
    },
  ];
  const [screenerData, setScreenerData] = useState();

  const getData = async () => {
    await getScreenerData(searchInput , page).then((res) => {
      console.log("screener", res);
      setisLoading(false);
      setScreenerData(res?.data);
      console.log("resdata ", res);
    });
  };

  useEffect(() => {
    setisLoading(true);
    getData();
  }, [searchInput , page]);

  console.log(screenerData, "screenerData");

  const deleteScreeners = (id) => {
    setisLoading(true);
    deleteScreener(id).then((res) => {
      getData()
      setPending(false);
    });
  };

  return (
    <div className="my-body px-30-0">
      <input
        //   width={"100%"}
        // value={NewWatchList}
        style={{ width: "20%", color: "white" }}
        placeholder="Search Query Name"
        value={input}
        onChange={(e) => setsearchInput(e.target.value)}
        type="text"
        className={`w-100 h-45 mr-5 br-3  p-3 mt-10 ${
          lightMode
            ? "border1px-light-mode"
            : "border1px-dark-mode bg-dark-gray"
        }`}
      ></input>
      <div
        className={`table-shadow mt-30 ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          // loading={isLoading}
          columns={columns1}
          data={screenerData?.results}
        />
        <CustomPagination
          current={page}
          lightMode={lightMode}
          onChange={onPageChange}
          total={screenerData?.count}
        />
      </div>
      <Modal
        style={{ width: "320px" }}
        width={"320px"}
        visible={Pending}
        centered
        wrapClassName={!lightMode && "modelClassname"}
        onCancel={() => {
          setPending(false);
        }}
        footer={[
          <div onClick={() => deleteScreeners(id)} className="">
            <button className="bg-darkred p-10 br-5 fw-500 text-whit e">
              Confirm
            </button>
          </div>,
        ]}
      >
        <div className="d-flex-col justify-content-center">
          <p className="d-flex mt-20 justify-content-center">
            {svgSheet.payReject}
          </p>
          <div className="d-flex flex-col align-items-center justify-content-center">
            <p className="fs-s-18 text-align-center fw-600 mb-0 d-flex justify-content-center mb-0">
              {" "}
              Delete Of Screener
            </p>
            <p className="fs-s-10">
              Are you sure you want to delete this Query?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Screener;
