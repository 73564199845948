import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import svgSheet from "../../assets/svg/svgSheet";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import TimeLineButtons from "../../components/TimeLineButtons";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import LeftIndexMoves from "../IndexDetails/LeftIndexMoves";
import NewsTabStockDetails from "../stock details/Corp Announce/NewsTabStockDetails";
import MaretPerformanceTable from "./MaretPerformanceTable";
import SelectOptions from "../../components/ant/SelectOptions";
function MarketReturnsIndex({ setisLoginPage }) {
  const { lightMode } = useSelector(getThemeMode);
  const [CurremtValue, setCurremtValue] = useState("all");
  const [value, setvalue] = useState("1d");

  useEffect(() => {
    setisLoginPage(false);
  }, []);

  const leftsidebar = [
    { label: "All Indices", value: "all" },
    { label: "BSE Indices", value: "bse" },
    { label: "NSE Indices", value: "nse" },
  ];
  const timeLineData = [
    { label: "1D", value: "1d" },
    { label: "1M", value: "1m" },
    // { label: "3M", value: "3m" },
    // { label: "6M", value: "6m" },
    // { label: "1Y", value: "1y" },
  ];
  const sidebarData = [
    {
      id: "All Indices",
      icon: svgSheet.overviewIcon,
      value: "All",
      label: "All Indices",
      offset: -150,
    },
    {
      id: "BSE Indices",
      icon: svgSheet.graphIcon,
      label: "BSE Indices",
      value: "bse",
      offset: -105,
    },
    {
      id: "NSE Indices",
      icon: svgSheet.heatMapIcon,
      label: "NSE Indices",
      value: "nse",
      offset: -130,
    },
  ];
  const [Selected, setSelected] = useState("all");

  const timeLineData1 = [
    { label: "All Indices", value: "all" },
    { label: "BSE Indices", value: "bse" },
    { label: "NSE Indices", value: "nse" },
  ];

  return (
    <>
      <ReactHelmet
        title="Index Returns | Track different market indices"
        desc="Track different market indices, their historical returns and more."
      />
      <div className="my-body scroll-behavior">
        <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
          <div className="d-flex justify-content-between mb-30 align-items-center ">
            <div className="d-flex ">
              <p
                style={{ marginRight: "10px" }}
                className="fs-36-20  mb-0 fw-700"
              >
                {svgSheet.MainMarketReturns}
              </p>
              <p className=" fs-36-20  mb-0 fw-700"> Index Returns</p>
            </div>
            <div>
              <TimeLineButtons
                data={timeLineData}
                setValue={setvalue}
                value={value}
              />
            </div>
          </div>
          <div className="only-mobile-view mb-20 d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              {" "}
              <SelectOptions
                lightMode={lightMode}
                defaultSelect={"All Indices"}
                setselectd={setCurremtValue}
                data={timeLineData1}
                width={250}
              />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: "20%" }} className="only-PC-view  h-100">
              <div className={`${lightMode ? "" : "bg-dark-gray"}  `}>
                {leftsidebar?.map((items) => (
                  <div
                    onClick={() => setCurremtValue(items?.value)}
                    style={{ height: "45px" }}
                    className={`px-20 flex align-items-center pointer ${
                      lightMode
                        ? "bb1px-light-mode hover-light-blue bt-first-child-light  "
                        : "bb1px-dark-mode bg-dark-hover bg-dark  bt-first-child-dark "
                    }
              ${
                items.value === CurremtValue
                  ? `${lightMode ? "desktop-active" : "desktop-active-dark"}`
                  : `${lightMode ? "desktop-active" : " bg-dark-gray"}`
              }
              
              `}
                  >
                    {items?.label}
                  </div>
                ))}
              </div>

              <div className={` mt-30 only-PC-view`}>
                <LeftIndexMoves
                  lightMode={lightMode}
                  CurrentIndex={"NIFTYPHARMA"}
                />
              </div>
            </div>
            <div className=" w-75-100 ml-30-0 ">
              <div>
                <MaretPerformanceTable
                  value1={value}
                  CurremtValue={CurremtValue}
                  lightMode={lightMode}
                />
              </div>
              <div>
                <p className="main-header " id="News">
                  News
                </p>
                <NewsTabStockDetails
                  symbol="JUBLINGREA"
                  lightMode={lightMode}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <MobileBottomBar navData={sidebarData} lightMode={lightMode} /> */}
      </div>
    </>
  );
}

export default MarketReturnsIndex;
