import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function PortfolioMobileSlider({lightMode,Portfolios,setCreateModel,portfolioid,setportfolioid,setcurrectPortplioName,location,planNumber,setIsPremiumModel}) {

    const  scroll= () =>{
      window.scrollTo(0, 0) 
    }

    // useEffect(() => {
    //   if (Portfolios?.length > 0) {
    //     // setPortFolio(location ? location :Portfolios[0]?.id);
    //     // setportfolioid(location?.id ? location?.id : Portfolios[0]?.id);
    //     setcurrectPortplioName(location ? location :Portfolios[0]?.name)
    //   }
    // }, [Portfolios]);

    const navigate = useNavigate()
  return (
    <div
    style={{ height: "55px", zIndex: 500, position: "sticky", bottom: "0" ,width:"99%"}}
    className={` ${
      lightMode ? "bg-gray" : "bg-dark-gray"
    } table-shadow   details-top-card  br-5  d-flex align-items-center bt-dark-mode`}
  >

    <div className="overflow-x h-100">
      {Portfolios.map((items) => (
        <div
        onClick={()=>{setportfolioid(items?.id);setcurrectPortplioName(items?.name);scroll();navigate(`/portfolio/${items?.id}`,{state:items?.name}) }}
          className="d-flex justify-content-center"
          style={{ minWidth: "100px" }}
        >
          <span
            style={{ minHeight: "38px" }}
            className={`${items.id === portfolioid ? "" : "opacity5"} fs-s-12 fw-600 mr-10 text-align-center d-flex align-items-center Textcapitalize `}
          >
            {items?.name}
          </span>
        </div>
      ))}

      <div className="p-5 align-items-center d-flex justify-centen-center">
        <button
        onClick={()=>  Portfolios?.length  >= planNumber ?  setIsPremiumModel(true) : setCreateModel(true) }
          style={{ minWidth: "150px" }}
          className={`mr-5 fs-s-12 btn-blue-border ${lightMode ? "bg-table-data text-btn-light " : "bg-dark-gray text-btn-dark"} `}
        >
          + Create Portfolio
        </button>
      </div>
    </div>
  </div>
  )
}

export default PortfolioMobileSlider