import React from "react";
import { Drawer } from "antd";
import bucketIcon from "../../assets/images/bucketIcon.svg";
import compareIcon from "../../assets/images/compareIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import DiscoundCoupon from "./DiscoundCoupon";
import svgSheet from "../../assets/svg/svgSheet";
import Fingrad from "./../../assets/images/Fingrad.png";
import zerodha from "../../assets/images/Brokers/Zerodha.png";
import Abgel from "../../assets/images/Brokers/Abgel.png";
import Mstock from "../../assets/images/Brokers/Mstock.png";
import MstockDark from "../../assets/images/Brokers/MstockDark.png";
import AbgelDark from "../../assets/images/Brokers/AbgelDark.png";
import ZerodhaLight from "../../assets/images/Brokers/ZerodhaLight.png";
import { useSelector } from "react-redux";
import { authStore } from "../../redux/reducers/authSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";

function ProductToggleCard({
  productsToggle,
  setproductsToggle,
  lightMode,
  setPopupStatus,
  authStatus,
}) {
  const navigate = useNavigate();
  const UserAuth = useSelector(authStore);

  const authRedirect = (pathName) => {
    if (authStatus) {
      navigate(pathName);
      setproductsToggle("NONE");
    } else {
      setPopupStatus("JOIN");
      setproductsToggle("NONE");
    }
  };

  const MarketDropdownData = [
    {
      name: "All Stocks",
      disc: "Every Indian stock methodically sorted by sector.",
      Logo: svgSheet.AllStocks,
      link: "stock/industry/Abrasives",
    },
    // {
    //   name: "Market Returns",
    //   disc: "Find returns and constituents of Sensex, Nifty and every other Indian Index!",
    //   Logo: svgSheet.MarketRetuenSvg,
    //   link: "",
    // },
    {
      name: "Top Gainers",
      disc: "Top Gainers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopGainersNewSvg,
      link: "marketstats/gainers",
    },
    {
      name: "Top Losers",
      disc: "Top Losers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopLosers,
      link: "marketstats/losers",
    },
    {
      name: "Market News",
      disc: "Stay updated with the latest news in the business world",
      Logo: svgSheet.MarketNewsSvg,
      link: "news",
    },
    {
      name: "Stock Heatmaps",
      disc: "Get bird eye view of how the market is performing",
      Logo: svgSheet.heatMapMenu,
      link: "index/NIFTY/heatmap",
    },
    {
      name: "Index Returns ",
      disc: "A straightforward and uncomplicated scan of the performance of all Indian indices.",
      Logo: svgSheet.MarketReturns,
      link: "marketstats",
    },  
    {
      name: "Corporate Actions",
      disc: "Keep track of all Dividends, Bonuses, Splits & other corporate actions affecting your stocks.",
      Logo: svgSheet.corporateActionMenu,
      link: "corporateactions",
    },  
    // {
    //   name: "Index Returns",
    //   disc: "Track different market indices, their historical returns and more.",
    //   Logo: svgSheet.heatMapMenu,
    //   link: "marketstats",
    // },
  ];
 
  return (
    <Drawer
      style={{ top: productsToggle === "Products" ?`${74+bannerOffsetCalc(UserAuth)}px` : "0px" }}
      //     drawerStyle={{padding:'0px'}}
      drawerStyle={{
        background: lightMode ? "white" : "#292E3F",
        color: lightMode ? "black" : "white",
      }}
      headerStyle={{ marginInline: "16px", padding: "0px" }}
      bodyStyle={{ margin: "16px 0px 0px 0px", padding: "0px" }}
      contentWrapperStyle={{ width: "99%" }}
      // title="Tools"
      placement={"top"}
      closable={false}
      onClose={() => setproductsToggle("NONE")}
      visible={productsToggle === "Products" ? true : false}
      key="tools"
      height="auto"
      zIndex={500}
    >
      <div
        className=""
        //   style={{ maxWidth: "1410px", marginInline: "auto", padding: "0px" }}
      >
        <div className="flex ff-poppins ff-poppins max-w mx-auto h-100  px-15 relative">
          <div
            style={{ width: "70%" }}
            className="w-70 bbr-blue d-flex flex-wrap"
          >
            {/* <div
            className="w-100  fw-500 fs-s-20 mb-10 fw-700 d-flex align-items-center"
            // style={{ height: "50px" }}
          >
            Market
          </div> */}
            <div className="d-flex flex-wrap my-50  justify-content-start">
              {MarketDropdownData.map((items,i) => (
                <div key={i}
                  style={{ width: "31%" }}
                  onClick={() => navigate(`/${items?.link}`)}
                  className={` h-100px mt-10 flex p-10 ${
                    lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
                  } pointer`}
                >
                  <p> {items.Logo}</p>
                  <div>
                    <p className="fw-700 fs-s-14 line-h-18">{items?.name}</p>
                    <p className="fs-s-12 line-h-18 pr-8">{items.disc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Knowledge Center  */}
          <div className="w-30 d-flex-col ">
            <div className="w-100 py-15 flex mt-25 align-items-center">
              <div
                className="w-100  fw-500 fs-s-20 mt-15 fw-700 d-flex align-items-center"
                // style={{ height: "50px" }}
              >
                Open Demat Account
              </div>
            </div>
            <div className="mb-10">
              <div className={`${lightMode ? "bg-gray": "card-drop-dark-shadow"} p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}>
                <div>
                  <img className=" w-230px h-60px fit-cover  "  src={lightMode ? ZerodhaLight : zerodha}></img>
                  <p
                    className={`${
                      lightMode ? "" : "text-gray"
                    } fs-s-12 mb-0 ml-10`}
                  >
                    India’s Largest discount Broker
                  </p>
                </div>
                <a href="https://tradebrains.in/get/zerodha/" target="_blank">
                  <button className=" btn-bg-primary  text-white br-5 p-5">
                    Open Account
                  </button>
                </a>
              </div>
              <div className={`${lightMode ? "bg-gray": "card-drop-dark-shadow"} p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}>

                <a>
                  <img className=" w-230px h-60px fit-cover " src={lightMode ? Abgel:AbgelDark}></img>
                  <p
                    className={`${
                      lightMode ? "" : "text-gray"
                    } fs-s-12 mb-0 ml-10`}
                  >
                    India’s full service discount Broker
                  </p>
                </a>
                <a href="https://tradebrains.in/get/angelbroking/"
                  target="_blank"
                >
                  <button className=" btn-bg-primary  text-white br-5 p-5">
                    Open Account
                  </button>
                </a>
              </div>
              <div className={`${lightMode ? "bg-gray": "card-drop-dark-shadow"} p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}>
            
                <div>
                  <img className=" w-230px h-60px fit-cover " src={lightMode ? Mstock : MstockDark}></img>
                  <p
                    className={`${
                      lightMode ? "" : "text-gray"
                    } fs-s-12 mb-0 ml-10`}
                  >
                    India’s full service discount Broker
                  </p>
                </div>
                <a href="https://tradebrains.in/get/mstock1/" target="_blank">
                  <button className=" btn-bg-primary  text-white br-5 p-5">
                    Open Account
                  </button>
                </a>
              </div>
              {/* <a href='https://tradebrains.in/get/angelbroking/' target="_blank"><img  className='p-10 w-200px h-100px '  src={Abgel}></img></a>
            <a href='https://tradebrains.in/get/mstock1/' target="_blank"><img  className='p-10 w-200px h-100px '  src={lightMode ? MstockDark : Mstock}></img></a> */}
            </div>
            {/* <div
            //   onClick={() => navigate(`/${items?.link}`)}
            className={`w-100 h-100px mt-30  flex  ${
              lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
            } pointer`}
          >
            <p> {svgSheet.KnowlegeCenter}</p>
            <div>
              <p className="fw-700 fs-s-14 line-h-18">Knowledge Center</p>
              <p className="fs-s-12 line-h-18">
                This is an extensive collection of questions answered on
                everything about the stock market.{" "}
              </p>
            </div>
          </div> */}
            {/* <div
            //   onClick={() => navigate(`/${items?.link}`)} 
            className={`w-100 h-100px mt-10 flex p-10   ${
              lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
            } pointer`}
          >
            <img  width={22} height={22} src={Fingrad} alt=""></img>
            <div>
              <p className="fw-700 fs-s-14 line-h-18">Fingrad</p>
              <p className="fs-s-12 line-h-18 ">
                New to the world of investing? Learn everything about your
                finances from industry experts here!
              </p>
            </div>
          </div> */}
          </div>
        </div>
        <div
          style={{ height: "50px" }}
          className={` d-flex align-items-center justify-content-around ${
            lightMode ? "bg-gray " : "bluedark-gradient"
          } `}
        >
          {!authStatus && (
            <div className="d-flex align-items-center">
              <p className="fw-700 fs-s-16  mb-0">Ready to get started ?</p>
              <p
                className={`mb-0 ml-10 fw-700 ${
                  lightMode ? "" : "text-btn-dark"
                }`}
              >
                Sign up for free
              </p>
            </div>
          )}
          <div>
            <p
              onClick={() => navigate("/getpremium")}
              className="fw-700 fs-s-16  mb-0 underline pointer"
            >
              View Pricing
            </p>
          </div>
        </div>
      </div>
    </Drawer>
    // <Drawer
    //     style={{top:productsToggle==="Products"?'74px':'0px'}}
    //     drawerStyle={{background:lightMode?'white':'#3B3F4F',color:lightMode?'black':'white'}}
    //     // maskStyle={{width:'99%'}}
    //     // drawerStyle={{maxWidth:'1440px',marginInline:'auto',padding:'0px'}}
    //     headerStyle={{marginInline:'16px',padding:'0px'}}
    //     bodyStyle={{margin:'16px 24px 16px 13px',padding:'0px'}}
    //     contentWrapperStyle={{width:"99%"}}
    //     title="Products"
    //     placement={'top'}
    //     closable={false}
    //     onClose={()=>setproductsToggle("NONE")}
    //     visible={productsToggle==="Products"?true:false}
    //     key='top'
    //     height='200px'
    //    zIndex={500}
    //   >
    //    <div className='d-flex justify-content-between w-100 ff-poppins' style={{maxWidth:'1410px',marginInline:'auto',padding:'0px'}}>
    //      <div  onClick={()=>authRedirect('/buckets')} className='pointer'>
    //      <div  className={`drop-box flex ${lightMode?'hover-light-blue':'bg-dark-hover'}`} >
    //         <img className='w-20 h-20' src={bucketIcon} alt='icon'/>
    //         <div >
    //             <p className='fw-500 line-h-18'>Buckets</p>
    //             <p className='fs-12 line-h-18'>Curated collection of best stocks, organized for you to simplify your stock research</p>
    //         </div>
    //      </div>
    //      </div>
    //      <div onClick={()=>authRedirect('/stockCompare')} className={`drop-box flex pointer ${lightMode?'hover-light-blue':'bg-dark-hover'}`}>
    //         <img className='w-20 h-20' src={compareIcon} alt='icon'/>
    //         <div >
    //             <p className='fw-500 line-h-18'>Compare Stocks</p>
    //             <p className='fs-12 line-h-18'>Compare stocks within the same or different industry to find out which one is performing best </p>
    //         </div>
    //      </div>
    //      <div onClick={()=>authRedirect('/screener')} className={`drop-box flex pointer ${lightMode?'hover-light-blue':'bg-dark-hover'}`}>
    //         <div >
    //             <p className='fw-500 line-h-18'>Stock Screener</p>
    //             <p className='fs-12 line-h-18'>Find winning stocks by applying  different filters that meets your selection criteria</p>
    //         </div>
    //      </div>
    //      <div onClick={()=>authRedirect('/superstars')} className={`pointer drop-box flex ${lightMode?'hover-light-blue':'bg-dark-hover'}`}>
    //         <div >
    //             <p className='fw-500 line-h-18'>Superstar Portfolios</p>
    //             <p className='fs-12 line-h-18'>Track the investments and latest portfolios of the super investors in India share market</p>
    //         </div>
    //      </div>
    //    </div>
    //   </Drawer>
  );
}

export default ProductToggleCard;
