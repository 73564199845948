import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StockEndpoints from "../../../api/endPoints";
import CustomPagination from "../../../components/ant/CustomPagination";
import CustomTable from "../../../components/CustomTable";
import useGetFetch from "../../../hooks/useGetFetch";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";
import GraphIndexdetails from "../../IndexDetails/GraphIndexdetails";
import SortHeader from "../../screener/table section/SortHeader";

function GainerLoserTable({ lightMode, activeIndex, apiEndpoint }) {
  const [activePage, setactivePage] = useState({});
  const [sortState, setsortState] = useState({
    head: "change",
    sortOrder: false,
  });
  const {
    isLoading,
    apiData: indexData,
    serverError,
  } = useGetFetch(
    StockEndpoints.alllMarketMovers(
      activeIndex.symbol,
      apiEndpoint,
      activePage.page ?? 1,
      activePage.perPage ?? 25,
      sortState.head,
      sortState.sortOrder
    )
  );

  useEffect(() => {
    setactivePage({ page: 1, perPage: 25 });
  }, [activeIndex]);

  const tableHeaders = [
    {
      title:
      (
        <SortHeader
        label="Company Name"
        header="comp_name"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "comp_name",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              <Link to={`/stock/${record?.symbol}/consolidated`}>{text}</Link>
            </div>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="CMP"
        header="close"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "close",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="Prev Close"
        header="prev_close"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "prev_close",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: 
      (
        <SortHeader
        label="Change"
        header="percent"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "change",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div style={{ gap: "10px" }} className="d-flex align-items-center">
              <span
                className="ff-lato"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: redGreenColorPicker(text, lightMode),
                }}
              >
                {record.percent.toFixed(2)} %
              </span>
              <span
                className="ff-lato"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: redGreenColorPicker(text, lightMode),
                }}
              >
                ( ₹ {numberWithCommas(text)})
              </span>
            </div>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">7 Days Trend</p>,
      dataIndex: "week_trend",
      width: "50px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div style={{ height: "50px" }}>
              <GraphIndexdetails
                lightMode={lightMode}
                graphData={record?.prices}
              />
              {/* <TableLineGraph graphData={record?.prices} /> */}
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          loading={isLoading}
          columns={tableHeaders}
          data={indexData?.results}
          scrollable={true}
          scrollLimit={700}
        />
      </div>
      <CustomPagination
        showQuickJumper={true}
        total={indexData?.count}
        onChange={(num, pageSize) =>
          setactivePage({ page: num, perPage: pageSize })
        }
        lightMode={lightMode}
        pageSize={activePage?.perPage}
        current={activePage?.page}
      />
    </>
  );
}

export default GainerLoserTable;
