import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteAdminStockRecommendation,
  getRecommendation,
  getUserManagementUserList,
} from "../../../../api/fetchClient";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import UserManagmentTable from "./UserManagmentTable";
import DeleteModal from "../../Components/DeleteModal";
// import PortfolioTable from "./PortfolioTable";

function UserManagment() {
  const { lightMode } = useSelector(getThemeMode);
  const [userList, setUserList] = useState([]);
  const [searchInput, setsearchInput] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [deleleModal, setDeleleModal] = useState({ state: false, id: null });
  const getData = async () => {
    await getUserManagementUserList(searchInput)
      .then((resp) => {
        setUserList(resp?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getData();
  }, []);
  console.log(userList, "user list tabel");

  const getSearchedData = async () => {
    setisLoading(true);
    await getUserManagementUserList(searchInput)
      .then((resp) => {
        console.log(resp, "asdasdasd");
        setUserList(resp?.data);
        setisLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const deleteClick = (id) => {
    setDeleleModal({ state: true, id: id });
  };
  const onDeleteConfirm = () => {
    console.log(deleleModal.id);
    deleteAdminStockRecommendation(deleleModal.id).then((resp) => {
      message.success("Stock Recommendation Deleted successfully");
      getData();
      setDeleleModal({ state: false, id: null });
    });
  };
  return (
    <>
      <div className="my-body px-30-0">
        <div className="d-flex ">
          <input
            //   width={"100%"}
            value={searchInput}
            style={{ width: "20%", color: "white" }}
            placeholder="Search by mail"
            onChange={(e) => setsearchInput(e.target.value)}
            type="text"
            className={`w-100 h-45  ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}></input>

          <button
            onClick={getSearchedData}
            className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white">
            Search
          </button>
        </div>
        <UserManagmentTable
          setisLoading={setisLoading}
          isLoading={isLoading}
          lightMode={lightMode}
          userList={userList}
          setDeleteModal={deleteClick}
          setUserList={setUserList}
        />
      </div>
      <DeleteModal
        item={true}
        visible={deleleModal.state}
        setVisible={setDeleleModal}
        lightMode={lightMode}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}

export default UserManagment;
