import { createAsyncThunk, createSlice,current } from '@reduxjs/toolkit'
import StockEndpoints from '../../api/endPoints'
import { get, post } from '../../api/MainClient'
import produce from 'immer'

// get user watchlist API
export const WatchListFetch= createAsyncThunk(
    "watchlist/WatchListFetch",
    async ()=>{
        const url=StockEndpoints.userWatchlist
        const response=await get(url)
        return response.data
    }
)

//add new stock to watchlsit with data from redux store
export const addStockToWatchlistAPI= createAsyncThunk(
    "watchlist/addStockToWatchlistAPI",
    async (item,watchlistName,thunkAPI)=>{
        const url=StockEndpoints.addStockToWatchlist()
        if(item !== "") return
        const response=await post(url,{stock:item.stock})
           await thunkAPI.dispatch(WatchListFetch())
           await thunkAPI.dispatch(watchListSlice.actions.resetWatchlistArr())
     
        return response
    }
)

//add new stock to watchlsit 
export const addToWatchlistWithData= createAsyncThunk(
    "watchlist/addToWatchlistWithData",
    async (params,thunkAPI)=>{
        const url=StockEndpoints.addStockToWatchlist(params.listName)
            for (let i = 0; i < params.stock.length; i++) {
            await post(url,{stock:params.stock[i]}) 
            }
           await thunkAPI.dispatch(WatchListFetch())
        //    return response.data
     
    }
)

const initialState = {
watchListData:{},
activeTab:0,
watchlistArray:[
    {stock:"",currPrice:0,target:0}
]
}

const watchListSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
     setActiveTab:(state,{payload})=>{
        return {...state,activeTab:payload}
     },
     addStockToArray:(state,{payload})=>{
           return {...state,watchlistArray:[...state.watchlistArray,
            {stock:"",currPrice:0,target:0}]}
     },
     editStockArrayList:(state,{payload})=>{
        const nextstate=produce(current(state.watchlistArray),draft=>{
            draft[payload.index][payload.keyName]=payload.value
        }
        )
        return {...state,watchlistArray:nextstate}
     },
     deleteStockArrayObj:(state,{payload})=>{
        state.watchlistArray= state.watchlistArray.filter((item,i)=>i!==payload)
     },
     resetWatchlistArr:(state,{payload})=>{
         return {...state,watchlistArray:[{stock:"",currPrice:0,target:0}]}
     }
  },    
  extraReducers:{
      [WatchListFetch.fulfilled]:(state,{payload})=>{
    return {...state,watchListData:payload,activeTab:Object.keys(payload)[0]}
    }
  }
});

export const {setActiveTab,addStockToArray,editStockArrayList,deleteStockArrayObj,resetWatchlistArr} = watchListSlice.actions
export const getWatchListStore=(state)=>state.watchlist
export default watchListSlice.reducer