import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import StockEndpoints from "../../../api/endPoints";
import { postStockArr } from "../../../api/fetchClient";
import useGetFetch from "../../../hooks/useGetFetch";
import { WatchListFetch } from "../../../redux/reducers/watchListSlice";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";
import StockGraph from "../../stock details/StockGraph";
import TopIndexTable from "../TopIndexTable";
import UserProductCard from "../UserProductCard";

function BottomSection({ lightMode }) {
  const dispatch = useDispatch();
  const [activeSymbol, setactiveSymbol] = useState("SENSEX");
  const [watchlist, setwatchlist] = useState({ name: "Watchlist", data: [] });
  // get watchlist data
  const { isLoading, serverError, apiData } = useGetFetch(
    StockEndpoints.trendingIndices
  );

  useEffect(() => {
    dispatch(WatchListFetch())
      .then(async (resp) => {
        setwatchlist({ name: "Watchlist", data: ["LOADING"] });
        const getKeys = Object.keys(resp.payload);
        if (getKeys.length > 0) {
          const stokckList = Object.values(resp.payload)[0].map(
            (item) => item.symbol
          );
          await postStockArr(stokckList)
            .then((response) => {
              const finalArr = response.data.map((stock) => {
                return {
                  label: (
                    <Link 
                      className="overFlow-text link-hover-underline"
                      to={`/stock/${stock.symbol}/consolidated`}
                    >
                      {stock.company_name}
                    </Link>
                  ),
                  // value: `${stock.per_change > 0 ? "+" : ""}${
                  //   stock.per_change
                  // }`,
                  value:(
                    <div style={{width:'50%'}} className="d-flex flex-col align-items-end">
                      <p className="mb-0 ff-lato fs-16-14 fw-600">₹{stock?.close}</p>
                      <span style={{color:redGreenColorPicker (stock?.per_change, lightMode)}} className="fs-14-12 fw-500 ff-lato">{stock?.change > 0 ? "+" : ""}{stock?.change} ({stock?.per_change > 0 ? "+" : ""}{stock?.per_change}%)</span>
                    </div>
                  ),
                  unit: "%",
                };
              });
              setwatchlist({
                name: getKeys[0],
                data:finalArr.length===0?['EMPTY']:finalArr.slice(0, 4),
              });
            })
            .catch(() => setwatchlist({ name: "Watchlist", data: ["EMPTY"] }));
        } else {
          setwatchlist({ name: "Watchlist", data: ["EMPTY"] });
        }
      })
      .catch(() => setwatchlist({ name: "Watchlist", data: ["EMPTY"] }));
  }, []);

  const tableContent = useMemo(() => {
    const data = apiData?.map((index) => {
      return {
        symbol: index.symbol,
        value: `${numberWithCommas(index.close)}`,
        per_change: `${index.per_change > 0 ? "+" : ""}${index.per_change}`,
      };
    });
    return data;
  }, [apiData]);
  
  return (
    <div style={{ minHeight: "300px" }} className="w-100 flex flex-col-row">
      <div className="w-70-100 flex flex-col-row">
        <div className="w-100-60">
          <StockGraph
            type={"index"}
            symbol={activeSymbol}
            lightMode={lightMode}
            volumeBar={false}
            height="300px"
          />
        </div>
        <div className="w-100-40 ">
          <TopIndexTable
            isLoading={isLoading}
            tableContent={tableContent}
            activeSymbol={activeSymbol}
            lightMode={lightMode}
            onSelectFn={(symbol) => setactiveSymbol(symbol)}
          />
        </div>
      </div>
      <div className="w-100-30 ">
        <UserProductCard
        tabletype="watchlist"
          lightMode={lightMode}
          head={watchlist?.name}
          tableContent={watchlist?.data}
          bottomNav={{ text: "Go to Watchlist", link: "/watchlist" }}
        />
      </div>
    </div>
  );
}

export default BottomSection;
