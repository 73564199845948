import React,{useEffect, useState} from 'react'
import { Drawer} from 'antd';
import searchIcon from '../../assets/images/searchIcon.svg'
import SearchComponent from './SearchComponent';
import { useSelector } from 'react-redux';
import { authStore } from '../../redux/reducers/authSlice';
import { bannerOffsetCalc } from '../../utilityFn/bannerOffsetcalc';

function MobileSearch({mobileSearchToggle,setMobileSearchToggle,inputData,lightMode,searchResultDiv,getResults,setMobileSearchDrop,MobileSearchDrop}) {
    const [mobSearchInput, setmobSearchInput] = useState('')
    const UserAuth = useSelector(authStore);
  useEffect(() => {
    setmobSearchInput(inputData)
  }, [inputData])
  
  return (
      <>
    <Drawer
    style={{top:mobileSearchToggle==="SEARCH"?`${105+bannerOffsetCalc(UserAuth)}px`:'0px'}}
    className="ff-poppins "
    // drawerStyle={{maxWidth:'1440px',marginInline:'auto',padding:'0px'}}
    drawerStyle={{background:lightMode?'white':'#292E3F',color:lightMode?'black':'white'}}
    headerStyle={{marginInline:'16px',padding:'0px'}}
    bodyStyle={{margin:'16px 24px 16px 24px',padding:'0px'}}
    // title="Tools"
    placement={'top'}
    closable={false}
    onClose={()=>{setMobileSearchToggle("NONE"); setMobileSearchDrop(false)}}
    visible={ mobileSearchToggle==="SEARCH"?true:false}
    // ;  MobileSearchDrop=== true && setMobileSearchDrop(false)
    afterOpenChange={(openorclose)=>console.log("isopen",openorclose)}
    key='tools'
    height='auto'
   zIndex={500}
  >
    {/* <input type='text'  value={mobSearchInput} onChange={(e)=>{
      getResults(e)
      setmobSearchInput(e.target.value)
      }} className={`w-100 px-15  h-40px ${lightMode?'bg-gray':'bg-dark-gray'}`}  style={{borderRadius:'42.5px',border:'none'}} placeholder='Search Company'/> */}
      <SearchComponent planData={UserAuth}  lightMode={lightMode} setMobileSearchToggle={setMobileSearchToggle}/>
     </Drawer>
     {mobSearchInput&&<div className={`mob-result-body w-100  absolute ${lightMode?'bg-white':'bg-dark-gray '}  px-15 b-whitesmoke`} style={{top:'177px'}} >
 {searchResultDiv}
</div>}

     </>
  )
}

export default MobileSearch