import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Router,
} from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/navBar/NavBar";
// import PopupIndex from "../components/popups/PopupIndex";
import ScrollToTop from "../components/ScrollToTop";
import Ticker from "../components/Ticker";
import IndexDetails from "../pages/IndexDetails/IndexDetails";
import StockDetailsIndex from "../pages/stock details/StockDetailsIndex";
// import WatchlistIndex from '../pages/watchlist/WatchlistIndex';
import WatchlistIndex from "../pages/watchlist/WatchListMain";
import { useSelector } from "react-redux";
import { getThemeMode } from "../redux/reducers/ThemeSlice";
import ShareHoldingIndex from "../pages/shareHolding/ShareHoldingIndex";
import BucketIndex from "../pages/Buckects/BucketIndex";
import BucketDetailsIndex from "../pages/bucket details/BucketDetailsIndex";
import StocksCompare from "../pages/CompareStocks/StocksCompare";
import ViewAllBuckets from "../pages/Buckects/ViewAllBuckets";
import PortfolioIndex from "../pages/star portfolio/PortfolioIndex";
import StarDetailsIndex from "../pages/star portfolio/portfolio Detalis/StarDetailsIndex";
import ViewAllCorpAction from "../pages/stock details/Corp Announce/ViewAllCorpAction";
import GainersIndex from "../pages/gainresAndLosers/GainersIndex";
import LosersIndex from "../pages/gainresAndLosers/LosersIndex";
import MarketNews from "../pages/Market News/MarketNews";
import AllStocksIndex from "../pages/all stocks/AllStocksIndex";
import SectorTable from "../pages/all stocks/SectorTable";
import NewsCategorys from "../pages/Market News/NewsCategorys";
import ScreenerIndex from "../pages/screener/ScreenerIndex";
import PortfolioAnalysis from "../pages/Portfolio Analysis/PortfolioAnalysis";
import AllTradeRecords from "../pages/Portfolio Analysis/AllTradeRecords";
import CustomScreenerIndex from "../pages/custom screener/CustomScreenerIndex";
import PlanPage from "../pages/PlanPage/PlanPage";
import HomeIndex from "../pages/home/HomeIndex";
import { BackTop } from "antd";
import LoginAndSignUps from "../pages/Authentications/LoginAndSignUps";
import DashboardIndex from "../pages/dashboard/DashboardIndex";
import GoogleRedirect from "../pages/Authentications/GoogleRedirect";
import { CaretUpOutlined, UpOutlined } from "@ant-design/icons";
import ProfileIndex from "../pages/user profile/ProfileIndex";
import { authStore } from "../redux/reducers/authSlice";
import AuthRouter from "./AuthRouter";
import Error404 from "../components/Error404";
import UnderDevPage from "../components/UnderDevPage";
import PaymentStatus from "../components/PaymentStatus";
import Policys from "../pages/Policys/Policys";
import Disclaimer from "../pages/Policys/Disclaimer";
import Terms from "../pages/Policys/Terms";
import Refund from "../pages/Policys/Refund";
import PremiumRouter from "./PremiumRouter";
import ContactIndex from "../pages/contactUs/ContactIndex";
import Plansummery from "../pages/PlanPage/Plansummery";
import AccountActive from "../pages/Authentications/AccountActive";
import MarketReturnsIndex from "../pages/MarketReturns/MarketReturnsIndex";
import AffiliateHome from "../pages/affiliate/component/pages/home/AffiliateHome";
import AffiliateRegisterIndex from "../pages/affiliate/component/pages/register/AffiliateRegisterIndex";
import AffliateDash from "../pages/affiliate/component/pages/Deshboard/AffliateDash";
import SalesIndex from "../pages/affiliate/component/pages/Deshboard/SalesIndex";
import Affiliatedashboard from "../pages/affiliate/component/pages/Deshboard/Affiliatedashboard";
import AffiliateSign from "../pages/affiliate/component/pages/Deshboard/AffiliateSign";
import AffiliateHelp from "../pages/affiliate/component/pages/Deshboard/AffiliateHelp";
import AffliatePromotion from "../pages/affiliate/component/pages/Deshboard/AffliatePromotion";
import AffiliateStatus from "../pages/affiliate/component/pages/register/AffiliateStatus";
import AffiliatePayout from "../pages/affiliate/component/pages/Deshboard/AffiliatePayout";
// import EventTopBanner from "../components/EventTopBanner";
// import EventPopup from "../components/popups/EventPopup";
import PaymentOfferStatus from "../components/PaymentOfferStatus";
import AffiliateTermsAndCondition from "../pages/affiliate/component/pages/Deshboard/AffiliateTermsAndCondition";
import AffiliateRouter from "./AffiliateRouter";
import AffiliateUpdatebank from "../pages/affiliate/component/pages/bank details/AffiliateUpdatebank";
// import Admin from "../pages/Admin/Admin";
// import AdminDash from "../pages/Admin/AdminDash";
// import AdminDashboard from "../pages/Admin/Pages/AdminDashboard";
// import AdminAffiliateApproval from "../pages/Admin/Pages/AdminAffiliateApproval";
// import AdminApprovalDetails from "../pages/Admin/Pages/AdminApprovalDetails";
// import AdminAllAffiliate from "../pages/Admin/Pages/AdminAllAffiliate";
// import AdminAllAffiliateDetails from "../pages/Admin/Pages/AdminAllAffiliateDetails";
// import AdminAffiliateSalesPerforms from "../pages/Admin/Pages/AdminAffiliate/AdminAffiliateSalesPerforms";
// import AdminAffiliatePayout from "../pages/Admin/Pages/AdminAffiliate/AdminAffiliatePayout";
// import AdminAffiliateReports from "../pages/Admin/Pages/AdminAffiliate/AdminAffiliateReports";
// import AdminAffiliatePromotion from "../pages/Admin/Pages/AdminAffiliate/AdminAffiliatePromotion";
// import AdminUserList from "../pages/Admin/Pages/AdminUser/AdmiUserList";
// import AdminAffiliateHelpCenterr from "../pages/Admin/Pages/AdminAffiliate/AdminAffiliateHelpCenterr";
import ChartIndex from "../components/Charts/ChartIndex";
import PremiumSupportIndex from "../pages/premium suport/PremiumSupportIndex";
// import AdminPremiumFeedback from "../pages/Admin/Pages/AdminPremiumUser/AdminPremiumFeedback";
import AdminRouterAuth from "./AdminRouterAuth";
import AdaminHelpCenterDetails from "../pages/Admin/Pages/AdminAffiliate/AdaminHelpCenterDetails";
// import AdaminPremiumDetails from "../pages/Admin/Pages/AdminPremiumUser/AdminPremiumDetails";
// import AdminBuckets from "../pages/Admin/Pages/Buckets/AdminBuckets";
// import AdminBucketDetails from "../pages/Admin/Pages/Buckets/AdminBucketDetails";
import JoinFingradIndex from "../pages/fingrad/JoinFingradIndex";
import ViewAllHeatmap from "../pages/IndexDetails/ViewAllHeatmap";
// import AdminaffiliatesUsersSignup from "../pages/Admin/Pages/AdminAllAffiliate/AdminaffiliatesUsersSignup";
// import TransationDetails from "../pages/Admin/Pages/TransationsDetails/TransationDetails";
import DotLoader from "../components/spinners/DotLoader";
import NewUpdates from "../pages/new updates/NewUpdates";
import BacktestingIndex from "../pages/portfolio backtesting/BacktestingIndex";
import CorporateActions from "../pages/CorporateActions/CorporateActions";
import OneTapSignin from "../pages/Authentications/OneTapSignin";
import AdminPortfolioIndex from "../pages/Admin/Pages/star portfolio/AdminPortfolioIndex";
import CreatePortfolio from "../pages/Admin/Pages/star portfolio/CreatePortfolio";
import Calculator from "../pages/Calculator/calculator";
import ReturnOnEquityCalc from "../pages/Calculator/ReturnOnEquityCalc";
import Editportfolio from "../pages/Admin/Pages/star portfolio/Editportfolio";
import EventTopBanner from "../components/EventTopBanner";
import EventPopup from "../components/popups/EventPopup";
import PriceEarningsCalc from "../pages/Calculator/Price Earnings Valuation/PriceEarningsCalc";
import AdvanceGrahamcalc from "../pages/Calculator/AdvanceGrahan/AdvanceGrahamcalc";
import GrahamNumberCalc from "../pages/Calculator/Graham Number Valuation/GrahamNumberCalc";
import DividendDiscountCalc from "../pages/Calculator/Dividend Discount Model Valuation/DividendDiscountCalc";
import StockProfitCalc from "../pages/Calculator/Stock Profit Calculator/StockProfitCalc";
import SystematicInvestment from "../pages/Calculator/Systematic Investment Plan/SystematicInvestment";
import OneTimeValueCalc from "../pages/Calculator/One-Time value Calculator/OneTimeValueCalc";
import DiscountedCashFlowCalc from "../pages/Calculator/Discounted Cash Flow Calculation/DiscountedCashFlowCalc";
import DhandoValuation from "../pages/Calculator/Dhando Valuation/DhandoValuation";
import StockAverageCalc from "../pages/Calculator/Stock Average Calculator/StockAverageCalc";
import BrokerageCalculator from "../pages/Calculator/Brokerage Calculator/BrokerageCalculator";
import Feedback from "../pages/Admin/Pages/Feedback/Feedback";
import AdminNotification from "../pages/Admin/Pages/Notification/AdminNotification";
import AdminAllSignup from "../pages/Admin/Pages/AdminAffiliate/AdminAllSignUps";
import StockDetailMain from "../pages/Admin/Pages/StockDetails/StockDetailMain";
import AddStockDetails from "../pages/Admin/Pages/StockDetails/AddStockDetails";
import EditStockDetails from "../pages/Admin/Pages/StockDetails/EditStockDetails";
import AdminStockRecommendation from "../pages/Admin/Pages/StockRecommendation/AdminStockRecommendation";
import SaveStockRecommendation from "../pages/Admin/Pages/StockRecommendation/SaveRecommendation";
import EditStockRecommendation from "../pages/Admin/Pages/StockRecommendation/EditStockRecommendation";
import UserManagment from "../pages/Admin/Pages/UserManagement/UserManagment";
import EditUserManagement from "../pages/Admin/Pages/UserManagement/Edit/EditUserManagement";
import AdminPortfolioIndexNew from "../pages/Admin/Pages/star portfolio new/AdminPortfolioIndexNew";
import CreatePortfolioNew from "../pages/Admin/Pages/star portfolio new/CreatePortfolioNew";
import EditportfolioNew from "../pages/Admin/Pages/star portfolio new/EditportfolioNew";

import Screener from "../pages/Admin/Pages/Screener/Screener";

function Routers() {
  const { lightMode } = useSelector(getThemeMode);
  const auth = useSelector(authStore);
  const [isLoginPage, setisLoginPage] = useState(false);
  const [MobileSearchDrop, setMobileSearchDrop] = useState(false);
  const AdminDash = React.lazy(() => import("../pages/Admin/AdminDash"));
  const AdminPremiumFeedback = React.lazy(() =>
    import("../pages/Admin/Pages/AdminPremiumUser/AdminPremiumFeedback")
  );
  const AdaminPremiumDetails = React.lazy(() =>
    import("../pages/Admin/Pages/AdminPremiumUser/AdminPremiumDetails")
  );
  const AdminUserList = React.lazy(() =>
    import("../pages/Admin/Pages/AdminUser/AdmiUserList")
  );
  const AdminBuckets = React.lazy(() =>
    import("../pages/Admin/Pages/Buckets/AdminBuckets")
  );
  const AdminaffiliatesUsersSignup = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAllAffiliate/AdminaffiliatesUsersSignup")
  );
  const AdminBucketDetails = React.lazy(() =>
    import("../pages/Admin/Pages/Buckets/AdminBucketDetails")
  );
  const AdminAffiliateApproval = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliateApproval")
  );
  const AdminApprovalDetails = React.lazy(() =>
    import("../pages/Admin/Pages/AdminApprovalDetails")
  );
  const AdminDashboard = React.lazy(() =>
    import("../pages/Admin/Pages/AdminDashboard")
  );
  const AdminAllAffiliate = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAllAffiliate")
  );
  const AdminAllAffiliateDetails = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAllAffiliateDetails")
  );
  const AdminAffiliateSalesPerforms = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliate/AdminAffiliateSalesPerforms")
  );
  const AdminAffiliatePayout = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliate/AdminAffiliatePayout")
  );
  const AdminAffiliateReports = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliate/AdminAffiliateReports")
  );
  const AdminAffiliatePromotion = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliate/AdminAffiliatePromotion")
  );
  const TransationDetails = React.lazy(() =>
    import("../pages/Admin/Pages/TransationsDetails/TransationDetails")
  );
  const AdminAffiliateHelpCenterr = React.lazy(() =>
    import("../pages/Admin/Pages/AdminAffiliate/AdminAffiliateHelpCenterr")
  );
  const AdminCoupons = React.lazy(() =>
    import("../pages/Admin/Pages/coupons/CouponAdminIndex")
  );
  const AdminSignupCount = React.lazy(() =>
    import("../pages/Admin/Pages/AdminDash/AdminSignUpCount")
  );

  useEffect(() => {
    if (window.location.pathname === "/login") setisLoginPage(true);
    else if (window.location.pathname === "/signup") setisLoginPage(true);
    else if (window.location.pathname === "/confirm_password")
      setisLoginPage(true);
    else if (window.location.pathname === "/verify-email") setisLoginPage(true);
  }, []);

  return (
    <BrowserRouter className="relative">
      <ScrollToTop>
        {/* { !auth?.userData?.access_token&&<OneTapSignin/>} */}
        {/* <PopupIndex/> */}
        {(auth?.userData?.user?.is_premium === false ||
          auth?.userData?.user?.is_premium === undefined) && <EventPopup />}
        <div style={{ zIndex: 999 }} className="p-fixed w-100">
          {/* when you are adding banner , add banner height in bannerOffsetCalc file  */}
          {!isLoginPage &&
            (auth?.userData?.user?.is_premium === false ||
              auth?.userData?.user?.is_premium === undefined) && (
              <EventTopBanner />
            )}
          {!isLoginPage && <Ticker lightMode={lightMode} />}
          {!isLoginPage && (
            <NavBar
              MobileSearchDrop={MobileSearchDrop}
              setMobileSearchDrop={setMobileSearchDrop}
            />
          )}
        </div>
        <Routes>
          <Route
            path="/"
            element={
              auth?.userData?.access_token ? (
                <DashboardIndex setisLoginPage={setisLoginPage} />
              ) : (
                <HomeIndex setisLoginPage={setisLoginPage} />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              <AuthRouter>
                <DashboardIndex setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route path="/privacy" element={<Policys />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/terms-and-conditions" element={<Terms />} />

          <Route path="/refund-policy" element={<Refund />} />

          <Route
            path="signup"
            state={"signup"}
            element={
              <AuthRouter afterLogin={true}>
                <LoginAndSignUps setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route
            path="confirm_password"
            state={"confirm_password"}
            element={
              <AuthRouter afterLogin={true}>
                <LoginAndSignUps setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route
            path="verify-email/:email/:id"
            state={"verify-email"}
            element={
              <AuthRouter afterLogin={true}>
                <AccountActive setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route
            path="verify-email/:email/:id/:status"
            state={"verify-email"}
            element={
              <AuthRouter afterLogin={true}>
                <AccountActive setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route path="/onetap/callback/" element={<GoogleRedirect />} />
          <Route
            path="stock/:symbol/:companyType"
            element={<StockDetailsIndex />}
          />
          <Route
            path=":type/:symbol/shareholdings/details"
            element={<ShareHoldingIndex />}
          />
          <Route
            path=":type/:symbol/:keyName/all"
            element={<ViewAllCorpAction />}
          />
          <Route path="index/:indexname/" element={<IndexDetails />} />
          <Route
            path="index/:indexname/heatmap"
            element={<ViewAllHeatmap setisLoginPage={setisLoginPage} />}
          />

          {/* <Route  path="test-chart" element={<ChartIndex />} /> */}
          <Route path="test-chart" element={<ChartIndex />} />
          <Route
            path="watchlist"
            element={
              <AuthRouter redirState="watchlist">
                <WatchlistIndex
                  setMobileSearchDrop={setMobileSearchDrop}
                  MobileSearchDrop={MobileSearchDrop}
                />
              </AuthRouter>
            }
          />
          <Route
            path="login"
            element={
              // <AuthRouter afterLogin={true}>
              <LoginAndSignUps setisLoginPage={setisLoginPage} />
              // </AuthRouter>
            }
            shouldRevalidate={({ currentUrl }) => {
              // only revalidate if the submission originates from
              // the `/meal-plans/new` route.
              currentUrl.pathname === "login" && setisLoginPage(true);
            }}
          />
          <Route
            path="buckets"
            element={
              <AuthRouter redirState="buckets">
                <BucketIndex />
              </AuthRouter>
            }
          />
          <Route
            path="buckets/:bucketname"
            element={
              <AuthRouter>
                <BucketDetailsIndex />
              </AuthRouter>
            }
          />
          <Route
            path="portfolio/:id"
            element={
              <AuthRouter>
                <PremiumRouter>
                  <PortfolioAnalysis />
                </PremiumRouter>
              </AuthRouter>
            }
          />
          <Route
            path="portfolio/:id/AllTradeRecords/"
            element={
              <AuthRouter>
                <AllTradeRecords />
              </AuthRouter>
            }
          />

          <Route
            path="bucket/:category"
            element={
              <AuthRouter>
                <ViewAllBuckets />
              </AuthRouter>
            }
          />
          <Route
            path="stockCompare"
            element={
              <AuthRouter redirState="stockCompare">
                <StocksCompare />
              </AuthRouter>
            }
          />
          <Route
            path="screener"
            element={
              <AuthRouter redirState="screener">
                <ScreenerIndex />
              </AuthRouter>
            }
          />
          <Route
            path="screener/query/:query"
            element={<CustomScreenerIndex />}
          />
          <Route
            path="superstars"
            element={
              <AuthRouter redirState="superstars">
                <PortfolioIndex />
              </AuthRouter>
            }
          />
          <Route
            path="superstars/:name"
            element={
              <AuthRouter>
                <StarDetailsIndex />
              </AuthRouter>
            }
          />
          <Route
            path="marketstats/gainers"
            element={<GainersIndex setisLoginPage={setisLoginPage} />}
          />
          <Route
            path="marketstats/losers"
            element={<LosersIndex setisLoginPage={setisLoginPage} />}
          />
          <Route
            path="portfolio/AllTradeRecords"
            element={
              <AuthRouter>
                <AllTradeRecords />
              </AuthRouter>
            }
          />
          <Route
            path="portfolio"
            element={
              <AuthRouter redirState="portfolio">
                <PremiumRouter>
                  <PortfolioAnalysis />
                </PremiumRouter>
              </AuthRouter>
            }></Route>
          <Route
            path="portfolio-backtesting"
            element={
              <AuthRouter redirState="portfolio-backtesting">
                <PremiumRouter>
                  <BacktestingIndex />
                </PremiumRouter>
              </AuthRouter>
            }></Route>
          <Route path="getpremium" element={<PlanPage />}></Route>
          <Route
            path="getpremium/summary/:id"
            element={
              <AuthRouter redirState="summary">
                <Plansummery />
              </AuthRouter>
            }
          />
          <Route
            path="news"
            element={<MarketNews setisLoginPage={setisLoginPage} />}
          />
          <Route path="news/:category" element={<NewsCategorys />} />

          {/* all stocks page  */}
          <Route
            path="stock/industry"
            element={<AllStocksIndex setisLoginPage={setisLoginPage} />}>
            <Route path=":sector" element={<SectorTable />} />
          </Route>
          <Route
            path="profile"
            element={
              <AuthRouter>
                <ProfileIndex setisLoginPage={setisLoginPage} />
              </AuthRouter>
            }
          />
          <Route
            path="marketstats"
            element={<MarketReturnsIndex setisLoginPage={setisLoginPage} />}
          />
          <Route path="contactus" element={<ContactIndex />} />
          <Route path="contact" element={<ContactIndex />} />
          <Route path="paymentstatus" element={<PaymentStatus />} />
          <Route path="paymentstatus-offer" element={<PaymentOfferStatus />} />
          <Route path="upcoming" element={<UnderDevPage />} />
          <Route path="*" element={<Error404 />} />
          {/* affiliate  */}
          <Route path="become-affiliate" element={<AffiliateHome />} />
          <Route
            path="affiliate-register"
            element={
              <AuthRouter>
                <AffiliateRegisterIndex />
              </AuthRouter>
            }
          />
          <Route path="affiliate-status" element={<AffiliateStatus />} />

          {/* affiliate  */}
          <Route
            path="affiliate/"
            element={
              <Suspense fallback={<DotLoader />}>
                <AffiliateRouter>
                  <AffliateDash setisLoginPage={setisLoginPage} />
                </AffiliateRouter>
              </Suspense>
            }>
            <Route
              path="sales"
              element={<SalesIndex setisLoginPage={setisLoginPage} />}></Route>
            <Route
              path="payoutdetails"
              element={
                <AffiliatePayout setisLoginPage={setisLoginPage} />
              }></Route>
            <Route
              path="dashboard"
              element={
                <Affiliatedashboard setisLoginPage={setisLoginPage} />
              }></Route>

            <Route
              path="signups"
              element={
                <AffiliateSign setisLoginPage={setisLoginPage} />
              }></Route>
            <Route
              path="promotions"
              element={
                <AffliatePromotion setisLoginPage={setisLoginPage} />
              }></Route>
            <Route
              path="helpcenter"
              element={
                <AffiliateHelp setisLoginPage={setisLoginPage} />
              }></Route>
            <Route
              path="terms&conditions"
              element={
                <AffiliateTermsAndCondition setisLoginPage={setisLoginPage} />
              }></Route>
            <Route
              path="user-data"
              element={
                <AffiliateUpdatebank setisLoginPage={setisLoginPage} />
              }></Route>
          </Route>

          {/* Calculator */}
          <Route path="calculator/" element={<Calculator />}></Route>
          <Route
            path="calculator/return-on-Equity"
            element={<ReturnOnEquityCalc />}></Route>
          <Route
            path="calculator/discount-cash-flow"
            element={<DiscountedCashFlowCalc />}></Route>
          <Route
            path="calculator/price-earning"
            element={<PriceEarningsCalc />}></Route>
          <Route
            path="calculator/dhando-valuation"
            element={<DhandoValuation />}></Route>
          <Route
            path="calculator/stock-average"
            element={<StockAverageCalc />}></Route>
          <Route
            path="calculator/Advanced-graham-number"
            element={<AdvanceGrahamcalc />}></Route>
          <Route
            path="calculator/graham-number"
            element={<GrahamNumberCalc />}></Route>
          <Route
            path="calculator/diviend-discount"
            element={<DividendDiscountCalc />}></Route>
          <Route
            path="calculator/stock-profit"
            element={<StockProfitCalc />}></Route>
          <Route
            path="calculator/systematic-investment"
            element={<SystematicInvestment />}></Route>
          <Route
            path="calculator/one-time-value"
            element={<OneTimeValueCalc />}></Route>
          <Route
            path="calculator/brokerage-calculator"
            element={<BrokerageCalculator />}></Route>

          {/* Admin */}

          {auth?.userData?.user?.accountType === "admin" && (
            <Route
              path="admin/"
              element={
                <Suspense fallback={<DotLoader />}>
                  <AdminRouterAuth>
                    <AdminDash setisLoginPage={setisLoginPage} />
                  </AdminRouterAuth>
                </Suspense>
              }>
              <Route
                path="PremiumSupport"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminPremiumFeedback setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="PremiumSupport/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdaminPremiumDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="userlist"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminUserList setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="Bucket"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminBuckets setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/AllAffiliates/users/:name/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminaffiliatesUsersSignup
                      setisLoginPage={setisLoginPage}
                    />
                  </Suspense>
                }></Route>
              <Route
                path="Bucket/:name/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminBucketDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="Bucket/create"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminBucketDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>

              <Route
                path="affiliate/Approvals/:stage"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAffiliateApproval setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/Approvals/:stage/:type/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminApprovalDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminDashboard setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="dashboard/affilate-signups"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminSignupCount setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/AllSignUps"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAllSignup setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/AllAffiliates"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAllAffiliate setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>

              <Route
                path="affiliate/AllAffiliates/:name/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAllAffiliateDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/SalesPerformance"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAffiliateSalesPerforms
                      setisLoginPage={setisLoginPage}
                    />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/PayoutDetails"
                element={
                  <AdminAffiliatePayout setisLoginPage={setisLoginPage} />
                }></Route>
              <Route
                path="affiliate/Report"
                element={
                  <AdminAffiliateReports setisLoginPage={setisLoginPage} />
                }></Route>
              <Route
                path="affiliate/Promotions"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAffiliatePromotion setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="Transactions"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <TransationDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="feedback"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <Feedback setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="screener"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <Screener setisLoginPage={setisLoginPage} />
                  </Suspense>
                }
              ></Route>
              
              <Route
                path="notification"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminNotification setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockdetails"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <StockDetailMain setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockdetails/create"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AddStockDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockdetails/edit/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <EditStockDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="Coupons"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminCoupons setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="star_portfolio"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminPortfolioIndex setisLoginPage={setisLoginPage} />z
                  </Suspense>
                }></Route>
              <Route
                path="star_portfolio/create"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <CreatePortfolio setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="star_portfolio/edit/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <Editportfolio setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
                 <Route
                path="star_portfolio_new"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminPortfolioIndexNew setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="star_portfolio_new/create"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <CreatePortfolioNew setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="star_portfolio_new/edit/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <EditportfolioNew setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/HelpCenter"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminAffiliateHelpCenterr
                      setisLoginPage={setisLoginPage}
                    />
                  </Suspense>
                }></Route>
              <Route
                path="affiliate/HelpCenter/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdaminHelpCenterDetails setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockRecommendation"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <AdminStockRecommendation setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockRecommendation/save"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <SaveStockRecommendation setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="stockRecommendation/edit/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <EditStockRecommendation setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="user-management"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <UserManagment setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
              <Route
                path="user-management/edit/:id"
                element={
                  <Suspense fallback={<DotLoader />}>
                    <EditUserManagement setisLoginPage={setisLoginPage} />
                  </Suspense>
                }></Route>
            </Route>
          )}
          <Route
            path="premium-support"
            element={
              <PremiumRouter>
                <PremiumSupportIndex />
              </PremiumRouter>
            }
          />
          <Route
            path="joinfingrad"
            element={
              <PremiumRouter>
                <JoinFingradIndex />
              </PremiumRouter>
            }
          />
          <Route path="new-updates" element={<NewUpdates />} />
          {/* affiliate  */}
          {/* <Route path="portfolio-backtesting/" element={<UnderDevPage />} /> */}
          <Route path="ipo/" element={<UnderDevPage />} />
          <Route
            path="marketstats/historicalReturns"
            element={<UnderDevPage />}
          />
          <Route path="corporateactions" element={<CorporateActions />} />
          <Route path="quizzes/" element={<UnderDevPage />} />
          <Route path="knowledgeCenter/home/" element={<UnderDevPage />} />
        </Routes>
        {!isLoginPage && <Footer lightMode={lightMode} />}
      </ScrollToTop>
      <BackTop style={{ zIndex: 1000 }}>
        <CaretUpOutlined style={{ fontSize: "20px" }} />
      </BackTop>
    </BrowserRouter>
  );
}

export default Routers;
