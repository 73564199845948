export const ReturnsTableData = [
  {
    key: "month1",
    name: "1 Month",
    unit: "%",
    colorable: true,
  },
  {
    key: "month6",
    name: "6 Months",
    unit: "%",
    colorable: true,
  },
  {
    key: "year1",
    name: "1 Year",
    unit: "%",
    colorable: true,
  },
  {
    key: "year3",
    name: "3 Years ",
    unit: "%",
    colorable: true,
  },
  {
    key: "year5",
    name: "5 Years",
    unit: "%",
    colorable: true,
  },
];

export const CompareKeyMetrics = [
  {
    key: "mcap",
    name: "Market Cap (Cr)",
    Rupee: "₹",
    covert: "Cr",
  },
  {
    key: "pe",
    name: "PE Ratio",
  },
  {
    key: "price_to_book_value",
    name: "PB Ratio",
  },
  {
    key: "dividend_per_share",
    name: "Dividend Yield ",
    unit: "%",
  },
  {
    key: "roe",
    name: "ROE",
    unit: "%",
  },
  {
    key: "roce",
    name: "ROCE",
    unit: "%",
  },
  {
    key: "current_ratio",
    name: "Current Ratio",
  },
  // {
  //   key: "debt_to_equity",
  //   name: "Debt to Equity Ratio",
  // },
  {
    key: "price_to_sales_ratio",
    name: "Net Profit Margin(NPM)",
    unit: "%",

  },
];

export const CompareKeyMetricsBanking = [
  {
    key: "mcap",
    name: "Market Cap",
    Rupee: "₹",
    covert: "Cr",
  },
  {
    key: "pe",
    name: "PE Ratio",
  },
  {
    key: "price_to_book_value",
    name: "PB Ratio",
  },
  {
    key: "dividend_per_share",
    name: "Dividend Yield ",
    unit: "%",
  },
  {
    key: "roe",
    name: "ROE",
    unit: "%",
  },
  {
    key: "roce",
    name: "ROCE",
    unit: "%",
  },
  {
    key: "net_interest_margin",
    name: "Net Interest Margin",
    unit: "%",
  },
  {
    key: "net_npa",
    name: "Net NPA Ratio",
  },
  {
    key: "price_to_sales_ratio",
    name: "Net Profit Margin(NPM)",
    unit: "%",

  },
];

export const comparebankProfitAndLoss = [
  {
    key: "total_income",
    name: "Total Income -",
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "intrest_earned",
    name: "Interest Earned",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "other_income",
    name: "Other Income",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "intrest",
    name: "Interest Expended",
    Rupee: "₹",
  },
  {
    key: "operating_expenditure",
    name: "Operating Expenses",
    Rupee: "₹",
  },
    {
    key: "operating_profit",
    name: "Operating Profit",
    Rupee: "₹",
  },
  {
    key: "total_provisions",
    name: "Total Provisions",
    Rupee: "₹",
  },
  {
    key: "ppop",
    name: "PPOP",
    Rupee: "₹",
  },
  {
    key: "profit_bf_tax",
    name: "Profit Before Tax",
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "tax",
    name: "Tax",
    Rupee: "₹",
  },
  {
    key: "profit_after_tax",
    name: "Profit after tax",
    highlight: true,
    Rupee: "₹",
  },
  // {
  //   key: "consolidated_profit",
  //   name: " Consolidated Profit",
  //   highlight: true,
  //   Rupee: "₹",
  // },
 
  // {
  //   key: "net_profit",
  //   name: "Net Profit",
  //   highlight: true,
  //   Rupee: "₹",
  // },
  {
    key: "adjustments",
    name: "Adjustments",
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "adjs_eps",
    name: "Adj. EPS",
    Rupee: "₹",
  },
];

export const compareProfitAndLoss = [
  {
    key: "net_slaes",
    name: "Net Sales",
    Rupee: "₹",
    highLighted: true,
  },
  {
    key: "total_expenditure",
    name: "Total Expenditure",
    Rupee: "₹",
  },
  {
    key: "raw_materials",
    name: "Raw Materials",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "employees",
    name: "Employees  ",
    Rupee: "₹",
  },
  {
    key: "selling_expns",
    name: "Selling, Administration Expenses",
    Rupee: "₹",
  },
  {
    key: "power_and_fule_cost",
    name: "Power and Fuel Cost",
    Rupee: "₹",
  },
  {
    key: "operating_profit",
    name: "Operating Profit",
    highLighted: true,
    Rupee: "₹",
  },
  {
    key: "other_income",
    Rupee: "₹",
    name: "Other Income",
  },
  {
    key: "depreciation",
    Rupee: "₹",
    name: "Depreciation & Amortization  ",
  },
  {
    key: "intrest",
    Rupee: "₹",
    name: "Interest",
  },
  {
    key: "profit_bf_tax",
    Rupee: "₹",
    name: "Profit Before Tax",
    highLighted: true,
  },
  {
    key: "tax",
    name: "Tax",
    Rupee: "₹",
  },
  {
    key: "profit_after_tax",
    name: "Profit After Tax",
    Rupee: "₹",
    highLighted: true,
  },
  {
    key: "adjustments",
    name: "Adjustments",
    Rupee: "₹",
  },
  // {
  //   key: "net_profit",
  //   name: "Net Profit",
  //   Rupee: "₹",
  //   highLighted: true,
  // },
  {
    key: "adjs_eps",
    Rupee: "₹",
    name: "Adj. EPS",
  },
];
export const CompareBalanceSheetdata = [
  {
    key: "assets",
    name: "Assets",
    isHeaderWithData: true,
    Rupee: "₹",
  },
  {
    key: "current_assets",
    name: "Current Assets +",
    isNested: true,
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "inventory",
    name: "Inventory",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "debtors",
    name: "Accounts (Receivable)",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "current_investments",
    name: "Short Term Investments",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "cash_and_bank_balances",
    name: "Cash & Bank Balances",
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "non_current_assets",
    name: "Non Current Assets +",
    isNested: true,
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "net_block",
    name: "Net Block",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "cwip",
    name: "Capital Work in Progress",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "ass_dev",
    name: "Intangible assets under dev",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "long_term_inv",
    name: "Long Term Investments",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "liabilities_ins",
    name: "Liabilities & Equity",
    isHeaderWithData: true,
    Rupee: "₹",
  },
  {
    key: "current_liabilities",
    name: "Current Liabilities +",
    isNested: true,
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "account_payables",
    name: "Account Payables",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "provisions",
    name: "Provisions",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "short_term_loans",
    name: "Short Term Loans",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "non_current_liabilities",
    name: `Non Current Liabilities +`,
    isNested: true,
    highlight: true,
    Rupee: "₹",
  },
  {
    key: "secured_loans",
    name: "Secured Loans",
    isNested2x: true,
    // highlight: true,
    Rupee: "₹",
  },
  {
    key: "non_secured_loans",
    name: "Non Secured Loans",
    isNested2x: true,
    // highlight: true,
    Rupee: "₹",
  },
  {
    key: "share_holders_fund",
    name: "Shareholder's Funds ",
    highlight: true,
    isNested: true,
    Rupee: "₹",
  },
  {
    key: "share_capital",
    name: "Share Capital",
    isNested2x: true,
    Rupee: "₹",
  },
  {
    key: "reserve",
    name: "Reserves",
    isNested2x: true,
    Rupee: "₹",
  },
];
export const BankCompareBalanceSheetdata = [
 
    {
      key: "total_assets",
      name: "Assets ",
      highlight: true,
      Rupee: "₹",
    },
    {
      key: "cash_balance_with_rbi",
      name: "Cash Balance with RBI",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "balance_with_bank",
      name: "Balance with bank",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "investments",
      name: "Investments",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "advances",
      name: "Advances",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "net_block",
      name: "Net Block",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "other_assets",
      name: "Other Assets",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "total_liabilities",
      name: "Liabilities & Equity -",
      highlight: true,
      Rupee: "₹",
    },
    {
      key: "deposits",
      name: "Deposits",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "borrowings",
      name: "Borrowings",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "other_liabilities",
      name: "Other Liabilities",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "share_holders_fund",
      name: "Shareholder's Funds-",
      isNested: true,
      Rupee: "₹",
    },
    {
      key: "share_capital",
      name: "Share Capital",
      isNested2x: true,
      Rupee: "₹",
    },
    {
      key: "reserves",
      name: "Reserves",
      isNested2x: true,
      Rupee: "₹",
    },
  
];

export const compareCashflow = [
  {
    key: "inves_cash_flow",
    name: "Cashflow From Investing Activities",
    Rupee: "₹",
    // covert:"Cr"
  },
  {
    key: "opr_cash_flow",
    Rupee: "₹",
    name: "Cashflow From Operating Activities  ",
    // covert:"Cr"
  },

  {
    key: "fins_cash_flow",
    Rupee: "₹",
    name: "Cashflow From Financial Activities",
    // covert:"Cr"
  },
  {
    key: "net_cash_flow",
    Rupee: "₹",
    name: "Net Cash Flow",
    // covert:"Cr"
  },
  {
    key: "free_cash_flow",
    Rupee: "₹",
    name: "Free Cash Flow",
    // covert:"Cr"
  },
  {
    key: "opr_cash_flow",
    Rupee: "₹",
    name: "Operating Profit",
    // covert:"Cr"
  },
];

export const QuarterlyStockComapreData = [
  {
    key: "net_sales",
    name: "Net Sales",
    Rupee: "₹",
    highLighted: true,
    
  },
  {
    key: "operating_expenses",
    Rupee: "₹",
    name: "Operating Expense  ",
  },

  {
    key: "operating_profit",
    Rupee: "₹",
    name: "Operating Profit",
    highLighted: true,
  },
  {
    key: "other_income",
    Rupee: "₹",
    name: "Other Income",
  },
  {
    key: "depereciation",
    Rupee: "₹",
    name: "Depreciation",
  },
  {
    key: "interest",
    Rupee: "₹",
    name: "Interest",
  },
  {
    key: "profit_before_tax",
    Rupee: "₹",
    name: "Profit Before Tax",
    highLighted: true,
  },
  {
    key: "tax",
    Rupee: "₹",
    name: "Tax",
  },
  {
    key: "profit_after_tax",
    Rupee: "₹",
    name: "Profit After Tax",
    highLighted: true,
  },
  // {
  //   key: "net_profit",
  //   Rupee: "₹",
  //   name: "Net Profit",
  //   highLighted: true,
  // },
  {
    key: "adj_eps",
    Rupee: "₹",
    name: "Adj. EPS",
  },
];

export const QuarterlyStockComapreDataIsBanking = [
  {
    key: "total_income",
    name: "Total Income",
    Rupee: "₹",
    highLighted: true,
  },
  {
    key: "interest_expended",
    Rupee: "₹",
    name: "Interest Expended  ",
  },

  {
    key: "operating_expenses",
    Rupee: "₹",
    name: "Operating Expenses",
  },
  {
    key: "provisions",
    highLighted: true,
    Rupee: "₹",
    name: "Provisions",
  },
  {
    key: "profit_before_tax",
    highLighted: true,
    Rupee: "₹",
    name: "Profit Before Tax",
  },
  {
    key: "tax",
    Rupee: "₹",
    name: "Tax",
  },
  {
    key: "profit_after_tax",
    highLighted: true,
    Rupee: "₹",
    name: "Profit After Tax",
  },
 
  {
    key: "adj_eps",
    Rupee: "₹",
    name: "Adj EPS",
  },
];

export const FactSheetStockComapreDataBanking = [
  {
    key: "Profitability Ratios",
    name: "Operational Ratios",
    isHeader: true,
  },
  {
    key: "gross_npa",
    name: "Gross NPA",
  },
  {
    key: "net_npa",
    name: "Net NPA  ",
  },
  {
    key: "casa",
    name: "Casa Ratio",
  },
  {
    key: "adv_growth",
    name: "Advance Growth",
  },{
    key: "Profitability Ratios",
    name: "Profitability Ratios",
    isHeader: true,
  },
  {
    key: "net_profit_margin",
    unit: "%",
    name: "Net Profit Margin",
  },
  {
    key: "net_intrest_margin",
    unit: "%",

    name: "Net Interest Margin",
  },
  {
    key: "credit_deposit_ratio",
    
    name: "Credit Deposit Ratio",
  },
  
  {
    key: "return_on_equity",
    unit: "%",
    name: "Return On Equity",
  },
  {
    key: "return_on_assets",
    unit: "%",
    name: "Return On Assets",
  },
  
  // {
  //   key: "current_liabilities",
  //   Rupee: "₹",
  //   name: "Current Liabilities",
  // },
  {
    key: "eps",
    Rupee: "₹",
    name: "EPS",
  },
  // {
  //   key: "provisions",
  //   Rupee: "₹",
  //   name: "Provisions",
  // },
  {
    key: "adj_eps",
    Rupee: "₹",
    name: "Adj",
  },
  {
    key: "head",

    name: "Valuation Ratios",
    isHeader:true
  },
  {
    key: "pe_ratio",
    name: "PE Ratio",
  },
  {
    key: "price_to_book",
    name: "Price To Book Value",
  },
  {
    key: "dividend_yield",
    unit: "%",
    name: "Dividend Yield",
  },
  {
    key: "Dividend & Other Metrics",
    name: "Dividend & Other Metrics",
    isHeader:true
  },
  {
    key: "ev",
    name: "Enterprise Value",
  },
  {
    key: "dividend_per_share",
    Rupee: "₹",
    name: "Dividend Per Share",
  },
  {
    key: "adj_dividend_per_share",
    name: "Adj Dividend Per Share",
    Rupee: "₹",
  },
  {
    key: "dividend_payout_ratio",
    unit: "%",
    name: "Dividend Payout Ratio",
  },
];

export const FactSheetStockComapreData = [
  {
    // key: "head",
    name: "Profitability Ratios",
    isHeader: true,
  },
  {
    key: "net_profit_margin",
    name: "Net Profit Margin",
    unit:"%",
  },

  {
    key: "ebitda_margin",
    unit:"%",
    name: "EBITDA Margin",
  },

  {
    key: "roe", 
    unit:"%",
    name: "Return On Equity (ROE)",
  },
  {
    key: "roce",
    unit:"%",
    name: "Return On Cap Employed (ROCE)",
  },
  {
    key: "roa",
    unit:"%",
    name: "Return On Assets (ROA)",
  },
  {
    key: "eps",
    Rupee: "₹",
    name: "Earnings Per Share (EPS)",
  },
  {
    key: "adj_eps",
    Rupee: "₹",
    name: "Adj. Earnings Per Share",
  },{
    key: "Valuation Ratios",
    name: "Valuation Ratios",
    isHeader: true,
  },
  {
    key: "ev_evitda",
    name: "EV/EBITDA",
  },
  {
    key: "yield_fact",
    unit:"%",
    name: "Dividend Yield",
  },
  {
    key: "pe_ratio",
    name: "PE Ratio",
  },
  {
    key: "price_to_book_value",
    name: "Price To Book Value",
  },
  {
    key: "price_to_sales_ratio",
    name: "Price/Sales Ratio",
  },
  {
    key: "mcap_sales",
    name: "Market Cap To Sales",
  },
  
  {
    key: "price_to_cash_flow",
    name: "Price To Cashflow",
  }, {
    key: "Debt and Liquidity Ratios",
    name: "Debt and Liquidity Ratios",
    isHeader: true,
  },
  {
    key: "current_ratio",
    name: "Current Ratio",
  },
  {
    key: "interest_coverage_ratio",
    name: "Interest Coverage Ratio",
  },
  {
    key: "debt_to_equity",
    name: "Debt To Equity Ratio",
    
  },
  {
    key: "total_debt_mcap",
    name: "Total Debt To Market Cap",
  },
  {
    key: "asset_turnover_ratio",
    name: "Asset Turnover Ratio",
  },
  {
    key: "inventory_turnover",
    name: "Inventory Turnover Ratio",
  },
  {
    key: "receivable_days",
    name: "Receivable Days (DSO)",
  },
  {
    key: "inventory_days",
    name: "Inventory Days (DIO)",
  },
  {
    key: "payable_days",
    name: "Payable Days (DPO)",
  },
  {
    key: "",
    name: "Cash conversion cycle",
  },
  {
    key: "",
    name: "Dividend & Other Metrics",
    isHeader:true
  },
  {
    key: "ev",
    name: "Enterprise Value",
  },
  
  {
    key: "div_per_share",
    name: "Dividend Per Share",
  },
  {
    key: "adj_div_per_share",
    Rupee: "₹",
    name: "Adj Dividend Per Share",
  },
  {
    key: "dividend_payout",
    unit:"%",
    name: "Dividend Payout Ratio",
  },
  {
    key: "free_cash_flow_per_share",
    Rupee: "₹",
    name: "Free Cash Flow Per Share",
  },
  
];

export const ShareHoldingComapreData = [
  {
    key: "promoters",
    name: "Promoters",
    
    unit: "%",

  },
  {
    key: "share_holding_pledge",
    name: "Shareholding Pledge",
    unit: "%",

  },

  {
    key: "public",
    name: "Public",
    unit: "%",

  },

  {
    key: "fii",

    name: "FII",
    unit: "%",

  },
  {
    key: "total_dii",
    name: "Total DII +",
    unit: "%",

  },
  {
    key: "mf",
    name: "MF",
    unit: "%",
    subTable:true
  },
  {
    key: "insurance",
    name: "Insurances",
    unit: "%",
    subTable:true

  },
  {
    key: "fin_inst",
    name: "Fin inst & other DII's",
    unit: "%",
    subTable:true

  },
  {
    key: "others",
    name: "Others",
    unit: "%",

  },
];

// insurance

export const ProfitAndLossINS = [
  {
    key: "revenue_from_operations",
    name: "Revenue From Operations ",
    highlight: true,
    Rupee:"₹"
    
  //   tooltipText:'This refers to the total money made by a company through the sale of goods and services for a given period. Sometimes, net sales and revenue are used interchangeably.'
  },
  {
    key: "income_from_investments",
    name: "Income From Investments ",
    Rupee:"₹"

  //   tooltipText:'Operating expenditure often called opex, is the expenditure that a company incurs for its daily operations. Ex: rent, wages, office supplies and so on.'
  },
  {
    key: "other_income",
    name: "Other Income",
    isNested: true,
    Rupee:"₹"
    
  //   tooltipText:'Materials used in primary production of goods. '
  },
  {
    key: "expenses",
    name: "Expenses",
    isNested: true,
    Rupee:"₹"

  //   tooltipText:'Salaries spent on workforce.'
  },
  {
    key: "provisions",
    name: "Provisions",
    isNested: true,
    Rupee:"₹"
    
  //   tooltipText:'All everyday operation expenses of running a business excluding production and delivery.'
  },
  {
      key: "profit_bf_tax",
      name: "Profit Before Tax",
      highlight: true,
    Rupee:"₹",
      tooltipText:'Profits made by the company before they have to pay corporate income tax.'
    },
    {
      key: "tax",
      name: "Tax",
    Rupee:"₹",
      tooltipText:'Taxes are compulsory contributions paid to the government.'
    },
    {
      key: "adjustments",
      name: "Adjustments",
      Rupee:"₹",
  tooltipText:'Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.',
    },
  {
    key: "net_profit",
    name: "Net Profit",
    highlight: true,
  tooltipText:'The actual profit after working expenses, interest and taxes.',
  Rupee:"₹",

  },
  {
    key: "adjs_eps",
    name: "Adj. EPS",
    Rupee:"₹",
tooltipText:'Adjusted EPS is arrived at after adding or removing components of net income which are non recurring.',
  },
];


export const balanceSheetINS = [
  {
    key: "assets",
    name: "Assets",
    isHeaderWithData: true,
    Rupee:"₹",

    tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
  },
  {
      key: "investments",
      name: "Investments",
    Rupee:"₹",
      
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "share_holders",
      name: "Shareholders",
    Rupee:"₹",
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "policy_holders",
      name: "Policyholders",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "assest_linked_liabilities",
      name: "Assets Linked Liabilities",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "loans",
      name: "Loans",
      isHeaderWithData: true,
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "secured_loans",
      name: "Secured Loans",
      isNested2x: true,
      // highlight: true,
    Rupee:"₹",
    
      tooltipText:'Secured loans are loans for which a company has submitted collateral to the bank. Let us say a company takes a loan of Rs. 1,000 crores from a bank and submits its office building as collateral. Tomorrow, if the company is not able to pay back the debt, the bank will seize the control of the building and sell it to recover its dues.'
    },
    {
      key: "non_secured_loans",
      name: "Unsecured Loans",
      isNested2x: true,
      // highlight: true,
    Rupee:"₹",
    
      tooltipText:'Non-secured loans have no underlying collateral. They are usually given at a higher rate of interest. Non-secured loans are generally provided to strong companies with a sustainable history of paying back debts.'
    },
    {
      key: "net_block",
      name: "Fixed Assets",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
  {
    key: "current_assets",
    name: "Current Assets +",
    isNested: true,
    highLighted: true,
    Rupee:"₹",
  
    tooltipText:'Current assets are the assets which get used by the company within one financial year. They do not have future earning capacity. Common examples of current assets are raw materials, stationery, and cash.'
  },
  {
      key: "cash_and_bank_balances",
      name: "Cash & Bank Balances",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "advance_and_other_assets",
      name: "Advances & Other Assets",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "other_current_assets",
      name: "Other Current Assets",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "liabilities_ins",
      name: "Current Liabilities +",
      isNested: true,
      highLighted: true,
    Rupee:"₹",
    
      tooltipText:'Current liabilities are payment obligations a company plans to pay within a year. Two examples are the amount owed to a supplier for purchasing raw material on credit and pending salary payable to the employees.'
    },
    {
      key: "share_holders_fund",
      name: "Shareholders Funds",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "share_capital",
      name: "Share Capital",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "reserve",
      name: "Reserve and Surplus",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "borrowings",
      name: "Borrowings",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "policy_holders_fund",
      name: "Policyholders Funds",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
    {
      key: "fund_of_appropriation",
      name: "Funds for Appropriation",
    Rupee:"₹",
    
      // tooltipText:'An asset is a resource used by the company to generate income. Assets are reported in the Balance Sheet and can have present or future benefits. Some examples are land, building, and computer.'
    },
  
    // {
    //   key: "current_liabilities",
    //   name: "Current Liabilities +",
    //   isNested: true,
    //   highlight: true,
    
    
    //   tooltipText:'Current liabilities are payment obligations a company plans to pay within a year. Two examples are the amount owed to a supplier for purchasing raw material on credit and pending salary payable to the employees.'
    // },
]; 