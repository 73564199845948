import { message, Popover } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import svgSheet from '../assets/svg/svgSheet';
import CreateWatchListModel from '../pages/watchlist/CreateWatchListModel';
import { authStore } from '../redux/reducers/authSlice';
import { addToWatchlistWithData, getWatchListStore, WatchListFetch } from '../redux/reducers/watchListSlice';

function NavToWatchList({lightMode,symbol,children,setWatchListTrigger,SetOpenModel}) {
    const watchListStore = useSelector(getWatchListStore);
    const [visible, setVisible] = useState(false);
    const [Watchlist, setWatchlist] = useState(Object.keys(watchListStore.watchListData)[0])
    // const [OpenModel,SetOpenModel] = useState(false)
    const auth = useSelector(authStore);
  
    const dispatch = useDispatch()
    const hide = () => {
      setVisible(false);
    };
    const success = () => {
      
        !auth.userData?.access_token ?(
          message.error({ content:"Login To Add To WatchList",className:!lightMode && "darkmodemessge"})
    
       ) :(
         message.success({content:"Added To Watchlist",className:!lightMode && "darkmodemessge"})
       )
    };
    const handleVisibleChange = (newVisible) => {
      setVisible(newVisible);
      setWatchListTrigger(newVisible)
    };
    const addStock = async (symbol) => {
      dispatch(
        addToWatchlistWithData({ listName: Watchlist, stock: symbol })
      ).then((resp) => {
        hide();
        dispatch(WatchListFetch())
        success();
      });
    };
    return (
        <div>
  
      <Popover
      color={lightMode ?"white":"#3B3F4F"}
      className="nameis  ff-poppins"
      overlayClassName='Navtowatchlist'
      placement="rightTop"
      content={
        <div className="ff-poppins">
          <div>
            {Object.keys(watchListStore.watchListData).map((items,i)=>(
              <div key={i} onClick={()=>setWatchListTrigger(true)} className="d-flex  align-items-center mt-5 ">
              <input onChange={(e)=>e.target.checked && setWatchlist(e.target.value)} checked={Watchlist === items && true} className={`${!lightMode  && "text-dark-gray"}`} value={items} type="checkbox"></input>
              <p className={`ml-5 mb-3 pointer ${lightMode ? 'text-dark-gray' :"text-white"}`}>{items}</p>
            </div>
            ))}
            
            
          </div>
          <div onClick={(e)=>{e.stopPropagation();}} className="d-flex mt-15">
            <button className={`mr-5 p-10 btn-blue-border br-3  ${lightMode ? "bg-white text-btn-light " : "bg-dark-gray text-btn-dark"}`} onClick={()=>(SetOpenModel(true),setVisible(false),setWatchListTrigger(false))}>
             
            
              + Create Watchlist{" "}
            </button>
            <button  className={`pointer px-20 mr-0  fw-600 text-white br-3 btn-bg-primary text-white mr-5`} onClick={() =>{ addStock(symbol); SetOpenModel(false); setWatchListTrigger(false)}}>Done</button>
          </div>
        </div>
      }
      title={<p className={`fs-s-16 fw-700 mb-0 pointer ${lightMode ?"text-dark-gray" :"text-white"}`}>Add Stock to</p>}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div onClick={(e)=>e.stopPropagation()} style={{width:'11px',height:'13px'}} className="pointer ">{svgSheet.SaveForLaterFilled}</div>
    </Popover>
    </div>
    )
}

export default NavToWatchList