import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Form, Input, Upload } from "antd";
import React, { useState } from "react";
import AntFormInput from "../../../../components/ant/AntFormInput";
import CustomTable from "../../../../components/CustomTable";
import AddKeywordModal from "./AddKeywordModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function PorrtfolioForm({
  lightMode,
  form,
  formSubmit,
  setkeywordArr,
  keywordArr,
  initValues,
}) {
  const [keywordModal, setkeywordModal] = useState(false);
  const field1 = [
    {
      uid: "-1",
      name: "yyy.png",
      status: "done",
      url: initValues?.image,
      thumbUrl: initValues?.image,
    },
  ];
  const featImgField = [
    {
      uid: "-1",
      name: "yyy.png",
      status: "done",
      url: initValues?.featured_image,
      thumbUrl: initValues?.featured_image,
    },
  ];
  const addKeyword = (event) => {
    event.preventDefault(event);
    setkeywordArr([...keywordArr, { keyword: event.target.keyword.value }]);
    event.target.reset();
    setkeywordModal(false);
  };
  const deleteKeyword = (index) => {
    setkeywordArr((prev) => {
      return prev.filter((item, i) => index !== i);
    });
  };

  const keywordTableColumn = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Keywords</p>,
      dataIndex: "keyword",
      // fixed: i === 0 ? "left" : "",
      width: "20px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Action</p>,
      dataIndex: "",
      // fixed: i === 0 ? "left" : "",
      width: "5px",
      render(text, record, i) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato pointer"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
              onClick={() => deleteKeyword(i)}
            >
              <DeleteOutlined />
            </span>
          ),
        };
      },
    },
  ];

  return (
    // <div style={{ gap: "20px" }} className="d-flex d-flex-row-col w-100">
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={formSubmit}
        className="flex flex-col-row"
        name="admin_portfolio"
        initialValues={initValues}
      >
        <div className="w-100-50">
          <AntFormInput
            label="Name"
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
            ]}
          />
          <Form.Item
            label={
              <div>
                <p className="mb-0">Image</p>
                <p className="mb-0">
                  Image Dimension 1200px*400px less than 50KB
                </p>
              </div>
            }
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select Thumbnail Image",
            //   },
            // ]}
            style={{ margin: "30px 0px" }}
            name="featured_image"
            initialValue={featImgField}
            getValueFromEvent={(e) => e.file}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              defaultFileList={[...featImgField]}
              maxCount={1}
              className="mt-10 darkmode-antdUploader"
            >
              <button
                className="btn-bg-primary p-10 mt-10 fw-500 br-5 text-white"
                icon={<UploadOutlined />}
              >
                Click to upload
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            label={
              <div>
                <p className="mb-0">Thumbnail Image</p>
                <p className="mb-0">Image Dimension 300*300px less than 50KB</p>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please select  Image",
              },
            ]}
            style={{ margin: "30px 0px" }}
            name="image"
            initialValue={field1}
            getValueFromEvent={(e) => e.file}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              defaultFileList={[...field1]}
              maxCount={1}
              className="mt-10 darkmode-antdUploader"
            >
              <button
                className="btn-bg-primary p-10 mt-10 fw-500 br-5 text-white"
                icon={<UploadOutlined />}
              >
                Click to upload
              </button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter Description",
              },
            ]}
            //   style={{ margin: "30px 0px" }}
            name="desc"
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <Input.TextArea
              showCount
              // maxLength={1000}
              type="text"
              rows={8}
              style={{
                height: "150px",
                width: "100%",
                paddingInline: "0px",
              }}
              className={`w-100  auth-form-input  ${
                lightMode
                  ? "bg-gray ant-textarea-light "
                  : "bg-dark-gray  ant-textarea-dark"
              }`}
              placeholder="Type Here"
            />
          </Form.Item>
        </div>
        <div className="w-100-50">
          <Form.Item
            label="Bio"
            rules={[
              {
                required: true,
                message: "Please enter Bio",
              },
            ]}
            //   style={{ margin: "30px 0px" }}
            name="bio"
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <CKEditor
              editor={ClassicEditor}
              data={initValues?.bio ?? ""}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // setekeditor(data);
                form.setFieldsValue({ bio: data });
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            {/* <Input.TextArea
              showCount
              // maxLength={1000}
              type="text"
              rows={8}
              style={{
                height: "150px",
                width: "100%",
                paddingInline: "0px",
              }}
              className={`w-100  auth-form-input  ${
                lightMode
                  ? "bg-gray ant-textarea-light "
                  : "bg-dark-gray  ant-textarea-dark"
              }`}
              placeholder="Type Here"
            /> */}
          </Form.Item>
          <Form.Item
            label={
              <div className="flex  justify-content-between w-100 mb-10">
                <p className="mb-0 fw-500 ">Keyworks</p>
                <p
                  className="mb-0  fw-500 text-blue-grad pointer"
                  onClick={() => setkeywordModal(true)}
                >
                  + Add Keyword
                </p>
              </div>
            }
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please enter keywords",
            //     },
            //   ]}
            //   style={{ margin: "30px 0px" }}
            name="keywords"
            //   initialValue={state?.desc}
            className={` ${
              lightMode ? "light-input-login" : "dark-input-login"
            }`}
          >
            <div
              className={`table-shadow  ${
                lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
              }`}
            >
              <CustomTable
                data={keywordArr}
                columns={keywordTableColumn}
                scrollable={true}
                scrollLimit={400}
              />
            </div>
          </Form.Item>
        </div>
      </Form>
      <AddKeywordModal
        visible={keywordModal}
        setVisible={setkeywordModal}
        lightMode={lightMode}
        onConfirm={addKeyword}
      />
    </>
  );
}

export default PorrtfolioForm;
