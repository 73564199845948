import React from "react";
import BacktestAnalysis from "./BacktestAnalysis";
import BacktestDiversification from "./BacktestDiversification";
import PortfolioGrowthChart from "./PortfolioGrowthChart";
import PortfolioReturns from "./PortfolioReturns";

function ResultIndex({ lightMode }) {
  return (
    <div className="mt-40">
      <BacktestAnalysis lightMode={lightMode} />
      <BacktestDiversification lightMode={lightMode} />
      <PortfolioGrowthChart lightMode={lightMode} />
      <PortfolioReturns lightMode={lightMode} />
    </div>
  );
}

export default ResultIndex;
