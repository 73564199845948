import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import CustomPagination from "../../components/ant/CustomPagination";
import CustomTable from "../../components/CustomTable";
import useGetFetch from "../../hooks/useGetFetch";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";

function SectorTable() {
  const { lightMode } = useSelector(getThemeMode);
  const { sector } = useParams();
  const [activePage, setactivePage] = useState({});
  const [sortState, setsortState] = useState({
    head: "mcap",
    sortOrder: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    setactivePage({ page: 1, perPage: 25 });
  }, [sector]);
  // get stocks data
  const {
    isLoading,
    apiData: sectorStocks,
    serverError,
  } = useGetFetch(
    StockEndpoints.sectorStocks(
      sector,
      activePage.page ?? 1,
      activePage.perPage ?? 25,
      sortState.head,
      sortState.sortOrder
    )
  );

  const tableHeaders = [
    {
      title:
      (
        <SortHeader
        label="Company Name"
        header="company"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "company",
      fixed: "left",
      width: "35px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              <Link to={`/stock/${record?.symbol}/consolidated`}>{text}</Link>
            </div>
          ),
        };
      },
    },
    {
      title:
      (
        <SortHeader
        label="MCap (Cr)"
        header="mcap"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "mcap",
      width: "30px",
      render(text, record) {
        const formatedMcap = (text / 10 ** 7)?.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(Number(formatedMcap))}
            </span>
          ),
        };
      },
    },
    {
      title: 
      (
        <SortHeader
        label="CMP"
        header="close"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "close",
      width: "30px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: 
      (
        <SortHeader
        label="Change"
        header="per_change"
        sortState={sortState}
        setsortState={setsortState}
      />
      ),
      dataIndex: "per_change",
      width: "30px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: redGreenColorPicker(text, lightMode),
              }}
            >
             {text>0&&"+"}{text?.toFixed(2)} %
            </span>
          ),
        };
      },
    },
  ];

  return (
    <>
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          loading={isLoading}
          columns={tableHeaders}
          data={sectorStocks?.results}
          scrollable={true}
          scrollLimit={700}
        />
      </div>
      <CustomPagination
        showQuickJumper={true}
        total={sectorStocks?.count}
        onChange={(num, pageSize) =>
          setactivePage({ page: num, perPage: pageSize })
        }
        lightMode={lightMode}
        pageSize={activePage?.perPage}
        current={activePage?.page}
      />
    </>
  );
}

export default SectorTable;
