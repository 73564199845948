import React from "react";
import StockEndpoints from "../../../api/endPoints";
import useGetFetch from "../../../hooks/useGetFetch";
import MarketMoverTable from "./MarketMoverTable";

function LoserGainerSection({lightMode}) {
  const { isLoading, serverError, apiData } = useGetFetch(
    StockEndpoints.marketMovers('NIFTY')
  );

  return (
    <section className="max-w mx-auto px-15 my-100 w-100">
      <div className="flex flex-col-row">
        <div className="w-100-50">
          <MarketMoverTable tableData={apiData?.gainers} tableHead="Top Gainers" lightMode={lightMode} link={`/marketstats/gainers`} isLoading={isLoading}/>
        </div>
        <div className="w-100-50">
          <MarketMoverTable tableData={apiData?.losers} tableHead="Top Losers" lightMode={lightMode} link={`/marketstats/losers`} isLoading={isLoading}/>
        </div>
      </div>
    </section>
  );
}

export default LoserGainerSection;
