import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../../utilityFn/redGreenColor";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function DiscountedCashFlowCalc() {
  const { lightMode } = useSelector(getThemeMode);
  const [GetRsult, setGetRsult] = useState([]);
  const [fcf, setFcf] = useState();
  const [tc, setTc] = useState();
  const [td, setTd] = useState();
  const [share, setShare] = useState();
  const [gr1, setGr1] = useState();
  const [gr2, setGr2] = useState();
  const [tgr, setTgr] = useState();
  const [dr, setDr] = useState();

  const [tycf, setTycf] = useState(0);
  const [tv, setTv] = useState(0);
  const [tpv, setTpv] = useState(0);
  const [cv, setCv] = useState(0);
  const [intrvalue, setIntrvalue] = useState(0);

  const [list1, setlist1] = useState([]);
  const [list2, setlist2] = useState([]);

  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);
  console.log("GetRsult", GetRsult);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (err === true) {
      setTimeout(() => setErr(false), 3000);
    }
  }, [err]);
  console.log("GetRsult[fcf]",GetRsult && GetRsult["fcf"])

  useEffect(() => {
    if (
      GetRsult && GetRsult["fcf"] !== "" &&
      GetRsult && GetRsult["fcf"] !== undefined &&
      GetRsult && GetRsult["tc"] !== "" &&
      GetRsult && GetRsult["tc"] !== undefined &&
      GetRsult && GetRsult["td"] !== "" &&
      GetRsult && GetRsult["td"] !== undefined &&
      GetRsult && GetRsult["share"] !== "" &&
      GetRsult && GetRsult["share"] !== undefined &&
      GetRsult && GetRsult["gr1"] !== "" &&
      GetRsult && GetRsult["gr1"] !== undefined &&
      GetRsult && GetRsult["gr2"] !== "" &&
      GetRsult && GetRsult["gr2"] !== undefined &&
      GetRsult && GetRsult["tgr"] !== "" &&
      GetRsult && GetRsult["tgr"] !== undefined &&
     GetRsult &&  GetRsult["dr"] !== "" &&
     GetRsult &&  GetRsult["dr"] !== undefined
    ) {

      let fcflist = [];
      let discntFact = [];
      let Pv = [];
      console.log(fcflist, "Fcf");
      console.log(Pv, "Present Value");
      let DcfFcf = Number(GetRsult["fcf"]) * (Number(GetRsult["gr1"]) / 100) + Number(GetRsult["fcf"]);
      fcflist.push(DcfFcf);
      for (let i = 0; i < 4; i++) {
        fcflist.push((fcflist[i] * Number(GetRsult["gr1"])) / 100 + fcflist[i]);
      }
      for (let i = 4; i < 9; i++) {
        fcflist.push(fcflist[i] * (Number(GetRsult["gr2"]) / 100) + fcflist[i]);
      }

      for (let i = 1; i < 11; i++) {
        discntFact.push((1 + Number(GetRsult["dr"]) / 100) ** i);
      }

      for (let i = 0; i < 10; i++) {
        Pv.push(fcflist[i] / discntFact[i]);
      }

      let TotalNvp = Pv.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      });
      let terminalValueCfYear =
        fcflist[fcflist.length - 1] * (GetRsult["tgr"] / 100) + fcflist[fcflist.length - 1];
      let terminalValue =
        terminalValueCfYear / (GetRsult["dr"] / 100 - GetRsult["tgr"] / 100) / (1 + GetRsult["dr"] / 100) ** 10;
      let getDcfTotPvCashFlow = Number(TotalNvp) + Number(terminalValue);
      let getDcfCompanyValue =
        Number(getDcfTotPvCashFlow) + Number(GetRsult["tc"]) - Number(GetRsult["td"]);
      let DcfIntrinsicValue = Number(getDcfCompanyValue) / Number(GetRsult["share"]);

      console.log(terminalValueCfYear, "terminalValue cf year");
      console.log(TotalNvp, "Nvp");
      console.log(terminalValue, "terminalValue");
      console.log(getDcfCompanyValue, "Company Value");
      console.log(getDcfTotPvCashFlow, "TotalPVcash");
      console.log(DcfIntrinsicValue, "IntrinsicValue");
      setTycf(TotalNvp);
      setTv(terminalValue);
      setTpv(getDcfTotPvCashFlow);
      setCv(getDcfCompanyValue);
      setIntrvalue(DcfIntrinsicValue);
      setlist1(fcflist);
      setlist2(Pv);
      setShow(true);
    }
  }, [GetRsult]);



 
 
 
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {},
      },
    },

    maintainAspectRatio: false,
  };
  var data = {
    labels: list1.map((items,i)=>{return  1}),
    datasets: [
      {
        label: "Fcf",
        backgroundColor: "#6DB8FD",
        data:list1,
      },
      {
        label: "pv",
        backgroundColor: "#1774FF",
        data: list2,
      },
     
    ],
  };
const result = list1.map((items,i)=>{
 return { comp_name: i+1,
  Free_cash: items,
  Present_Value: list2[i],}
})

  const ResultColumns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Year </p>,
      dataIndex: "comp_name",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >{text}</div>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Free Cash Flow </p>,
      dataIndex: "Free_cash",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(Number(text).toFixed(2))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Present Value</p>,
      width: "50px",
      dataIndex: "Present_Value",
      render(text, record) {
        const textwithComma = Number(text).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {/* {text >= 0 ? "+" : "-"} */}
              {text > 0
                ? numberWithCommas(Number(textwithComma))
                : numberWithCommas(Math.abs(Number(textwithComma)))}
            </span>
          ),
        };
      },
    },
   
  ];
  const ResultHeder =[{
    "Intrinsic Value ":intrvalue !== Infinity  ? Number(Number(intrvalue).toFixed(2)) :0
  }]

  const resetValues = () =>{
  setGetRsult([])
  setTpv(0)
  setlist1([])
  setTycf(0)
  setCv(0)
  setTv(0)
  setIntrvalue(0)
  }


  const navigate = useNavigate();
  const formlist = [
    { name: "fcf", value: "Free Cash Flow (in Mn/Cr)" },
    { name: "gr1", value: "Growth Rate (1-5 Years)(%)" },
    { name: "tc", value: "Total Cash (in Mn/Cr)" },
    { name: "gr2", value: "Growth Rate (6-10 Years)(%)" },
    { name: "td", value: "Total Debt ( in Mn/Cr)" },
    { name: "tgr", value: "Terminal Growth Rate (%)" },
    { name: "share", value: "Shares Outstanding (in Mn/Cr)" },
    { name: "dr", value: "Discount Rate (%) " },
  ];
  const valueData = [
    { key: "Terminal Year CF", value:` ${(Number(tycf)).toFixed(2).toLocaleString("en-US")}` },
    { key: "Terminal Value", value:tv && tv !== Infinity
      ? tv?.toFixed(2).toLocaleString("en-US")
      : 0},
    { key: "Total PV", value:tpv && tpv !== Infinity
    ? tpv?.toFixed(2).toLocaleString("en-US")
    : 0 },
    { key: "Total Cash", value: GetRsult["tc"] === undefined || GetRsult["tc"] === "" ? (0.0).toFixed(2) : GetRsult["tc"]},
    { key: "Total Debt", value: GetRsult["td"] === undefined || GetRsult["td"] === "" ? (0.0).toFixed(2) : GetRsult["td"]},
    { key: "Compact Value", value:cv && cv !== Infinity
    ? cv?.toFixed(2).toLocaleString("en-US")
    : 0 },
  ];

  const bottomData = [{
    header:"What is Discounted Cash Flow Valuation?",
    body:"It is a valuation method used to estimate the attractiveness of an investment opportunity. DCF analysis uses future free cash flow projections and discounts them, using a required annual rate, to arrive at present value estimates. A present value estimate is then used to evaluate the potential for investment.  FCF = Cash flow from operating activities capital expenditures"
  },{
    header:"Free cash flow (FCF):",
    body:"Free cash flow can be defined as the excess cash that a company is able to generate after spending the money required for its operation or to expand its asset base. It's important for an investor to look into the free cash flow of a company carefully because it is a relatively more accurate method to find the profitability of a company than the company's earnings. Free cash flow of a company can be calculated by using the below formula:    "
  },{
    header:"Growth Rate:",
    body:"It is the expected rate at which the company may grow in the upcoming 5-10 years. It's really important to use a realistic growth rate for efficient calculations. Else, the calculated intrinsic value might be misleading. Different investors use different approaches to find the expected growth rate of a company. Few of the common ways are by looking at the historical growth rate for the earnings/profits, reading the analysts reports to find out what they are forecasting, peeking in the company's annual report/latest news to find out what the management/CEO is saying regarding the company's growth rate in upcoming years etc."
  },{
    header:"Discount rate:",
    body:"The discount rate is usually calculated by CAPM (Capital asset pricing). However, you can also use the discount rate as the rate of return that they want to earn from the stock. For example, let's say that you want an annual return rate of 12%, then you can use it as the discount rate. As a thumb rule for the discount rate, use a higher value if the stock is riskier and a lower discount rate if the stock is safer (like blue chips). This rule is in accordance with the principle of the risk-reward which claims a higher reward for a higher risk."
  },{
    header:"Terminal Growth Rate:",
    body:"The terminal growth rate represents an assumption that the company will continue to grow or decline at a steady, constant rate into perpetuity. Typically, perpetuity growth rates range between the historical inflation rate of 2-3% and the historical GDP growth rate of 4 - 5%. If the perpetuity growth rate exceeds 5%, it is basically assumed that the company's expected growth will outpace the economy's growth forever. This rule is in accordance with the principle of the risk-reward which claims a higher reward for a higher risk."
  }]

  return (
    <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
        <p className="pointer" onClick={() => navigate(-1)}>
          Calculators / Discounted Cash Flow Calculation
        </p>
        <p className="mb-0 fs-30-20 fw-700">Discounted Cash Flow Calculation</p>
        <p className="mb-0 w-60-80">
          (DCF) is a method of valuing a company using the concepts of the time
          value of money. All future cash flows are estimated and discounted to
          give their present values.
        </p>
      </div>
      <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
        <div>
          <CalcInputFeilds
            setGetRsult={setGetRsult}
            lightMode={lightMode}
            formlist={formlist}
            resetValues={resetValues}
          />
        </div>
        <div>
          <ResultContainerCalc
            tableResult={result}  
            tabelColumn={ResultColumns}
            resultLise={valueData}
            chartOptions={options}
            chartData={data}
            ResultHeder={ResultHeder}
            lightMode={lightMode}
          />
        </div>
      </div>
      <div>
        <BottomDataSection bottomData={bottomData} />
      </div>
    </div>
  );
}

export default DiscountedCashFlowCalc;
