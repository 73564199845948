import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAffiliatePromotion } from '../../../../../api/fetchClient';
import { getThemeMode } from '../../../../../redux/reducers/ThemeSlice';
import PromotionCard from './AffiliateComponets/PromotionCard';

function AffliatePromotion() {
  const { lightMode } = useSelector(getThemeMode);
const [Promotion, setPromotion] = useState()
  useEffect(() => {
    getAffiliatePromotion().then((Res)=>{
      setPromotion(Res?.data)
    })
  }, [])
  

  return (
    <div className="mt-30 px-30-0">
    {/* <p className='fs-s-20 fw-700 mb-0 '>Promotion</p> */}
    <div className='d-flex d-flex-row-col align-center-start justify-content-between'>
    <p className='fs-s-20 fw-500'>All Banners for promotions are given below.</p>
    <p className={`'fs-s-14  d-flex justify-content-end fw-700' ${lightMode ?  " text-btn-light" : "text-btn-dark"}`}>Download All</p>
    </div>
    <div  className='promo-card-wrap'>
    {Promotion?.map((items) =>
      <PromotionCard  img={items?.image} kbsize={items?.image_size} size={items?.image_dimession} lightMode={lightMode}/>
    )}
    </div>  
    
</div>
  )
}

export default AffliatePromotion