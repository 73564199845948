import { DatePicker, Modal, Radio, Form, Input, Row, Col } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "../../../../components/CustomTable";
import { getThemeMode } from "../../../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../../../utilityFn/numberWithCommas";
import CustomPagination from "../../../../components/ant/CustomPagination";
import { EditOutlined } from "@ant-design/icons";

import {
  addNotification,
  editNotification,
  getAllNotification,
} from "../../../../api/fetchClient";
import moment from "moment";
import NotificationModal from "./NotificationModal";

function AdminNotification() {
  // const [date, setDate] = useState("2023-04-01");
  const { lightMode } = useSelector(getThemeMode);
  const [Page, setPage] = useState({ page: 1, perPage: 10 });
  const [Search, setSearch] = useState();
  const [input, setinput] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const [Noti] = Form.useForm();
  const [loadingState, setloadingState] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const { RangePicker } = DatePicker;
  const dateFormat = ["YYYY-MM-DD"];

  var currentDate = new Date().getDate();
  const editInitValues = useMemo(() => {
    let data = {
      ...editData,
      start_date: moment(editData.start_date),
      end_date: moment(editData.end_date),
    };
    return { data, id: editData.id };
  }, [editModal]);

  // Edit Notification
  const handleEditClick = async (values) => {
    // setModalIsOpen(true);

    const data = {
      ...values,
      start_date: moment(values.start_date).format("YYYY-MM-DD"),
      end_date: moment(values.end_date).format("YYYY-MM-DD"),
      id: editData.id,
    };
    await editNotification(data, editData.id).then((resp) => {
      setEditModal(false);
    });
  };

  const navigate = useNavigate();
  // Get Notification
  useEffect(() => {
    const date = moment(new Date()).startOf("month").format("YYYY-MM-DD");
    setisLoading(true);
    const data = () => {
      getAllNotification(date, Search, Page).then((res) => {
        setisLoading(false);

        setTableData(res?.data);
      });
    };
    data();
  }, [Search, Page, editModal, modalIsOpen]);
  const onChange = (date) => {
    if (date === null) {
      date = moment(new Date()).startOf("month").format("YYYY-MM-DD");
    } else {
      date = moment(date).startOf("month").format("YYYY-MM-DD");
    }
    getAllNotification(date, Search, Page).then((res) => {
      setTableData(res?.data);
    });
  };
  const createNotification = async (data, form) => {
    // data["audience"] = "all";
    console.log(data, "data");
    if (loadingState) return;
    setloadingState(true);
    await addNotification(data)
      .then((resp) => {
        setTableData(resp?.data);

        setModalIsOpen(false);
        console.log(resp?.data, "post");
      })
      .catch((err) => {
        setloadingState(false);
      });

    Noti.resetFields();
  };
  const onPageChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };
  const columns1 = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Create Date </p>,
      dataIndex: "created_at",
      fixed: "left",
      width: "40px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato pointer link-hover-underline"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {moment(text).format("DD-MM-YYYY")}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Heading</p>,
      dataIndex: "heading",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato "
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Sub Text</p>,
      width: "50px",
      dataIndex: "sub_text",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Button Text</p>,
      width: "50px",
      dataIndex: "button_text",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Button Link</p>,
      width: "50px",
      dataIndex: "button_link",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Valid From </p>,
      width: "50px",
      dataIndex: "start_date",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {moment(text).format("YYYY-MM-DD")}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Valid Till </p>,
      width: "50px",
      dataIndex: "end_date",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {moment(text).format("YYYY-MM-DD")}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0"> Visibility On</p>,
      width: "50px",
      dataIndex: "visibility",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text ? "YES" : "NO"}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0"> Edit</p>,
      width: "30px",
      dataIndex: "",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },

          children: (
            <span
              className="ff-lato Textcapitalize"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              <div
                onClick={() => {
                  setEditModal(true);
                  // alert(record.id);

                  setEditData((prev) => record);
                }}
                className="pointer "
              >
                <EditOutlined />
              </div>
            </span>
          ),
        };
      },
    },
  ];

  return (
    <div className="my-body px-30-0">
      <div className="d-flex justify-content-between mb-30">
        <div style={{ gap: "10px" }} className="d-flex">
          <DatePicker
            dropdownClassName={!lightMode && "DatePickerdropdrow-Antd-Dark"}
            className={"DatePicker-Antd-Dark"}
            style={{ height: "34px" }}
            // onChange={(e) => {
            //   setPage(1);
            //   setDate(moment(e).format("YYYY-MM-DD"));
            // }}
            onChange={onChange}
            defaultValue={moment(new Date(), dateFormat).startOf("month")}
            format={dateFormat}
            picker="month"
          />
          <input
            style={{ width: "30%", color: "white" }}
            placeholder="Search by Heading"
            value={input}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className={`w-100 h-45 mr-5 br-3 mb-10 p-3 portFolio-Modal-inputFeild ${
              lightMode
                ? "border1px-light-mode"
                : "border1px-dark-mode bg-dark-gray"
            }`}
          ></input>
        </div>
        <button
          onClick={() => setModalIsOpen(true)}
          className={`p-10 pointer btn-bg-primary text-white w-250px text-white br-3 fw-500 fs-16-14 `}
        >
          Create Notification
        </button>
      </div>

      {modalIsOpen && (
        <NotificationModal
          loadingState={loadingState}
          lightMode={lightMode}
          openModal={modalIsOpen}
          changeModalState={setModalIsOpen}
          onSubmit={createNotification}
          initialValues={{}}
        />
      )}
      {editModal && (
        <NotificationModal
          loadingState={loadingState}
          lightMode={lightMode}
          openModal={editModal}
          changeModalState={setEditModal}
          initialValues={editInitValues?.data}
          propdate={editInitValues?.data?.start_date}
          propdate1={editInitValues?.data?.end_date}
          onSubmit={handleEditClick}
        />
      )}
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          loading={isLoading}
          data={tableData?.results}
          columns={columns1}
        />
        <CustomPagination
          current={Page.page}
          pageSize={Page.perPage}
          lightMode={lightMode}
          onChange={onPageChange}
          total={tableData?.count}
        />
      </div>
    </div>
  );
}

export default AdminNotification;
