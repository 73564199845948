import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import modalTopImg from "../../assets/images/event banner/modalTopImg.png";
import fingradLogo from "../../assets/images/logo/fingrad-logo.png";
import modalBgImg from "../../assets/images/event banner/eventTopupBg.jpg";
import { useSelector } from "react-redux";
import { authStore } from "../../redux/reducers/authSlice";

function EventPopup() {
  const [visible, setvisible] = useState(false);
  const auth = useSelector(authStore);
  const navigate = useNavigate();
  const authRedirect = (pathName) => {
    if (auth.userData?.access_token) {
      navigate(pathName);
    } else {
      navigate(`/login`, { state: "/getpremium/summary/year/" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // setvisible(true);
      clearTimeout();
    }, 15000);
  }, []);

  return (
    <div>
      <Modal
        centered
        closable={true}
        width="400px"
        bodyStyle={{ padding: "0px", borderRadius: "20px",height:'400px' }}
        visible={visible}
        footer={null}
        onCancel={() => setvisible(false)}
        className="relative "
        wrapClassName={`premium-mod event-bg-grad`}
      >
        <img
          // style={{ backdropFilter: "blur(0.025px)" }}
          className="absolute w-100 h-100 fit-cover br-5"
          src={modalBgImg}
          alt="img" 
        />
        <div className="absolute flex w-100 h-100 flex-col justify-content-around px-30">
          <div>
            <h2 className="text-white fs-50-35 fw-700">Get</h2>
            <h2 className="text-white fs-50-35 fw-700">30% Off </h2>
            <p className="text-white fs-24-16 fw-500 mb-0">on Whales Plan</p>
          </div>
          <div    
            className="pointer"
            onClick={() => {
              setvisible(false);
              authRedirect("/getpremium/summary/year/");
            }}
          >
          <button
            style={{ height: "65px", width:'100%'}}
            className="bg-white  fw-600 text-black fs-24-16 br-5 p-5"
          >
            Claim Your Offer Now
          </button>
          </div>
        </div>
        {/* <img
          style={{ top: "-100px", left: "50%", transform: "translateX(-50%)" }}
          className="absolute"
          src={modalTopImg}
          alt="img"
        /> */}
        {/* <div
          style={{ left: "50%", transform: "translateX(-50%)", top: "20%" }}
          className="text-white w-90 d-flex flex-col div-items-center absolute"
        >
          <h1 className="fs-40-32 fw-700 text-md-center">Get FREE</h1>
          <p className="fs-27-16 fw-600 mb-20 text-center">
            Annual Subscription to 60+ Stock Market Courses & Webinars <br />{" "}
            (Worth Rs 1,999)
          </p>
          <div
            className="pointer"
            onClick={() => {
              setvisible(false);
              authRedirect("/getpremium/summary/year/");
            }}
          >
            <button
              style={{ width: "200px", height: "50px" }}
              className="btn-bg-secondary mb-20 fw-600 text-white fs-16-14 br-5"
            >
              Claim Your Offer Now
            </button>
          </div>
          <p style={{ opacity: "0.7" }} className="fs-s-12 mb-0">
            *Available with Portal’s 1 Year Whales Plan
          </p>
          <div className="flex align-items-center" style={{ opacity: "0.7" }}>
            <p className="fs-18-14 fw-600 mb-0">Visit</p>
            <a href="https://joinfingrad.com/" target="_blank">
              <img
                style={{ width: "130px", height: "40px" }}
                src={fingradLogo}
                alt="logo"
              />
            </a>
          </div>
        </div> */}
      </Modal>
    </div>
  );
}

export default EventPopup;
