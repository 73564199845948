import { Modal } from "antd";
import React from "react";
import svgSheet from "../../../assets/svg/svgSheet";

function DeleteModal({ visible, setVisible, lightMode, onConfirm , item }) {
  return (
    <Modal
      style={{ width: "320px" }}
      width={"320px"}
      visible={visible}
      centered
      wrapClassName={!lightMode && "modelClassname"}
      onCancel={() => {
        setVisible({state:false,id:null});
      }}
      footer={[
        <div onClick={() => onConfirm()} className="">
          <button className="bg-darkred p-10 br-5 fw-500 text-whit e">
            Confirm
          </button>
        </div>
      ]}
    >
      <div className="d-flex-col justify-content-center">
        <p className="d-flex mt-20 justify-content-center">
          {svgSheet.payReject}
        </p>
        <div className="d-flex flex-col align-items-center justify-content-center">
          <p className="fs-s-18 text-align-center fw-600 mb-0 d-flex justify-content-center mb-0">
            {" "}
            {item ? " Delete Of Stock Recommendation item" :" Delete Of Superstar item"}
          </p>
          <p className="fs-s-14 text-center">
            Are you sure you want to delete this superstar item ?
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
