import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { authStore } from '../redux/reducers/authSlice';

function EventTopBanner() {
  const auth = useSelector(authStore);
  const navigate = useNavigate();
  const authRedirect = (pathName) => {
    if (auth.userData?.access_token) {
      navigate(pathName);
    } else {
      navigate(`/login`, { state: "/getpremium/summary/year/" });
    }
  };

  return (
    <section className='bg-top-gardient w-100 h-60px flex div-items-center px-15' >
       <div className='flex align-items-center text-white'>
        <p className='fs-16-14 fw-600 mb-0'>40% OFF on Whales Plan</p>
        <div onClick={()=>authRedirect('/getpremium/summary/year/')} >
        <button
            style={{ maxWidth: "200px", height: "37px",minWidth:'120px' }}
            className="bg-white  fw-600 text-black fs-14-12 br-5 p-5"
          >
            Claim Offer
          </button>
          </div>
       </div>
        </section>
  )
}

export default EventTopBanner