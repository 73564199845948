import Item from "antd/lib/list/Item";
import React from "react";

function BottomDataSection({bottomData}) {
  const Calculators = [
    "One Time Value Calculator",
    "One Time Value Calculator",
    "One Time Value Calculator",
    "One Time Value Calculator",
    
  ];
  return (
    <div className="d-flex justify-content-between flex-wrap  my-30">
      <div className="w-70-100">
      {bottomData?.map((items)=>(
  <div>
  {" "}
  <p className="fs-22-18 fw-700">{items?.header}</p>
  <p>
    {items?.body}
  </p>
</div>
      ))}
        
      </div>
      <div className="w-29-100">
        <div>
          <p className="fs-22-18 fw-700">Popular Calculators</p>
          <div>
            {Calculators?.map((items) => (
              <p className="fs-s-16 fw-600 p-15 card-drop-dark-shadow">{items}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomDataSection;
