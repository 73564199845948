import React, { useState } from "react";
import { Drawer } from "antd";
import { Collapse } from "antd";
import Signup from "../popups/Signup";
import JoinPortal from "../popups/JoinPortal";
import Login from "../popups/Login";
import ForgotPassword from "../popups/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { authStore, setAuth } from "../../redux/reducers/authSlice";
import { Link, useNavigate } from "react-router-dom";
import svgSheet from "../../assets/svg/svgSheet";
import Fingrad from "../../assets/images/Fingrad.png";
import DiscoundCoupon from "./DiscoundCoupon";
import zerodha from "../../assets/images/Brokers/Zerodha.png";
import Abgel from "../../assets/images/Brokers/Abgel.png";
import Mstock from "../../assets/images/Brokers/Mstock.png";
import MstockDark from "../../assets/images/Brokers/MstockDark.png";
import AbgelDark from "../../assets/images/Brokers/AbgelDark.png";

import ZerodhaLight from "../../assets/images/Brokers/ZerodhaLight.png";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import PremiumRedirectModal from "../PremiumRedirectModal";

function MobileSideBar({
  mobileToggle,
  setMobileToggle,
  lightMode,
  authStatus,
  userLogout,
  fingradAccessStatus
}) {
  const [Visible, setVisible] = useState(false);
  const auth = useSelector(authStore);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const [PopupStatus, setPopupStatus] = useState("NONE");
  const navRedirect = (path) => {
    if (authStatus?.access_token) {
      navigate(path);
      setMobileToggle("NONE");
    } else {
      // navigate('/login')
      navigate(path);

      // setPopupStatus("JOIN");
      setMobileToggle("NONE");
    }
  };
  const ListOfDropdownData = [
    {
      name: "Portfolio Analysis",
      disc: "Get visual reports of your portfolio similar to what million dollar investment managers get!",
      Logo: svgSheet.portFolioSvg,
      link: "portfolio",
      isPremium:true
    },
    {
      name: "Screener",
      disc: "Stock search made easier. This powerful screener helps you saperate glitter from gold.",
      Logo: svgSheet.ScreenerSvg,
      link: "screener",
    },
    {
      name: "Stock compare",
      disc: "Compare stocks side to side and pick the winner!",
      Logo: svgSheet.CompareStocksSvg,
      link: "stockCompare",
    },
    {
      name: "Buckets",
      disc: "Find ready made bucket of stocks across several themes, and sectors. ",
      Logo: svgSheet.BuckectNewSvg,
      link: "Buckets",
    },
    {
      name: "Watchlist",
      disc: "Track your favourite stocks to find the best opportunity.",
      Logo: svgSheet.WatchListNewSvg,
      link: "watchlist",
    },
    {
      name: "Superstar Portfolio",
      disc: "Find out what the big names like the Jhunjhunwala's of the investing world are buying.",
      Logo: svgSheet.SuperStarPorNewSvg,
      link: "superstars",
    },
    // {
    //   name: "Calculator",
    //   disc: "Find out what the big names like the Jhunjhunwala's of the investing world are buying.",
    //   Logo: svgSheet.calculatorNavbarIcon,
    //   link: "calculator",
    // },
  ];

  const MarketDropdownData = [
    {
      name: "All Stocks",
      disc: "Every Indian stock methodically sorted by sector.",
      Logo: svgSheet.AllStocks,
      link: "stock/industry/Abrasives",
    },
    // {
    //   name: "Market Returns",
    //   disc: "Find returns and constituents of Sensex, Nifty and every other Indian Index!",
    //   Logo: svgSheet.MarketRetuenSvg,
    //   link: "",
    // },
    {
      name: "Top Gainers",
      disc: "Top Gainers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopGainersNewSvg,
      link: "marketstats/gainers",
    },
    {
      name: "Top Losers",
      disc: "Top Losers across Sensex, Nifty and every other Indian Index at one glance!",
      Logo: svgSheet.TopLosers,
      link: "marketstats/losers",
    },
    {
      name: "Market News",
      disc: "Stay updated with the latest news in the business world",
      Logo: svgSheet.MarketNewsSvg,
      link: "news",
    },
    {
      name: "Stock Heatmaps",
      disc: "Get bird eye view of how the market is performing",
      Logo: svgSheet.heatMapMenu,
      link: "index/NIFTY/heatmap",
    },
    {
      name: "Index Returns ",
      disc: "A straightforward and uncomplicated scan of the performance of all Indian indices.",
      Logo: svgSheet.MarketReturns,
      link: "marketstats",
    },
    {
      name: "Corporate Actions",
      disc: "Keep track of all Dividends, Bonuses, Splits & other corporate actions affecting your stocks.",
      Logo: svgSheet.MarketReturns,
      link: "corporateactions",
    },
    // {
    //   name: "Index Returns",
    //   disc: "Track different market indices, their historical returns and more.",
    //   Logo: svgSheet.heatMapMenu,
    //   link: "marketstats",
    // },
  ];
  //toggle popup
  const selectPopup = (key) => {
    switch (key) {
      case "SIGNUP":
        return (
          <Signup popupStatus={PopupStatus} onCloseHandler={setPopupStatus} />
        );
      case "JOIN":
        return (
          <JoinPortal
            popupStatus={PopupStatus}
            onCloseHandler={setPopupStatus}
          />
        );
      case "LOGIN":
        return (
          <Login popupStatus={PopupStatus} onCloseHandler={setPopupStatus} />
        );
      case "FORGOT":
        return (
          <ForgotPassword
            popupStatus={PopupStatus}
            onCloseHandler={setPopupStatus}
          />
        );
      default:
        return <div></div>;
    }
  };
  const authRedirect = (pathName) => {
    if (authStatus) {
      navigate(pathName);
      setPopupStatus("NONE");
    } else {
      selectPopup("JOIN");
      setPopupStatus("JOIN");
    }
    setMobileToggle("NONE");
  };

  return (
    <>
      <Drawer
        style={{
          top:
            mobileToggle === "MOBILE"
              ? `${105 + bannerOffsetCalc(auth)}px`
              : "0px",
        }}
        className="ff-poppins"
        // drawerStyle={{maxWidth:'1440px',marginInline:'auto',padding:'0px'}}
        drawerStyle={{
          background: lightMode ? "white" : "#292E3F",
          color: lightMode ? "black" : "white",
        }}
        headerStyle={{ marginInline: "16px", padding: "0px" }}
        bodyStyle={{ margin: "16px 24px 16px 13px", padding: "0px" }}
        // title="Tools"
        placement={"top"}
        closable={false}
        onClose={() => setMobileToggle("NONE")}
        visible={mobileToggle === "MOBILE" ? true : false}
        key="tools"
        height="92vh"
        zIndex={500}
      >
        <div className="d-flex flex-col justify-content-between h-90">
          <div>
            {auth.userData.access_token && (
              <div
                onClick={() => {
                  navigate("/profile");
                  setMobileToggle("NONE");
                }}
                className="flex align-items-center"
                style={{ padding: "20px 40px 0px 16px" }}
              >
                {auth?.userData?.user?.profile_pic ? (
                  <img
                    src={auth?.userData?.user?.profile_pic}
                    style={{ height: "30px", width: "30px" }}
                    className=" br-50 d-flex pointer"
                  />
                ) : (
                  <li
                    style={{ height: "30px", width: "30px" }}
                    className="avatar-img  br-50 d-flex pointer"
                  ></li>
                )}
                <div>
                  <p className="fs-s-14">{auth?.userData?.user?.first_name}</p>
                  <p className="fs-12">{auth?.userData?.user?.email}</p>
                </div>
              </div>
            )}
            {auth?.userData?.user?.is_affiliate && (
              <Link
                to={"/affiliate"}
                className="fs-s-16 fw-700"
                style={{ padding: "20px 40px 0px 16px" }}
              >
                Affiliate
              </Link>
            )}
            <Collapse
              // defaultActiveKey={['1']}
              // onChange={callback}
              expandIconPosition="end"
              bordered={false}
              className="ff-poppins "
              style={{ background: lightMode ? "#fff" : "#292E3F" }}
            >
              <Panel
                header={
                  <div
                    className="fs-s-16 fw-700"
                    style={{ color: lightMode ? "black" : "white" }}
                  >
                    Top Features
                  </div>
                }
                className="fw-500 mt-15"
                key="1"
              >
                <div style={{ color: lightMode ? "black" : "white" }}>
                  {ListOfDropdownData.map((items, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        items?.isPremium
                      ? authStatus?.user?.plan?.planId === "basic"
                        ? setVisible(true)
                        : navRedirect(`/${items?.link}`)
                      : navRedirect(`/${items?.link}`);
                        // navRedirect(`/${items?.link}`);
                        setMobileToggle("NONE");
                      }}
                      className={`w-100 h-100px mt-10 flex  ${
                        lightMode
                          ? "hover-light-blue"
                          : "bg-dark-nav-hover text-white"
                      } pointer`}
                    >
                      <p> {items.Logo}</p>
                      <div>
                        <p className="fw-700 fs-s-14 line-h-18">
                          {items?.name}
                        </p>
                        <p className="fs-s-12 line-h-18">{items.disc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Panel>
              <Panel
                header={
                  <div
                    className="fs-s-16 fw-700"
                    style={{ color: lightMode ? "black" : "white" }}
                  >
                    Market
                  </div>
                }
                className="fw-500"
                key="2"
                style={{ borderBottom: "1px solid #d9d9d9" }}
              >
                <div style={{ color: lightMode ? "black" : "white" }}>
                  {MarketDropdownData.map((items, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        navigate(`/${items?.link}`);
                        setMobileToggle("NONE");
                      }}
                      className={`w-100 h-100px mt-10 flex  ${
                        lightMode
                          ? "hover-light-blue"
                          : "bg-dark-nav-hover text-white"
                      } pointer`}
                    >
                      <p> {items.Logo}</p>
                      <div>
                        <p className="fw-700 fs-s-14 line-h-18">
                          {items?.name}
                        </p>
                        <p className="fs-s-12 line-h-18">{items.disc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Panel>
              <Panel
                header={
                  <div
                    className="fs-s-16 fw-700"
                    style={{ color: lightMode ? "black" : "white" }}
                  >
                    Start Learning
                  </div>
                }
                className="fw-500 "
                key="3"
              >
                <div style={{ color: lightMode ? "black" : "white" }}>
                  {/* <div
              //   onClick={() => navRedirect(`/${items?.link}`)}
              className={`w-100 h-100px mt-10 flex  ${
                lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
              } pointer`}
            >
              <p> {svgSheet.KnowlegeCenter}</p>
              <div>
                <p className="fw-700 fs-s-14 line-h-18">Knowledge Center</p>
                <p className="fs-s-12 line-h-18">
                  This is an extensive collection of questions answered on
                  everything about the stock market.{" "}
                </p>
              </div>
            </div> */}
                  <DiscoundCoupon lightMode={lightMode} />
                  <div
                    //   onClick={() => navRedirect(`/${items?.link}`)}
                    className={`w-100 h-100px mt-10 flex  ${
                      lightMode ? "hover-light-blue" : "bg-dark-nav-hover"
                    } pointer`}
                  >
                    <img width={22} height={22} src={Fingrad} alt=""></img>
                    <a href="https://joinfingrad.com/subscribe" target="_blank">
                      <p className="fw-700 fs-s-14 line-h-18">Fingrad</p>
                      <p className="fs-s-12 line-h-18">
                        New to the world of investing? Learn everything about
                        your finances from industry experts here!
                      </p>
                    </a>
                  </div>
                </div>
              </Panel>
              <Panel
                header={
                  <div
                    className="fs-s-16 fw-700"
                    style={{ color: lightMode ? "black" : "white" }}
                  >
                    Open Demat Account
                  </div>
                }
                className="fw-500 "
                key="4"
              >
                <div className="mb-10">
                  <div
                    className={`${
                      lightMode ? "bg-gray" : "card-drop-dark-shadow"
                    } p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}
                  >
                    <div>
                      <img
                        className=" w-230px h-60px fit-cover  "
                        src={lightMode ? ZerodhaLight : zerodha}
                      ></img>
                      <p
                        className={`${
                          lightMode ? "" : "text-gray"
                        } fs-s-12 mb-0 ml-10`}
                      >
                        India’s Largest discount Broker
                      </p>
                    </div>
                    <a
                      href="https://tradebrains.in/get/zerodha/"
                      target="_blank"
                    >
                      <button className=" btn-bg-primary  text-white br-5 p-5">
                        Open Account
                      </button>
                    </a>
                  </div>
                  <div
                    className={`${
                      lightMode ? "bg-gray" : "card-drop-dark-shadow"
                    } p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}
                  >
                    <a>
                      <img
                        className=" w-230px h-60px fit-cover "
                        src={lightMode ? Abgel : AbgelDark}
                      ></img>
                      <p
                        className={`${
                          lightMode ? "" : "text-gray"
                        } fs-s-12 mb-0 ml-10`}
                      >
                        India’s full service discount Broker
                      </p>
                    </a>
                    <a
                      href="https://tradebrains.in/get/angelbroking/"
                      target="_blank"
                    >
                      <button className=" btn-bg-primary  text-white br-5 p-5">
                        Open Account
                      </button>
                    </a>
                  </div>
                  <div
                    className={`${
                      lightMode ? "bg-gray" : "card-drop-dark-shadow"
                    } p-12  br-5  mb-10 align-items-center d-flex justify-content-between`}
                  >
                    <div>
                      <img
                        className=" w-230px h-60px fit-cover "
                        src={lightMode ? Mstock : MstockDark}
                      ></img>
                      <p
                        className={`${
                          lightMode ? "" : "text-gray"
                        } fs-s-12 mb-0 ml-10`}
                      >
                        India’s full service discount Broker
                      </p>
                    </div>
                    <a
                      href="https://tradebrains.in/get/mstock1/"
                      target="_blank"
                    >
                      <button className=" btn-bg-primary  text-white br-5 p-5">
                        Open Account
                      </button>
                    </a>
                  </div>
                  {/* <a href='https://tradebrains.in/get/angelbroking/' target="_blank"><img  className='p-10 w-200px h-100px '  src={Abgel}></img></a>
            <a href='https://tradebrains.in/get/mstock1/' target="_blank"><img  className='p-10 w-200px h-100px '  src={lightMode ? MstockDark : Mstock}></img></a> */}
                </div>
              </Panel>
            </Collapse>
            {auth?.userData?.user?.is_premium && (
              <div 
              onClick={() => {
                navigate("/premium-support");
                setMobileToggle("NONE");
              }}
                className="fw-700 fs-s-16"
                style={{ padding: "20px 40px 1px 16px" }}
              >
                Premium Support
              </div>
            )}
            {auth?.userData?.user?.plan?.sname==="Whales"  && (
              <div 
              onClick={() => {
                setMobileToggle("NONE");
                return (fingradAccessStatus.url===false&&fingradAccessStatus.url!==null)?window.open(fingradAccessStatus?.url)
                : fingradAccessStatus.url===null?window.open('https://joinfingrad.com/')
                 :
                 navigate("/joinfingrad")
                // navigate("/joinfingrad");
              }}
                className="fw-700 fs-s-16"
                style={{ padding: "20px 40px 1px 16px" }}
              >
               Fingrad Access
              </div>
            )}

            {auth?.userData?.user ? (
              <div>
                <div
                  className="fw-700 fs-s-16"
                  style={{ padding: "20px 40px 1px 16px" }}
                  // onClick={() =>{ dispatch(setAuth({})); navigate('/') }}
                  onClick={userLogout}
                >
                  {/* <GoogleLogoutFn/> */}
                  Logout
                </div>
              </div>
            ) : (
              <button
                className="w-100 h-40px btn-bg-primary text-white br-5"
                onClick={() => {
                  setMobileToggle("NONE");
                  // setPopupStatus("JOIN");
                  navigate("/login");
                }}
              >
                Login
              </button>
            )}
          </div>
          <div
            style={{ height: "50px" }}
            className={` d-flex align-items-center flex-col justify-content-around ${
              lightMode ? "bg-gray " : "bluedark-gradient"
            } `}
          >
            {!authStatus?.access_token && (
              <div className="d-flex align-items-center">
                <p className="fw-700 mb-0">Ready to get started ?</p>
                <p
                  className={`mb-0 ml-10 fw-700 ${
                    lightMode ? "" : "text-btn-dark"
                  }`}
                >
                  Sign up for free
                </p>
              </div>
            )}
            <div>
              <p
                onClick={() => {
                  navigate("/getpremium");
                  setMobileToggle("NONE");
                }}
                className="fw-700   mb-0 underline pointer"
              >
                View Pricing
              </p>
            </div>
          </div>
        </div>
      </Drawer>
      {selectPopup(PopupStatus)}
      <PremiumRedirectModal
        visible={Visible}
        setVisible={setVisible}
        modalPlan="portFolio"
      />
    </>
  );
}

export default MobileSideBar;
