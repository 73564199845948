import React, { useEffect, useState } from "react";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { authStore, setAuth } from "../../redux/reducers/authSlice";
import userIcon from "../../assets/images/userIcon.jpg";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import ImgWithUpload from "./ImgWithUpload";
import UserDataForm from "./UserdataForm";
import { patchUserData } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import moment from "moment";
import { message } from "antd";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
function ProfileIndex({setisLoginPage}) {
  const dispatch = useDispatch();
  const { lightMode } = useSelector(getThemeMode);
  const { userData } = useSelector(authStore);
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const fileTypes=['image/jpeg','image/png']
  useEffect(() => { 
    setisLoginPage(false)
  }, [])
  useEffect(() => {
    setImgUrl(userData?.user?.profile_pic ?? userIcon);
  }, [userData]);

  // uploaad to firebase
  const handleSubmit = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file||fileTypes.includes(file.type)===false) return;

    const storageRef = ref(storage, `/${userData?.user?.email}.jpeg`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await patchUserData({
            profilePic: downloadURL,
          }).then((resp) => {
            dispatch(
              setAuth({
                ...userData,
                user: { ...userData.user, profile_pic: downloadURL },
              })
            );
          });
      message.success('Profile Photo Updated');
          setImgUrl(downloadURL);
          setProgresspercent(0);
        });
      }
    );
  };

  const onSubmit = async (data) => {
    await patchUserData({
      name: `${data.first} ${data.last}`,
    }).then((resp) => {
      message.success('Profile Updated');
      dispatch(
        setAuth({
          ...userData,
          user: {
            ...userData.user,
            first_name: data.first,
            last_name: data.last,
          },
        })
      );
    });
  };

  return (
    <>
     <ReactHelmet
        title=" Profile - Trade Brains Portal"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="my-body" style={{ minHeight: "45vh" }}>
      <div className="ff-poppins w-100 max-w mx-auto px-15  ">
      <div className="d-flex align-items-center mb-30">
          <p className="mr-10  mb-0">{svgSheet.profileLogo}</p>
          <p className="fs-36-20  mb-0 fw-700 ">Profile Settings</p>
        </div>
        <section>
          <div className="d-flex flex-col-row gap-6r ">
            <div className="d-flex justify-md-center">
              <ImgWithUpload
                onLoading={progresspercent}
                curentImg={imgUrl}
                lightMode={lightMode}
                onUpload={handleSubmit}
              />
            </div>
            <div>
              <UserDataForm
                dispatch={dispatch}
                lightMode={lightMode}
                userData={userData?.user}
                onSubmit={onSubmit}
              />
              <div
                className={`${
                  lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
                }  w-100 only-mobile-view my-30`}
              ></div>
            </div>
            <div>
              <h2 className="fs-22-18 fw-700 lh-20 mb-20">
                Current Plan Details
              </h2>
              <h1 className="text-blue-grad fs-s-30 fw-700">{userData?.user?.plan?.sname} User</h1>
              {userData?.user?.premiumExpiry&&<p className="fs-s-16">Plan expires on {moment(userData?.user?.premiumExpiry).format('ll')}</p>}
            </div>
          </div>
        </section>
      </div>
    </div>
    </>
  );
}

export default ProfileIndex;
