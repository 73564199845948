import React from "react";
import AreaChart from "../../../components/Charts/AreaChart";
import BarChart from "../../../components/Charts/BarChart";
import { getGradient } from "../../../components/Charts/getGradients";
import CustomTable from "../../../components/CustomTable";
import numberWithCommas from "../../../utilityFn/numberWithCommas";

function PortfolioReturns({ lightMode }) {
  const backgroundColors = ["#438FFF", "#9AB6FB", "#E4E846"];
  const gradientColors = [
    [
      { xValue: 0, color: "#438FFF" },
      { xValue: 0.45, color: "#9A60FA" },
    ],

    [
      { xValue: 0, color: "#9AB6FB" },
      { xValue: 0.45, color: "#A0E7CA" },
    ],
    [
      { xValue: 0, color: "#E4E846" },
      { xValue: 0.45, color: "#ACE991" },
    ],
  ];
  const data2 = {
    labels: [2019, 2020, 2021, 2022],
    datasets: [
      {
        pointStyle: "circle",
        label: "Portfolio 1",
        fill: false,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[0]);
        },
        data: [34, 52, 32, 12],
        borderWidth: 0,
      },
      {
        pointStyle: "circle",
        label: "Portfolio 2",
        fill: false,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[1]);
        },
        data: [37, 15, 34, 15],
        borderWidth: 0,
      },
      {
        pointStyle: "circle",
        label: "Portfolio 3",
        fill: false,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          return getGradient(ctx, chartArea, gradientColors[2]);
        },
        data: [38, 22, 27, 18],
        borderWidth: 0,
      },
    ],
  };
  const tableColumns = [
    {
      title: <p className="fw-600 mb-0 fs-s-16">Portfolios</p>,
      dataIndex: "Portfolios",
      fixed: "left",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <div
              className="fs-s-14 link-hover-underline ff-poppins"
              style={{ color: lightMode ? "black" : "white" }}
            >
              {/* <Link to={`/stock/${record?.symbol}/consolidated`}> */}
              {text}
              {/* </Link> */}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">2018</p>,
      dataIndex: "2018",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">2019</p>,
      dataIndex: "2019",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fw-600 mb-0 fs-s-16">2020</p>,
      dataIndex: "2020",
      width: "40px",
      render: (text, record) => {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              ₹ {numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
  ];
  const tabletestdata = [
    {
      Portfolios: "Portfolio 1",
      2018: 2536666,
      2019: 23455,
      2020: 33456,
    },
    {
      Portfolios: "Portfolio 2",
      2018: 234567,
      2019: 12345,
      2020: 65435,
    },
    {
      Portfolios: "Portfolio 3",
      2018: 234567,
      2019: 12345,
      2020: 65435,
    },
  ];

  return (
    <div className="mt-40" id="Returns Analysis">
      <h2 className="main-header">Portfolio Returns</h2>
      <div style={{ height: "300px" }}>
        <BarChart
          type="bar"
          data={data2}
          options={{
            responsive: true,

            maintainAspectRatio: false,
            tension: 0,
            scales: {
              y: {
                display: true,
                ticks: {
                  color: lightMode ? "#3B3F4F" : "#ffff",
                  font: {
                    size: 14,
                  },
                  padding: 5,
                  maxTicksLimit: 7,
                },
                grid: {
                  color: lightMode ? "#ABABAB" : "#545E78",
                  borderColor: lightMode ? "#000" : "#545E78",
                  borderWidth: 1,
                  borderDash: [5, 15],
                  drawTicks: false,
                  lineWidth: 1,
                  offset: false,
                },
              },

              x: {
                display: true,
                ticks: {
                  color: lightMode ? "#3B3F4F" : "#ffff",
                  font: {
                    size: 14,
                  },
                  padding: 5,
                  maxTicksLimit: 4,
                },
                grid: {
                  color: lightMode ? "#ABABAB" : "#545E78",
                  borderColor: lightMode ? "#000" : "#545E78",
                  borderWidth: 1,
                  drawTicks: false,
                  lineWidth: 0,
                  offset: false,
                },
              },
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  color: lightMode ? "#3B3F4F" : "#ffff",
                  fontSize: 14,
                  usePointStyle: true,
                  boxWidth: 5,
                  padding: 30,
                },
              },
              tooltip: {
                titleAlign: "center",
                yAlign: "center",
                backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
                bodyFont: {
                  size: 14,
                  weight: 600,
                },

                displayColors: false,
                callbacks: {
                  title: (chart, args) => {
                    return "";
                  },
                  label: (context) => `${context.formattedValue}`,
                  labelTextColor: (context) => {
                    return backgroundColors[context.datasetIndex];
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
          }}
        />
      </div>
      <div
        className={`table-shadow mt-40  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={tableColumns}
          data={tabletestdata}
          scrollable={true}
          // loading={isLoading}
        />
      </div>
    </div>
  );
}

export default PortfolioReturns;
