import React, { useEffect, useMemo, useState } from "react";
import {
  getProfitAndLossCompareStocks,
  getQuaterlyCompareStocks,
} from "../../../api/fetchClient";
import SelectOptions from "../../../components/ant/SelectOptions";
import CustomTable from "../../../components/CustomTable";
import { getMonth } from "../../../utilityFn/getMonth";
import convertTableStructure from "../convertTableStructure";
import {
  QuarterlyStockComapreData,
  QuarterlyStockComapreDataIsBanking,
} from "../TableType";

function QuarterlyResultsCompareStocks({
  lightMode,
  removedEmptyData,
  symbolsandnames,
  columns,
  CompanyType
}) {
  const [selectd, setselectd] = useState("202209");
  const [Quaterly, setQuaterly] = useState();
  const [isLoading, setisLoading] = useState(false);
  const values =[{"value":"202209","label":"SEP 2022"},{"value":"202206","label":"JUN 2022"},{"value":"202203","label":"MAR 2022"},{"value":"202112","label":"DEC 2021"},{"value":"202109","label":"SEP 2021"}]

  const currentYear = "202209";
  const year = currentYear.substring(0, currentYear.length - 2);
  const month = currentYear.slice(4);
  const QuarterlyMonthsArray = []
  for(let i =0;i<5;i++){
  var d = new Date(`${year}-${month}`);
  console.log("log",d);
    d.setMonth(d.getMonth() - 3*i);
    const month1 =d.getMonth() === '0' ? '1': (String(d.getMonth()).length === 1 ?  "0"+d.getMonth() : d.getMonth() )
  let  date =d.getFullYear()+""+month1
  QuarterlyMonthsArray.push({"value":date,"label":getMonth(month1) + " "+ d.getFullYear() })
  }

  useEffect(() => {
    const getData = async () => {
      setisLoading(true);
      getQuaterlyCompareStocks(removedEmptyData, selectd,CompanyType).then(async (res) => {
        setisLoading(false);
        setQuaterly(res.data);
      });
    };
    getData();
  }, [selectd,CompanyType]);

  const returnsTableData = useMemo(() => {
    if (Quaterly?.stocks === undefined) {
      return;
    } else {
      const { tableBodyData, columns } = convertTableStructure(
        Quaterly?.stocks,
        Quaterly?.is_banking
          ? QuarterlyStockComapreDataIsBanking
          : QuarterlyStockComapreData,
        removedEmptyData,
        lightMode,
        symbolsandnames
      );
      return { tableBodyData, columns };
    }
  }, [Quaterly, selectd,lightMode]);

  return (
    <div id="Quarterly Results">
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center">
          <h2 className="main-header-c ">Quarterly Results</h2>
        </div>
        <div className="d-flex flex-col align-items-end">
          <SelectOptions
            lightMode={lightMode}
            data={values}
            setselectd={setselectd}
            defaultSelect={selectd}
            width={116}
          />
          <p className="fs-s-12 mb-0 text-gray mt-15 ">*Values in Cr.</p>
        </div>
      </div>
      <div
        className={`table-shadow   ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}
      >
        <CustomTable
          columns={returnsTableData?.columns}
          scrollable={true}
          loading={isLoading}
          data={returnsTableData?.tableBodyData}
        />
      </div>
    </div>
  );
}

export default QuarterlyResultsCompareStocks;
