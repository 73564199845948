import React, { useMemo } from 'react'
import CustomTable from '../../../components/CustomTable';
import { createTableData } from '../createTableData';
import { DividentTableData } from '../tableTypes';


function DividentTable({finRatioData,notBankStock,lightMode}) {
const yarsHeader=[
    {
        "title": <p className="fw-600 mb-0 fs-s-18">PARTICULARS</p>,
        "dataIndex": "PARTICULARS",
        "fixed": "left",
        "width": "70px",
        render:(text)=>{
            return {
                props:{
                    style:lightMode?  
                    { background:'rgba(242, 246, 248, 1)',fontSize:'16px',borderRight:'1px solid #C9D7DE',borderLeft:'1px solid #C9D7DE',borderBottom:'none'}
                    :  
                    { background:'#3B3F4F',borderLeft:'1px solid #545E78',borderRight:'1px solid #545E78',borderBottom:'none',fontSize:'16px'}
                    
                },
                children:(
                    <div style={{color:lightMode ? "black":"white"}}>{text}</div>
                )
            }
            
        }
    },
    {
        "title": <p className="fw-600 mb-0 fs-s-18">2018</p>,
        "dataIndex": "2018",
        "key": "2018",
        "width": "50px",
        render(text, index) {
            return {
              props: {
                style:lightMode?  
               { background:'rgba(242, 246, 248, 1)',borderColor:'#C9D7DE',borderRight:'1px solid #C9D7DE',borderBottom:'none'}
               
               :
               { background:'#3B3F4F',borderLeft:'1px solid #545E78',borderRight:'1px solid #545E78',borderBottom:'none'} 
              },
              children: (
                <span className="ff-lato" style={{ fontSize: "16px", fontWeight: "400",color:lightMode ? "black":"white" }}>
                ₹ {text}
              </span>
              )
            };
          },
    },
    {
        "title":<p className="fw-600 mb-0 fs-s-18">2019</p>,
        "dataIndex": "2019",
        "key": "2019",
        "width": "50px",
        render(text, index) {
            return {
              props: {
                style:lightMode?  
               { background:'rgba(242, 246, 248, 1)',borderColor:'#C9D7DE',borderRight:'1px solid #C9D7DE',borderBottom:'none'}
               
               :
               { background:'#3B3F4F',borderLeft:'1px solid #545E78',borderRight:'1px solid #545E78',borderBottom:'none'} 
              },
              children: (
                <span className="ff-lato" style={{ fontSize: "16px", fontWeight: "400",color:lightMode ? "black":"white" }}>
                ₹ {text}
              </span>
              )
            };
          },
    },
    {
        "title": <p className="fw-600 mb-0 fs-s-18">2020</p>,
        "dataIndex": "2020",
        "key": "2020",
        "width": "50px",
        render(text, index) {
            return {
              props: {
                style:lightMode?  
               { background:'rgba(242, 246, 248, 1)',borderColor:'#C9D7DE',borderRight:'1px solid #C9D7DE',borderBottom:'none'}
               
               :
               { background:'#3B3F4F',borderLeft:'1px solid #545E78',borderRight:'1px solid #545E78',borderBottom:'none'} 
              },
              children: (
                <span className="ff-lato" style={{ fontSize: "16px", fontWeight: "400",color:lightMode ? "black":"white" }}>
                ₹ {text}
              </span>
              )
            };
          },
    },
    {
        "title": <p className="fw-600 mb-0 fs-s-18">2021</p>,
        "dataIndex": "2021",
        "key": "2021",
        "width": "50px",
        render(text, index) {
            return {
              props: {
                style:lightMode?  
               { background:'rgba(242, 246, 248, 1)',borderColor:'#C9D7DE',borderRight:'1px solid #C9D7DE',borderBottom:'none'}
               
               :
               { background:'#3B3F4F',borderLeft:'1px solid #545E78',borderRight:'1px solid #545E78',borderBottom:'none'} 
              },
              children: (
                <span className="ff-lato" style={{ fontSize: "16px", fontWeight: "400",color:lightMode ? "black":"white" }}>
                ₹ {text}
              </span>
              )
            };
          },
    }
]
    const FiveYearData = useMemo(() => {
        if (finRatioData) {
          const { YearHeaders, tableBodyData } = createTableData(
            finRatioData,
             DividentTableData,
             lightMode
          );
          return { YearHeaders, tableBodyData };
        }
      }, [finRatioData,lightMode]);
  return (
      <div className={`table-shadow ${lightMode?'custom-antd-head-light':'custom-antd-head-dark'}`}>
    <CustomTable
    columns={FiveYearData?.YearHeaders}
    scrollable={true}
    data={FiveYearData?.tableBodyData}
    border={false}
  />
  </div>
  )
}

export default DividentTable