import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getMultiGraphProfitPortfolio,
  getPortfolioAnalysis,
  getPortfolioAnalysisCardData,
} from "../../../api/fetchClient";
import { authStore } from "../../../redux/reducers/authSlice";
import numFormatter from "../../../utilityFn/numFormatter";
import UserProductCard from "../UserProductCard";
import MainIndexList from "./MainIndexList";
import MarketStatus from "./MarketStatus";

function TopSection({ lightMode }) {
  const UserAuth = useSelector(authStore);
  const [portfolioData, setportfolioData] = useState({
    name: "Portfolio",
    data: [],
  });
console.log(portfolioData,'data');
  // get first portfolio
  useEffect(() => {
    if(UserAuth?.userData?.user?.is_premium){
    getPortfolioAnalysis()
      .then((response) => {
        console.log(response);
        if (response.data.length > 0) {
          setportfolioData({ name: "Portfolio", data: ["LOADING"] });
          getPortfolioAnalysisCardData({
            portfolioid: response.data[0]?.id,
          })
            .then(async(res) => {
           await   getMultiGraphProfitPortfolio(response.data[0]?.id,'1m').then((portResp)=>{
            console.log(portResp,'respppp');
            setportfolioData({
              port_perChange:portResp?.data.at(-1)?.per_change,
              name: response.data[0]?.name,
              data: [
                {
                  label: "Investment Value",
                  value: numFormatter(res.data.invest_value)??0,
                  unit: "₹",
                },
                {
                  label: "Current Value",
                  value: numFormatter(res.data.current_value)??0,
                  unit: "₹",
                },
                {
                  label: "Total P/L",
                  value: res.data.profit_loss_per??0,
                  unit: "%",
                },
                {
                  label: "Total Stocks",
                  value: `${res.data?.stocks_count??0} Stocks`,
                  unit: "",
                },
              ],
            });
           })
            })
            .catch(() =>
              setportfolioData({ name: "Portfolio", data: ["EMPTY"] })
            );
        } else {
          setportfolioData({ name: "Portfolio", data: ["EMPTY"] });
        }
      })
      .catch(() => setportfolioData({ name: "Portfolio", data: ["EMPTY"] }));
    }
  }, []);
  return (
    <div className="w-100 flex flex-col-row">
      <div className="w-70-100 ">
        {/* <MarketStatus
          lightMode={lightMode}
          portChange={portfolioData?.port_perChange ?? 0}
          isPremium={UserAuth?.userData?.user?.is_premium}
        /> */}
        <MainIndexList lightMode={lightMode}/>
      </div>
      <div className="w-100-30 ">
        <UserProductCard
          lightMode={lightMode}
          head={portfolioData?.name}
          tableContent={portfolioData?.data}
          bottomNav={{ text: "Go to Portfolio Analysis", link: "/portfolio" }}
          isPremium={UserAuth?.userData?.user?.is_premium?false:true}
        />
      </div>
    </div>
  );
}

export default TopSection;
