import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CustomTable from "../../../../components/CustomTable";
import svgSheet from "../../../../assets/svg/svgSheet";
import { Image } from "antd";
import CustomPagination from "../../../../components/ant/CustomPagination";
import {
  getAdminTrasationDetails,
  getUserManagementUserList,
} from "../../../../api/fetchClient";

function UserManagmentTable({
  lightMode,
  userList,
  setDeleteModal,
  setUserList,
  setisLoading,
  isLoading,
}) {
  const [Page, setPage] = useState({ page: 1, perPage: 25 });

  const tableColumnData = [
    {
      title: "Username",
      apiKey: "username",
      returnText: (text) => text,
    },
    {
      title: "Name",
      apiKey: "Name",
      // width:"10%",
      //   returnText: (text, record) =>
      //     `${record?.type === "monetary" ? "₹" : ""} ${text}
      //     `,
      returnText: (text) => text,
    },
    {
      title: "Email",
      apiKey: "email",
      returnText: (text) => text,
    },
    {
      title: "Role",
      apiKey: "role",
      returnText: (text) =>
        `${text[0].toUpperCase() + text.slice(1)}
        
        `,
      // ${text === "buy" ? svgSheet.buyGreen : svgSheet.sellRed}
    },
  ];

  const onPageChange = (page, perPage) => {
    const Page = { page: page, perPage: perPage };

    // setisLoading(true);
    // getUserManagementUserList(Page).then((res) => {
    //   setisLoading(false);
    //   setUserList(res?.data);
    // });
    // setPage({ page: page, perPage: perPage });
  };

  const stockTableData = useMemo(() => {
    const resp = tableColumnData?.map((data, i) => {
      return {
        title: <p className="fs-s-16 fw-600 mb-0">{data.title}</p>,
        dataIndex: data.apiKey,
        // fixed: i === 0 ? "left" : "",
        width: "10px",
        render(text, record) {
          return {
            props: {
              style: {
                background: lightMode ? "#F2F6F8 " : "#3B3F4F",
                borderRight: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderLeft: lightMode
                  ? "1px solid #C9D7DE"
                  : "1px solid #545E78",
                borderBottom: "none",
              },
            },
            children: (
              <span
                className="ff-lato "
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}>
                {data.title === "Username" ? (
                  <div className="flex">
                    <Image
                      width={40}
                      height={40}
                      src="https://portal.tradebrains.in/_next/image?url=https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAAcHTteQ5kmWFlQXXuE_m4TZv3aEUo-nAk5I9--7RSRyHPH7%3Ds96-c&w=48&q=75"
                    />
                    <div>
                      <p className="fw-600 mb-0">
                        {data.returnText(text, record)}
                      </p>
                      <div className="flex">
                        <Link
                          to={`/admin/user-management/edit/${record.id}`}
                          state={{ data: { ...record } }}
                          className="ff-lato pointer underline"
                          style={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}>
                          Edit
                        </Link>
                        <span
                          className="ff-lato pointer underline"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: lightMode ? "black" : "white",
                          }}
                          onClick={() => setDeleteModal(record.id)}>
                          Send password reset
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  data.returnText(text, record)
                )}
              </span>
            ),
          };
        },
      };
    });
    return resp;
  }, []);

  return (
    <div className="mt-40">
      <div
        className={`table-shadow  ${
          lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
        }`}>
        <CustomTable
          loading={isLoading}
          data={userList?.results}
          columns={stockTableData}
          scrollable={true}
          scrollLimit={400}
        />
        <CustomPagination
          current={Page.page}
          pageSize={Page.perPage}
          lightMode={lightMode}
          onChange={onPageChange}
          total={userList?.count}
        />
      </div>
    </div>
  );
}

export default UserManagmentTable;
