import { Form, Input, Modal } from 'antd'
import React, { useState } from 'react'
import { postForgoPassword } from '../../api/fetchClient';

function ForgotPassword({popupStatus,onCloseHandler}) {
  const [form] = Form.useForm();
  const [apiLoader, setapiLoader] = useState(false)
  
 const onSubmit=async(values)=>{
    setapiLoader(true)
     try{
        await postForgoPassword(values).then((resp)=>{    
            setapiLoader(false)
        })
     }
    catch(error){
        setapiLoader(false)
    }
 }
  return (
    <Modal
    centered
    closable={false}
    width='800px'
    bodyStyle={{padding:'0px'}}
    visible={popupStatus==="FORGOT"}
    footer={null}
    onCancel={()=>onCloseHandler('NONE')}
    >
         <div className='d-flex flex-col align-items-center ff-poppins w-100  p-50-16 bg-popup-img'>
         <p className='fw-500 fs-s-20'>Password Reset</p>
         <Form
    className='w-60-80'
    form={form}
    name="login"
    onFinish={onSubmit}
    scrollToFirstError>
         <Form.Item
        style={{margin:'6px 0px'}}
        name="email"
        rules={[
        {
            type: 'email',
            message: 'The input is not valid E-mail!'
        },
        {
            required: true,
            message: 'Please Enter your E-mail!',
        },
        ]}
          >
        <Input type='text' className='w-100  auth-form-input' placeholder='Email'/>
        </Form.Item>
        {
            apiLoader?
            <button disabled style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white pointer'>Sending Reset Email...</button>
            :
            <button type='submit' style={{margin:'6px 0px'}} className='w-100 bg-skyblue py-10 br-5 text-white pointer'>Email me the reset link</button>
        }
    </Form>
    <p style={{color:'#6c757d',textAlign:'center'}} className="fs-s-12 w-60-80 my-10">By signing up, you have read and agreed to our  
            <span style={{color:'#343a40'}}> Privacy</span> and <span style={{color:'#343a40'}}>Terms & Conditions</span>.</p>
            <p className='fw-700'>Don't have an account yet ? <span onClick={()=>onCloseHandler("SIGNUP")} className='pointer' style={{color:"#28a745"}}>Sign up</span></p>
             </div>
    </Modal>
  )
}

export default ForgotPassword