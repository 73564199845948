import React from 'react'
import ReactHelmet from '../../components/react-helmet/ReactHelmet'
import HeaderParagraph from './HeaderParagraph'

function Disclaimer() {
    const data = [
        {
        
            header:" General Disclaimer",
            value:<p> The information on this site is for educational purposes only and is not investment/trading advice/research and may be delayed. Please do your own research before making any investment decisions.
            The Materials on Site are provided on an 'as is' basis. The data feed for the website is sourced and curated using automated algorithms from various data sources. The comments and opinions are based on automated formulas. These are prone to errors of automation. 
            Though we try to make Trade Brains portal as accurate as possible, but this portal does not warrant the accuracy, completeness, timeliness, usefulness, no infringement, or fitness for a particular purpose on the content available through Trade Brains portal or any sites linked to or from this website. Neither Trade Brains shall be liable to you or anyone else for any investment decision, damages, or other losses resulting directly or indirectly from the use of Portal.tradebrains.in.</p>
        },{
            
            header:"Non Financial Advice",
            value:<p>None of the content on the website should be regarded as an advice to buy or sell any stock. Trade Brains’ Portal does not offer any financial investment advice or legal advice through the website. The information provided on Portal.tradebrains.in website in the form of charts, numbers, articles, blogs, notes, tool tips, etc. are solely to help individual make wise investment/financial decisions. This portal is neither a Portfolio Manager, Broker nor a Sub-broker. Trade Brains is not a registered investment advisor.
            Before investing, the user must check the correctness of the data at their own end. Due to the nature and speed of information, we cannot guarantee that the information is accurate, complete or up-to-date.
            Equity investments are volatile and high risk in nature. Don't invest more than what you can afford to lose. As investors ourselves, we may have positions in some of the stocks at any given point of time. Neither the Company nor any of its employees or owners shall be held liable or responsible for any errors or omissions on this website or for any damage you may suffer as a result of failing to seek competent financial advice from a professional who is familiar with your situation.
         <p className='fw-500'>   Note :</p>
            As investors ourselves, we may have positions in some of the stocks at any given point of time.</p>
        },
        ,{
            
            header:"NOT LEGAL ADVICE",
            value:<p>The information contained on this Website and the resources available for download through this website is not intended as, and shall not be understood or construed as, legal advice. While we provide some supplemental and occasional trainings on legal topics taught by attorneys or legal professionals, the information contained on this Website is not a substitute for legal advice from a licensed attorney who is aware of the facts and circumstances of your individual situation. We have done our best to ensure that the information provided on this Website and the resources available for download are accurate and provide valuable information. Regardless of anything to the contrary, nothing available on or through this Website should be understood as a recommendation that you should not consult with an attorney to address your particular information. The Company expressly recommends that you seek advice from an attorney prior to taking any actions. Neither the Company nor any of its attorneys shall be held liable or responsible for any errors or omissions on this website or for any damage you may suffer as a result of failing to seek competent legal advice from a licensed attorney who is familiar with your situation.</p>
        },
        {
            
            header:"NOT TAX ADVICE",
            value:<p>The information contained on this Website and the resources available for download through this website is not intended as, and shall not be understood or construed as, tax advice. The information contained on this Website is not a substitute for tax advice from a professional who is aware of the facts and circumstances of your individual situation. We have done our best to ensure that the information provided on this Website and the resources available for download are accurate and provide valuable information. Regardless of anything to the contrary, nothing available on or through this Website should be understood as a recommendation that you should not consult with a tax professional to address your particular information. The Company expressly recommends that you seek advice from a professional. Neither the Company nor any of its employees or owners shall be held liable or responsible for any errors or omissions on this website or for any damage you may suffer as a result of failing to seek competent tax advice from a professional who is familiar with your situation.

          <p className='fw-500'>  DISCLAMIER :</p>
            For applicable disclaimers, including limitations of liability, please see Daily Raven’s General Disclosures.</p>
        }
        ,{
            
            header:"Affiliate Disclosure    ",
            value:<p>Some of the links on this website are affiliate links, which means that we may earn a commission if you make a purchase using the link. The price you pay when you make a purchase any material will be the same whether you use the affiliate link or go directly to the vendors website using a non-affiliate link. By using the affiliate links, you are helping support the Trade Brains group and we genuinely appreciate your support.</p>
        },{
            
            header:"Paid Advertisements",
            value:<p>Paid advertisements appear on tradebrains’ publications. We does not endorse or evaluate the advertised product, service, or company, nor any of the claims made by the advertisement.</p>
        }
    ]
  return (
    <>
     <ReactHelmet
        title='Trade Brains Stock Reserach & Analysis Portal'
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="my-body">
    <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
      <div>
        <p className="fs-40-32 d-flex fw-700 align-items-center justify-content-center">
        Disclaimer
        </p>
      </div>
      <p>
        Dailyraven Technologies Private Limited is the owner of the website
        “portal.tradebrains.in” Our privacy policy is designed to provide
        greater transparency into our privacy practices and principles while
        processing your personal information. This privacy policy describes
        the treatment of certain Personal Information provided by you or
        collected by us on portal.tradebrains.in, where this privacy policy is
        posted. By using our Services, you are hereby consenting to the
        collection of your information by us. The information in some cases
        may include Personal Information and Sensitive Personal Information
        too. You will be required to click on the box bellow, confirming your
        acceptance of this Privacy Policy.
      </p>
      <div>
          <HeaderParagraph data={data}/>
      </div>
    </div>
  </div>
  </>
  )
}

export default Disclaimer