import React, { useEffect, useMemo, useState } from "react";
import { getSizeIndustryAnalysisGraph } from "../../api/fetchClient";
import DoughnutWithLabels from "../../components/Charts/DoughnutWithLabels";
import RadarChartWithLabels from "../../components/Charts/RadarChartWithLabels";
import { ArrayofSectors } from "./ArrayofSectors";

function SizeIndustryAnalysisGraph({
  portfolioid,
  lightMode,
  Portfoliostocksdata,
  trigger,
  IsOverFlowHidden
}) {
  const [SizeIndustryData, setSizeIndustryData] = useState();
  // const [UniqLabel, setUniqLabel] = useState([])

  const sizeAnalysisLabels = ["large", "mid", "small", "micro"];
  useEffect(() => {
    getSizeIndustryAnalysisGraph({ portfolioid }).then((res) => {
      if (Object.values(res?.data?.ind_analysis) === undefined) {
        return;
      } else {
        setSizeIndustryData(res?.data);
      }
    });
  }, [portfolioid, Portfoliostocksdata, trigger]);

  const arrayOfData = [];
  SizeIndustryData?.ind_analysis &&
    Object?.values(SizeIndustryData?.ind_analysis)?.map((items) => {
      arrayOfData.push(
        Number(((items / SizeIndustryData["total"]) * 100).toFixed(2))
      );
    });

  const SizeAnalysisArray = [];
  SizeIndustryData?.size_analysis &&
    sizeAnalysisLabels?.map((items, i) => {
      items
        ? SizeAnalysisArray.push(Number(SizeIndustryData?.size_analysis[items]))
        : SizeAnalysisArray.push(0);
    });

  const labelsofDoughnutChart = [];

  const uniqLabel = useMemo(() => {
    // const labeluniq = []
    
  }, [portfolioid, Portfoliostocksdata, trigger]);
  if (SizeIndustryData?.ind_analysis === undefined) return;

    const labeluniq = Object?.keys(SizeIndustryData?.ind_analysis);
    const data = []
    const data1 = []
   const returnValue =  labeluniq.map((uniq) => {
   
    ArrayofSectors.map((items)=>{
      items.apiValue === uniq && labelsofDoughnutChart.push(items.shortValue)
      items.apiValue === uniq && data1.push(items.apiValue)
    })

    });

    data1.map((uniq) => {

      data.push(Number(SizeIndustryData?.ind_analysis[uniq].toFixed(2)))
    })

  SizeIndustryData?.ind_analysis &&
    Object?.keys(SizeIndustryData?.ind_analysis)?.map((items) => {});

  return (
    <div >
      <div>
        <h2 className="main-header">Industry Analysis</h2>
        <DoughnutWithLabels
          isIndustry={true}
          divID="industry"
          labelArray={labelsofDoughnutChart}
          lightMode={lightMode}
          totalValue={SizeIndustryData?.total && SizeIndustryData["total"]}
          dataArray={
            data
          }
          IsOverFlowHidden={IsOverFlowHidden}
        />
      </div>
      <div>
        <h2 className="main-header">Size Analysis</h2>
        <RadarChartWithLabels
          divID="companySize"
          labelArray={["Large Cap", "Mid Cap", "Small Cap ", "Micro Cap "]}
          lightMode={lightMode}
          totalValue={SizeIndustryData?.total && SizeIndustryData["total"]}
          dataArray={SizeAnalysisArray}
          IsOverFlowHidden={IsOverFlowHidden}
        />
      </div>
    </div>
  );
}

export default SizeIndustryAnalysisGraph;
