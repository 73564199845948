import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThemeMode } from '../../../redux/reducers/ThemeSlice';
import BottomDataSection from '../BottomDataSection';
import CalcInputFeilds from '../CalcInputFeilds';
import ResultContainerCalc from '../ResultContainerCalc';

function AdvanceGrahamcalc() {
    const { lightMode } = useSelector(getThemeMode);
    const [GetRsult, setGetRsult] = useState([]);
    const [eps, setEps] = useState();
    const [egr, setEgr] = useState();
    const [gvtbond, setGvtbond] = useState(4.22);
    const [crnt, setCrnt] = useState();
    const [avgbond, setAvgbond] = useState(4.4);
    const [pe, setPe] = useState(8.5);
    const [grm, setGrm] = useState(2);
    const [result, setResult] = useState(0);
    const [err, setErr] = useState(false);
  
    console.log("GetRsult",GetRsult);
const options = {
    scales: {
      y: {
        beginAtZero: true
      }
      
    },
    plugins:{
        legend:{
            position:"bottom",
            labels:{

            }
        },
    },

    maintainAspectRatio: false
  }
  useEffect(() => {
      const Value = Number(GetRsult["eps"]) * (Number(GetRsult["pe"]) + 2 * Number(GetRsult["egr"])) * (Number(GetRsult["avgbond"]) / Number(GetRsult["gvtbond"]));
      console.log("Value",Value);
      setResult(Value);
    
  }, [GetRsult])
  
  var data = {
    labels: ["Chocolate", "Vanilla", "Strawberry","Strawberry1"],
    datasets: [
        {
            label: "Blue",
            backgroundColor: "#6DB8FD",
            data: [3,7,4,5],
            
  
        },
        {
            label: "Red",
            backgroundColor: "#1774FF",
            data: [4,3,5,5],
            
  
        },
     
    ]
  };
  const bottomData = [{
    header:"Graham Formula:",
    body:"The Graham formula proposes to calculate a company's intrinsic value V* as: V* = (E PSX (8.5+2g) x 4.4) /Y V* = the value expected from the growth formulas over the next 7 to 10 years EPS= the company's last 12-month earnings per share 8.5 P/E base for a no growth company g= reasonably expected 7 to 10 year growth rate 4.4 the average yield of AAA corporate bonds in 1962 (Graham did not specify the duration of the bonds, though it has been asserted that he used 20 year AAA bonds as his benchmark for this variable[4]) the current yield on AAA corporate bonds"
  }]
  const formlist = [
    {name:"crnt",value:"Current Share Price (in Rs)"},
    {name:"avgbond",value:"Average 10 Yrs Govt.Bond Yield (1962)"},
    {name:"eps",value:"EPS (last 4 quaters)(in Cr)"},
    {name:"pe",value:"PE of stock with Zero Growth"},
    {name:"egr",value:"Expected Growth Rate (next 5 yrs)(%)"},
    {name:"grm",value:"Growth Rate Multiple"},
    {name:"gvtbond",value:"10 Yrs Govt.Bond Yield (%)"},
  ];
  
  const resetValues = () =>{
    setResult(0)
  }

  const navigate = useNavigate()
  const ResultHeder =[{"Intrinsic Value per Share":result ? Math.floor((Number(result)).toFixed(2)) :0}]
  return (
 <div>
         <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
      <p className='pointer' onClick={()=>navigate(-1)}>Calculators / Advanced Graham Calculator</p>
        <p className="mb-0 fs-30-20 fw-700">Advanced Graham Calculator</p>
        <p className='w-60-80'>This is a modified version of the Original Graham Number valuation to reach a defensive Intrinsic value of the stocks
</p>
      </div>
      <div  className="mx-50-0 p-45-10 br-0 custom-input-dark">
      <div>
          <CalcInputFeilds setGetRsult={setGetRsult} resetValues={resetValues} formlist={formlist} lightMode={lightMode}/>
      </div>
      <div>
          <ResultContainerCalc ResultHeder={ResultHeder}  lightMode={lightMode}/>
      </div>
      </div>  
      <div>
          <BottomDataSection bottomData={bottomData} />
      </div>  
    </div>
    </div>
  )
}

export default AdvanceGrahamcalc