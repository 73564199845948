import { utils, write } from "xlsx";
import { fromDataAsync, RichText } from "xlsx-populate/browser/xlsx-populate";
import { excelWorkbookToBlob } from "./excelWorkbookToBlob";
export const exportToExcel = (
  excelData,
  fileName,
  pageLink = "portal.tradebrains.in",
  dataType,
) => {
  const {
    PandLtableData,
    metricsTableData,
    quraterlyFinData,
    balanceSheetTable,
    cashFlowTableData,
    shareHoldingData,
    FiveYearData,
  } = excelData;
 
  const addStyles = (workBookBlob, dataInfo) => {
    return fromDataAsync(workBookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        for (let i = 0; i < dataInfo.titleRange.length; i++) {
          sheet.range(dataInfo.titleRange[i]).merged(true).style({
            fontFamily: "poppins",
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
            fontSize:15
          });
        }
        for (let i = 0; i < dataInfo.tBodyRange.length; i++) {
          sheet.range(dataInfo.tBodyRange[i]).style({
            fontFamily: "poppins",
            horizontalAlignment: "center",
            border: true,
          });
        }
        for (let i = 0; i < dataInfo.headerRange.length; i++) {
          sheet.range(dataInfo.headerRange[i]).style({
            fill: "3B3F4F",
            bold: "true",
            fontColor: "FFFFFF",
          });
        }
        for (let i = 0; i < dataInfo.subHeadText.length; i++) {
          sheet.cell(dataInfo.subHeadText[i]).style({
            bold: "true"
          });
          
        }
        for (let i = 0; i < dataInfo.tableValueType.length; i++) {
          sheet.cell(dataInfo.tableValueType[i]).
          value('Values in Cr.')
          .style({
            fontFamily: "poppins",
            fontColor: "4A515E",
            horizontalAlignment: "center",
          });
          
        }
      });
      
      // add stock name
      const cell = workbook.sheet(0).range("A1:B1");
      cell.value(new RichText());
      cell.value(fileName);
      cell.style({ bold: true, fontFamily: "poppins", fontSize: 15 });
      cell.merged(true);
      // add data type
      const dataTypeCell = workbook.sheet(0).cell("A2");
      dataTypeCell.value(new RichText());
      dataTypeCell.value(`Figures represented in ${dataType}`);
      dataTypeCell.style({ fontFamily: "poppins", fontSize:12 });
      // add page link
      const linkCell = workbook.sheet(0).cell("F1");
      linkCell.hyperlink(pageLink);
      linkCell.value(pageLink);
      linkCell.style({ bold: true, fontFamily: "poppins", fontSize: 13 });
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  const convertExeclTable = (data) => {
    let headersData = {};
    data?.YearHeaders?.forEach(({ PARTICULARS, ...item }, i) => {
      headersData[i] = item.title.props.children;
    });
    const bodyData = data?.tableBodyData?.map(({ PARTICULARS, ...item }) => {
      let partData = PARTICULARS;
      delete item["PARTICULARS"];
      delete item["key"];
      delete item["tooltipText"];
      let finalobj = {};
      Object.entries(item).forEach((ele, i) => {
        finalobj[i + 1] = ele[1];
      });
      return { 0: partData, ...finalobj };
    });
    return { headersData, tableBodyData: bodyData };
  };

  const matricTableData = convertExeclTable(metricsTableData);
  const qurterlyTableData = convertExeclTable(quraterlyFinData);
  const pandLTableData = convertExeclTable(PandLtableData);
  const balanceSheetTableData = convertExeclTable(balanceSheetTable);
  const cashflowTableData = convertExeclTable(cashFlowTableData);
  const holdingTableData = convertExeclTable(shareHoldingData);
  const factSheetTableData = convertExeclTable(FiveYearData);

  const yearColSize = [
    {
      wch: 30,
    },
    {
      wch: 15,
    },
    {
      wch: 15,
    },
    {
      wch: 15,
    },
    {
      wch: 15,
    },
    {
      wch: 15,
    },
  ];

  const wb = utils.book_new();
  // key matrics
  const metricTitle = [{ 0: "Key Metrics" }];
  const metricFinalArr = [
    {},
    {},
    ...metricTitle,
    matricTableData.headersData,
    ...matricTableData.tableBodyData,
  ];
  // quarerly table arr
  const quarterTitle = [{}, { 0: "Quarterly Results" }];
  const quarterlyFinalArr = [
    ...quarterTitle,
    qurterlyTableData.headersData,
    ...qurterlyTableData.tableBodyData,
  ];
  // profit and loss arraya
  const pandLTitle = [{}, { 0: "Profit and Loss" }];
  const pandLFinalArr = [
    ...pandLTitle,
    pandLTableData.headersData,
    ...pandLTableData.tableBodyData,
  ];
  // balance sheet array
  const balanceTitle = [{}, { 0: "Balance Sheet" }];
  const balanceFinalArr = [
    ...balanceTitle,
    balanceSheetTableData.headersData,
    ...balanceSheetTableData.tableBodyData,
  ];
  // cashflow array
  const cashflowTitle = [{}, { 0: "Cashflow Statement" }];
  const cashflowFinalArr = [
    ...cashflowTitle,
    cashflowTableData.headersData,
    ...cashflowTableData.tableBodyData,
  ];
  // share holding array
  const holdingTitle = [{}, { 0: "Shareholding Pattern" }];
  const holdingFinalArr = [
    ...holdingTitle,
    holdingTableData.headersData,
    ...holdingTableData.tableBodyData,
  ];
  // 5 year fact sheet
  const factSheetTitle = [{}, { 0: "5 Year Factsheet" }];
  const factSheetArr = [
    ...factSheetTitle,
    factSheetTableData.headersData,
    ...factSheetTableData.tableBodyData,
  ];
  // final array
  const finalArray = [
    ...metricFinalArr,
    ...quarterlyFinalArr,
    ...pandLFinalArr,
    ...balanceFinalArr,
    ...cashflowFinalArr,
    ...holdingFinalArr,
    ...factSheetArr,
  ];

  // row height
  const wsRowData = finalArray?.map((item) => {
    return {
      hpx: 30,
    };
  });
  const ws = utils.json_to_sheet(finalArray, { skipHeader: true });
  ws["!cols"] = yearColSize;
  ws["!rows"] = wsRowData;
  utils.book_append_sheet(wb, ws, "Stock Data");
  const wbBlob1 = excelWorkbookToBlob(wb);
  const dataInfo = {
    titleCell: "A3",
    titleRange: [
      "A3:B3",
      `A${matricTableData.tableBodyData.length + 6}:F${
        matricTableData.tableBodyData.length + 6
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        9
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        9
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        12
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        12
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        15
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        15
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        18
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        18
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        21
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        21
      }`,
    ],
    tBodyRange: [
      `A4:B${matricTableData.tableBodyData.length + 4}`,
      `A${matricTableData.tableBodyData.length + 7}:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        7
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        10
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        10
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        13
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        13
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        16
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        16
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        19
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        19
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        22
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        factSheetTableData.tableBodyData.length +
        22
      }`,
    ],
    // tBodyRange: `A4:B${qurterlyTableData.tableBodyData.length+4}`,
    headerRange: [
      "A4:B4",
      `A${matricTableData.tableBodyData.length + 7}:F${
        matricTableData.tableBodyData.length + 7
      }`,

      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        10
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        10
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        13
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        13
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        16
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        16
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        19
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        19
      }`,
      `A${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        22
      }:F${
        matricTableData.tableBodyData.length +
        qurterlyTableData.tableBodyData.length +
        pandLTableData.tableBodyData.length +
        balanceSheetTableData.tableBodyData.length +
        cashflowTableData.tableBodyData.length +
        holdingTableData.tableBodyData.length +
        22
      }`,
    ],
    subHeadText:['A94',"A102",'A110',"A115",'A122'],
    tableValueType:['F15',
    `F${
      matricTableData.tableBodyData.length +
      qurterlyTableData.tableBodyData.length +
      8
    }`
    ,
    `F${
      matricTableData.tableBodyData.length +
      qurterlyTableData.tableBodyData.length +
      pandLTableData.tableBodyData.length +
      11
    }`
    ,`F${
      matricTableData.tableBodyData.length +
      qurterlyTableData.tableBodyData.length +
      pandLTableData.tableBodyData.length +
      balanceSheetTableData.tableBodyData.length +
      14
    }`]
  };
  return addStyles(wbBlob1, dataInfo);
};
