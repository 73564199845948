import { Collapse, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import StockEndpoints from "../../../api/endPoints";
import CustomTable from "../../../components/CustomTable";
import NoData from "../../../components/NoData";
import useGetFetch from "../../../hooks/useGetFetch";
import { getFormatDate } from "../../../utilityFn/getFormatdate";
import numberWithCommas from "../../../utilityFn/numberWithCommas";

function CorporateActionsStock({ lightMode, symbol }) {
  const {
    isLoading,
    apiData: actionData,
    serverError,
  } = useGetFetch(StockEndpoints.stockActions(symbol));
  const { Panel } = Collapse;

  const createTableHeader = (data) => {
    console.log(data);
    const removeName =
      data &&
      Object?.keys(data)?.filter((item) => item.includes("_name") === false);
      console.log(removeName,"actionData1");
    const headerResult =
      removeName &&
      removeName?.map((key, i) => {
        return {
          key: i,
          title: (
            <p className="fw-600 mb-0 fs-s-16">
              {data[`${key}_name`] ? data[`${key}_name`] : key}
            </p>
          ),
          dataIndex: key,
          width: "100px",
          render(text, index) {
            return {
              props: {
                style: lightMode
                  ? {
                      background: "rgba(242, 246, 248, 1)",
                      borderColor: "#C9D7DE",
                      borderRight: "1px solid #C9D7DE",
                      borderBottom: "none",
                    }
                  : {
                      background: "#3B3F4F",
                      borderLeft: "1px solid #545E78",
                      borderRight: "1px solid #545E78",
                      borderBottom: "none",
                    },
              },
              children: (
                <span
                  className="ff-lato"
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: lightMode ? "black" : "white",
                  }}
                >
                  {(key.includes("date")
                    ? getFormatDate(text)
                    : numberWithCommas(text)) ?? "NA"}
                </span>
              ),
            };
          },
        };
      });
    return headerResult;
  };
  if (isLoading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }
  console.log("actionData",actionData)
  return (
    <div>
      <Collapse
        accordion
        style={{ background: lightMode ? "#ffff" : "#3B3F4F", padding: "10px" }}
        bordered={false}
        expandIconPosition="end"
      >
        {actionData &&
          Object.entries(actionData)?.map((item, i) => {
            return (
              <Panel
                key={i}
                className="fs-16-14 collapse-borderless"
                style={{
                  background: lightMode ? "#F2F6F8" : "#292E3F",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
                header={
                  <p
                    className={`fw-500 fs-18-16 mb-0 ${
                      lightMode ? "text-black " : "text-white"
                    }`}
                  >
                    {item[0]}
                  </p>
                }
              >
                <div
                  className={`px-15 ${lightMode ? "text-black" : "text-white"}`}
                >
                  {
                    item[1].length > 0 ? (
                      <div
                        className={`w-100 table-shadow ${
                          lightMode
                            ? "custom-antd-head-light"
                            : "custom-antd-head-dark"
                        }`}
                      >
                        <CustomTable
                          columns={createTableHeader(item[1][0])}
                          scrollable={true}
                          scrollLimit={800}
                          data={item[1].slice(0, 8)
                            // item[0] === "Dividends" ||
                            // item[0] === "Block Deal BSE"
                            //   ? item[1].slice(0, 8)
                            //   : item[1]
                          }
                        />
                      </div>
                    ) : (
                      <NoData />
                    )
                    //  <NoData/>
                  }
                  {
                    item[1].length > 8 && (
                      <Link
                        to={`/stock/${symbol}/${item[0]}/all`}
                        className="text-gray d-flex justify-content-end mt-10 underline fs-s-14"
                        style={{ textDecorationColor: "#AEAEAE" }}
                      >
                        View All
                      </Link>
                    )}
                </div>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
}

export default CorporateActionsStock;
