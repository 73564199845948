import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import { getIndexreturns, getStockChange } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import CustomTable from "../../components/CustomTable";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import MobileBottomBar from "../stock details/MobileBottomBar";
import StockGraph from "../stock details/StockGraph";
import NewsTabStockDetails from "../stock details/Corp Announce/NewsTabStockDetails";
import LeftIndexMoves from "./LeftIndexMoves";
import LeftSectionCompanies from "./LeftSectionCompanies";
import ReturnsTable from "./ReturnsTable";
import StockConstituents from "./StockConstituents";
import TodaysPerformanceSection from "./TodaysPerformanceSection";
import PerformanceBarLine from "../../components/PerformanceBarLine";
import StockFaq from "../stock details/StockFaq";
import useGetFetch from "../../hooks/useGetFetch";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import { authStore } from "../../redux/reducers/authSlice";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import TechnicalIndex from "../stock details/technical/TechnicalIndex";

function IndexDetails() {
  const { indexname } = useParams();
  const UserAuth = useSelector(authStore);
  const [IndexReturn, setIndexReturn] = useState([]);

  // company stock data
  const [Stockdata, setStockdata] = useState();
  const { isLoading, serverError, apiData } = useGetFetch(
    StockEndpoints.marketMovers(indexname)
  );

  useEffect(() => {
    const getData = () => {
      getIndexreturns({ indexname }).then(async (res) => {
        await setIndexReturn(res);
      });
    };
    getData();
  }, [indexname]);

  console.log("apiData", apiData);

  let IndexReturndata = [
    "1W Returns",
    "3M Returns",
    "6M Returns",
    "1Y Returns",
  ];
  const indexReturns = {
    one_week:"1W Returns",
    three_months:"3M Returns",
    six_months:"6M Returns",
    one_year:"1Y Returns",
  }
  const [indexReturnTable, setindexReturnTable] = useState();
  const returns = Object.keys(indexReturns).map((key,i)=>  {
    return {[indexReturns[key]]:IndexReturn[key]}
  })
  console.log("indexReturnTable",returns);
  useEffect(() => {
    let IndexReturndata1 = [];

    for (let i = 0; i < Object.keys(IndexReturndata)?.length; i++) {
      IndexReturndata1.push({
        label: IndexReturndata[i],
        value: Object.values(IndexReturn)[i],
      });
    }
    setindexReturnTable(IndexReturndata1);
  }, [IndexReturn]);

  useEffect(() => {
    const getdata = () => {
      const res = getStockChange(indexname).then(async (res) => {
        await setStockdata(res.data);
      });
    };
    getdata();
  }, [indexname]);

  const { lightMode } = useSelector(getThemeMode);
  // get faq data
  const {
    isLoading: loading,
    apiData: faqData,
    serverError: faqErr,
  } = useGetFetch(StockEndpoints.getFaq(`index/?index=${indexname}`));
  console.log("faq", faqData);

  const convertFaq = useMemo(() => {
    const testdata = faqData?.map((item) => {
      return {
        head: item.question,
        content: item.answer,
      };
    });
    return testdata;
  }, [faqData, indexname]);
  const columns = [
    {
      title: <p className="fs-s-16 fw-600 mb-0">Company Name</p>,
      dataIndex: "comp_name",
      fixed: "left",
      width: "60px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated`}>
              <span
                className="ff-lato link-hover-underline"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            </Link>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">CMP</p>,
      dataIndex: "close",
      width: "50px",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" + numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Change </p>,
      width: "50px",
      dataIndex: "change",
      render(text, record) {
        const textwithComma = text?.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text > 0 ? "+" : "-"} ₹{" "}
              {text > 0
                ? numberWithCommas(Number(textwithComma))
                : numberWithCommas(Math.abs(Number(textwithComma)))}
            </span>
          ),
        };
      },
    },
    {
      title: <p className="fs-s-16 fw-600 mb-0">Percent</p>,
      dataIndex: "percent",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: redGreenColorPicker(text, lightMode),
              }}
            >
              {text > 0 && "+"} {text && text?.toFixed(2) + "%"}
            </span>
          ),
        };
      },
    },

    {
      title: <p className="fs-s-16 fw-600 mb-0">Prev Close</p>,
      dataIndex: "prev_close",
      width: "50px",

      render(text, record) {
        const textwithComma = text?.toFixed(2);

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && "₹" + numberWithCommas(Number(textwithComma))}
            </span>
          ),
        };
      },
    },
  ];

  const SecondValidatinLosers = (data) => {
    const datavalue = data?.map((items, i) => {
      if (items?.percent <= 0) {
        return { ...items };
      }
    });

    return datavalue;
  };
  const SecondValidatinGainers = (data) => {
    const datavalue = data?.map((items, i) => {
      if (items?.percent > 0) {
        return { ...items };
      }
    });

    return datavalue;
  };

  const sidebarData = [
    {
      id: "Overview",
      icon: svgSheet.overviewIcon,
      label: "Overview",
      offset: -150 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Top Gainers & Losers",
      icon: svgSheet.graphIcon,
      label: "Top Gainers & Losers",
      offset: -105 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Heat Map",
      icon: svgSheet.heatMapIcon,
      label: "Heat Map",
      offset: -130 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "Stock Constituents",
      icon: svgSheet.candleStickicon,
      label: "Stock Constituents",
      offset: -135 - bannerOffsetCalc(UserAuth),
    },
    {
      id: "News",
      icon: (
        <div style={{ width: "24px", height: "24px" }}>{svgSheet.newsIcon}</div>
      ),
      label: "News",
      offset: -115 - bannerOffsetCalc(UserAuth),
    },
  ];

  return (
    <>  
      <ReactHelmet
        title={`${indexname} Price, Charts & Constituents | Trade Brains`}
        desc={`${indexname} Index price, Index data, ${indexname} stock Constituents.`}
      />
      <div className=" ff-poppins max-w mx-auto h-100  px-15 relative  ">
        <div className="d-flex  mobile-only-col">
          <div
            style={{ top: `${145 + bannerOffsetCalc(UserAuth)}px` }}
            className="left-main-section box-shadow-card my-body"
          >
            {/* Left section */}

            <LeftSectionCompanies lightMode={lightMode} Navdata={sidebarData} />
            <LeftIndexMoves lightMode={lightMode} CurrentIndex={indexname} />
          </div>
          <div className="d-flex flex-col right-mobilw-view-indexdetails w-78-100 my-body ">
            <div className="right-main-index-details d-flex flex-col  ml-30-0">
              {/* Right Section */}
              <div
                style={{ width: "100%" }}
                className="center-data-indexDetails"
                id="Overview"
              >
                {/* Today's Performance */}
                <TodaysPerformanceSection
                  lightMode={lightMode}
                  indexname={indexname}
                  Stockdata={Stockdata}
                />

                {apiData?.total_count !== 0 && (
                  <div
                    className={`Gainers-loosers-bar-div mt-20 box-shadow-card p-20 d-flex flex-col ${
                      lightMode ? "bg-gray" : "bg-dark-gray text-white"
                    }`}
                  >
                    <div>
                      <p className="fs-16-14 ff-lato">Gainers & Losers</p>
                    </div>
                    <PerformanceBarLine
                      LoserCount={apiData?.losers_count}
                      gainerCount={apiData?.gainers_count}
                      totalCount={apiData?.total_count}
                    />
                  </div>
                )}

                <div className="mt-20">
                  <StockGraph
                    type="index"
                    lightMode={lightMode}
                    symbol={indexname}
                    volumeBar={false}
                  />
                </div>
                <ReturnsTable
                  returnsdata={returns}
                  lightMode={lightMode}
                />
                {/* <div className="desktop-non mt-20">
              <LeftSectionCompanies />
              </div> */}
            <TechnicalIndex lightMode={lightMode} symbol={indexname} CMP={Stockdata&&Stockdata[0] && Stockdata&&Stockdata[0]["close"]} index={true}/>
              </div>
              {apiData?.total_count !== 0 && (
                <div
                  className="d-flex flex-col wrap-right-section"
                  id="Top Gainers & Losers"
                >
                  <h2
                    style={{
                      color: lightMode ? "#009633" : "#00FF57",
                    }}
                    className="main-header-c"
                  >
                    Top Gainers <CaretUpOutlined />
                  </h2>
                  <div className=" box-shadow-card">
                    <div
                      className={`table-shadow   ${
                        lightMode
                          ? "custom-antd-head-light"
                          : "custom-antd-head-dark"
                      }`}
                    >
                      <CustomTable
                        columns={columns}
                        data={SecondValidatinGainers(apiData?.gainers)}
                        scrollable={true}
                        loading={isLoading}
                      />
                    </div>
                  </div>
                  <h2
                    style={{
                      color: lightMode ? "#FF0000" : "#F82E2E",
                    }}
                    className="main-header-c text-dark-red"
                  >
                    Top Losers <CaretDownOutlined color="clr-red" />
                  </h2>
                  <div className="box-shadow-card">
                    <div
                      className={`table-shadow   ${
                        lightMode
                          ? "custom-antd-head-light"
                          : "custom-antd-head-dark"
                      }`}
                    >
                      <CustomTable
                        columns={columns}
                        data={SecondValidatinLosers(apiData?.losers)}
                        scrollable={true}
                        loading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <StockConstituents indexname={indexname} lightMode={lightMode} />
            <div id="News">
            <h2 className="main-header sub-table-news-data">News</h2>
              <div className="sub-table-news-data w-100 d-flex flex-col ">
                <NewsTabStockDetails />
                <StockFaq data={convertFaq} lightMode={lightMode} />
              </div>
            </div>
          </div>
        </div>
        <MobileBottomBar navData={sidebarData} lightMode={lightMode} />
      </div>
    </>
  );
}

export default IndexDetails;
