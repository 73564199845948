import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import StockEndpoints from "../../api/endPoints";
import svgSheet from "../../assets/svg/svgSheet";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import DotLoader from "../../components/spinners/DotLoader";
import TrendingStocks from "../../components/TrendingStocks";
import useGetFetch from "../../hooks/useGetFetch";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { urlEncode } from "../../utilityFn/urlEncode";
import StockFaq from "../stock details/StockFaq";
import LeftBar from "./LeftBar";
import SectorDropDown from "./SectorDropDown";

function AllStocksIndex({setisLoginPage}) {
  const { lightMode } = useSelector(getThemeMode);
  const navigate = useNavigate();
  const { sector } = useParams();
  const {
    isLoading,
    apiData: sectorName,
    serverError,
  } = useGetFetch(StockEndpoints.AllSecotrs);

  useEffect(() => { 
    setisLoginPage(false)
  }, [])
  

  // get faq data 
  const {
    isLoading: loading,
    apiData: faqData,
    serverError: faqErr,
  } = useGetFetch(StockEndpoints.getFaq(`sector/?sector=${urlEncode(sector)}`))
  console.log(faqData);

  const convertFaq=useMemo(() => {
    const testdata=faqData?.map((item)=>{
     return {
       head:item.question,
       content:item.answer
     }
    })
    return testdata
    }, [faqData,sector])

if(isLoading){
  return <DotLoader />;
}
  return (
    <>
     <ReactHelmet
        title={`Complete stock List of ${sector} sector | All stocks in NSE BSE | Complete stock List of NSE and BSE Stocks`}
        desc="Find and search from a database of all stocks in the market"
      />
    <div className="ff-poppins max-w mx-auto  px-15  w-100">
      <div className="my-body">
        <div className="d-flex align-items-center mb-30">
          <p className="mr-10 w-m-28 mt-5 mb-0">{svgSheet.allStocksLogo}</p>
          <h1 className="mb-0 fs-36-20 fw-700">All Stocks</h1>
        </div>
        <div className="d-flex">
          <div
            style={{ width: "20%", top: "130px", height: "min-content" }}
            className="detail-left-card p-fixed"
          >
            <LeftBar
              lightMode={lightMode}
              data={sectorName}
              navigate={navigate}
              activeSector={sector}
            />
          </div>
          <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
            <div className="mb-20 d-flex justify-content-end">
              <SectorDropDown
                lightMode={lightMode}
                data={sectorName}
                navigate={navigate}
                activeValue={sector}
              />
            </div>
            <Outlet />
          </div>
        </div>
        <StockFaq data={convertFaq} lightMode={lightMode} />
        <TrendingStocks lightMode={lightMode} />
      </div>
    </div>
    </>
  );
}

export default AllStocksIndex;
