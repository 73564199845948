import React from "react";
import StockEndpoints from "../../api/endPoints";
import PremiumBlurLayout from "../../components/PremiumBlurLayout";
import ProsAndConscard from "./ProsAndConscard";
import prosConsBlurDark from "../../assets/images/premiumBlur/prosConsBlurDark.jpg";
import prosConsBlurLight from "../../assets/images/premiumBlur/prosConsBlurLight.jpg";
import { useSelector } from "react-redux";
import { authStore } from "../../redux/reducers/authSlice";
import useGetFetch from "../../hooks/useGetFetch";

function ProsConsDiv({ lightMode, symbol, companyType }) {
  const UserAuth = useSelector(authStore);
  const {
    isLoading,
    apiData: prosAndConsdata,
    serverError,
  } = useGetFetch(StockEndpoints.prosAndCons(symbol, companyType));
  return (
    <div style={{ width: "65%" }} className="pros-and-cons  d-flex-col mr-10">
      {UserAuth?.userData?.user?.is_premium ? (
        <>
          <ProsAndConscard
            prosOrCons={true}
            data={prosAndConsdata?.pros}
            lightMode={lightMode}
          />
          <ProsAndConscard
            prosOrCons={false}
            data={prosAndConsdata?.cons}
            lightMode={lightMode}
          />
        </>
      ) : (
        <PremiumBlurLayout
          bgImg={lightMode ? prosConsBlurLight : prosConsBlurDark}
          top="50%"
          headerText="Unlock Pros and Cons"
        />
      )}
    </div>
  );
}

export default ProsConsDiv;
