import { InfoCircleFilled } from "@ant-design/icons";
import React from "react";
import GaugeAnt from "../../../components/Charts/GaugeAnt";

function DividentGraphTable({ finRatioData, lightMode }) {
  let dpCurrent, dpAvg, dyCurrent, dyAvg;
  if (finRatioData !== null) {
    const objKeys = Object.keys(finRatioData);
    dpCurrent = finRatioData[objKeys.at(-1)]?.dividend_payout_ratio;
    dpAvg =
      (finRatioData[objKeys.at(-1)]?.dividend_payout_ratio +
        finRatioData[objKeys.at(-2)]?.dividend_payout_ratio +
        finRatioData[objKeys.at(-3)]?.dividend_payout_ratio) /
      3;
    dyCurrent = finRatioData[objKeys.at(-1)]?.yield;
    dyAvg =
      (finRatioData[objKeys.at(-1)]?.yield +
        finRatioData[objKeys.at(-2)]?.yield +
        finRatioData[objKeys.at(-3)]?.yield) /
      3;
  }

  const gaugetChartfirstData = {
    title: {
      formatter: ({ percent }) => {
        return `Current`;
      },
      style: ({ percent }) => {
        return {
          fontSize: "18px",
          fontWeight: "500",
          lineHeight: 1,
          // color: percent<.35?'#F5AF19':percent>.35&&percent<.65?'#1AFF00':'#F27121',
          color: lightMode ? "#575757" : "white",
        };
      },
    },
    content: {
      offsetY: 25,
      formatter: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      style: () => {
        return {
          fontSize: "18px",
          color: lightMode ? "#575757" : "white",
          fontWeight: "500",
        };
      },
    },
  };
  const gaugeChartSecond = {
    title: {
      formatter: ({ percent }) => {
        return `Current`;
      },
      style: ({ percent }) => {
        return {
          fontSize: "18px",
          lineHeight: 1,
          fontWeight: "500",
          // color: percent<.35?'#8A2387':percent>.35&&percent<.65?'#FFFC00':'#38EF7D',
          color: lightMode ? "#575757" : "white",
        };
      },
    },
    content: {
      offsetY: 25,
      style: () => {
        return {
          fontSize: "18px",
          color: lightMode ? "#575757" : "white",
          fontWeight: "500",
        };
      },
      formatter: ({ percent }) => `${(percent * 10).toFixed(2)}%`,
      // formatter: ({ percent }) =>console.log("percent",percent * 10),

    },
  };
  return (
    <div>
      <div style={{ gap: "1rem" }} className="flex-col-row p-20">
        <div className="w-100-50 ">
          <p className="mb-10 d-flex justify-content-center fw-600">
            Dividend Payout Ratio
          </p>
          <div style={{ maxHeight: "300px", marginInline: "auto" }}>
            <GaugeAnt
            id="DPR"
            labelFormat={{
              formatter(v) {
                return  Number(v) * 100 
              },
            }}
              percetage={dpCurrent / 100 <= 100 ? dpCurrent / 100 : 100}
              // colors={[
              //   "l(0) 0:#F5AF19 0.142:#F12711 .284:#51CF43 .426:#51CF43 .568:#1AFF00 .710:#1AFF00 .852:#F27121 1:#8A2387",
              // ]}
              colors={[
                "l(0) 0.1:rgba(234, 54, 54, 1) 0.2:rgba(255, 252, 0, 1) 0.6:rgba(0, 199, 79, 1) 0.8:rgba(255, 252, 0, 1) 0.9:rgba(234, 54, 54, 1)",
              ]}
              labelData={gaugetChartfirstData}
              width={300}

            />
          </div>
          <div className="d-flex flex-col align-items-center my-10">
            <div style={{ gap: "1rem" }} className="d-flex flex-col">
              <div className="d-flex align-items-center fs-s-16">
                <span>3 Yrs Avg {' '}: </span>
                <span className="fw-500 ml-2">{' '} {dpAvg?.toFixed(2)}%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100-50">
          <p className="mb-10 d-flex justify-content-center fw-600">
            Dividend Yield
          </p>
          <div style={{ maxHeight: "300px", marginInline: "auto" }}>
            <GaugeAnt
            id="DY"
              percetage={dyCurrent / 10 <= 10 ? dyCurrent / 10 : 10}
              // colors={[
              //   "l(0) 0:#8A2387 0.142:#F27121 .284:#FFFC00 .426:#FFFC00 .568:#A8FF78 .710:#A8FF78 .852:#38EF7D 1:#11998E",
              // ]}
              colors={[
                "l(0) 0.1:rgba(234, 54, 54, 1) 0.5:rgba(255, 252, 0, 1) 0.9:rgba(0, 199, 79, 1)",
              ]}
              labelData={gaugeChartSecond}
              width={300}

            />
          </div>
          <div className="d-flex flex-col align-items-center my-10">
            <div style={{ gap: "1rem" }} className="d-flex flex-col">
              <div className="d-flex align-items-center fs-s-16">
                <span>3 Yrs Avg : </span>
                <span className="fw-500 ml-2">{' '} {dyAvg?.toFixed(2)}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DividentGraphTable;
