import { MailFilled } from '@ant-design/icons'
import { Modal } from 'antd'
import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import googleIcon from '../../assets/images/logo/googleIcon.png'
import bgLeftImg from '../../assets/images/bg/login-bg-left.jpg'
import bgRightImg from '../../assets/images/bg/login-bg-right.jpg'
import GoogleAuth from './GoogleAuth'

function JoinPortal({popupStatus,onCloseHandler}) {
 
  return (
    <Modal
      centered
      closable={false}
      width='800px'
      bodyStyle={{padding:'0px'}}
      visible={popupStatus==="JOIN"}
      footer={null}
      onCancel={()=>onCloseHandler('NONE')}
      >
        <div className='d-flex flex-col align-items-center ff-poppins w-100  p-50-16 bg-popup-img'>
        <p className='fw-500 fs-s-20'>Join Trade Brains Portal</p>
        <div onClick={()=>onCloseHandler("SIGNUP")} style={{margin:'6px 0px'}} className='center w-60-80 py-10 br-5 bg-white b-gray pointer'>
        <MailFilled style={{fontSize:'20px'}} className='mx-10px'/>
         <span> Sign up with Email</span>
          </div>
          <GoogleAuth title="Sign up with Google" onCloseHandler={onCloseHandler}/>
          {/* <div style={{margin:'6px 0px'}} className='pl-10px center w-60-80 py-10 br-5 bg-white b-gray pointer'>
        <img src={googleIcon} alt="icon" className='mx-10px h-20 w-20'/>
         <span> Sign up with Google</span>
          </div> */}
          <p style={{color:'#6c757d',textAlign:'center'}} className="fs-s-12 w-60-80 my-10">By signing up, you have read and agreed to our  
                       <span style={{color:'#343a40'}}> Privacy</span> and <span style={{color:'#343a40'}}>Terms & Conditions</span>.</p>
                       <p className='fw-700'>Already have an account ? <span className='pointer' onClick={()=>onCloseHandler("LOGIN")} style={{color:"#28a745"}}>Login</span></p>
        </div>
      </Modal>
  )
}

export default JoinPortal