import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import BottomDataSection from "../BottomDataSection";
import CalcInputFeilds from "../CalcInputFeilds";
import ResultContainerCalc from "../ResultContainerCalc";

function DividendDiscountCalc() {
  const [GetRsult, setGetRsult] = useState([]);
  const [crntdivd, setcrntdivd] = useState();
  const [rrretrun, setRrreturn] = useState();
  const [egr, setEgr] = useState();
  const [rslt, setrslt] = useState(0);
  const [err, setErr] = useState(false);
  const { lightMode } = useSelector(getThemeMode);
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {},
      },
    },

    maintainAspectRatio: false,
  };
 
  console.log("GetRsult",rslt);
  var data = {
    labels: ["Chocolate", "Vanilla", "Strawberry", "Strawberry1"],
    datasets: [
      {
        label: "Blue",
        backgroundColor: "#6DB8FD",
        data: [3, 7, 4, 5],
      },
      {
        label: "Red",
        backgroundColor: "#1774FF",
        data: [4, 3, 5, 5],
      },
    ],
  };
useEffect(() => {

    const getdiv1 = GetRsult["crntdivd"] * (1 + GetRsult["egr"] / 100);
    const getddvalue = getdiv1 / (GetRsult["rrretrun"] / 100 - GetRsult["egr"] / 100);
    console.log("getddvalue",getddvalue);
    setrslt(getddvalue);
 
}, [GetRsult])

const ResultHeder =[{
  "Intrinsic Value ":Math.floor(
    rslt && rslt !== Infinity && rslt !== -Infinity
      ? rslt
      : 0
  ).toLocaleString("en-US")
}]
const bottomData = [{
  header:"Dividend Discount Model (DDM) Formula:",
  body:"Dividend discount model is a valuation method used to find the intrinsic value of a company by discounting the predicted dividends that the company will be giving (to its shareholders in future) to its present value. Here, the present intrinsic value of stock can also be found using the Discounted Dividend Model. The Dividend Discount Model uses the present value of the stock, the expected future dividends, and the growth rate. This model is similar to the Constant Growth Model expect it discounts the dividends at the expected return instead of discounting the free cash flows at the weighted."
},
{
  header:"Average cost of capital. The intrinsic value of the stock will be calculated as:",
  body:"V* = (Current Dividend * (1 + Expected Growth Rate)) / (Required Rate of Return-Expected Growth Rate)"
},{
  header:"Current Dividend:",
  body:"It is the most recent annual dividend received in the current financial period."
},{
  header:"Required Rate of Return:",
  body:"It is the percentage that represents the minimum rate of return that the investors require when buying stocks.  "
},{
  header:"Expected Growth Rate:",
  body:"It is the percentage growth rate of the company's dividend expected during a certain period of time."
}]
  const navigate = useNavigate();
  const formlist = [
    { name: "crntdivd", value: "Current Share Price (in Rupees)" },
    { name: "rrretrun", value: "Required Rate of Return (in percentage)" },
    { name: "egr", value: "Expected Growth Rate (in Percentage)" },
  ];

  const resetValues = () =>{
    setrslt(0)
  }
  
  return (
    <div>
      <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
        <div className="my-30">
        <p onClick={() => navigate(-1)} className="pointer">
            Calculators / Dividend Discount Model Valuation
          </p>
          <p className="mb-0 fs-30-20 fw-700">
            Dividend Discount Model Valuation
          </p>
         <p className="w-60-80">Dividend discount valuation model is the valuation method used to find the Intrinsic value of a company by discounting the expected dividends that the company would be giving
</p>
        </div>
        <div className="mx-50-0 p-45-10 br-0 custom-input-dark">
          <div>
            <CalcInputFeilds resetValues={resetValues} setGetRsult={setGetRsult} formlist={formlist} lightMode={lightMode} />
          </div>
          <div>
            <ResultContainerCalc ResultHeder={ResultHeder}  lightMode={lightMode} />
          </div>
        </div>
        <div>
          <BottomDataSection bottomData={bottomData} />
        </div>
      </div>
    </div>
  );
}

export default DividendDiscountCalc;
