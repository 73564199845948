const StockEndpoints = {
  marketMovers: (symbol) => `index/${symbol}/movers/`,
  alllMarketMovers: (symbol, mover, page, perPage,sortBy,sortOrder) =>
    `index/${symbol}/movers/${mover}?page=${page}&per_page=${perPage}&by=${sortBy}&ascending=${sortOrder}`,
  // stock details page
  keyMetrics: (type, symbol, standOrConso) => {
    return `${type}/${symbol}/${standOrConso}/key-metrics`;
  },
  priceChange: (type, symbol) =>
    `${type}/${symbol}/prices?days=1&type=INTRADAY&limit=1`,
  profitAndLoss: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/profit-and-loss`,
  balanceSheet: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/balance-sheet`,
  cashFlow: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/cash-flow`,
  quarterlyFinStatement: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/quarterly`,
  shareHolding: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/share-holding`,
  allShareHolding: (symbol) => `stock/share_holdings/${symbol}/`,
  finRatio: (type, symbol, standOrConso) =>
    `${type}/${symbol}/${standOrConso}/financial-ratios`,
  basicStock: (type, symbol) => `${type}/${symbol}`,
  stockGraphData: (type, symbol, duration, limit) =>
    `${type}/${symbol}/prices?${duration}&type=${
      duration === "days=1" ? "INTRADAY" : "EOD"
    }${limit ? limit : ""}`,
  stockPerformance: (stock) => `stock/${stock}/performance`,
  stockAddress: (symbol) => `address/${symbol}/`,
  stockReturns: (symbol) => `stock-returns/${symbol}`,
  stockAnnouncement: (symbol) => `stock/annoucements/${symbol}/`,
  stockPeerCompany: (symbol, standOrConso) =>
    `stock/similar/${symbol}/${standOrConso}/`,
  stockActions: (symbol) => `stock/corp-actions/${symbol}/`,
  growthReturns: (symbol, standOrConso) =>
    `stock/growth/${symbol}/${standOrConso}/`,
  prosAndCons: (symbol, standOrConso) =>
    `stock/prof_loss/${symbol}/${standOrConso}/`,
    pivotAPI:(symbol,index,sortBy)=>`${index? 'piviot-index':'piviot'}/table/${symbol}/?sort_by=${sortBy}`,
  // news api
  stockRelatednews: (symbol) => `search?search=${symbol}`,
  categoryNews: (perpage, page, categoryId) =>
    `posts?per_page=${page}&&page=${perpage}&&categories=${categoryId}`,
  stockRelatedDateApi: (postId) => `posts/${postId}`,
  //watchlist page
  userWatchlist: "watchlists/",
  addStockToWatchlist: (listname) => `watchlists/${listname}/`,
  //screener
  // getFilterTypes: "screener/",
  getFilterTypes: "screener/filters",
  // index constitients
  getIndexconstitients: (listname) => `index/constitients/BANKNIFTY/?page=1`,
  //superstar portfolio
  getSuperstars: `superstars_port/list_all/`,

  starDetails: (name,sortBy,sortOrder) => `superstars_port/stocks/${name}/?by=${sortBy}&ascending=${sortOrder}`,
  starTableDrop:(portId,finCode)=>`superstars_port/stocks/last_four/${portId}/${finCode}/`,
  starDescdata:(name)=>`superstars_port/details/${name}/`,
  starPortfolioGraph:(name)=>`superstars_port/graph/${name}/`,
  starSectorGraph:(name)=>`superstars_port/industry-analysis/${name}/`,
  // top gainers and losers page
  getIndexList: "index/all",
  // all stocks
  AllSecotrs: `stock/industry/all`,
  //    compare Stocks
  profitAndLossCompareStocks: () => `stock/prof/compare/standalone/?`,

  sectorStocks: (sector, page, perPage,sortBy,sortOrder) =>
    `stock/industry/${sector}/stocks?page=${page}&per_page=${perPage}&by=${sortBy}&ascending=${sortOrder}`,

  postPortfolioAnaltsis: ({data}) => `add/portfolio/${data}`,
  getPortfolioAnaltsis: `add/portfolio/`,
  getstockArrCmp:`stock/batch-prices/`,
  trendingIndices:`index/trending/`,
  // payment 
  paymentCallback:(token)=>`payments/checkout/callback/${token}`,
  // faq 
  getFaq:(query)=>`faq/${query}`,
  // contact us
  postFeedback:'contactus/',
  // affiliate
  postAffiliate:`affiliate/signup/`,
  affiliateQuery:`affiliate/query/add/`,
  //afiliate
  referAffiliate:(referId,email)=>`affiliate/user/add/?affiliate=${referId}&email=${email}`,
  postAffiliateBankAPI:`affiliate/bankdetails/`,
  excelCounter:`users/downloads/`
};

export default StockEndpoints;
