import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import BottomDataSection from "./BottomDataSection";
import CalcInputFeilds from "./CalcInputFeilds";
import ResultContainerCalc from "./ResultContainerCalc";

function ReturnOnEquityCalc() {
  const [GetRsult, setGetRsult] = useState([]);

  const [shareeqty, setShareeqty] = useState();
  const [csp, setCsp] = useState();
  const [roe, setRoe] = useState();
  const [so, setSo] = useState();
  const [dy, setDy] = useState();
  const [dpr, setDpr] = useState();
  const [ms, setMs] = useState();
  const [dr, setDr] = useState();

  const [intvalue, setIntvalue] = useState(0);
  const [nincome, setNincome] = useState(0);
  const [rv, setRv] = useState(0);
  const [nvprv, setnvprv] = useState(0);
  const [nvpd, setnvpd] = useState(0);

  const [she, setShe] = useState();
  const [div, setDiv] = useState();
  const [npv, setNpv] = useState();

  const [err, setErr] = useState(false);
  const [show, setShow] = useState(false);


  

  console.log("GetRsult",GetRsult);
console.log("nvprv",nvprv,rv);
  useEffect(() => {
  
      let sheList = [];
      let divList = [];
      let npvList = [];
      setShe(sheList);
      setDiv(divList);
      setNpv(npvList);
      console.log(sheList, "SheList");
      console.log(divList, "divList");
      console.log(npvList, "npvList");
      let RoeConservativeGrowthRate =
        (GetRsult["roe"] / 100) * (1 - GetRsult["ms"] / 100) * (1 - GetRsult["dpr"] / 100) * 100;

      let RoeShareholdersEquity =
        (GetRsult["shareeqty"] * (1 + Number(RoeConservativeGrowthRate) / 100)) / GetRsult["so"];
      sheList.push(RoeShareholdersEquity);

      let RoeDividends =
      Number(GetRsult["csp"]) * (Number(GetRsult["dy"]) / 100) * (1 + Number(RoeConservativeGrowthRate) / 100);
      divList.push(RoeDividends);
      for (let i = 0; i < 9; i++) {
        sheList.push(
          sheList[i] * (1 + Number(RoeConservativeGrowthRate) / 100)
        );
      }
      console.log("RoeConservativeGrowthRate",GetRsult["csp"]);

      for (let i = 0; i < 9; i++) {
        divList.push(
          divList[i] * (1 + Number(RoeConservativeGrowthRate) / 100)
        );
      }
      for (let i = 0; i < 10; i++) {
        npvList.push(divList[i] / (1 + Number(GetRsult["dr"]) / 100) ** (i + 1 - 1));
      }
      let RoeNetIncome = sheList[sheList.length - 1] * (GetRsult["roe"] / 100);

      let RoeRequireValue = RoeNetIncome / (GetRsult["dr"] / 100);

      let RoeNVPRequiredValue = RoeRequireValue / (1 + Number(GetRsult["dr"]) / 100) ** 10;

      let npvDividends = npvList.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue;
      });
      let InstrinsValue = Number(RoeNVPRequiredValue) + Number(npvDividends);
      console.log("RoeNetIncome",npvList,RoeNVPRequiredValue);

      setIntvalue(InstrinsValue);
      setNincome(RoeNetIncome);
      setRv(RoeRequireValue);
      setnvprv(RoeNVPRequiredValue);
      setnvpd(npvDividends);
      setShow(true);
    
  }, [GetRsult])

  const ResultHeder =[{
    "Intrinsic Value ":intvalue ? 
      Number(intvalue).toFixed(2) : 0
   
  }]
  const resetValues = () =>{
    setIntvalue(0)
    setShe([])
setNincome(0)
setRv(0)
setnvprv(0)
setnvpd(0)
  }
console.log("intvalue",intvalue);
const { lightMode } = useSelector(getThemeMode);
const options = {
  scales: {
    y: {
      beginAtZero: true
    }
    
  },
  plugins:{
      legend:{
          position:"bottom",
          labels:{

          }
      },
  },

  maintainAspectRatio: false
}
// var data = {
//   labels: ["Chocolate", "Vanilla", "Strawberry","Strawberry1","Strawberry2","Strawberry", "Strawberry","Strawberry1","Strawberry2","Strawberry","Strawberry1","Strawberry2","Strawberry", "Strawberry","Strawberry1","Strawberry2"],
//   datasets: [
//       {
//           label: "Blue",
//           backgroundColor: "#6DB8FD",
//           data: [3,7,4,5,2,7,5,6,4,7,6,3,4,3,6,1],
          

//       },
//       {
//           label: "Red",
//           backgroundColor: "#1774FF",
//           data: [4,3,5,5,2,7,5,6,4,7,6,3,4,3,6,1],
          

//       },
//       {
//           label: "Yellow",
//           backgroundColor: "#8874FE",
//           data: [4,3,5,5,2,7,5,6,4,3,6,1,7,6,3,4],
          

//       },
//   ]
// };
const navigate = useNavigate()
const formlist = [
  {name:"shareeqty",value:"Shareholders Equity (in Cr)"},
  {name:"csp",value:"Current Share Price(in Rs)"},
  {name:"roe",value:"Return on Equity (3 Yrs Avg) (%)"},
  {name:"so",value:"Shares Outstanding (in Cr)"},
  {name:"dy",value:"Dividend Yield (%)"},
  {name:"dpr",value:"Dividend Payout Ratio (%)"},
  {name:"ms",value:"Margin Of Safety (%)"},
  {name:"dr",value:"Discount Ratio (%)"},
];
const valueData = [
  { key:"10 Year Net Income", value:nincome && nincome !== Infinity
  ? nincome?.toFixed(2).toLocaleString("en-US")
  : 0 },
  { key:"Required Value", value: rv && rv !== Infinity
  ? rv?.toFixed(2).toLocaleString("en-US")
  : 0 },
  { key:"NVP Required Value ", value:nvprv && nvprv !== Infinity
  ? nvprv?.toFixed(2).toLocaleString("en-US")
  : 0 },
  { key:"NVP Dividend", value: nvpd && nvpd !== Infinity
  ? nvpd?.toFixed(2).toLocaleString("en-US")
  : 0 },
];

const bottomData = [{
  header:"What is Return On Equity Valuation?",
  body:"Return on equity (ROE) is the amount of net income returned as a percentage of shareholders equity. ROE measures a corporation's profitability by revealing how much profit a company generates with the money shareholders have invested. In other words, ROE tells you how good a company is at rewarding its shareholders for their investment.  As a thumb rule, always invest in a company with ROE greater than 20% for at least the last 3 years. An yearly increase in ROE is also a good sign. Return on Equity (Net Income)/ (Average Stockholder Equity)"
},{
  header:"Shareholders Equity:",
  body:"Stockholders Equity (also known as Shareholders Equity) is an account on a company's balance sheet that consists of share capital plus retained earnings. It also represents the residual value of assets minus liabilities. By rearranging the original accounting equation, Assets = Liabilities + Stockholders Equity, it can also be expressed as: Stockholders Equity = Assets - Liabilities Stockholders Equity provides highly useful information when analysing financial statements. In events of liquidation, equity holders are later in line than debt holders to receive any payments. This means that bondholders are paid before equity holders. Therefore, debt holders are not very interested in the value of equity beyond the general amount of equity to determine overall solvency. Shareholders, however, are concerned with both liabilities and equity accounts because stockholders equity can only be paid after bondholders have been paid."
},{
  header:"Shares Outstanding:",
  body:"Shares outstanding refer to a company's stock currently held by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company's officers and insiders. Outstanding shares are shown on a company's balance sheet under the heading 'Capital Stock. 'The number of outstanding shares is used in calculating key metrics such as a company's market capitalization, as well as its earnings per share (EPS) and cash flow per share (CFPS). A company's number of outstanding shares is not static and may fluctuate wildly over time."
},{
  header:"Dividend Yield:",
  body:"The Dividend Yield is the amount of money a company pays shareholders (over the course of a year) for owning a share of its stock divided by its current stock price-displayed as a percentage. It is an estimated one-year return of an investment in a stock based only on the dividend payment. Note that many stocks do not pay dividends. Mature companies tend to pay dividends, with companies in the utility and consumer staple industries often paying higher dividend yields. Dividend Payout Ratio: Dividend Payout Ratio (DPR) is the amount of dividends paid to shareholders in relation to the total amount of net income the company generates. In other words, the dividend payout ratio measures the percentage of net income that is distributed to shareholders in the form of dividends."
},{
  header:"Dividend Payout Ratio:",
  body:"Dividend Payout Ratio (DPR) is the amount of dividends paid to shareholders in relation to the total amount of net income the company generates. In other words, the dividend payout ratio measures the percentage of net income that is distributed to shareholders in the form of dividends."
},{
  header:"Margin Of Safety:",
  body:"Margin Of Safety isa principle of investing in which an investor only purchases securities when their market price is significantly below their intrinsic value. In other words, when the market price of a security is significantly below your estimation of its intrinsic value, the difference is the margin of safety. Because investors may set a margin of safety in accordance with their own risk preferences, buying securities when this difference is present allows an investment to be made with minimal downside risk."
}]

var data = {
  labels: she?.map((items,i)=>{return  1+i}),
  datasets: [
    {
      label: "SHE",
      backgroundColor: "#6DB8FD",
      data:she,
    },
    {
      label: "Dividend",
      backgroundColor: "#1774FF",
      data: div,
    },
      {
      label: "Npv",
      backgroundColor: "#6DB8FD",
      data:npv,
    },
  
   
  ],
};
  return (
    <div className="ff-poppins max-w mx-auto h-100   px-15 relative">
      <div className="my-30">
      <p className='pointer' onClick={()=>navigate(-1)}>Calculators / Return On Equity Calculator</p>

        <p className="mb-0 fs-30-20 fw-700">Return On Equity Calculator</p>
        <p className="w-60-80">This is an essential and popular calculator that is used by investors to understand the efficiency of the company in utilising the capital employed
</p>

      </div>
      <div  className="mx-50-0 p-45-10 br-0 custom-input-dark">
      <div>
          <CalcInputFeilds resetValues={resetValues} setGetRsult={setGetRsult}   lightMode={lightMode} formlist={formlist}/>
      </div>
      <div>
          <ResultContainerCalc ResultHeder={ResultHeder}  resultLise={valueData} chartOptions={options} chartData={data} lightMode={lightMode}/>
      </div>
      </div>  
      <div>
          <BottomDataSection bottomData={bottomData}/>
      </div>  
    </div>
  );
}

export default ReturnOnEquityCalc;
