import React from "react";
import StockDetailsCard from "../../../components/StockDetailsCard";
import DividentGraphTable from "./DividentGraphTable";

function RatioGraphsGroup({ finRatioData, lightMode, notBankStock }) {
  const tooltiptextArr = {
    roe: {
      formula: "ROE = Net Income / Shareholders’ Equity",
      desc: " ROE is the return on your money invested into the company, which is calculated per share.",
    },
    roce: {
      formula: "EBIT/ Capital Employed",
      desc: " The ratio measures the comapanys profitability in terms of its capital which includes equity and debt.",
    },
    roce: {
      formula: "EBIT/ Capital Employed",
      desc: " The ratio measures the comapanys profitability in terms of its capital which includes equity and debt.",
    },
    roa: {
      formula: "Net Income / Total Assets",
      desc: "It measure how well the company is able to use its assets to generate profits.",
    },
    npm: {
      formula: "Net Profit ⁄ Total revenue x 100",
      desc: "The ratio calculates the percentage of profit a company produces from its total revenue after accounting for all its expenses.",
    },
    pe: {
      formula: "Current market price / Earnings per share",
      desc: "PE Ratio is the ratio used to measure current share price to its earnings per share.",
    },
    ev: {
      formula: "",
      desc: "It is used a valuation metric to compare the relative value of different businesses in a sector",
    },
    pb_ratio: {
      formula: "Market price per share / Book Value per share",
      desc: "P/B Value is the ratio of the current market value of the company shares to the book value of equity. It is used to estimate if the company is over or undervalued.",
    },
    price_flow: {
      formula: "Market capitalization / Total sales or Revenue",
      desc: "It measures the value of a stocks price relative to its operating cash flow per share",
    },
    debt_eqt: {
      formula: "Total Liabilities / Total shareholders equity",
      desc: "It measures the degree to which a company is financing its operations with debt rather than its own resources.",
    },
    curr_ratio: {
      formula: "Current Assets / Current Liabilities",
      desc: "It measures the companys ability to meet their short-term obligations which are to be repaid within a year",
    },
    icr: {
      formula: "EBIT / Interest Expenses",
      desc: "It measures the companys ability to pay interest on its outstanding debt",
    },
    quick_ratio: {
      formula:
        "Quick Assets (Cash + Cash Equivalents + Marketable Securities + Net Accounts Receivable) / Current Liabilities",
      desc: "It measures the companys ability to meet their short-term obligations with its most liquid assets",
    },
    net_npa: {
      formula: "",
      desc: "It shows proportion of loan or advances that are in default or arrears after adjusting for provisions ",
    },
    casa_ratio:{
      formula:'',
      desc:'This helps us find the banks profitability. It indicates how much of a bank’s total deposits are in both current and savings accounts.'
    },
    advance_growth:{
      formula:'',
      desc:'It shows the YoY Growth in advances given by a bank. Advances here refer to the loans they give to the public'
    },
    interest_margin:{
      formula:'',
      desc:'It is a measure of difference between interest income generated by banks and amount paid to their lenders. It is similar to the gross margin of non-financial companies.'
    }
  };
  return (
    <div className="pros-and-cons d-flex d-flex-col" id="Ratios">
      <h2 className="main-header">Ratios</h2>
      <h3 className="fs-20-16 fw-600 mb-30">Profitability</h3>
      <div className="card-grid-wrap">
        <StockDetailsCard
          title="ROE"
          data={finRatioData}
          respKey="roe"
          lightMode={lightMode}
          unit="%"
          tooltipText={tooltiptextArr.roe}
        />
        <StockDetailsCard
          title="ROCE"
          data={finRatioData}
          respKey="roce"
          lightMode={lightMode}
          unit="%"
          tooltipText={tooltiptextArr.roce}
        />
        <StockDetailsCard
          title="ROA"
          data={finRatioData}
          respKey="roa"
          lightMode={lightMode}
          unit="%"
          tooltipText={tooltiptextArr.roa}
        />
        <StockDetailsCard
          title="Net Profit Margin"
          data={finRatioData}
          respKey="net_margin"
          bottomTitle="NPM"
          lightMode={lightMode}
          unit="%"
          tooltipText={tooltiptextArr.npm}
        />
      </div>
      <h3 className="fs-20-16 fw-600 my-30">Valuation</h3>
      <div className="card-grid-wrap">
        <StockDetailsCard
          title="P/E Ratio"
          data={finRatioData}
          respKey="adj_pe"
          lightMode={lightMode}
          unit=""
          tooltipText={tooltiptextArr.pe}
        />
        <StockDetailsCard
          title="EV/EBIDTA"
          data={finRatioData}
          respKey="ev_ebitda"
          lightMode={lightMode}
          unit=""
          tooltipText={tooltiptextArr.ev}
        />
        <StockDetailsCard
          title="Price to Book Value"
          data={finRatioData}
          respKey="price_to_book_value"
          bottomTitle="PB value"
          lightMode={lightMode}
          unit=""
          tooltipText={tooltiptextArr.pb_ratio}
        />
        <StockDetailsCard
          title="Price to Cash Flow"
          data={finRatioData}
          respKey="net_margin"
          lightMode={lightMode}
          unit=""
          tooltipText={tooltiptextArr.price_flow}
        />
      </div>
      {notBankStock=='INSURANCE' ?"": <div>
      <p className="fs-20-16 fw-600 my-30">
        {notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "Liquidity" : "Operational Ratios"}
      </p>
      <div className="card-grid-wrap">
        <StockDetailsCard
          title={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "Debt to Equity" : "NET NPA"}
          data={finRatioData}
          respKey={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "debt_to_equity" : "net_npa"}
          lightMode={lightMode}
          unit=""
          tooltipText={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? tooltiptextArr.debt_eqt : tooltiptextArr.net_npa}
        />
        <StockDetailsCard
          title={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "Current Ratio" : "CASA Ratio"}
          data={finRatioData}
          respKey={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "current_ratio" : "casa"}
          lightMode={lightMode}
          unit=""
          tooltipText={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? tooltiptextArr.curr_ratio : tooltiptextArr.casa_ratio}
        />
        <StockDetailsCard
          title={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "Interest Coverage Ratio" : "Advance Growth"}
          data={finRatioData}
          respKey={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "interest_coverage_ratio" : "advance_growth"}
          bottomTitle={notBankStock==="OTHER"  ? "ICR" : "Growth"}
          lightMode={lightMode}
          unit=""
          tooltipText={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? tooltiptextArr.icr : tooltiptextArr.advance_growth}
        />
        <StockDetailsCard
          title={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "Quick Ratio" : "Net Interest Margin"}
          data={finRatioData}
          respKey={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? "quick_ratio" : "net_interest_margin"}
          lightMode={lightMode}
          unit=""
          tooltipText={notBankStock==="OTHER"||notBankStock==="INSURANCE"  ? tooltiptextArr.quick_ratio : tooltiptextArr.interest_margin}
        />
      </div></div>}
      <h3 className="fs-20-16 fw-600 my-30">Dividend</h3>
      <DividentGraphTable finRatioData={finRatioData} lightMode={lightMode} />
    </div>
  );
}

export default RatioGraphsGroup;
