import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getNews } from "../../api/fetchClient";
import TrendingStocks from "../../components/TrendingStocks";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";

import TopTrendingNews from "./TopTrendingNews";
import svgSheet from '../../assets/svg/svgSheet'
import MarketNewsList from "./EconamyNews";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";

function MarketNews({setisLoginPage}) {
  const { lightMode } = useSelector(getThemeMode);
  const [TrendingNews, setTrendingNews] = useState([])

  useEffect(() => {
    getNews("1","5","1789").then((res)=>{
      setTrendingNews(res.data)
    })
    setisLoginPage(false)
  }, [])


  return (
    <>
    <ReactHelmet
        title="Market News - Read all financial news at one portal"
        desc="Easiest platform to find stocks and perform stock market analysis. Trade Brains Portal helps investors do efficient stock research by providing quality data & insightful analysis."
      />
    <div className="my-body">
      <div className="ff-poppins w-100 max-w mx-auto px-15 h-95 ">
        <div className="d-flex align-items-center mb-30">
          <p style={{marginRight:"5px",marginTop:"5px"}} className="fs-36-20 mb-0 fw-700 d-flex align-items-center">{svgSheet.MarketNews} </p>
        <h1 className="fs-36-20  mb-0 fw-700 d-flex align-items-center lh-36" >Market News</h1>
        </div>
       <TopTrendingNews lightMode={lightMode}/>  
        <MarketNewsList lightMode={lightMode} id={1513} title={"Editors Picks"}/>
        <MarketNewsList lightMode={lightMode} id={1515} title={"Company Results"}/>
        <MarketNewsList lightMode={lightMode} id={1696} title={"Corporate Action"}/>
        <MarketNewsList lightMode={lightMode} id={1697} title={"Economy News"}/>
        <TrendingStocks lightMode={lightMode}/>
      </div>
    </div>
    </>
  );
}

export default MarketNews;
