import { Form, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAdminStar, addAdminStarCreate } from "../../../../api/fetchClient";
import BreadCrumb from "../../../../components/BreadCrumb";
import PorrtfolioFormNew from "./PorrtfolioFormNew";

function CreatePortfolioNew() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const breadCrumbData = [
    { label: "Superstar Portfolio 2.0", link: `/admin/star_portfolio_new` },
  ];
  const [keywordArr, setkeywordArr] = useState([]);
  const onSubmit = async (values) => {
    const postData = new FormData();
    Object.entries().map((res) => {
      postData.append(res[0], res[1]);
    });
    await addAdminStarCreate(postData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Portfolio Added successfully");
          navigate("/admin/star_portfolio_new");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="my-body px-30-0">
      <div className="mb-20">
        <BreadCrumb
          linkData={breadCrumbData}
          current={"Creation of Super star"}
        />
      </div>
      <div className="d-flex flex-col-row justify-content-between ">
        <h1 className="fs-27-16 fw-600 ">Superstar Creation</h1>
        <button
          style={{ minWidth: "150px" }}
          onClick={() => form.submit()}
          className="btn-bg-primary  p-10 h-45 fw-500 br-5 text-white"
        >
          Create
        </button>
      </div>
      <PorrtfolioFormNew
        formSubmit={onSubmit}
        form={form}
        keywordArr={keywordArr}
        setkeywordArr={setkeywordArr}
      />
    </div>
  );
}

export default CreatePortfolioNew;
