import { Select } from "antd";
import React from "react";
import { useState } from "react";

function SelectOptions({
  lightMode,
  data,
  setselectd,
  defaultSelect,
  width,
  placeholder,
}) {
  const { Option } = Select;
  const handleChange = (value) => {
    setselectd(value);
  };
  return (
    <div>
      <Select
        className={
          lightMode
            ? "br-5 border1px-light-mode"
            : "br-5 antd-Selete-Custom-dark border1px-dark-mode"
        }
        defaultValue={defaultSelect}
        dropdownClassName={`${!lightMode && "drop-down-stock invert-text"}`}
        getPopupContainer={(trigger) => trigger.parentNode}
        onChange={handleChange}
        placeholder={placeholder}
        style={{
          width: width,
        }}
      >
        {data.map((items, i) => (
          <Option key={i} value={items.value}>
            {items.label}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default SelectOptions;
