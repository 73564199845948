import { RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getPlans, PostPlanSubScription } from "../../api/fetchClient";
import CouponsList from "../../routers/CouponsList";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { useSelector } from "react-redux";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import CouponAppliedModel from "./CouponAppliedModel";
function Plansummery() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [Visible, setVisible] = useState(false);
  const [PlanData, setPlanData] = useState();
  const [SortedPlan, setSortedPlan] = useState();
  const [AppliedCoupon, setAppliedCoupon] = useState();
  const { lightMode } = useSelector(getThemeMode);
  const [AppliedSuccess, setAppliedSuccess] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [couponerr, setcouponerr] = useState("");
  useEffect(() => {
    if (location?.search?.includes("event=true")) {
      localStorage.setItem(
        "eventRedirect",
        `/${location.pathname?.substring(1)}${location.search}`
      );
    }
    getPlans().then((res) => {
      setPlanData(res.data);
    });
    if (PlanData === undefined) {
      return;
    }
  }, []);
  console.log(AppliedCoupon,"AppliedCoupon");
  const PoseSubScription = () => {
    localStorage.removeItem("eventRedirect");
    const data = {
      plan: params.id,
      coupon_code: AppliedCoupon
        ? AppliedCoupon?.code
        : searchParams?.get("event")
        ? searchParams?.get("coupon")
        : "",
      // coupon_code: AppliedCoupon ? AppliedCoupon?.code:"",
      status: "",
      code: searchParams?.get("code") ?? "",
      // coupon:searchParams?.get('coupon')??''
    };
    setLoading(true);
    PostPlanSubScription(data)
      .then((res) => {
        setLoading(false);
        setcouponerr("");
        if (res.data.request_url) {
          window.location.href = res.data.request_url;
        }
      })

      .catch((err) => {
        console.log(err);
        setcouponerr(err?.response?.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (PlanData) {
      PlanData.filter(
        (items) => items?.plan_id === params?.id && setSortedPlan(items)
      );
    }
  }, [PlanData]);

  const arrayOFPlan = {
    basic: "Life Time Free",
    "3months": "3 Months",
    "6months": "6 Months",
    year: "1 Year",
  };

  return (
    <div className="my-body">
      <div className="d-flex flex-col ff-poppins max-w mx-auto d-flex  px-15  w-100 relative">
        <div className="w-100 d-flex flex-col-row justify-content-between">
          <div className="w-100-50">
            <div>
              <p className="fs-36-20 fw-700">Payment Summary</p>
              <p className="fs-20-16 fw-500">Billing Plan</p>
            </div>

            <div className="d-flex justify-content-between mb-10">
              <div className="d-flex flex-col">
                <p className="fs-36-20 fw-700 mb-0 gradient-text-blue">
                  {SortedPlan?.plan_sname}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="fw-500 mb-0 fs-34-20 ff-lato fw-700">
                  ₹ {numberWithCommas(SortedPlan?.plan_amount)}{" "}
                </p>{" "}
                <p className="fw-400 mb-0  fs-18-14  ml-5">
                  {" "}
                  / {arrayOFPlan[params.id]}
                </p>
              </div>
            </div>
            {/* {params.id==="year"&&
            <p style={{width:'80%'}}>& Fingrad’s 1 year free subcription worth of <span className="ff-lato fw-600">₹1,999</span></p>
           }  */}

            {/* applied coupen */}
            {AppliedCoupon && searchParams?.get("event") === null ? (
              <div
                className={` pointer d-flex justify-content-between align-items-center   p-20  ${
                  lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
                }`}
              >
                <div className="d-flex flex-col justify-content-between ">
                  <div className="d-flex mb-10">
                    {" "}
                    <p className="fs-20-16 fw-700 mb-0 gradient-text-blue">
                      {AppliedCoupon?.code}{" "}
                    </p>
                    <p className="fs-20-16 fw-700 mb-0 ml-5">Applied</p>
                  </div>
                  <p>{AppliedCoupon?.description}</p>
                </div>

                <div>
                  <p
                    style={{ color: redGreenColorPicker(-1, lightMode) }}
                    className="mb-0"
                    onClick={() => setAppliedCoupon("")}
                  >
                    Remove
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    // params.id==="3months"||
                    opacity: searchParams?.get("event") ? 0.5 : 1,
                  }}
                  // add next line when festive offer available
                  onClick={() =>
                    searchParams?.get("event") === null &&
                    // &&params.id!=="3months"
                    setVisible(true)
                  }
                  // add next line when festive offer not available
                  // onClick={() =>setVisible(true)}
                  className={` pointer d-flex flex-col justify-content-between  p-20 ${
                    lightMode
                      ? "card-drop-light-shadow"
                      : "card-drop-dark-shadow"
                  }`}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="fs-20-16 fw-500 mb-0">Apply Coupon</p>
                    <RightOutlined />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`p-30 d-flex flex-col w-40-100   ${
              lightMode ? "card-drop-light-shadow" : "card-drop-dark-shadow"
            }`}
          >
            <div className="d-flex justify-content-between">
              <p className="fs-18-16">Plan Value</p>
              <p className="fs-18-14   fw-700  ff-lato">
                ₹ {numberWithCommas(SortedPlan?.plan_amount)}
              </p>
            </div>
            <div className="d-flex justify-content-between bb-1-gray">
              <p className="fs-18-16">Discount Value</p>
              {/* (AppliedCoupon?.value/100)*SortedPlan?.plan_amount ) */}
              <p
                style={{ color: redGreenColorPicker(1, lightMode) }}
                className="fs-18-14  fw-700 ff-lato"
              >
                - ₹{" "}
                {AppliedCoupon?.type === "percentage"
                  ? (
                      (AppliedCoupon?.value / 100) *
                      SortedPlan?.plan_amount
                    ).toFixed(2)
                  : AppliedCoupon?.type === "monetary"
                  ? AppliedCoupon?.value
                  : !AppliedCoupon && 0}{" "}
              </p>
            </div>

            <div className="d-flex mt-10 justify-content-between">
              <p className="fs-18-16">Total</p>
              <p className="fs-18-14  fw-700 ff-lato">
                ₹{" "}
                {AppliedCoupon?.type === "percentage"
                  ? (
                      SortedPlan?.plan_amount -
                      (AppliedCoupon?.value / 100) * SortedPlan?.plan_amount
                    ).toFixed(2)
                  : AppliedCoupon?.type === "monetary"
                  ? numberWithCommas(
                      SortedPlan?.plan_amount - AppliedCoupon?.value
                    )
                  : !AppliedCoupon && numberWithCommas(SortedPlan?.plan_amount)}
              </p>
            </div>

            <div className="d-flex mt-10 justify-content-between">
              <p className="fw-700 fs-24-16">Payable Amount</p>
              <p className="fs-18-14  fw-700 ff-lato">
                ₹{" "}
                {AppliedCoupon?.type === "percentage"
                  ? (
                      SortedPlan?.plan_amount -
                      (AppliedCoupon?.value / 100) * SortedPlan?.plan_amount
                    ).toFixed(2)
                  : AppliedCoupon?.type === "monetary"
                  ? numberWithCommas(
                      SortedPlan?.plan_amount - AppliedCoupon?.value
                    )
                  : !AppliedCoupon && numberWithCommas(SortedPlan?.plan_amount)}
              </p>
            </div>
            {couponerr.error && (
              <p className="mx-auto text-red fw-500">{couponerr?.error}</p>
            )}
            <button
              onClick={() => PoseSubScription()}
              className="btn-bg-primary text-white fs-18-14 fw-600 w-100 h-45 br-5"
            >
              {Loading ? "Processing" : "Proceed to Pay"}
            </button>
            <div className="mt-10">
              <p className={`text-gray `}>* Inclusive of all taxes</p>
            </div>
          </div>
        </div>
      </div>
      <CouponsList
        setAppliedCoupon={setAppliedCoupon}
        plan_id={params?.id}
        setVisible={setVisible}
        visible={Visible}
        setAppliedSuccess={setAppliedSuccess}
        CouponDiscount={
          AppliedCoupon?.type === "percentage"
            ? ((AppliedCoupon?.value / 100) * SortedPlan?.plan_amount).toFixed(
                2
              )
            : AppliedCoupon?.type === "monetary"
            ? AppliedCoupon?.value
            : !AppliedCoupon && 0
        }
        AppliedCoupon={AppliedCoupon}
        SortedPlan={SortedPlan}
        festiveStatus={searchParams?.get("event")}
        festiveCoupon={searchParams?.get("coupon")}
      />
      <CouponAppliedModel
        setVisible={setAppliedSuccess}
        visible={AppliedSuccess}
        CouponDiscount={
          AppliedCoupon?.type === "percentage"
            ? ((AppliedCoupon?.value / 100) * SortedPlan?.plan_amount).toFixed(
                2
              )
            : AppliedCoupon?.type === "monetary"
            ? AppliedCoupon?.value
            : !AppliedCoupon && 0
        }
        AppliedCoupon={AppliedCoupon}
      />
    </div>
  );
}

export default Plansummery;
