import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PremiumRedirectModal from '../components/PremiumRedirectModal';
import { authStore } from '../redux/reducers/authSlice';

function PremiumRouter({children}) {
  const [visible, setVisible] = useState(true)
  const UserAuth = useSelector(authStore);


    if (UserAuth?.userData?.user?.plan?.planId === 'basic') {
        return <PremiumRedirectModal visible={visible} setVisible={setVisible}/>;
      }
      return children;
}

export default PremiumRouter