import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import LeftSidebar from "./sidebar/LeftSidebar";
import svgSheet from "../../assets/svg/svgSheet";
import Bucketcard from "./BucketCard";
import SideHeader from "./SideHeader";
import MobileBottomBar from "../stock details/MobileBottomBar";
import TrendingStocks from "../../components/TrendingStocks";
import { getBucketdata } from "../../api/fetchClient";
import DotLoader from "../../components/spinners/DotLoader";
import demoImg from "../../assets/images/Demo/dronedemo.png";
import { useNavigate } from "react-router-dom";
import { authStore } from "../../redux/reducers/authSlice";
import PremiumRedirectModal from "../../components/PremiumRedirectModal";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
function BucketIndex() {
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const [Bucketdata, setBucketdata] = useState();
  const [premiumModal, setpremiumModal] = useState(false)
  const navigate = useNavigate();

  // get all buckets
  useEffect(() => {
    const data = () => {
      getBucketdata().then((res) => {
        setBucketdata(res.data.buckets);
      });
    };
    data();
  }, []);

  // divide to different categories
  const sectorDataGroup = useMemo(() => {
    const thematicArr = Bucketdata?.filter(
      (bucket) => bucket.category === "Thematic Stocks"
    );
    const SectorialArr = Bucketdata?.filter(
      (bucket) => bucket.category === "Sectorial Stocks"
    );
    const BusinessArr = Bucketdata?.filter(
      (bucket) => bucket.category === "Business Groups"
    );
    return {
      thematic: thematicArr?.slice(0, 8),
      sectorial: SectorialArr?.slice(0, 8),
      bussiness: BusinessArr?.slice(0, 8),
    };
  }, [Bucketdata]);
console.log("sectorDataGroup",sectorDataGroup);
  // side and bottom bar data
  const sidebarData = [
    {
      id: "Thematic",
      icon: svgSheet.thematicIcon,
      label: "Thematic",
      offset: -240-bannerOffsetCalc(UserAuth),
    },
    {
      id: "Sectorial",
      icon: svgSheet.pieIcon,
      label: "Sectorial",
      offset: -127-bannerOffsetCalc(UserAuth),
    },
    {
      id: "BusinessHouse",
      icon: svgSheet.bussinessIcon,
      label: "Business Groups",
      offset: -127-bannerOffsetCalc(UserAuth),
    },
  ];
  const topBuckets = [
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/drone+stocks.png",
      label: "Drone Stocks",
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/5G.png",
      label: "5G Stocks",
      isPremium:true
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Semiconductor+stocks.png",
      label: "Semiconductor Stocks",
      isPremium:true
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Bluechip+stocks.png",
        label: "Blue Chip Stocks",
      isPremium:true
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/EV+Stocks.png",
      label: "Electric Vehicles (EV) India",
      isPremium:true
    },
    {
      image:
        "https://tradebrains-portal.s3.ap-south-1.amazonaws.com/Buckets/Thematic/Navratan+stocks.png",
      label: "Navratna Stocks",
    },
  ];

  const cardNavigate = (isPremium, bucketname) => {
    if (isPremium && !UserAuth?.userData?.user?.is_premium) {
      // navigate(`/getpremium`);
  setpremiumModal(true)
    } else {
      navigate(`/buckets/${bucketname}`, {
        state: null,
      });
    }
  };
  if (!sectorDataGroup.thematic) {
    return <DotLoader />;
  }

  return (
    <>
     <ReactHelmet
        title="Stock Buckets by Trade Brains Portal - Hand picked stocks exclusive for you"
        desc="Stock Buckets by Trade Brains offers curated collection of best stocks organized for you to simplify your stock research. Start investing with readymade stocks."
      />
      <div className="ff-poppins max-w mx-auto  px-15  w-100">
        <div className="my-body">
          <div className="d-flex align-items-center mb-30">
            <p className="mr-10 w-m-28 mt-5 mb-0">{svgSheet.bucketLogo}</p>
            <h1 className="mb-0 fs-36-20 fw-700">Buckets</h1>
          </div>
          {/* <p className=" mb-0 fs-36-20 fw-700 mb-15 line-h-36">Buckets</p> */}
          <div className="d-flex">
            <div
              style={{ width: "20%", top:`${130+bannerOffsetCalc(UserAuth)}px`, height: "min-content" }}
              className="detail-left-card p-fixed"
            >
              <LeftSidebar
                lightMode={lightMode}
                sidebarData={sidebarData}
                subHead="Top Buckets"
                subData={topBuckets}
                baseURL="/buckets"
                premiumCheck={UserAuth?.userData?.user?.is_premium}
                setpremiumModal={setpremiumModal}
              />
            </div>
            <div className=" w-75-100 ml-30-0" style={{ marginBottom: "40px" }}>
              <div className="mb-30" id="Thematic">
                <SideHeader icon={svgSheet.thematicIcon} label="Thematic" />
                {/* thematic  */}
                <div className="bucket-card-wrap">
                  {sectorDataGroup?.thematic?.map((bucket, i) => (
                    <Bucketcard
                      key={i}
                      isPremium={
                        bucket.premium && !UserAuth?.userData?.user?.is_premium
                          ? true
                          : false
                      }
                      bucketname={bucket.title}
                      stockNum={bucket.stocks.length}
                      cardImg={bucket?.image}
                      clickFn={() => cardNavigate(bucket.premium, bucket.title)}
                    />
                  ))}
                </div>
                {/* thematic  */}
              </div>
              <div className="mb-30" id="Sectorial">
                <SideHeader icon={svgSheet.pieIcon} label="Sectorial" />
                {/* sectorial  */}
                <div className="bucket-card-wrap">
                  {sectorDataGroup?.sectorial?.map((bucket, i) => (
                    <Bucketcard
                      key={i}
                      isPremium={
                        bucket.premium && !UserAuth?.userData?.user?.is_premium
                          ? true
                          : false
                      }
                      bucketname={bucket.title}
                      stockNum={bucket.stocks.length}
                      cardImg={bucket?.image}
                      clickFn={() => cardNavigate(bucket.premium, bucket.title)}
                    />
                  ))}
                </div>
                {/* sectorial  */}
              </div>
              <div id="BusinessHouse">
                <SideHeader
                  icon={svgSheet.bussinessIcon}
                  label="Business Groups"
                />
                <div className="bucket-card-wrap">
                  {/* bussiness houses  */}
                  {sectorDataGroup?.bussiness?.map((bucket, i) => (
                    <Bucketcard
                      key={i}
                      isPremium={
                        bucket.premium && !UserAuth?.userData?.user?.is_premium
                          ? true
                          : false
                      }
                      bucketname={bucket.title}
                      stockNum={bucket.stocks.length}
                      cardImg={bucket?.image}
                      clickFn={() => cardNavigate(bucket.premium, bucket.title)}
                    />
                  ))}
                </div>
                {/* bussiness houses  */}
              </div>
            </div>
          </div>
          <TrendingStocks lightMode={lightMode} />
        </div>
      </div>
      <MobileBottomBar navData={sidebarData} lightMode={lightMode} />
    <PremiumRedirectModal  visible={premiumModal} setVisible={setpremiumModal} />
    </>
  );
}

export default BucketIndex;
