import { CaretUpOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { getStockLTP } from "../../api/fetchClient";
import svgSheet from "../../assets/svg/svgSheet";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import Movetowatchlist from "../../components/Movetowatchlist";
import numberWithCommas from "../../utilityFn/numberWithCommas";
function SimilarStockCard({ lightMode, symbol, comp_name }) {
  const [visible, setVisible] = useState(false);
  const [stockdata, setstockdata] = useState({});
  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    if (symbol !== undefined) {
      getStockLTP("stock", symbol).then((res) => {
        setstockdata(res.data[0]);
      });
    }
  }, [symbol]);
  return (
    <div
      style={{ marginInline: "5px", minHeight: "100px", minHeight: "125px" }}
      className={`br-3 p-10  d-flex flex-col justify-content-between ${
        lightMode ? "border2px-light-mode" : "border2px-dark-mode"
      }`}
    >
      <div className="d-flex justify-content-between">
        <Link to={`/stock/${symbol}/consolidated`} className="mb-0 fs-s-12">
          {comp_name}
        </Link>
        {/* popover  */}
        <div className="popup-div">
          <Movetowatchlist lightMode={lightMode} symbol={[symbol]}>
          <div className="pointer square-18-14">{svgSheet.SaveForLaterFilled}</div>
          </Movetowatchlist>
        </div>
        {/* popover  */}
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <p className="mb-0 fw-600 ff-lato">
            ₹ {numberWithCommas(stockdata?.close)}
          </p>
          <p className="fw-600 mb-0">{symbol}</p>
        </div>
        <div className="d-flex align-self-end">
          <CaretUpOutlined
            className="d-flex align-self -end"
            style={{
              color: redGreenColorPicker(stockdata?.percent, lightMode),
              display: "flex",
              alignSelf: "center",
            }}
            rotate={stockdata?.percent < 0 ? 180 : 0}
          />
          <span
            style={{ color: redGreenColorPicker(stockdata?.percent, lightMode) }}
            className={`ff-lato fw-500 `}
          >
            {stockdata?.percent}%
          </span>
        </div>
      </div>
    </div>
  );
}

export default SimilarStockCard;
