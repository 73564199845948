import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StockEndpoints from "../../../api/endPoints";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomTable from "../../../components/CustomTable";
import DotLoader from "../../../components/spinners/DotLoader";
import useGetFetch from "../../../hooks/useGetFetch";
import { getThemeMode } from "../../../redux/reducers/ThemeSlice";
import { getFormatDate } from "../../../utilityFn/getFormatdate";
import numberWithCommas from "../../../utilityFn/numberWithCommas";
// import {createTableHeader} from './CorporateActionsStock'
function ViewAllCorpAction() {
  const { lightMode } = useSelector(getThemeMode);
  const { symbol, keyName } = useParams();
  const {
    isLoading,
    apiData: actionData,
    serverError,
  } = useGetFetch(StockEndpoints.stockActions(symbol));

  const createTableHeader = (data) => {
    const removeName =
      data &&
      Object?.keys(data)?.filter((item) => item.includes("_name") === false);
    const headerResult =
      removeName &&
      removeName?.map((key) => {
        return {
          title: (
            <p className="fw-600 mb-0 fs-s-16">
              {data[`${key}_name`] ? data[`${key}_name`] : key}
            </p>
          ),
          dataIndex: key,
          width: "30px",
          render(text, index) {
            return {
              props: {
                style: lightMode
                  ? {
                      background: "rgba(242, 246, 248, 1)",
                      borderColor: "#C9D7DE",
                      borderRight: "1px solid #C9D7DE",
                      borderBottom: "none",
                    }
                  : {
                      background: "#3B3F4F",
                      borderLeft: "1px solid #545E78",
                      borderRight: "1px solid #545E78",
                      borderBottom: "none",
                    },
              },
              children: (
                <span
                  className="ff-lato"
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: lightMode ? "black" : "white",
                  }}
                >
                  {(key.includes("date")
                    ? getFormatDate(text)
                    : numberWithCommas(text)) ?? "NA"}
                </span>
              ),
            };
          },
        };
      });
    return headerResult;
  };
  const breadCrumbData = [
    { label: "Stock Details", link: `/stock/${symbol}/consolidated` },
  ];
  if (isLoading) {
    return <DotLoader />;
  }
  return (
    <div className="ff-poppins max-w mx-auto  h-100 px-15">
      <div className="my-body">
        <h2
          style={{ color: "inherit" }}
          className=" fs-36-20 fw-700 mb-10 line-h-36"
        >
          {keyName}
        </h2>
        <div className="mb-20">
          <BreadCrumb linkData={breadCrumbData} current={keyName} />
        </div>
        <div
          className={`w-100 table-shadow my-20 ${
            lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
          }`}
        >
          <CustomTable
            columns={createTableHeader(actionData && actionData?.[keyName][0])}
            scrollable={true}
            data={actionData && actionData[keyName]}
            lightMode={lightMode}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewAllCorpAction;
