import React, { useState } from "react";
import BottomSection from "./BottomSection";
import TopSection from "./TopSection";

function MarketWatchIndex({ lightMode }) {

  return (
    <section style={{ gap: "40px" }} className="d-flex flex-col">
      <TopSection lightMode={lightMode} />
      <BottomSection lightMode={lightMode} />
    </section>
  );
}

export default MarketWatchIndex;
