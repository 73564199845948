const screenerFormula = {
  bluechipScreen: [
    {
      label: "Market Cap (Rs Cr)",
      firstValue: "30000",
      secondValue: 2500000,
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
    {
      label: "Debt To Equity",
      firstValue: 0,
      secondValue: "0.5",
      particular: "Total_Debt_Equity",
      operator: "&",
      min: 0,
      max: 10,
      unit: "",
      symbol: "",
    },
    {
      label: "ROE 3 Yr(%)",
      firstValue: "12",
      secondValue: 100,
      particular: "ROE_3",
      operator: "&",
      min: 0,
      max: 100,
      unit: "",
      symbol: "%",
    },
    {
      label: "Current Ratio",
      firstValue: "1",
      secondValue: 10,
      particular: "Current_Ratio",
      operator: "&",
      min: 0,
      max: 10,
      unit: "",
      symbol: "",
    },
    {
      label: "ROA 3 Yr(%)",
      firstValue: "12",
      secondValue: 100,
      particular: "ROA_3",
      operator: "&",
      min: 0,
      max: 100,
      unit: "",
      symbol: "%",
    },
    {
      label: "Revenue Growth 3Yr(%)",
      firstValue: "10",
      secondValue: 500,
      particular: "Net_sales_cagr_3",
      operator: "&",
      min: -500,
      max: 500,
      unit: "",
      symbol: "%",
    },
  ],
  debtFreeScreen: [
    {
      label: "Market Cap (Rs Cr)",
      firstValue: "500",
      secondValue: 2500000,
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
    {
      label: "Debt To Equity",
      firstValue: 0,
      secondValue: "0",
      particular: "Total_Debt_Equity",
      operator: "&",
      min: 0,
      max: 10,
      unit: "",
      symbol: "",
    },
  ],
  pennyScreen: [
    {
      label: "Market Cap (Rs Cr)",
      firstValue: "0",
      secondValue: "100",
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
    {
      label: "Current Price (Rs)",
      firstValue: 0,
      secondValue: "10",
      particular: "PRICE",
      operator: "&",
      min: 0,
      max: 100000,
      unit: "",
      symbol: "rs",
    },
    {
      label: "ROE 1 Yr(%)",
      firstValue: "10",
      secondValue: 100,
      particular: "ROE",
      operator: "&",
      min: 0,
      max: 100,
      unit: "",
      symbol: "%",
    },
  ],
  dividentScreen: [
    {
      label: "Market Cap (Rs Cr)",
      firstValue: "500",
      secondValue: 2500000,
      particular: "MCAP",
      operator: "&",
      min: 0,
      max: 2500000,
      symbol: "rs",
      unit: "Cr",
    },
    {
      label: "Dividend Yield(%)",
      firstValue: "1.5",
      secondValue: 30,
      particular: "YIELD",
      operator: "&",
      min: 0,
      max: 30,
      unit: "",
      symbol: "%",
    },
    {
      label: "Dividend Payout(%)",
      firstValue: 0,
      secondValue: "70",
      particular: "Dividend_Payout_Per",
      operator: "&",
      min: 0,
      max: 100,
      unit: "",
      symbol: "%",
    },
    {
      label: "Net Profit Growth 3Yr(%)",
      firstValue: "15",
      secondValue: 500,
      particular: "Profit_after_tax_cagr_3",
      operator: "&",
      min: -500,
      max: 500,
      unit: "",
      symbol: "%",
    },
    {
      label: "Revenue Growth 1Yr(%)",
      firstValue: "15",
      secondValue: 500,
      particular: "Net_sales_cagr_1",
      operator: "&",
      min: -500,
      max: 500,
      unit: "",
      symbol: "%",
    },
  ],
};
export default screenerFormula;
