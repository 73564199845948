import { CaretUpOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import StockEndpoints from "../../api/endPoints";
import svgSheet from "../../assets/svg/svgSheet";
import ReactHelmet from "../../components/react-helmet/ReactHelmet";
import useGetFetch from "../../hooks/useGetFetch";
import { authStore } from "../../redux/reducers/authSlice";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import { bannerOffsetCalc } from "../../utilityFn/bannerOffsetcalc";
import StockFaq from "../stock details/StockFaq";
import GainerLoserTable from "./component/GainerLoserTable";
import IndexDropdown from "./component/IndexDropdown";
import IndexLeftBar from "./component/IndexLeftBar";

function GainersIndex({setisLoginPage}) {
  const { lightMode } = useSelector(getThemeMode);
  const UserAuth = useSelector(authStore);
  const [activeIndex, setactiveIndex] = useState({
    symbol: "NIFTY",
    label: "NIFTY 50",
  });
  useEffect(() => { 
    setisLoginPage(false)
  }, [])
  const {
    isLoading: loading,
    apiData: faqData,
    serverError: faqErr,
  } = useGetFetch(StockEndpoints.getFaq(`gainers/?index=${activeIndex.symbol}`));
  console.log('faqq',faqData);
  // get all gainers
  console.log(activeIndex);
 const convertFaq=useMemo(() => {
 const testdata=faqData?.map((item)=>{
  return {
    head:item.question,
    content:item.answer
  }
 })
 return testdata
 }, [faqData,activeIndex])
 
  return (
    <>
     <ReactHelmet
        title="Top Gainers Today | BSE NSE Gainers Companies List | BSE NSE Gainers Stock List"
        desc="Complete List Top Gainers across Sensex, Nifty and every other Indian Index at one glance!"
      />
    <div className="ff-poppins max-w mx-auto  h-100 px-15">
      <div className="my-body">
        <div className="d-flex align-items-center  mb-30">
          <p className="mr-10 lh-36  mb-0 d-flex align-self-center">
            {svgSheet.gainersLogo}
          </p>
          <h1 className="mb-0 fs-36-20 fw-700 lh-36">
            Top Gainers{" "}
            <CaretUpOutlined
              style={{
                color: lightMode ? "#009633" : "#00FF57",
              }}
            />
          </h1>
        </div>

        <div className="d-flex">
          <div
            style={{ width: "20%", top: `${130+bannerOffsetCalc(UserAuth)}px`, height: "min-content" }}
            className="detail-left-card p-fixed"
          >
            <IndexLeftBar
              lightMode={lightMode}
              setactiveIndex={setactiveIndex}
              activeIndex={activeIndex}
            />
          </div>
          <div className=" w-75-100 ml-30-0">
            <div className="mb-20 d-flex justify-content-end">
              <IndexDropdown
                lightMode={lightMode}
                // data={sectorName}
                navigate={setactiveIndex}
                activeValue={activeIndex}
              />
            </div>
            <GainerLoserTable
              lightMode={lightMode}
              activeIndex={activeIndex}
              apiEndpoint="gainers"
            />
            <StockFaq data={convertFaq} lightMode={lightMode} />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default GainersIndex;
