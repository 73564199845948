import React, { useMemo } from "react";
import StockEndpoints from "../../../api/endPoints";
import DoughnutWithLabels from "../../../components/Charts/DoughnutWithLabels";
import useGetFetch from "../../../hooks/useGetFetch";
import { ArrayofSectors } from "../../Portfolio Analysis/ArrayofSectors";

function SectorDiverse({ lightMode, name }) {
  const {
    isLoading,
    apiData: sectorData,
    serverError,
  } = useGetFetch(StockEndpoints.starSectorGraph(name));
  //  replace long text to small
  const createGraphData = useMemo(() => {
    if (sectorData === null) return;
    const labeluniq = Object?.keys(sectorData);
    const data = [];
    const valuedata = [];
    const returnValue = labeluniq.map((uniq) => {
      ArrayofSectors.map((items) => {
        items.apiValue === uniq && data.push(items.shortValue);
        items.apiValue === uniq && valuedata.push(items.apiValue);
      });
    });
    // return array of values
    const fixedValues = valuedata.map((uniq) => {
      return Number(sectorData[uniq].toFixed(2));
    });
    return { data, value: fixedValues };
  }, [sectorData]);

  return (
    <section>
      {" "}
      <DoughnutWithLabels
        isIndustry={true}
        divID="industry"
        labelArray={createGraphData?.data}
        lightMode={lightMode}
        totalValue={100}
        dataArray={createGraphData?.value}
      />
    </section>
  );
}

export default SectorDiverse;
