import React, { useEffect, useState } from "react";
import StockEndpoints from "../../../api/endPoints";
import { getStockRelatedNewsDate } from "../../../api/fetchClient";
import {
  getFormatDate,
  getFormatDateAgo,
} from "../../../utilityFn/getFormatdate";

function GetNewscard({ postId, lightMode }) {
  const [newsdata, setNewsdata] = useState({});
  const defaultImg =
    "https://tradebrains.in/features/wp-content/uploads/2022/07/Portal-Logo-Cover-Image.jpg";
  useEffect(() => {
    const getData = async () => {
      await getStockRelatedNewsDate(
        StockEndpoints.stockRelatedDateApi(postId)
      ).then((resp) => {
        setNewsdata(resp.data);
      });
    };
    getData();
  }, [postId]);

  const convertTitleUnicode = newsdata?.title?.rendered
    ?.replaceAll("&#8217;", '‘')
    .replaceAll("#038;", "&")
    .replaceAll("&#8216;", "")
    .replaceAll(";", ",");
  return (
    <div
      id={postId}
      className={` w-100  pb-10px ${
        lightMode ? "bb1px-light-mode" : "bb1px-dark-mode"
      }`}
    >
      <a href={newsdata?.link} target="_blank">
        <div className="new data d-flex mt-20">
          <div className="image-div-stock-news">
            <img
              className="h-100 w-100"
              src={
                newsdata?.yoast_head_json?.og_image
                  ? (newsdata?.yoast_head_json?.og_image)[0]["url"]
                  : defaultImg
              }
              alt="thumbnail"
            />
          </div>
          <div className="d-flex flex-col  ml-10 justify-content-between w-100">
            <div className="px-10px">
              <p className="fs-s-14">{convertTitleUnicode}</p>
            </div>
            <div className="d-flex mb-3">
              <p className="fs-s-12 m-0 brb-1 mr-2 px-10px ">
                {getFormatDate(newsdata?.date)}
              </p>
              <p className="fs-s-12 m-0 px-10px">
                {getFormatDateAgo(newsdata?.date)}
              </p>
            </div>
          </div>
        </div>
      </a>
      {/* <hr className='mt-15 mb-15'/> */}
    </div>
  );
}

export default GetNewscard;
