import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StockEndpoints from "../../../../../api/endPoints";
import svgSheet from "../../../../../assets/svg/svgSheet";
import useGetFetch from "../../../../../hooks/useGetFetch";
import { getThemeMode } from "../../../../../redux/reducers/ThemeSlice";
import bgGrad from "../../../../../assets/images/bg/getStartedPlans.png";
import { Link, useNavigate } from "react-router-dom";
import  { authStore, setAuth } from "../../../../../redux/reducers/authSlice";

function AffiliateStatus() {
  const { lightMode } = useSelector(getThemeMode);
  const [affiliateStatus, setaffiliateStatus] = useState({})
  const {userData}=useSelector(authStore)
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {
    isLoading,
    apiData,
    serverError,
  } = useGetFetch(StockEndpoints.postAffiliate);
  useEffect(() => {
  if(apiData?.error==="pending"){
    setaffiliateStatus({
      icon:svgSheet.blueHeartIcon,
      head:'Thank you for applying to be a Portal’s Affiliate',
      desc:(
        <div className="prevent-text">
         <p className="fs-18-14 my-30">Your form has been successfully submitted.</p>
          <p className="fs-18-14 my-40">
            You will <span className="fw-600">receive an confirmation email</span> once your <span className="fw-600"> profile has been
            approved</span> and you will be provided with an affliate dashboard to track
            all responses and payments.
          </p>
          <p className="fs-18-14 text-blue-grad mb-0">We will get back to you soon.</p></div>
      )
    })
  }
  else if(apiData?.is_verified==="accepted"){
    setaffiliateStatus({
      icon:svgSheet.blueHeartIcon,
      head:'Congratulations',
      desc:(
        <>
          <p className="fs-18-14 my-30">
          Your affliate Application has successfully been approved
          </p>
          <div className="z-10"  >
         <button   
         onClick={()=>{
             dispatch(
              setAuth({
                access_token: userData.access_token,
                refresh_token: userData.refresh_token,
                user: {...userData.user,is_affiliate:true}
              }))
              navigate('/affiliate')
          }}
           className="w-200px  h-45 btn-bg-primary fw-500 py-10 br-5  h-42 text-white pointer">Go to Affiliate Dashboard</button>
         </div>
         </>
      )
    })
  }
  else if(apiData?.error==="rejected"){
    setaffiliateStatus({
      icon:svgSheet.holdupIcon,
      head:'Hold Up',
      desc:(
        <>
          <p className="fs-18-14 my-30">
          We are sorry to inform you that your affiliate application has been  denied. Kindly wait for a month to reapply again
          </p>
         </>
      )
    })
  }
  }, [apiData])
  
  return (
    <div
      className="my-body flex justify-content-center align-items-center"
      style={{ height: "70vh" }}
    >
      <div
        style={{ maxWidth: "900px",height:'min-content',padding:'35px'}}
        className={`w-49-100 relative  flex flex-col  br-5 ${
          lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
        }`}
      >
         <img
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "0",
          left:0,
          // opacity: ".6",
          objectFit: "fill",
        }}
        src={bgGrad}
        alt="bg"
      />
        <div>{affiliateStatus.icon}</div>
        <h1 className="fs-40-28 fw-700">
         {affiliateStatus.head}
        </h1>
        <div className="z-10">
         {affiliateStatus.desc}
        </div>
      </div>
    </div>
  );
}

export default AffiliateStatus;
