import { Form, Input, Radio, Select, Switch } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import { useSelector } from "react-redux";
import AntFormInput from "../../components/ant/AntFormInput";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";
import CalculatorSlider from "./CalculatorSlider";

function CalcInputFeilds({
  lightMode,
  formlist,
  sliderList,
  setGetRsult,
  SliderValues,
  setSliderValues,
  setNse,
  Averagecalc,
  nse,
  setSharePrice,
  SharePrice,
  setPurchasePrice,
  PurchasePrice,
  setTot,
  setAvg,
  resetValues
}) {
  const onFinish = (e) => {
    console.log("this the average data", e);
    setGetRsult(e);
  };
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const sumbit = ()=>{
    console.log("SharePrice",SharePrice["sharePrice1"]);
    let total =
    Number(SharePrice["sharePrice1"] ? SharePrice["sharePrice1"] : 0) +
    Number(SharePrice["sharePrice2"] ? SharePrice["sharePrice2"] : 0) +
    Number(SharePrice["sharePrice3"] ? SharePrice["sharePrice3"] : 0) +
    Number(SharePrice["sharePrice4"] ? SharePrice["sharePrice4"] : 0);
  let Average =
    (Number(PurchasePrice["PurchasePrice1"] ? PurchasePrice["PurchasePrice1"] : 0) +
      Number(PurchasePrice["PurchasePrice2"] ? PurchasePrice["PurchasePrice2"] : 0) +
      Number(PurchasePrice["PurchasePrice3"] ? PurchasePrice["PurchasePrice3"] : 0) +
      Number(PurchasePrice["PurchasePrice4"] ? PurchasePrice["PurchasePrice4"] : 0)) /
    (Number(PurchasePrice["PurchasePrice1"] ? 1 : 0) +
      Number(PurchasePrice["PurchasePrice2"] ? 1 : 0) +
      Number(PurchasePrice["PurchasePrice3"] ? 1 : 0) +
      Number(PurchasePrice["PurchasePrice4"] ? 1 : 0));
      setTot(total)
      setAvg(Average)
      console.log("Average",Number(PurchasePrice["PurchasePrice1"] ? PurchasePrice["PurchasePrice1"] : 0) ,
      Number(PurchasePrice) ,
      Number(PurchasePrice) ,
      PurchasePrice);
  }
  const reset =()=>{
    setPurchasePrice({PurchasePrice1:"",
    PurchasePrice2:"",
    PurchasePrice3:"",
    PurchasePrice4:"",})
    setAvg("")
    setTot("")
  }
  const [form] = Form.useForm();
  console.log("form",form);

  return (
    <div>
      {setNse && (
        <div className="d-flex justify-content-end w-100">
          <div className="flex">
            <Switch
              defaultChecked
              onChange={(checked) => {
                console.log("nse", nse);
                if (checked) {
                  setNse("nse");
                } else {
                  setNse("bse");
                }
              }}
            />
            <span className="fw-500">{nse === "nse" ? "NSE" : "BSE"}</span>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center w-100 ">
        {sliderList && (
          <div className="w-100">
            {sliderList.map((res, i) => (
              <CalculatorSlider
                setSliderValues={setSliderValues}
                SliderValues={SliderValues}
                data={res}
                lightMode={lightMode}
                i={i}
              />
            ))}
          </div>
        )}

        {formlist ? (
          <Form
            layout="vertical"
            //   name="nest-messages"
            onFinish={onFinish}
            form={form}
            style={{ gap: "10px" }}
            className="w-100 d-flex flex-wrap justify-content-between"
            validateMessages={validateMessages}
          >
            {formlist?.map((items) => (
              // <AntFormInput label={items} />
              <Form.Item
              rules={[
                {
                  required:items.default ? false : true,
                  message: 'Please input your value!',
                },
              ]}
                name={[items.name]}
                label={
                  <p style={{ color:lightMode ? "black" :"white",  marginBottom: "0px" }}>
                    {items.value}
                  </p>
                }
                className={`w-45-100 flex-col flex-grid ${
                  lightMode ? "light-input-login" : "Dark-input-login"
                }`}
              >
                 {Object.keys(items).includes('options') ? 
                <Select
                className={
                  lightMode
                    ? "br-5 contact-select-light bg-gray border1px-light-mode"
                    : "br-5  contact-select-dark bg-dark-gray border1px-dark-mode"
                }
                // defaultValue={defaultSelect}
                dropdownClassName={`${
                  !lightMode && "drop-down-stock invert-text"
                }`}
                // onChange={handleChange}
                style={{
                  width: "100%",
                  height: "40px",
                  margin:'auto'
                }}
                placeholder="Type*"
              >
                {items?.options?.map((items, i) => (
                  <Option key={i} value={items?.value}>
                    {items?.name}
                  </Option>
                ))}
              </Select>
              :

              <Input
                  type="number"
                  defaultValue={items.default}
                  value={items.default}
                  className={`w-100  h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black dark-input-login calc-input-login-page"
                  }`}
                />  
                 } 
              </Form.Item>
            ))}
            <div
              style={{ gap: "10px" }}
              className="d-flex w-100 justify-content-end"
            >
              <div
              
              onClick={()=>{form.resetFields() 
                resetValues()
              }}
                className="br-3 align-items-center px-3 w-100px bg-transparent fs-s-14 pointer d-flex justify-content-center btn-blue-border 
              text-btn-dark"
              >
                Reset
              </div>
              <button
              htmlType="submit"
                type="submit"
                className="fw-500 fs-s-14 w-100px btn-bg-primary text-white animation-button  pointer br-5 p-10"
              >
                Calculate
              </button>
            </div>
          </Form>
        ) : (
          Averagecalc && (
            <div>
            <div className="d-flex flex-col-row" style={{ gap: "20px" }}>
              <div className="w-50-100">
                <div>
                  <p>Share Bought</p>
                </div>
                <Input
                  onChange={(e)=>setSharePrice({...SharePrice,sharePrice1:e.target.value})}
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                />{" "}
                <Input
                  type="number"
                  onChange={(e)=>setSharePrice({...SharePrice,sharePrice2:e.target.value})}
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                />{" "}
                <Input
                  onChange={(e)=>setSharePrice({...SharePrice,sharePrice3:e.target.value})}
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                />
                <Input
                  type="number"
                  onChange={(e)=>setSharePrice({...SharePrice,sharePrice4:e.target.value})}
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                />
              </div>
              <div className="w-50-100">
                <div>
                  <p>Purchase Price</p>
                </div>
                <Input
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                  onChange={(e)=>setPurchasePrice({...PurchasePrice,PurchasePrice1:e.target.value})}

                />{" "}
                <Input
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                  onChange={(e)=>setPurchasePrice({...PurchasePrice,PurchasePrice2:e.target.value})}

                />{" "}
                <Input
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                  onChange={(e)=>setPurchasePrice({...PurchasePrice,PurchasePrice3:e.target.value})}

                />
                <Input
                  type="number"
                  className={`w-100 h-40px auth-form-input  ${
                    lightMode
                      ? "bg-gray email-input-login-page-light"
                      : "bg-dark-black calc-input-login-page"
                  }`}
                  onChange={(e)=>setPurchasePrice({...PurchasePrice,PurchasePrice4:e.target.value})}
                />
              </div>
          
            </div>
            <div
              style={{ gap: "10px" }}
              className="d-flex w-100 mt-20 justify-content-end"
            >
              <button
                className="br-3 px-3 w-100px bg-transparent fs-s-14 pointer btn-blue-border 
              text-btn-dark"
              onClick={reset}
              >
                Reset
              </button>
              <button
                onClick={sumbit}
                className="fw-500 fs-s-14 w-100px btn-bg-primary text-white animation-button  pointer br-5 p-10"
              >
                Calculate
              </button>
            </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CalcInputFeilds;
