import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFundamentalPorfolioAnalysis } from "../../api/fetchClient";
import CustomTable from "../../components/CustomTable";
import numberWithCommas from "../../utilityFn/numberWithCommas";
import { redGreenColorPicker } from "../../utilityFn/redGreenColor";
import SortHeader from "../screener/table section/SortHeader";

function FundamentalTable({isLoading,Fundamental, sortState,setsortState,lightMode }) {
 
 
  const columns = [
    {
       title: (
        <SortHeader
          label="Company"
          header="company"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "company",
      fixed: "left",
      width: "60px",
      render(text, record) {

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <Link to={`/stock/${record?.symbol}/consolidated`}>
              <span
                className="link-hover-underline"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: lightMode ? "black" : "white",
                }}
              >
                {text}
              </span>
            </Link>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label={<div>Mcap <p className="fs-s-12">(cr)</p></div>}
          header="mcap"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "mcap",
      width: "50px",
      render(text, record) {
        const NumberComverted = numberWithCommas(Number(Math.trunc(Number(text))))

        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: lightMode ? "black" : "white",
              }}
            >
              {text && "₹" +  NumberComverted }
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label={<div className="d-inline">PE <p className="fs-s-12">(TTM)</p></div>}
          header="pe"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      width: "50px",
      dataIndex: "pe",
      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text && numberWithCommas(text)}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="D/E"
          header="de"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "de",
      width: "50px",

      render(text, record) {
        const toFixedValue = text;
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text &&  numberWithCommas(Number(toFixedValue))}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="ROE"
          header="roe"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "roe",
      width: "50px",

      render(text, record) {
        const toFixedValue = text?.toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text ?  numberWithCommas(Number(toFixedValue))+'%' : 0}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="ROCE"
          header="roec"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "roec",
      width: "50px",

      render(text, record) {
        const toFixedValue = text;
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text && numberWithCommas(Number(toFixedValue))+'%'}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Current Ratio"
          header="current_ratio"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "current_ratio",
      width: "50px",

      render(text, record) {
        // const toFixedValue = (record?.cmp * record?.quantity).toFixed(2);
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                fontSize: "14px",
                fontWeight: "400",

                color: lightMode ? "black" : "white",
              }}
            >
              {text &&  text}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="Div Yield"
          header="yield"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "yield",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: lightMode ? "black" : "white",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {numberWithCommas(text) + " " + "%"}
            </span>
          ),
        };
      },
    },
    {
      title: (
        <SortHeader
          label="YTD %"
          header="ytd"
          sortState={sortState}
          setsortState={setsortState}
        />
      ),
      dataIndex: "ytd",
      width: "50px",

      render(text, record) {
        return {
          props: {
            style: {
              background: lightMode ? "#F2F6F8 " : "#3B3F4F",
              borderRight: lightMode
                ? "1px solid #C9D7DE"
                : "1px solid #545E78",
              borderLeft: lightMode ? "1px solid #C9D7DE" : "1px solid #545E78",
              borderBottom: "none",
            },
          },
          children: (
            <span
              className="ff-lato"
              style={{
                color: redGreenColorPicker(text, lightMode),
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {text >= 0 && "+" + " "} {numberWithCommas(text) + " " + "%"}
            </span>
          ),
        };
      },
    },
  ];
  const testData = [
    {
      comp_name: "test",
      m_cap: 1455222,
      pe: 25,
      debt_eqt: 25,
      roe: 12,
      roce: 14,
      curr_ratio: 14,
      div_yield: 85,
      ytd_percent: 36,
    },
  ];
 
  
  return (
    <div
      className={`table-shadow   ${
        lightMode ? "custom-antd-head-light" : "custom-antd-head-dark"
      }`}
    >
      <CustomTable
        columns={columns}
        data={Fundamental}
        scrollable={true}
        // scrollLimit={900}
          loading={isLoading}
      />
    </div>
  );
}

export default FundamentalTable;
