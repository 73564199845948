import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import getStartedPlans from "../../../assets/images/bg/getStartedPlans.png";
function ViewPlanSection({ lightMode }) {
  return (
    <section
      // style={{ backgroundImage: `url(${bgGradLight})`}}
      className="home-ring-bg my-100 d-flex flex-col div-items-center"
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-40px",
          // opacity: ".6",
          objectFit: "fill",
        }}
        src={getStartedPlans}
        alt="bg"
      />
      <div className="max-w  h-100  h-100 mx-auto px-15  w-100 ">
        <div className="d-flex flex-col div-items-center">
          <h1 className="fs-40-32 fw-700 mb-30">Get Started Now</h1>
          <p className="fs-16-14 text-align-center mb-30 w-100-50">
            Access our premium features for insightful and detailed analysis of
            your favourite stocks to create your dream portfolio.
          </p>
          <Link to={`/getpremium`}>
            <button
              style={{ width: "200px", height: "50px" }}
              className="btn-bg-primary  fw-600 text-white fs-16-14 br-5"
            >
              View Our Plans <ArrowRightOutlined />
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ViewPlanSection;
