import React from "react";
import { Link } from "react-router-dom";
import getstartedBgDark from "../../../../../assets/images/bg/getstartedBgDark.png";
import getStartedBgMob from "../../../../../assets/images/bg/getStartedBgMob.png";
import spaceManImg from "../../../../../assets/images/affiliateSpace.png";

function SignupAffiliate({userRedirect}) {
  return (
    <div
      // style={{ backgroundImage: `url(${getStartedBg})` }}
      className="home-ring-bg py-40"
    >
      <img
        className=" display-lg"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-40px",
          // opacity: ".6",
          objectFit: "fill",
          zIndex: 1,
        }}
        src={getstartedBgDark}
        alt="bg"
      />
      <img
        className=" display-md"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "-40px",
          opacity: ".8",
          objectFit: "fill",
          zIndex: 1,
        }}
        src={getStartedBgMob}
        alt="bg"
      />
      <section className="d-flex flex-col-row justify-content-between align-lg-center max-w mx-auto px-15 ">
        {/* left section  */}
        <div className="w-100-60 d-flex flex-col align-md-center ">
          <h1 className="fs-40-28 fw-700 text-md-center">
            Earn with us through our <br /> Affliate Program
          </h1>
          <p className="my-20 fs-16-12 w-100-70 text-md-center">
            Trade brains portal helps investors make efficient stock research
            and analysis by providing quality fundamental data
          </p>
            <button
            onClick={()=>userRedirect()}
              style={{ width: "200px", height: "50px" }}
              className="btn-bg-primary fw-600 z-10 text-white fs-16-14 br-5"
            >
              Sign up as An Affiliate
            </button>
        </div>
        {/* left section  */}
        {/* right section  */}
        {/* only in pc view  */}
        <div style={{ margin: "auto" }}>
          <img src={spaceManImg} alt="img" />
        </div>
        {/* right section  */}
      </section>
    </div>
  );
}

export default SignupAffiliate;
