import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getScreenerStore } from "../../../redux/reducers/screenerSlice";
import FilterResultTable from "./FilterResultTable";
import Movetowatchlist from "../../../components/Movetowatchlist";
import AddColumnModal from "../components/AddColumnModal";
import svgSheet from "../../../assets/svg/svgSheet";
import CustomDropdown from "../../../components/ant/CustomDropdown";
import { screenerExcel } from "../components/ScreenerExcel";
import { excelCouterAPI, getAllScreenStocks } from "../../../api/fetchClient";
import { getFormatDate } from "../../../utilityFn/getFormatdate";
import { authStore } from "../../../redux/reducers/authSlice";
import PremiumRedirectModal from "../../../components/PremiumRedirectModal";

function RightSectionIndex({
  lightMode,
  filterTypeArr,
  postSectorArr,
  postIndexArr,
  columnsArr,
  setcolumnsArr,
  activePage,
  setactivePage,
  setpremiumModal,
}) {
  const { userData } = useSelector(authStore);
  const screenerStore = useSelector(getScreenerStore);
  const [watchlistTickToggle, setwatchlistTickToggle] = useState(false);
  const [watchlistStocks, setwatchlistStocks] = useState([]);
  const [toggleColumnModal, settoggleColumnModal] = useState(false);
  const [columnTabledata, setColumnTabledata] = useState([]);
  const [excelLimitModal, setexcelLimitModal] = useState(false);
  const [sortState, setsortState] = useState({
    head: "MCAP",
    sortOrder: false,
  });
  // create appiled filter arr
  const defaultChecked = useMemo(() => {
    const particularArr = filterTypeArr?.map((data) => data.particular);
    return particularArr;
  }, [filterTypeArr]);

  // export excel function
  const onExportExcel = async () => {
    if (userData?.user?.is_premium) {
      await excelCouterAPI({ file: "Stock-screener" }).then(async (resp) => {
        if (resp.data.count_expired === false) {
          // get table headers
          const newArr = [...columnTabledata];
          newArr.shift();
          // get all stocks without pagination
          const postData = filterTypeArr?.map((item) => {
            let valueArr = [item.firstValue, item.secondValue];
            return {
              values: valueArr,
              particular: item.particular,
              operator: item.operator,
            };
          });
          const defaultColumns = [
            "MCAP",
            "PRICE",
            "returns_1y",
            "TTMPE",
            "YIELD",
          ];
          const coulumnData = filterTypeArr?.map(
            ({ particular }) => particular
          );
          const columnParticularArr = columnsArr?.map(
            (data) => data.particular
          );
          const data = {
            payload: {
              allFilters: postData,
              selectedColumns: [
                ...new Set([
                  ...defaultColumns,
                  ...coulumnData,
                  ...columnParticularArr,
                ]),
              ],
              offset: 0,
              // sortBy: "MCAP",
              // sortOrder: "DESC",
              sortBy:sortState.head,
              sortOrder: sortState.sortOrder ? "ASC" : "DESC",
              industries: postSectorArr,
              indices: postIndexArr,
            },
            page: 1,
            perPage: screenerStore?.filterResponse?.count,
          };
          await getAllScreenStocks(data).then((resp) => {
            screenerExcel(newArr, resp.data).then((url) => {
              const downloadAnchorNode = document.createElement("a");
              downloadAnchorNode.setAttribute("href", url);
              downloadAnchorNode.setAttribute(
                "download",
                `screener-data-${getFormatDate(new Date())}.xlsx`
              );
              downloadAnchorNode.click();
              downloadAnchorNode.remove();
            });
          });
        } else {
          setexcelLimitModal(true);
        }
      });
    } else {
      setexcelLimitModal(true);
    }
  };
  // right side dropdown items 
  const menuItems = [
    {
      label: (
        <p className="mb-0" onClick={() => settoggleColumnModal(true)}>
          Add Columns
        </p>
      ),
      key: "0",
    },
    {
      label: (
        <p className="mb-0" onClick={onExportExcel}>
          Export Excel
        </p>
      ),
      key: "1",
    },
  ];

  return (
    <>
      <div>
        <div className="d-flex flex-col-row justify-content-between">
          <p className="fs-18-16 mb-0 fw-500 lh-18 d-flex align-items-start">
            Search Results - {screenerStore?.filterResponse?.count}
          </p>
          <div className="flex flex-col-row-reverse ">
            <div className="flex align-items-center">
              {watchlistTickToggle && (
                <div className="flex align-items-center">
                  <p className="mb-0 fs-14-12 ">
                    {watchlistStocks?.length} Selected
                  </p>
                  <Movetowatchlist
                    lightMode={lightMode}
                    symbol={watchlistStocks}
                  >
                    <button className="  btn-bg-primary text-white br-3 fw-500  p-5 fs-14-12">
                      + Add to Watchlist
                    </button>
                  </Movetowatchlist>
                </div>
              )}
            </div>

            <div className="flex align-items-center">
              {/* <SelectOptions
                lightMode={lightMode}
                data={[
                  { value: "test", label: "test query" },
                  { value: "test2", label: "test query2" },
                ]}
                // setselectd={setselectd}
                // defaultSelect={selectd}
                width={200}
              />
              <Link to={`/screener/query/new`}>
              <button className="px-3 h-30px btn-bg-primary text-white br-3 fw-600  fs-14-12">
                Custom Query
              </button>
              </Link> */}
              <CustomDropdown
                menuItems={menuItems}
                activeTrigger="click"
                lightMode={lightMode}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  className="pointer"
                  // onClick={() => settoggleColumnModal(true)}
                >
                  {svgSheet.settingsIcon}
                </div>
              </CustomDropdown>
            </div>
          </div>
        </div>
        <FilterResultTable
          filterTypeArr={filterTypeArr}
          checkedColumns={columnsArr}
          lightMode={lightMode}
          screenerStore={screenerStore}
          postSectorArr={postSectorArr}
          postIndexArr={postIndexArr}
          watchlistTickToggle={watchlistTickToggle}
          setwatchlistTickToggle={setwatchlistTickToggle}
          watchlistStocks={watchlistStocks}
          setwatchlistStocks={setwatchlistStocks}
          activePage={activePage}
          setactivePage={setactivePage}
          setColumnTabledata={setColumnTabledata}
          sortState={sortState}
          setsortState={setsortState}
        />
      </div>
      {/* add custom columns to filtered table  */}
      <AddColumnModal
        toggleModal={toggleColumnModal}
        settoggleModal={settoggleColumnModal}
        screenerStore={screenerStore}
        lightMode={lightMode}
        filterTypeArr={filterTypeArr}
        defaultChecked={[
          ...defaultChecked,
          "PRICE",
          "returns_1y",
          "TTMPE",
          "YIELD",
        ]}
        postSectorArr={postSectorArr}
        postIndexArr={postIndexArr}
        columnsArr={columnsArr}
        setcolumnsArr={setcolumnsArr}
      />
      <PremiumRedirectModal
        visible={excelLimitModal}
        setVisible={setexcelLimitModal}
        modalPlan="excel"
      />
    </>
  );
}

export default RightSectionIndex;
