import React from "react";
import DoughnutChart from "../../../components/Charts/DoughnutChart";

function BacktestDiversification({ lightMode }) {
  const data = {
    labels: ["Energy", "Auto", "FMCG", "IT", "Petrolium"],
    datasets: [
      {
        data: [3, 4, 34, 23, 21],
        backgroundColor: [
          "#FF9177",
          "#EB5757",
          "#5ECBC8",
          "#438FFF",
          "#BF81FB",
          "#4A95DA",
        ],
        // backgroundColor:(context)=>{
        // },
        borderColor: ["#FF9177", "#EB5757", "#5ECBC8", "#438FFF", "#BF81FB"],
        borderWidth: 0,
        hoverOffset: 15,
      },
    ],
  };
  const options = {
    layout: { padding: 10 },
    cutout: 90,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        enabled: true,
        backgroundColor: lightMode ? "rgba(0, 0, 0, 0.8)" : "#ffff",
        bodyFont: {
          size: 16,
          weight: 600,
        },

        displayColors: false,
        callbacks: {
          title: (contextArr) => {
            return "";
          },
          labelTextColor: (context) => {
            return context.dataset.backgroundColor[context.dataIndex];
          },
          //   labelTextColor: (context) => {
          //     return "#438FFF";
          //   },
          label: (context) => {
            return `${context.label}:${context.formattedValue}%`;
          },
        },
      },
    },
  };

  return (
    <div className="mt-40" id="Diversification">
      <h2 className="main-header">Diversification</h2>
      <div
        style={{ gap: "30px" }}
        className="flex flex-col-row justify-content-between"
      >
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 1</h3>
          <div style={{ height: "300px" }}>
            <DoughnutChart data={data} options={options} />
          </div>
        </div>
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 2</h3>
          <div style={{ height: "300px" }}>
            <DoughnutChart data={data} options={options} />
          </div>
        </div>
        <div className="w-100-30 d-flex flex-col align-items-center">
          <h3 className="fs-s-16 fw-600 mb-20">Portfolio 3</h3>
          <div style={{ height: "300px" }}>
            <DoughnutChart data={data} options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BacktestDiversification;
