import React from "react";
import { useSelector } from "react-redux";
import { getThemeMode } from "../../redux/reducers/ThemeSlice";

function UpdateCard({ data }) {
  const { lightMode } = useSelector(getThemeMode);
  return (
    <div
      className={`card-shadow relative h-100 w-100 p-10md-50lg mb-40 br-3 ${
        lightMode ? "bg-gray card-drop-light-shadow" : "card-drop-dark-shadow"
      }`}
    >
      <h1 className="fs-25-20 mb-20 fw-600">{data.head}</h1>
      <p className="fw-700 ">{data.date}</p>
      <p className="mb-40">{data.desc}</p>
      <img src={data.image} alt="new image" className="w-100" />
      <p className="text-center mt-20 mb-0 text-gray">{data.bottomDesc}</p>
    </div>
  );
}

export default UpdateCard;
