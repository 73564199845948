import React from "react";

function ScreenCard({ lightMode, icon, head, content, onClickFn }) {
  return (
    <div
      onClick={onClickFn}
      style={{ minHeight: "200px" }}
      className={`  align-items-center d-flex flex-col p-10 br-5 pointer ${
        lightMode ? "bg-gray" : "bg-dark-gray"
      }`}
    >
      <img style={{width:'60px',height:'60px'}}  src={icon} alt="icon" />
      <p className="fs-16-14 fw-600  text-align-center">{head}</p>
      <p className="fs-s-12  text-align-center">{content}</p>
    </div>
  );
}

export default ScreenCard;
