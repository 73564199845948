import { createAsyncThunk, createSlice,current} from '@reduxjs/toolkit'
import produce from 'immer'
import { get, post } from '../../api/MainClient'
import StockEndpoints from '../../api/endPoints'

//get all filter select data
export const getFilterData= createAsyncThunk(
    "screener/getFilterData",
    async ()=>{
        const url=StockEndpoints.getFilterTypes
        const response=await get(url)
        return response.data
    }
)
// post screener data
export const postScreenerData= createAsyncThunk(
    "screener/postScreenerData",
    async ({applyFilters,industries=[],indices,sortBy="MCAP",sortOrder='DESC',customColumns=[],page=1,perPage=25})=>{
        const postData=applyFilters?.map((item)=>{
            let valueArr=[item.firstValue,item.secondValue]
            return {
                values:valueArr,
                particular: item.particular,
                operator: item.operator
              }
        })
        const defaultColumns=["MCAP","PRICE","returns_1y","TTMPE","YIELD"]
        // const defaultColumns=["MCAP","PRICE","TTMPE","YIELD"]
        const coulumnData=applyFilters?.map(({particular})=>particular)
        const data={
            allFilters:postData,
            selectedColumns: [...new Set([...defaultColumns,...coulumnData,...customColumns])],
            offset: 0,
            sortBy: sortBy,
            sortOrder: sortOrder,
            industries:industries,
            indices:indices
        }
        const url=StockEndpoints.getFilterTypes
        // const response=await post("screener/",data)
        const response=await post(`screener/?page=${page}&per_page=${perPage}`,data)
        return response.data
    }
)
// add page offset
export const postOffsetData= createAsyncThunk(
    "screener/postOffsetData",
    async ({applyFilters,offset=0,industries,indices})=>{
        const postData=applyFilters?.map((item)=>{
            let valueArr=[item.firstValue,item.secondValue]
            return {
                values:valueArr,
                particular: item.particular,
                operator: item.operator
              }
        })
        const defaultColumns=["MCAP","PRICE","returns_1y","TTMPE","YIELD"]
        const coulumnData=applyFilters?.map(({particular})=>particular)
        const data={
            allFilters:postData,
            selectedColumns: [...new Set([...defaultColumns,...coulumnData])],
            offset: offset,
            sortBy: "MCAP",
            sortOrder: "DESC",
            industries: industries
        }
        const url=StockEndpoints.getFilterTypes
        const response=await post("screener/",data)
        return response.data
    }
)
const initialState = {
    filterData:{
        indices:[],
    },
    filterResponse:{
        results:[],
    },
    responseStatus:false
}

const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  reducers: {},
  extraReducers:{
      [getFilterData.fulfilled]:(state,{payload})=>{
    let groupedState={}
    for (let index = 0; index < payload.filter_categories.length; index++) {
        const categoryFilter=payload.filters?.filter((item)=>item.category===payload.filter_categories[index])
   groupedState={...groupedState,[payload.filter_categories[index]]:categoryFilter}
    }
        return {...state,filterData:groupedState,industries:payload.industries,indices:payload.indices}
      },
      // post api status
      [postScreenerData.pending]:(state,{payload})=>{
       return {...state,responseStatus:true}
      },
      [postScreenerData.fulfilled]:(state,{payload})=>{
          return {...state,filterResponse:payload,responseStatus:false}

      },
      [postScreenerData.rejected]:(state,{payload})=>{
          return {...state,responseStatus:false,filterResponse:{
            results:[]
        }}
      },
    //   increament next page 
      [postOffsetData.pending]:(state,{payload})=>{
        return {...state,responseStatus:true}
      },
      [postOffsetData.fulfilled]:(state,{payload})=>{
        const nextstate=produce(current(state.filterResponse),draft=>{
          draft.results=draft.results.concat(...payload.results)
          draft.results_count=payload.results_count
      })
        return {...state,filterResponse:nextstate,responseStatus:false}
      },
      [postOffsetData.rejected]:(state,{payload})=>{
        return {...state,responseStatus:false}
    },
  }
});

export const {} = screenerSlice.actions
export const getScreenerStore=(state)=>state.screener
export default screenerSlice.reducer